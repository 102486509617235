import clsx from "clsx";
import styles from "@scss/common/WrapperSVG.module.scss";
type WrapperSVGT = {
  children: React.ReactNode;
  color: string;
  cursor?: boolean;
  margin?: boolean;
};

export const WrapperSVG: React.FC<WrapperSVGT> = ({
  children,
  color,
  margin,
  cursor = true,
}) => {
  return (
    <div
      className={clsx(
        margin && styles.marginRight,
        styles.wrapper,
        styles[color],
        cursor && styles.cursor
      )}
    >
      {children}
    </div>
  );
};
