import PositionsGetAllService, {
  GetAllPositionsParams,
  PositionsGetAllT,
} from "@services/positions-get-all.service";

import { useQuery } from "react-query";

const uniquePositions = (data: PositionsGetAllT[]) => {
  const filteredData = data.reduce((accumulator, current) => {
    const existingObject = accumulator.find(
      (item) => item.title === current.title
    );
    if (!existingObject) {
      accumulator.push(current);
    }
    return accumulator;
  }, [] as PositionsGetAllT[]);

  return filteredData;
};

export const usePositionsGetAllQuery = (
  params?: GetAllPositionsParams,
  onQuery?: boolean
) => {
  const { data } = useQuery(
    ["positions-get-all", params, onQuery],
    async () => {
      return await PositionsGetAllService.getAll(params);
    },
    { enabled: !!onQuery }
  );

  const filteredData = data ? uniquePositions(data as PositionsGetAllT[]) : [];
  return { data: filteredData };
};
