import { useMutation, useQueryClient } from "react-query";
import {
  studyCenterService,
  UpdateEducationalPromotionData,
} from "@services/study-center";
import { studyCenterQueryKeys } from "./queryKeys";

export const useUpdateEducationalPromotionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateEducationalPromotionData) =>
      studyCenterService.updatePromotion(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(studyCenterQueryKeys.all);
      },
    }
  );
};
