import React from "react";
import clsx from "clsx";
import styles from "./text-field-title.module.scss";

type AllTypeElement = React.InputHTMLAttributes<HTMLInputElement>;

interface TextFieldProps extends AllTypeElement {
  label?: string;
  name: string;
  alignText?: "left" | "right" | "center";
  className?: string;
  fontSize?: "small" | "medium" | "large";
  type?: typesForTextField;
  inputClassName?: string;
  iconClassName?: string;
  labelClassName?: string;
  color?: string;
  errorMessage?: string;
  isError?: boolean;
  focus?: boolean;
  border?: boolean;
  textarea?: boolean;
  typeColor?: string;
  max?: number | string;
}

type typesForTextField =
  | "color"
  | "date"
  | "datetime-local"
  | "email"
  | "file"
  | "hidden"
  | "month"
  | "number"
  | "password"
  | "range"
  | "reset"
  | "search"
  | "submit"
  | "tel"
  | "text"
  | "time"
  | "url"
  | "week";

export const TextFieldTitle = React.forwardRef(
  (
    {
      typeColor = "",
      textarea = false,
      className = "",
      inputClassName = "",
      iconClassName = "",
      type = "text",
      alignText = "left",
      name,
      maxLength = 60,
      fontSize = "medium",
      label,
      errorMessage = undefined,
      isError = false,
      children,
      color,
      focus = false,
      border = true,
      max = 9999,
      ...props
    }: React.PropsWithoutRef<TextFieldProps>,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <div className={styles.wrapper}>
        <input
          maxLength={maxLength}
          autoComplete="off"
          name={name}
          style={{ textAlign: alignText }}
          className={clsx(styles.input, styles[`font_${fontSize}`])}
          type={type}
          max={max}
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);
