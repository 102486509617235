import { FC } from "react";

export const DeleteCrossSVG: FC<{ size?: number }> = ({ size = 10 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.44854 0.951447C9.91717 1.42008 9.91717 2.17987 9.44854 2.6485L2.24854 9.8485C1.77991 10.3171 1.02011 10.3171 0.551484 9.8485C0.0828549 9.37988 0.0828549 8.62008 0.551484 8.15145L7.75148 0.951447C8.22011 0.482818 8.97991 0.482818 9.44854 0.951447Z"
        fill="#E24826"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.551484 0.951447C1.02011 0.482818 1.77991 0.482818 2.24854 0.951447L9.44854 8.15145C9.91717 8.62008 9.91717 9.37988 9.44854 9.8485C8.97991 10.3171 8.22011 10.3171 7.75148 9.8485L0.551484 2.6485C0.0828549 2.17987 0.0828549 1.42008 0.551484 0.951447Z"
        fill="#E24826"
      />
    </svg>
  );
};
