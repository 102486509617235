import { $User, $UserAddPermissions, ACTIONS } from "@store/user-store";

import { BodyBold } from "@ui/fonts";
import { Companies } from "./Companies";
import { NotePencil } from "@phosphor-icons/react";
import { UserData } from "./UserData";
import { UserDataT } from "@interfaces/user";
import { UserPassword } from "./UserPassword";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import { concatApiUrl, concatClientUrl } from "@utils/api-tools";
import styles from "./Profile.module.scss";
import { useDropzone } from "react-dropzone";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";

export const Profile: React.FC = () => {
  const user = useStore($User) as UserDataT;
  const permissions = useStore($UserAddPermissions);

  const { open } = useModal();

  const onFileSelect = async (acceptedFile: any) => {
    const blob = new Blob(acceptedFile, { type: acceptedFile.type });
    const getOfflinePreview = (file: any) =>
      new Promise((resolve) => {
        const fileReader = new FileReader();

        fileReader.onload = (e: any) => {
          resolve(e.target.result);
        };

        fileReader.onloadend = (e: any) => {
          resolve(fileReader.result);
        };

        fileReader.readAsDataURL(file);
      });

    const preview = await getOfflinePreview(blob);

    open("UploadUserImage", {
      modalData: { blob, preview, file: acceptedFile },
    });
  };

  const { getInputProps, getRootProps } = useDropzone({
    onDrop: onFileSelect,
    multiple: false,
    accept: ["image/*"],
  });

  return (
    <div className={styles.wrapper}>
      <div>
        <div className={styles.userInfo}>
          <p>
            <BodyBold weight={"bold"}>Личная информация</BodyBold>
          </p>
          <div {...getRootProps()} className={styles.profile_img_container}>
            <input {...getInputProps()} />
            {permissions.hasPermission(
              ACTIONS.personalAccount_allowedToEdit
            ) && (
              <WrapperSVG color={"orange"}>
                <NotePencil size={24} className={styles.edit_icon} />
              </WrapperSVG>
            )}
            <img
              src={
                user.avatar
                  ? concatApiUrl(`/uploads/users/avatars/${user.avatar}`)
                  : concatClientUrl("/img/static/dummy-avatar.png")
              }
              alt=""
              className={styles.profile_img}
            />
          </div>
          <UserData />
          <UserPassword />
        </div>
      </div>
      <div className={styles.companies}>
        <p>
          <BodyBold weight={"bold"}>Мои организации</BodyBold>
        </p>
        <Companies />
      </div>
    </div>
  );
};
