import { CheckboxCheckedSVG, CheckboxSVG } from "@components/svgs";
import {
  ControlStatus,
  Procedure,
  ProcedureDependentT,
  TablesDirectionDataT,
} from "@interfaces/company/procedure";
import React, { useState } from "react";

import { Checkbox } from "@material-ui/core";
import { GenerateCell } from "feature/procedure-control/generate-cell";
import ProcedureControlService from "@services/procedure-control-service";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { UpdateDataT } from "../table-category/table-category";
import clsx from "clsx";
import { isAllowedToCreateTrainingRequest } from "@utils/training-request";
import moment from "moment";
import { proceduresQueryKeys } from "@lib/procedures/queryKeys";
import style from "@scss/components/tables/table-category.module.scss";
import toast from "react-hot-toast";
import { useBriefingProcedureParamsContext } from "../briefing-params-context";
import { useQueryClient } from "react-query";
import { useTrainingProcedureParamsContext } from "../training-params-context";

type RowTableCategoryProps = {
  type: "training" | "briefing";
  index: number;
  row: Procedure;
  ignoredKeys: (keyof Procedure)[];
  selectedProcedures: Procedure[];
  companyId?: number;
  config?: TablesDirectionDataT["config"];
  onSelectProcedure: (procedure: Procedure, checked: boolean) => void;
};
type RowItemT = {
  date: string | Date | null | undefined;
  value: string | number | Date | null | undefined | ProcedureDependentT;
  item: {
    key: keyof Procedure;
    type: "string" | "date" | "input_date" | "input_string" | string[];
    label: string;
    place: number;
  };
};

export const RowTableCategory: React.FC<RowTableCategoryProps> = ({
  index,
  row,
  type,
  ignoredKeys,
  selectedProcedures,
  onSelectProcedure,
  companyId,
  config,
}) => {
  const queryClient = useQueryClient();
  const trainingParams = useTrainingProcedureParamsContext();
  const briefingParams = useBriefingProcedureParamsContext();

  const [updatedData, setUpdatedData] = useState<UpdateDataT[]>([]);

  const handleUpdateItem = (id: number, data: UpdateDataT[] = updatedData) => {
    const itemData = data.find((item) => item.id === id);
    if (!itemData) return;

    const newData: UpdateDataT = { id };
    Object.keys(itemData).forEach((key) => {
      if (key === "id") return;
      newData[key] = itemData[key] || null;
    });

    ProcedureControlService.EditTriningProcedure(type, newData, (err, res) => {
      if (err || !res) {
        const msg = err?.response?.data?.message;
        if (msg && typeof msg === "string") {
          toast.error(msg);
        }
        return;
      }

      const updater = (oldState: TablesDirectionDataT[] | undefined) => {
        if (!oldState) return [];

        return oldState.map((table) => {
          const newBody = table.body.map((row) => {
            const newDepRow = res.data?.dependent?.find((row_d) => {
              if (row_d.id === row.id) {
                return true;
              } else return false;
            });
            if (newDepRow) return newDepRow;

            return row.id === res.data.id ? res.data : row;
          });
          return { ...table, body: newBody };
        });
      };

      if (type === "briefing") {
        queryClient.setQueryData<TablesDirectionDataT[]>(
          proceduresQueryKeys.allBriefingProcedures(briefingParams),
          updater
        );
      }

      if (type === "training") {
        queryClient.setQueryData<TablesDirectionDataT[]>(
          proceduresQueryKeys.allTrainingProcedures(trainingParams),
          updater
        );
      }
    });
  };
  const handleItemChange = (
    id: number,
    value: { [key: string]: string },
    type: "string" | "date" | "input_date" | "input_string" | string[]
  ) => {
    const alreadyUpdated = updatedData.find((item) => item.id === id);

    if (type === "input_date") {
      const key = Object.keys(value)[0];
      const val = value[key];
      if (val === "") {
        handleUpdateItem(id, [{ id, [key]: null }]);
        return;
      }
      const date = moment(val);
      if (date.isValid()) {
        handleUpdateItem(id, [{ id, [key]: val }]);
      }
    }

    const newItem: UpdateDataT = { id };

    Object.keys(value).forEach((key) => {
      newItem[key] = value[key];
    });

    if (!alreadyUpdated) {
      setUpdatedData((prev) => [...prev, newItem]);
    } else {
      const item = updatedData.find((item) => item.id === id);
      if (!item) return;

      setUpdatedData((prev) =>
        prev.map((item) =>
          item.id === id ? { ...item, ...newItem } : { ...item }
        )
      );
    }
  };

  const handlerRowCell = ({ item, value, date }: RowItemT) => {
    if (item.type === "string") {
      if (typeof value === "number" && value < 0) {
        return 0;
      }
      return value;
    }

    if (item.type === "date") {
      if (item?.key === "trainingDate") {
        return date && moment(new Date(date)).format("DD.MM.YYYY");
      }
      return (
        row[item?.key] &&
        moment(new Date(row[item?.key] as string)).format("DD.MM.YYYY")
      );
    }
    return `Поле с типом "${item.type}"`;
  };

  const ColorCell = (status: ControlStatus, day: number | null) => {
    if (
      (status === ControlStatus.RequiredToFill ||
        status === ControlStatus.RequiredToFillAndProcessing) &&
      day === null
    ) {
      return style.box_red;
    }

    if (day === null) return "";

    if (day === 0) {
      return style.box_red;
    }

    return day
      ? day < 60 && day >= 30
        ? style.box_yellow
        : day < 30 && day >= 14
          ? style.box_orange
          : day < 14
            ? style.box_red
            : style.box_green
      : "";
  };

  const getRowStyles = (status: ControlStatus) => {
    if (
      [
        ControlStatus.RequiredToFill,
        ControlStatus.RequiredToFillAndProcessing,
      ].includes(status)
    ) {
      return style.row_color;
    }
    return "";
  };

  return (
    <TableRow className={getRowStyles(row.status)}>
      <TableCell>
        <Checkbox
          checked={
            !!selectedProcedures.find((procedure) => procedure.id === row.id)
          }
          disabled={!isAllowedToCreateTrainingRequest(row, companyId)}
          name="select_all"
          color="primary"
          icon={<CheckboxSVG />}
          checkedIcon={<CheckboxCheckedSVG />}
          onChange={(_, checked) => onSelectProcedure(row, checked)}
          classes={{ disabled: "mui_disabled_checkbox" }}
        />
      </TableCell>
      <TableCell component="th" scope="row">
        {index + 1}
      </TableCell>

      {config &&
        config
          .filter((item: RowItemT["item"]) => !ignoredKeys.includes(item.key))
          .map((item) => {
            const daysBeforeTraining = row?.daysBeforeTraining ?? null;
            const daysBeforeBriefing = row?.daysBeforeBriefing ?? null;
            const date = row?.trainingDate;
            const value = row[item.key];

            return (
              <TableCell
                align="left"
                key={item.key}
                className={clsx(
                  (item.key === "trainingDate" ||
                    item.key === "daysBeforeTraining") &&
                    ColorCell(row.status, daysBeforeTraining),
                  (item.key === "briefingDate" ||
                    item.key === "daysBeforeBriefing") &&
                    ColorCell(row.status, daysBeforeBriefing)
                )}
              >
                <div key={item.key} className={style.table_row_center}>
                  {Array.isArray(item?.type) ||
                  item?.type.split("_")[0] === "input" ? (
                    <GenerateCell
                      key={item.key}
                      onChange={(id, value) =>
                        handleItemChange(id, value, item.type)
                      }
                      onBlur={() => handleUpdateItem(row.id)}
                      type={item?.type}
                      name={item.key}
                      row={row}
                      label={item.label}
                      defaultValue={row[item.key] ?? ""}
                    />
                  ) : (
                    handlerRowCell({ item, value, date })
                  )}
                </div>
              </TableCell>
            );
          })}
    </TableRow>
  );
};
