export const CheckAround: React.FC = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 23.605C6.07617 23.605 0.786621 18.3154 0.786621 11.8916C0.786621 5.479 6.07617 0.178223 12.4888 0.178223C18.9126 0.178223 24.2134 5.479 24.2134 11.8916C24.2134 18.3154 18.9238 23.605 12.5 23.605ZM12.5 21.0332C17.5649 21.0332 21.6528 16.9565 21.6416 11.8916C21.6304 6.82666 17.5649 2.75 12.4888 2.75C7.42383 2.75 3.36963 6.82666 3.36963 11.8916C3.36963 16.9565 7.43506 21.0332 12.5 21.0332ZM11.2983 17.2373C10.8604 17.2373 10.5122 17.0352 10.1978 16.6421L7.60352 13.5088C7.40137 13.2505 7.28906 12.9922 7.28906 12.7114C7.28906 12.105 7.77197 11.6333 8.36719 11.6333C8.71533 11.6333 8.99609 11.7681 9.26562 12.1162L11.2646 14.6206L15.5322 7.77002C15.7793 7.36572 16.105 7.16357 16.4756 7.16357C17.0483 7.16357 17.5762 7.56787 17.5762 8.17432C17.5762 8.43262 17.4526 8.71338 17.2954 8.96045L12.354 16.6309C12.1069 17.0127 11.7251 17.2373 11.2983 17.2373Z"
        fill="#00B856"
      />
    </svg>
  );
};
