import { SigningControlJournalParams } from "@interfaces/signing-control";
import { signingControlService } from "@services/signing-control.service";
import { useQuery } from "react-query";
import { signingControlJournalQueryKeys } from "./queryKeys";

export const useSigningControlJournalQuery = (
  params: SigningControlJournalParams
) => {
  return useQuery(
    signingControlJournalQueryKeys.journal(params),
    () => signingControlService.getJournal(params),
    {
      keepPreviousData: true,
    }
  );
};
