import { documentTemplateService } from "@services/document-templates-service";
import { documentTemplatesQueryKeys } from "../document-templates/queryKeys";
import { useQuery } from "react-query";

export const useDocumentTemplatesGroupsQuery = () => {
  const { data: groups, ...stuff } = useQuery(
    documentTemplatesQueryKeys.allGroups(),
    () => documentTemplateService.getGroups()
  );

  return {
    groups,
    ...stuff,
  };
};
