import Fetcher from "@http/fetcher";
import { ResCallback } from "@interfaces/common";
import { TariffT } from "@interfaces/payment";

class TariffService {
  static async getAll(cb: ResCallback<any>) {
    try {
      const res = await Fetcher.get<any>("/tariffs");

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      return cb(err);
    }
  }

  static async update(
    id: number,
    data: Partial<TariffT>,
    cb: ResCallback<any>
  ) {
    try {
      const res = await Fetcher.patch<any>(`/tariffs/${id}`, data);

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      return cb(err);
    }
  }
  static async SendEmailToAdminAboutNewTariff(
    requestedTariffId: number,
    cb: ResCallback<any>
  ) {
    try {
      const res = await Fetcher.post<any>("/companies/tariffs/send", {
        requestedTariffId,
      });

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      return cb(err);
    }
  }
}

export default TariffService;
