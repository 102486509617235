import fetcher from "@http/fetcher";
import {
  InternalStudyCenterMaterial,
  InternalStudyCenterSection,
} from "@interfaces/internal-study-center";

export type GetInternalSectionsCategoriesResult = {
  data: InternalStudyCenterSection[];
};

export type CreateInternalStudyCenterCategoryData = {
  sectionId: number;
  name: string;
};

export type GetCategoryMaterialsData = {
  sectionId: number;
  categoryId: number;
};

export type CreateInternalMaterialData = {
  sectionId: number;
  categoryId: number;
  title: string;
  link: string | null;
  text: string | null;
  tags?: string[];
  preview: File;
  files?: File[];
};

export type UpdateInternalMaterialData = {
  sectionId: number;
  categoryId: number;
  id: number;
  title?: string;
  link?: string | null;
  text?: string | null;
  preview?: File;
  files?: File[];
};

export type DeleteInternalMaterialData = {
  sectionId: number;
  categoryId: number;
  id: number;
};

export const internalStudyCenterService = {
  async getSectionsCategories() {
    const resp = await fetcher.get<GetInternalSectionsCategoriesResult>(
      "/educational-center/internal-training/sections/categories"
    );

    return resp.data.data;
  },

  async createCategory(data: CreateInternalStudyCenterCategoryData) {
    const resp = await fetcher.post(
      `/educational-center/internal-training/sections/${data.sectionId}/categories`,
      { name: data.name }
    );

    return resp.data;
  },

  async getCategoryMaterials(data: GetCategoryMaterialsData) {
    const resp = await fetcher.get<{ data: InternalStudyCenterMaterial[] }>(
      `/educational-center/internal-training/sections/${data.sectionId}/categories/${data.categoryId}/materials`
    );

    return resp.data.data;
  },

  async getMaterialById(id: number, sectionId: number, categoryId: number) {
    const resp = await fetcher.get<InternalStudyCenterMaterial>(
      `/educational-center/internal-training/sections/${sectionId}/categories/${categoryId}/materials/${id}`
    );

    return resp.data;
  },

  async createMaterial(data: CreateInternalMaterialData) {
    const formData = new FormData();
    formData.set("title", data.title);

    if (data.link) {
      formData.set("link", data.link);
    }

    if (data.text) {
      formData.set("text", data.text);
    }

    formData.set("preview", data.preview);

    if (data.tags) {
      for (const tag of data.tags) {
        formData.append("tags", tag);
      }
    }

    if (data.files) {
      for (const file of data.files) {
        formData.append("files", file);
      }
    }

    const resp = await fetcher.post(
      `/educational-center/internal-training/sections/${data.sectionId}/categories/${data.categoryId}/materials`,
      formData
    );

    return resp.data;
  },

  async updateMaterial(data: UpdateInternalMaterialData) {
    const formData = new FormData();

    if (data.title) {
      formData.set("title", data.title);
    }

    if (data.link) {
      formData.set("link", data.link);
    }

    if (data.text) {
      formData.set("text", data.text);
    }

    if (data.preview) {
      formData.set("preview", data.preview);
    }

    // if (data.tags) {
    //   for (const tag of data.tags) {
    //     formData.append("tags", tag);
    //   }
    // }

    if (data.files) {
      for (const file of data.files) {
        formData.append("files", file);
      }
    }

    const resp = await fetcher.patch(
      `/educational-center/internal-training/sections/${data.sectionId}/categories/${data.categoryId}/materials/${data.id}`,
      formData
    );

    return resp.data;
  },

  async deleteMaterial(data: DeleteInternalMaterialData) {
    const resp = await fetcher.delete(
      `/educational-center/internal-training/sections/${data.sectionId}/categories/${data.categoryId}/materials/${data.id}`
    );

    return resp.data;
  },
};
