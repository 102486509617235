import {
  FetchProcedureControlPropsT,
  FetchProcedureTrainingProgramsT,
  ProcedureTrainingProgramsT,
  TablesDirectionDataT,
} from "@interfaces/company/procedure";
import { createEffect, createEvent, createStore } from "effector";

import { EventStateStoreT } from "@interfaces/common";
import ProcedureControlService from "@services/procedure-control-service";
import ProcedureService from "@services/programs-service";
import { resetAllStates } from "@store/user-store";
import { СategoriesT } from "@interfaces/directory/version";
export const setCategoriesTraining = createEvent<TablesDirectionDataT>();
export const pushProcedureInstructionsCategories = createEvent<{
  categories?: СategoriesT;
}>();
export const removeProcedureInstructionsCategories = createEvent<number>();
export const editProcedureInstructionsCategories = createEvent<СategoriesT>();
export const setProcedureInstructions = createEvent<{
  categories?: СategoriesT[];
  programs?: ProcedureTrainingProgramsT[];
}>();
export const $ProcedureInstructions = createStore<{
  categories?: СategoriesT[];
  programs?: ProcedureTrainingProgramsT[];
}>({ categories: [], programs: [] })
  .on(setProcedureInstructions, (oldState, newStateTraining) => {
    if (!newStateTraining) {
      return { categories: [] };
    }
    return {
      ...oldState,
      ...newStateTraining,
    };
  })
  .on(pushProcedureInstructionsCategories, (oldState, newState) => {
    if (newState.categories && oldState.categories) {
      return {
        ...oldState,
        categories: [...oldState?.categories, newState?.categories],
      };
    }
    if (newState.categories && !oldState.categories) {
      return { ...oldState, categories: [newState?.categories] };
    }
  })
  .on(removeProcedureInstructionsCategories, (oldState, categoryId) => {
    if (categoryId) {
      return {
        categories: oldState?.categories?.filter(
          (category) => category.id !== categoryId
        ),
      };
    }
  })
  .on(editProcedureInstructionsCategories, (oldState, editCategory) => {
    if (editCategory.title) {
      const editTitle = oldState?.categories?.map((category) => {
        if (category.id === editCategory.id) {
          return { ...category, title: editCategory.title };
        }
        return category;
      });
      return { categories: editTitle };
    }
  })
  .reset(resetAllStates);

export const setProcedureInstructionsStates = createEvent<EventStateStoreT>();
export const setProcedureInstructionsLoading = createEvent<boolean>();
export const setProcedureInstructionsError = createEvent<boolean>();
export const SELECTS_ID = 99999;

export const $ProcedureControlStates = createStore<EventStateStoreT>({
  isLoading: true,
  error: false,
  isFetched: false,
})
  .on(setProcedureInstructionsLoading, (oldState, newState) => ({
    ...oldState,
    isLoading: newState,
  }))
  .on(setProcedureInstructionsError, (oldState, newState) => ({
    ...oldState,
    isLoading: false,
    error: newState,
  }))
  .on(setProcedureInstructionsStates, (_, newStateTraining) => newStateTraining)
  .reset(resetAllStates);

// REQUESTS
export const FetchEmployeeInstructionsCategories = createEffect(
  (employeeId: number) => {
    setProcedureInstructionsLoading(true);
    ProcedureControlService.GetInstructionsControls(
      {
        employees: [employeeId],
      },
      (err, res) => {
        if (err || !res) {
          return setProcedureInstructionsStates({
            error: true,
            isFetched: false,
            isLoading: false,
          });
        }

        console.log(res.data);

        const categories: СategoriesT[] = res.data
          .filter((table) => table.body.length > 0)
          .map((table) => ({
            id: table.id,
            title: table.title,
            taskGroup: "",
            action: undefined,
          }));

        setProcedureInstructions({ categories });
        setProcedureInstructionsStates({
          error: false,
          isFetched: true,
          isLoading: false,
        });
      }
    );
  }
);

export const FetchAllCategoriesForInstructionsPrograms =
  createEffect<FetchProcedureControlPropsT>(({ isIncludeChanges = true }) => {
    setProcedureInstructionsLoading(true);
    ProcedureControlService.GetAllCategories(
      "briefing",
      isIncludeChanges,
      (err, res) => {
        if (err || !res) {
          setProcedureInstructionsStates({
            error: true,
            isFetched: false,
            isLoading: false,
          });
          return console.error("При получении данных произошла ошибка");
        }
        if (!res.data.length) {
          setProcedureInstructions({ categories: [] });
        }
        if (!!res.data.length) {
          res.data.unshift({
            id: SELECTS_ID,
            title: "Выбрать все",
            taskGroup: "",
          });
          const categories: СategoriesT[] = res.data.map((item) => {
            if (item?.change?.id && item?.change?.title) {
              return {
                id: item.change.id,
                title: item.change.title,
                action: item.change?.action || undefined,
                taskGroup: item.change?.taskGroup,
              };
            }
            if (item?.id && item?.title) {
              return {
                id: item.id,
                title: item.title,
                action: undefined,
                taskGroup: item.taskGroup,
              };
            }
            return {
              id: 100000,
              title: "",
              action: undefined,
              taskGroup: "",
            };
          });
          if (categories) setProcedureInstructions({ categories });
        }
        setProcedureInstructionsStates({
          error: false,
          isFetched: true,
          isLoading: false,
        });
      }
    );
  });

export const FetchAllInstructionsPrograms =
  createEffect<FetchProcedureTrainingProgramsT>(({ isIncludeChanges }) => {
    setProcedureInstructionsLoading(true);
    ProcedureService.Programs(
      "briefing",
      undefined,
      isIncludeChanges,
      (err, res) => {
        if (err || !res) {
          setProcedureInstructionsStates({
            error: true,
            isFetched: false,
            isLoading: false,
          });
          return console.error(
            "При получении данных Programs произошла ошибка"
          );
        }
        if (res.data) {
          if (!!res.data.length) {
            setProcedureInstructions({ programs: res.data });
          }
        }

        setProcedureInstructionsStates({
          error: false,
          isFetched: true,
          isLoading: false,
        });
      }
    );
  });
