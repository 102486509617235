import { UserRoleEnum } from "@store/user-store";

export const GetMaxQAs = (roleId: number) => {
  switch (roleId) {
    case UserRoleEnum.SuperAdmin:
      return 99999;
    case UserRoleEnum.Admin:
      return 1000;
    // eslint-disable-next-line no-duplicate-case
    case UserRoleEnum.Admin:
      return 100;
    case UserRoleEnum.Client:
      return 1000;
    default:
      return 10;
  }
};
