import { FC } from "react";

import clsx from "clsx";

import styles from "./ChatHeader.module.scss";
import { BackButton } from "@components/back-button";
import { BodyNormal } from "@ui/fonts";
import moment from "moment";
import { useStore } from "effector-react";
import { $DeviceType } from "@store/device-type-store";
import { FeedbackToggle } from "../";

type ChatHeaderPropsT = {
  className?: string;
  isFeedBackClosed?: boolean;
  closedAt?: string;
  createdAt?: string;
  feedBackId?: string;
  isClosed?: boolean;
  setIsClosed?: (closed: boolean) => void;
};

export const ChatHeader: FC<ChatHeaderPropsT> = ({
  className,
  createdAt,
  feedBackId,
  setIsClosed,
  isClosed,
}) => {
  const { isPhone } = useStore($DeviceType);

  return (
    <div
      className={clsx(
        styles.chatHeader__container,
        isPhone && styles.chatHeader__container_mobile,
        className
      )}
    >
      <div className={styles.chatHeader}>
        <BackButton link="/feedback" />
        <div className={styles.chatHeader__info}>
          {!isPhone && (
            <div className={styles.closedStatus}>
              {isClosed && (
                <div className={styles.closedStatus__message}>
                  <BodyNormal weight="bold">Обращение закрыто</BodyNormal>
                </div>
              )}
              <FeedbackToggle
                isClosed={isClosed}
                setIsClosed={setIsClosed}
                feedBackId={feedBackId}
              />
            </div>
          )}
          <div className={styles.date}>
            <BodyNormal weight="bold">Дата обращения:</BodyNormal>
            &nbsp;
            <BodyNormal>{moment(createdAt).format("LLL")}</BodyNormal>
          </div>
        </div>
      </div>
    </div>
  );
};
