import { FC } from "react";
import { NavLink } from "react-router-dom";
import { MedicalExamControls } from "feature/procedure-control/medical-exam-controls";
import styles from "@scss/pages/company/procedure-control.module.scss";

const ProcedureControlMedicalExams: FC = () => {
  return (
    <div className={styles.content}>
      <div className={styles.head}>
        <div className={styles.tabs}>
          <NavLink to="/company/procedure-control/training">
            Контроль обучения
          </NavLink>
          <NavLink to="/company/procedure-control/briefings">
            Контроль инструктажей
          </NavLink>
          <div className={styles.tabs_active}>
            Контроль медицинских осмотров
          </div>
        </div>
      </div>
      <MedicalExamControls />
    </div>
  );
};

export default ProcedureControlMedicalExams;
