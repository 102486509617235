import { FC, useEffect } from "react";
import { Modal } from "@ui/modal";
import { useForbiddenWarning } from "@store/forbidden-warning-store";
import styles from "@scss/modals/forbidden-warning-modal.module.scss";
import { useAuth } from "@store/auth-store";
import { useHistory } from "react-router-dom";
import { Button } from "@ui/button";

export const ForbiddenWarningModal: FC = () => {
  const isLoggedIn = useAuth();
  const [forbiddenWarning, set] = useForbiddenWarning();

  const history = useHistory();

  const isOpen = forbiddenWarning.message !== null;

  const handleGoBack = () => {
    history.goBack();
    set(null);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      set(null);
    }
  }, [isLoggedIn, set]);

  return (
    <Modal open={isOpen} onClose={() => set(null)}>
      <div className={styles.forbiddenWarningModal}>
        <h2 className={styles.forbiddenWarningModal__title}>
          {forbiddenWarning.message}
        </h2>
        <Button
          className={styles.forbiddenWarningModal__btn}
          onClick={handleGoBack}
        >
          Вернуться обратно
        </Button>
      </div>
    </Modal>
  );
};
