import {
  fetchSpecialistPlans,
  pushToTasks,
  updatePlanTask,
} from "@store/specialist-plan-store";
import { ComponentType, memo, useEffect, useState } from "react";

import SpecialistPlanService from "@services/specialist-plan-service";
import clsx from "clsx";
import moment from "moment";
import "moment/locale/ru";
import style from "@scss/modals/company/create-task.module.scss";
import useModal from "@modals/modal-hook";
import useStyles from "@ui/material-ui-styles";
import { FormProvider, useForm } from "react-hook-form";
import { TabSwicher } from "@ui/tab-swicher";
import { CreateTaskModalMainTab } from "./create-task-modal-main-tab";
import { CreateTaskModalEmployeesTab } from "./create-task-modal-employees-tab";
import { CreateTaskModalFilesTab } from "./create-task-modal-files-tab";
import { useFormDirtyFields } from "@hooks/useFormDirtyFields";
import { useSelectedCompanyQuery } from "@lib/company";

export type CreateTaskModalForm = {
  title: string;
  description: string;
  startDate: string;
  endDate: string;
  employees: number[];
  documents: number[];
};

enum Tab {
  Main,
  Employees,
  Files,
}

export type CreateTaskModalTabProps = {
  className?: string;
  touchField: (field: keyof CreateTaskModalForm) => void;
};

const formTabs: { [key in Tab]: ComponentType<CreateTaskModalTabProps> } = {
  [Tab.Main]: CreateTaskModalMainTab,
  [Tab.Employees]: CreateTaskModalEmployeesTab,
  [Tab.Files]: CreateTaskModalFilesTab,
};
const formTabLabels = ["Основное", "Сотрудники", "Документы"];

moment.locale("ru");

const getDefaultValues = (event?: any, isEditMode?: boolean) => {
  if (isEditMode) {
    return {
      title: event?.title ?? "",
      description: event?.desc ?? "",
      startDate: event?.startDate,
      endDate: event?.deadline,
      employees: event?.employees ?? [],
      documents: event?.documents ?? [],
    };
  }

  return {
    title: "",
    description: "",
    startDate: moment().add(1, "hour").minute(0).toISOString(),
    endDate: moment().add(2, "hours").minute(0).toISOString(),
    employees: [],
    documents: [],
  };
};

const CreateTaskModal: React.FC = memo(() => {
  const { modalComponent, modalData, close } = useModal();

  const { company } = useSelectedCompanyQuery();

  const form = useForm<CreateTaskModalForm>({
    defaultValues: getDefaultValues(modalData.event, modalData.editMode),
  });

  const { handleTouchField, isFieldTouched } =
    useFormDirtyFields<keyof CreateTaskModalForm>();

  const [tab, setTab] = useState<Tab>(Tab.Main);
  const FormTab = formTabs[tab];

  const classes = useStyles();

  useEffect(() => {
    if (!company) return;

    fetchSpecialistPlans({
      companies: [company.id],
      groups: null,
      statuses: null,
      startDate: null,
      endDate: null,
      count: true,
      offset: 0,
      limit: 1000,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const handleUpdate = (data: CreateTaskModalForm) => {
    if (!company) return;

    const eventNewData = {
      title: form.formState.touchedFields.title ? data.title : undefined,
      desc: form.formState.touchedFields.description
        ? data.description
        : undefined,
      startDate: isFieldTouched("startDate")
        ? new Date(data.startDate).getTime()
        : undefined,
      deadline: isFieldTouched("endDate")
        ? new Date(data.endDate).getTime()
        : undefined,
      employees: isFieldTouched("employees") ? data.employees : undefined,
      documents: isFieldTouched("documents") ? data.documents : undefined,
    };

    const parsedEventData = {
      ...eventNewData,
      employees: isFieldTouched("employees")
        ? eventNewData.employees
        : undefined,
      documents: isFieldTouched("documents")
        ? eventNewData.documents
        : undefined,
    };

    SpecialistPlanService.updateTask(
      company.id,
      modalData.event.eventId,
      parsedEventData,
      (err, res) => {
        if (err || !res) {
          return console.error("При изменении задачи произошла ошибка");
        }
        updatePlanTask(res.data);
        close();
      }
    );
  };

  const handleCreate = (data: CreateTaskModalForm) => {
    if (!company) return;

    const eventNewData = {
      title: data.title,
      desc: data.description,
      periodicity: modalData.event ? modalData.event.periodicity : 1,
      savedToCalendar: modalData.event
        ? modalData.event?.savedToCalendar ?? false
        : false,
      status: modalData.event ? modalData.event.status : 1,
      groupId: undefined,
      parentId: modalData.parentId || undefined,
      startDate: new Date(data.startDate).getTime(),
      deadline: new Date(data.endDate).getTime(),
      withSigning: 1,
      employees: data.employees,
      documents: data.documents,
    };

    SpecialistPlanService.createTask(company.id, eventNewData, (err, res) => {
      if (err || !res) {
        return console.error("При создании события произошла ошибка");
      }
      pushToTasks(res.data);
      close();
    });
  };

  const onSubmit = form.handleSubmit((data) => {
    const handle = modalData.editMode ? handleUpdate : handleCreate;
    handle(data);
  });

  return (
    <div key={modalComponent.key} className={clsx(style.createTaskModal)}>
      <p className="modal_title">{modalData.modalTitle}</p>
      <div className="underline" />
      <div className={style.createTaskModal__tabs}>
        <TabSwicher value={tab} onChange={setTab} labels={formTabLabels} />
      </div>
      {/* @ts-ignore something with type inference as i understand. see https://github.com/microsoft/TypeScript/issues/34933  */}
      <FormProvider {...form}>
        <form
          className={`modal_content ${classes.root} ${style.createTaskModal__form}`}
          onSubmit={onSubmit}
        >
          <FormTab
            className={style.createTaskModal__tab}
            touchField={handleTouchField}
          />
          <button
            type="submit"
            className={clsx("modal_btn", style.createTaskModal__submitBtn)}
          >
            {modalComponent.btnText}
          </button>
        </form>
      </FormProvider>
    </div>
  );
});

export default CreateTaskModal;
