import { MedicalExaminationService } from "@services/medical-examination.service";
import { useQuery } from "react-query";
import { medicalExaminationQueryKeys } from "./queryKeys";

export const useMedicalExaminationHasChangesQuery = () => {
  return useQuery(
    medicalExaminationQueryKeys.hasChanges(),
    MedicalExaminationService.getHasAnyChanges
  );
};
