export const AttentionSVG = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="8" fill="#E24826" />
      <path
        d="M7.62909 9.426L7.48609 4.512L7.46009 3.29H8.53909L8.51309 4.512L8.37009 9.426H7.62909ZM8.00609 12.156C7.78943 12.156 7.60309 12.0823 7.44709 11.935C7.29976 11.779 7.22609 11.584 7.22609 11.35C7.22609 11.0987 7.29976 10.8993 7.44709 10.752C7.60309 10.596 7.78943 10.518 8.00609 10.518C8.21409 10.518 8.39176 10.596 8.53909 10.752C8.69509 10.8993 8.77309 11.0987 8.77309 11.35C8.77309 11.584 8.69509 11.779 8.53909 11.935C8.39176 12.0823 8.21409 12.156 8.00609 12.156Z"
        fill="white"
      />
    </svg>
  );
};
