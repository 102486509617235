import { BodyBold, Subtitle } from "@ui/fonts";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import React, { useState } from "react";

import { $UserAddPermissions } from "@store/user-store";
import { ACTIONS } from "@store/user-store";
import { AddRowTable } from "../add-row-table";
import { Button } from "@ui/button";
import { DeleteCrossSVG } from "@components/svgs/delete-cross";
import { EditNameCategory } from "../edit-name-category/edit-name-category";
import { PencilSVG } from "@components/svgs";
import ProcedureService from "@services/programs-service";
import { ProgramTableT } from "@interfaces/directory/version";
import { TableBodyRowProgram } from "../table-body-row-program";
import clsx from "clsx";
import { removeDirectoryProgramRowInstructionsTable } from "@store/directory-programm-instuctions";
import { removeDirectoryProgramRowTrainingTable } from "@store/directory-programm-training";
import styles from "./table-programm.module.scss";
import { switchColor } from "@utils/switch-color";
import { useChoiceState } from "@hooks/useChoiceState";
import { useStore } from "effector-react";
import { v4 as uuidv4 } from "uuid";

type TableProgrammProps = {
  type: "training" | "briefing" | "medical-examination";
  action?: "Добавлено" | "Изменено" | "Удалено";
  head: {
    id: string;
    title: string;
  }[];
};

export const TableProgramm: React.FC<ProgramTableT & TableProgrammProps> = ({
  type,
  id,
  title,
  head,
  change,
  programs,
  action,
}) => {
  const [editName, setEditName] = useState<boolean>(false);
  const [stateModalAddProgramm, setStateModalAddProgramm] =
    useState<boolean>(false);

  const {
    selectedItems,
    areAllItemsSelected,
    selectAll,
    selectById,
    getIsItemSelected,
  } = useChoiceState(
    programs ?? [],
    (program) => program.change?.id ?? program.id
  );

  const permissions = useStore($UserAddPermissions);

  const canSeeChanges = permissions.hasPermission([
    ACTIONS.directories_allowedToCreate,
    ACTIONS.directories_allowedToEdit,
    ACTIONS.directories_allowedToDelete,
    ACTIONS.directories_allowedToConfirmChanges,
    ACTIONS.directories_allowedToReverseChanges,
  ]);

  const handleDeleteProgram = async (programId: number) => {
    return new Promise((resolve, reject) => {
      ProcedureService.DeleteProgramRowTable(programId, type, (err, res) => {
        if (err || !res) {
          reject(err);
          return Error(
            "При ркдактировании программы обучения произошла ошибка"
          );
        }

        resolve(res.data);
        if (res.data) {
          if (type === "training") {
            removeDirectoryProgramRowTrainingTable({ programs: res.data });
          }
          if (type === "briefing") {
            removeDirectoryProgramRowInstructionsTable({
              programs: res.data,
            });
          }
        }
      });
    });
  };

  const handleDeleteSelectedPrograms = async () => {
    const promises = [];
    for (const program of selectedItems) {
      promises.push(handleDeleteProgram(program.change?.id ?? program.id));
    }

    await Promise.all(promises);
  };

  const isDeleteTable = () =>
    change?.action !== "Удалено" && action !== "Удалено";

  return (
    <div className={styles.wrapper}>
      <div className={styles.contain_title}>
        <div className={styles.contain_title__left}>
          <Checkbox
            color="primary"
            checked={areAllItemsSelected}
            onChange={(e) => selectAll(e.target.checked)}
          />
          {editName ? (
            <EditNameCategory
              title={title}
              id={change?.id ?? id}
              changeTitle={change?.title}
              onClose={() => setEditName(false)}
              type={type}
            />
          ) : (
            <div className={styles.title}>
              <div
                className={clsx(
                  change?.action ? styles[switchColor(change?.action)] : "",
                  action ? styles[switchColor(action)] : ""
                )}
              >
                <Subtitle weight="bold">{change?.title ?? title}</Subtitle>
              </div>
              {isDeleteTable() && (
                <>
                  {permissions.hasPermission(
                    ACTIONS.directories_allowedToEdit
                  ) && (
                    <Tooltip title="Изменить" placement={"top"}>
                      <button onClick={() => setEditName(true)}>
                        <PencilSVG />
                      </button>
                    </Tooltip>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {selectedItems.length > 0 &&
          permissions.hasPermission(ACTIONS.directories_allowedToDelete) && (
            <Tooltip title="Удалить" placement={"top"}>
              <button
                tabIndex={0}
                className={styles.contain_title__right}
                title="Удалить выбранные программы"
                onClick={handleDeleteSelectedPrograms}
              >
                <DeleteCrossSVG size={18} />
              </button>
            </Tooltip>
          )}
      </div>
      <div className={styles.table}>
        <Table className={styles.table_wrapper} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {head.map((item) => (
                <TableCell
                  key={uuidv4()}
                  align="right"
                  className={styles.table_head}
                >
                  <div className={styles.table_head_wrapper}>
                    <div className={styles.table_head_name}>{item.title}</div>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!!programs &&
              programs.map((item, index: number) => {
                if (item.change && !canSeeChanges) return null;

                return (
                  <TableBodyRowProgram
                    key={uuidv4()}
                    program={item}
                    isSelected={getIsItemSelected(item)}
                    index={index}
                    type={type}
                    tableDelete={!isDeleteTable()}
                    onSelect={(_, checked) => selectById(item, checked)}
                  />
                );
              })}
          </TableBody>
        </Table>
      </div>

      {isDeleteTable() && (
        <div className={styles.add_programm}>
          {permissions.hasPermission(ACTIONS.directories_allowedToCreate) && (
            <Button
              color={"white-green"}
              borderColor={"green"}
              onClick={() => setStateModalAddProgramm(true)}
            >
              <BodyBold>+ Добавить программу</BodyBold>
            </Button>
          )}
        </div>
      )}
      <AddRowTable
        id={id}
        type={type}
        title={title}
        changeId={change?.id}
        changeTitle={change?.title}
        changeAction={change?.action}
        open={stateModalAddProgramm}
        onClose={() => setStateModalAddProgramm(false)}
      />
    </div>
  );
};
