import { LearningComponents } from "@feature";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { memo } from "react";
import style from "@scss/pages/company/procedure-control.module.scss";
import { useSelectedCompanyQuery } from "@lib/company";

const ProcedureControlTraning = memo(() => {
  const { company } = useSelectedCompanyQuery();

  return (
    <div className={clsx(style.content)}>
      <div className={style.head}>
        <div className={style.tabs}>
          <div className={style.tabs_active}>Контроль обучения</div>
          <Link to={"/company/procedure-control/briefings"}>
            Контроль инструктажей
          </Link>
          <Link to="/company/procedure-control/medical-exams">
            Контроль медицинских осмотров
          </Link>
        </div>
      </div>
      <div className={style.modules_table}>
        <LearningComponents
          ignoredKeys={["companyName"]}
          defaultFilters={{
            companies: company?.id ? [company?.id] : undefined,
            isArchived: false,
            isConfirmed: true,
            isRequired: true,
            isShowOneTime: false,
          }}
        />
      </div>
    </div>
  );
});

export default ProcedureControlTraning;
