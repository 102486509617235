import { WorkspaceGetFilesParams } from "@interfaces/company/workspace";
import { documentTemplateService } from "@services/document-templates-service";
import { useQuery } from "react-query";
import { documentTemplatesQueryKeys } from "./queryKeys";

export const useArchivedDocumentTemplatesFilesQuery = (
  groupId: number,
  params?: WorkspaceGetFilesParams
) => {
  const { data: files, ...stuff } = useQuery(
    documentTemplatesQueryKeys.archivedFiles(groupId, params),
    () =>
      documentTemplateService.getisArchivedDirectoriesAndFiles(groupId, params)
  );

  return {
    files,
    ...stuff,
  };
};
