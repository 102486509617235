import { memo, useEffect, useMemo, useState } from "react";

import { AddCatedory } from "../add-catedory/add-catedory";
import { BodyNormal } from "@ui/fonts";
import { Button } from "@ui/button";
import { DirectoryVersion } from "../directory-version";
import { OnSaveAll } from "../on-save-all";
import styles from "./medical-examination-directory.module.scss";
import {
  useMedicalExaminationFactorsQuery,
  useMedicalExaminationHasChangesQuery,
} from "@lib/medical-examination";
import { MedicalExaminationCaterogy } from "./medical-examination-category";
import { useQueryClient } from "react-query";
import { medicalExaminationQueryKeys } from "@lib/medical-examination/queryKeys";
import { DropDownMultiSelect } from "@ui/drop-down";

type SelectListType = {
  id: number;
  title: string;
};

export const MedicalExaminationDirectory: React.FC = memo(() => {
  const queryClient = useQueryClient();

  const { data: categories } = useMedicalExaminationFactorsQuery({
    isIncludeChanges: true,
  });
  const { data: hasChanges } = useMedicalExaminationHasChangesQuery();

  const [openVersionLog, setOpenVersionLog] = useState(false);
  const [categoriesList, setCategoriesList] = useState<SelectListType[]>([]);
  const [categoriesOtherList, setCategoriesOtherList] = useState<
    SelectListType[]
  >([]);
  const [selectedCategory, setSelectedCategory] = useState<number>();

  const filteredCategories = useMemo<typeof categories>(() => {
    if (typeof selectedCategory !== "undefined") {
      return categories?.filter(
        (category) =>
          category.change?.id === selectedCategory ||
          category.id === selectedCategory
      );
    }

    return categories?.filter(
      (category) =>
        !!categoriesOtherList.find(
          (c) => c.id === category.change?.id || c.id === category.id
        )
    );
  }, [categories, selectedCategory, categoriesOtherList]);

  const handleInvalidateQueries = () => {
    queryClient.invalidateQueries(medicalExaminationQueryKeys.all);
  };

  const handleCategoriesChange = (values: SelectListType[]) => {
    setCategoriesOtherList(values);
  };

  const handleAddCategoryChange = (value: number) => {
    setSelectedCategory(value !== 0 ? value : undefined);
  };

  useEffect(() => {
    if (!categories) return;

    const list = categories.map((category) => ({
      id: category.change?.id ?? category.id ?? -1,
      title: category.change?.title ?? category.title ?? "",
    }));
    setCategoriesList([{ id: -1, title: "Выбрать все" }, ...list]);
    setCategoriesOtherList(list);
  }, [categories]);

  return (
    <div className={styles.content}>
      {openVersionLog ? (
        <DirectoryVersion
          updateCategory={handleInvalidateQueries}
          onClose={() => setOpenVersionLog(false)}
          type="medical-examination"
        />
      ) : (
        <>
          <div className={styles.head}>
            <div className={styles.head_direction}>
              <AddCatedory
                list={categoriesOtherList}
                type="medical-examination"
                handlerProgramm={handleAddCategoryChange}
                updateCategories={handleInvalidateQueries}
                withTaskGroup={false}
              />
            </div>
            <div className={styles.head_right}>
              <Button
                onClick={() => setOpenVersionLog(true)}
                className={styles.button_next}
              >
                <BodyNormal>Журнал версий справочника</BodyNormal>
              </Button>
            </div>
          </div>
          <div className={styles.head_select}>
            <DropDownMultiSelect
              title="Все категории"
              direction={categoriesList}
              handlerList={handleCategoriesChange}
              allId={-1}
            />
          </div>
          <div className={styles.tables}>
            {filteredCategories?.map((category) => (
              <MedicalExaminationCaterogy
                key={category.change?.id ?? category.id}
                category={category}
                factors={category.factors}
              />
            ))}
          </div>
          {hasChanges && (
            <OnSaveAll
              type="medical-examination"
              updateCategory={handleInvalidateQueries}
              closeDownSave={handleInvalidateQueries}
            />
          )}
        </>
      )}
    </div>
  );
});
