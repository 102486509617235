import { createEffect, createEvent, createStore } from "effector";

import EmployeeService from "@services/employee-service";
// STORE
import { resetAllStates } from "@store/user-store";

import { EmployeeListDataT } from "@interfaces/company/employees";
import { EventStateStoreT } from "@interfaces/common";

// EMPLOYEES DATA
export const setEmployees = createEvent<EmployeeListDataT[]>();
export const pushToEmployees = createEvent<EmployeeListDataT>();

export const $Employees = createStore<EmployeeListDataT[]>([])
  .on(setEmployees, (_, newState) => newState)
  .on(pushToEmployees, (oldState, newState) => {
    const index = oldState.findIndex((item) => item.id === newState.id);

    if (index === -1) {
      return [...oldState, newState];
    }
    return oldState.map((item) => {
      if (item.id === newState.id) {
        return { ...item, ...newState };
      }
      return item;
    });
  })
  .reset(resetAllStates);

// EMPLOYEES DATA STATES
export const setEmployeesLoading = createEvent<boolean>();
export const setEmployeesFetched = createEvent<boolean>();
export const setEmployeesError = createEvent<boolean>();
export const setEmployeesStates = createEvent<EventStateStoreT>();

export const $EmployeesStates = createStore<EventStateStoreT>({
  isLoading: true,
  isFetched: false,
  error: false,
})
  .on(setEmployeesLoading, (oldState, newState) => ({
    ...oldState,
    isLoading: newState,
  }))
  .on(setEmployeesFetched, (oldState, newState) => ({
    ...oldState,
    isFetched: newState,
  }))
  .on(setEmployeesError, (oldState, newState) => ({
    ...oldState,
    isLoading: false,
    error: newState,
  }))
  .on(setEmployeesStates, (_, newState) => newState)
  .reset(resetAllStates);

export const fetchEmployees = createEffect(() => {
  setEmployeesLoading(true);

  EmployeeService.getAll(false, (err, res) => {
    if (err || !res) {
      return setEmployeesError(true);
    }
    setEmployees(res.data);
    setEmployeesStates({
      isLoading: false,
      error: false,
      isFetched: true,
    });
  });
});
