import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { BodyLight, BodyNormal } from "@ui/fonts";
import {
  editDirectoryProgramRowInstructionsTable,
  removeDirectoryProgramRowInstructionsTable,
} from "@store/directory-programm-instuctions";
import {
  editDirectoryProgramRowTrainingTable,
  removeDirectoryProgramRowTrainingTable,
} from "@store/directory-programm-training";

import { DeleteCrossSVG } from "@components/svgs/delete-cross";
import { PencilSVG } from "@components/svgs";
import ProcedureService from "@services/programs-service";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Checkbox, TextField, Tooltip } from "@material-ui/core";
import clsx from "clsx";
import styles from "./table-body-row-program.module.scss";
import { switchColor } from "@utils/switch-color";
import { useState } from "react";
import { useStore } from "effector-react";

type TableBodyRowProps = {
  type: "training" | "briefing" | "medical-examination";
  index: number;
  isSelected: boolean;
  tableDelete?: boolean;
  program: {
    categoryId: number;
    id: number;
    period: number;
    termPrimaryConducting: number;
    title: string;
    change?: {
      action: "Добавлено" | "Изменено" | "Удалено";
      id: number;
      title: string;
      termPrimaryConducting: number;
      period: number;
      categoryId: number;
    };
  };
  onSelect: (programId: number, checked: boolean) => void;
};
type InputRows = {
  title: string;
  termPrimaryConducting: number | null;
  period: number | null;
};

export const TableBodyRowProgram: React.FC<TableBodyRowProps> = ({
  program,
  isSelected,
  index,
  type,
  tableDelete = false,
  onSelect,
}) => {
  const permissions = useStore($UserAddPermissions);
  const [editRow, setEditRow] = useState<boolean>(false);
  const [value, setValue] = useState<InputRows>({
    title: program?.change?.title || program?.title || "",
    termPrimaryConducting:
      program?.change?.termPrimaryConducting ||
      program?.termPrimaryConducting ||
      0,
    period: program?.change?.period || program?.period || 0,
  });

  const onChangeRow: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    let inputValue: string | number | null = target.value;
    if (target.type === "number") {
      inputValue = parseInt(inputValue, 10);
      if (isNaN(inputValue)) {
        inputValue = "";
      } else if (inputValue > 9999) {
        return inputValue;
      }
    }

    setValue((prevState) => ({
      ...prevState,
      [target.name]: inputValue,
    }));
  };

  const onSubmitSave = () => {
    if (value.title.trim() && (program?.id || program?.change?.id)) {
      ProcedureService.EditRowTableProgram(
        program?.change?.id ?? program?.id,
        program?.change?.categoryId ?? program?.categoryId,
        type,
        value,
        (err, res) => {
          if (err || !res) {
            return Error(
              "При ркдактировании программы обучения произошла ошибка"
            );
          }
          if (res.data) {
            setEditRow(false);
            if (type === "training") {
              editDirectoryProgramRowTrainingTable({ programs: res.data });
            }
            if (type === "briefing") {
              editDirectoryProgramRowInstructionsTable({ programs: res.data });
            }
          }
        }
      );
    }
  };

  const onSubmitDelete = () => {
    if (program?.change?.id ?? program?.id) {
      ProcedureService.DeleteProgramRowTable(
        program?.change?.id ?? program?.id,
        type,
        (err, res) => {
          if (err || !res) {
            return Error(
              "При ркдактировании программы обучения произошла ошибка"
            );
          }
          if (res.data) {
            if (type === "training") {
              removeDirectoryProgramRowTrainingTable({ programs: res.data });
            }
            if (type === "briefing") {
              removeDirectoryProgramRowInstructionsTable({
                programs: res.data,
              });
            }
          }
        }
      );
    }
  };

  return (
    <>
      {!!program && (
        <TableRow
          className={clsx(
            program?.change?.action
              ? styles[switchColor(program.change.action)]
              : "",
            tableDelete && styles["DELETE"]
          )}
        >
          <TableCell align="left">
            <Checkbox
              color="primary"
              checked={isSelected}
              onChange={(e) =>
                onSelect(program.change?.id ?? program.id, e.target.checked)
              }
            />
          </TableCell>
          <TableCell align="left">
            <div
              className={clsx(
                styles.table_row_center,
                styles.table_row_vertical
              )}
            >
              <BodyLight>{++index}</BodyLight>
            </div>
          </TableCell>
          <TableCell align="left">
            <div
              className={clsx(styles.table_row_center, styles.table_row_title)}
            >
              {editRow ? (
                <div
                  className={clsx(
                    styles.table_row_center,
                    styles.table_row_input,
                    styles.table_row_vertical
                  )}
                >
                  <TextField
                    required
                    autoFocus
                    multiline
                    rows={3}
                    name={"title"}
                    type={"text"}
                    style={{ width: "100%", backgroundColor: "white" }}
                    onChange={onChangeRow}
                    placeholder={
                      type === "training"
                        ? "Наименование программы..."
                        : "Вид инструктажа..."
                    }
                    value={value?.title ?? ""}
                  />
                </div>
              ) : (
                <BodyLight>
                  {program?.change?.title ?? program?.title}
                </BodyLight>
              )}
            </div>
          </TableCell>
          <TableCell align="left">
            <div
              className={clsx(
                styles.table_row_center,
                styles.table_row_vertical
              )}
            >
              {editRow ? (
                <div className={styles.table_row_input}>
                  <TextField
                    style={{ width: 200 }}
                    onChange={onChangeRow}
                    inputProps={{
                      max: 9999,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    type={"number"}
                    name={"termPrimaryConducting"}
                    placeholder={
                      type === "training"
                        ? "Первичное обучение..."
                        : "Срок проведения..."
                    }
                    value={value?.termPrimaryConducting ?? ""}
                  />
                </div>
              ) : (
                <BodyLight>
                  {program?.change?.termPrimaryConducting ??
                    program?.termPrimaryConducting}
                </BodyLight>
              )}
            </div>
          </TableCell>
          <TableCell align="left">
            <div
              className={clsx(
                styles.table_row_center,
                styles.table_row_vertical
              )}
            >
              {editRow ? (
                <div className={styles.table_row_input}>
                  <TextField
                    style={{ width: 200 }}
                    onChange={onChangeRow}
                    inputProps={{
                      max: 9999,
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                    }}
                    type={"number"}
                    name={"period"}
                    placeholder={
                      type === "training"
                        ? "Повторное обучение..."
                        : "Периодичность..."
                    }
                    value={value?.period ?? ""}
                  />
                </div>
              ) : (
                <BodyLight>
                  {program?.change?.period ?? program.period}
                </BodyLight>
              )}
            </div>
          </TableCell>
          <TableCell align="left">
            {program?.change?.action !== "Удалено" && !tableDelete && (
              <>
                {editRow ? (
                  <div className={styles.table_row_center}>
                    <div
                      className={styles.table_row_save}
                      onClick={onSubmitSave}
                    >
                      <BodyNormal
                        weight="bold"
                        color={
                          program?.change?.action === "Добавлено"
                            ? "green-darker"
                            : "green"
                        }
                      >
                        Сохранить
                      </BodyNormal>
                    </div>
                  </div>
                ) : (
                  <>
                    {permissions.hasPermission(
                      ACTIONS.directories_allowedToEdit
                    ) && (
                      <Tooltip title="Изменить" placement={"top"}>
                        <button
                          className={clsx(
                            styles.table_row_center,
                            styles.table_row_edit
                          )}
                          onClick={() => {
                            setEditRow(true);
                          }}
                        >
                          <PencilSVG
                            width="15"
                            color={
                              program?.change?.action === "Добавлено"
                                ? "green-darker"
                                : "green"
                            }
                          />
                        </button>
                      </Tooltip>
                    )}
                  </>
                )}
              </>
            )}
          </TableCell>
          <TableCell align="left">
            {program?.change?.action !== "Удалено" &&
              !tableDelete &&
              !editRow && (
                <>
                  {permissions.hasPermission(
                    ACTIONS.directories_allowedToDelete
                  ) && (
                    <Tooltip title="Удалить" placement={"top"}>
                      <button
                        className={clsx(
                          styles.table_row_center,
                          styles.table_row_edit
                        )}
                        onClick={onSubmitDelete}
                      >
                        <DeleteCrossSVG />
                      </button>
                    </Tooltip>
                  )}
                </>
              )}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
