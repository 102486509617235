import {
  $RolesPermissions,
  $RolesPermissionsStates,
  $User,
  $UserAddPermissions,
  ACTIONS,
  UserPriorityEnum,
  changeRolePermission,
  fetchRolesPermissions,
  getPermissionName,
} from "@store/user-store";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { CheckboxCheckedSVG, CheckboxSVG } from "@components/svgs";
import { ErrorIndicator, Loader } from "@ui/indicators";
import { Fragment, memo, useEffect, useMemo, useState } from "react";

import { ColorfulButton } from "@components/common/common";
import RolesPermissionsService from "@services/roles-permissions-service";
import { UserDataT } from "@interfaces/user";
import clsx from "clsx";
import s from "@scss/pages/permissions.module.scss";
import { useLocation } from "react-router-dom";
import { useModal } from "@modals/index";
import { useStore } from "effector-react";
import { useSelectedCompanyQuery } from "@lib/company";

export const PermissionsRoles = memo(() => {
  const rolesNPermissions = useStore($RolesPermissions);
  const { isFetched, isLoading, error, isFetchedCompany } = useStore(
    $RolesPermissionsStates
  );
  const [selectedRole, setSelectedRole] = useState<any>(null);
  const permissions = useStore($UserAddPermissions);
  const user = useStore($User) as UserDataT;
  const location = useLocation();
  const IS_COMPANY_MODULE = location.pathname.includes("company");

  const { company } = useSelectedCompanyQuery();

  useEffect(() => {
    if (!selectedRole && rolesNPermissions?.roles[0]) {
      setSelectedRole({
        ...rolesNPermissions.roles[0],
        permissions: rolesNPermissions.roles[0].permissions.map(
          (perm: any) => perm.action_name
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesNPermissions]);

  useEffect(() => {
    if (!company) return;

    const doesNeedToFetch =
      (!isFetchedCompany && !isLoading && IS_COMPANY_MODULE) ||
      (!isFetched && !isLoading && !IS_COMPANY_MODULE);

    if (doesNeedToFetch) {
      fetchRolesPermissions(
        IS_COMPANY_MODULE
          ? {
              exactCompany: true,
              companyId: company.id,
              priority: user.role.priority,
            }
          : { priority: user.role.priority }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const rolesToRender = useMemo(() => {
    if (!rolesNPermissions) return null;
    const rolesByPriority: any = [];
    rolesNPermissions.roles.forEach((role) => {
      if (!rolesByPriority[role.priority]) rolesByPriority[role.priority] = [];
      rolesByPriority[role.priority].push(role);
    });
    return rolesByPriority.map((rolesGrop: any, index: number) => {
      return (
        <Fragment key={index}>
          <p className={s.level}>{index} уровень:</p>
          {rolesGrop.map((role: any) => {
            return (
              <button
                key={role.id}
                className={clsx(s.role, {
                  [s.role_selected]: role?.name === selectedRole?.name,
                })}
                onClick={() => changeRole(role)}
              >
                {role.name}
              </button>
            );
          })}
        </Fragment>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesNPermissions, rolesNPermissions?.roles.length, selectedRole]);

  const { open } = useModal();

  if (error) return <ErrorIndicator />;
  if (isLoading) return <Loader />;
  if (
    (!isFetched && !IS_COMPANY_MODULE) ||
    (!isFetchedCompany && IS_COMPANY_MODULE)
  )
    return <Loader />;

  const changeRole = (role: any) => {
    setSelectedRole({
      ...role,
      permissions: role.permissions.map((perm: any) => perm.action_name),
    });
  };

  const changePermission = (permission: any) => {
    changeRolePermission({
      roleId: selectedRole.id,
      permission: permission,
    });
    RolesPermissionsService.ChangeRolePermissions(
      {
        roleId: selectedRole.id,
        permissionId: permission.id,
      },
      (err, res) => {
        if (res) {
          const existingPermission = selectedRole.permissions.find(
            (perm: any) => perm === permission.action_name
          );

          if (existingPermission) {
            const filteredPermissions = selectedRole.permissions.filter(
              (perm: any) => perm !== existingPermission
            );
            return setSelectedRole((prev: any) => ({
              ...prev,
              permissions: filteredPermissions,
            }));
          } else {
            return setSelectedRole((prev: any) => ({
              ...prev,
              permissions: [...prev.permissions, permission.action_name],
            }));
          }
        }
      }
    );
  };

  return (
    <>
      {(permissions.hasRequiredLevel(UserPriorityEnum.Admin) ||
        permissions.hasPermission(ACTIONS.roles_allowedToShow)) && (
        <div className={s.content}>
          <div className={s.roles}>
            <ColorfulButton
              text={"Создать роль"}
              onClick={() =>
                open("CreateRoleModal", {
                  modalData: {
                    text: ``,
                    modalTitle: "Создание роли",
                    companyId: company?.id,
                  },
                  btnText: "Создать",
                })
              }
            />
            {rolesToRender}
          </div>
          <div className={s.devider}></div>
          <div className={s.permissions}>
            <p className="font-xl">
              {selectedRole?.name ? selectedRole.name : ""}
            </p>
            {selectedRole &&
              rolesNPermissions?.permissions.map(
                (permission: any, index: number) => {
                  if (
                    !permissions.hasRequiredLevel(
                      UserPriorityEnum.SuperAdmin
                    ) &&
                    [
                      ACTIONS.roles_levelFirstRoles_allowedToCreate,
                      ACTIONS.roles_levelFirstRoles_allowedToEdit,
                      ACTIONS.roles_levelFirstRoles_allowedToAssign,
                    ].includes(permission.action_name)
                  )
                    return null;
                  if (
                    !permissions.hasRequiredLevel(UserPriorityEnum.Admin) &&
                    [
                      ACTIONS.roles_levelFirstRoles_allowedToCreate,
                      ACTIONS.roles_levelFirstRoles_allowedToEdit,
                      ACTIONS.roles_levelFirstRoles_allowedToAssign,
                      ACTIONS.roles_levelSecondRoles_allowedToCreate,
                      ACTIONS.roles_levelSecondRoles_allowedToEdit,
                      ACTIONS.roles_levelSecondRoles_allowedToAssign,
                    ].includes(permission.action_name)
                  )
                    return null;

                  const included = selectedRole?.permissions[0]
                    ? selectedRole?.permissions.includes(permission.action_name)
                    : false;
                  let title: string | null = null;
                  switch (permission.id) {
                    case 1:
                      title = "Личный кабинет";
                      break;
                    case 101:
                      title = "Пользователи";
                      break;
                    case 201:
                      title = "Роли";
                      break;
                    case 301:
                      title = "Компании";
                      break;
                    case 401:
                      title = "Компания - рабочее пространство";
                      break;
                    case 451:
                      title = "Шаблоны документов";
                      break;
                    case 501:
                      title = "Компания - штатное расписание";
                      break;
                    case 601:
                      title = "Компания - календарь событий";
                      break;
                    case 701:
                      title = "Компания - план работы специалиста";
                      break;
                    case 801:
                      title = "Компания - контроль подписания документов";
                      break;
                    case 901:
                      title = "Компания - предписания";
                      break;
                    case 1001:
                      title = "Компания - контроль выполнения процедур";
                      break;
                    case 1101:
                      title = "Компания - заявки на обучение";
                      break;
                    case 1150:
                      title = "Новости проекта";
                      break;
                    case 1201:
                      title = "Учебный центр";
                      break;
                    case 1250:
                      title = "Скоро в Личном кабинете";
                      break;
                    case 1301:
                      title = "Новости законодательства";
                      break;
                    case 1401:
                      title = "Полезные материалы";
                      break;
                    case 1501:
                      title = "Ответственность";
                      break;
                    case 1601:
                      title = "Юридическая информация";
                      break;
                    case 1701:
                      title = "Судебная практика";
                      break;
                    case 1801:
                      title = "Оплата";
                      break;
                    case 1901:
                      title = "Помощь специалиста";
                      break;
                    case 2001:
                      title = "Справочники";
                      break;
                    case 2101:
                      title = "Техподдержка";
                      break;
                  }
                  return (
                    <div key={permission.id}>
                      {title && (
                        <div className="mt-30 mb-5 font-lg">{title}:</div>
                      )}
                      <FormControlLabel
                        key={index * Math.random()}
                        control={
                          <Checkbox
                            checked={included}
                            name="isFinancial"
                            color="primary"
                            icon={<CheckboxSVG />}
                            checkedIcon={<CheckboxCheckedSVG />}
                            onChange={() => changePermission(permission)}
                          />
                        }
                        label={getPermissionName(permission.action_name)}
                      />
                      {/* <span>123</span> */}
                    </div>
                  );
                }
              )}
          </div>
        </div>
      )}
    </>
  );
});
