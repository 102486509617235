import { InstructionsComponents } from "@feature";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { memo } from "react";
import style from "@scss/pages/company/procedure-control.module.scss";
import { useSelectedCompanyQuery } from "@lib/company";

const ProcedureControlBriefings = memo(() => {
  const { company } = useSelectedCompanyQuery();

  return (
    <div className={clsx(style.content)}>
      <div className={style.head}>
        <div className={style.tabs}>
          <NavLink to={"/company/procedure-control/training"}>
            Контроль обучения
          </NavLink>
          <div className={style.tabs_active}>Контроль инструктажей</div>
          <NavLink to="/company/procedure-control/medical-exams">
            Контроль медицинских осмотров
          </NavLink>
        </div>
      </div>
      <div className={style.modules_table}>
        <InstructionsComponents
          ignoredKeys={["companyName"]}
          defaultFilters={{
            companies: company?.id ? [company?.id] : undefined,
            isArchived: false,
            isConfirmed: true,
            isRequired: true,
            isShowOneTime: false,
          }}
        />
      </div>
    </div>
  );
});

export default ProcedureControlBriefings;
