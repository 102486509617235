import { SignatureStatusEnum } from "./company/departments";
import { SortingType } from "./sorting";

export const signingControlJournalSortingKeys = [
  "signedAtOrder",
  "filenameOrder",
  "positionNameOrder",
  "employeeNameOrder",
  "departmentTitleOrder",
];

export type SigningControlJournalParams = {
  companyId: number;
  limit?: number;
  offset?: number;
  signedStart?: string;
  signedEnd?: string;
  departmentsId?: number[];
  positionsId?: number[];
  employeesId?: number[];
  documentsId?: number[];
  status?: number;
  signingsId?: number[];
  signedAtOrder?: SortingType;
  filenameOrder?: SortingType;
  positionNameOrder?: SortingType;
  employeeNameOrder?: SortingType;
  departmentTitleOrder?: SortingType;
};

export type SigningControlJournalRow = {
  id: number;
  status: SignatureStatusEnum;
  signedAt: string | null;
  file: {
    id: number;
    name: string;
  };
  signer: {
    id: number;
    name: string;
    position: {
      id: number;
      title: string;
      parentId: number | null;
      department: {
        id: number;
        title: string;
      };
    };
  };
};

export type SigningControlJournal = {
  count: number;
  rows: SigningControlJournalRow[];
};
