export const downloadFile = (src: string, filename: string) => {
  const link = document.createElement("a");
  link.href = src;
  link.download = filename;

  link.style.display = "hidden";

  document.body.appendChild(link);
  link.click();
  link.remove();
};
