export const EditSVG = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0117 3.03906L19.6855 2.3457C20.0664 1.95508 20.0762 1.41797 19.7051 1.05664L19.4512 0.792969C19.1191 0.470703 18.5625 0.519531 18.2109 0.871094L17.5176 1.55469L19.0117 3.03906ZM7.36133 13.5371L9.35352 12.6973L18.2598 3.78125L16.7754 2.30664L7.86914 11.2227L6.99023 13.1465C6.89258 13.3711 7.13672 13.625 7.36133 13.5371ZM3.66992 20.0508H15.0566C16.9219 20.0508 18.0156 18.9668 18.0156 16.8867V6.56445L16.1016 8.47852V16.7207C16.1016 17.668 15.5938 18.1465 14.9395 18.1465H3.78711C2.87891 18.1465 2.38086 17.668 2.38086 16.7207V5.85156C2.38086 4.9043 2.87891 4.42578 3.78711 4.42578H12.1367L14.0508 2.51172H3.66992C1.57031 2.51172 0.466797 3.5957 0.466797 5.68555V16.8867C0.466797 18.9766 1.57031 20.0508 3.66992 20.0508Z"
        fill="#00B856"
      />
    </svg>
  );
};
