export const ArrowDropDownGraySVG = ({ className }: { className?: string }) => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.41421 0C0.523308 0 0.0771419 1.07714 0.707107 1.70711L5.29289 6.29289C5.68342 6.68342 6.31658 6.68342 6.70711 6.29289L11.2929 1.70711C11.9229 1.07714 11.4767 0 10.5858 0H1.41421Z"
        fill="#8D96A5"
      />
    </svg>
  );
};
