import { FC, useState } from "react";
import moment, { Moment } from "moment";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { MedicalExamControlsBody } from "@interfaces/medical-examination";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import styles from "./medical-exam-controls-table.module.scss";
import { EyeSVG } from "@components/svgs/eye";
import { DownloadFileSVG } from "@components/svgs/download-file";
import { SendSVG } from "@components/svgs/send";
import {
  useDownloadEmployeeReferralMutation,
  useSendEmployeeReferralMutation,
} from "@lib/employees";
import { downloadBlob } from "@utils/downloadBlob";
import { MedicalExamReferralModal } from "../medical-exam-referral-modal";
import { useDisclosure } from "@mantine/hooks";
import toast from "react-hot-toast";
import { useAsyncConfirm } from "@hooks/useAsyncConfirm";

export type MedicalExamControlsTableRowProps = {
  row: MedicalExamControlsBody;
  number: number;
  withCompany: boolean;
  onUpdateLastDate: (
    row: MedicalExamControlsBody,
    lastDate: string | null
  ) => void;
};

export const MedicalExamControlsTableRow: FC<
  MedicalExamControlsTableRowProps
> = ({ row, number, withCompany, onUpdateLastDate }) => {
  const sendReferralMutation = useSendEmployeeReferralMutation();
  const downloadReferralMutation = useDownloadEmployeeReferralMutation();

  const confirm = useAsyncConfirm();
  const [isReferralModalOpen, referralModal] = useDisclosure();
  const [isNewReferral, setIsNewReferral] = useState(false);

  const [isLastDateError, setIsLastDateError] = useState(false);

  const handleChangeLastDate = (
    row: MedicalExamControlsBody,
    date: Moment | null
  ) => {
    setIsLastDateError(false);

    let lastDate: string | null = null;
    if (date?.isValid()) {
      lastDate = date.toISOString();
    } else if (date === null) {
      lastDate = null;
    } else {
      return;
    }

    if (moment().isBefore(moment(lastDate))) {
      toast.error("Дата не может быть позже текущей даты");
      setIsLastDateError(true);
      return;
    }

    onUpdateLastDate(row, lastDate);
  };

  const askIsNewReferral = async () => {
    return await confirm({
      btnText: "Сформировать новое",
      modalData: {
        title: "",
        text: "Выберите направление",
        removeIcon: true,
        textStyle: {
          marginTop: 42,
        },
        cancelBtnText: "Текущее",
      },
    });
  };

  const handleOpenReferralModal = async () => {
    const isNew = await askIsNewReferral();
    setIsNewReferral(isNew);
    referralModal.open();
  };

  const handleDownloadReferral = async () => {
    const isNew = await askIsNewReferral();
    downloadReferralMutation.mutate(
      {
        companyId: row.companyId,
        id: row.employeeId,
        isNew,
      },
      {
        onSuccess: (blob) => {
          downloadBlob(
            blob,
            `${row.employeeName} - Направление на медицинский осмотр.docx`
          );
        },
      }
    );
  };

  const handleSendReferral = async (row: MedicalExamControlsBody) => {
    const isNew = await askIsNewReferral();
    sendReferralMutation.mutate(
      {
        companyId: row.companyId,
        id: row.employeeId,
        isNew,
      },
      {
        onSuccess: () => {
          toast.success("Направление отправлено");
        },
      }
    );
  };

  const getTableCellColor = (row: MedicalExamControlsBody, key: string) => {
    if (!row.lastDate) {
      return styles.box_red;
    }

    if (key === "daysBeforeNextDate") {
      const daysBeforeNextDate = row.daysBeforeNextDate;
      if (daysBeforeNextDate === null || daysBeforeNextDate === 0) {
        return styles.box_red;
      }

      if (daysBeforeNextDate < 60 && daysBeforeNextDate >= 30) {
        return styles.box_yellow;
      }

      if (daysBeforeNextDate < 30 && daysBeforeNextDate >= 14) {
        return styles.box_orange;
      }

      if (daysBeforeNextDate < 14) {
        return styles.box_red;
      }

      return styles.box_green;
    }

    return "";
  };

  return (
    <TableRow>
      {isReferralModalOpen && (
        <MedicalExamReferralModal
          isOpen={isReferralModalOpen}
          companyId={row.companyId}
          employeeId={row.employeeId}
          isNewReferral={isNewReferral}
          onClose={referralModal.close}
        />
      )}

      <TableCell
        width={25}
        align="center"
        className={getTableCellColor(row, "index")}
      >
        {number}
      </TableCell>
      <TableCell width={200} className={getTableCellColor(row, "lastDate")}>
        <DatePicker
          value={row.lastDate ? new Date(row.lastDate) : null}
          error={isLastDateError}
          onChange={(date) => handleChangeLastDate(row, date)}
        />
      </TableCell>
      {withCompany && (
        <TableCell
          width={200}
          className={getTableCellColor(row, "companyName")}
        >
          {row.companyName}
        </TableCell>
      )}
      <TableCell width={200} className={getTableCellColor(row, "employeeName")}>
        {row.employeeName}
      </TableCell>
      <TableCell
        width={200}
        className={getTableCellColor(row, "positionTitle")}
      >
        {row.positionTitle}
      </TableCell>
      <TableCell
        width={200}
        className={getTableCellColor(row, "departmentTitle")}
      >
        {row.departmentTitle}
      </TableCell>
      <TableCell width={400} className={getTableCellColor(row, "factorName")}>
        {row.factorName}
      </TableCell>
      <TableCell
        width={200}
        className={getTableCellColor(row, "daysBeforeNextDate")}
      >
        {row.daysBeforeNextDate}
      </TableCell>
      <TableCell width={200} className={getTableCellColor(row, "nextDate")}>
        <div className={styles.table__lastColumn}>
          {moment(row.nextDate).format("DD.MM.YYYY")}
          <div className={styles.table__actions}>
            <button
              className={styles.table__actionBtn}
              onClick={() => handleOpenReferralModal()}
            >
              <EyeSVG size={24} color="svg-blue-dark" />
            </button>
            <button
              className={styles.table__actionBtn}
              onClick={() => handleDownloadReferral()}
            >
              <DownloadFileSVG />
            </button>
            <button
              className={styles.table__actionBtn}
              onClick={() => handleSendReferral(row)}
            >
              <SendSVG />
            </button>
          </div>
        </div>
      </TableCell>
    </TableRow>
  );
};

type DatePickerProps = {
  value: Date | null;
  error?: boolean;
  onChange: (date: Moment | null) => void;
};

const DatePicker: FC<DatePickerProps> = ({
  value,
  error = false,
  onChange,
}) => {
  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale="ru"
    >
      <KeyboardDatePicker
        cancelLabel="Отменить"
        okLabel="ОК"
        // InputProps={{ classes: { root: classes.inputBase } }}
        disableToolbar
        fullWidth
        variant="dialog"
        format="DD.MM.yyyy"
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        maxDate={moment()}
        value={value}
        onChange={onChange}
        error={error}
        helperText=""
      />
    </MuiPickersUtilsProvider>
  );
};
