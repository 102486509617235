import {
  $Employees,
  fetchEmployees,
  setEmployees,
} from "@store/company/employees-store";
import { $Departments, setDepartments } from "@store/company/departments-store";
import {
  ImportEmployee,
  ImportEmployeesFailedToAddItem,
  ImportEmployeesInvalidDataItem,
} from "@interfaces/company/employees";
import {
  addImportedPositions,
  addImportedDepartments,
  addMissingEmployees,
} from "@store/company/import-employees-store";

import { employeesService } from "@services/employee-service";
import { openFile } from "@utils/openFile";
import { useHistory } from "react-router-dom";
import { useModal } from "@modals/index";
import { useStore } from "effector-react";
import { useSelectedCompanyQuery } from "./company";

export const useImportEmployees = () => {
  const modal = useModal();
  const history = useHistory();

  const { company } = useSelectedCompanyQuery();

  const employees = useStore($Employees);
  const departments = useStore($Departments);

  const handleReplaceEmployee = async (
    employee: ImportEmployee
  ): Promise<boolean> => {
    return new Promise((resolve) => {
      modal.open("ConfirmActionModal", {
        btnText: "Заменить",
        modalData: {
          style: {
            maxWidth: "405px",
          },
          title: "Внимание!",
          titleStyle: {
            color: "#E57373",
          },
          removeIcon: true,
          html: true,
          text: `<strong>Сотрудник:</strong> ${employee.name}<br><strong>Должность:</strong> ${employee.position?.title}<br>Уже находится в списке. Вы хотите заменить данные?`,
          textStyle: {
            textAlign: "left",
          },
          cancelBtnText: "Отмена",
          onCancel: () => {
            resolve(false);
            modal.close();
          },
        },
        onConfirm: () => {
          resolve(true);
        },
      });
    });
  };

  const handleInvalidData = async (
    data: ImportEmployeesInvalidDataItem[]
  ): Promise<void> => {
    return new Promise((resolve) => {
      let text = "В следующих строках были обнаружены ошибки:<br>";
      data.forEach((item) => {
        const errors = Object.entries(item.errors).map(
          ([field, error]) => `<b>[${field}]</b> ${error}`
        );
        text += `<details><summary><b>Строка ${
          item.rowNumber
        }:</b></summary>${errors.join("<br>")}</details>`;
      });

      modal.open("NotificationModal", {
        btnText: "Закрыть",
        modalData: {
          style: {
            width: "600px",
          },
          modalTitle: "Ошибка при загрузке сотрудников",
          titleStyle: {
            color: "#E57373",
          },
          removeIcon: true,
          text2: text,
          textStyle: {
            textAlign: "left",
          },
        },
        onConfirm: () => {
          resolve();
        },
      });
    });
  };

  const handleFailedToAdd = async (
    data: ImportEmployeesFailedToAddItem[]
  ): Promise<void> => {
    return new Promise((resolve) => {
      let text = "Следующие строки не удалось добавить:<br>";
      data.forEach((item) => {
        text += `<b>Строка ${item.rowNumber}:</b> ${item.error}<br>`;
      });

      modal.open("NotificationModal", {
        btnText: "Закрыть",
        modalData: {
          style: {
            width: "600px",
          },
          modalTitle: "Ошибка при загрузке сотрудников",
          titleStyle: {
            color: "#E57373",
          },
          removeIcon: true,
          text2: text,
          textStyle: {
            textAlign: "left",
          },
        },
        onConfirm: () => {
          resolve();
        },
      });
    });
  };

  return async (departmentId?: number) => {
    if (!company) return;

    const file = await openFile({
      accept:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    if (!file) return;

    const data = await employeesService.importFromFile(
      company.id,
      file,
      departmentId
    );

    if (data.invalidData.length > 0) {
      await handleInvalidData(data.invalidData);
    }

    if (data.failedToAdd.length > 0) {
      await handleFailedToAdd(data.failedToAdd);
    }

    if (data.existingEmployees?.length > 0) {
      for (const employee of data.existingEmployees) {
        const shouldReplace = await handleReplaceEmployee(
          employee.dataFromFile
        );
        if (!shouldReplace) continue;

        try {
          await employeesService.update(employee.id, employee.dataFromFile);
        } catch (_) {}
      }
    }

    addMissingEmployees(data.missingEmployees.map((employee) => employee.id));

    data.addedEmployees.forEach((newEmployee) => {
      const department = departments.find(
        ({ id }) => id === newEmployee.position.department.id
      );
      if (department) {
        department.employeesCount++;
      }
    });

    setEmployees([...employees, ...data.addedEmployees]);
    setDepartments([...departments, ...data.addedDepartments]);

    const missingDepartments = new Set<number>();
    const missingPositions = new Set<number>();
    for (const department of departments) {
      const departmentWasInFile = data.existingInFileDepartments.includes(
        department.id
      );
      if (!departmentWasInFile) {
        missingDepartments.add(department.id);
      }

      for (const position of department.positions) {
        const positionWasInFile = data.existingInFilePositions.includes(
          position.id
        );
        if (!positionWasInFile) {
          missingPositions.add(position.id);
        }
      }
    }

    addImportedDepartments([...missingDepartments]);
    addImportedPositions([...missingPositions]);

    fetchEmployees();

    modal.open("ConfirmActionModal", {
      btnText: "Выполнить",
      modalData: {
        removeIcon: true,
        text: 'Данные загружены. Укажите необходимые программы и сведения о проведённых обучениях и инструктажах в разделах "Подразделения" и "Сотрудники"',
        cancelBtnText: "Позже",
        onCancel: () => {
          modal.close();
        },
      },
      onConfirm: () => {
        modal.close();
        history.push("/company/departments");
      },
    });
  };
};
