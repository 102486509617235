import { FC } from "react";
import { InternalStudyCenterSection } from "@interfaces/internal-study-center";
import { PublicMaterialSection } from "./public-material-section";
import { Subtitle } from "@ui/fonts";
import styles from "./materials-public-tab.module.scss";
import { useInternalSectionsCategoriesQuery } from "@lib/internal-study-center/useInternalSectionsCategoriesQuery";

export const MaterialsPublicTab: React.FC = () => {
  const { sections } = useInternalSectionsCategoriesQuery();

  return (
    <div className={styles.MaterialsPublicTab}>
      {sections?.[0] && (
        <PublicMaterialSection
          className={styles.wrapperScroll}
          section={sections[0]}
          rightSide={<LinkChat section={sections?.[1]} />}
        />
      )}
    </div>
  );
};

type LinkChatProps = {
  section: InternalStudyCenterSection;
};

const LinkChat: FC<LinkChatProps> = ({ section }) => {
  return (
    <div className={styles.room}>
      <Subtitle weight="bold" className={styles.room__title}>
        Вебинарная комната
      </Subtitle>
      <div className={styles.roomMessage}>
        <p>
          Скоро появится возможность проведения видеоинструктажей и вебинаров с
          использованием технических средств связи.
        </p>
      </div>
      <div className={styles.educationalPromotionRoom}>
        <img
          src="/img/static/linkchat_feature_preview.jpg"
          alt=""
          width="100%"
          height="100%"
        />
      </div>

      <PublicMaterialSection
        section={section}
        withCategories={false}
        className={styles.roomSection}
      />
    </div>
  );
};
