import React, { useRef } from "react";

export type FileInputProps = JSX.IntrinsicElements["input"] & {};

export const FileInput: React.FC<FileInputProps> = ({
  className,
  children,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChooseFile = () => {
    inputRef.current?.click();
  };

  return (
    <div className={className} tabIndex={0} onClick={handleChooseFile}>
      <input
        type="file"
        ref={inputRef}
        {...props}
        style={{ display: "none" }}
      />
      {children}
    </div>
  );
};
