import { $UserAddPermissions, ACTIONS, setModule } from "@store/user-store";
import React, { useEffect } from "react";
import { useDocumentTemplatesGroupsQuery } from "@lib/document-templates";

import { ColorfulButton } from "@components/common/common";
import DocumentTemplatesGroups from "@components/company/document-templates/document-templates-groups";
import { DocumentTemplatesTable } from "./components";
import { Modules } from "@interfaces/common";
import clsx from "clsx";
import style from "@scss/pages/company/company-workspace.module.scss";
import styles from "./document-templates.module.scss";
import { useHistory } from "react-router-dom";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import { useQueryParam } from "@hooks/useQueryParam";
import qs from "qs";

export const DocumentTemplates: React.FC = () => {
  const permissions = useStore($UserAddPermissions);
  const history = useHistory();

  const { groups } = useDocumentTemplatesGroupsQuery();

  const groupId = useQueryParam("group_id");
  const activeGroupId = groupId ? parseInt(groupId) : groups?.[0]?.id;

  const { open } = useModal();

  const setActiveGroupId = (groupId: number | null) => {
    if (groupId === null) {
      return history.replace(history.location.pathname);
    }

    history.replace({
      pathname: history.location.pathname,
      search: qs.stringify({ group_id: groupId }),
    });
  };

  useEffect(() => {
    setModule(Modules.WORKSPACE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (groups && groups.length === 0 && activeGroupId) {
      setActiveGroupId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups, activeGroupId]);

  return (
    <div className={styles.content}>
      <div className={clsx(style.workspace_top_panel)}>
        {groups && activeGroupId && (
          <DocumentTemplatesGroups
            groups={groups}
            activeGroupId={activeGroupId}
            setActiveGroupId={setActiveGroupId}
          />
        )}

        <div className={clsx(style.workspace_buttons)}>
          {permissions.hasPermission(
            ACTIONS.companyDocumentTemplates_groups_allowedToCreate
          ) && (
            <>
              <ColorfulButton
                text="Создать группу"
                onClick={() =>
                  open("CreateDocumentTemplatesGroupModal", {
                    btnText: "Создать",
                    modalData: { modalTitle: "Создать группу" },
                  })
                }
              />
              {permissions.hasPermission(
                ACTIONS.companyDocumentTemplates_directories_allowedToCreate
              ) && (
                <ColorfulButton
                  text="Создать папку"
                  plusIcon={false}
                  onClick={() =>
                    open("CreateFolderDocumentTemplatesModal", {
                      btnText: "Создать",
                      modalData: {
                        modalTitle: "Создать папку",
                        groupId: activeGroupId,
                      },
                    })
                  }
                />
              )}
            </>
          )}
          {permissions.hasPermission(
            ACTIONS.companyDocumentTemplates_files_allowedToCreate
          ) && (
            <ColorfulButton
              text="Загрузить документы"
              plusIcon={false}
              onClick={() => open("UploadDocumentTemplatesFileModal")}
            />
          )}
          {permissions.hasPermission(
            ACTIONS.companyDocumentTemplates_allowedToShowArchive
          ) && (
            <ColorfulButton
              text="Архив"
              plusIcon={false}
              link="/document-templates/archive"
            />
          )}
        </div>
      </div>
      <div className={style.workspace_table}>
        <DocumentTemplatesTable
          groupId={activeGroupId ?? 0}
          groupsCount={groups?.length ?? 0}
        />
      </div>
    </div>
  );
};
