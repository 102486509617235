import { ReactNode, useCallback } from "react";

import { DownloadSimple } from "@phosphor-icons/react";
import { DropzoneImagePreview } from "./image-preview";
// import { FolderLoadingSVG } from "@components/svgs/FolderLoadingSVG";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import style from "./dropzone.module.scss";
import { FileRejection, useDropzone } from "react-dropzone";

type DropzonePropsT = {
  type?: "company";
  files?: File[];
  maxFiles?: number;
  maxFileSize?: number;
  maxFileSizeUnits?: string;
  requiredFiles?: number;
  accept?: string;
  withPreviews?: boolean;
  icon?: ReactNode;
  onUpload: (files: File[]) => void;
  onDeleteFile?: (file: File) => void;
  typeFile?: "file" | "image";
  widthFull?: boolean;
  filesCount?: number;
  error?: string;
  onError?: (error: string) => void;
};

const Dropzone: React.FC<DropzonePropsT> = ({
  type,
  filesCount,
  files,
  maxFiles,
  maxFileSize,
  maxFileSizeUnits,
  requiredFiles,
  accept,
  withPreviews = false,
  icon,
  onUpload,
  onDeleteFile,
  typeFile = "file",
  widthFull,
  error,
  onError,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      onError?.("");

      if (fileRejections.length > 0) {
        fileRejections.forEach((rejection) => {
          const hasFileTooLargeError = rejection.errors.some(
            (error) => error.code === "file-too-large"
          );
          if (hasFileTooLargeError && maxFileSize) {
            return onError?.(
              `Максимальный размер файла - ${
                maxFileSize / 1024 / 1024
              } ${maxFileSizeUnits}`
            );
          }

          const hasTooManyFilesError = rejection.errors.some(
            (error) => error.code === "too-many-files"
          );
          if (hasTooManyFilesError) {
            return onError?.(`Максимальное количество файлов - ${maxFiles}`);
          }
        });
        return;
      }

      onUpload(acceptedFiles);
    },
    [onUpload, onError, maxFileSize, maxFiles, maxFileSizeUnits]
  );

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    maxFiles,
    maxSize: maxFileSize,
    accept,
    onDrop,
  });

  return (
    <div className={clsx(style.wrapper)}>
      <section
        className={clsx(
          style.dropzone_container,
          widthFull && style.dropzone_container_full,
          "hover-animation-card",
          {
            [style.error]: !!error,
          }
        )}
      >
        <div {...getRootProps({ className: clsx(style.dropzone) })}>
          <input
            {...getInputProps()}
            disabled={
              maxFiles !== undefined ? (files?.length ?? 0) >= maxFiles : false
            }
          />
          {/* <FolderLoadingSVG/> */}
          <WrapperSVG color={"green-light"}>
            {icon ? icon : <DownloadSimple size={24} />}
          </WrapperSVG>
          {type === "company" && <p>Загрузите логотип Организации</p>}
          <p>
            Для загрузки нажмите сюда
            <br />
            Или перетащите
            {typeFile === "image" ? " изображение" : " файл"}
            <br />
            {typeFile === "image" && "PNG, JPEG"}
          </p>
        </div>
      </section>
      {withPreviews && (
        <div className={style.files}>
          {files?.map((file, i) => (
            <DropzoneImagePreview
              key={i}
              file={file}
              onDelete={() => onDeleteFile?.(file)}
            />
          ))}
        </div>
      )}
      <div className={style.count_file}>
        {type !== "company" && (
          <>
            <p className={clsx(style.uploaded_text)}>
              Загружено файлов:{" "}
              {filesCount === undefined
                ? (files?.length ?? 0) > 0
                  ? files?.length
                  : acceptedFiles.length
                : filesCount}
              {requiredFiles ? " из " + requiredFiles : null}
            </p>
            {
              // !requiredFiles && maxFiles ? <p className={ clsx(style.uploaded_text) }>Максимум файлов: { maxFiles }</p> : null
              maxFiles ? (
                <p className={clsx(style.uploaded_text)}>
                  Максимум файлов: {maxFiles}
                </p>
              ) : null
            }
          </>
        )}
      </div>
      {error && <p className="error-text">{error}</p>}
    </div>
  );
};

export default Dropzone;
