import { FC, useEffect, useState } from "react";
import {
  ArrowDropDownGraySVG,
  CheckboxCheckedSVG,
  CheckboxSVG,
} from "@components/svgs";

import { Checkbox } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import clsx from "clsx";
import style from "./drop-down-multi-select.module.scss";

export type SelectList = {
  id: number;
  title: string;
};

export type SimpleDropDownMultiSelectPropsT = {
  options: SelectList[];
  value: SelectList[];
  label?: string;
  emptyIsFull?: boolean; // NOTE: hack to use empty array as "full"
  onChange: (values: SelectList[]) => void;
};

export const SimpleDropDownMultiSelect: FC<SimpleDropDownMultiSelectPropsT> = ({
  options,
  value,
  label = "",
  emptyIsFull = false,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState(value);

  const getTextValue = (): string => {
    if (value.length === 0 || value.length === options.length) return label;
    return value.map((item) => item.title).join(", ");
  };

  const isOptionSelected = (option: SelectList): boolean => {
    return selectedItems.findIndex((item) => item.id === option.id) !== -1;
  };

  const handleSelectOption = (option: SelectList, checked: boolean) => {
    if (checked) {
      setSelectedItems([...selectedItems, option]);
    } else {
      setSelectedItems(selectedItems.filter((item) => item.id !== option.id));
    }
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedItems(options);
    } else {
      setSelectedItems([]);
    }
  };

  const handleClosePopover = () => {
    onChange(selectedItems);
    setIsOpen(false);
  };

  useEffect(() => {
    if (emptyIsFull && value.length === 0) {
      return setSelectedItems(options);
    }

    setSelectedItems(value);
  }, [value, emptyIsFull, options]);

  return (
    <div className={style.container}>
      <div className={style.selectedValue}>
        <span className={style.selectedValue__text}>{getTextValue()}</span>
        <ArrowDropDownGraySVG
          className={clsx(style.selectedValue__icon, {
            [style.selectedValue__icon_rotated]: isOpen,
          })}
        />
      </div>
      <FormControl variant="filled">
        <Select
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={handleClosePopover}
          multiple
          value={value}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                maxHeight: 48 * 4.5 + 8,
                maxWidth: 600,
                minWidth: 300,
                left: "50%",
              },
            },
          }}
          className={clsx(style.box_input, style.box_input_smile)}
        >
          <label className={style.checked_list}>
            <Checkbox
              checked={selectedItems.length === options.length}
              onChange={(e) => handleSelectAll(e.target.checked)}
              name="select_all"
              color="primary"
              icon={<CheckboxSVG />}
              checkedIcon={<CheckboxCheckedSVG />}
            />
            Выбрать все
          </label>
          {options.map((option) => (
            <label key={option.id} className={style.checked_list}>
              <Checkbox
                checked={isOptionSelected(option)}
                onChange={(e) => handleSelectOption(option, e.target.checked)}
                name={option.title}
                color="primary"
                icon={<CheckboxSVG />}
                checkedIcon={<CheckboxCheckedSVG />}
              />
              {option.title}
            </label>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
