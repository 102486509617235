import { ProgrammInstuctions, ProgrammTraining, MedicalExaminationDirectory} from "@feature";
import React, { memo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { TabItems, Title } from "@components/common/common";

import { BreadCrumb } from "@components/common";

const DirectorySystems: React.FC = memo(() => {
  const TAB_ITEMS: { label: string; path: string; exact?: boolean }[] = [
    {
      label: "Справочник программ обучения",
      path: "/directories/training",
      exact: true,
    },
    {
      label: "Справочник инструктажей",
      path: "/directories/instructions",
      exact: true,
    },
    {
      label: "Медицинские осмотры",
      path: "/directories/medical-examination",
      exact: true,
    },
  ];

  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={["Главная", "Справочники"]} />
          <Title text="Справочники" />
        </div>
        <div className="tab-section">
          <div className="tab-cont">
            <TabItems items={TAB_ITEMS} />
            <div className="tab-content-items">
              <Switch>
                <Route path="/directories" exact>
                  <Redirect to="/directories/training" />
                </Route>
                <Route
                  path={"/directories/training"}
                  exact
                  component={ProgrammTraining}
                />
                <Route
                  path={"/directories/instructions"}
                  exact
                  component={ProgrammInstuctions}
                />
                <Route
                  path={"/directories/medical-examination"}
                  exact
                  component={MedicalExaminationDirectory}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
});

export default DirectorySystems;
