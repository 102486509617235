import { useDebouncedValue } from "@mantine/hooks";
import { ChangeEvent, useState } from "react";

export const useSearch = (wait = 300) => {
  const [value, setValue] = useState("");
  const [debounced] = useDebouncedValue(value, wait);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return {
    value,
    setValue,
    onChange,
    debounced: debounced || undefined,
  };
};
