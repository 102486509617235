import { BreadCrumb, TabItems, Title } from "@components/common";
import {
  CreateEducationalEvent,
  CreateEducationalPromotion,
  MaterialsPublicTab,
  EducationalEventPage,
  StudyCenter,
  StudyCenterMaterials,
  EducationalPromotionPage,
} from "@feature";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { PageTabPropsT } from "@interfaces/common";
import { EducationalEventsTab } from "feature/study-materials/educational-events-tab";
import { EducationalPromotionsTab } from "feature/study-materials/educational-promotions-tab";
import styles from "@scss/pages/study-center.module.scss";
import { CreatePublicArticle } from "feature/study-materials/create-public-article";
import { CreatePublicPostlink } from "feature/study-materials/create-public-postlink";
import { PublicMaterialPage } from "feature/study-materials/public-material-page";
import { EditPublicArticle } from "feature/study-materials/edit-public-article";

const TAB_ITEMS: PageTabPropsT[] = [
  { path: "/study-center", label: "Внешнее обучение", exact: true },

  {
    path: "/study-center/public-materials",
    label: "Материалы для внутреннего обучения",
  },
  {
    path: "/study-center/materials",
    label: "Предложения месяца",
  },
];

const isCreateEducationalPromotionPage = (pathname: string): boolean => {
  return pathname === "/study-center/materials/educational-promotions/create";
};

const isUpdateEducationalPromotionPage = (pathname: string): boolean => {
  return /\/study-center\/materials\/educational-promotions\/edit\/[0-9]+/i.test(
    pathname
  );
};

const isCreateEventPage = (pathname: string): boolean => {
  return pathname === "/study-center/materials/educational-events/create";
};

const isUpdateEventPage = (pathname: string): boolean => {
  return /\/study-center\/materials\/events\/edit\/[0-9]+/i.test(pathname);
};

const isEducationalPromotionPage = (pathname: string): boolean => {
  return (
    isCreateEducationalPromotionPage(pathname) ||
    isUpdateEducationalPromotionPage(pathname)
  );
};

const isEventPage = (pathname: string): boolean => {
  return isCreateEventPage(pathname) || isUpdateEventPage(pathname);
};

const getEducationalPromotionPageTitle = (pathname: string): string => {
  if (isCreateEducationalPromotionPage(pathname)) {
    return "Создание акции или предложения";
  }

  if (isUpdateEducationalPromotionPage(pathname)) {
    return "Редактирование акции или предложения";
  }

  if (isCreateEventPage(pathname)) {
    return "Создание события или вебинара";
  }

  if (isUpdateEventPage(pathname)) {
    return "Редактирование события или вебинара";
  }

  return "Учебный центр";
};

const StudyCenterRoutes = () => {
  const location = useLocation();
  const isEducationalPromotionFormPage = isEducationalPromotionPage(
    location.pathname
  );
  const isEventFormPage = isEventPage(location.pathname);
  const title = getEducationalPromotionPageTitle(location.pathname);

  return (
    <div className={styles.StudyCenter}>
      <BreadCrumb items={["Главная", "Учебный центр"]} />
      <div className="content-title-section-with-btn">
        <Title
          text={title}
          withHistory={isEducationalPromotionFormPage || isEventFormPage}
        />
      </div>
      <div className={styles.StudyCenter__panel}>
        {!isEducationalPromotionFormPage && <TabItems items={TAB_ITEMS} />}
        <Switch>
          <Route exact path="/study-center" component={StudyCenter} />
          <Route exact path="/study-center/materials">
            <Redirect to="/study-center/materials/educational-promotions" />
          </Route>
          <Route
            exact
            path="/study-center/materials/educational-promotions"
            component={EducationalPromotionsTab}
          >
            <StudyCenterMaterials>
              <EducationalPromotionsTab />
            </StudyCenterMaterials>
          </Route>
          <Route exact path="/study-center/materials/educational-events">
            <StudyCenterMaterials>
              <EducationalEventsTab />
            </StudyCenterMaterials>
          </Route>
          <Route exact path="/study-center/public-materials">
            <StudyCenterMaterials viewLink={false}>
              <MaterialsPublicTab />
            </StudyCenterMaterials>
          </Route>
          <Route
            exact
            path="/study-center/public-materials/:sectionId/:categoryId/:id"
          >
            <PublicMaterialPage />
          </Route>
          <Route
            exact
            path="/study-center/public-materials/:sectionId/:categoryId/article/create"
          >
            <CreatePublicArticle type="create" />
          </Route>
          <Route
            exact
            path="/study-center/public-materials/:sectionId/:categoryId/post-link/create"
          >
            <CreatePublicPostlink type="create" />
          </Route>
          <Route
            exact
            path="/study-center/public-materials/:sectionId/:categoryId/:id/article/edit"
          >
            <EditPublicArticle />
          </Route>
          <Route
            exact
            path="/study-center/materials/educational-promotions/create"
            component={CreateEducationalPromotion}
          />
          <Route
            exact
            path="/study-center/materials/educational-events/create"
            component={CreateEducationalEvent}
          />
          <Route
            exact
            path="/study-center/materials/educational-promotions/:id"
            component={EducationalPromotionPage}
          />
          <Route
            exact
            path="/study-center/materials/educational-events/:id"
            component={EducationalEventPage}
          />
          <Route
            exact
            path="/study-center/materials/educational-promotions/edit/:id"
            component={CreateEducationalPromotion}
          />
          <Route
            exact
            path="/study-center/materials/educational-events/edit/:id"
            component={CreateEducationalEvent}
          />
        </Switch>
      </div>
    </div>
  );
};

export default StudyCenterRoutes;
