import styles from "../goskey.module.scss";

export const ImageBlock: React.FC = () => {
  return (
    <div className={styles.block_image}>
      <div className={styles.item_image}>
        <img src="/img/static/goskey/1.jpg" alt="" />
      </div>
      <div className={styles.item_image}>
        <img src="/img/static/goskey/2.jpg" alt="" />
      </div>
      <div className={styles.item_image}>
        <img src="/img/static/goskey/3.jpg" alt="" />
      </div>
    </div>
  );
};
