import { Center, Text } from "@mantine/core";
import {
  Procedure,
  ProcedureInstructionQueryParams,
  TablesDirectionDataT,
} from "@interfaces/company/procedure";
import React, { memo, useMemo } from "react";

import { Pagination } from "@components/pagination";
import { Loader } from "@ui/indicators";
import { UseSortingResult } from "@hooks/useSorting";
import { WrapperTableCategory } from "../wrapper-table-category";
import styles from "./procedure-tables-training.module.scss";

type ProcedureTablesTrainigProps = {
  withoutEmpty?: boolean;
  TYPE: "briefing" | "training";
  sorting?: UseSortingResult | undefined;
  withoutFilters?: boolean;
  ignoredKeys?: (keyof Procedure)[];
  defaultFilters?: Partial<ProcedureInstructionQueryParams>;
  tables: TablesDirectionDataT[];
  isLoading: boolean;
  offset: number;
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
};

export const ProcedureTablesTrainig: React.FC<ProcedureTablesTrainigProps> =
  memo(
    ({
      withoutEmpty,
      TYPE,
      ignoredKeys,
      sorting,
      tables,
      isLoading,
      offset,
      page,
      totalPages,
      onPageChange,
    }) => {
      const isDataInTable = useMemo(() => {
        return tables?.filter((item) =>
          withoutEmpty ? !!item.body.length : true
        ).length;
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [tables]);

      if (isLoading) {
        return (
          <Center h={950}>
            <Loader />
          </Center>
        );
      }

      if (!isDataInTable) {
        return (
          <Text mt={40} size="xl" align="center" color="gray">
            Таблицы пусты
          </Text>
        );
      }

      return (
        <>
          {tables.map((item) => {
            return (
              <div key={item.id}>
                <WrapperTableCategory
                  type={TYPE}
                  withName={true}
                  ignoredKeys={ignoredKeys ?? []}
                  table={item}
                  sorting={sorting}
                  offset={offset}
                />
              </div>
            );
          })}
          <div className={styles.pagination}>
            <Pagination
              value={page}
              onChange={onPageChange}
              total={totalPages}
            />
          </div>
        </>
      );
    }
  );
