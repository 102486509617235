import { FC, FormEvent, useState } from "react";
import { File, Image } from "@phosphor-icons/react";
import { useHistory, useParams } from "react-router-dom";

import Dropzone from "@ui/dropzone";
import { TextField } from "@material-ui/core";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import styles from "./create-public-article.module.scss";
import { useCreateInternalMaterialMutation } from "@lib/internal-study-center/useCreateInternalMaterialMutation";
import useStyles from "@ui/material-ui-styles";
import { useUpdateInternalMaterialMutation } from "@lib/internal-study-center";

export type CreatePublicArticleProps = {
  type: "create" | "edit";
  id?: number;
  title?: string;
  tags?: string[];
  text?: string;
};

export const CreatePublicArticle: FC<CreatePublicArticleProps> = ({
  type,
  title: defaultTitle = "",
  tags: defaultTags = [],
  text: defaultText = "",
  id,
}) => {
  const { sectionId, categoryId } = useParams() as {
    sectionId: string;
    categoryId: string;
  };
  const history = useHistory();

  const createMaterialMutation = useCreateInternalMaterialMutation();
  const updateMaterialMutation = useUpdateInternalMaterialMutation();

  const [title, setTitle] = useState(defaultTitle);
  const [text, setText] = useState(defaultText);
  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const [validation, setValidation] = useState({
    titleError: "",
    textError: "",
    uploadError: "",
    processError: "",
  });

  const classes = useStyles();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!categoryId || !sectionId) return;

    if (type === "create") {
      if (uploadedImages.length === 0) {
        return setValidation((prev) => ({
          ...prev,
          uploadError: "Выберите картинку для статьи",
        }));
      }

      createMaterialMutation.mutate(
        {
          sectionId: +sectionId,
          categoryId: +categoryId,
          title,
          text,
          link: null,
          preview: uploadedImages[0],
          files: uploadedFiles,
        },
        {
          onSuccess: () => {
            history.push("/study-center/public-materials");
          },
        }
      );
      return;
    }

    if (type === "edit") {
      if (!id) return;

      updateMaterialMutation.mutate(
        {
          sectionId: +sectionId,
          categoryId: +categoryId,
          id,
          title,
          text,
          link: null,
          preview: uploadedImages[0],
          files: uploadedFiles,
        },
        {
          onSuccess: () => {
            history.push("/study-center/public-materials");
          },
        }
      );
      return;
    }
  };

  return (
    <div className={styles.createArticle}>
      <form
        onSubmit={onSubmit}
        className={clsx(classes.root, styles.createArticle__form)}
      >
        <TextField
          label="Название статьи"
          variant="filled"
          name="title"
          required
          placeholder="Название статьи"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <p className="error-text">{validation.titleError}</p>
        {/* <TextField */}
        {/*   label="Теги (через запятую)" */}
        {/*   variant="filled" */}
        {/*   name="tags" */}
        {/*   placeholder="Через запятую добавьте теги" */}
        {/*   value={tags} */}
        {/*   onChange={(e) => setTags(e.target.value)} */}
        {/* /> */}
        {type === "create" && (
          <>
            <p className={styles.createArticle__fieldMessage}>
              <WrapperSVG color="green" cursor={false}>
                <Image size={24} />
              </WrapperSVG>
              Загрузить фото *
            </p>
            <p className={styles.createArticle__fieldDescription}>
              Допускается только формат изображения!
            </p>
            <Dropzone
              widthFull={true}
              accept="image/*"
              maxFiles={1}
              typeFile={"image"}
              onUpload={(files) => setUploadedImages(files)}
            />
            <p className="error-text">{validation.uploadError}</p>
          </>
        )}
        <TextField
          label="Текст статьи"
          multiline
          variant="filled"
          name="text"
          required
          placeholder="Текст статьи"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />

        {type === "create" && (
          <>
            <p className={styles.createArticle__fieldMessage}>
              <WrapperSVG color="green" cursor={false}>
                <File size={24} />
              </WrapperSVG>
              Загрузить файлы для скачивания
            </p>
            <Dropzone
              widthFull={true}
              maxFiles={64}
              onUpload={(files) => setUploadedFiles(files)}
            />
          </>
        )}

        <p className="error-text">{validation.textError}</p>
        <p className="error-text">{validation.processError}</p>
        <button
          disabled={
            createMaterialMutation.isLoading || updateMaterialMutation.isLoading
          }
          type="submit"
          className={styles.createArticle__submitBtn}
        >
          {type === "create" ? "Создать" : "Сохранить"}
        </button>
      </form>
    </div>
  );
};
