import { FontPropsT } from "@interfaces/fonts";
import clsx from "clsx";
import { correctWeight } from "@utils/correct-weight";
import styles from "./subtitle.module.scss";
import { useGetCSSVars } from "@lib/use-get-css-vars";

export const Subtitle: React.FC<FontPropsT> = ({
  children,
  className = "",
  color = "inherit",
  weight = "normal",
  sx,
  ...props
}) => {
  const currentColor = useGetCSSVars("color", color);
  const currentWeight = correctWeight(weight);
  return (
    <span
      style={{ color: currentColor, fontWeight: currentWeight, ...sx }}
      className={clsx(styles.Subtitle, className)}
      {...props}
    >
      {children}
    </span>
  );
};
