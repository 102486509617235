import {
  $DepartmentState,
  $Departments,
  fetchDepartments,
} from "@store/company/departments-store";
import { Box, Group, Text } from "@mantine/core";
import { EmployeeListDataT, PostUserData } from "@interfaces/company/employees";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect, useMemo, useState } from "react";
import {
  TextField,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import moment, { Moment } from "moment";

import { EmployeePosition } from "./employee-position";
import MomentUtils from "@date-io/moment";
import Radio from "@mui/material/Radio";
import { useStore } from "effector-react";

const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputBase: {
      backgroundColor: "#F5F6FA",
    },
    disabled: { backgroundColor: "#f2f4f9", color: "black" },
  })
);

const MAX_INN_LENGTH = 12;
const MAX_SNILS_LENGTH = 11;
const MAX_PHONE_LENGTH = 11;

const NUMBERS_REGEX = /1|2|3|4|5|6|7|8|9|0/;
const NUMBERS_PHONE_REGEX = /[0-9]/;

interface EmployeesGeneralProps {
  user?: EmployeeListDataT;
  userData: PostUserData;
  setUserData: React.Dispatch<React.SetStateAction<PostUserData>>;
  errors: { [key: string]: string };
  setErrors: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>;
}

const EmployeesGeneral: React.FC<EmployeesGeneralProps> = ({
  user,
  userData,
  setUserData,
  errors,
  setErrors,
}) => {
  const classes = useFormStyles();

  const handleChangeDate = (date: Moment, name: string) => {
    if (name === "birthdate") {
      const timestamp: Moment | undefined = date;

      if (timestamp && timestamp.isValid()) {
        const currentDate = moment(new Date());
        const ageYears: number = currentDate.diff(timestamp, "years");

        setFullYears(ageYears.toString());
      } else {
        setFullYears(""); // Если значение `date` не определено или недействительно, установите пустую строку или другое значение по умолчанию
      }
    }
    setErrors({});
    setUserData((prev) => ({
      ...prev,
      [name]: date?.format("YYYY-MM-DD"),
    }));
  };
  const [fullYears, setFullYears] = useState("");
  const { isFetched } = useStore($DepartmentState);
  const departmentsData = useStore($Departments);

  const departments = useMemo(() => {
    if (!departmentsData) return [];

    return departmentsData.map((item) => ({
      value: item.id,
      label: item.title,
      data: item,
    }));
  }, [departmentsData]);

  useEffect(() => {
    if (!isFetched) {
      fetchDepartments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.birthdate) {
      const currentDate = moment(new Date());
      const birthDate = moment(new Date(user.birthdate));

      const ageYears: number = currentDate.diff(birthDate, "years");

      setFullYears(ageYears.toString());
    }
  }, [user]);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { target } = event;
    const { name, value } = target;
    const correctValue = value.trim() === "" ? undefined : value;

    // Не самый изящный способ, но пойдёт
    if (name === "workExperience" && correctValue) {
      const lastSymbol = correctValue[correctValue.length - 1];

      if (!NUMBERS_REGEX.test(lastSymbol)) {
        event.preventDefault();
        return;
      }
    }

    // проверяю максимальную длину ИНН и СНИЛС тут, потому что `TextField` ниже не работает с браузерным `maxLength` аттрибутом
    if (name === "inn" && correctValue) {
      const lastSymbol = correctValue && correctValue[correctValue.length - 1];

      if (correctValue.length > MAX_INN_LENGTH) {
        event.preventDefault();
        return;
      }

      if (!NUMBERS_REGEX.test(lastSymbol)) {
        event.preventDefault();
        return;
      }
    }
    if (name === "phone" && correctValue) {
      const lastSymbol = correctValue && correctValue[correctValue.length - 1];
      if (!NUMBERS_PHONE_REGEX.test(lastSymbol)) {
        event.preventDefault();
        return;
      }

      if (correctValue.length > MAX_PHONE_LENGTH) {
        return event.preventDefault();
      }
    }
    if (name === "snils" && correctValue) {
      const lastSymbol = correctValue && correctValue[correctValue.length - 1];

      if (correctValue.length > MAX_SNILS_LENGTH) {
        event.preventDefault();
        return;
      }
      if (!NUMBERS_REGEX.test(lastSymbol)) {
        event.preventDefault();
        return;
      }
    }

    setErrors({});
    setUserData((prev) => ({ ...prev, [name]: correctValue ?? null }));
  };
  const controlProps = (item: string) => ({
    checked: userData.sex === item,
    onChange: handleChange,
    value: item,
    name: "sex",
    inputProps: { "aria-label": item },
  });

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: 420,
        "& > *:not(:first-of-type)": {
          marginTop: 26,
        },
      }}
    >
      <TextField
        fullWidth
        required
        InputProps={{ classes: { root: classes.inputBase } }}
        variant="filled"
        name="name"
        error={!!errors.name}
        helperText={errors.name}
        value={userData.name ?? ""}
        onChange={handleChange}
        label="ФИО сотрудника"
        placeholder="Введите..."
      />
      <Box>
        <Group>
          <Typography style={!!errors.sex ? { color: "#f44336" } : undefined}>
            Пол
          </Typography>
          <Group spacing={0}>
            <Radio
              {...controlProps("М")}
              sx={{
                color: !!errors.sex ? "#f44336" : "#00B856",
                "&.Mui-checked": {
                  color: !!errors.sex ? "#f44336" : "#00B856",
                },
              }}
            />
            <Typography>M</Typography>
          </Group>
          <Group spacing={0}>
            <Radio
              {...controlProps("Ж")}
              sx={{
                color: !!errors.sex ? "#f44336" : "#00B856",
                "&.Mui-checked": {
                  color: !!errors.sex ? "#f44336" : "#00B856",
                },
              }}
            />
            <Typography>Ж</Typography>
          </Group>
        </Group>
        {!!errors.sex && (
          <Text color="#f44336" m="3px 14px 0" size="xs">
            Заполните это поле
          </Text>
        )}
      </Box>
      <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale={"ru"}
      >
        <KeyboardDatePicker
          cancelLabel={"Отменить"}
          okLabel={"ОК"}
          disableToolbar
          InputProps={{ classes: { root: classes.inputBase } }}
          fullWidth
          name="birthdate"
          error={!!errors.birthdate}
          helperText={errors.birthdate}
          inputVariant="filled"
          variant="dialog"
          format="DD.MM.yyyy"
          label="Дата рождения"
          value={userData.birthdate ? userData.birthdate : null}
          onChange={(data) => handleChangeDate(data, "birthdate")}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          onError={console.error}
        />
        <TextField
          fullWidth
          value={fullYears ?? ""}
          name="workE"
          disabled
          variant="filled"
          InputProps={{
            classes: { root: classes.inputBase, disabled: classes.disabled },
          }}
          label="Полных лет"
          placeholder="Введите..."
        />
        <KeyboardDatePicker
          cancelLabel={"Отменить"}
          okLabel={"ОК"}
          required
          disableToolbar
          fullWidth
          variant="dialog"
          InputProps={{ classes: { root: classes.inputBase } }}
          label="Дата приема"
          format="DD.MM.yyyy"
          inputVariant="filled"
          error={!!errors.employmentDate}
          helperText={errors.employmentDate}
          value={userData.employmentDate ? userData.employmentDate : null}
          onChange={(data) => handleChangeDate(data, "employmentDate")}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          onError={console.error}
        />
        <KeyboardDatePicker
          cancelLabel={"Отменить"}
          okLabel={"ОК"}
          disableToolbar
          fullWidth
          variant="dialog"
          InputProps={{ classes: { root: classes.inputBase } }}
          label="Дата увольнения"
          format="DD.MM.yyyy"
          inputVariant="filled"
          value={userData.dismissalDate ? userData.dismissalDate : null}
          onChange={(data) => handleChangeDate(data, "dismissalDate")}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          error={!!errors.dismissalDate}
          helperText={errors.dismissalDate}
        />
      </MuiPickersUtilsProvider>
      <TextField
        fullWidth
        required
        name="snils"
        value={userData.snils ?? ""}
        onChange={handleChange}
        variant="filled"
        InputProps={{ classes: { root: classes.inputBase } }}
        label="СНИЛС"
        placeholder="Введите..."
        error={!!errors.snils}
        helperText={errors.snils}
      />
      <TextField
        fullWidth
        value={userData.inn ?? ""}
        onChange={handleChange}
        variant="filled"
        name="inn"
        InputProps={{ classes: { root: classes.inputBase } }}
        label="ИНН"
        placeholder="Введите..."
        error={!!errors.inn}
        helperText={errors.inn}
      />
      <TextField
        required
        fullWidth
        value={userData.email ?? ""}
        onChange={handleChange}
        name="email"
        variant="filled"
        error={!!errors.email}
        helperText={errors.email}
        InputProps={{ classes: { root: classes.inputBase }, type: "email" }}
        label="Адрес электронной почты"
        type="email"
        placeholder="Введите..."
      />
      <TextField
        fullWidth
        variant="filled"
        value={userData.phone ?? ""}
        onChange={handleChange}
        name="phone"
        type="tel"
        InputProps={{ classes: { root: classes.inputBase }, type: "tel" }}
        label="Номер телефона"
        placeholder="Введите..."
        error={!!errors.phone}
        helperText={errors.phone}
      />
      <EmployeePosition
        setUserData={setUserData}
        errors={errors}
        setErrors={setErrors}
        departmentsData={departments}
        user={user}
        userData={userData}
      />
    </Box>
  );
};

export default EmployeesGeneral;
