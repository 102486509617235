import { ArticleType, getServiceByType } from "@interfaces/legislation-news";
import { useMutation, useQueryClient } from "react-query";
import { invalidateNewsQuery } from "./useNewsQuery";

export const useCreateNewsMutation = (type: ArticleType) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: FormData) => {
      return new Promise((resolve, reject) => {
        const service = getServiceByType(type);
        service.create(data, (err, res) => {
          if (err || !res) {
            reject(err);
          } else {
            resolve(res.data);
          }
        });
      });
    },
    {
      onSuccess: () => {
        invalidateNewsQuery(queryClient, type);
      },
    }
  );
};
