import { FeedbackT } from "@interfaces/feedback";
import Fetcher from "@http/fetcher";
import { ResCallback } from "@interfaces/common";

class QAService {
  static async CreateQuestionToSpecialist(
    formData: FormData,
    cb: ResCallback<FeedbackT>
  ) {
    try {
      const res = await Fetcher.post<FeedbackT>(
        "/question-to-specialist",
        formData
      );

      if (res.status !== 200) {
        throw new Error();
      }
      return cb(null, res);
    } catch (err) {
      console.error("error");
      // @ts-ignore
      return cb(err);
    }
  }
}

export default QAService;
