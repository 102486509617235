import { useMutation, useQueryClient } from "react-query";
import { EventData, studyCenterService } from "@services/study-center";
import { studyCenterQueryKeys } from "./queryKeys";

export const useCreateEducationalEventMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: EventData) => studyCenterService.createEvent(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(studyCenterQueryKeys.all);
      },
    }
  );
};
