import { UpdateDocumentTemplatesFileData } from "@interfaces/company/document-templates";
import { documentTemplateService } from "@services/document-templates-service";
import { useMutation, useQueryClient } from "react-query";
import { documentTemplatesQueryKeys } from "./queryKeys";

export const useUpdateDocumentTemplatesFileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateDocumentTemplatesFileData) =>
      documentTemplateService.updateFile(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(documentTemplatesQueryKeys.all);
      },
    }
  );
};
