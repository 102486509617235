import {
  DownloadPrescriptionFileParams,
  PrescriptionStoreT,
  PrescriptionT,
} from "@interfaces/prescriptions";

import Fetcher from "@http/fetcher";
import { ResCallback } from "@interfaces/common";

class PrescriptionService {
  static async getAll(
    companyId: number,
    offset: number,
    limit: number,
    count: boolean | undefined,
    cb: ResCallback<PrescriptionStoreT>
  ) {
    try {
      const res = await Fetcher.modified.get<PrescriptionStoreT>(
        "/prescriptions",
        {
          params: {
            companyId,
            offset,
            limit,
            count,
          },
        }
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async create(formData: FormData, cb: ResCallback<PrescriptionT>) {
    try {
      const res = await Fetcher.modified.post<PrescriptionT>(
        "/prescriptions",
        formData
      );

      if (res.status === 201) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async update(
    id: string,
    formData: FormData,
    cb: ResCallback<PrescriptionT>
  ) {
    try {
      const res = await Fetcher.modified.patch<PrescriptionT>(
        `/prescriptions/${id}`,
        formData
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async markAsCompleted(
    id: string,
    checked: boolean,
    cb: ResCallback<string>
  ) {
    try {
      const res = await Fetcher.modified.post<string>(
        `/prescriptions/${id}/mark`,
        {
          checked,
        }
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async delete(id: string, roleId: number, cb: ResCallback<string>) {
    try {
      const res = await Fetcher.modified.delete<string>(
        `/prescriptions/${id}`,
        {
          data: { roleId },
        }
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async send(id: number, emails: string[], cb: ResCallback<string>) {
    try {
      const res = await Fetcher.modified.post<string>(
        `/prescriptions/${id}/send`,
        {
          emails,
        }
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }
}

export const prescriptionService = {
  async downloadFile({ companyId, fileName }: DownloadPrescriptionFileParams) {
    const resp = await Fetcher.get<Blob>(
      `/companies/${companyId}/prescriptions/${fileName}/download`,
      {
        responseType: "blob",
      }
    );

    return resp.data;
  },
};

export default PrescriptionService;
