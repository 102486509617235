import { GetAllTrainingRequestsParams } from "@interfaces/request-training";
import requestTrainingService from "@services/request-training-service";
import { useQuery } from "react-query";
import { trainingRequestsQueryKeys } from "./queryKeys";

export const useTrainingRequestsQuery = (
  params: GetAllTrainingRequestsParams
) => {
  const { data: trainingRequests, ...stuff } = useQuery(
    trainingRequestsQueryKeys.allWithParams(params),
    () => requestTrainingService.getAll(params)
  );

  return {
    trainingRequests,
    ...stuff,
  };
};
