import React, { useEffect, useState } from "react";
import clsx from "clsx";
import qs from "qs";

import useModal from "@modals/modal-hook";
import useStyles from "@ui/material-ui-styles";

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { periods } from "@pages/company/workspace";

import style from "@scss/modals/company/workspace-document.module.scss";
import { useUpdateWorkspaceFileMutation } from "@lib/workspace";
import { useHistory } from "react-router-dom";
import { useSelectedCompanyQuery } from "@lib/company";

const EditWorkspaceDocumentModal = () => {
  const { company } = useSelectedCompanyQuery();

  const updateWorkspaceFileMutation = useUpdateWorkspaceFileMutation();

  const [period, setPeriod] = useState(periods[0].value);

  const { modalComponent, modalData, close } = useModal();
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    setPeriod(modalData?.item?.signingFrequency || periods[0].value);
  }, [modalData?.item?.signingFrequency]);

  const handlePeriodChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPeriod(event.target.value as any);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!company) return;

    const modalBtn = document.querySelector(
      "button.modal_btn"
    ) as HTMLButtonElement;
    modalBtn.disabled = true;

    const titleField = document.querySelector(
      'input[name="title"]'
    ) as HTMLInputElement;

    const queryString = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    if (titleField && modalData?.item?.id) {
      const data = { name: titleField.value, periodicity: +period };

      const groupId = queryString.group_id ? +queryString.group_id : 0;
      const directoryId = queryString.folder_id ? +queryString.folder_id : 0;

      updateWorkspaceFileMutation.mutate(
        {
          companyId: company.id,
          groupId,
          directoryId,
          fileId: modalData.item.id,
          signingFrequency: period as any,
          ...data,
        },
        {
          onSuccess: () => {
            close();
          },
        }
      );
    }
  };

  return (
    <div className={clsx(style.workspace_document_modal)}>
      <p className="modal_title">Изменить файл</p>
      <div className="underline" />
      <form className={`modal_content ${classes.root}`} onSubmit={handleSubmit}>
        <TextField
          label="Название файла"
          variant="filled"
          name="title"
          required
          defaultValue={modalData?.item?.name}
        />
        <FormControl variant="filled" className={clsx(style.period_select)}>
          <InputLabel id="ot-specialist">Периодичность подписания</InputLabel>
          <Select
            labelId="ot-specialist"
            value={period}
            onChange={handlePeriodChange}
          >
            {periods.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <button type="submit" className="modal_btn">
          {modalComponent.btnText}
        </button>
      </form>
    </div>
  );
};

export default EditWorkspaceDocumentModal;
