import {
  DepartmentFileDataT,
  DepartmentFileForAddingToDepartmentAndPositionResT,
  DepartmentShortDataT,
  DocumentSignatureHistoryT,
  GetFilesForAddingToDepartmentPropsT,
  GetFilesForAddingToPositionPropsT,
} from "@interfaces/company/departments";

import { EmployeeListDataT } from "@interfaces/company/employees";
import Fetcher from "@http/fetcher";
import { ResCallback } from "@interfaces/common";

class DepartmentService {
  static async getAll(cb: ResCallback<DepartmentShortDataT[]>) {
    try {
      const res =
        await Fetcher.modified.get<DepartmentShortDataT[]>("/departments");

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async getEmployees(
    departmentId: number,
    cb: ResCallback<EmployeeListDataT[]>
  ) {
    try {
      const res = await Fetcher.modified.get<EmployeeListDataT[]>(
        `/departments/${departmentId}/employees`
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async getFiles(
    departmentId: number,
    cb: ResCallback<DepartmentFileDataT[]>
  ) {
    try {
      const res = await Fetcher.modified.get<DepartmentFileDataT[]>(
        `/departments/${departmentId}/files`
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async GetFilesForAddingToDepartment(
    props: GetFilesForAddingToDepartmentPropsT,
    cb: ResCallback<DepartmentFileForAddingToDepartmentAndPositionResT>
  ) {
    const { departmentId, groupId, addedFilesIds, minify } = props;
    try {
      const res =
        await Fetcher.modified.post<DepartmentFileForAddingToDepartmentAndPositionResT>(
          `/departments/${departmentId}/files`,
          { addedFilesIds },
          {
            params: {
              groupId,
              minify,
            },
          }
        );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async GetFilesForAddingToPosition(
    props: GetFilesForAddingToPositionPropsT,
    cb: ResCallback<DepartmentFileForAddingToDepartmentAndPositionResT>
  ) {
    const { departmentId, minify, addedFilesIds, positionId, groupId } = props;
    try {
      const res =
        await Fetcher.modified.post<DepartmentFileForAddingToDepartmentAndPositionResT>(
          `/departments/${departmentId}/positions/${positionId}/files`,
          { addedFilesIds },
          { params: { minify, groupId } }
        );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async GetSignatureHistories(
    departmentId: number,
    documentId: number,
    cb: ResCallback<DocumentSignatureHistoryT[]>
  ) {
    try {
      const res = await Fetcher.modified.get<DocumentSignatureHistoryT[]>(
        `/departments/${departmentId}/document/${documentId}/history`
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async create(
    companyId: number,
    title: string,
    cb: ResCallback<DepartmentShortDataT>
  ) {
    try {
      const res = await Fetcher.modified.post<DepartmentShortDataT>(
        "/departments",
        {
          title,
          companyId,
        }
      );

      if (res.status === 201) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async update(
    departmentId: number,
    title: string,
    cb: ResCallback<string>
  ) {
    try {
      const res = await Fetcher.modified.patch<string>(
        `/departments/${departmentId}`,
        {
          title,
        }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async addFiles(
    departmentId: number,
    selectedFiles: number[] | [],
    cb: ResCallback<string>
  ) {
    try {
      const res = await Fetcher.modified.put<string>(
        `/departments/${departmentId}/files`,
        {
          files: selectedFiles,
        }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async delete(departmentId: number, cb: ResCallback<string>) {
    try {
      const res = await Fetcher.modified.delete<string>(
        `/departments/${departmentId}`
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }
}

export default DepartmentService;
