import Fetcher from "@http/fetcher";

export type DepartmentsGetAllT = {
  id: number;
  title: string | null;
  companyId: number | null;
}[];

export type GetAllDepartmentsParams = {
  companies?: number[];
};

const DepartmentsGetAllService = {
  async getAll(params?: GetAllDepartmentsParams) {
    const resp = await Fetcher.get<DepartmentsGetAllT>(
      "/companies/departments/",
      { params }
    );

    return resp.data;
  },
};

export default DepartmentsGetAllService;
