import { createEffect, createEvent, createStore } from "effector";

import { EventStateStoreT } from "@interfaces/common";
import WorkerService from "@services/worker-service";
import { WorkerShortDataT } from "@interfaces/user";
import { resetAllStates } from "@store/user-store";

const setWorkersData = createEvent<WorkerShortDataT[]>();
export const updateWorkerData = createEvent<WorkerShortDataT>();
export const pushToWorkersData = createEvent<WorkerShortDataT>();

export const $Workers = createStore<WorkerShortDataT[]>([])
  .on(setWorkersData, (_, newState) => newState)
  .on(updateWorkerData, (oldState, newState) => {
    return oldState.map((worker) => {
      if (worker.id === newState.id) {
        worker = newState;
      }
      return worker;
    });
  })
  .on(pushToWorkersData, (oldState, newState) => [...oldState, newState])
  .reset(resetAllStates);

export const setWorkersStates = createEvent<EventStateStoreT>();
export const setWorkersLoading = createEvent<boolean>();
export const setWorkersError = createEvent<boolean>();
export const serWorkersStates = createEvent<EventStateStoreT>();

export const $WorkersDataStates = createStore<EventStateStoreT>({
  isLoading: true,
  error: false,
  isFetched: false,
})
  .on(setWorkersStates, (oldState, newState) => newState)
  .on(setWorkersLoading, (oldState, newState) => ({
    ...oldState,
    isLoading: newState,
  }))
  .on(setWorkersError, (oldState, newState) => ({
    ...oldState,
    isLoading: false,
    error: newState,
  }))
  .on(serWorkersStates, (_, newState) => newState)
  .reset(resetAllStates);

export const getWorkers = createEffect<
  (params?: { cb?: (workers: WorkerShortDataT[]) => void }) => void
>((params) => {
  setWorkersLoading(true);

  WorkerService.GetWorkers((err, res) => {
    if (err || !res) {
      setWorkersStates({ error: true, isFetched: false, isLoading: false });
      return console.error(
        "При получении данных пользователей произошла ошибка"
      );
    }
    setWorkersData(res.data);
    setWorkersStates({ error: false, isFetched: true, isLoading: false });

    params?.cb?.(res.data);
  });
});
