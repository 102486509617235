import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { CheckboxCheckedSVG, CheckboxSVG } from "@components/svgs";
import React, { useState } from "react";
import {
  TableCategory,
  TableControlProps,
} from "../table-category/table-category";

import { Button } from "@ui/button";
import { Checkbox } from "@material-ui/core";
import { Modal } from "@ui/modal";
import { Procedure } from "@interfaces/company/procedure";
import { isAllowedToCreateTrainingRequest } from "@utils/training-request";
import moment from "moment";
import styles from "@scss/pages/company/procedure-control.module.scss";
import { useCreateTrainingRequestMutation } from "@lib/request-training";
import { useHistory } from "react-router-dom";
import { useModal } from "@modals/index";
import { useStore } from "effector-react";

export type LearningComponentsItemProps = Omit<
  TableControlProps,
  "selectedProcedures" | "onSelectAll" | "onSelectProcedure"
> & {
  type: "training" | "briefing";
  withName?: boolean;
  withCreateTrainingRequest?: boolean;
};

export const WrapperTableCategory: React.FC<LearningComponentsItemProps> = ({
  withName = false,
  withCreateTrainingRequest = true,
  table,
  type,
  ...props
}) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const createTrainingRequestMutation = useCreateTrainingRequestMutation();

  const modal = useModal();
  const [selectedProcedures, setSelectedProcedures] = useState<Procedure[]>([]);
  const [companyId, setCompanyId] = useState<number>();
  const [isCreateTask, setIsCreateTask] = useState<boolean>(true);
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>();
  const permissions = useStore($UserAddPermissions);

  const handleSelectAll = (checked: boolean) => {
    if (!table) return;

    if (checked) {
      setSelectedProcedures(
        table.body
          .filter((procedure) =>
            isAllowedToCreateTrainingRequest(procedure, companyId)
          )
          .map((procedure) => procedure)
      );
    } else {
      setSelectedProcedures([]);
      setCompanyId(undefined);
    }
  };

  const handleSelectProcedure = (procedure: Procedure, checked: boolean) => {
    if (checked) {
      setSelectedProcedures((prev) => [...prev, procedure]);
      setCompanyId(procedure.companyId);
    } else {
      setSelectedProcedures((prev) =>
        prev.filter((_procedure) => _procedure.id !== procedure.id)
      );

      if (selectedProcedures.length - 1 === 0) {
        setCompanyId(undefined);
      }
    }
  };

  const handleCreateTrainingRequest = () => {
    if (!table) return;

    const selectedCompanies = [
      ...new Set(selectedProcedures.map((employee) => employee.companyId)),
    ];

    if (!selectedCompanies.length) {
      if (selectedProcedures.length === 0) {
        modal.open("NotificationModal", {
          btnText: "Ок",
          modalData: {
            text: "Выберите сотрудников, по которым нужно сформировать заявку на обучение!",
          },
        });
      }
      return;
    }

    if (selectedCompanies.length > 1) {
      return modal.open("NotificationModal", {
        btnText: "Ок",
        modalData: {
          text: "Нельзя создать заявку для сотрудников из разных организаций",
        },
      });
    }
    setIsCreateTask(false);
    setOpen(true);
    setSelectedCompanyId(selectedCompanies[0]);
  };

  const onClose = () => {
    setSelectedCompanyId(undefined);
    setIsCreateTask(false);
    setOpen(false);
  };
  const onSubmit = () => {
    const students = selectedProcedures.map((procedure) => ({
      programId: procedure.programId,
      employeeId: procedure.employeeId,
    }));

    const title = `Заявка на обучение от ${moment().format("DD.MM.YYYY")}`;
    if (!selectedCompanyId) return;
    createTrainingRequestMutation.mutate(
      {
        title,
        customerId: selectedCompanyId,
        executorId: selectedCompanyId,
        students,
        isCreateTask: isCreateTask,
      },
      {
        onSuccess: (data) => {
          onClose();
          history.push(`/company/request-training?id=${data.id}`);
        },
      }
    );
  };
  return (
    <div>
      <Modal open={open} onClose={onClose} className={styles.modalConfirm}>
        <div className={styles.modalConfirm_head}>Подтвердите действие</div>
        <div className={styles.modalConfirm_text}>
          Вы хотите создать заявку?
        </div>
        <div className={styles.modalConfirm_check}>
          <Checkbox
            checked={isCreateTask}
            onChange={(e) => setIsCreateTask(!isCreateTask)}
            name="isFinancial"
            color="primary"
            icon={<CheckboxSVG />}
            checkedIcon={<CheckboxCheckedSVG />}
          />
          Поставить задачу в план работ
        </div>
        <div className={styles.modalConfirm_buttons}>
          <Button onClick={onSubmit} className={styles.buttonYes}>
            Да
          </Button>
          <Button color={"white-green"} borderColor={"green"} onClick={onClose}>
            Отменить
          </Button>
        </div>
      </Modal>
      <div className={styles.table_name}>
        {withName && <div>{table?.title}</div>}
        {withCreateTrainingRequest &&
          type === "training" &&
          permissions.hasPermission(
            ACTIONS.companyApplicationsForTraining_allowedToCreate
          ) && (
            <Button
              disabled={createTrainingRequestMutation.isLoading}
              onClick={handleCreateTrainingRequest}
            >
              Сформировать заявку на обучение
            </Button>
          )}
      </div>
      <div className={styles.table_wrapper}>
        <TableCategory
          table={table}
          type={type}
          selectedProcedures={selectedProcedures}
          companyId={companyId}
          onSelectAll={handleSelectAll}
          onSelectProcedure={handleSelectProcedure}
          {...props}
        />
      </div>
    </div>
  );
};
