import { FC } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { Grid, TextField } from "@material-ui/core";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import style from "@scss/modals/company/create-task.module.scss";
import {
  CreateTaskModalForm,
  CreateTaskModalTabProps,
} from "./create-task-modal";
import clsx from "clsx";
import { useModal } from "@modals/index";

export const CreateTaskModalMainTab: FC<CreateTaskModalTabProps> = ({
  className,
  touchField,
}) => {
  const { modalData } = useModal();

  const isEditMode = !!modalData.editMode;

  const isTaskEditable = modalData.event ? modalData.event.status === 1 : true;
  const isDeadlineEditable = modalData.event
    ? [1, 2].includes(modalData.event.status)
    : true;

  const {
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<CreateTaskModalForm>();

  const [startDate, endDate] = watch(["startDate", "endDate"]);

  return (
    <div className={clsx(style.createTaskModal__mainTab, className)}>
      <TextField
        label="Название задачи"
        variant="filled"
        required
        inputProps={{ maxLength: 255 }}
        disabled={!isTaskEditable}
        {...register("title", {
          required: "Обязательное поле",
        })}
      />
      <p className="error-text">{errors.title?.message}</p>
      <TextField
        label="Описание"
        variant="filled"
        required
        multiline
        minRows={10}
        maxRows={15}
        inputProps={{ maxLength: 16777216 }}
        disabled={!isTaskEditable}
        {...register("description", {
          required: "Обязательное поле",
        })}
      />
      <p className="error-text">{errors.description?.message}</p>
      <div className="create-event-modal-pickers">
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale={"ru"}
        >
          <Grid container className={clsx(style.main_pickers_container)}>
            <Grid item xs={12} md={6}>
              <p>Начало</p>
              <Controller
                control={control}
                name="startDate"
                rules={{
                  validate: {
                    should_be_in_future: (value) => {
                      if (isEditMode) return true;

                      if (moment(value).isBefore(moment())) {
                        return "Дата не может быть в прошлом";
                      }
                      return true;
                    },
                  },
                  onChange: (e) => {
                    touchField("startDate");
                    const _startDate = moment(e.target.value);
                    const _endDate = moment(endDate);
                    if (_startDate.isAfter(_endDate)) {
                      touchField("endDate");
                      setValue(
                        "endDate",
                        _startDate.add(1, "hour").toISOString()
                      );
                    }
                  },
                }}
                render={({ field }) => (
                  <Grid container className={clsx(style.pickers_container)}>
                    <Grid item xs={12} sm={5}>
                      <KeyboardTimePicker
                        cancelLabel="Отменить"
                        okLabel="ОК"
                        className={clsx(style.pickers_input)}
                        format="HH:mm"
                        ampm={false}
                        margin="normal"
                        label="Время"
                        value={moment(field.value)}
                        onChange={(value) =>
                          field.onChange(value.toISOString())
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                        disabled={!isTaskEditable}
                        error={!!errors.startDate}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <KeyboardDatePicker
                        cancelLabel="Отменить"
                        okLabel="ОК"
                        className={clsx(style.pickers_input)}
                        disableToolbar
                        variant="dialog"
                        format="DD.MM.yyyy"
                        margin="normal"
                        label="Дата"
                        value={moment(field.value)}
                        onChange={(value) =>
                          field.onChange(value.toISOString())
                        }
                        minDate={moment()}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        error={!!errors.startDate}
                        disabled={!isTaskEditable}
                      />
                    </Grid>
                  </Grid>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <p>Крайний срок</p>
              <Controller
                control={control}
                name="endDate"
                rules={{
                  validate: {
                    should_be_in_future: (value) => {
                      if (isEditMode) return true;

                      if (moment(value).isBefore(moment())) {
                        return "Дата не может быть в прошлом";
                      }
                      return true;
                    },
                  },
                  onChange: (e) => {
                    touchField("endDate");
                    const value = e.target.value;
                    const _startDate = moment(startDate);
                    const _endDate = moment(value);
                    if (_endDate.isBefore(_startDate)) {
                      touchField("startDate");
                      setValue(
                        "startDate",
                        _endDate.subtract(1, "hour").toISOString()
                      );
                    }
                  },
                }}
                render={({ field }) => (
                  <Grid container className={clsx(style.pickers_container)}>
                    <Grid item xs={12} sm={5}>
                      <KeyboardTimePicker
                        cancelLabel="Отменить"
                        okLabel="ОК"
                        className={clsx(style.pickers_input)}
                        format="HH:mm"
                        ampm={false}
                        margin="normal"
                        label="Время"
                        value={moment(field.value)}
                        onChange={(value) =>
                          field.onChange(value.toISOString())
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change time",
                        }}
                        error={!!errors.endDate}
                        disabled={!isDeadlineEditable}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <KeyboardDatePicker
                        cancelLabel="Отменить"
                        okLabel="ОК"
                        className={clsx(style.pickers_input)}
                        disableToolbar
                        variant="dialog"
                        format="DD.MM.yyyy"
                        margin="normal"
                        label="Дата"
                        value={moment(field.value)}
                        onChange={(value) =>
                          field.onChange(value.toISOString())
                        }
                        minDate={moment()}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        error={!!errors.endDate}
                        disabled={!isDeadlineEditable}
                      />
                    </Grid>
                  </Grid>
                )}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        <p className="error-text">
          {errors.startDate?.message || errors.endDate?.message}
        </p>
      </div>
    </div>
  );
};
