import { AxiosError, AxiosResponse } from "axios";

import React from "react";
import { UserRoleEnum } from "@store/user-store";
import { WarningT } from "./warning";

export type ResCallback<T> = (
  err: AxiosError | null,
  res?: AxiosResponse<T>
) => void;

export type EventStateStoreT = {
  isFetched: boolean;
  isLoading: boolean;
  error: boolean;
};

export type UserAdditionalPermissions = {
  id: number;
  // modules: Modules[],
  modules: string[];
  roleId: UserRoleEnum;
  priority: number;
  permissions?: string[];
};

export enum Modules {
  NONE = "",
  USERS = "USERS",
  COMPANY_INFO = "COMPANY_INFO",
  WORKSPACE = "WORKSPACE",
  SUBDIVISION = "SUBDIVISION",
  EMPLOYEES = "EMPLOYEES",
  CALENDAR = "CALENDAR",
  ARCHIVE = "ARCHIVE",
  PRESCRIPTIONS = "PRESCRIPTIONS",
  SPECIALIST_PLAN = "SPECIALIST_PLAN",
}

export interface ApiData {
  success: boolean;
  data: Array<{ [key: string]: any }> | { [key: string]: any };
  count: number;
  limit: number;
}

export type ItemsOfStringArrayT = {
  items: string[];
};

export type HeaderPropsT = {
  menuDisplay: boolean;
  onBurgerClick: () => void;
  unauthorized?: boolean;
};

export type MenuBurgerIconsPropsT = {
  menuDisplay: boolean;
  onBurgerClick: () => void;
};

export type MenuPanelPropsT = {
  onItemClick: () => void;
};

export type MenuItemT = {
  text: string;
  imageSrc: string;
  link: string;
  notification?: boolean;
  attention?: boolean;
  attentionText?: string;
};

export type MenuItemsPropsType = {
  items: MenuItemT[];
};

export type PageTabPropsT = {
  label: string;
  path: string;
  exact?: boolean;
};

export interface TabItemsPropsT {
  items: PageTabPropsT[];
  warning?: WarningT;
  warningLoading?: boolean;
  currentPath?: any;
}

export type TableTopPanelPropsT = {
  text: string;
  options?: SortOptionT[];
  onSearch?: (value: string) => void;
  onSelectOption?: (option: SortOptionT) => void;
  hideSearchPanel?: boolean;
  hideSelectPanel?: boolean;
  withoutPadding?: boolean;
};

export type TableSearchProcedurePropsT = {
  text: string;
  options?: SortOptionT[];
  onSearch?: (value: string) => void;
  onSelectOption?: (option: SortOptionT) => void;
  hideSearchPanel?: boolean;
  hideSelectPanel?: boolean;
  withoutPadding?: boolean;
};
export type SortOptionT = {
  label: string;
  value: number;
};

// COMMON COMPONENTS

export type FileT = {
  filename: string;
  originalName: string;
  size: number;
  _id: string;
};

export type ColorfulActionButtonPropsT = {
  link?: string;
  text?: string;
  plusIcon?: boolean;
  customIcon?: any;
  type?: "button" | "submit" | "reset";
  className?: string;
  onClick?:
    | React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
    | undefined;
};

export type TitlePropsT = {
  text: string;
  withHistory?: boolean;
};

export type FileForViewButtonT = {
  id: number;
  name: string;
  path: string;
};
