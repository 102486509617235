import { useMutation, useQueryClient } from "react-query";
import {
  DeleteInternalMaterialData,
  internalStudyCenterService,
} from "@services/internal-study-center-service";
import { internalStudyCenterQueryKeys } from "./queryKeys";

export const useDeleteInternalMaterialMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: DeleteInternalMaterialData) =>
      internalStudyCenterService.deleteMaterial(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(internalStudyCenterQueryKeys.all);
      },
    }
  );
};
