// STORE
import {
  $Employees,
  setEmployees,
  setEmployeesFetched,
} from "@store/company/employees-store";
import {
  CreateNewPositionPropsT,
  CreateNewDepartmentPropsT,
  UpdatePositionPropsT,
  UpdateDepartmentPropsT,
} from "@interfaces/store/departments-store-types";
import { createEffect, createEvent, createStore } from "effector";

import { EventStateStoreT } from "@interfaces/common";

import DepartmentService from "@services/department-service";
import PositionsService from "@services/positions-service";

import { DepartmentShortDataT } from "@interfaces/company/departments";
import { resetAllStates } from "@store/user-store";

// DEPARTMENTS DATA
export const setDepartments = createEvent<DepartmentShortDataT[]>();

export const $Departments = createStore<DepartmentShortDataT[]>([])
  .on(setDepartments, (_, newState) => newState)
  .reset(resetAllStates);

// DEPARTMENTS STATES
export const setDepartmentStateLoading = createEvent<boolean>();
export const setDepartmentStateError = createEvent<boolean>();
const setDepartmentStateFetched = createEvent<boolean>();
const setDepartmentStates = createEvent<EventStateStoreT>();

export const $DepartmentState = createStore<EventStateStoreT>({
  isLoading: true,
  error: false,
  isFetched: false,
})
  .on(setDepartmentStateLoading, (oldState, newState) => ({
    ...oldState,
    isLoading: newState,
  }))
  .on(setDepartmentStateError, (oldState, newState) => ({
    ...oldState,
    isLoading: false,
    error: true,
  }))
  .on(setDepartmentStateFetched, (oldState, newState) => ({
    ...oldState,
    isFetched: newState,
  }))
  .on(setDepartmentStates, (_, newState) => newState)
  .reset(resetAllStates);

export const fetchDepartments = createEffect(async () => {
  DepartmentService.getAll((err, res) => {
    if (err || !res) {
      return setDepartmentStateError(true);
    }
    setDepartments(res.data);
    setDepartmentStates({
      isLoading: false,
      error: false,
      isFetched: true,
    });
  });
});

export const createNewDepartment = createEffect<CreateNewDepartmentPropsT>(
  async ({ companyId, title, cb }) => {
    DepartmentService.create(companyId, title, (err, res) => {
      if (err || !res) {
        return cb(err);
      }
      setDepartments([...$Departments.getState(), res.data]);
      cb(null);
    });
  }
);

export const updateDepartment = createEffect<UpdateDepartmentPropsT>(
  async ({ departmentId, title, cb }) => {
    DepartmentService.update(departmentId, title, (err, res) => {
      if (err || !res) {
        return cb(err);
      }
      setDepartments(
        $Departments.getState().map((department) => {
          if (department.id === departmentId) {
            department.title = title;
          }
          return department;
        })
      );
      cb(null);
    });
  }
);

export const removeDepartment = createEffect<(p: { id: number }) => void>(
  async ({ id }) => {
    DepartmentService.delete(id, (err, res) => {
      if (err || !res) {
        return console.error("При удалении подразделения произошла ошибка");
      }
      const departments = $Departments.getState();
      const departmentIndex = departments.findIndex(
        (department) => department.id === id
      );

      if (departments[departmentIndex].employeesCount > 0) {
        // ITS DOING TO RE-FETCH EMPLOYEES DATA WHEN USER WILL VISIT EMPLOYEES PAGE
        // ( DO THIS IF EMPLOYEES COUNT GREATER THAN 0)
        setEmployeesFetched(false);
      }
      departments.splice(departmentIndex, 1);
      setDepartments(Array.from(departments));
    });
  }
);

export const createNewPosition = createEffect<CreateNewPositionPropsT>(
  async ({
    departmentId,
    title,
    briefingPrograms,
    trainingPrograms,
    medicalExaminationFactors,
    cb,
  }) => {
    PositionsService.create(
      departmentId,
      title,
      briefingPrograms,
      trainingPrograms,
      medicalExaminationFactors,
      (err, res) => {
        if (err || !res) {
          return cb(err);
        }
        setDepartments(
          $Departments.getState().map((department) => {
            if (department.id === departmentId) {
              department.positions.push(res.data);
            }
            return department;
          })
        );
        cb(null, res);
      }
    );
  }
);

export const updatePosition = createEffect<UpdatePositionPropsT>(
  async ({
    positionId,
    departmentId,
    title,
    briefingPrograms,
    trainingPrograms,
    medicalExaminationFactors,
    cb,
  }) => {
    console.log("updatePosition: ", { medicalExaminationFactors });
    PositionsService.update(
      departmentId,
      positionId,
      title,
      briefingPrograms,
      trainingPrograms,
      medicalExaminationFactors,
      (err, res) => {
        if (err || !res) {
          return cb(err);
        }
        setDepartments(
          $Departments.getState().map((department) => {
            if (department.id === departmentId) {
              department.positions = department.positions.map((position) => {
                if (position.id === positionId) {
                  return res.data;
                }
                return position;
              });
            }
            return department;
          })
        );
        setEmployees(
          $Employees.getState().map((employee) => {
            if (employee.position.id === positionId) {
              employee.position.title = title;
            }

            return employee;
          })
        );
        cb(null, res);
      }
    );
  }
);
