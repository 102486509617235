import { DocumentTemplatesCommonParams } from "@interfaces/company/document-templates";
import { documentTemplateService } from "@services/document-templates-service";
import { useMutation, useQueryClient } from "react-query";
import { documentTemplatesQueryKeys } from "./queryKeys";

export const useDeleteDocumentTemplatesDirectoryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: DocumentTemplatesCommonParams) =>
      documentTemplateService.deleteDirectory(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(documentTemplatesQueryKeys.all);
      },
    }
  );
};
