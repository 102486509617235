import { createEffect, createEvent, createStore } from "effector";

export type DeviceTypeT = {
  isMobile?: boolean;
  isPhone?: boolean;
};

const setDeviceType = createEvent<DeviceTypeT>("Изменение состояния");

export const $DeviceType = createStore<DeviceTypeT>({}).on(
  setDeviceType,
  (state, newData) => newData
);

export const getDeviceType = createEffect((width: number) => {
  const isMobile = width <= 1024;
  const isPhone = width <= 768;

  setDeviceType({ isMobile, isPhone });
});
