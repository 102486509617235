import { EmployeeListDataT } from "@interfaces/company/employees";

export type DocumentArchiveTablePropsT = {
  className?: string;
};

// DIRECTORIES
export type ArchiveFileT = {
  readonly id: number;
  title: string;
  updatedAt: string;
};

export type ArchiveDirectoryDataT = {
  readonly id: number;
  title: string;
  updatedAt: string;
};

// EMPLOYEES
export type ArchiveEmployeeDataT = EmployeeListDataT;

export type EmployeeArchiveItemT = {
  id: number;
  name: string;
  department: string;
  position: string;
  roleId: 1 | /* СПЕЦИАЛИСТ ОТ */ 2 /* СОТРУДНИК */;
};

export enum ArchiveDocumentTypeEnum {
  document = 1,
  employee,
}
