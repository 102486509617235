import {
  GetCategoryMaterialsData,
  internalStudyCenterService,
} from "@services/internal-study-center-service";
import { useQuery } from "react-query";
import { internalStudyCenterQueryKeys } from "./queryKeys";

export const useInternalCategoryMaterialsQuery = (
  data: GetCategoryMaterialsData
) => {
  const { data: materials, ...stuff } = useQuery(
    internalStudyCenterQueryKeys.materials(data),
    () => internalStudyCenterService.getCategoryMaterials(data)
  );

  return {
    materials,
    ...stuff,
  };
};
