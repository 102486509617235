import { Box, Flex, Text } from "@mantine/core";

import { ProcedureProgram } from "@interfaces/company/procedure";
import React from "react";
import { TRAINING_PLACES } from "./edit-learning-program-modal";

interface ViewTrainingProgramDepartmentProps {
  item: ProcedureProgram;
  trainingProgramsValue: { id: number; location: string }[];
}

const ViewTrainingProgramDepartment: React.FC<
  ViewTrainingProgramDepartmentProps
> = ({ item, trainingProgramsValue }) => {
  const indexInSelectedList = trainingProgramsValue.findIndex(
    (prog) => prog.id === item.id
  );
  const currentItemInSelectedList = trainingProgramsValue[indexInSelectedList];
  const itemPlace = currentItemInSelectedList.location;

  const correctPlace = TRAINING_PLACES.find(
    (option) => option.value === itemPlace
  )?.label;
  return (
    <Flex
      wrap="wrap-reverse"
      sx={{
        borderBottom: "1px solid white",
        padding: "5px 42px",
        minHeight: 62,
        rowGap: 10,
        columnGap: 30,
      }}
    >
      <Text sx={{ width: "100%", minWidth: 300, maxWidth: 450 }}>
        {item.title}
      </Text>
      <Box sx={{ flex: 1 }}>
        <Text size="sm">{correctPlace}</Text>
      </Box>
    </Flex>
  );
};

export default ViewTrainingProgramDepartment;
