import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { ArrowDropDownGraySVG } from "@components/svgs";
import { TableBodyRow } from "../table-body-row";
import { TrainingRequest } from "@interfaces/request-training";
import { UseSortingResult } from "@hooks/useSorting";
import clsx from "clsx";
import styles from "./table-training.module.scss";
import { v4 as uuidv4 } from "uuid";

type TableTrainingProps = {
  data: TrainingRequest[];
  sorting: UseSortingResult;
};

export const TableTraining: React.FC<TableTrainingProps> = ({
  data,
  sorting,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <Table className={styles.table_wrapper} aria-label="caption table">
          <TableHead>
            <TableRow>
              {head.map((item) => (
                <TableCell
                  key={uuidv4()}
                  align="right"
                  className={styles.table_head}
                >
                  <div className={styles.table_head_wrapper}>
                    <div className={styles.table_head_name}>{item.title}</div>
                    {item.id === 3 && (
                      <div
                        className={styles.table_head_sort}
                        tabIndex={0}
                        onClick={() =>
                          sorting.onItemSortingChange("dateCreationOrder")
                        }
                      >
                        <div
                          className={clsx(styles.table_head_sortIcon, {
                            [styles.table_head_sortIcon_rotated]:
                              sorting.shouldIconRotate("dateCreationOrder"),
                          })}
                        >
                          <ArrowDropDownGraySVG />
                        </div>
                      </div>
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableBodyRow key={uuidv4()} index={index} request={item} />
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

type HeadT = {
  id: number;
  title: string;
}[];

const head: HeadT = [
  {
    id: 1,
    title: "№",
  },
  {
    id: 2,
    title: "Название",
  },
  {
    id: 3,
    title: "дата заявки",
  },
  {
    id: 4,
    title: "комментарий",
  },
  {
    id: 5,
    title: "статус заявки",
  },
];
