import { GetCategoryMaterialsData } from "@services/internal-study-center-service";

export const internalStudyCenterQueryKeys = {
  all: ["internal-study-center"],
  sectionsCategories: () => [
    ...internalStudyCenterQueryKeys.all,
    "sections-categories",
  ],
  categories: () => [...internalStudyCenterQueryKeys.all, "categories"],
  materials: (data: GetCategoryMaterialsData) => [
    ...internalStudyCenterQueryKeys.all,
    "materials",
    data,
  ],
  materialById: (
    id: number | undefined,
    sectionId: number | undefined,
    categoryId: number | undefined
  ) => [
    ...internalStudyCenterQueryKeys.materials({
      sectionId: sectionId ?? 0,
      categoryId: categoryId ?? 0,
    }),
    id,
  ],
};
