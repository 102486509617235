import { WorkspaceGetFilesParams } from "@interfaces/company/workspace";

export const workspaceQueryKeys = {
  all: ["workspace"],
  allGroups: ["workspace-groups"],
  groups: (companyId: number | undefined) => [
    ...workspaceQueryKeys.allGroups,
    companyId,
  ],
  allDirectoriesFiles: ["workspace-directories-files"],
  directoriesFiles: (
    companyId: number | undefined,
    groupId: number,
    directoryId: number | undefined,
    params?: WorkspaceGetFilesParams
  ) => [
    ...workspaceQueryKeys.allDirectoriesFiles,
    {
      companyId,
      groupId,
      directoryId,
    },
    params,
  ],
  groupFiles: (companyId: number | undefined, groups: number[]) => [
    ...workspaceQueryKeys.all,
    "group",
    {
      companyId,
      groups,
    },
  ],
};
