import { BackButton } from "@components/back-button";
import { FooterEditRequest } from "../footer-edit-request";
import { Loader } from "@ui/indicators";
import { PostTypeDocuments } from "../post-type-documents";
import { QueryInfo } from "../query-info";
import { TableForUserTraining } from "../table-for-user-training";
import { TableNewEmployees } from "../table-new-employees/table-new-employees";
import { Warning } from "@phosphor-icons/react";
import styles from "./edit-request-training.module.scss";
import { useParams } from "react-router-dom";
import { useTrainingRequestQuery } from "@lib/request-training";

export const EditRequestTraining: React.FC = () => {
  const { id } = useParams<{ id?: string }>();

  const { training, isError, isLoading, isSuccess } =
    useTrainingRequestQuery(id);

  return (
    <div className={styles.content}>
      {isLoading && <Loader />}
      {!isLoading && isSuccess && (
        <>
          <div className={styles.content__close}>
            <BackButton />
          </div>
          <QueryInfo training={training} />
          <TableNewEmployees training={training} />
          <PostTypeDocuments training={training} />
          <TableForUserTraining training={training} />
          <FooterEditRequest training={training} />
        </>
      )}
      {!isLoading && isError && (
        <div className={styles.warning}>
          <Warning size={64} />
          <div>По данному запросу ничего не найдено</div>
        </div>
      )}
    </div>
  );
};
