import { useQuery } from "react-query";
import { GetMedicalExamEmployeeReferralParams } from "@interfaces/medical-examination";
import { MedicalExaminationService } from "@services/medical-examination.service";
import { medicalExaminationQueryKeys } from "./queryKeys";

export const useEmployeeMedicalExamReferralQuery = (
  params: GetMedicalExamEmployeeReferralParams
) => {
  return useQuery(medicalExaminationQueryKeys.employeeReferral(params), () =>
    MedicalExaminationService.getEmployeeReferral(params)
  );
};
