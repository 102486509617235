import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { ColorfulButton, Title } from "@components/common/common";
import { ErrorIndicator, Loader } from "@ui/indicators";

import { BreadCrumb } from "@components/common";
import NewsItems from "@components/news-components";
import { useStore } from "effector-react";
import { useNewsQuery } from "@lib/news";

const itemsPerPage = 16;

const LegislationNews = () => {
  const permissions = useStore($UserAddPermissions);

  const { data, isLoading, error, hasNextPage, fetchNextPage } = useNewsQuery({
    limit: itemsPerPage,
    type: "LEGISLATION-NEWS",
  });

  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={["Главная", "Новости законодательства"]} />
          <div className="content-title-section-with-btn">
            <Title text="Новости законодательства" />
            {permissions.hasPermission(
              ACTIONS.legislationNews_allowedToCreate
            ) && (
              <ColorfulButton
                link={"/legislation-news/create"}
                text={"Создать новость"}
              />
            )}
          </div>
        </div>
        {error ? (
          <ErrorIndicator />
        ) : isLoading ? (
          <Loader />
        ) : (
          data?.pages.map((page) => (
            <NewsItems items={page.rows} path="legislation-news" />
          ))
        )}
        {hasNextPage && (
          <button className="pagination-button" onClick={() => fetchNextPage()}>
            Показать еще
          </button>
        )}
      </div>
    </main>
  );
};

export default LegislationNews;
