import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { ErrorIndicator, Loader } from "@ui/indicators";
import { Trash, UploadSimple } from "@phosphor-icons/react";

import React from "react";
import { Tooltip } from "@material-ui/core";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import style from "@scss/pages/company/archive.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import { useArchivedWorkspaceEmployeeQuery } from "@lib/workspace/useWorkspaceEmployeeQuery";
import { useDeleteEmployeeMutation } from "@lib/workspace/useDeleteEmployee";
import { useStore } from "effector-react";
import {
  useUnarchiveWorkspaceEmployeeMutation,
  useUnarchiveWorkspaceEmployeesMutation,
} from "@lib/workspace/useUnarchiveWorkspaceEmployesMutation";
import { useChoiceState } from "@hooks/useChoiceState";
import { useSelectedCompanyQuery } from "@lib/company";

const ArchiveEmployeeTable: React.FC = () => {
  const { company } = useSelectedCompanyQuery();

  const permissions = useStore($UserAddPermissions);

  const { employees, isLoading, error } = useArchivedWorkspaceEmployeeQuery(
    company?.id
  );

  const employeesChoice = useChoiceState(
    employees ?? [],
    (employee) => employee.id
  );

  const unarchiveWorkspaceEmployeeMutation =
    useUnarchiveWorkspaceEmployeeMutation();
  const unarchiveWorkspaceEmployeesMutation =
    useUnarchiveWorkspaceEmployeesMutation();

  const deleteEmployeeMutation = useDeleteEmployeeMutation();

  const handleUnarchiveEmployee = (employeeId: number) => {
    if (!company) return;

    unarchiveWorkspaceEmployeeMutation.mutate({
      employeeId,
      companyId: company.id,
    });
  };

  const deleteEmployee = (employeeId: number) => {
    if (!company) return;

    deleteEmployeeMutation.mutate({
      employeeId,
      companyId: company.id,
    });
  };

  const handleUnarchiveItems = () => {
    if (!company) return;

    const employees = employeesChoice.selectedItems.map(
      (employee) => employee.id
    );

    unarchiveWorkspaceEmployeesMutation.mutate(
      {
        companyId: company.id,
        employees,
      },
      {
        onSuccess: () => {
          employeesChoice.clearSelectedItems();
        },
      }
    );
  };

  const handleDeleteItems = () => {
    if (!company) return;

    for (const employee of employeesChoice.selectedItems) {
      deleteEmployeeMutation.mutate({
        companyId: company.id,
        employeeId: employee.id,
      });
    }

    employeesChoice.clearSelectedItems();
  };

  const tableBodyContent = employees?.map((employee) => {
    const checkboxId = `employee-${employee.id}-checkbox`;

    const firstRow = (
      <label className={clsx(tableStyle.column_fixed_height)}>
        <input
          type="checkbox"
          className={clsx(tableStyle.checkbox_item)}
          hidden
          id={checkboxId}
          checked={employeesChoice.getIsItemSelected(employee)}
          onChange={(e) =>
            employeesChoice.selectById(employee, e.target.checked)
          }
        />
        <label htmlFor={checkboxId}>
          <svg
            className="w-5 h-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </label>
        <label htmlFor={`key`} className={clsx(tableStyle.checkbox_label)}>
          {employee.name}
        </label>
      </label>
    );

    return (
      <tr key={employee.id}>
        <td>{firstRow}</td>
        <td>{employee?.position?.department?.title}</td>
        <td>{employee?.position?.title}</td>
        <td>
          <div className={style.removeOrUnarchive}>
            {permissions.hasPermission(
              ACTIONS.companyStaffing_employees_allowedToRestoreFromArchive
            ) ? (
              <Tooltip title="Вернуть" placement={"top"}>
                <button onClick={() => handleUnarchiveEmployee(employee.id)}>
                  <WrapperSVG color="green-light">
                    <UploadSimple size={24} className={clsx(style.edit_icon)} />
                  </WrapperSVG>
                </button>
              </Tooltip>
            ) : null}
            {permissions.hasPermission(
              ACTIONS.companyStaffing_employees_allowedToDeleteFromArchive
            ) ? (
              <Tooltip title="Удалить" placement={"top"}>
                <button onClick={() => deleteEmployee(employee.id)}>
                  <Trash size={24} className={clsx(style.edit_icon)} />
                </button>
              </Tooltip>
            ) : null}
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div
      className={clsx(tableStyle.base_table_container, style.employee_table)}
    >
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorIndicator />
      ) : (
        <table className={clsx(tableStyle.base_table)}>
          <thead>
            <tr>
              <td>
                <label>
                  <input
                    type="checkbox"
                    className={clsx(tableStyle.checkbox_item)}
                    hidden
                    id="employees-all-checkbox"
                    checked={employeesChoice.areAllItemsSelected}
                    onChange={(e) =>
                      employeesChoice.selectAll(e.target.checked)
                    }
                  />
                  <label htmlFor="employees-all-checkbox">
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </label>
                  <label className={clsx(tableStyle.checkbox_label)}>
                    Сотрудник
                  </label>
                </label>
              </td>
              <td>Подразделение</td>
              <td>Должность</td>
              <td>
                {employeesChoice.selectedItems.length > 0 && (
                  <div className={style.removeOrUnarchive}>
                    {permissions.hasPermission(
                      ACTIONS.companyStaffing_employees_allowedToRestoreFromArchive
                    ) ? (
                      <Tooltip title="Вернуть" placement={"top"}>
                        <button onClick={handleUnarchiveItems}>
                          <WrapperSVG color="green-light">
                            <UploadSimple
                              size={24}
                              className={clsx(style.edit_icon)}
                            />
                          </WrapperSVG>
                        </button>
                      </Tooltip>
                    ) : null}
                    {permissions.hasPermission(
                      ACTIONS.companyStaffing_employees_allowedToDeleteFromArchive
                    ) ? (
                      <Tooltip title="Удалить" placement={"top"}>
                        <button onClick={handleDeleteItems}>
                          <Trash size={24} className={clsx(style.edit_icon)} />
                        </button>
                      </Tooltip>
                    ) : null}
                  </div>
                )}
              </td>
            </tr>
          </thead>
          <tbody>{tableBodyContent}</tbody>
        </table>
      )}
    </div>
  );
};

export default ArchiveEmployeeTable;
