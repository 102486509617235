import {
  $ImportEmployees,
  removeImportedPosition,
} from "@store/company/import-employees-store";
import { $Departments, setDepartments } from "@store/company/departments-store";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { FileArrowUp, NotePencil, Trash } from "@phosphor-icons/react";
import {
  PositionT,
  PositionsTablePropsT,
} from "@interfaces/company/departments";

import { BodyNormal } from "@ui/fonts";
import { Button } from "@ui/button";
import { Link } from "react-router-dom";
import PositionsService from "@services/positions-service";
import React from "react";
import { Tooltip } from "@material-ui/core";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import style from "@scss/pages/company/departments.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";

const PositionTable: React.FC<PositionsTablePropsT> = ({
  items,
  departmentId,
}) => {
  const departments = useStore($Departments);
  const permissions = useStore($UserAddPermissions);
  const { missingPositions } = useStore($ImportEmployees);

  const { open } = useModal();

  const handleLeavePosition = (id: number) => {
    removeImportedPosition(id);
  };

  const handleDeletePosition = (position: PositionT) => {
    if (position.employees) {
      const employees = position.employees.filter(
        (employee) => !employee.isArchived
      );
      if (employees.length > 0) {
        return open("NotificationModal", {
          btnText: "Отмена",
          modalData: {
            modalTitle: "Внимание!",
            titleStyle: {
              color: "#e57373",
            },
            removeIcon: true,
            text: "Удаление запрещено. В должности находятся сотрудники!",
          },
        });
      }
    }

    PositionsService.delete(departmentId, position.id, (err, res) => {
      if (err) {
        return console.error("При удалении должности произошла ошибка");
      }

      removeImportedPosition(position.id);
      setDepartments(
        departments.map((department) => {
          if (department.id === departmentId) {
            department.positions = department.positions.filter(
              (_position) => _position.id !== position.id
            );
          }
          return department;
        })
      );
    });
  };

  const handleRemovePosition = (position: PositionT) => {
    open("ConfirmActionModal", {
      btnText: "Удалить",
      modalData: {
        text: `Вы уверены, что хотите удалить должность "${position.title}"?`,
      },
      onConfirm: () => {
        handleDeletePosition(position);
      },
    });
  };

  const tableBodyContent = items.map((position) => {
    const isPositionWithoutPrograms =
      position.trainingPrograms?.length === 0 ||
      position.briefingPrograms?.length === 0 ||
      position.medicalExaminationFactors?.length === 0;

    const firstRow = (
      <label htmlFor={`key`} className={clsx(tableStyle.column_fixed_height)}>
        <label
          htmlFor={`key`}
          className={clsx({
            [style.positionMissingPrograms__title]: isPositionWithoutPrograms,
          })}
        >
          {position.title}
        </label>
      </label>
    );

    return (
      <tr
        key={position.id}
        className={clsx({
          [style.positionMissingPrograms]: isPositionWithoutPrograms,
        })}
      >
        <td className={style.wrapperRow}>
          <Link
            to={`/company/departments/${departmentId}/${position.id}`}
            className={style.wrapperRow}
          >
            {firstRow}
          </Link>
        </td>
        <td>
          <div className={clsx(style.department_actions_col)}>
            {isPositionWithoutPrograms && (
              <BodyNormal className={style.positionMissingPrograms__message}>
                Необходимо добавить программы обучения, инструктажи и мед.
                осмотры
              </BodyNormal>
            )}
            {missingPositions.includes(position.id) && (
              <div className={style.importEmployeesActions}>
                <BodyNormal color={isPositionWithoutPrograms ? "white" : "red"}>
                  Должность отсутствует в списке
                </BodyNormal>
                <Button onClick={() => handleLeavePosition(position.id)}>
                  Оставить
                </Button>
                <Button
                  color="white"
                  border
                  borderColor="green"
                  onClick={() => handleDeletePosition(position)}
                >
                  Удалить
                </Button>
              </div>
            )}
            {permissions.hasPermission(
              ACTIONS.companyWorkspace_allowedToDistributionByDepartmentsAndPositions
            ) && (
              <Tooltip title="Добавить документы" placement="top">
                <button
                  className={style.department_actions_col__icon}
                  onClick={() =>
                    open("AddingDepartmentDocumentModal", {
                      modalData: {
                        itemId: position.id,
                        departmentId,
                        positionMode: true,
                      },
                    })
                  }
                >
                  <FileArrowUp size={24} />
                </button>
              </Tooltip>
            )}
            {permissions.hasPermission(
              ACTIONS.companyStaffing_positions_allowedToEditPositions
            ) && (
              <Tooltip title="Изменить" placement="top">
                <button
                  className={style.department_actions_col__icon}
                  onClick={() =>
                    open("CreatePositionModal", {
                      btnText: "Сохранить",
                      modalData: {
                        modalTitle:
                          "Изменить должность и добавить программы обучения и инструктажи",
                        fieldTitle: "Название",
                        positionMode: true,
                        editMode: true,
                        itemValue: position.title,
                        position: position,
                        departmentId: departmentId,
                      },
                    })
                  }
                >
                  <WrapperSVG color={"orange"}>
                    <NotePencil size={24} />
                  </WrapperSVG>
                </button>
              </Tooltip>
            )}
            {permissions.hasPermission(
              ACTIONS.companyStaffing_positions_allowedToDelete
            ) && (
              <Tooltip title="Удалить" placement="top">
                <button
                  className={style.department_actions_col__icon}
                  onClick={() => handleRemovePosition(position)}
                >
                  <Trash size={24} />
                </button>
              </Tooltip>
            )}
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div
      className={clsx(tableStyle.base_table_container, style.departments_table)}
    >
      <table className={clsx(tableStyle.base_table)}>
        <thead>
          <tr>
            <td>
              <label>
                <label className={clsx(tableStyle.checkbox_label)}>
                  Название должности
                </label>
              </label>
            </td>
            <td />
          </tr>
        </thead>
        <tbody>{tableBodyContent}</tbody>
      </table>
    </div>
  );
};

export default PositionTable;
