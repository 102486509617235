/**
 * @param date pass the date from which you want to retrieve the name of the month
 * @returns name of
 */
export type TMonthsName =
  | "января"
  | "февраля"
  | "марта"
  | "апреля"
  | "мая"
  | "июня"
  | "июля"
  | "августа"
  | "сентября"
  | "октября"
  | "ноября"
  | "декабря";

const DateToStringMonth = (date: Date): TMonthsName | null => {
  const months: Map<string, TMonthsName> = new Map([
    ["0", "января"],
    ["1", "февраля"],
    ["2", "марта"],
    ["3", "апреля"],
    ["4", "мая"],
    ["5", "июня"],
    ["6", "июля"],
    ["7", "августа"],
    ["8", "сентября"],
    ["9", "октября"],
    ["10", "ноября"],
    ["11", "декабря"],
  ]);
  const month = months.get(date.getMonth().toString()) ?? null;
  return month;
};
export default DateToStringMonth;
