import { FC, useState } from "react";
import { Text, Box, ScrollArea, Flex, Checkbox } from "@mantine/core";
import clsx from "clsx";
import { ChildrenProps } from "./create-position-modal";
import { useMedicalExaminationFactorsQuery } from "@lib/medical-examination";
import { ArrowDropDownSVG } from "@components/svgs";
import styles from "@scss/modals/medical-exams/medical-exams-modal.module.scss";
import { MedicalExaminationFactorT } from "@interfaces/medical-examination";

export const MedicalExamsModal: FC<ChildrenProps> = ({
  medicalExamsValue,
  setMedicalExamsValue,
  type,
}) => {
  const { data } = useMedicalExaminationFactorsQuery({
    isIncludeChanges: false,
  });

  return (
    <div className={styles.modal}>
      <div className={styles.modal__title}>Выбрать медицинские осмотры</div>
      <ScrollArea style={{ height: "50vh", maxHeight: "100%", minWidth: 250 }}>
        {data?.map((category) => (
          <Category
            key={category.id}
            title={category.title ?? ""}
            factors={category.factors}
            medicalExamsValue={medicalExamsValue}
            setMedicalExamsValue={setMedicalExamsValue}
            type={type}
          />
        ))}
      </ScrollArea>
    </div>
  );
};

type CategoryProps = {
  title: string;
  factors: MedicalExaminationFactorT[];
  medicalExamsValue: number[];
  type: "view" | "edit";
  setMedicalExamsValue: (value: number[]) => void;
};

const Category: FC<CategoryProps> = ({
  title,
  factors,
  medicalExamsValue,
  type,
  setMedicalExamsValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelectFactor = (factorId: number, checked: boolean) => {
    if (checked) {
      setMedicalExamsValue([...medicalExamsValue, factorId]);
    } else {
      setMedicalExamsValue(medicalExamsValue.filter((id) => id !== factorId));
    }
  };

  const filteredFactors =
    type === "view"
      ? factors.filter((factor) => medicalExamsValue.includes(factor.id))
      : factors;

  return (
    <div>
      <div
        className={styles.modal__categoryTitle}
        tabIndex={0}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div
          className={clsx(styles.modal__arrowIcon, {
            [styles.modal__arrowIcon_open]: isOpen,
          })}
        >
          <ArrowDropDownSVG />
        </div>
        <Text py={12}>{title}</Text>
      </div>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.colors.gray[0],
        })}
      >
        {isOpen && (
          <div>
            <div className={styles.modal__tableHead}>
              <Text size="md" sx={{ whiteSpace: "nowrap" }}>
                Наименование осмотра
              </Text>
            </div>
            {filteredFactors.map((factor) => (
              <FactorView
                key={factor.id}
                factor={factor}
                selected={medicalExamsValue.includes(factor.id)}
                type={type}
                onSelect={(checked) => handleSelectFactor(factor.id, checked)}
              />
            ))}
          </div>
        )}
      </Box>
    </div>
  );
};

type FactorViewProps = {
  factor: MedicalExaminationFactorT;
  selected: boolean;
  type: "view" | "edit";
  onSelect: (checked: boolean) => void;
};

const FactorView: FC<FactorViewProps> = ({
  factor,
  selected,
  type,
  onSelect,
}) => {
  return (
    <Flex
      wrap="wrap-reverse"
      sx={{
        borderBottom: "1px solid white",
        padding: "5px 42px",
        minHeight: 62,
        rowGap: 10,
        columnGap: 30,
      }}
    >
      {type === "view" ? (
        <Text sx={{ width: "100%", minWidth: 300, maxWidth: 550 }}>
          {factor.title}
        </Text>
      ) : type === "edit" ? (
        <Checkbox
          checked={selected}
          onChange={(e) => onSelect(e.target.checked)}
          styles={{
            body: { borderColor: "#00B856", alignItems: "center" },
            input: {
              borderColor: "#00B856",
              "&:checked": {
                backgroundColor: "#00B856",
                borderColor: "#00B856",
              },
            },
          }}
          mr={11}
          label={<Text>{factor.title}</Text>}
        />
      ) : null}
    </Flex>
  );
};
