import clsx from "clsx";

import useModal from "@modals/modal-hook";

import style from "@scss/modals/common/confirm-action.module.scss";

const ConfirmActionModal = () => {
  const { close, modalComponent, modalData } = useModal();

  return (
    <div
      key={modalComponent.key}
      className={clsx(style.confirm_modal)}
      style={modalData.style}
    >
      <p className="modal_title" style={modalData.titleStyle}>
        {modalData.title ?? "Подтвердите действие"}
      </p>
      <div className="underline" />
      <div className="modal_content">
        {!modalData.removeIcon && (
          <svg
            className={clsx(style.exclamation_icon)}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        )}
        {modalData.html ? (
          <p
            className={clsx(style.confirm_modal_text)}
            style={modalData.textStyle}
            dangerouslySetInnerHTML={{ __html: modalData.text }}
          />
        ) : (
          <p
            className={clsx(style.confirm_modal_text)}
            style={modalData.textStyle}
          >
            {modalData.text}
          </p>
        )}
        <div className="modal_buttons_group">
          <button
            type="submit"
            className={clsx("modal_btn", style.confirm_modal__btn)}
            onClick={(e) => {
              (e.target as HTMLButtonElement).disabled = true;
              close();
              return modalComponent.onConfirm
                ? modalComponent.onConfirm()
                : null;
            }}
          >
            {modalComponent.btnText}
          </button>
          {!modalData.removeCancelBtn && (
            <button
              type="submit"
              className={clsx(
                "modal_btn colorful-btn",
                style.confirm_modal__btn
              )}
              onClick={modalData.onCancel ?? close}
            >
              {modalData?.cancelBtnText ?? "Отменить"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmActionModal;
