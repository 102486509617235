import {
  ArticleType,
  getServiceByType,
  LegislationNewsT,
} from "@interfaces/legislation-news";
import { useQuery } from "react-query";
import { newsQueryKeys } from "./queryKeys";

export const useNewsItemQuery = (id: string | undefined, path: ArticleType) => {
  return useQuery(
    newsQueryKeys.itemById(path, id),
    async () => {
      return new Promise<LegislationNewsT>((resolve, reject) => {
        const service = getServiceByType(path);
        service.getById(id!, (err, res) => {
          if (err || !res) {
            reject(err);
          } else {
            resolve(res.data);
          }
        });
      });
    },
    {
      enabled: typeof id !== "undefined",
    }
  );
};
