import { EmployeeListDataT } from "./employees";

export enum SignatureStatusEnum {
  signed = 1,
  waitingForSignature,
  expired,
}

export type PositionCompanyDataT = {
  department: {
    companyId: number;
    id: number;
    title: string;
    departmentId: number;
  };
  id: number;
  parentId: null;
  title: string;
};

export type PositionShortDataT = {
  id: number;
  title: string;
  briefingPrograms: InstructionPrograms[];
  trainingPrograms: TrainingPrograms[];
  medicalExaminationFactors: MedicalExaminationFactors[];
};

export type PositionDataT = {
  id: number;
  title: string;
  briefingPrograms?: InstructionPrograms[];
  trainingPrograms?: TrainingPrograms[];
  medicalExaminationFactors?: MedicalExaminationFactors[];
  parentId: number | null;
};

export type PostPositionDataT = {
  title: string;
  instructionPrograms: number[];
  trainingPrograms: { id: number; location: string }[];
  parentId: number | null;
};

export type TrainingPrograms = {
  Pos_TrProg: {
    companyPositionId: number;
    id: number;
    location: string;
    trainingProgramId: number;
  };
  categoryId: number;
  code: string;
  termPrimaryConducting: number;
  id: number;
  title: string;
  period: number;
};

export type InstructionPrograms = {
  Pos_BriProg: {
    companyPositionId: number;
    id: number;
    briefingProgramId: number;
  };
  categoryId: number;
  id: number;
  termPrimaryConducting: number;
  title: string;
  type: null;
};

export type MedicalExaminationFactors = {
  id: number;
  title: string;
  inspectionFrequency: number;
  medicalSpecialists: string;
  laboratoryFunctionalStudies: string;
  position_medical_examinations: {
    id: number;
  };
};

export type DepartmentShortDataT = {
  id: number;
  title: string;
  employeesCount: number;
  positions: PositionShortDataT[];
};

export type DepartmentFileDataT = {
  id: number;
  title: string;
};

export type GetFilesForAddingToDepartmentPropsT = {
  departmentId: number;
  groupId: number;
  addedFilesIds?: number[] | null;
  minify?: string;
};

export type GetFilesForAddingToPositionPropsT = {
  departmentId: number;
  positionId: number;
  addedFilesIds?: number[] | null;
  groupId: number;
  minify?: string;
};

export type DepartmentFileForAddingToDepartmentAndPositionResT =
  DepartmentFileForAddingToPositionDataT[];

export type FileShortDataT = {
  id: number;
  name: string;
  path: string;
};

export type DepartmentFileForAddingToPositionDataT = Omit<
  FileShortDataT,
  "title"
> & {
  name: string;
  isAdded: boolean;
};

export type DocumentTablePropsT = {
  departmentId: number;
  positions: PositionShortDataT[];
};

export type SendingForSignatureDocumentT = {
  readonly id: number;
  title: string;
  path: string;
  hash: string;
};

export type DocumentSignatureHistoryT = {
  signer: {
    name: string;
  };
  position: {
    title: string;
  };
  signedAt: number;
  signatureEnd: number;
};

export type DocumentSignatureHistoryTablePropsT = {
  signatures: DocumentSignatureHistoryT[];
};

export type PositionT = {
  id: number;
  title: string;
  briefingPrograms: InstructionPrograms[];
  trainingPrograms: TrainingPrograms[];
  medicalExaminationFactors: MedicalExaminationFactors[];
  employees?: EmployeeListDataT[];
};

export type ShortDocumentT = {
  id: number;
  title: string;
  status: 1 /* ИМЕЕТСЯ */ | 2 /* ПРОСРОЧЕНА */ | 3 /* НЕТ ПОДПИСИ */;
};

export type DepartmentsTablePropsT = {};

export type PositionsTablePropsT = {
  items: PositionT[];
  departmentId: number;
};
