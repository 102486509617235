import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import React, { memo } from "react";
import { Route, Switch } from "react-router-dom";

import CreateNews from "./create-news";
import LegislationNews from "./legislationNews";
import Practice from "./practice";
import DevelopmentNews from "./development-news";
import SpecHelps from "@pages/news/spec-helps";
import ViewNews from "./view-news";
import { useStore } from "effector-react";
import { ProjectNews } from "feature/ProjectNews";
import CreateProjectNews from "feature/ProjectNews/CreateProjectNews/CreateProjectNews";

const NewsAndPracticeRoutes: React.FC = memo(() => {
  const permissions = useStore($UserAddPermissions);

  return (
    <Switch>
      <Route exact path={"/legislation-news"}>
        <LegislationNews />
      </Route>
      <Route exact path={"/practices"}>
        <Practice />
      </Route>
      <Route exact path={"/help"}>
        <SpecHelps />
      </Route>
      <Route exact path={"/development-news"}>
        <DevelopmentNews />
      </Route>
      <Route exact path="/project-news">
        <ProjectNews />
      </Route>
      {permissions.hasPermission(ACTIONS.legislationNews_allowedToCreate) && (
        <Route exact path="/legislation-news/create">
          <CreateNews mode="create" type="LEGISLATION-NEWS" />
        </Route>
      )}
      {permissions.hasPermission(ACTIONS.legislationNews_allowedToEdit) && (
        <Route exact path="/legislation-news/:id/edit">
          <CreateNews mode="edit" type="LEGISLATION-NEWS" />
        </Route>
      )}
      {permissions.hasPermission(ACTIONS.usefulMaterials_allowedToCreate) && (
        <Route exact path="/help/create">
          <CreateNews mode="create" type="HELP" />
        </Route>
      )}
      {permissions.hasPermission(ACTIONS.usefulMaterials_allowedToEdit) && (
        <Route exact path="/help/:id/edit">
          <CreateNews mode="edit" type="HELP" />
        </Route>
      )}
      {permissions.hasPermission(ACTIONS.judicialPractice_allowedToCreate) && (
        <Route exact path="/practice/create">
          <CreateNews mode="create" type="PRACTICE" />
        </Route>
      )}
      {permissions.hasPermission(ACTIONS.judicialPractice_allowedToEdit) && (
        <Route exact path="/practice/:id/edit">
          <CreateNews mode="edit" type="PRACTICE" />
        </Route>
      )}
      {permissions.hasPermission(ACTIONS.developmentNews_allowedToCreate) && (
        <Route exact path="/development-news/create">
          <CreateNews mode="create" type="DEVELOPMENT-NEWS" />
        </Route>
      )}
      {permissions.hasPermission(ACTIONS.developmentNews_allowedToEdit) && (
        <Route exact path="/development-news/:id/edit">
          <CreateNews mode="edit" type="DEVELOPMENT-NEWS" />
        </Route>
      )}
      {permissions.hasPermission(ACTIONS.projectNews_allowedToCreate) && (
        <Route exact path="/project-news/create">
          <CreateProjectNews mode="create" />
        </Route>
      )}
      {permissions.hasPermission(ACTIONS.projectNews_allowedToEdit) && (
        <Route exact path="/project-news/:id/edit">
          <CreateProjectNews mode="edit" />
        </Route>
      )}
      <Route exact path={"/practice/:id"}>
        <ViewNews path="PRACTICE" />
      </Route>
      <Route exact path={"/legislation-news/:id"}>
        <ViewNews path="LEGISLATION-NEWS" />
      </Route>
      <Route exact path={"/help/:id"}>
        <ViewNews path="HELP" />
      </Route>
      <Route exact path="/development-news/:id">
        <ViewNews path="DEVELOPMENT-NEWS" />
      </Route>
      <Route exact path="/project-news/:id">
        <ViewNews path="PROJECT-NEWS" />
      </Route>
    </Switch>
  );
});

export default NewsAndPracticeRoutes;
