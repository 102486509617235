import { BackButton } from "@components/back-button";
import { ColorfulButton } from "@components/common";
import { useAsyncConfirm } from "@hooks/useAsyncConfirm";
import { INTERNAL_IMAGES_BASE_URL } from "@lib/constants";
import { useDeleteInternalMaterialMutation } from "@lib/internal-study-center/useDeleteInternalMaterialMutation";
import { useInternalMaterialQuery } from "@lib/internal-study-center/useInternalMaterialQuery";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { Button } from "@ui/button";
import { ErrorIndicator, Loader } from "@ui/indicators";
import { Modal } from "@ui/modal";
import { SliderWithNav } from "@ui/sliders";
import { getTextWithAnchors } from "@utils/common-utils";
import clsx from "clsx";
import { useStore } from "effector-react";
import { FC, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import styles from "./public-material-page.module.scss";

type PublicMaterialsPageParams = {
  sectionId: string;
  categoryId: string;
  id: string;
};

export const PublicMaterialPage: FC = () => {
  const { sectionId, categoryId, id } = useParams<PublicMaterialsPageParams>();
  const history = useHistory();

  const confirm = useAsyncConfirm();
  const permissions = useStore($UserAddPermissions);

  const { material, isError, isLoading } = useInternalMaterialQuery(
    id ? +id : undefined,
    sectionId ? +sectionId : undefined,
    categoryId ? +categoryId : undefined
  );

  const deleteMaterialMutation = useDeleteInternalMaterialMutation();

  const [isFilesModalOpen, setIsFilesModalOpen] = useState(false);

  const handleDeleteMaterial = async () => {
    if (!id || !categoryId || !sectionId) return;

    const ok = await confirm({
      btnText: "Удалить",
      modalData: {
        text: `Вы уверены, что хотите удалить запись "${material?.title}"?`,
      },
    });
    if (!ok) return;

    deleteMaterialMutation.mutate(
      { sectionId: +sectionId, categoryId: +categoryId, id: +id },
      {
        onSuccess: () => {
          history.push("/study-center/public-materials");
        },
      }
    );
  };

  return (
    <div className={styles.materialPage}>
      <Modal
        open={isFilesModalOpen}
        className={styles.materialFilesModal}
        onClose={() => setIsFilesModalOpen(false)}
      >
        <ul className={styles.materialFilesModal__filesList}>
          {material?.files?.map((file) => (
            <li key={file.id} className={styles.materialFiles__file}>
              <a href={`${INTERNAL_IMAGES_BASE_URL}/${file.name}`}>
                {file.originalName}
              </a>
            </li>
          ))}
        </ul>
      </Modal>

      <div className="content-section">
        <div className="top-content">
          <div className="content-title-section-with-btn">
            {isError ? (
              <ErrorIndicator />
            ) : isLoading ? (
              <Loader />
            ) : (
              material && (
                <div className={styles.materialPage__actionsWrapper}>
                  <BackButton />
                  <div className={styles.actions}>
                    {(permissions.hasPermission(
                      ACTIONS.legislationNews_allowedToEdit
                    ) ||
                      permissions.hasPermission(
                        ACTIONS.usefulMaterials_allowedToEdit
                      ) ||
                      permissions.hasPermission(
                        ACTIONS.judicialPractice_allowedToEdit
                      )) && (
                      <ColorfulButton
                        link={`/study-center/public-materials/${sectionId}/${categoryId}/${material.id}/article/edit`}
                        text={"Изменить статью"}
                        plusIcon={false}
                      />
                    )}
                    {(permissions.hasPermission(
                      ACTIONS.legislationNews_allowedToDelete
                    ) ||
                      permissions.hasPermission(
                        ACTIONS.usefulMaterials_allowedToDelete
                      ) ||
                      permissions.hasPermission(
                        ACTIONS.judicialPractice_allowedToDelete
                      )) && (
                      <ColorfulButton
                        text={"Удалить"}
                        plusIcon={false}
                        onClick={handleDeleteMaterial}
                      />
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        {isError ? (
          <ErrorIndicator />
        ) : isLoading ? (
          <Loader />
        ) : (
          material && (
            <div className={styles.view_news_container}>
              <div
                className={clsx(
                  styles.view_news_slider_section,
                  styles.view_news_section
                )}
              >
                <SliderWithNav
                  images={[
                    `/uploads/educational-center/internal-training/${material.preview}`,
                  ]}
                />
              </div>
              <div
                className={clsx(
                  styles.view_news_content_section,
                  styles.view_news_section
                )}
              >
                <p className={styles.view_news_title}>{material.title}</p>
                {material.text && (
                  <p
                    className={styles.view_news_text}
                    dangerouslySetInnerHTML={{
                      __html: getTextWithAnchors(material.text),
                    }}
                  />
                )}
                {material.files && material.files.length > 0 && (
                  <Button
                    className={styles.downloadFilesBtn}
                    onClick={() => setIsFilesModalOpen(true)}
                  >
                    Скачать прикреплённые материалы
                  </Button>
                )}
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};
