import {
  WorkspaceArchiveEmployeesParams,
  WorkspaceEmployeeParams,
} from "@interfaces/company/workspace";

import Fetcher from "@http/fetcher";
import { GetArchiveEmployeesResT } from "@interfaces/requests/archive";
import {
  DeleteDocumentTemplatesGroupData,
  DocumentTemplatesArchiveFileData,
  DocumentTemplatesCommonParams,
  DocumentTemplatesDeleteFileData,
  DocumentTemplatesDirectoryData,
  DocumentTemplatesDirectoryT,
  DocumentTemplatesDownloadFileData,
  DocumentTemplatesGetArchivedDirectoriesAndFilesResponse,
  DocumentTemplatesGetDirectoriesAndFilesResponse,
  DocumentTemplatesGetFilesParams,
  DocumentTemplatesGroupData,
  DocumentTemplatesGroupFile,
  DocumentTemplatesGroupT,
  UpdateDocumentTemplatesFileData,
  UpdateDocumentTemplatesGroupData,
  UploadDocumentTemplatesFileData,
} from "@interfaces/company/document-templates";

export const documentTemplateService = {
  // EMPLOYEE
  async unarchiveEmployee(data: WorkspaceEmployeeParams) {
    const resp = await Fetcher.put<any>(
      `companies/${data.companyId}/employees/${data.employeeId}/archive`,
      { isArchived: false }
    );

    return resp.data;
  },

  async unarchiveEmployees(data: WorkspaceArchiveEmployeesParams) {
    const resp = await Fetcher.post<any>(
      `companies/${data.companyId}/employees/archive`,
      {
        isArchived: false,
        employees: data.employees,
      }
    );

    return resp.data;
  },

  async getIsArchivedEmployee(companyId: number) {
    const resp = await Fetcher.get<GetArchiveEmployeesResT>(
      `/companies/${companyId}/employees`,
      {
        params: {
          isArchived: true,
        },
      }
    );

    return resp.data;
  },

  async deleteEmployeeId(data: WorkspaceEmployeeParams) {
    const resp = await Fetcher.delete<any>(
      `companies/${data.companyId}/employees/${data.employeeId}`
    );

    return resp.data;
  },

  // FILE
  async getGroups() {
    const resp = await Fetcher.get<DocumentTemplatesGroupT[]>(
      `/document-templates/groups`
    );

    return resp.data;
  },

  async createGroup(data: DocumentTemplatesGroupData) {
    const resp = await Fetcher.post<DocumentTemplatesGroupT>(
      `/document-templates/groups`,
      {
        name: data.name,
      }
    );

    return resp.data;
  },

  async updateGroup(data: UpdateDocumentTemplatesGroupData) {
    const resp = await Fetcher.patch<DocumentTemplatesGroupT>(
      `/document-templates/groups/${data.id}`,
      {
        name: data.name,
      }
    );

    return resp.data;
  },

  async deleteGroup(data: DeleteDocumentTemplatesGroupData) {
    const resp = await Fetcher.delete(`/document-templates/groups/${data.id}`);

    return resp.data;
  },

  async getDirectoriesAndFiles(
    groupId: number,
    directoryId: number,
    params?: DocumentTemplatesGetFilesParams
  ) {
    const resp =
      await Fetcher.get<DocumentTemplatesGetDirectoriesAndFilesResponse>(
        `/document-templates/groups/${groupId}/directories/${directoryId}`,
        { params }
      );

    return resp.data;
  },

  async getisArchivedDirectoriesAndFiles(
    groupId: number,
    params?: DocumentTemplatesGetFilesParams
  ) {
    const resp =
      await Fetcher.get<DocumentTemplatesGetArchivedDirectoriesAndFilesResponse>(
        `/document-templates/groups/${groupId}/archived`,
        {
          params,
        }
      );

    return resp.data;
  },

  async createDirectory(data: DocumentTemplatesDirectoryData) {
    const resp = await Fetcher.post<DocumentTemplatesDirectoryT>(
      `/document-templates/groups/${data.groupId}/directories/${data.directoryId}`,
      {
        name: data.name,
      }
    );

    return resp.data;
  },

  async updateDirectory(data: DocumentTemplatesDirectoryData) {
    const resp = await Fetcher.patch<DocumentTemplatesDirectoryT>(
      `/document-templates/groups/${data.groupId}/directories/${data.directoryId}`,
      {
        name: data.name,
      }
    );

    return resp.data;
  },

  async archiveDirectory(data: DocumentTemplatesCommonParams) {
    const resp = await Fetcher.patch(
      `/document-templates/groups/${data.groupId}/directories/${data.directoryId}/archive`,
      { isArchived: true }
    );

    return resp.data;
  },

  async unarchiveDirectory(data: DocumentTemplatesCommonParams) {
    const resp = await Fetcher.patch(
      `/document-templates/groups/${data.groupId}/directories/${data.directoryId}/archive`,
      { isArchived: false }
    );

    return resp.data;
  },

  async deleteDirectory(data: DocumentTemplatesCommonParams) {
    const resp = await Fetcher.delete(
      `/document-templates/groups/${data.groupId}/directories/${data.directoryId}`
    );

    return resp.data;
  },

  async addFiles(data: UploadDocumentTemplatesFileData) {
    const formData = new FormData();
    data.files.forEach((file) => {
      formData.append("files", file, file.name);
    });

    const resp = await Fetcher.post(
      `/document-templates/groups/${data.groupId}/directories/${data.directoryId}/files`,
      formData
    );

    return resp.data;
  },

  async updateFile(data: UpdateDocumentTemplatesFileData) {
    const resp = await Fetcher.put(
      `/document-templates/groups/${data.groupId}/directories/${data.directoryId}/files/${data.fileId}`,
      {
        name: data.name,
      }
    );

    return resp.data;
  },

  async archiveFile(data: DocumentTemplatesArchiveFileData) {
    const resp = await Fetcher.patch(
      `/document-templates/groups/${data.groupId}/directories/${data.directoryId}/files/${data.fileId}`,
      { isArchived: true }
    );

    return resp.data;
  },

  async unarchiveFile(data: DocumentTemplatesArchiveFileData) {
    const resp = await Fetcher.patch(
      `/document-templates/groups/${data.groupId}/directories/${data.directoryId}/files/${data.fileId}`,
      { isArchived: false }
    );

    return resp.data;
  },

  async deleteFile(data: DocumentTemplatesDeleteFileData) {
    const resp = await Fetcher.delete(
      `/document-templates/groups/${data.groupId}/directories/${data.directoryId}/files/${data.fileId}`
    );

    return resp.data;
  },

  async downloadFile(data: DocumentTemplatesDownloadFileData) {
    const resp = await Fetcher.get<Blob>(
      `/document-templates/groups/${data.groupId}/directories/${data.directoryId}/files/${data.fileId}/download`,
      {
        responseType: "blob",
      }
    );

    return resp.data;
  },

  async getGroupFiles(groups: number[]) {
    const resp = await Fetcher.get<DocumentTemplatesGroupFile[]>(
      `/document-templates/groups/file-list`,
      {
        params: {
          groups,
        },
      }
    );

    return resp.data;
  },
};
