import { Archive, DownloadSimple } from "@phosphor-icons/react";

import { Loader } from "@ui/indicators";
import QueryString from "qs";
import React from "react";
import { SelectedDocsDataT } from "@modals/modal-items/company/sending-for-signature-modal";
import { SendingForSignatureDocumentT } from "@interfaces/company/departments";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import style from "@scss/components/tables/sending-for-signature.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import { useArchiveWorkspaceFileMutation } from "@lib/workspace";
import { useHistory } from "react-router-dom";
import { useSelectedCompanyQuery } from "@lib/company";

export type SendingForSignatureTablePropsT = {
  selectedDocs: Array<SelectedDocsDataT>;
  onSelect: (val: Array<SelectedDocsDataT>) => void;
  currentPositionId: number;
  files: SendingForSignatureDocumentT[] | null;
  onMoveToArchive: (id: number) => void;
};

const SendingForSignatureTable: React.FC<SendingForSignatureTablePropsT> = (
  props
) => {
  const { selectedDocs, onSelect, currentPositionId, files, onMoveToArchive } =
    props;

  const { company } = useSelectedCompanyQuery();

  const history = useHistory();

  const archiveWorkspaceFileMutation = useArchiveWorkspaceFileMutation();

  const query = QueryString.parse(history.location.search);
  const groupId = query.group_id ? +query.group_id : 0;
  const directoryId = query.folder_id ? +query.folder_id : 0;

  const handleChange = (e: any, id: number) => {
    if (e.target.checked) {
      return onSelect([
        ...selectedDocs,
        { positionId: currentPositionId, documentId: id },
      ]);
    }
    const uncheckedDocIndex = selectedDocs.findIndex(
      (doc) => doc.documentId === id && doc.positionId === currentPositionId
    );
    onSelect(
      selectedDocs.filter((selectedDoc, idx) => idx !== uncheckedDocIndex)
    );
  };

  const sendFileToArchive = (fileId: number) => {
    if (!company) return;

    archiveWorkspaceFileMutation.mutate(
      {
        companyId: company.id,
        groupId,
        directoryId,
        fileId,
      },
      {
        onSuccess: () => {
          onMoveToArchive(fileId);
        },
      }
    );
  };

  const tableBodyContent = files?.map((file, idx) => {
    const id = file?.id;
    return (
      <tr key={idx}>
        <td>
          <label
            htmlFor={`${id}`}
            className={clsx(tableStyle.column_fixed_height)}
          >
            <input
              type="checkbox"
              className={clsx(tableStyle.checkbox_item)}
              hidden
              name=""
              id={`${id}`}
              onChange={(e) => handleChange(e, id)}
              checked={
                !!selectedDocs.find(
                  (selectedDoc) =>
                    selectedDoc.documentId === id &&
                    selectedDoc.positionId === currentPositionId
                )
              }
            />
            <label htmlFor={`${id}`}>
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </label>
            <label
              htmlFor={`${id}`}
              className={clsx(tableStyle.checkbox_label)}
            >
              {file.title}
            </label>
          </label>
        </td>
        <td>
          <div className={clsx(style.actions_col)}>
            <a
              href={` ${process.env.API_URL}/api/v1/file/${file.id}?type=workspace&hash=${file.hash} `}
            >
              <WrapperSVG color={"green-light"}>
                <DownloadSimple size={24} />
              </WrapperSVG>
            </a>
            <WrapperSVG color="red">
              <Archive size={24} onClick={() => sendFileToArchive(id)} />
            </WrapperSVG>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div className={clsx(tableStyle.base_table_container)}>
      {!files ? (
        <Loader autoHeight />
      ) : (
        <table
          className={clsx(
            tableStyle.base_table,
            style.sending_for_signature_table
          )}
        >
          <thead>
            <tr>
              <td>
                <label>
                  <input
                    type="checkbox"
                    className={clsx(tableStyle.checkbox_item)}
                    hidden
                    disabled
                    name=""
                  />
                  <label>
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </label>
                  <label className={clsx(tableStyle.checkbox_label)}>
                    Название документа
                  </label>
                </label>
              </td>
              <td />
            </tr>
          </thead>
          <tbody>{tableBodyContent}</tbody>
        </table>
      )}
    </div>
  );
};

export default SendingForSignatureTable;
