import { Dispatch, FC, SetStateAction } from "react";

import clsx from "clsx";

import styles from "./InputFiles.module.scss";
import { formatFileSize } from "@utils/formatFileSize";
import { FileDocSVG } from "@components/svgs";
import { X } from "@phosphor-icons/react";

type InputFilesPropsT = {
  className?: string;
  files: File[];
  setFiles: Dispatch<SetStateAction<File[]>>;
  isLoading?: boolean;
};

export const InputFiles: FC<InputFilesPropsT> = ({
  files,
  setFiles,
  isLoading,
}: any) => {
  const content: any = [];

  const handleCloseFile = (file: File) => {
    if (isLoading) return;

    setFiles((prev: File[]) => prev.filter((prevFile) => prevFile !== file));
  };

  for (let key in files) {
    const currentFile = files[key];
    const fileName = true ? currentFile.name : currentFile.originalName ?? "";

    if (!currentFile || !+currentFile.size) {
      break;
    }

    content.push(
      <div key={key}>
        <div
          className={clsx(styles.file, {
            [styles.file_disabled]: isLoading,
          })}
        >
          <FileDocSVG />
          <div className={styles.file__name}>
            {(fileName && fileName.length > 12
              ? fileName?.slice(0, 12) + "..."
              : fileName) ?? ""}
            <div>({formatFileSize(currentFile.size)})</div>
          </div>
          <X
            size={18}
            className={styles.closeFile}
            onClick={() => handleCloseFile(currentFile)}
          />
        </div>
      </div>
    );
  }

  return <div className={styles.fileList}>{content}</div>;
};
