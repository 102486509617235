import { getDeviceType } from "@store/device-type-store";
import { useCallback, useEffect } from "react";

export const useGetDeviceType = () => {
  const resizeHandler = useCallback(() => {
    const clientWidth = document.documentElement.clientWidth;

    getDeviceType(clientWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  useEffect(() => {
    resizeHandler();
    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, [resizeHandler]);
};
