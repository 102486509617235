import {
  PlanGroupDataT,
  PlanGroupsStoreT,
  PlanTaskDataT,
  SimplePlanGroupDataT,
} from "@interfaces/specialist-plan";
import { createEffect, createEvent, createStore } from "effector";

import { EventStateStoreT } from "@interfaces/common";
import { FetchingPlansPropsT } from "@interfaces/requests/specialist-plan";
import SpecialistPlanService from "@services/specialist-plan-service";
import { resetAllStates } from "@store/user-store";

export const setPlans = createEvent<PlanGroupsStoreT>();
export const pushToPlans = createEvent<PlanGroupDataT[]>();
export const pushToTasks = createEvent<PlanTaskDataT>();
export const updatePlanGroup = createEvent<SimplePlanGroupDataT>();
export const updatePlanTask = createEvent<PlanTaskDataT>();
export const removeGroup = createEvent<number>();
export const removeTask = createEvent<number>();

export const $SpecialistPlans = createStore<PlanGroupsStoreT>({
  count: 0,
  rows: [],
})
  .on(setPlans, (_, newState) => {
    return newState;
  })
  .on(pushToPlans, (oldState, newState) => ({
    count: oldState.count,
    rows: [...oldState.rows, ...newState],
  }))
  .on(pushToTasks, (oldState, newTask) => {
    try {
      const renewingGroupIdx = oldState.rows.findIndex((group) => {
        return (
          group.id === newTask.groupId || group.title === newTask.groupTitle
        );
      });
      oldState.rows[renewingGroupIdx]?.tasks.push(newTask);
      oldState.rows = [...oldState.rows];
      return { ...oldState };
    } catch (e) {
      console.error("catch", e);
    }
  })
  .on(updatePlanGroup, (oldState, updatedGroup) => {
    const renewingGroupIdx = oldState.rows.findIndex(
      (group) => group.id === updatedGroup.id
    );
    oldState.rows[renewingGroupIdx].title = updatedGroup.title;

    return { ...oldState };
  })
  .on(updatePlanTask, (oldState, updatedTask) => {
    return {
      count: oldState.count,
      rows: oldState.rows.map((group) => {
        const tasks = group.tasks.map((task) => {
          if (task.id !== updatedTask.id) return task;
          return {
            ...task,
            ...updatedTask,
          };
        });
        return { ...group, tasks };
      }),
    };
  })
  .on(removeGroup, (oldState, groupId) => ({
    count: oldState.count - 1,
    rows: oldState.rows.filter((group) => group.id !== groupId),
  }))
  .on(removeTask, (oldState, taskId) => ({
    count: oldState.count,
    rows: oldState.rows.map((group) => {
      const isInThisGroup = group.tasks.find(
        (task) => task.id === taskId || task.parentId === taskId
      );
      if (isInThisGroup) {
        group.tasks = group.tasks.filter(
          (task) => task.id !== taskId && task.parentId !== taskId
        );
      }
      return { ...group };
    }),
  }));
// .reset(resetAllStates); NOT USED

export const setPlansLoading = createEvent<boolean>();
export const setPlansError = createEvent<boolean>();
export const setPlansStates = createEvent<EventStateStoreT>();

export const $SpecialistPlansStates = createStore<EventStateStoreT>({
  isLoading: true,
  error: false,
  isFetched: false,
})
  .on(setPlansLoading, (oldState, newState) => ({
    ...oldState,
    isLoading: newState,
  }))
  .on(setPlansError, (oldState, newState) => ({
    ...oldState,
    isLoading: false,
    error: newState,
  }))
  .on(setPlansStates, (_, newState) => newState)
  .reset(resetAllStates);

export const fetchSpecialistPlans = createEffect<FetchingPlansPropsT>(
  ({
    companies,
    groups,
    statuses,
    startDate,
    endDate,
    offset,
    limit,
    count,
    cb,
  }) => {
    SpecialistPlanService.GetSpecialistPlans(
      companies,
      groups,
      statuses,
      startDate,
      endDate,
      offset,
      limit,
      count || false,
      (err, res) => {
        if (err || !res) {
          setPlansStates({ isFetched: true, error: true, isLoading: false });
          if (cb) cb(err);
          return;
        }
        if (!res.data) return;
        if (count) {
          setPlans(res.data as PlanGroupsStoreT);
          setPlansStates({ isFetched: true, error: false, isLoading: false });
          return;
        } else {
          pushToPlans(res.data as PlanGroupDataT[]);
          setPlansStates({ isFetched: true, error: false, isLoading: false });
        }

        if (cb) cb(null, res);
      }
    );
  }
);
