import { useMutation, useQueryClient } from "react-query";

import { WorkspaceCommonParams } from "@interfaces/company/workspace";
import { workspaceQueryKeys } from "./queryKeys";
import { workspaceService } from "@services/workspace-service";

export const useDeleteWorkspaceDirectoryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: WorkspaceCommonParams) => workspaceService.deleteDirectory(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(workspaceQueryKeys.allDirectoriesFiles);
        queryClient.invalidateQueries(["arhived"]);
      },
    }
  );
};
