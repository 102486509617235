import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import {
  CheckboxCheckedSVG,
  CheckboxSVG,
  GreyArrowDownSVG,
} from "@components/svgs";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useCompaniesQuery } from "@lib/company";
import { useEffect, useMemo, useState } from "react";

import { ColorfulButton } from "@components/common/common";
import { DropDownMultiSelect } from "@ui/drop-down";
import MomentUtils from "@date-io/moment";
import SpecialistPlanService from "@services/specialist-plan-service";
import { Trash } from "@phosphor-icons/react";
import { WorkplanGroup } from "../workplan-group/workplan-group";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import moment from "moment";
import styles from "./workplan-table.module.scss";
import { useAsyncConfirm } from "@hooks/useAsyncConfirm";
import { useChoiceState } from "@hooks/useChoiceState";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import { useTableColumnsWidth } from "@hooks/useTableColumnsWidth";
import { UseSortingResult } from "@hooks/useSorting";
import { GetWorkplanTasksFilters, WorkplanGroupT } from "@interfaces/workplan";
import { useQueryClient } from "react-query";
import { workplanQueryKeys } from "@lib/workplan/queryKeys";

type SelectListType = {
  id: number;
  title: string;
};

type TWorkplanTable = {
  groups: WorkplanGroupT[];
  filters: GetWorkplanTasksFilters;
  selectedCompanyId?: number;
  companyId?: number;
  isCompanyDefined?: boolean;
  resetCompany: boolean;
  resetStatus: boolean;
  resetCategory: boolean;
  handlerChoiceCompany: (values: SelectListType[]) => void;
  handlerChoiceCategory: (values: SelectListType[]) => void;
  handlerChoiceStatus: (values: SelectListType[]) => void;
  handlerReset: () => void;
  onStartDateChange: (date: any, value?: string | null) => void;
  onEndDateChange: (date: any, value?: string | null) => void;
  clearAllFilters: () => void;
  handlerArchive: (state: boolean) => void;
  sorting: UseSortingResult;
  withDeleteChoice?: boolean;
};

export const WorkplanTable: React.FC<TWorkplanTable> = ({
  groups,
  filters,
  selectedCompanyId,
  companyId,
  isCompanyDefined = false,
  resetCompany,
  resetStatus,
  resetCategory,
  handlerChoiceCompany,
  handlerChoiceCategory,
  handlerChoiceStatus,
  handlerReset,
  onStartDateChange,
  onEndDateChange,
  clearAllFilters,
  handlerArchive,
  sorting,
  withDeleteChoice = true,
}) => {
  const queryClient = useQueryClient();

  const { open } = useModal();
  const permissions = useStore($UserAddPermissions);
  const { companies } = useCompaniesQuery();
  const confirm = useAsyncConfirm();

  const [columnsWidth, updateColumnsWidth] = useTableColumnsWidth(
    "workplan",
    [100, 100, 100, 100, 100]
  );

  const [openedGroup, setOpenedGroup] = useState<string>();

  const choice = useChoiceState(groups, (group) => group.id ?? -1);

  const filteredGroups =
    filters.groups.length > 0
      ? groups.filter((group) => filters.groups.includes(group.title))
      : groups;

  // NOTE: this useState is here because i couldn't find a way to abstract it into custom `useEffectOnce` hook
  const [effectRun, setEffectRun] = useState(false);
  useEffect(() => {
    if (effectRun || groups.length === 0) return;

    setOpenedGroup(groups[0].title);
    setEffectRun(true);
  }, [effectRun, groups]);

  const handleOpenGroup = (group: string) => {
    if (openedGroup === group) {
      return setOpenedGroup(undefined);
    }

    setOpenedGroup(group);
  };

  const listStatus = useMemo(() => {
    return [
      { title: "Все статусы", id: 99999 },
      { title: "Создание", id: 1 },
      { title: "На подписании", id: 2 },
      { title: "Документы подписаны", id: 3 },
      { title: "Подписание проверено", id: 4 },
      { title: "В работе", id: 5 },
      { title: "Выполнено", id: 6 },
    ];
  }, []);

  const listCompanies = useMemo(() => {
    return [
      {
        title: "Все компании",
        id: 99999,
      },
      ...(companies?.map((company) => ({
        title: company.name,
        id: company.id,
      })) ?? []),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId, companies]);

  const handleDeleteGroup = async (
    groupId: number,
    shouldInvalidate = true
  ) => {
    return new Promise((resolve, reject) => {
      if (companyId) {
        SpecialistPlanService.deleteGroup(companyId, groupId, (err, res) => {
          if (err || !res) {
            reject(err);
            return console.error("При удалении категории произошла ошибка");
          }
          resolve(res.data);
          if (res.data === 1 && shouldInvalidate) {
            queryClient.invalidateQueries(workplanQueryKeys.all);
          }
        });
      }
    });
  };

  const handleDeleteSelectedGroups = async () => {
    if (!withDeleteChoice) return;

    const ok = await confirm({
      modalData: {
        text: "Вы уверены, что хотите удалить выбранные категории и все задачи?",
      },
    });
    if (!ok) return;
    const promises = [];
    for (const group of choice.selectedItems) {
      promises.push(handleDeleteGroup(group.id ?? -1, false));
    }
    await Promise.all(promises);
    choice.clearSelectedItems();

    queryClient.invalidateQueries(workplanQueryKeys.all);
  };

  useEffect(() => {
    const elements = document.querySelectorAll(
      ".resizable"
    ) as any as HTMLCollectionOf<HTMLDivElement>;
    const resizers = document.querySelectorAll(".resizable .resizer");
    const table = document.querySelector("#resizable-table") as HTMLElement;
    for (let i = 0; i < resizers.length; i++) {
      const currentResizer = resizers[i];

      const resize = (e: any) => {
        let resultWidth = Math.round(
          e.pageX - elements[i].getBoundingClientRect().left
        );
        if (resultWidth < 150) resultWidth = 150;
        if (resultWidth > 500) resultWidth = 500;
        if (isCompanyDefined) {
          columnsWidth[i + 1] = resultWidth;
        } else {
          columnsWidth[i] = resultWidth;
        }
        updateColumnsWidth([...columnsWidth]);

        table.style.width =
          columnsWidth.reduce((acc, value, index) => {
            if (isCompanyDefined && index === 0) return acc;
            return acc + value;
          }, 0) + "px";
      };

      const stopResize = () => {
        window.removeEventListener("mousemove", resize);
        window.removeEventListener("mouseup", stopResize);

        updateColumnsWidth(columnsWidth);
      };

      currentResizer.addEventListener("mousedown", function (e) {
        e.preventDefault();
        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResize);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <Table
        aria-label="collapsible table"
        id="resizable-table"
        stickyHeader
        className={styles.workplanTable}
        style={{
          position: "relative",
          maxWidth: "100vh",
          width:
            columnsWidth.reduce((acc, value, index) => {
              if (isCompanyDefined && index === 0) return acc;
              return acc + value;
            }, 0) + "px",
        }}
      >
        <TableHead>
          {!isCompanyDefined && (
            <TableCell
              id="column_1"
              className={clsx(styles.cell_border, "resizable")}
              align="left"
              width={columnsWidth[0]}
            >
              <div className={clsx(styles.cell_header, styles.cellHeadCompeny)}>
                <span>Компания</span>
              </div>
              <div
                id="column_1_resizer"
                className={clsx(styles.resizer, "resizer")}
              ></div>
            </TableCell>
          )}
          <TableCell
            id="column_2"
            className={clsx(styles.cell_border, "resizable")}
            align="left"
            width={columnsWidth[1]}
          >
            <div className={styles.cell_header}>
              {withDeleteChoice && (
                <Checkbox
                  color="primary"
                  icon={<CheckboxSVG />}
                  checkedIcon={<CheckboxCheckedSVG />}
                  checked={choice.areAllItemsSelected}
                  onChange={(e) => choice.selectAll(e.target.checked)}
                />
              )}
              <span>Задача</span>
            </div>
            <div
              id="column_2_resizer"
              className={clsx(styles.resizer, "resizer")}
            ></div>
          </TableCell>
          <TableCell
            id="column_3"
            className={clsx(styles.cell_border, "resizable")}
            align="left"
            width={columnsWidth[2]}
          >
            <div className={styles.cell_header}>
              <span>Дата создания</span>
              <div
                className={clsx(styles.sort_icon, {
                  [styles.sort_icon_active]:
                    sorting.shouldIconRotate("startDateOrder"),
                })}
                onClick={() => sorting.onItemSortingChange("startDateOrder")}
              >
                <GreyArrowDownSVG />
              </div>
            </div>
            <div
              id="column_3_resizer"
              className={clsx(styles.resizer, "resizer")}
            ></div>
          </TableCell>
          <TableCell
            id="column_4"
            className={clsx(styles.cell_border, "resizable")}
            width={columnsWidth[3]}
            align="left"
          >
            <div className={styles.cell_header}>
              <span>Крайний срок</span>
              <div
                className={clsx(styles.sort_icon, {
                  [styles.sort_icon_active]:
                    sorting.shouldIconRotate("deadlineOrder"),
                })}
                onClick={() => sorting.onItemSortingChange("deadlineOrder")}
              >
                <GreyArrowDownSVG />
              </div>
            </div>
            <div
              id="column_4_resizer"
              className={clsx(styles.resizer, "resizer")}
            ></div>
          </TableCell>
          <TableCell
            id="column_5"
            className={clsx(styles.cell_border, "resizable")}
            width={columnsWidth[4]}
            align="left"
          >
            <div className={styles.cell_header}>
              <span>Статус</span>
              <div
                className={clsx(styles.sort_icon, {
                  [styles.sort_icon_active]:
                    sorting.shouldIconRotate("statusOrder"),
                })}
                onClick={() => sorting.onItemSortingChange("statusOrder")}
              >
                <GreyArrowDownSVG />
              </div>
            </div>
            <div
              id="column_5_resizer"
              className={clsx(styles.resizer, "resizer")}
            ></div>
          </TableCell>
          <TableCell
            id="column_6"
            className={clsx(styles.cell_border, "resizable")}
            width={columnsWidth[5]}
            align="left"
          >
            <div className={styles.cell_header}>
              <span>Примечание</span>
            </div>
            <div
              id="column_6_resizer"
              className={clsx(styles.resizer, "resizer")}
            ></div>
          </TableCell>
          <TableCell
            id="column_7"
            width={columnsWidth[6]}
            className={clsx(styles.cell_border, "resizable")}
            align="right"
          >
            {withDeleteChoice &&
              choice.selectedItems.length > 0 &&
              permissions.hasPermission(
                ACTIONS.companySpecialistWorkPlan_groups_allowedToDelete
              ) && (
                <div className={styles.selectedGroupsActions}>
                  <WrapperSVG color="">
                    <Tooltip title="Удалить категорию" placement="top">
                      <Trash size={24} onClick={handleDeleteSelectedGroups} />
                    </Tooltip>
                  </WrapperSVG>
                </div>
              )}
          </TableCell>
        </TableHead>
        <TableBody>
          <TableRow className={styles.headFilter}>
            <TableCell align="left">
              <div
                className={clsx(styles.headTable, {
                  [styles.headTable_inCompany]: isCompanyDefined,
                })}
              >
                <div
                  className={clsx(styles.header_filters, {
                    [styles.header_filters__sep]: !companyId,
                  })}
                >
                  <DropDownMultiSelect
                    typeStyle={"input"}
                    defaultTitle={"Все категории"}
                    allId={-1}
                    direction={[
                      { id: -1, title: "Выбрать все" },
                      ...groups.map((group, i) => ({
                        id: i + 1,
                        title: group.title,
                      })),
                    ]}
                    reset={resetCategory}
                    handlerReset={handlerReset}
                    handlerList={handlerChoiceCategory}
                  />
                  {!isCompanyDefined && (
                    <DropDownMultiSelect
                      selectOnly={selectedCompanyId}
                      typeStyle={"input"}
                      defaultTitle={"Все компании"}
                      direction={listCompanies}
                      reset={resetCompany}
                      handlerReset={handlerReset}
                      handlerList={handlerChoiceCompany}
                    />
                  )}
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                    locale={"ru"}
                  >
                    <KeyboardDatePicker
                      cancelLabel={"Отменить"}
                      okLabel={"ОК"}
                      className={clsx(styles.pickers_input)}
                      disableToolbar
                      clearable
                      clearLabel="Очистить"
                      variant="dialog"
                      format="DD.MM.yyyy"
                      placeholder="Начало"
                      value={filters.startDate}
                      onChange={onStartDateChange}
                      onError={console.error}
                    />
                    <KeyboardDatePicker
                      cancelLabel={"Отменить"}
                      okLabel={"ОК"}
                      className={clsx(styles.pickers_input)}
                      disableToolbar
                      clearable
                      clearLabel="Очистить"
                      variant="dialog"
                      format="DD.MM.yyyy"
                      placeholder="Окончание"
                      value={filters.deadline}
                      onChange={onEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      onError={console.error}
                    />
                  </MuiPickersUtilsProvider>
                  <DropDownMultiSelect
                    typeSelect={"status"}
                    typeStyle={"input"}
                    defaultTitle={"Все статусы"}
                    direction={listStatus}
                    reset={resetStatus}
                    handlerReset={handlerReset}
                    handlerList={handlerChoiceStatus}
                  />
                  <div className={styles.checkbox}>
                    <Checkbox
                      color="primary"
                      checked={filters.statuses?.includes(7)}
                      onChange={(e) => handlerArchive(e.target.checked)}
                      icon={<CheckboxSVG />}
                      checkedIcon={<CheckboxCheckedSVG />}
                      title="В архиве"
                    />
                    <span>Показать задачи в архиве</span>
                  </div>
                  <button className="modal_btn" onClick={clearAllFilters}>
                    Очистить фильтры
                  </button>
                </div>
                {isCompanyDefined && (
                  <div className={clsx(styles.plan_top_content)}>
                    {permissions.hasPermission(
                      ACTIONS.companySpecialistWorkPlan_groups_allowedToCreate
                    ) && (
                      <ColorfulButton
                        text="Добавить категорию"
                        onClick={() =>
                          open("AddPlanGroupModal", {
                            btnText: "Добавить",
                            modalData: {
                              modalTitle: "Добавить категорию",
                              companyId: companyId,
                              categories: groups,
                            },
                          })
                        }
                      />
                    )}
                  </div>
                )}
              </div>
            </TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
          </TableRow>
          {filteredGroups.map((group) => {
            return (
              <WorkplanGroup
                key={group.title}
                isOpen={openedGroup === group.title}
                isSelected={choice.getIsItemSelected(group)}
                group={group}
                filters={filters}
                sorting={sorting}
                isCompanyDefined={isCompanyDefined}
                withCheckbox={withDeleteChoice}
                onToggleOpen={() => handleOpenGroup(group.title)}
                onSelectChange={(_, checked) =>
                  choice.selectById(group, checked)
                }
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};
