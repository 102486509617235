import { ProcedureInstruction } from "@interfaces/company/procedure";
import { Flex, Text } from "@mantine/core";
import React from "react";

interface ViewInstructionsProgramDepartmentProps {
  item: ProcedureInstruction;
}

const ViewInstructionsProgramDepartment: React.FC<
  ViewInstructionsProgramDepartmentProps
> = ({ item }) => {
  return (
    <Flex
      wrap="wrap-reverse"
      sx={{
        borderBottom: "1px solid white",
        padding: "5px 42px",
        minHeight: 62,
        rowGap: 10,
        columnGap: 30,
      }}
    >
      <Text sx={{ width: "100%", minWidth: 300, maxWidth: 550 }}>
        {item.title}
      </Text>
    </Flex>
  );
};

export default ViewInstructionsProgramDepartment;
