import {
  CheckboxCheckedSVG,
  CheckboxSVG,
  DownloadSVG,
  FileJustSVG,
  PencilSVG,
} from "@components/svgs";
import React, { useState } from "react";

import { BodyNormal } from "@ui/fonts";
import { Button } from "@ui/button";
import { Checkbox } from "@material-ui/core";
import { FileInput } from "@ui/file-input";
import { Modal } from "@ui/modal";
import { SingleTrainingRequest } from "@interfaces/request-training";
import moment from "moment";
import styles from "./send-training-request-modal.module.scss";
import { useSendTrainingRequestEmailMutation } from "@lib/request-training";

export type SendTrainingRequestModalProps = {
  open: boolean;
  training: SingleTrainingRequest;
  onClose: () => void;
};

export const SendTrainingRequestModal: React.FC<
  SendTrainingRequestModalProps
> = ({ open, training, onClose }) => {
  const sendTrainingRequestEmailMutation =
    useSendTrainingRequestEmailMutation();

  const [send, setSend] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState<string>();
  const [file, setFile] = useState<File>();

  const sendDate =
    training?.createdAt &&
    moment(training.createdAt).format("D MMM YYYYг., HH:mm");

  const handleClose = () => {
    setSend(false);
    setFile(undefined);
    setError(undefined);
    onClose();
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.item(0);
    if (!file) return;

    setError(undefined);
    setFile(file);
  };

  const handleSend = () => {
    if (!training) return;

    if (!file) {
      setError("Пожалуйста, прикрепите файл с реквизитами");
      return;
    }

    sendTrainingRequestEmailMutation.mutate(
      { id: training.id, file },
      {
        onSuccess: () => {
          setSend(true);
        },
      }
    );
  };

  return (
    <Modal open={open} onClose={handleClose} className={styles.modal}>
      {send ? (
        <>
          <div className={styles.modal__head}>
            <BodyNormal weight={"bold"}>Обращение отправлено</BodyNormal>
          </div>
          <div className={styles.modal__line}></div>
          <div className={styles.modal__message}>
            <BodyNormal>
              Благодарим за обращение! Ваша заявка принята. Скоро с Вами
              свяжется наш специалист.
            </BodyNormal>
            <BodyNormal>
              Время ожидания обратной связи 30—60 мин в рабочий день с 9:00 до
              17:30.
            </BodyNormal>
          </div>
          <div className={styles.modal__buttons}>
            <Button onClick={handleClose}>Закрыть</Button>
          </div>
        </>
      ) : (
        <>
          <div className={styles.modal__head}>
            <BodyNormal weight={"bold"}>Отправить заявку</BodyNormal>
          </div>
          <div className={styles.modal__line}></div>
          <div className={styles.modal__request}>
            <div
              onClick={() => setIsChecked((prev) => !prev)}
              className={styles.modal__check}
            >
              <Checkbox
                checked={isChecked}
                name="select_all"
                color="primary"
                icon={<CheckboxSVG />}
                checkedIcon={<CheckboxCheckedSVG />}
              />
            </div>
            <div className={styles.modal__text}>
              <BodyNormal weight="bold">Заявка на обучение</BodyNormal>
              <BodyNormal>{sendDate}</BodyNormal>
              <BodyNormal>{training?.title}</BodyNormal>
            </div>
            <div className={styles.modal__edit}>
              <PencilSVG width={"17"} />
            </div>
          </div>
          <div className={styles.modal__file}>
            <FileJustSVG />
            <BodyNormal color={"gray-dark2"}>{training.title}.xlsx</BodyNormal>
            <DownloadSVG />
          </div>
          <FileInput
            className={styles.modal__fileInput}
            onChange={handleFileChange}
          >
            <Button color="green" className={styles.modal__fileInputBtn}>
              Загрузить реквизиты
            </Button>
            <BodyNormal>{file?.name}</BodyNormal>
          </FileInput>
          {error && (
            <BodyNormal className={styles.modal__error} color="red">
              {error}
            </BodyNormal>
          )}
          <div className={styles.modal__buttons}>
            <Button onClick={handleSend}>Отправить</Button>
            <Button
              color="white"
              border={false}
              borderColor={"white"}
              onClick={handleClose}
            >
              <BodyNormal color={"green"} weight={"bold"}>
                Отменить
              </BodyNormal>
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};
