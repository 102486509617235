import {
  $Prescriptions,
  $PrescriptionsStates,
  fetchPrescriptions,
} from "@store/prescription-store";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { ErrorIndicator, Loader } from "@ui/indicators";
import { useEffect, useState } from "react";

import { ColorfulButton } from "@components/common/common";
import { PrescriptionItems } from "@components/prescriptions";
import { TableTopPanel } from "@components/common";
import clsx from "clsx";
import style from "@scss/pages/prescription/prescriptions.module.scss";
import { useStore } from "effector-react";
import { useSelectedCompanyQuery } from "@lib/company";

const itemsPerPage = 9;

const Prescriptions: React.FC = () => {
  const { count, rows } = useStore($Prescriptions);
  const permissions = useStore($UserAddPermissions);
  const { isLoading, error } = useStore($PrescriptionsStates);
  const [offset, setOffset] = useState(0);

  const { company } = useSelectedCompanyQuery();

  useEffect(() => {
    if (!company) return;

    fetchPrescriptions({
      companyId: company.id,
      count: true,
      offset,
      limit: itemsPerPage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    setOffset(rows.length);
  }, [rows]);

  const handleShowMore = () => {
    if (!company) return;

    fetchPrescriptions({
      companyId: company.id,
      offset,
      limit: itemsPerPage,
    });
  };

  return (
    <div className="bg-white rounded-md">
      <TableTopPanel
        text={`Предписаний: ${count}`}
        hideSearchPanel
        hideSelectPanel
      />
      <div className="underline" />
      <div className={clsx(style.prescriptions_content_container)}>
        <div className={style.actions}>
          {permissions.hasPermission(
            ACTIONS.companyPrescriptions_allowedToCreate
          ) && (
            <ColorfulButton
              text={"Создать предписание"}
              link={"/company/prescriptions/create"}
            />
          )}
        </div>
        {error ? (
          <ErrorIndicator />
        ) : isLoading ? (
          <Loader />
        ) : (
          <PrescriptionItems rows={rows} singleMode={false} />
        )}
        {!error && !isLoading && count ? (
          count > rows.length ? (
            <button className="pagination-button" onClick={handleShowMore}>
              Показать еще
            </button>
          ) : null
        ) : null}
      </div>
    </div>
  );
};

export default Prescriptions;
