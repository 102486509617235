import {
  ArticleType,
  getServiceByType,
  LegislationNewsT,
} from "@interfaces/legislation-news";
import { useQuery } from "react-query";
import { newsQueryKeys } from "./queryKeys";

export const useOtherNewsQuery = (excludeId: string, type: ArticleType) => {
  return useQuery(newsQueryKeys.otherNews(type, excludeId), async () => {
    return new Promise<LegislationNewsT[]>((resolve, reject) => {
      const service = getServiceByType(type);
      service.getAll({ limit: 5, offset: 0 }, (err, res) => {
        if (err || !res) {
          reject(err);
        } else {
          resolve(
            res.data.rows.filter((item) => item._id !== excludeId).slice(0, 4)
          );
        }
      });
    });
  });
};
