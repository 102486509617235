export const switchColor = (
  action: "Добавлено" | "Изменено" | "Удалено"
): "ADD" | "EDIT" | "DELETE" | "" => {
  switch (action) {
    case "Добавлено":
      return "ADD";
    case "Изменено":
      return "EDIT";
    case "Удалено":
      return "DELETE";
    default:
      return "";
  }
};
