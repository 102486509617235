export const CheckboxSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect x="0.5" y="0.5" width="19" height="19" rx="1.5" stroke="#00B856" />
    </svg>
  );
};
