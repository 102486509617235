import {
  EditRowTrainingTableT,
  ProgramRowTrainingTableT,
  ProgramTableT,
} from "@interfaces/directory/version";
import {
  ProcedureInstructionsProgramT,
  ProcedureTrainingProgramsT,
} from "@interfaces/company/procedure";

import Fetcher from "@http/fetcher";
import { ResCallback } from "@interfaces/common";

class ProcedureService {
  static async EditRowTableProgram(
    id: number,
    categoryId: number,
    type: "training" | "briefing" | "medical-examination",
    value: {
      title: string;
      period: number | null;
      termPrimaryConducting: number | null;
    },
    cb: ResCallback<EditRowTrainingTableT>
  ) {
    try {
      const params = {
        categoryId: categoryId,
        type: type,
        title: value.title,
        termPrimaryConducting: value?.termPrimaryConducting ?? null,
        period: value.period ?? null,
      };
      const res = await Fetcher.patch<EditRowTrainingTableT>(
        `/directories/${type}/programs/${id}`,
        params
      );
      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async GetAllProgram(
    type: "training" | "briefing" | "medical-examination",
    categories: number[] | undefined,
    isIncludeChanges: boolean,
    cb: ResCallback<ProcedureInstructionsProgramT[]>
  ) {
    try {
      const params = {
        isIncludeChanges: isIncludeChanges ?? false,
        categories: categories?.length ? categories : undefined,
      };

      const res = await Fetcher.get<ProcedureInstructionsProgramT[]>(
        `/directories/${type}/programs`,
        {
          params,
        }
      );
      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async PostProgramRowTable(
    id: number | undefined,
    type: "training" | "briefing" | "medical-examination",
    value: {
      title: string | null;
      termPrimaryConducting: number | null;
      period: number | null;
    },
    cb: ResCallback<ProgramRowTrainingTableT>
  ) {
    try {
      const params = {
        categoryId: id,
        title: value.title,
        termPrimaryConducting: value.termPrimaryConducting,
        period: value.period,
      };
      const res = await Fetcher.post<ProgramRowTrainingTableT>(
        `/directories/${type}/programs`,
        params
      );
      if (res.status === 201) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async Programs(
    type: "training" | "briefing" | "medical-examination",
    categories: number[] | undefined,
    isIncludeChanges: boolean | undefined,
    cb: ResCallback<ProcedureTrainingProgramsT[]>
  ) {
    try {
      const res = await Fetcher.get<ProcedureTrainingProgramsT[]>(
        `/directories/${type}/programs`,
        {
          params: {
            categories,
            isIncludeChanges,
          },
        }
      );
      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async ProcedureTable(
    type: "training" | "briefing" | "medical-examination",
    isIncludeChanges: boolean | undefined,
    categories: number[] | undefined,
    titleOrder: "ASC" | "DESC" | undefined,
    termPrimaryConductingOrder: "ASC" | "DESC" | undefined,
    periodOrder: "ASC" | "DESC" | undefined,
    cb: ResCallback<ProgramTableT[]>
  ) {
    try {
      const params = {
        isIncludeChanges,
        categories,
        titleOrder,
        termPrimaryConductingOrder,
        periodOrder,
      };
      const res = await Fetcher.get<ProgramTableT[]>(
        `/directories/${type}/programs`,
        { params }
      );
      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async DeleteProgramRowTable(
    id: number,
    type: "training" | "briefing" | "medical-examination",
    cb: ResCallback<EditRowTrainingTableT>
  ) {
    try {
      const params = { id };
      const res = await Fetcher.delete<EditRowTrainingTableT>(
        `/directories/${type}/programs/${id}`,
        { params }
      );
      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }
}

export default ProcedureService;
