import {
  createNewPosition,
  createNewDepartment,
  updatePosition,
  updateDepartment,
} from "@store/company/departments-store";

import { TextField } from "@material-ui/core";
import useModal from "@modals/modal-hook";
import useStyles from "@ui/material-ui-styles";
import { useSelectedCompanyQuery } from "@lib/company";

const CreateDepartmentModal = () => {
  const { modalComponent, modalData, close } = useModal();
  const classes = useStyles();

  const { company } = useSelectedCompanyQuery();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!company) return;

    const modalBtn = document.querySelector(
      "button.modal_btn"
    ) as HTMLButtonElement;
    modalBtn.disabled = true;

    const titleField = document.querySelector(
      'input[name="title"]'
    ) as HTMLInputElement;

    // IF NEED TO CREATE & UPDATE DEPARTMENT POSITION ( DONT FORGET PUT RETURN INSIDE THE BELOW IF )
    if (modalData.positionMode) {
      if (modalData.editMode) {
        updatePosition({
          positionId: modalData.position.id,
          departmentId: modalData.departmentId,
          title: titleField.value,
          briefingPrograms: [],
          trainingPrograms: [],
          medicalExaminationFactors: [],
          cb: (err, res) => {
            if (err) {
              return console.error("При изменении должности произошла ошибка");
            }
            close();
          },
        });
      } else {
        createNewPosition({
          departmentId: modalData.departmentId,
          title: titleField.value,
          briefingPrograms: [],
          trainingPrograms: [],
          medicalExaminationFactors: [],
          cb: (err, res) => {
            if (err || !res) {
              return console.error("При добавлении должности произошла ошибка");
            }
            close();
          },
        });
      }
      return;
    }

    // IF NEED TO CREATE & UPDATE DEPARTMENT
    if (modalData.editMode) {
      updateDepartment({
        departmentId: modalData.departmentId,
        title: titleField.value,
        cb: (err) => {
          if (err) {
            return console.error(
              "При изменении подразделения произошла ошибка"
            );
          }
          close();
        },
      });
    } else {
      createNewDepartment({
        companyId: company.id,
        title: titleField.value,
        cb: (err) => {
          if (err) {
            return console.error("При создании подразделения произошла ошибка");
          }
          close();
        },
      });
    }
  };

  return (
    <div key={modalComponent.key}>
      <p className="modal_title">{modalData.modalTitle}</p>
      <div className="underline" />
      <form className={`modal_content ${classes.root}`} onSubmit={handleSubmit}>
        <TextField
          label={modalData.fieldTitle}
          name={"title"}
          variant="filled"
          required
          defaultValue={modalData.itemValue || ""}
        />
        <button type="submit" className="modal_btn">
          {modalComponent.btnText}
        </button>
      </form>
    </div>
  );
};

export default CreateDepartmentModal;
