import { BreadCrumb } from "@components/common";
import SpecialistPlan from "feature/specialist-plan/specialist-plan";
import { Title } from "@components/common/common";
import { memo } from "react";

export enum TaskSortEnum {
  DEFAULT,
  BY_START_DATE,
  BY_FINISH_DATE,
  BY_STATUS,
}

const SpecialistPlanPage = memo(() => {
  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={["Главная", "План специалиста"]} />
          <Title text="План специалиста" />
        </div>
        <SpecialistPlan />
      </div>
    </main>
  );
});

export default SpecialistPlanPage;
