const defaultUnits = ["Кб", "Мб", "Гб", "Тб", "Пб"];

// copied from this stackoverflow answer
// https://stackoverflow.com/a/14919494/13725946
export const formatFileSize = (
  bytes: number,
  dp = 1,
  options?: { units?: string[]; showBytes?: boolean }
) => {
  const { units = defaultUnits, showBytes = true } = options || {};

  const thresh = 1024;

  if (showBytes && Math.abs(bytes) < thresh) {
    return bytes + " Б";
  }

  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
};
