import {
  $ProcedureControlStates,
  $ProcedureInstructions,
  FetchAllInstructionsPrograms,
} from "@store/company/procedure-instrunctions-store";
import { Box, Center, ScrollArea, Text } from "@mantine/core";
import React, { useEffect, useMemo, useState } from "react";

import { ArrowDropDownSVG } from "@components/svgs";
import { ChildrenProps } from "./create-position-modal";
import EditInstructionsProgramDepartment from "./edit-instructions-program-department";
import { Loader } from "@ui/indicators";
import { ProcedureProgram } from "@interfaces/company/procedure";
import ViewInstructionsProgramDepartment from "./view-instructions-program-department";
import clsx from "clsx";
import styles from "@scss/modals/program/edit-program-modal.module.scss";
import { useStore } from "effector-react";

const EditInstructionProgramsModal: React.FC<ChildrenProps> = ({
  instructionValue,
  setInstructions,
  type,
}) => {
  const { programs } = useStore($ProcedureInstructions);
  const { isLoading } = useStore($ProcedureControlStates);
  const sortedPrograms = useMemo(() => {
    if (!programs?.length) return [];

    return programs.filter((item) => item.programs.length !== 0);
  }, [programs]);

  const addedSortedPrograms = useMemo(() => {
    if (type === "view") {
      return sortedPrograms
        .map((item) => {
          const instructions = item.programs.filter((dataItem) =>
            instructionValue.includes(dataItem.id)
          );
          return { ...item, instructions };
        })
        .filter((block) => block.programs.length !== 0);
    }
    return sortedPrograms;
  }, [type, instructionValue, sortedPrograms]);

  useEffect(() => {
    FetchAllInstructionsPrograms({ isIncludeChanges: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.head_title}>Выбрать инструктажи</div>
      <ScrollArea style={{ height: "50vh", maxHeight: "100%", minWidth: 250 }}>
        {!isLoading ? (
          addedSortedPrograms?.map(
            ({ programs, categoryId, title, instructions }) => {
              const items = instructions ?? programs;
              if (items.length === 0) {
                return null;
              }

              return (
                <Categoryes
                  key={categoryId}
                  title={title}
                  items={items}
                  type={type}
                  setInstructions={setInstructions}
                  instructionValue={instructionValue}
                />
              );
            }
          )
        ) : (
          <Center sx={{ height: "70vh", margin: "0 auto", width: 250 }}>
            <Loader />
          </Center>
        )}
      </ScrollArea>
    </>
  );
};

type CategoryesTypeProps = {
  title: string;
  items: ProcedureProgram[];
  type: "view" | "edit";
  setInstructions: React.Dispatch<React.SetStateAction<number[]>>;
  instructionValue: number[];
};

const Categoryes: React.FC<CategoryesTypeProps> = ({
  title,
  items,
  type,
  setInstructions,
  instructionValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      <div onClick={() => setIsOpen(!isOpen)} className={styles.title}>
        <div
          className={clsx(
            {
              [styles.arrow_drop__opened]: isOpen,
            },
            styles.arrow
          )}
        >
          <ArrowDropDownSVG />
        </div>
        <Text py={12}>{title}</Text>
      </div>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.colors.gray[0],
        })}
      >
        {isOpen && (
          <>
            <div className={styles.table_head}>
              <Text size="md" sx={{ whiteSpace: "nowrap" }}>
                Наименование инструктажа
              </Text>
            </div>
            {items.map((item) =>
              type === "edit" ? (
                <EditInstructionsProgramDepartment
                  setInstructions={setInstructions}
                  instructionsValue={instructionValue}
                  key={item.id}
                  item={item}
                />
              ) : (
                <ViewInstructionsProgramDepartment key={item.id} item={item} />
              )
            )}
          </>
        )}
      </Box>
    </React.Fragment>
  );
};

export default EditInstructionProgramsModal;
