import { $SelectedPrescription } from "@store/prescription-store";
import { PrescriptionItems } from "@components/prescriptions";
import { useStore } from "effector-react";

const SinglePrescription: React.FC = () => {
  const prescription = useStore($SelectedPrescription);

  if (!prescription) {
    return null;
  }
  return <PrescriptionItems rows={[prescription]} singleMode />;
};

export default SinglePrescription;
