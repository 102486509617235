export const SearchSVG = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 13C10.3137 13 13 10.3137 13 7C13 3.68629 10.3137 1 7 1C3.68629 1 1 3.68629 1 7C1 10.3137 3.68629 13 7 13ZM14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7Z"
        fill="#8D96A5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6464 11.6464C11.8417 11.4512 12.1583 11.4512 12.3536 11.6464L15.3536 14.6464C15.5488 14.8417 15.5488 15.1583 15.3536 15.3536C15.1583 15.5488 14.8417 15.5488 14.6464 15.3536L11.6464 12.3536C11.4512 12.1583 11.4512 11.8417 11.6464 11.6464Z"
        fill="#8D96A5"
      />
    </svg>
  );
};
