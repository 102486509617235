import { SortingState, SortingType } from "@interfaces/sorting";
import { useEffect, useState } from "react";

const keysToState = (keys: string[], defaultSort?: SortingType) => {
  const state: SortingState = {};

  keys.forEach((key) => (state[key] = defaultSort));

  return state;
};

const getOppositeState = (type: SortingType | undefined): SortingType => {
  if (type === "ASC") return "DESC";

  return "ASC";
};

export type UseSortingResult = {
  state: SortingState;
  onItemSortingChange: (key: string, newSorting?: SortingType) => void;
  shouldIconRotate: (key: string) => boolean;
  getActiveSorting: () => SortingState;
};

export const useSorting = (
  keys: string[],
  defaultSort?: SortingType,
  onSortingChange?: (newSortingKey: string) => void
): UseSortingResult => {
  const [state, setState] = useState(keysToState(keys, defaultSort));
  const [activeSorting, setActiveSorting] = useState(() => keys[0]);

  useEffect(() => {
    if (!onSortingChange) return;

    onSortingChange(activeSorting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSorting]);

  const onItemSortingChange = (key: string, newSorting?: SortingType) => {
    const value =
      newSorting ?? key === activeSorting
        ? getOppositeState(state[key])
        : "ASC";

    setActiveSorting(key);
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));

    if (onSortingChange) onSortingChange(key);
  };

  const shouldIconRotate = (key: string): boolean => {
    const value = state[key];

    return key === activeSorting && value === "ASC";
  };

  const getActiveSorting = () => {
    return {
      [activeSorting]: state[activeSorting],
    };
  };

  return {
    state,
    onItemSortingChange,
    shouldIconRotate,
    getActiveSorting,
  };
};
