import { LegislationNewsT } from "@interfaces/legislation-news";
import React from "react";
import clsx from "clsx";
import style from "@scss/pages/news-and-practice/news-and-practice.module.scss";
import { ProjectNewsItem } from "@interfaces/project-news";
import { NewsItem } from "./news-item";

type NewsItemsPropsT = {
  items: (LegislationNewsT | ProjectNewsItem)[];
  path: string;
  limit?: number;
  itemClassName?: string;
};

const NewsItems: React.FC<NewsItemsPropsT> = ({
  items,
  path,
  limit,
  itemClassName,
}) => {
  if (limit) {
    items = items.slice(0, limit);
  }

  return (
    <div className={clsx(style.news_items)}>
      {items.map((item) => (
        <NewsItem
          key={item._id}
          id={item._id}
          title={item.title}
          tags={item.tags}
          type={path}
          text={item.text}
          image={item.images[0].filename}
          createdAt={item.createdAt}
          color={"color" in item ? item.color : undefined}
          className={itemClassName}
        />
      ))}
    </div>
  );
};

export default NewsItems;
