import { ProcedureInstructionQueryParams } from "@interfaces/company/procedure";
import { createContext, FC, ReactNode, useContext } from "react";

const context = createContext<ProcedureInstructionQueryParams>({});

export const useBriefingProcedureParamsContext = () => useContext(context);

export const BriefingProcedureParamsProvider: FC<{
  value: ProcedureInstructionQueryParams;
  children: ReactNode;
}> = ({ value, children }) => {
  return <context.Provider value={value}>{children}</context.Provider>;
};
