import { ComponentType, FC, useEffect, useState } from "react";

import OTServiceLogo from "@assets/images/otservice.png";
import clsx from "clsx";
import style from "./auth.module.scss";
import useStyles from "@ui/material-ui-styles";
import { LoginForm } from "./LoginForm";
import { RegisterForm } from "./RegisterForm";
import { NewsItem } from "@components/news-components/news-item";
import { $DeviceType } from "@store/device-type-store";
import { useStore } from "effector-react";
import { useProjectNewsQuery } from "@lib/project-news";
import { useHistory } from "react-router-dom";
import { useModal } from "@modals/index";

type Tab = "login" | "register";

const tabs: Record<Tab, ComponentType> = {
  login: LoginForm,
  register: RegisterForm,
};

export const AuthPage: FC = () => {
  const { isMobile } = useStore($DeviceType);

  const [activeAction, setActiveAction] = useState<Tab>("login");

  const { data } = useProjectNewsQuery(1);

  const classes = useStyles();
  const history = useHistory();
  const modal = useModal();

  const handleChangeAction = (action: Tab) => {
    setActiveAction(action);
  };

  const Form = tabs[activeAction];

  const newsItem = data?.pages?.[0].result?.rows?.[0];

  useEffect(() => {
    const q = new URLSearchParams(history.location.search);
    const error = q.get("error");
    if (!error || modal.modal.display) return;

    modal.open("NotificationModal", {
      modalData: {
        modalTitle: "Внимание",
        titleStyle: {
          color: "var(--color-red)",
        },
        text: error,
        removeIcon: true,
        style: {
          width: "400px",
        },
        textStyle: {
          fontSize: "16px",
        },
      },
      onConfirm: () => {
        modal.close();
        history.replace("/login");
      },
    });
  }, [history, modal]);

  return (
    <div className={clsx(style.auth_container)}>
      <div className={clsx(style.auth_section)}>
        <div className={clsx(style.auth_section_content)}>
          <img src={OTServiceLogo} alt="" />
          <p className={clsx(style.welcome_text)}>
            Добро пожаловать в ОТ Сервис!
          </p>
          <div className={clsx(style.auth_form, classes.root)}>
            <div className={clsx(style.action_buttons)}>
              <button
                type="button"
                className={clsx(style.action_button, {
                  [style.active]: activeAction === "login",
                })}
                onClick={() => handleChangeAction("login")}
              >
                Вход
              </button>
              {/* NOTE: закомментил регистрацию пока, ибо говорят на беке метод не очень безопасный */}
              {/* <button */}
              {/*   type="button" */}
              {/*   className={clsx(style.action_button, { */}
              {/*     [style.active]: activeAction === "register", */}
              {/*   })} */}
              {/*   onClick={() => handleChangeAction("register")} */}
              {/* > */}
              {/*   Регистрация */}
              {/* </button> */}
            </div>
            <Form />
          </div>
        </div>
      </div>
      <div className={clsx(style.auth_section)}>
        {!isMobile && newsItem && (
          <NewsItem
            id={newsItem._id}
            title={newsItem.title}
            type="project-news"
            image={newsItem.images[0].filename}
            tags={newsItem.tags}
            createdAt={newsItem.createdAt}
            text={newsItem.text}
            color={newsItem.color}
            className={style.newsItem}
          />
        )}
      </div>
    </div>
  );
};
