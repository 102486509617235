export const CircleDoneSVG = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9004 30.7021C24.2793 30.7021 31.1787 23.8027 31.1787 15.4238C31.1787 7.05957 24.2646 0.145508 15.8857 0.145508C7.52148 0.145508 0.62207 7.05957 0.62207 15.4238C0.62207 23.8027 7.52148 30.7021 15.9004 30.7021ZM15.9004 27.3477C9.29395 27.3477 3.99121 22.0303 3.99121 15.4238C3.99121 8.81738 9.2793 3.5 15.8857 3.5C22.5068 3.5 27.8096 8.81738 27.8242 15.4238C27.8389 22.0303 22.5068 27.3477 15.9004 27.3477ZM14.333 22.3965C14.8896 22.3965 15.3877 22.1035 15.71 21.6055L22.1553 11.6006C22.3604 11.2783 22.5215 10.9121 22.5215 10.5752C22.5215 9.78418 21.833 9.25684 21.0859 9.25684C20.6025 9.25684 20.1777 9.52051 19.8555 10.0479L14.2891 18.9834L11.6816 15.7168C11.3301 15.2627 10.9639 15.0869 10.5098 15.0869C9.7334 15.0869 9.10352 15.7021 9.10352 16.4932C9.10352 16.8594 9.25 17.1963 9.51367 17.5332L12.8975 21.6201C13.3076 22.1328 13.7617 22.3965 14.333 22.3965Z"
        fill="#00B856"
      />
    </svg>
  );
};
