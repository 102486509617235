import { useMutation, useQueryClient } from "react-query";
import {
  StudyCenterCreateRequestData,
  studyCenterService,
} from "@services/study-center";
import { studyCenterQueryKeys } from "./queryKeys";
import { trainingRequestsQueryKeys } from "@lib/request-training/queryKeys";

export const useCreateStudyCenterRequestMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: StudyCenterCreateRequestData) =>
      studyCenterService.createRequest(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(studyCenterQueryKeys.all);
        queryClient.invalidateQueries(trainingRequestsQueryKeys.all);
      },
    }
  );
};
