import { useMutation, useQueryClient } from "react-query";

import { WorkspaceEmployeeParams } from "@interfaces/company/workspace";
import { workspaceService } from "@services/workspace-service";

export const useDeleteEmployeeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: WorkspaceEmployeeParams) => workspaceService.deleteEmployeeId(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["archiverd_employee"]);
      },
    }
  );
};
