import {
  $UserAddPermissions,
  ACTIONS,
  UserRoleEnum,
  setModule,
} from "@store/user-store";
import { CompanyTabPropsT } from "@interfaces/company/company";
import React, { useEffect, useState } from "react";
import {
  useChangeWorkspaceGroupAvailabilityMutation,
  useRequestAccessForWorkspaceMutation,
  useWorkspaceGroupsQuery,
} from "@lib/workspace";

import { Button } from "@ui/button";
import { ColorfulButton } from "@components/common/common";
import { Modules } from "@interfaces/common";
import { SigningFrequency } from "@interfaces/company/workspace";
import { WorkspaceGroups } from "@components/company";
import { WorkspaceTable } from "@components/tables";
import clsx from "clsx";
import { handleApiErrorLocally } from "@utils/handleApiError";
import qs from "qs";
import style from "@scss/pages/company/company-workspace.module.scss";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import { useSelectedCompanyQuery } from "@lib/company";

export const periods = [
  { label: "Разово", value: SigningFrequency.OneTime },
  { label: "Квартал", value: SigningFrequency.Quarter },
  { label: "Раз в месяц", value: SigningFrequency.OnceMonth },
  { label: "Пол года", value: SigningFrequency.HalfYear },
  { label: "Раз в год", value: SigningFrequency.OnceYear },
];

const Workspace: React.FC<CompanyTabPropsT> = () => {
  const { company } = useSelectedCompanyQuery();

  const permissions = useStore($UserAddPermissions);

  const [activeGroupId, setActiveGroupId] = useState<number>(0);

  const { groups } = useWorkspaceGroupsQuery(company?.id);

  const requestAccessMutation = useRequestAccessForWorkspaceMutation();
  const changeGroupAvailabilityMutation =
    useChangeWorkspaceGroupAvailabilityMutation();

  const isGroupAvailable =
    activeGroupId === 0 ||
    !!groups?.find((group) => group.id === activeGroupId)?.isAvailable;

  const { open } = useModal();
  const history = useHistory();

  const handleRequestAccessForGroup = () => {
    if (!company || activeGroupId === 0) return; // safe guard

    const group = groups?.find((group) => group.id === activeGroupId);
    if (!group) return;

    requestAccessMutation.mutate(
      {
        companyId: company?.id,
        groupId: activeGroupId,
      },
      {
        onSuccess: () => {
          toast.success(
            `Запрос на доступ к группе документов "${group.name}" успешно отправлен`
          );
        },
        onError: (error) => {
          handleApiErrorLocally(error, (err) => {
            if (!err) return;

            toast.error(
              `Не удалось отправить запрос на доступ к группе документов "${group.name}"`
            );
          });
        },
      }
    );
  };

  const handleChangeGroupAccess = (allow: boolean) => {
    if (!company || activeGroupId === 0) return; // safe guard

    const group = groups?.find((group) => group.id === activeGroupId);
    if (!group) return;

    changeGroupAvailabilityMutation.mutate(
      {
        companyId: company.id,
        groupId: activeGroupId,
        isAvailable: allow,
      },
      {
        onSuccess: () => {
          const state = allow ? "разрешён" : "запрещён";
          toast.success(`Доступ к группе документов "${group.name}" ${state}`);
        },
        onError: (error) => {
          handleApiErrorLocally(error, (err) => {
            if (!err) return;

            toast.error(err);
          });
        },
      }
    );
  };

  const handleAllowGroupAccess = () => {
    handleChangeGroupAccess(true);
  };

  const handleForbidGroupAccess = () => {
    handleChangeGroupAccess(false);
  };

  useEffect(() => {
    setModule(Modules.WORKSPACE);

    const Querystring = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    if (Querystring.groupId) {
      return setActiveGroupId(+Querystring.groupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tab-content-item">
      <div className={clsx(style.workspace_top_panel)}>
        {groups && (
          <WorkspaceGroups
            groups={groups}
            activeGroupId={activeGroupId}
            setActiveGroupId={setActiveGroupId}
            editBtn
          />
        )}

        <div className={clsx(style.workspace_buttons)}>
          {permissions.hasPermission(
            ACTIONS.companyWorkspace_groups_allowedToCreate
          ) &&
            isGroupAvailable && (
              <>
                <ColorfulButton
                  text="Создать группу"
                  onClick={() =>
                    open("CreateWorkspaceGroupModal", {
                      btnText: "Создать",
                      modalData: { modalTitle: "Создать группу" },
                    })
                  }
                />
                {permissions.hasPermission(
                  ACTIONS.companyWorkspace_directories_allowedToCreate
                ) && (
                  <ColorfulButton
                    text="Создать папку"
                    plusIcon={false}
                    onClick={() =>
                      open("CreateFolderModal", {
                        btnText: "Создать",
                        modalData: {
                          modalTitle: "Создать папку",
                          groupId: activeGroupId,
                        },
                      })
                    }
                  />
                )}
              </>
            )}
          {permissions.hasPermission(
            ACTIONS.companyWorkspace_files_allowedToCreate
          ) &&
            isGroupAvailable && (
              <ColorfulButton
                text="Загрузить документы"
                plusIcon={false}
                onClick={() => open("UploadWorkspaceDocumentModal")}
              />
            )}
          {permissions.roleIsIn([UserRoleEnum.SuperAdmin]) &&
            activeGroupId !== 0 &&
            (isGroupAvailable ? (
              <ColorfulButton
                text="Запретить доступ"
                plusIcon={false}
                onClick={handleForbidGroupAccess}
              />
            ) : (
              <ColorfulButton
                text="Разрешить доступ"
                plusIcon={false}
                onClick={handleAllowGroupAccess}
              />
            ))}
        </div>
      </div>
      <div className={style.workspace_table}>
        {!isGroupAvailable && (
          <div className={style.workspaceAccessRequirement}>
            <div className={style.workspaceAccessRequirement__inner}>
              <p>Данная группа документов отсутствует в Вашем тарифе</p>
              <Button
                size="large"
                className={style.workspaceAccessRequirement__button}
                onClick={handleRequestAccessForGroup}
              >
                Заказать
              </Button>
            </div>
          </div>
        )}

        <WorkspaceTable disabled={!isGroupAvailable} />
      </div>
    </div>
  );
};

export default Workspace;
