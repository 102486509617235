import { ColorPicker } from "@interfaces/gradient";
import s from "@scss/components/gradient.module.scss";
import { useEffect } from "react";

type GradientProps = {
  id: string;
  onChange: (color: string) => void;
};
const Gradient: React.FC<GradientProps> = (props) => {
  const { id, onChange } = props;

  useEffect(() => {
    const colorPicker = new ColorPicker(
      document.querySelector(`#${id}`),
      320,
      50
    );
    colorPicker.onChange((color: any) => {
      onChange(`rgb(${color.r}, ${color.g}, ${color.b})`);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onChange]);

  return (
    <div className={s.container}>
      <canvas id={id} style={{ overflow: "visible" }}></canvas>
    </div>
  );
};
export default Gradient;
