export class ColorPicker {
  target: HTMLCanvasElement;
  width: any;
  height: any;
  pickerCircle: any;
  context: CanvasRenderingContext2D;
  onChangeCallback: any;

  constructor(target: any, width: any, height: any) {
    this.target = target;
    this.width = width;
    this.height = height;
    this.target.width = width;
    this.target.height = height;

    this.context = this.target.getContext("2d") as CanvasRenderingContext2D;

    this.pickerCircle = { x: 30, y: 20, width: 10, height: 10 };

    this.listenForEvents();
    this.draw();
  }

  draw() {
    let gradient = this.context.createLinearGradient(0, 0, this.width, 0);
    gradient.addColorStop(0, "#59D1F8");
    gradient.addColorStop(0.25, "#00B856");
    gradient.addColorStop(0.5, "#8250BE");
    gradient.addColorStop(0.75, "#E24826");
    gradient.addColorStop(1, "#ffaa2b");

    this.context.fillStyle = gradient;
    this.context.fillRect(0, 0, this.width, this.height);

    gradient = this.context.createLinearGradient(0, 0, 0, this.height);
    gradient.addColorStop(0, "rgba(255,255,255,1)");
    gradient.addColorStop(0.9, "rgba(255,255,255,0)");

    this.context.fillStyle = gradient;
    this.context.fillRect(0, 0, this.width, this.height);

    this.context.beginPath();
    this.context.arc(
      this.pickerCircle.x,
      this.pickerCircle.y,
      this.pickerCircle.width,
      0,
      Math.PI * 2
    );
    this.context.strokeStyle = "white";
    this.context.stroke();
    this.context.closePath();
  }

  listenForEvents() {
    const onMouseDown = (e: any) => {
      document.addEventListener("mousemove", onMouseMove);
      const box = this.target.getBoundingClientRect();
      let currentX = e.clientX - box.left;
      let currentY = e.clientY - box.top;
      this.pickerCircle.x = currentX;
      this.pickerCircle.y = currentY;
      this.draw();
      this.onChangeCallback(this.getPickedColor());
    };
    const onMouseMove = (e: any) => {
      const box = this.target.getBoundingClientRect();
      let currentX = e.clientX - box.left;
      let currentY = e.clientY - box.top;
      this.pickerCircle.x = currentX;
      this.pickerCircle.y = currentY;
      if (currentX < 0) this.pickerCircle.x = 0;
      if (currentX > this.width) this.pickerCircle.x = this.width - 1;
      if (currentY < 0) this.pickerCircle.y = 0;
      if (currentY > this.height) this.pickerCircle.y = this.height - 1;
      this.draw();
      this.onChangeCallback(this.getPickedColor());
    };
    const onMouseUp = (e: any) => {
      document.removeEventListener("mousemove", onMouseMove);
      const box = this.target.getBoundingClientRect();
      let currentX = e.clientX - box.left;
      let currentY = e.clientY - box.top;
      this.pickerCircle.x = currentX;
      this.pickerCircle.y = currentY;
    };

    this.target.addEventListener("mousedown", onMouseDown);

    document.addEventListener("mouseup", onMouseUp);
  }

  getPickedColor() {
    let imageData = this.context.getImageData(
      this.pickerCircle.x,
      this.pickerCircle.y,
      1,
      1
    );
    return { r: imageData.data[0], g: imageData.data[1], b: imageData.data[2] };
  }

  onChange(cb: any) {
    this.onChangeCallback = cb;
  }
}
