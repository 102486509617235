import { BackButton } from "@components/back-button";
import { BreadCrumb, Title } from "@components/common";
import ArchiveTemplatesTable from "@components/tables/archive-tables/archive-templates-table";
import styles from "./document-templates-archive.module.scss";

export const DocumentTemplatesArchive = () => {
  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={["Главная", "Шаблоны документов", "Архив"]} />
          <Title text="Архив" />
        </div>
        <BackButton className={styles.archive__backBtn} />
        <ArchiveTemplatesTable className={styles.archive__table} />
      </div>
    </main>
  );
};
