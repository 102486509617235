import {
  GetAllTrainingRequestsParams,
  requestTrainingStatusList,
  trainingRequestsSortings,
} from "@interfaces/request-training";

import { BodyNormal } from "@ui/fonts";
import { CommonSearch } from "@ui/common-search";
import { DropDownLabel } from "@ui/drop-down";
import { TableRequestTraining } from "./table-request-training";
import styles from "./control-request-training.module.scss";
import { useDebouncedValue } from "@mantine/hooks";
import { useSorting } from "@hooks/useSorting";
import { useState } from "react";
import { useTrainingRequestsQuery } from "@lib/request-training";
import { useCompaniesQuery } from "@lib/company";

type Choice = {
  id: number | string;
  title: string;
};

const firstChoice: Choice = {
  id: 0,
  title: "Выбрать...",
};

export const ControlRequestTraining: React.FC = () => {
  const { companies } = useCompaniesQuery();

  const [filters, setFilters] = useState<GetAllTrainingRequestsParams>();

  const sorting = useSorting(trainingRequestsSortings);

  const [searchString] = useDebouncedValue(filters?.searchString, 300);

  const { trainingRequests } = useTrainingRequestsQuery({
    ...filters,
    customer: filters?.customer || undefined,
    status: filters?.status || undefined,
    searchString,
    ...sorting.getActiveSorting(),
  });

  const companiesList = [
    firstChoice,
    ...(companies ?? []).map((company) => ({
      id: company.id,
      title: company.name,
    })),
  ];
  const statusList = [firstChoice, ...requestTrainingStatusList];

  const handleSearchChange = (value: string) => {
    setFilters((prev) => ({
      ...prev,
      searchString: value,
    }));
  };

  const handlerChangeFilter = (key: string, value: string) => {
    if (key === "customer") {
      return setFilters((prev) => ({
        ...prev,
        customer: +value,
      }));
    }

    if (key === "status") {
      return setFilters((prev) => ({
        ...prev,
        status: value,
      }));
    }
  };

  return (
    <div className={styles.content}>
      <>
        <div className={styles.content__head}>
          <div className={styles.content__filter}>
            <DropDownLabel
              label={"Компания"}
              list={companiesList}
              type="customer"
              handlerChangeFilter={handlerChangeFilter}
              choice={false}
            />
            <DropDownLabel
              label={"Статус заявки"}
              list={statusList}
              type="status"
              handlerChangeFilter={handlerChangeFilter}
              choice={false}
            />
          </div>
          <div className={styles.content__search}>
            <CommonSearch
              className="ml-auto"
              onChange={() => {}}
              setSearch={handleSearchChange}
            />
          </div>
        </div>
        {trainingRequests && (
          <div className={styles.content__table}>
            {trainingRequests.length > 0 ? (
              <TableRequestTraining data={trainingRequests} sorting={sorting} />
            ) : (
              <div className={styles.content__table_empty}>
                <BodyNormal color="gray-darker">Ничего не найдено</BodyNormal>
              </div>
            )}
          </div>
        )}
      </>
    </div>
  );
};
