import fetcher from "@http/fetcher";
import {
  SigningControlJournal,
  SigningControlJournalParams,
} from "@interfaces/signing-control";

export const signingControlService = {
  async getJournal({ companyId, ...params }: SigningControlJournalParams) {
    const resp = await fetcher.get<SigningControlJournal>(
      `/companies/${companyId}/signatures`,
      {
        params,
      }
    );

    return resp.data;
  },

  async downloadJournal({ companyId, ...params }: SigningControlJournalParams) {
    const resp = await fetcher.get<Blob>(
      `/companies/${companyId}/signatures/download`,
      {
        params,
        responseType: "blob",
      }
    );

    return resp.data;
  },
};
