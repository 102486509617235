import { createEffect, createEvent, createStore } from "effector";

import { resetAllStates } from "@store/user-store";
import { fetchEvents } from "@store/company/event-store";

import { HomeStoreT } from "@interfaces/home";
import { EventT } from "@interfaces/company/event";

const setHomeDataFetched = createEvent<boolean>();
const setHomeNotifications = createEvent<EventT[] | false>();

export const $HomeData = createStore<HomeStoreT>({
  notifications: null,
  isFetched: false,
})
  .on(setHomeDataFetched, (oldState, newState) => ({
    ...oldState,
    isFetched: newState,
  }))
  .on(setHomeNotifications, (oldState, newState) => ({
    ...oldState,
    notifications: newState,
  }))
  .reset(resetAllStates);

export const fetchHomeData = createEffect(async () => {
  setHomeDataFetched(true);
  fetchEvents({
    dateStart: null,
    dateFinish: null,
    skip: 0,
    limit: 3,
    cb: (err, res) => {
      if (err || !res) {
        return setHomeNotifications(false);
      }
      setHomeNotifications(res.data as EventT[]);
    },
  });
});
