import {
  GetAllGroupIdT,
  PlanGroupDataT,
  PlanGroupsStoreT,
  PlanTaskDataT,
  SimplePlanGroupDataT,
  TaskChangeHistoryT,
} from "@interfaces/specialist-plan";

import { CreateNewPlanEventPropsT } from "@interfaces/requests/specialist-plan";
import Fetcher from "@http/fetcher";
import { ResCallback } from "@interfaces/common";

class SpecialistPlanService {
  static async GetSpecialistPlans(
    companies: number[] | null,
    groups: string[] | null,
    statuses: number[] | null,
    startDate: Date | null,
    endDate: Date | null,
    offset: number,
    limit: number,
    count: boolean,
    cb: ResCallback<PlanGroupsStoreT | PlanGroupDataT[]>
  ) {
    try {
      const res = await Fetcher.modified.get<
        PlanGroupsStoreT | PlanGroupDataT[]
      >("/work-plan/tasks", {
        params: {
          companies: companies ? companies.join(",") : undefined,
          groups: groups
            ? groups?.map((title) => `"${title}"`).join(",")
            : undefined,
          statuses: statuses ? statuses.join(",") : undefined,
          startDate,
          endDate,
          offset,
          limit,
          count: count || undefined,
        },
      });

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async GetChangeHistory(
    taskId: number,
    cb: ResCallback<TaskChangeHistoryT[]>
  ) {
    try {
      const res = await Fetcher.modified.get<TaskChangeHistoryT[]>(
        `/work-plan/tasks/${taskId}/history`
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }
  static async getAllGroupId(
    companyId: number,
    cb: ResCallback<GetAllGroupIdT[]>
  ) {
    try {
      const res = await Fetcher.get<GetAllGroupIdT[]>(
        `companies/${companyId}/work-plan/groups`
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async createGroup(
    companyId: number,
    data: { title: string } | { title: string }[],
    cb: ResCallback<SimplePlanGroupDataT[]>
  ) {
    try {
      const res = await Fetcher.modified.post<SimplePlanGroupDataT[]>(
        `/work-plan/groups`,
        data
      );

      if (res.status === 201) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async updateGroup(
    companyId: number,
    groupId: number,
    title: string,
    cb: ResCallback<number>
  ) {
    try {
      const res = await Fetcher.modified.patch<number>(
        `/work-plan/groups/${groupId}`,
        { title, companyId }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  // createTask
  static async createTask(
    companyId: number,
    data: CreateNewPlanEventPropsT,
    cb: ResCallback<PlanTaskDataT>
  ) {
    try {
      const res = await Fetcher.post<PlanTaskDataT>(
        `companies/${companyId}/work-plan/tasks`,
        {
          isFinancial: false,
          selectedGroups: [],
          employees: [],
          documents: [],
          ...data,
        }
      );
      if (res.status === 201) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  // updateTask
  static async updateTask(
    companyId: number,
    taskId: number,
    data: Partial<CreateNewPlanEventPropsT>,
    cb: ResCallback<PlanTaskDataT>
  ) {
    try {
      const res = await Fetcher.modified.patch<PlanTaskDataT>(
        `/work-plan/tasks/${taskId}`,
        data,
        {
          params: { companyId },
        }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async changeTaskStatus(
    taskId: number,
    status: number,
    cb: ResCallback<number>
  ) {
    try {
      const res = await Fetcher.modified.patch<number>(
        `/work-plan/tasks/${taskId}/status`,
        { status }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async changeSavedStatus(taskId: number, cb: ResCallback<number>) {
    try {
      const res = await Fetcher.modified.patch<number>(
        `/work-plan/tasks/${taskId}/saved`
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async deleteGroup(
    companyId: number,
    groupId: number,
    cb: ResCallback<number>
  ) {
    try {
      const res = await Fetcher.modified.delete<number>(
        `/work-plan/groups/${groupId}`,
        {
          params: { companyId },
        }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async deleteTask(
    companyId: number,
    taskId: number,
    groupId: number,
    cb: ResCallback<number>
  ) {
    try {
      const res = await Fetcher.modified.delete<number>(
        `/work-plan/tasks/${taskId}`,
        {
          params: {
            companyId,
            groupId,
          },
        }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }
}

export default SpecialistPlanService;
