export const renderDayWithEnding = (days: number): string => {
  if (days % 100 > 10 && days % 100 < 15) return "дней";
  switch (days % 10) {
    case 1:
      return "день";
    case 2:
      return "дня";
    case 3:
      return "дня";
    case 4:
      return "дня";
    default:
      return "дней";
  }
};
