import { createEffect, createEvent, createStore } from "effector";

import { EventStateStoreT } from "@interfaces/common";
import WarningService from "@services/warning-service";
import { WarningT } from "@interfaces/warning";
import { resetAllStates } from "@store/user-store";

export const setWarning = createEvent<WarningT>();
export const $Warning = createStore<WarningT>({
  isTrainingFill: false,
  isBriefingFill: false,
}).on(setWarning, (_oldState, newState) => newState);
export const setWarningStates = createEvent<EventStateStoreT>();
export const setWarningLoading = createEvent<boolean>();
export const setWarningError = createEvent<boolean>();

export const $WarningStates = createStore<EventStateStoreT>({
  isLoading: true,
  error: false,
  isFetched: false,
})
  .on(setWarningLoading, (oldState, newState) => ({
    ...oldState,
    isLoading: newState,
  }))
  .on(setWarningError, (oldState, newState) => ({
    ...oldState,
    isLoading: false,
    error: newState,
  }))
  .on(setWarningStates, (_, newState) => newState)
  .reset(resetAllStates);

export const FetchWarning = createEffect((companyId: number) => {
  if (!companyId) return;
  setWarningLoading(true);
  WarningService.GetWarning(companyId, (err, res) => {
    if (err || !res) {
      setWarningStates({
        error: true,
        isFetched: false,
        isLoading: false,
      });
      return console.error("При получении данных произошла ошибка", err);
    }
    if (res.data) {
      setWarning(res.data);
    }
    setWarningStates({
      error: false,
      isFetched: true,
      isLoading: false,
    });
  });
});
