import {
  $DepartmentState,
  fetchDepartments,
} from "@store/company/departments-store";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { useEffect, useState } from "react";

import { Button } from "@ui/button";
import { ColorfulButton } from "@components/common/common";
import { EmployeesTable } from "@components/tables";
import { UploadSVG } from "@components/svgs";
import clsx from "clsx";
import { fetchEmployees } from "@store/company/employees-store";
import style from "@scss/pages/company/employees.module.scss";
import { useHistory } from "react-router-dom";
import { useImportEmployees } from "@lib/use-import-employees";
import { usePostDocumentsQuery } from "@lib/request-training";
import { useStore } from "effector-react";

const EmployeesContent = () => {
  const { isFetched: isDepartmentsFetched } = useStore($DepartmentState);
  const permissions = useStore($UserAddPermissions);
  const { push } = useHistory();
  const handleImportEmployees = useImportEmployees();
  const [idsForPost, setIdsForPost] = useState<number[] | []>([]);

  useEffect(() => {
    fetchEmployees();
    if (!isDepartmentsFetched) {
      fetchDepartments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const usePostDocuments = usePostDocumentsQuery();
  const idsPostDocuments = (id: number[] | []) => setIdsForPost(id);
  const handleEmployeeIds = () => {
    usePostDocuments.mutate(idsForPost);
    setIdsForPost([]);
  };

  return (
    <>
      {permissions.hasPermission(
        ACTIONS.companyStaffing_employees_allowedToCreate
      ) && (
        <div className={style.employees_top}>
          <div
            className={clsx(style.employees_top_panel, style.second_top_panel)}
          >
            <ColorfulButton
              text={"Добавить сотрудника"}
              onClick={() => push("/company/employees/create")}
            />
            <ColorfulButton
              text="Загрузить список сотрудников"
              customIcon={<UploadSVG />}
              onClick={() => handleImportEmployees()}
            />
          </div>
          <div>
            <Button
              onClick={() => handleEmployeeIds()}
              disabled={!idsForPost.length}
            >
              Отправить документы на подпись
            </Button>
          </div>
        </div>
      )}
      <EmployeesTable
        idsPostDocuments={idsPostDocuments}
        isStatusPostDocumets={usePostDocuments.data}
      />
    </>
  );
};

export default EmployeesContent;
