import { useMutation, useQueryClient } from "react-query";
import {
  internalStudyCenterService,
  UpdateInternalMaterialData,
} from "@services/internal-study-center-service";
import { internalStudyCenterQueryKeys } from "./queryKeys";

export const useUpdateInternalMaterialMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateInternalMaterialData) =>
      internalStudyCenterService.updateMaterial(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(internalStudyCenterQueryKeys.all);
      },
    }
  );
};
