import { useCallback } from "react";
import { createEvent, createStore } from "effector";
import { useStore } from "effector-react";

export type ForbiddenWarningState = {
  message: string | null;
};

export const setForbiddenWarning = createEvent<string | null>();
export const $ForbiddenWarning = createStore<ForbiddenWarningState>({
  message: null,
}).on(setForbiddenWarning, (_, newState) => ({
  message: newState,
}));

export const useForbiddenWarning = () => {
  const forbiddenWarning = useStore($ForbiddenWarning);

  const set = useCallback(
    (value: string | null) => setForbiddenWarning(value),
    []
  );

  return [forbiddenWarning, set] as const;
};
