export const getProcedureControlParamsArray = (
  arr: { id: number; title: string }[],
  totalItemsCount: number,
  useTitleAsValue: boolean
): (string | number)[] | undefined => {
  if (arr.length === 0) return undefined;
  if (arr.length === totalItemsCount) return undefined;

  if (useTitleAsValue) {
    return arr.map((item) => item.title);
  }

  return arr.map((item) => item.id);
};
