// TODO: переписать все остальные запросы на объекты как этот вместо классов

import {
  CreateTrainingRequestData,
  EmployeeAvailableToAdd,
  GetAllTrainingRequestsParams,
  SingleTrainingRequest,
  TrainingRequest,
  TrainingRequestSendEmailData,
  TrainingRequestStatusActionData,
  UpdateTrainingRequestData,
} from "@interfaces/request-training";

import fetcher from "@http/fetcher";

const requestTrainingService = {
  async getAll(params: GetAllTrainingRequestsParams) {
    const resp = await fetcher.get<TrainingRequest[]>(
      "/applications-for-training",
      {
        params,
      }
    );

    return resp.data;
  },

  async getById(id: number) {
    const resp = await fetcher.get<SingleTrainingRequest>(
      `/applications-for-training/${id}`
    );

    return resp.data;
  },

  async create(data: CreateTrainingRequestData) {
    const resp = await fetcher.post<TrainingRequest>(
      "/applications-for-training",
      data
    );

    return resp.data;
  },

  async update(_data: UpdateTrainingRequestData) {
    const { id, ...data } = _data;

    const resp = await fetcher.patch<SingleTrainingRequest>(
      `/applications-for-training/${id}`,
      data
    );

    return resp.data;
  },

  async deleteById(id: number) {
    const resp = await fetcher.delete(`/applications-for-training/${id}`);

    return resp.data;
  },

  async statusAction(data: TrainingRequestStatusActionData) {
    const resp = await fetcher.put(
      `/applications-for-training/${data.id}/${data.action}`,
      {}
    );

    return resp.data;
  },

  async sendEmail(data: TrainingRequestSendEmailData) {
    const formData = new FormData();
    formData.set("file", data.file);

    const resp = await fetcher.put(
      `/applications-for-training/${data.id}/send`,
      formData
    );

    return resp.data;
  },

  async downloadExcelFile(id: number) {
    const resp = await fetcher.get<Blob>(
      `/applications-for-training/${id}/download`,
      {
        responseType: "blob",
      }
    );

    return resp.data;
  },

  async getAvailableEmployeesToAdd(id: number) {
    const resp = await fetcher.get<EmployeeAvailableToAdd[]>(
      `/applications-for-training/${id}/employees-available-to-add`
    );

    return resp.data;
  },
};

export default requestTrainingService;
