import {
  $DirectoryVersionInstructions,
  FetchDirectoryVersionInstructions,
} from "@store/directory-instructions-version";
import {
  $DirectoryVersionTraining,
  FetchDirectoryVersionTraining,
} from "@store/directory-training-version";
import {
  $DirectoryVersionMedicalExamination,
  FetchDirectoryVersionMedicalExamination,
} from "@store/directory-medical-examination-version";

import { memo, useEffect } from "react";

import { BackButton } from "@components/back-button";
import { Subtitle } from "@ui/fonts";
import { TableVersion } from "../table-version";
import styles from "./directory-version.module.scss";
import { useStore } from "effector-react";

type VersionLogProps = {
  onClose: () => void;
  type: "training" | "briefing" | "medical-examination";
  updateCategory: () => void;
};

export const DirectoryVersion: React.FC<VersionLogProps> = memo(
  ({ onClose, type, updateCategory }) => {
    const getVersionTraining = useStore($DirectoryVersionTraining);
    const getVersionInstructions = useStore($DirectoryVersionInstructions);
    const getVersionMedicalExamination = useStore(
      $DirectoryVersionMedicalExamination
    );

    useEffect(() => {
      if (type === "training") {
        FetchDirectoryVersionTraining(type);
      }
      if (type === "briefing") {
        FetchDirectoryVersionInstructions(type);
      }
      if (type === "medical-examination") {
        FetchDirectoryVersionMedicalExamination(type);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    return (
      <div className={styles.wrapper}>
        <div className={styles.back} onClick={onClose}>
          <BackButton off={true} />
        </div>
        <div className={styles.head}>
          <div className={styles.head__title}>
            <Subtitle weight="bold">
              Журнал версий справочника{" "}
              {type === "briefing" ? "инструктажей" : "обучения"}
            </Subtitle>
          </div>
        </div>
        <TableVersion
          type={type}
          getVersion={
            type === "training"
              ? getVersionTraining
              : type === "briefing"
                ? getVersionInstructions
                : getVersionMedicalExamination
          }
          updateCategory={updateCategory}
        />
      </div>
    );
  }
);
