import { useMutation, useQueryClient } from "react-query";

import { documentTemplateService } from "@services/document-templates-service";
import { documentTemplatesQueryKeys } from "./queryKeys";
import { DocumentTemplatesGroupData } from "@interfaces/company/document-templates";

export const useCreateDocumentTemplatesGroupMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: DocumentTemplatesGroupData) =>
      documentTemplateService.createGroup(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(documentTemplatesQueryKeys.all);
      },
    }
  );
};
