import {
  DeleteCategoriesProgrammT,
  EditRowTrainingTableT,
  ProgramRowTrainingTableStateT,
  ProgramTableT,
  СategoriesT,
} from "@interfaces/directory/version";
import { createEffect, createEvent, createStore } from "effector";
import produce, { Draft } from "immer";

import { EventStateStoreT } from "@interfaces/common";
import { FetchGetDirectoryInstructionsPropsT } from "@interfaces/company/procedure";
import ProcedureService from "@services/programs-service";
import { WritableDraft } from "immer/dist/internal";
import { resetAllStates } from "@store/user-store";

export const setDirectoryInstructions = createEvent<{
  instructions?: ProgramTableT[];
}>();
export const pushDirectoryProgramRowInstructionsTable = createEvent<{
  programs?: ProgramRowTrainingTableStateT;
}>();
export const editDirectoryProgramRowInstructionsTable = createEvent<{
  programs?: EditRowTrainingTableT;
}>();
export const removeDirectoryProgramRowInstructionsTable = createEvent<{
  programs?: EditRowTrainingTableT;
}>();
export const pushDirectoryInstructionsCategories = createEvent<{
  instructions?: СategoriesT;
}>();
export const editDirectoryInstructionsCategories = createEvent<СategoriesT>();
export const removeDirectoryInstructionsCategories =
  createEvent<DeleteCategoriesProgrammT>();
export const $DirectoryInstructions = createStore<{
  instructions?: ProgramTableT[];
}>({ instructions: [] })
  .on(setDirectoryInstructions, (oldState, newState) => ({
    ...oldState,
    ...newState,
  }))
  .on(pushDirectoryInstructionsCategories, (oldState, newState) => {
    if (newState.instructions && oldState.instructions) {
      return {
        ...oldState,
        instructions: [...oldState.instructions, newState?.instructions],
      };
    }
    if (newState.instructions && !oldState.instructions) {
      return { ...oldState, categories: [newState?.instructions] };
    }
  })
  .on(editDirectoryProgramRowInstructionsTable, (oldState, editRowTable) => {
    const updatedState = produce(oldState, (draftState) => {
      if (draftState.instructions) {
        const editRowTableProgramId = editRowTable?.programs?.id;
        draftState.instructions = draftState.instructions.map((program) => {
          if (
            editRowTableProgramId &&
            editRowTableProgramId < 0 &&
            !!program.programs?.length
          ) {
            program.programs = program.programs.map((objectProgram) => {
              if (
                objectProgram.change?.id === editRowTableProgramId &&
                editRowTable?.programs
              ) {
                const updatedProgram = {
                  ...objectProgram,
                  change: editRowTable.programs,
                };
                return updatedProgram as typeof objectProgram;
              }
              if (
                objectProgram.id === editRowTableProgramId &&
                editRowTable?.programs
              ) {
                const updatedProgram = {
                  ...objectProgram,
                  change: editRowTable.programs,
                };
                return updatedProgram as typeof objectProgram;
              }
              return objectProgram;
            });
          }
          if (editRowTableProgramId && !!program.programs?.length) {
            program.programs = program.programs.map((objectProgram) => {
              if (
                objectProgram.id === editRowTableProgramId &&
                editRowTable?.programs
              ) {
                const updatedProgram = {
                  ...objectProgram,
                  change: editRowTable.programs,
                };
                return updatedProgram as typeof objectProgram;
              }
              return objectProgram;
            });
          }
          return program;
        });
      }
    });
    return updatedState;
  })
  .on(
    removeDirectoryProgramRowInstructionsTable,
    (oldState, removeRowTable) => {
      const updatedState = produce(oldState, (draftState) => {
        if (draftState.instructions) {
          const removeRowTableProgramId = removeRowTable?.programs?.id;
          draftState.instructions = draftState.instructions.map((program) => {
            if (removeRowTableProgramId && removeRowTableProgramId < 0) {
              program.programs = program.programs?.filter(
                (objectProgram) =>
                  objectProgram?.id !== removeRowTableProgramId &&
                  objectProgram?.change?.id !== removeRowTableProgramId
              );
            }
            if (removeRowTableProgramId && !!program.programs?.length) {
              program.programs = program.programs.map((objectProgram) => {
                if (
                  objectProgram.id === removeRowTableProgramId &&
                  removeRowTable?.programs
                ) {
                  const updatedProgram = {
                    ...objectProgram,
                    change: removeRowTable.programs,
                  };
                  return updatedProgram as typeof objectProgram;
                }
                return objectProgram;
              });
            }
            return program;
          });
        }
      });
      return updatedState;
    }
  )
  .on(pushDirectoryProgramRowInstructionsTable, (oldState, newRowTable) => {
    if (newRowTable?.programs) {
      return produce(oldState, (draftState) => {
        if (draftState?.instructions) {
          const programIndex = draftState.instructions.findIndex(
            (program) =>
              program?.id === newRowTable?.programs?.categoryId ||
              program?.change?.id === newRowTable?.programs?.categoryId
          );
          if (programIndex !== undefined && programIndex !== -1) {
            const program = draftState.instructions[programIndex];

            const updatedProgram = Object.assign({}, program, {
              programs: program.programs
                ? [...program.programs, { change: newRowTable.programs }]
                : [{ change: newRowTable.programs }],
            });

            draftState.instructions[programIndex] = updatedProgram;
          }
        }
      });
    }
    return oldState;
  })
  .on(editDirectoryInstructionsCategories, (oldState, editCategory) => {
    const newState = produce(
      oldState,
      (draftState: Draft<{ instructions: ProgramTableT[] }>) => {
        if (editCategory.id) {
          if (editCategory.id > 0) {
            const editTitle = draftState?.instructions?.map((category) => {
              if (category.id === editCategory.id) {
                return {
                  ...editCategory,
                  programs: category.programs,
                };
              }
              if (category?.change?.id === editCategory.id) {
                const newData = {
                  ...category,
                  change: editCategory as СategoriesT,
                };
                return newData;
              }
              return category;
            });
            draftState.instructions =
              editTitle as WritableDraft<ProgramTableT>[];
          }
          if (editCategory.id < 0) {
            const editTitle = draftState?.instructions?.map((category) => {
              if (category?.change?.id === editCategory.id) {
                Object.assign(category.change, editCategory);
                return category;
              }
              if (category?.id === editCategory.id) {
                Object.assign(category, editCategory);
                return category;
              }
              return category;
            });
            draftState.instructions =
              editTitle as WritableDraft<ProgramTableT>[];
          }
        }
      }
    );
    return newState;
  })
  .on(removeDirectoryInstructionsCategories, (oldState, removeCategory) => {
    if (removeCategory.id < 0) {
      const removeProgramm = oldState?.instructions?.filter(
        (programm) =>
          programm.id !== removeCategory.id &&
          programm?.change?.id !== removeCategory.id
      );
      return { instructions: removeProgramm };
    }
    if (removeCategory.id > 0) {
      const removeProgramm = oldState?.instructions?.map((programm) => {
        if (programm.id === removeCategory.id) {
          return {
            ...programm,
            title: removeCategory.title,
            action: removeCategory.action,
          };
        }
        return programm;
      });

      return { instructions: removeProgramm };
    }
  });

export const setDirectoryInstructionsStates = createEvent<EventStateStoreT>();
export const setDirectoryInstructionsLoading = createEvent<boolean>();
export const setDirectoryInstructionsError = createEvent<boolean>();

export const $DirectoryControlStates = createStore<EventStateStoreT>({
  isLoading: true,
  error: false,
  isFetched: false,
})
  .on(setDirectoryInstructionsLoading, (oldState, newState) => ({
    ...oldState,
    isLoading: newState,
  }))
  .on(setDirectoryInstructionsError, (oldState, newState) => ({
    ...oldState,
    isLoading: false,
    error: newState,
  }))
  .on(
    setDirectoryInstructionsStates,
    (_, newStateInstructions) => newStateInstructions
  )

  .reset(resetAllStates);

export const FetchGetDirectoryInstructionsTable =
  createEffect<FetchGetDirectoryInstructionsPropsT>(
    ({
      isIncludeChanges = true,
      categories = undefined,
      titleOrder,
      termPrimaryConductingOrder,
      periodOrder,
    }) => {
      setDirectoryInstructionsLoading(true);
      ProcedureService.ProcedureTable(
        "briefing",
        isIncludeChanges,
        categories,
        titleOrder,
        termPrimaryConductingOrder,
        periodOrder,
        (err, res) => {
          if (err || !res) {
            setDirectoryInstructionsStates({
              error: true,
              isFetched: false,
              isLoading: false,
            });
            return console.error(
              "При получении данных таблиц произошла ошибка"
            );
          }
          if (res.data) {
            if (!!res.data.length) {
              setDirectoryInstructions({ instructions: res.data });
            }
            if (!res.data.length) {
              setDirectoryInstructions({ instructions: [] });
            }
          }
          setDirectoryInstructionsStates({
            error: false,
            isFetched: true,
            isLoading: false,
          });
        }
      );
    }
  );
