import { useQuery } from "react-query";
import { employeesService } from "@services/employee-service";
import { employeesQueryKeys } from "./queryKeys";

export const useEmployeeQuery = (id: number | undefined) => {
  const { data: employee, ...stuff } = useQuery(
    employeesQueryKeys.byId(id),
    () => employeesService.getById(id!),
    {
      enabled: typeof id !== "undefined",
    }
  );

  return {
    employee,
    ...stuff,
  };
};
