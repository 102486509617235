export const FileDocSVG: React.FC = () => {
  return (
    <svg
      className="file-icon"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z"
        fill="#DFE3F1"
      />
      <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#B0B7BD" />
      <path d="M30 14L24 8H30V14Z" fill="#CAD1D8" />
    </svg>
  );
};
