import Fetcher from "@http/fetcher";
import { ResCallback } from "@interfaces/common";
import {
  DevelopmentNewsT,
  GetDevelopmentNewsParamsT,
  GetDevelopmentNewsResT,
} from "@interfaces/requests/development-news";

class DevelopmentNewsService {
  static async getAll(
    params: GetDevelopmentNewsParamsT,
    cb: ResCallback<GetDevelopmentNewsResT>
  ) {
    try {
      const res = await Fetcher.get<GetDevelopmentNewsResT>(
        "/development-news",
        {
          params,
        }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err) {
      return cb(err);
    }
  }

  static async getById(id: string, cb: ResCallback<DevelopmentNewsT>) {
    try {
      const res = await Fetcher.get<DevelopmentNewsT>(
        `/development-news/${id}`
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err) {
      return cb(err);
    }
  }

  static async create(data: FormData, cb: ResCallback<DevelopmentNewsT>) {
    try {
      const res = await Fetcher.post<DevelopmentNewsT>(
        `/development-news`,
        data
      );
      if (res.status === 201) {
        return cb(null, res);
      }
    } catch (err) {
      return cb(err);
    }
  }

  static async update(
    id: string,
    data: FormData,
    cb: ResCallback<DevelopmentNewsT>
  ) {
    const formData = {
      title: data.get("title"),
      tags: data.getAll("tags"),
      text: data.get("text"),
      createdAt: data.get("createdAt"),
    };

    try {
      const res = await Fetcher.patch<DevelopmentNewsT>(
        `/development-news/${id}`,
        formData
      );
      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err) {
      return cb(err);
    }
  }

  static async delete(id: string, cb: ResCallback<DevelopmentNewsT>) {
    try {
      const res = await Fetcher.delete<DevelopmentNewsT>(
        `/development-news/${id}`
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err) {
      return cb(err);
    }
  }

  static async download(filename: string, cb: ResCallback<Blob>) {
    try {
      const res = await Fetcher.get<Blob>(
        `/development-news/download/${filename}`,
        {
          responseType: "blob",
        }
      );
      cb(null, res);
    } catch (err) {
      return cb(err);
    }
  }
}

export default DevelopmentNewsService;
