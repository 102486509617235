import EmployeesGetAllService, {
  EmployeesGetAllT,
  GetAllEmployeesParams,
} from "@services/employees-get-all.service";

import { useQuery } from "react-query";

const uniqueEmployees = (data: EmployeesGetAllT[]) => {
  const filteredData = data.reduce((accumulator, current) => {
    const existingObject = accumulator.find(
      (item) => item.name === current.name
    );
    if (!existingObject) {
      accumulator.push(current);
    }

    return accumulator;
  }, [] as EmployeesGetAllT[]);

  return filteredData;
};

export const useEmployeesGetAllQuery = (
  params?: GetAllEmployeesParams,
  onQuery?: boolean
) => {
  const { data } = useQuery(
    ["employees-get-all", params, onQuery],
    async () => {
      return await EmployeesGetAllService.getAll(params);
    },
    { enabled: !!onQuery }
  );

  const filteredData = data ? uniqueEmployees(data) : [];
  return { data: filteredData };
};
