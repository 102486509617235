import { useMutation, useQuery, useQueryClient } from "react-query";

import { EmployeeListDataT } from "@interfaces/company/employees";
import { employeesService } from "@services/employee-service";
import fetcher from "@http/fetcher";
import { positionQueryKeys } from "./queryKeys";

export type PositionParams = {
  departmentId: number;
  positionId: number | undefined;
  companyId: number | undefined;
};

export type PositionFile = {
  id: number;
  name: string;
  path: string;
};

export type PositionFileAndDepartment = {
  department?: {
    id: number;
    title: string;
  };
  position?: {
    id: number;
    title: string;
  };
  files?: PositionFile[];
};

export type PositionEmployees = {
  department?: {
    id: number;
    title: string;
  };
  position?: {
    id: number;
    title: string;
  };
  employees?: EmployeeListDataT[];
};

export const usePositionFilesQuery = (params: PositionParams) => {
  const { data: files, ...stuff } = useQuery(
    positionQueryKeys.files(params),
    async () => {
      const resp = await fetcher.get<PositionFileAndDepartment>(
        `/companies/${params.companyId}/departments/${params.departmentId}/positions/${params.positionId}/files`
      );

      return resp.data;
    },
    {
      enabled:
        typeof params.positionId !== "undefined" &&
        typeof params.companyId !== "undefined",
    }
  );

  return {
    files,
    ...stuff,
  };
};

export const usePositionEmployeesQuery = (params: PositionParams) => {
  const { data: employees, ...stuff } = useQuery(
    "employees",
    async () => {
      const resp = await fetcher.get<PositionEmployees>(
        `/companies/${params.companyId}/departments/${params.departmentId}/positions/${params.positionId}/employees`
      );

      // https://vgokna.ru/api/v1/companies/95/departments

      return resp.data;
    },
    {
      enabled: typeof params.positionId !== "undefined",
    }
  );

  return {
    employees,
    ...stuff,
  };
};

type ArchiveType = {
  employeeId: number;
};

export const useAddToArchiveEmployeeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((data: ArchiveType) => employeesService.archive(data), {
    onSuccess: () => {
      queryClient.refetchQueries(["employees"]);
    },
  });
};
