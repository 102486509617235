import { Link, RichTextEditor } from "@mantine/tiptap";

import { Article } from "@phosphor-icons/react";
import StarterKit from "@tiptap/starter-kit";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import styles from "./rich-editor.module.scss";
import { useEditor } from "@tiptap/react";
import clsx from "clsx";

type RichEditorPropsT = {
  initialContent?: string;
  label?: string;
  handleContent: (content: string) => void;
  error?: string;
};

export const RichEditor: React.FC<RichEditorPropsT> = ({
  initialContent = "",
  handleContent,
  label = "Текст новости",
  error,
}) => {
  const editor = useEditor({
    extensions: [StarterKit, Link],
    content: initialContent,

    onUpdate: ({ editor }) => {
      handleContent(editor.getHTML());
    },
  });

  return (
    <>
      <p className={styles.Label}>
        <WrapperSVG color="green" cursor={false}>
          <Article size={24} />
        </WrapperSVG>
        {label}
      </p>
      <RichTextEditor
        editor={editor}
        className={clsx(styles.Editor, {
          [styles.Editor_error]: !!error,
        })}
      >
        <RichTextEditor.Toolbar sticky stickyOffset={60}>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Strikethrough />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.H1 />
            <RichTextEditor.H2 />
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Blockquote />
            <RichTextEditor.Hr />
          </RichTextEditor.ControlsGroup>

          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content />
      </RichTextEditor>
      {error && <span className="error-text">{error}</span>}
    </>
  );
};
