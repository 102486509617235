import { FC } from "react";

export const DownloadFolderSVG: FC<JSX.IntrinsicElements["svg"]> = ({
  width = 32,
  height = 32,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_1_6)">
        <path
          d="M0 10.4676V27.0195C0 27.9573 0.71725 28.6747 1.65519 28.6747H30.3448C31.2828 28.6747 32 27.9573 32 27.0195V10.4676C32 9.52978 31.2828 8.8125 30.3448 8.8125H1.65519C0.71725 8.8125 0 9.58498 0 10.4676Z"
          fill="#F7B84E"
        />
        <path
          d="M29.2412 8.82758C29.2412 7.61377 28.248 6.6207 27.0342 6.6207H18.2067L15.448 3.31033H3.31013C2.3722 3.31033 1.65495 4.08276 1.65495 4.96552V8.82758H29.2412Z"
          fill="#E4A12F"
        />
        <path d="M26 27L20.8038 19.5H31.1962L26 27Z" fill="#00B856" />
        <rect x="24" y="13" width="4" height="9" fill="#00B856" />
      </g>
      <defs>
        <clipPath id="clip0_1_6">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
