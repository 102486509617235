export const GreyArrowDownSVG = () => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M7.01172 1.46875C7.01172 1.32552 6.96094 1.21224 6.85938 1.12891C6.75781 1.04297 6.62109 1 6.44922 1L1.35547 0.996094C1.1862 0.996094 1.05078 1.03906 0.949219 1.125C0.847656 1.20833 0.796875 1.32161 0.796875 1.46484C0.796875 1.54297 0.811198 1.61719 0.839844 1.6875C0.871094 1.75521 0.90625 1.83073 0.945312 1.91406L3.38281 6.93359C3.46354 7.10026 3.54427 7.21484 3.625 7.27734C3.70833 7.33984 3.80078 7.37109 3.90234 7.37109C4.00651 7.37109 4.09896 7.33984 4.17969 7.27734C4.26042 7.21484 4.34245 7.10026 4.42578 6.93359L6.85938 1.91406C6.90104 1.83333 6.9362 1.75911 6.96484 1.69141C6.99609 1.62109 7.01172 1.54688 7.01172 1.46875Z"
        fill="#373737"
      />
    </svg>
  );
};
