import { ACTIONS, UserPriorityEnum, UserRoleEnum } from "@store/user-store";
import { Modules, UserAdditionalPermissions } from "@interfaces/common";

export const concatApiUrl = (url: string): string => {
  return process.env.API_URL + url;
};

export const concatClientUrl = (url: string): string => {
  return process.env.CLIENT_URL + "/" + url;
};

export class Permissions {
  private _permissions: UserAdditionalPermissions | null;
  private _module: Modules;

  constructor(permissions?: UserAdditionalPermissions, module?: Modules) {
    this._permissions = permissions || null;
    this._module = module || Modules.NONE;
  }

  set module(module: Modules) {
    this._module = module;
  }

  get module() {
    return this._module;
  }

  set permissions(permissions: UserAdditionalPermissions) {
    this._permissions = permissions;
  }

  get permissions(): UserAdditionalPermissions {
    return this._permissions!;
  }

  public setRole(newRole: UserRoleEnum) {
    if (this._permissions) {
      this._permissions.roleId = newRole;
    }
  }

  public hasAddPermissionsFor(module: Modules) {
    if (this._permissions === null) {
      throw new Error("User permissions isn't specified");
    }
    return this._permissions.modules.includes(module);
  }

  public hasPermission(permissions: ACTIONS | ACTIONS[]): boolean {
    let hasPermission = false;
    if (typeof permissions === "object") {
      permissions.forEach((permission) => {
        if (
          this._permissions &&
          this._permissions.permissions &&
          this._permissions.permissions.includes(permission)
        ) {
          hasPermission = true;
        }
      });
    } else {
      if (
        this._permissions &&
        this._permissions.permissions &&
        this._permissions.permissions.includes(permissions)
      ) {
        hasPermission = true;
      }
    }
    return hasPermission;
  }

  public roleIsIn(roles: UserRoleEnum[], deep: boolean = false): boolean {
    if (this._permissions === null) {
      throw new Error("User permissions isn't specified");
    }
    if (roles.includes(this._permissions.roleId)) {
      if (deep) {
        if (this._module === Modules.NONE) {
          return false;
        }
        return !this._permissions.modules.includes(this._module);
      }
      return true;
    }
    return false;
  }
  public hasRequiredLevel(priority: UserPriorityEnum): boolean {
    if (this._permissions === null) {
      throw new Error("User level isn't specified");
    }
    if (this._permissions.priority <= priority) {
      return true;
    }
    return false;
  }
  // [CLIENT] INCLUDES CLIENT
  public roleIsNotIn(roles: UserRoleEnum[], deep: boolean = false): boolean {
    if (this._permissions === null) {
      throw new Error("User permissions isn't specified");
    }
    if (!roles.includes(this._permissions.roleId)) {
      return true;
    }

    if (deep) {
      if (this._module === Modules.NONE) {
        return false;
      }
      return this._permissions.modules.includes(this._module);
    }
    return false;
  }
}
