import ProjectNewsService from "@services/project-news-service";
import { useMutation, useQueryClient } from "react-query";
import { projectNewsQueryKeys } from "./queryKeys";

export const useCreateProjectNewsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: FormData) => {
      return new Promise((resolve, reject) => {
        ProjectNewsService.create(data, (err, res) => {
          if (err || !res) {
            return reject(err);
          }
          resolve(res.data);
        });
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(projectNewsQueryKeys.all);
      },
    }
  );
};
