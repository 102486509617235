import React, { useState } from "react";

import { AxiosError } from "axios";
import { DownloadFolderSVG } from "@components/svgs/download-folder";
import Dropzone from "@ui/dropzone";
import { FileDocSVG } from "@components/svgs";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import { X } from "@phosphor-icons/react";
import clsx from "clsx";
import { formatFileSize } from "@utils/formatFileSize";
import qs from "qs";
import styles from "@scss/modals/company/workspace-document.module.scss";
import { useHistory } from "react-router-dom";
import useModal from "@modals/modal-hook";
import useStyles from "@ui/material-ui-styles";
import { useUploadDocumentTemplateFilesMutation } from "@lib/document-templates/useUploadDocumentTemplateFilesMutation";

const UploadDocumentTemplatesFileModal: React.FC = () => {
  const [uploadedFiles, setUploadedFiles] = useState<{ [key: string]: File }>(
    {}
  );
  const [error, setError] = useState<string>("");
  const [fileCount, setFileCount] = useState(Object.keys(uploadedFiles).length);

  const uploadFilesMutation = useUploadDocumentTemplateFilesMutation();

  const history = useHistory();
  const { modalComponent, close, open } = useModal();
  const classes = useStyles();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (Object.keys(uploadedFiles).length === 0) return;

    const queryString = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    }) as any;

    const groupId = queryString.group_id ? +queryString.group_id : 0;
    const directoryId = queryString.folder_id ? +queryString.folder_id : 0;

    uploadFilesMutation.mutate(
      {
        groupId,
        directoryId,
        files: Object.values(uploadedFiles),
      },
      {
        onSuccess: () => {
          close();
        },
        onError: (error) => {
          const err = error as AxiosError;
          const message = err.response?.data?.message as string | undefined;
          console.error(err.response?.data);
          if (!message) return;

          if (message.match("large")) {
            return setError(
              "Недопустимый размер файла. Максимальный размер 5мб"
            );
          }

          if (message.match("space")) {
            return open("NotificationModal", {
              modalData: {
                modalTitle: "Недостаточно места!",
                text: `Сообщаем, что у Вас не осталось свободного места в хранилище.
                          Для увеличения объема памяти вы можете обратиться на почту к Администратору (tarif@0tservice.com).
                          С уважением, Команда 0tservice.`,
              },
            });
          }
        },
      }
    );
  };

  const handleUpload = (files: File[]) => {
    const newFiles: { [key: string]: File } = {};

    files.forEach((file) => {
      const fileId = file.name + file.size; // Уникальный идентификатор файла
      if (!uploadedFiles[fileId]) {
        newFiles[fileId] = file;
      }
    });

    if (Object.keys(newFiles).length === 0) {
      // Все файлы являются дубликатами, ничего не добавляем
      return;
    }

    const updatedFiles = { ...uploadedFiles, ...newFiles };
    setUploadedFiles(updatedFiles);
    setFileCount((prevCount) => prevCount + Object.keys(newFiles).length);
  };

  const handleDeleteFile = (fileId: string) => {
    const updatedFiles = { ...uploadedFiles };
    delete updatedFiles[fileId];
    setUploadedFiles(updatedFiles);
    setFileCount((prevCount) => prevCount - 1);
  };
  return (
    <div className={clsx(styles.workspaceDocumentModal)}>
      <p className="modal_title">Загрузить файлы</p>
      <div className="underline" />
      <form
        className={`modal_content ${classes.root} ${clsx(
          styles.workspaceDocumentModalForm
        )}`}
        onSubmit={handleSubmit}
      >
        <Dropzone
          onUpload={(files: File[]) => handleUpload(files)}
          filesCount={fileCount}
          icon={<DownloadFolderSVG width={24} height={24} />}
        />
        <div className={styles.formFilesList}>
          <div className={styles.listLoadFile}>
            {Object.entries(uploadedFiles).map(([fileId, file]) => (
              <div key={fileId} className={styles.formFileItem}>
                <div className={styles.formFile}>
                  <FileDocSVG />
                  <span className={styles.formFile__name}>{file.name}</span>
                  <span className={styles.formFile__fileSize}>
                    ({formatFileSize(file.size)})
                  </span>
                </div>
                <div
                  onClick={() => handleDeleteFile(fileId)}
                  className={styles.deleteFile}
                >
                  <WrapperSVG color={""}>
                    <X size={14} />
                  </WrapperSVG>
                </div>
              </div>
            ))}
          </div>
        </div>
        {error ? <p className="error-text">{error}</p> : null}
        <button
          type="submit"
          className="modal_btn"
          disabled={uploadFilesMutation.isLoading}
        >
          {modalComponent.btnText}
        </button>
      </form>
    </div>
  );
};

export default UploadDocumentTemplatesFileModal;
