import { ErrorIndicator, Loader } from "@ui/indicators";
import React, { useState } from "react";

import { DocumentTablePropsT } from "@interfaces/company/departments";
import { GroupSwitch } from "@components/group-switch";
import clsx from "clsx";
import style from "@scss/pages/company/departments.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import { useModal } from "@modals/index";
import { usePositionFilesQuery } from "@lib/positions";
import { useSelectedCompanyQuery } from "@lib/company";

const DocumentTable: React.FC<DocumentTablePropsT> = ({
  departmentId,
  positions,
}) => {
  const { company } = useSelectedCompanyQuery();

  const { open } = useModal();

  const [selectedPosition, setSelectedPosition] = useState<number>(
    positions[0].id
  );

  const { files, isError, isLoading } = usePositionFilesQuery({
    companyId: company?.id,
    departmentId,
    positionId: selectedPosition,
  });

  const positionsList = positions.map((position) => ({
    value: `${position.id}`,
    label: position.title,
  }));

  return (
    <div>
      <GroupSwitch
        data={positionsList}
        value={`${selectedPosition}` ?? ""}
        onChange={(value) => setSelectedPosition(+value)}
        className={style.positionFilter}
      />
      <div
        className={clsx(
          tableStyle.base_table_container,
          style.departments_table
        )}
      >
        {isError ? (
          <ErrorIndicator />
        ) : isLoading ? (
          <Loader />
        ) : (
          <table className={clsx(tableStyle.base_table)}>
            <thead>
              <tr>
                <td>
                  <label>
                    <label className={clsx(tableStyle.checkbox_label)}>
                      Название документа
                    </label>
                  </label>
                </td>
                <td />
              </tr>
            </thead>
            <tbody>
              {files?.files?.map((file) => (
                <tr key={file.id}>
                  <td>
                    <label
                      htmlFor={`key`}
                      className={clsx(tableStyle.column_fixed_height)}
                    >
                      <label
                        htmlFor={`key`}
                        className={clsx(tableStyle.checkbox_label)}
                      >
                        {file.name}
                      </label>
                    </label>
                  </td>
                  <td>
                    <button
                      className={clsx(style.signatures_list_btn)}
                      onClick={() =>
                        open("SignatureLogModal", {
                          modalData: {
                            file,
                            departmentId,
                          },
                        })
                      }
                    >
                      Журнал подписей
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default DocumentTable;
