import { FC } from "react";

import clsx from "clsx";

import styles from "./MessageFiles.module.scss";
import { downloadFile } from "@utils/downloadFile";
import { formatFileSize } from "@utils/formatFileSize";
import { FileDocSVG } from "@components/svgs";
import { concatApiUrl } from "@utils/api-tools";
import { FileT } from "@interfaces/common";

type MessageFilesPropsT = {
  className?: string;
  files: FileT[];
};

export const MessageFiles: FC<MessageFilesPropsT> = ({ files }) => {
  const handleDownloadFile = (file: FileT) => {
    const baseUrl = concatApiUrl("/uploads/technical-support/issues");
    const downloadUrl = `${baseUrl}/${file.filename}`;

    downloadFile(downloadUrl, file.originalName);
  };

  return (
    <div className={styles.fileList}>
      {files.map((file) => (
        <div key={file._id} onClick={() => handleDownloadFile(file)}>
          <div className={clsx(styles.file, styles.file_message)}>
            <FileDocSVG />
            <div className={styles.file__name}>
              {(file.originalName.length > 12
                ? file.originalName.slice(0, 12) + "..."
                : file.originalName) ?? ""}
              <div>({formatFileSize(file.size)})</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
