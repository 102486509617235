import { useState } from "react";

export const useFormDirtyFields = <TField extends string>() => {
  const [dirtyFields, setDirtyFields] = useState<Record<TField, number>>(
    {} as Record<TField, number>
  );

  const handleTouchField = (field: TField) => {
    setDirtyFields((fields) => ({
      ...fields,
      [field]: field in fields ? fields[field]++ : 1,
    }));
  };

  const isFieldTouched = (field: TField) => {
    return field in dirtyFields;
  };

  return {
    dirtyFields,
    handleTouchField,
    isFieldTouched,
  };
};
