import { createEvent, createStore } from "effector";
import { ImportEmployeesStore } from "@interfaces/company/employees";

export const addMissingEmployees = createEvent<number[]>();
export const addImportedPositions = createEvent<number[]>();
export const addImportedDepartments = createEvent<number[]>();

export const removeMissingEmployee = createEvent<number>();
export const removeImportedPosition = createEvent<number>();
export const removeImportedDepartment = createEvent<number>();

export const $ImportEmployees = createStore<ImportEmployeesStore>({
  missingPositions: [],
  missingDepartments: [],
  missingEmployees: [],
})
  .on(addMissingEmployees, (state, payload) => {
    return {
      ...state,
      missingEmployees: [...state.missingEmployees, ...payload],
    };
  })
  .on(addImportedPositions, (state, payload) => {
    return {
      ...state,
      missingPositions: [...state.missingPositions, ...payload],
    };
  })
  .on(addImportedDepartments, (state, payload) => {
    return {
      ...state,
      missingDepartments: [...state.missingDepartments, ...payload],
    };
  })
  .on(removeMissingEmployee, (state, payload) => {
    return {
      ...state,
      missingEmployees: state.missingEmployees.filter((id) => id !== payload),
    };
  })
  .on(removeImportedPosition, (state, payload) => {
    return {
      ...state,
      missingPositions: state.missingPositions.filter((id) => id !== payload),
    };
  })
  .on(removeImportedDepartment, (state, payload) => {
    return {
      ...state,
      missingDepartments: state.missingDepartments.filter(
        (id) => id !== payload
      ),
    };
  });
