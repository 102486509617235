import {
  $Employees,
  $EmployeesStates,
  fetchEmployees,
  setEmployees,
} from "@store/company/employees-store";
import { $Departments, setDepartments } from "@store/company/departments-store";
import {
  $UserAddPermissions,
  ACTIONS,
  UserPriorityEnum,
  UserRoleEnum,
} from "@store/user-store";
import { Archive, NotePencil } from "@phosphor-icons/react";
import {
  EmployeeDocumentT,
  EmployeeListDataT,
  RelatedEmployeeDataT,
} from "@interfaces/company/employees";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { CompanyTabPropsT } from "@interfaces/company/company";
import EmployeeService from "@services/employee-service";
import { EmployeeSignaturesTable } from "@components/tables";
import { ErrorIndicator } from "@ui/indicators";
import Loader from "@ui/indicators/loader";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import { pushToArchiveEmployees } from "@store/company/archive-store";
import style from "@scss/pages/company/single-employee.module.scss";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";

const SingleEmployee: React.FC<CompanyTabPropsT> = () => {
  const employees = useStore($Employees);
  const { isFetched } = useStore($EmployeesStates);
  const departments = useStore($Departments);
  const permissions = useStore($UserAddPermissions);
  const [currentEmployee, setCurrentEmployee] =
    useState<RelatedEmployeeDataT | null>(null);
  const [state, setState] = useState({
    isInEmployeesList: false,
    error: false,
  });
  const { open } = useModal();
  const query = useRouteMatch<{ id: string }>();
  const history = useHistory();

  useEffect(() => {
    if (!isFetched) {
      fetchEmployees();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetched) {
      const currentEmployeeInCommonList = employees.find(
        (employee) => employee.id === +query.params.id
      );
      const extended = !currentEmployeeInCommonList;

      EmployeeService.getById(+query.params.id, extended, (err, res) => {
        if (err || !res) {
          return setState({ ...state, error: true });
        }
        if (!extended) {
          return setCurrentEmployee({
            ...(currentEmployeeInCommonList as EmployeeListDataT),
            signed_documents: res.data as EmployeeDocumentT[],
          });
        }
        setCurrentEmployee(res.data as RelatedEmployeeDataT);
      });

      if (currentEmployeeInCommonList) {
        setState({ ...state, isInEmployeesList: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);

  const handleSendingNewSignatures = () => {
    EmployeeService.getById(+query.params.id, false, (err, res) => {
      if (err || !res) {
        return setState({ ...state, error: true });
      }
      setCurrentEmployee({
        ...(currentEmployee as EmployeeListDataT),
        signed_documents: res.data as EmployeeDocumentT[],
      });
    });
  };

  const handleAddToArchive = (employee: RelatedEmployeeDataT) => {
    EmployeeService.AddToArchive(employee.id, (err) => {
      if (err) {
        return console.error(
          "При добавлении пользователя в архив произошла ошибка"
        );
      }

      setDepartments(
        departments.map((department) => {
          if (employee.position?.department?.id === department.id) {
            department.employeesCount--;
          }
          return department;
        })
      );
      pushToArchiveEmployees(employee);
      setEmployees(employees.filter((empl) => empl.id !== employee.id));
      history.push("/company/employees");
    });
  };

  return (
    <>
      <div className={clsx(style.single_employee__info_container)}>
        {/* FIRST BLOCK */}
        {state.error ? (
          <ErrorIndicator />
        ) : !currentEmployee ? (
          <Loader />
        ) : (
          <section className={clsx(style.single_employee__section)}>
            <div className={clsx(style.single_employee__section_top_panel)}>
              <p className={clsx(style.employee_name)}>
                {currentEmployee.name}
              </p>
              {permissions.hasRequiredLevel(UserPriorityEnum.Client) ? null : (
                <div className={clsx(style.single_employee__buttons)}>
                  {permissions.hasPermission(
                    ACTIONS.companyStaffing_employees_allowedToEdit
                  ) && (
                    <button
                      className={clsx(style.single_employee__button)}
                      onClick={() =>
                        open("CreateEmployeeModal", {
                          btnText: "Сохранить",
                          modalData: {
                            editMode: true,
                            employee: currentEmployee,
                          },
                          onConfirm: (data: EmployeeListDataT) =>
                            setCurrentEmployee({
                              ...currentEmployee,
                              ...data,
                            }),
                        })
                      }
                    >
                      <WrapperSVG color={"orange"} margin>
                        <NotePencil size={24} />
                      </WrapperSVG>
                    </button>
                  )}
                  {permissions.hasPermission(
                    ACTIONS.companyStaffing_employees_allowedToArchive
                  ) && (
                    <button
                      className={clsx(style.single_employee__button)}
                      onClick={() => handleAddToArchive(currentEmployee)}
                    >
                      <WrapperSVG color="red">
                        <Archive size={24} />
                      </WrapperSVG>
                    </button>
                  )}
                </div>
              )}
            </div>
            {permissions.hasPermission(
              ACTIONS.companyWorkspace_allowedToSendForSignature
            ) && (
              <button
                className={clsx(style.send_to_signature_btn)}
                onClick={() =>
                  open("SendingForSignatureModal", {
                    modalData: {
                      employeeId: currentEmployee.id,
                      positions: currentEmployee?.position
                        ? [currentEmployee.position]
                        : null,
                    },
                    onConfirm: handleSendingNewSignatures,
                  })
                }
              >
                Отправить на подпись
              </button>
            )}
            <p
              className={clsx(style.key_value_item, {
                // eslint-disable-next-line no-useless-computed-key
                ["mt-12"]: permissions.roleIsIn([UserRoleEnum.Client], true),
              })}
            >
              Подразделение:
              <span>{currentEmployee.position?.department?.title}</span>
            </p>
            <p className={clsx(style.key_value_item)}>
              Должность:
              <span>{currentEmployee.position?.title}</span>
            </p>
            <p className={clsx(style.key_value_item)}>
              Почта: <span>{currentEmployee.email}</span>
            </p>
            <p className={clsx(style.key_value_item)}>
              Телефон: <span>{currentEmployee.phone}</span>
            </p>
          </section>
        )}
      </div>
      {/* SIGNATURES TABLE */}
      <EmployeeSignaturesTable
        items={currentEmployee?.signed_documents}
        employeeId={currentEmployee?.id as number}
      />
    </>
  );
};

export default SingleEmployee;
