import {
  StudyCenterProgramsParams,
  studyCenterService,
} from "@services/study-center";
import { useQuery } from "react-query";
import { studyCenterQueryKeys } from "./queryKeys";

export const useStudyCenterProgramsQuery = (
  params?: StudyCenterProgramsParams
) => {
  const { data: programs, ...stuff } = useQuery(
    studyCenterQueryKeys.programs(params),
    () => studyCenterService.getPrograms(params)
  );

  return {
    programs,
    ...stuff,
  };
};
