import React from "react";

export const ExternalLinkSVG: React.FC<{ color?: string }> = ({
  color = "#00B856",
}) => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.75781 15.0625H12.2344C13.875 15.0625 14.6875 14.25 14.6875 12.6406V3.10156C14.6875 1.49219 13.875 0.679688 12.2344 0.679688H2.75781C1.125 0.679688 0.304688 1.48438 0.304688 3.10156V12.6406C0.304688 14.2578 1.125 15.0625 2.75781 15.0625ZM2.77344 13.8047C1.99219 13.8047 1.5625 13.3906 1.5625 12.5781V3.16406C1.5625 2.35156 1.99219 1.9375 2.77344 1.9375H12.2188C12.9922 1.9375 13.4297 2.35156 13.4297 3.16406V12.5781C13.4297 13.3906 12.9922 13.8047 12.2188 13.8047H2.77344ZM9.94531 9.79688C10.2891 9.79688 10.5234 9.53125 10.5234 9.17188V5.48438C10.5234 5.02344 10.2656 4.84375 9.86719 4.84375H6.16406C5.79688 4.84375 5.5625 5.07031 5.5625 5.41406C5.5625 5.75781 5.80469 5.98438 6.17969 5.98438H7.60156L8.75781 5.85938L7.53906 6.99219L4.65625 9.875C4.54688 9.98438 4.47656 10.1406 4.47656 10.2969C4.47656 10.6484 4.70312 10.875 5.04688 10.875C5.23438 10.875 5.38281 10.8047 5.49219 10.6953L8.36719 7.82031L9.49219 6.61719L9.375 7.83594V9.1875C9.375 9.55469 9.60156 9.79688 9.94531 9.79688Z"
        fill={color}
      />
    </svg>
  );
};
