export const PeopleSVG = () => {
  return (
    <svg
      width="31"
      height="15"
      viewBox="0 0 31 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7266 7.28125C17.4062 7.28125 18.8516 5.78125 18.8516 3.82812C18.8516 1.89844 17.3984 0.46875 15.7266 0.46875C14.0625 0.46875 12.6016 1.92969 12.6094 3.84375C12.6094 5.78125 14.0547 7.28125 15.7266 7.28125ZM6.71875 7.46094C8.17969 7.46094 9.4375 6.14844 9.4375 4.45312C9.4375 2.77344 8.17188 1.53125 6.71875 1.53125C5.27344 1.53125 3.99219 2.80469 4 4.46875C4 6.14844 5.26562 7.46094 6.71875 7.46094ZM24.7109 7.46094C26.1719 7.46094 27.4375 6.14844 27.4375 4.46875C27.4375 2.80469 26.1641 1.53125 24.7109 1.53125C23.2656 1.53125 21.9922 2.77344 21.9922 4.45312C21.9922 6.14844 23.2578 7.46094 24.7109 7.46094ZM2.36719 14.5156H8.29688C7.48438 13.3359 8.47656 10.9609 10.1641 9.66406C9.28906 9.08594 8.17188 8.65625 6.71875 8.65625C3.19531 8.65625 0.992188 11.2578 0.992188 13.4219C0.992188 14.125 1.375 14.5156 2.36719 14.5156ZM29.0703 14.5156C30.0625 14.5156 30.4453 14.125 30.4453 13.4219C30.4453 11.2578 28.2422 8.65625 24.7188 8.65625C23.2578 8.65625 22.1406 9.08594 21.2734 9.66406C22.9531 10.9609 23.9453 13.3359 23.1328 14.5156H29.0703ZM10.8203 14.5156H20.6328C21.8594 14.5156 22.2969 14.1641 22.2969 13.4766C22.2969 11.4609 19.7734 8.67969 15.7266 8.67969C11.6719 8.67969 9.14844 11.4609 9.14844 13.4766C9.14844 14.1641 9.58594 14.5156 10.8203 14.5156Z"
        fill="#00B856"
      />
    </svg>
  );
};
