import React, { memo } from "react";

import { BreadCrumb } from "@components/common";
import { EditRequestTraining } from "feature/request-training/edit-request-training";
import { Title } from "@components/common/common";

const EditRequestTrainingPage: React.FC = memo(() => {
  return (
    <>
      <main className="content-container">
        <div className="content-section">
          <div className="top-content">
            <BreadCrumb items={["Главная", "Контроль заявок на обучение"]} />
            <Title text="Контроль заявок на обучение" />
          </div>
          <EditRequestTraining />
        </div>
      </main>
    </>
  );
});

export default EditRequestTrainingPage;
