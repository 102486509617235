import {
  $CommonLegalInformationDocuments,
  $LegalInformationDocuments,
  $LegalInformationStates,
  setLegalInformationCommonDocs,
  setLegalInformationDocuments,
  setLegalInformationError,
  setLegalInformationLoading,
  setLegalInformationStates,
} from "@store/legal-information-store";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import React, { useEffect, useState } from "react";
import {
  ResponsibilityDirectoryT,
  ResponsibilityFileT,
  ResponsibilityTablePropsT,
} from "@interfaces/responsibility";
import { useHistory } from "react-router-dom";

import { ErrorIndicator } from "@ui/indicators";
import LegalInformationService from "@services/legal-information-service";
import Loader from "@ui/indicators/loader";
import clsx from "clsx";
import qs from "qs";
import style from "@scss/pages/responsibility.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import { LegalInformationDirectory } from "./legal-information-directory";
import { LegalInformationFile } from "./legal-information-file";
import { useChoiceState } from "@hooks/useChoiceState";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import { Tooltip } from "@material-ui/core";
import { Trash } from "@phosphor-icons/react";
import { useAsyncConfirm } from "@hooks/useAsyncConfirm";
import {
  useDeleteLegalInformationDirectoryMutation,
  useDeleteLegalInformationFileMutation,
} from "@lib/legal-information";

const LegalInformationTable: React.FC<ResponsibilityTablePropsT> = ({
  setWithHistory,
  sort,
}) => {
  const commonDocuments = useStore($CommonLegalInformationDocuments);
  const currentDocuments = useStore($LegalInformationDocuments);
  const { isFetched, isLoading, error } = useStore($LegalInformationStates);
  const permissions = useStore($UserAddPermissions);
  const [directoryId, setDirectoryId] = useState<number>(0);

  const history = useHistory();
  const { close } = useModal();
  const confirm = useAsyncConfirm();

  const dirChoice = useChoiceState(currentDocuments[1], (dir) => dir.id);
  const filesChoice = useChoiceState(currentDocuments[0], (file) => file.id);

  const deleteDirectoryMutation = useDeleteLegalInformationDirectoryMutation();
  const deleteFileMutation = useDeleteLegalInformationFileMutation();

  useEffect(() => {
    const folderId = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    })?.folder_id;
    if (+(folderId as string) > 0) {
      setWithHistory(true);
    } else {
      setWithHistory(false);
    }

    setDirectoryId(+(folderId as string) || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  useEffect(() => {
    setLegalInformationLoading(true);
    if (directoryId !== 0) {
      LegalInformationService.getItems(directoryId, (err, res) => {
        if (err || !res) {
          return setLegalInformationError(true);
        }
        setLegalInformationDocuments(
          sortDocuments([res.data.files, res.data.directories])
        );
        setLegalInformationLoading(false);
      });
      return;
    }
    if (directoryId === 0 && !isFetched) {
      LegalInformationService.getItems(directoryId, (err, res) => {
        if (err || !res) {
          return setLegalInformationError(true);
        }
        setLegalInformationCommonDocs([res.data.files, res.data.directories]);
        setLegalInformationStates({
          error,
          isLoading: false,
          isFetched: true,
        });
      });
      return;
    }
    setLegalInformationDocuments(sortDocuments(commonDocuments));
    setLegalInformationLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directoryId]);

  useEffect(() => {
    if (directoryId === 0) {
      setLegalInformationDocuments(sortDocuments(commonDocuments));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonDocuments]);

  useEffect(() => {
    setLegalInformationDocuments(sortDocuments(currentDocuments));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  const sortDocuments = (
    documents: [ResponsibilityFileT[], ResponsibilityDirectoryT[]]
  ) => {
    if (sort === 10) {
      documents.forEach((docs) => {
        docs.sort((doc1, doc2) => {
          return (
            new Date(doc2.updatedAt).getTime() -
            new Date(doc1.updatedAt).getTime()
          );
        });
      });
    } else if (sort === 20) {
      documents.forEach((docs) => {
        docs.sort((doc1, doc2) => {
          return (
            new Date(doc1.updatedAt).getTime() -
            new Date(doc2.updatedAt).getTime()
          );
        });
      });
    }
    return Array.from(documents) as [
      ResponsibilityFileT[],
      ResponsibilityDirectoryT[],
    ];
  };

  const handleDirectoryDelete = (folderId: number) => {
    deleteDirectoryMutation.mutate(folderId, {
      onSuccess: () => {
        if (directoryId === 0) {
          setLegalInformationCommonDocs([
            commonDocuments[0],
            commonDocuments[1].filter((dir) => dir.id !== folderId),
          ]);
        } else {
          setLegalInformationDocuments(
            sortDocuments([
              currentDocuments[0],
              currentDocuments[1].filter((dir) => dir.id !== folderId),
            ])
          );
        }
        close();
      },
    });
  };

  const handleFileDelete = (id: number) => {
    deleteFileMutation.mutate(id, {
      onSuccess: () => {
        if (directoryId === 0) {
          setLegalInformationCommonDocs([
            commonDocuments[0].filter((file) => file.id !== id),
            commonDocuments[1],
          ]);
        } else {
          setLegalInformationDocuments(
            sortDocuments([
              currentDocuments[0].filter((file) => file.id !== id),
              currentDocuments[1],
            ])
          );
        }
        close();
      },
    });
  };

  const handleDeleteSelectedItems = async () => {
    const ok = await confirm({
      btnText: "Удалить",
      modalData: {
        text: "Вы уверены, что хотите удалить выбранные элементы?",
      },
    });
    if (!ok) return;

    const deletedDirectories = dirChoice.selectedItems.map((dir) => dir.id);
    const deletedFiles = filesChoice.selectedItems.map((file) => file.id);

    const promises = [];
    for (const dir of deletedDirectories) {
      promises.push(handleDirectoryDelete(dir));
    }
    for (const file of deletedFiles) {
      promises.push(handleFileDelete(file));
    }
    await Promise.all(promises);

    setLegalInformationCommonDocs([
      commonDocuments[0].filter((file) => !deletedFiles.includes(file.id)),
      commonDocuments[1].filter((dir) => !deletedDirectories.includes(dir.id)),
    ]);
    setLegalInformationDocuments(
      sortDocuments([
        currentDocuments[0].filter((file) => !deletedFiles.includes(file.id)),
        currentDocuments[1].filter(
          (dir) => !deletedDirectories.includes(dir.id)
        ),
      ])
    );
  };

  return (
    <div className={clsx(tableStyle.base_table_container)}>
      {error ? (
        <ErrorIndicator />
      ) : isLoading ? (
        <Loader />
      ) : (
        <table
          className={clsx(tableStyle.base_table, style.responsibility_table)}
        >
          <thead>
            <tr>
              <td>
                <label>
                  <input
                    type="checkbox"
                    className={clsx(tableStyle.checkbox_item)}
                    hidden
                    id="all-items-checkbox"
                    checked={
                      dirChoice.areAllItemsSelected &&
                      filesChoice.areAllItemsSelected
                    }
                    onChange={(e) => {
                      dirChoice.selectAll(e.target.checked);
                      filesChoice.selectAll(e.target.checked);
                    }}
                  />
                  <label htmlFor="all-items-checkbox">
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </label>
                  <label className={clsx(tableStyle.checkbox_label)}>
                    Название документа
                  </label>
                </label>
              </td>
              <td>Дата обновления</td>
              <td align="right">
                {(dirChoice.selectedItems.length > 0 ||
                  filesChoice.selectedItems.length > 0) &&
                  permissions.hasPermission([
                    ACTIONS.legalInformation_directories_allowedToDelete,
                    ACTIONS.legalInformation_files_allowedToDelete,
                  ]) && (
                    <WrapperSVG color="">
                      <Tooltip title={"Удалить"} placement="top">
                        <Trash size={24} onClick={handleDeleteSelectedItems} />
                      </Tooltip>
                    </WrapperSVG>
                  )}
              </td>
            </tr>
          </thead>
          <tbody>
            {currentDocuments[1].map((directory) => (
              <LegalInformationDirectory
                key={directory.id}
                directory={directory}
                isSelected={dirChoice.getIsItemSelected(directory)}
                onDelete={() => handleDirectoryDelete(directory.id)}
                onSelect={(checked) => dirChoice.selectById(directory, checked)}
              />
            ))}
            {currentDocuments[0].map((file) => (
              <LegalInformationFile
                key={file.id}
                file={file}
                isSelected={filesChoice.getIsItemSelected(file)}
                onDelete={() => handleFileDelete(file.id)}
                onSelect={(checked) => filesChoice.selectById(file, checked)}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default LegalInformationTable;
