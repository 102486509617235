import React, { useEffect, useState } from "react";

import EmployeeService from "@services/employee-service";
import { SigningDocumentT } from "@interfaces/company/employees";
import { SigningDocumentsPropsT } from "@interfaces/signing-documents";
import clsx from "clsx";
import { downloadBlob } from "@utils/downloadBlob";
import qs from "qs";
import style from "@scss/pages/signing-documents.module.scss";
import { useLocation } from "react-router-dom";

export const Documents: React.FC<SigningDocumentsPropsT> = ({
  items,
  onReadyToSign,
}) => {
  const [docs, setDocs] = useState<SigningDocumentT[]>(items);
  const [params, setParams] = useState({
    companyId: 0,
    hash: "",
    employeeId: 0,
    eventId: 0,
  });
  const location = useLocation();

  useEffect(() => {
    const Querystring = qs.parse(location.search, { ignoreQueryPrefix: true });
    setParams({
      companyId: +(Querystring.company as string),
      hash: Querystring.hash + "",
      employeeId: +(Querystring.employee as string),
      eventId: +(Querystring.eventId as string),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isNotViewedDoc = docs.find((doc) => !doc.viewed);

    if (!isNotViewedDoc) {
      onReadyToSign();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [docs]);

  const handleOnClick = (signature: SigningDocumentT) => {
    EmployeeService.DownloadFileForSignature(
      params.employeeId,
      params.hash,
      params.eventId,
      signature.file.id,
      (err, res) => {
        if (err || !res) {
          return;
        }

        downloadBlob(res.data, signature.file.name);

        if (!signature.viewed) {
          setDocs(
            docs.map((doc) => {
              if (doc.id === signature.id) {
                doc.viewed = true;
                EmployeeService.MarkDocumentAsViewed(
                  params.companyId,
                  signature.id,
                  params.hash,
                  (err, res) => {
                    if (err) {
                      return console.error(
                        "Не получилось отметить файл просмотренным"
                      );
                    }
                  }
                );
              }
              return doc;
            })
          );
        }
      }
    );
  };

  const content = docs.map((item) => (
    <div
      className={clsx(style.document_item, { [style.viewed]: item.viewed })}
      onClick={() => handleOnClick(item)}
    >
      <div className={clsx(style.view_status)}>
        {item.viewed ? "Просмотрено" : "Не просмотрено"}
      </div>
      <p className={clsx(style.document_name)}>{item.file.name}</p>
    </div>
  ));

  return <div className={clsx(style.documents)}>{content}</div>;
};
