import { $User } from "@store/user-store";

import Loader from "@ui/indicators/loader";
import { UserDataT } from "@interfaces/user";
import styles from "./Companies.module.scss";
import { useStore } from "effector-react";
import { useCompaniesQuery } from "@lib/company";
import { CompanyCard } from "../CompanyCard";

export const Companies: React.FC = () => {
  const user = useStore($User) as UserDataT;

  const { companies } = useCompaniesQuery();

  if (!user) {
    return <Loader />;
  }

  return (
    <div className={styles.wrapper}>
      {companies?.map((company) => (
        <CompanyCard key={company.id} company={company} />
      ))}
    </div>
  );
};
