import React, { memo } from "react";

import { BreadCrumb } from "@components/common";
import { DocumentTemplates } from "feature/document-templates";
import { Title } from "@components/common/common";

const DocumentTemplatesPage: React.FC = memo(() => {
  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={["Главная", "Шаблоны документов"]} />
          <Title text="Шаблоны документов" />
        </div>
        <DocumentTemplates />
      </div>
    </main>
  );
});

export default DocumentTemplatesPage;
