import { createEffect, createEvent, createStore } from "effector";

import DirectoryVersionService from "@services/version-service";
import { EventStateStoreT } from "@interfaces/common";
import { VersionsAllT } from "@interfaces/directory/version";
import { resetAllStates } from "@store/user-store";

export const setDirectoryVersion = createEvent<VersionsAllT[]>();
export const pushDirectoryVersion = createEvent<VersionsAllT[]>();
export const editDirectoryVersion = createEvent<VersionsAllT>();
export const $DirectoryVersionMedicalExamination = createStore<VersionsAllT[]>([]).on(
  setDirectoryVersion,
  (oldState, newState) => [...newState]
);

export const setDirectoryVersionStates = createEvent<EventStateStoreT>();
export const setDirectoryVersionLoading = createEvent<boolean>();
export const setDirectoryVersionError = createEvent<boolean>();

export const $DirectoryVersionStates = createStore<EventStateStoreT>({
  isLoading: true,
  error: false,
  isFetched: false,
})
  .on(setDirectoryVersionLoading, (oldState, newState) => ({
    ...oldState,
    isLoading: newState,
  }))
  .on(setDirectoryVersionError, (oldState, newState) => ({
    ...oldState,
    isLoading: false,
    error: newState,
  }))
  .on(setDirectoryVersionStates, (_, newStateTraining) => newStateTraining)
  .reset(resetAllStates);

export const FetchDirectoryVersionMedicalExamination = createEffect(
  (type: "training" | "briefing" | "medical-examination") => {
    setDirectoryVersionLoading(true);
    DirectoryVersionService.GetAllChanges(type, (err, res) => {
      if (err || !res) {
        setDirectoryVersionStates({
          error: true,
          isFetched: false,
          isLoading: false,
        });
        return console.error("При получении данных произошла ошибка", err);
      }
      if (res.data) {
        if (!!res.data.length) {
          const newData = [...res.data].reverse();
          setDirectoryVersion(newData);
        }
      }
      setDirectoryVersionStates({
        error: false,
        isFetched: true,
        isLoading: false,
      });
    });
  }
);
