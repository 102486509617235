import { memo, useRef, useState } from "react";

import { SimpleOptionT } from "@interfaces/company/employees";
import clsx from "clsx";
import s from "./drop-down-select.module.scss";
import { useOnClickOutside } from "@hooks/useOnClickOutside";

interface DropDownSelectProps {
  title: string;
  options: SimpleOptionT[];
  value?: SimpleOptionT;
  onChange: (value: SimpleOptionT) => void;
  className?: any;
}

export const DropDownSelect: React.FC<DropDownSelectProps> = memo((props) => {
  const { value, options, className, onChange } = props;

  const [open, setOpen] = useState(false);
  const listRef = useRef<any>(null);

  useOnClickOutside(listRef, () => {
    setOpen(false);
  });

  const onClickHandler = (option: SimpleOptionT) => {
    onChange(option);
    setOpen(false);
  };
  return (
    <>
      <div className={clsx(s.container)}>
        <span className={className} onClick={() => setOpen(!open)}>
          {value ? value.label : options[0]}
        </span>
        {open && (
          <ul ref={listRef} className={clsx(s.list)}>
            {options.map((option) => {
              return (
                <li value={option.value} onClick={() => onClickHandler(option)}>
                  <span className={s.radioCircle}>
                    {value && value.value === option.value && (
                      <span className={s.innerCircle}></span>
                    )}
                  </span>
                  {option.label}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </>
  );
});
