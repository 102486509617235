import { useInternalMaterialQuery } from "@lib/internal-study-center/useInternalMaterialQuery";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { CreatePublicArticle } from "../create-public-article";

export const EditPublicArticle: FC = () => {
  const { sectionId, categoryId, id } = useParams() as {
    sectionId: string;
    categoryId: string;
    id: string;
  };

  const { material } = useInternalMaterialQuery(
    id ? +id : undefined,
    sectionId ? +sectionId : undefined,
    categoryId ? +categoryId : undefined
  );

  if (!material) {
    return null;
  }

  return (
    <CreatePublicArticle
      id={material.id}
      type="edit"
      title={material.title}
      text={material.text ?? ""}
    />
  );
};
