import "dayjs/locale/ru";

import { SetStateAction, useEffect, useState } from "react";

import { DatePicker } from "@mantine/dates";
import Modal from "@mui/material/Modal";
import { X } from "@phosphor-icons/react";
import style from "./date-period.module.scss";

type Props = {
  closeModal: () => void;
  active: boolean;
  selectedDate: Date | null;
  setTextDate: React.Dispatch<SetStateAction<string>>;
  setSelectedDate: React.Dispatch<SetStateAction<Date | null>>;
  type: string;
  handlerChangeFilter: (key: string, value: string[] | undefined) => void;
};

export const DatePeriod: React.FC<Props> = ({
  closeModal,
  active,
  setSelectedDate,
  setTextDate,
  selectedDate,
  type,
  handlerChangeFilter,
}) => {
  const [value, setValue] = useState<[Date | null, Date | null]>([null, null]);
  const handleRangeCalendarChange = (newValue: [Date | null, Date | null]) => {
    setValue(newValue);
    setSelectedDate(newValue[0]);
  };

  const handleAcceptButtonClick = () => {
    closeModal();
  };

  const handleClearButtonClick = () => {
    setValue([null, null]);
    setSelectedDate(null);
  };

  const handleCancelButtonClick = () => {
    handleClearButtonClick();
    closeModal();
  };

  const dateDisplay = selectedDate
    ? `${selectedDate.toLocaleDateString()}  ${
        !!value[1] ? " - " + value[1].toLocaleDateString() : ""
      }`
    : "Выбрать...";

  const dateParsing = (isDate: Date): string => {
    return String(Date.parse(isDate.toString()));
  };

  useEffect(() => {
    if (selectedDate) {
      handlerChangeFilter(
        type,
        value[1]
          ? [dateParsing(selectedDate), dateParsing(value[1])]
          : [dateParsing(selectedDate)]
      );
    } else {
      handlerChangeFilter(type, undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, type, value]);

  useEffect(() => {
    setTextDate(dateDisplay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateDisplay]);

  return (
    <Modal
      open={active}
      onClose={handleAcceptButtonClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={style.modal_box}>
        <div className={style.calendar}>
          <DatePicker
            locale="ru"
            value={value}
            type={"range"}
            onChange={handleRangeCalendarChange}
            classNames={{
              day: style.calendar_day,
              weekday: style.calendar_weekday,
            }}
          />
        </div>
        {dateDisplay && !dateDisplay.includes("Выбрать...") && (
          <div className={style.calendarDate}>
            <div className={style.calendarDate_date}>{dateDisplay}</div>
            <button
              onClick={handleClearButtonClick}
              className={style.calendarDate_clear}
            >
              <X />
            </button>
          </div>
        )}
        <div className={style.calendar_buttons}>
          <button onClick={handleCancelButtonClick}>ОТМЕНИТЬ</button>
          <button onClick={handleAcceptButtonClick}>ОК</button>
        </div>
      </div>
    </Modal>
  );
};
