import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useState } from "react";
import {
  useCreateEducationalPromotionMutation,
  useStudyCenterEducationalPromotionQuery,
  useUpdateEducationalPromotionMutation,
} from "@lib/study-center";
import { useHistory, useParams } from "react-router-dom";

import { BodyNormal } from "@ui/fonts";
import { Button } from "@ui/button";
import Dropzone from "@ui/dropzone";
import MomentUtils from "@date-io/moment";
import { RichEditor } from "@ui/rich-editor";
import { handleApiError } from "@utils/handleApiError";
import moment from "moment";
import styles from "./create-educational-promotion.module.scss";

type CreateEducationalPromotionParams = {
  id?: string;
};

export const CreateEducationalPromotion: React.FC = () => {
  const history = useHistory();
  const educationalPromotionId =
    useParams<CreateEducationalPromotionParams>().id;

  const id =
    typeof educationalPromotionId === "undefined"
      ? undefined
      : +educationalPromotionId;
  const { educationalPromotion } = useStudyCenterEducationalPromotionQuery(id);

  const createEducationalPromotionMutation =
    useCreateEducationalPromotionMutation();
  const updateEducationalPromotionMutation =
    useUpdateEducationalPromotionMutation();

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [title, setTitle] = useState(educationalPromotion?.title ?? "");
  const [date, setDate] = useState(
    educationalPromotion?.date
      ? new Date(educationalPromotion?.date)
      : new Date()
  );
  const [description, setDescription] = useState(
    educationalPromotion?.description ?? ""
  );
  const [shortDescription, setShortDescription] = useState(
    educationalPromotion?.shortDescription ?? ""
  );
  const [files, setFiles] = useState<File[]>([]);

  const isEdit = !history.location.pathname.endsWith("/create");

  const handleUploadFiles = (files: File[]) => {
    setFiles((prev) => [...prev, ...files]);
  };

  const handleDeleteFile = (file: File) => {
    setFiles((files) => files.filter((_file) => _file.name !== file.name));
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const onSuccess = () => {
      history.push("/study-center/materials/educational-promotions");
    };

    const onError = (error: unknown) => {
      handleApiError(error, setErrors);
    };

    if (isEdit) {
      updateEducationalPromotionMutation.mutate(
        {
          id,
          title,
          shortDescription,
          description,
          file: files[0],
          date: date.toISOString(),
        },
        {
          onSuccess,
          onError,
        }
      );
    } else {
      createEducationalPromotionMutation.mutate(
        {
          title,
          shortDescription,
          description,
          file: files[0],
          date: date.toISOString(),
        },
        {
          onSuccess,
          onError,
        }
      );
    }
  };

  const handleContent = (content: string) => {
    setDescription(content);
  };

  return (
    <div className={styles.EducationalPromotion}>
      <form className={styles.EducationalPromotion__form} onSubmit={onSubmit}>
        <input
          placeholder="Название акции и предложения*"
          required
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className={styles.EducationalPromotion__input}
        />
        <input
          placeholder="Краткое описание акции и предложения*"
          required
          maxLength={255}
          value={shortDescription}
          onChange={(e) => setShortDescription(e.target.value)}
          className={styles.EducationalPromotion__input}
        />
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale={"ru"}
        >
          <DateTimePicker
            cancelLabel={"Отменить"}
            okLabel={"ОК"}
            value={date}
            disablePast
            disableToolbar
            onChange={setDate}
            label="Начало"
            format="DD.MM.YYYY HH:mm"
            error={false}
            helperText=""
          />
        </MuiPickersUtilsProvider>
        <RichEditor
          label="Описание*"
          initialContent={description || ""}
          handleContent={handleContent}
        />
        <Dropzone
          files={files}
          maxFiles={1}
          withPreviews
          accept="image/*"
          onUpload={handleUploadFiles}
          onDeleteFile={handleDeleteFile}
        />
        {errors.upload && <BodyNormal color="red">{errors.upload}</BodyNormal>}
        <Button
          type="submit"
          className={styles.EducationalPromotion__submitBtn}
          disabled={
            createEducationalPromotionMutation.isLoading ||
            updateEducationalPromotionMutation.isLoading
          }
        >
          Сохранить
        </Button>
      </form>
    </div>
  );
};
