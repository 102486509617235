import { useModal } from "@modals/index";
import { CSSProperties, useCallback } from "react";

export type ConfirmParams = {
  btnText?: string;
  modalData?: {
    style?: CSSProperties;
    titleStyle?: CSSProperties;
    title?: string;
    removeIcon?: boolean;
    html?: boolean;
    textStyle?: CSSProperties;
    text?: string;
    cancelBtnText?: string;
  };
};

export const useAsyncConfirm = () => {
  const modal = useModal();

  const confirm = useCallback(
    async (params: ConfirmParams) => {
      return new Promise<boolean>((resolve) => {
        modal.open("ConfirmActionModal", {
          btnText: params.btnText ?? "Да",
          modalData: {
            ...params.modalData,
            title: params.modalData?.title ?? "Подтвердите действие",
            cancelBtnText: params.modalData?.cancelBtnText ?? "Нет",
            onCancel: () => {
              resolve(false);
              modal.close();
            },
          },
          onConfirm: () => {
            resolve(true);
          },
        });
      });
    },
    [modal]
  );

  return confirm;
};
