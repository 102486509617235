import { WorkspaceGetFilesParams } from "@interfaces/company/workspace";

export const documentTemplatesQueryKeys = {
  all: ["document-templates"],
  allGroups: () => [...documentTemplatesQueryKeys.all, "groups"],
  allDirectoriesFiles: () => [
    ...documentTemplatesQueryKeys.all,
    "directories-files",
  ],
  directoriesFiles: (
    groupId: number,
    directoryId: number | undefined,
    params?: WorkspaceGetFilesParams
  ) => [
    ...documentTemplatesQueryKeys.allDirectoriesFiles(),
    {
      groupId,
      directoryId,
    },
    params,
  ],
  groupFiles: (groups: number[]) => [
    ...documentTemplatesQueryKeys.all,
    "group",
    {
      groups,
    },
  ],
  allArchivedFiles: () => [...documentTemplatesQueryKeys.all, "archived"],
  archivedFiles: (groupId: number, params?: WorkspaceGetFilesParams) => [
    ...documentTemplatesQueryKeys.allArchivedFiles(),
    "archived",
    {
      groupId,
    },
    params,
  ],
};
