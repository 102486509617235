import {
  $ImportEmployees,
  removeImportedDepartment,
} from "@store/company/import-employees-store";
import {
  $DepartmentState,
  $Departments,
  fetchDepartments,
  removeDepartment,
} from "@store/company/departments-store";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { Checkbox, Tooltip } from "@material-ui/core";
import { ErrorIndicator, Loader } from "@ui/indicators";
import { NotePencil, Trash } from "@phosphor-icons/react";
import React, { useEffect } from "react";
import {
  DepartmentShortDataT,
  DepartmentsTablePropsT,
} from "@interfaces/company/departments";

import { BodyNormal } from "@ui/fonts";
import { Button } from "@ui/button";
import { ColorfulButton } from "@components/common/common";
import { Link } from "react-router-dom";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import style from "@scss/pages/company/departments.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import { useAsyncConfirm } from "@hooks/useAsyncConfirm";
import { useChoiceState } from "@hooks/useChoiceState";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";

const DepartmentsTable: React.FC<DepartmentsTablePropsT> = () => {
  const { isLoading, error, isFetched } = useStore($DepartmentState);
  const departments = useStore($Departments);
  const permissions = useStore($UserAddPermissions);
  const { missingDepartments } = useStore($ImportEmployees);

  const {
    selectedItems,
    areAllItemsSelected,
    selectAll,
    selectById,
    clearSelectedItems,
    getIsItemSelected,
  } = useChoiceState(departments, (department) => department.id);

  const { open } = useModal();
  const confirm = useAsyncConfirm();

  const handleLeaveDepartment = (id: number) => {
    removeImportedDepartment(id);
  };

  const handleDeleteDepartment = (department: DepartmentShortDataT) => {
    if (department.employeesCount > 0) {
      return open("NotificationModal", {
        btnText: "Отмена",
        modalData: {
          modalTitle: "Внимание!",
          titleStyle: {
            color: "#e57373",
          },
          removeIcon: true,
          text: "Удаление запрещено. В должности находятся сотрудники!",
        },
      });
    }

    removeDepartment({ id: department.id });
    removeImportedDepartment(department.id);
  };

  const handleRemoveDepartment = (department: DepartmentShortDataT) => {
    open("ConfirmActionModal", {
      btnText: "Удалить",
      modalData: {
        text: `Вы уверены, что хотите удалить подразделение "${department.title}"?`,
      },
      onConfirm: () => {
        handleDeleteDepartment(department);
      },
    });
  };

  const handleDeleteSelectedDepartments = async () => {
    const ok = await confirm({
      modalData: {
        text: "Вы уверены, что хотите удалить выбранные подразделения?",
      },
    });
    if (!ok) return;

    for (const department of selectedItems) {
      handleDeleteDepartment(department);
    }

    clearSelectedItems();
  };

  useEffect(() => {
    if (!isFetched) {
      fetchDepartments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: move this to a separate `DepartmentsTableItem` component
  const tableBodyContent = departments.map((department) => {
    const isSelected = getIsItemSelected(department);
    return (
      <tr key={department.id}>
        <td>
          <span>
            <Checkbox
              color="primary"
              checked={isSelected}
              onChange={(e) => selectById(department, e.target.checked)}
            />
          </span>
          <Link
            to={`/company/departments/${department.id}`}
            className={style.wrapperRow}
          >
            <label
              htmlFor={`key`}
              className={clsx(
                tableStyle.column_fixed_height,
                style.wrapperLabel
              )}
            >
              <label
                htmlFor={`key`}
                className={clsx(tableStyle.checkbox_label, style.wrapperLabel)}
              >
                <span className={style.wrapperLabel}>{department.title}</span>
              </label>
            </label>
          </Link>
        </td>
        <td align="left">{department.employeesCount}</td>
        <td>
          <div className={clsx(style.department_actions_col)}>
            {missingDepartments.includes(department.id) && (
              <div className={style.importEmployeesActions}>
                <BodyNormal color="red">
                  Подразделение отсутствует в списке
                </BodyNormal>
                <Button onClick={() => handleLeaveDepartment(department.id)}>
                  Оставить
                </Button>
                <Button
                  color="white"
                  border
                  borderColor="green"
                  onClick={() => handleDeleteDepartment(department)}
                >
                  Удалить
                </Button>
              </div>
            )}
            {permissions.hasPermission(
              ACTIONS.companyStaffing_positions_allowedToCreate
            ) && (
              <ColorfulButton
                text={"Добавить должность"}
                onClick={() =>
                  open("CreatePositionModal", {
                    btnText: "Добавить",
                    modalData: {
                      modalTitle: "Добавить должность",
                      fieldTitle: "Название",
                      departmentId: department.id,
                    },
                  })
                }
              />
            )}
            {permissions.hasPermission(
              ACTIONS.companyStaffing_departments_allowedToEdit
            ) && (
              <WrapperSVG color={"orange"}>
                <Tooltip title="Изменить" placement="top">
                  <NotePencil
                    size={24}
                    className={style.department_actions_col__icon}
                    onClick={() =>
                      open("CreateDepartmentModal", {
                        btnText: "Сохранить",
                        modalData: {
                          modalTitle: "Изменить подразделение",
                          departmentId: department.id,
                          itemValue: department.title,
                          editMode: true,
                        },
                      })
                    }
                  />
                </Tooltip>
              </WrapperSVG>
            )}
            {permissions.hasPermission(
              ACTIONS.companyStaffing_departments_allowedToDelete
            ) && (
              <Tooltip title="Удалить" placement="top">
                <Trash
                  size={24}
                  className={style.department_actions_col__icon}
                  onClick={() => handleRemoveDepartment(department)}
                />
              </Tooltip>
            )}
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div
      className={clsx(tableStyle.base_table_container, style.departments_table)}
    >
      {isLoading ? (
        <Loader />
      ) : error ? (
        <ErrorIndicator />
      ) : (
        <table className={clsx(tableStyle.base_table)}>
          <thead>
            <tr>
              <td>
                <span>
                  <Checkbox
                    color="primary"
                    checked={areAllItemsSelected}
                    onChange={(e) => selectAll(e.target.checked)}
                  />
                </span>
                <label className={clsx(tableStyle.checkbox_label)}>
                  Название подразделения
                </label>
              </td>
              <td align="left">Кол-во сотрудников</td>
              <td align="right">
                {selectedItems.length > 0 && (
                  <span>
                    {permissions.hasPermission(
                      ACTIONS.companyStaffing_departments_allowedToDelete
                    ) && (
                      <Tooltip title="Удалить" placement="top">
                        <Trash
                          size={24}
                          className={style.department_actions_col__icon}
                          onClick={handleDeleteSelectedDepartments}
                        />
                      </Tooltip>
                    )}
                  </span>
                )}
              </td>
            </tr>
          </thead>
          <tbody>{tableBodyContent}</tbody>
        </table>
      )}
    </div>
  );
};

export default DepartmentsTable;
