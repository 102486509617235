import { $SelectedPrescription } from "@store/prescription-store";
import { useLocation } from "react-router-dom";
import {
  PrescriptionItemsPropsT,
  PrescriptionT,
} from "@interfaces/prescriptions";
import React, { useEffect } from "react";

import clsx from "clsx";
import style from "@scss/pages/prescription/prescriptions.module.scss";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import { PrescriptionItem } from "./prescription-item";

export const PrescriptionItems: React.FC<PrescriptionItemsPropsT> = ({
  rows,
  singleMode = false,
}) => {
  const prescription = useStore($SelectedPrescription) as PrescriptionT;

  const { open } = useModal();
  const { search } = useLocation();

  useEffect(() => {
    if (search.includes("send=true")) {
      open("SendPrescriptionModal", {
        btnText: "Отправить",
        modalData: {
          text: `Вы уверены, что хотите удалить предписание "${prescription.title}"?`,
          id: prescription._id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div className={clsx(style.prescriptions)}>
      {rows.map((prescription) => (
        <PrescriptionItem
          key={prescription._id}
          prescription={prescription}
          singleMode={singleMode}
        />
      ))}
    </div>
  );
};
