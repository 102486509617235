export type JWTUser = {
  userId: number;
  exp: number;
  iat: number;
};

// NOTE: function was copied from stackoverflow
// https://stackoverflow.com/a/38552302/13725946
export function parseJWT(token: string): JWTUser {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
