import "@scss/pages/question-answer.scss";

import { BreadCrumb } from "@components/common";
import { FeedbackChat } from "@feature";
import { Title } from "@components/common/common";
import { useGetIdFeedbackQuery } from "@lib/feedback";
import { useParams } from "react-router-dom";
import { useStore } from "effector-react";
import { $DeviceType } from "@store/device-type-store";

const FeedbackItem: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: feedbackItem } = useGetIdFeedbackQuery(id);
  const { isPhone } = useStore($DeviceType);

  return (
    <>
      <main className="content-container">
        <div className="content-section">
          {!isPhone && (
            <div className="top-content">
              <BreadCrumb
                items={[
                  "Главная",
                  "Техподдержка",
                  feedbackItem?.title
                    ? feedbackItem?.title?.length > 50
                      ? feedbackItem?.title?.slice(0, 50) + "..."
                      : feedbackItem?.title
                    : "",
                ]}
              />
              <Title text="Техподдержка" />
            </div>
          )}
          <FeedbackChat feedbackItem={feedbackItem} />
        </div>
      </main>
    </>
  );
};

export default FeedbackItem;
