import {
  CurrentTariffT,
  FetchingPaymentHistoryPropsT,
  FetchingPaymentHistoryUrlParamsT,
  PaymentHistoryDataT,
  PaymentHistoryT,
  TariffPeriodT,
  TariffT,
} from "@interfaces/payment";
import { createEvent, createStore } from "effector";

import TariffService from "@services/tariff-service";
import axios from "axios";
import { createEffect } from "effector/compat";

const setCurrentTariff = createEvent<CurrentTariffT | false>(
  "Изменение состояния"
);
const resetCurrentTariff = createEvent("Сброс состояния");

export const $CurrentTariff = createStore<CurrentTariffT | null | false>(null)
  .on(setCurrentTariff, (state, newData) => newData)
  .reset(resetCurrentTariff);
export const fetchCurrentTariff = createEffect(async () => {});
const setTariffPeriods = createEvent<TariffPeriodT[]>("Изменение состояния");
const resetTariffPeriods = createEvent("Сброс состояния");

export const $TariffPeriods = createStore<TariffPeriodT[] | null>(null)
  .on(setTariffPeriods, (state, newData) => newData)
  .reset(resetTariffPeriods);

export const fetchTariffPeriods = createEffect(() => {
  return setTimeout(() => {
    return setTariffPeriods([
      {
        period: 3,
        price: 3900,
      },
      {
        period: 5,
        price: 4900,
      },
      {
        period: 6,
        price: 5900,
      },
      {
        period: 9,
        price: 9900,
      },
    ]);
  }, 500);
});

const setPaymentHistory = createEvent<PaymentHistoryT[] | false>(
  "Изменение состояния"
);
const resetPaymentHistory = createEvent("Сброс состояния");

export const $PaymentHistory = createStore<PaymentHistoryT[] | null | false>(
  null
)
  .on(setPaymentHistory, (state, newData) => newData)
  .reset(resetPaymentHistory);

export const fetchPaymentHistory = createEffect(
  async ({ search, option }: FetchingPaymentHistoryPropsT) => {
    try {
      const requestParams: FetchingPaymentHistoryUrlParamsT = {};

      if (search) {
        requestParams.q = search;
      }

      if (option && option !== "default") {
        option.toLowerCase() === "asc" || option.toLowerCase() === "desc"
          ? (requestParams.sort = option.toUpperCase())
          : (requestParams.sort_field = option.toLowerCase());
      }
      const response = await axios.get<PaymentHistoryDataT>(
        `${process.env.API_URL}/payment_history`,
        {
          params: requestParams,
        }
      );

      return setPaymentHistory(response.data.data);
    } catch (err) {
      console.error(err);
      return setPaymentHistory(false);
    }
  }
);

// CHOOSING TARIFF

export const updateTariff = createEvent<TariffT>();
const setChosenTariff = createEvent<TariffT[]>("Изменение состояния");
const resetChosenTariff = createEvent("Сброс состояния");

export const $AllTariffs = createStore<TariffT[] | null>(null)
  .on(setChosenTariff, (state, newData) => newData)
  .on(updateTariff, (allTariffs, updatedTariff) => {
    return allTariffs!.map((tariff) => {
      if (tariff.id === updatedTariff.id) {
        return updatedTariff;
      }
      return tariff;
    });
  })
  .reset(resetChosenTariff);

export const fetchAllTariffs = createEffect(async () => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const response = await TariffService.getAll((err, res) => {
      if (err || !res) return console.error("Error or response is empty");
      setChosenTariff(res.data as unknown as TariffT[]);
      return null;
    });
  } catch (err) {
    console.error(err);
  }
});
