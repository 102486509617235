import {
  $User,
  $UserAddPermissions,
  ACTIONS,
  UserPriorityEnum,
} from "@store/user-store";
import { EnvelopeSimple, NotePencil, Trash } from "@phosphor-icons/react";
import { ErrorIndicator, Loader } from "@ui/indicators";

import { CalendarEventItemsPropsT } from "@interfaces/company/event";
import EmployeeService from "@services/employee-service";
import React from "react";
import { Tooltip } from "@material-ui/core";
import { UserDataT } from "@interfaces/user";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import moment from "moment";
import style from "@scss/pages/company/company-calendar.module.scss";
import { useModal } from "@modals/index";
import { useStore } from "effector-react";

moment.locale("ru");

export const setCalendarArrowsOpacity = (value: string) => {
  const calendarNavigationArrows = document.querySelectorAll(
    ".react-calendar__navigation__arrow"
  ) as NodeListOf<HTMLButtonElement>;
  calendarNavigationArrows?.forEach((arrow) => (arrow.style.opacity = value));
};

const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];
const monthsWithEnding = [
  "Января",
  "Февраля",
  "Марта",
  "Апреля",
  "Мая",
  "Июня",
  "Июля",
  "Августа",
  "Сентября",
  "Октября",
  "Ноября",
  "Декабря",
];

export const getMonth = (month: number, withEnding: boolean = false) => {
  return withEnding ? monthsWithEnding[month] : months[month];
};

export const CalendarEventItems: React.FC<CalendarEventItemsPropsT> = (
  props
) => {
  const {
    events,
    error,
    isPending,
    isCompanyCalendar,
    onDelete,
    onUpdateEvent,
  } = props;
  const user = useStore($User) as UserDataT;

  const permissions = useStore($UserAddPermissions);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { open, close } = useModal();

  const handleReSignature = (e: any, signatureId: number, signerId: number) => {
    EmployeeService.ReSendForSignature(signerId, signatureId, (err, res) => {
      if (err) {
        return console.error(
          "При отправке документа на переподписание произошла ошибка"
        );
      }
      e.target.style.display = "none";
    });
  };

  const onClickHandler = (event: any) => {
    open("CreateEventModal", {
      btnText: "Сохранить",
      modalData: {
        modalTitle: "Редактирование события",
        editMode: true,
        isCompanyCalendar,
        event,
      },
      onConfirm: onUpdateEvent,
    });
  };

  const content = events.map((event) => {
    return (
      <div
        key={event.id}
        className={clsx(style.event_item, "hover-animation-card")}
        {...(event.type === "EVENT" &&
          ((permissions.hasPermission(
            ACTIONS.companyEventsCalendar_allowedToEdit
          ) &&
            event.createdBy === user.id) ||
            permissions.hasRequiredLevel(UserPriorityEnum.Owner)) && {
            onClick: () => onClickHandler(event),
          })}
      >
        <div className={clsx(style.event_item__top_panel)}>
          <p className={clsx(style.event_title)}>
            {event.type === "EVENT" ? (
              <img src="/img/static/event.png" alt="" />
            ) : event.signature?.status === 1 ? (
              <img src="/img/static/danger.png" alt="" />
            ) : (
              <img src="/img/static/round-arrows.png" alt="" />
            )}
            {event.type === "EVENT"
              ? event.title
              : event.signature?.status === 1
                ? "Был подписан документ"
                : "Просрочен срок подписания"}
          </p>
          <div className={clsx(style.event_item__buttons)}>
            {event.signature?.status !== 3 ? (
              <Tooltip title="Отправить на почту" placement="top">
                <button
                  className={clsx(style.event_item__button)}
                  onClick={(e) => {
                    e.stopPropagation();
                    open("EventEmailModal", {
                      modalData: { event },
                    });
                  }}
                >
                  <WrapperSVG color="">
                    <EnvelopeSimple size={24} />
                  </WrapperSVG>
                </button>
              </Tooltip>
            ) : null}
            {event.isFinancial ? (
              <Tooltip title="Финансовая задача" placement="top">
                <button className={clsx(style.event_item__button)}>
                  <img src="/img/static/coins.png" alt="" />
                </button>
              </Tooltip>
            ) : null}
            {event.type === "EVENT" &&
              ((permissions.hasPermission(
                ACTIONS.companyEventsCalendar_allowedToEdit
              ) &&
                event.createdBy === user.id) ||
                permissions.hasRequiredLevel(UserPriorityEnum.Owner)) && (
                <Tooltip title="Изменить" placement="top">
                  <button
                    className={clsx(style.event_item__button)}
                    onClick={(e) => {
                      e.stopPropagation();
                      open("CreateEventModal", {
                        btnText: "Сохранить",
                        modalData: {
                          modalTitle: "Редактирование события",
                          editMode: true,
                          isCompanyCalendar,
                          event,
                        },
                        onConfirm: onUpdateEvent,
                      });
                    }}
                  >
                    <WrapperSVG color="orange">
                      <NotePencil size={24} />
                    </WrapperSVG>
                  </button>
                </Tooltip>
              )}
            {((permissions.hasPermission(
              ACTIONS.companyEventsCalendar_allowedToDelete
            ) &&
              event.createdBy === user.id) ||
              permissions.hasRequiredLevel(UserPriorityEnum.Owner)) && (
              <Tooltip title="Удалить" placement="top">
                <button
                  className={clsx(style.event_item__button)}
                  onClick={(e) => {
                    e.stopPropagation();
                    open("ConfirmActionModal", {
                      modalData: {
                        text: `Вы уверены, что хотите удалить задачу "${
                          event.title || "Подписание документа"
                        }"`,
                      },
                      btnText: "Удалить",
                      onConfirm: () => onDelete(event.id),
                    });
                  }}
                >
                  <WrapperSVG color="">
                    <Trash size={24} />
                  </WrapperSVG>
                </button>
              </Tooltip>
            )}
          </div>
        </div>
        <p className={clsx(style.event_item__date)}>
          {event.dateFinish === 0
            ? "Разово " + moment(event.dateStart).format("lll")
            : moment(event.dateStart).format("lll") +
              " - " +
              moment(event.dateFinish).format("lll")}
        </p>
        <p className={clsx(style.event_item__desc)}>
          {event.desc}{" "}
          {event.type === "SIGNATURE" ? event.signature?.file.title : ""}
          {event.type === "SIGNATURE" ? (
            <>
              <br />
              Подписант: {event.signature?.signer.name}
              <br />
              Должность: {event.signature?.position.title}
              {!permissions.hasRequiredLevel(UserPriorityEnum.Client) &&
              event.signature?.status &&
              event.signature.status !== 1 ? (
                <>
                  <br />
                  <button
                    className={clsx(style.event_item__resignature_btn)}
                    onClick={(e) =>
                      handleReSignature(
                        e,
                        event.signature?.id as number,
                        event.signature?.signer.id as number
                      )
                    }
                  >
                    Переподписать
                  </button>
                </>
              ) : null}
            </>
          ) : (
            ""
          )}{" "}
          <br />
        </p>
      </div>
    );
  });

  return error ? (
    <ErrorIndicator />
  ) : isPending ? (
    <Loader />
  ) : content.length ? (
    <>{content}</>
  ) : (
    <span
      className="subtitle-text"
      style={{ display: "inline-block", margin: "10px 0" }}
    >
      Задач на этот день не найдено
    </span>
  );
};
