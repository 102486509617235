import { BodyLight } from "@ui/fonts";
import { Link } from "react-router-dom";
import { PencilSVG } from "@components/svgs";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { TrainingRequest } from "@interfaces/request-training";
import clsx from "clsx";
import moment from "moment";
import styles from "./table-body-row.module.scss";
import { DeleteCrossSVG } from "@components/svgs/delete-cross";
import { useDeleteTrainingRequestMutation } from "@lib/request-training/useDeleteTrainingRequestMutation";
import { useAsyncConfirm } from "@hooks/useAsyncConfirm";

type TableBodyRowProps = {
  index: number;
  request: TrainingRequest;
};

export const TableBodyRow: React.FC<TableBodyRowProps> = ({
  index,
  request,
}) => {
  const confirm = useAsyncConfirm();

  const deleteTrainingRequestMutation = useDeleteTrainingRequestMutation();

  const handleDeleteTraining = async () => {
    const ok = await confirm({
      btnText: "Да",
      modalData: {
        title: "Подтвердите действие",
        text: "Вы действительно хотите удалить заявку на обучение?",
        cancelBtnText: "Нет",
        removeIcon: true,
      },
    });
    if (!ok) return;

    deleteTrainingRequestMutation.mutate(request.id);
  };

  return (
    <>
      {!!request && (
        <TableRow>
          <TableCell align="left">
            <div className={clsx(styles.table_row_center)}>
              <BodyLight>{++index}</BodyLight>
            </div>
          </TableCell>
          <TableCell align="left">
            <div className={styles.edit_and_save}>
              <div
                className={clsx(
                  styles.table_row_center,
                  styles.table_row_title
                )}
              >
                <BodyLight>{request.title.slice(0, 150)}</BodyLight>
              </div>
              {/* TODO: Add Tooltip*/}
              <Link to={`/company/request-training/${request.id}`}>
                <div
                  className={clsx(
                    styles.table_row_center,
                    styles.table_row_edit
                  )}
                >
                  <PencilSVG width="15" />
                </div>
              </Link>
              <button
                className={clsx(
                  styles.table_row_center,
                  styles.table_row_deleteBtn
                )}
                onClick={handleDeleteTraining}
              >
                <DeleteCrossSVG size={15} />
              </button>
            </div>
          </TableCell>
          <TableCell align="left">
            <div className={styles.table_row_center}>
              <BodyLight>
                {moment(request.createdAt).format("D/M/YYYY")}
              </BodyLight>
            </div>
          </TableCell>
          <TableCell align="left">
            <div className={styles.table_row_center}>
              <BodyLight>{request.comment}</BodyLight>
            </div>
          </TableCell>
          <TableCell align="left">
            <div className={styles.table_row_center}>
              <BodyLight>{request.status}</BodyLight>
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
