import React from "react";

type UploadSVGProps = JSX.IntrinsicElements["svg"] & {};

export const UploadSVG: React.FC<UploadSVGProps> = ({
  width = "14",
  ...props
}) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.66667 1.33333H4.00001C3.64638 1.33333 3.30724 1.4738 3.0572 1.72385C2.80715 1.9739 2.66667 2.31304 2.66667 2.66666V13.3333C2.66667 13.687 2.80715 14.0261 3.0572 14.2761C3.30724 14.5262 3.64638 14.6667 4.00001 14.6667H12C12.3536 14.6667 12.6928 14.5262 12.9428 14.2761C13.1929 14.0261 13.3333 13.687 13.3333 13.3333V6L8.66667 1.33333Z"
        stroke="#00B856"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.66667 1.33333V5.99999H13.3333"
        stroke="#00B856"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 10L8 8L6 10"
        stroke="#00B856"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 8L8 12.6667"
        stroke="#00B856"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
