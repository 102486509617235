import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { ArrowDropDownGraySVG } from "@components/svgs";
import { TableRequestBodyRow } from "../table-request-body-row";
import { TrainingRequest } from "@interfaces/request-training";
import { UseSortingResult } from "@hooks/useSorting";
import clsx from "clsx";
import styles from "./table-request-training.module.scss";
import { v4 as uuidv4 } from "uuid";

type TableTrainingProps = {
  data: TrainingRequest[];
  ignoredFields?: (keyof TrainingRequest)[];
  sorting?: UseSortingResult;
};

export const TableRequestTraining: React.FC<TableTrainingProps> = ({
  data,
  ignoredFields,
  sorting,
}) => {
  const fields = head.filter(
    (item) => !item.key || !ignoredFields?.includes(item.key)
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.table}>
        <Table className={styles.table_wrapper} aria-label="caption table">
          <TableHead>
            <TableRow>
              {fields.map((item) => (
                <TableCell
                  key={uuidv4()}
                  align="right"
                  className={styles.table_head}
                >
                  <div
                    className={styles.table_head_wrapper}
                    tabIndex={0}
                    onClick={() =>
                      item.sortKey && sorting?.onItemSortingChange(item.sortKey)
                    }
                  >
                    <div className={styles.table_head_name}>{item.title}</div>
                    {item.sortKey && (
                      <div className={styles.table_head_sort}>
                        <div
                          className={clsx(styles.table_head_sortIcon, {
                            [styles.table_head_sortIcon_rotated]:
                              item.sortKey &&
                              sorting?.shouldIconRotate(item.sortKey),
                          })}
                        >
                          <ArrowDropDownGraySVG />
                        </div>
                      </div>
                    )}
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <TableRequestBodyRow
                key={uuidv4()}
                index={index}
                request={item}
                ignoredFields={ignoredFields}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

type HeadT = {
  id: number;
  title: string;
  key?: keyof TrainingRequest;
  sortKey?: string;
}[];

const head: HeadT = [
  {
    id: 1,
    title: "№",
  },
  {
    id: 2,
    title: "Компания",
    key: "customer",
    sortKey: "customerOrder",
  },
  {
    id: 3,
    title: "Название заявки",
    key: "title",
    sortKey: "titleOrder",
  },
  {
    id: 4,
    title: "",
  },
  {
    id: 5,
    title: "Дата отправки заявки",
    key: "createdAt",
    sortKey: "dateCreationOrder",
  },
  {
    id: 6,
    title: "Комментарий",
    key: "comment",
    sortKey: "commentOrder",
  },
  {
    id: 7,
    title: "Статус заявки",
    key: "status",
    sortKey: "statusOrder",
  },
];
