import { SortingType } from "@interfaces/sorting";

export type FetchProcedureControlPropsT = (params: {
  isIncludeChanges: boolean;
}) => void;

export type FetchGetDirectoryInstructionsPropsT = (params: {
  isIncludeChanges?: boolean;
  categories?: number[] | undefined;
  titleOrder?: "ASC" | "DESC" | undefined;
  termPrimaryConductingOrder?: "ASC" | "DESC" | undefined;
  periodOrder?: "ASC" | "DESC" | undefined;
}) => void;

export type TablesDirectionDataT = {
  id: number;
  title: string;
  head: {
    id: string;
    title: string;
  }[];
  config: {
    key: keyof Procedure;
    type: "string" | "date" | "input_date" | "input_string" | string[];
    label: string;
    place: number;
  }[];
  body: Procedure[];
};

export enum InstructionProgram {
  First = "Первичный",
  Introduction = "Вводный",
}

export interface Procedure {
  id: number;
  lastTrainingDate?: Date | null;
  companyId: number;
  companyName: string;
  departmentId: number;
  departmentTitle: string;
  positionId: number;
  positionTitle: string;
  employeeId: number;
  employeeName: string;
  employeeEmail: string;
  programId: number;
  programTitle: string;
  protocolNumber: string | null;
  trainingDocumentNumber: string | null;
  trainingDate?: Date | null;
  daysBeforeTraining?: number | null;
  daysBeforeBriefing?: number | null;
  cause: string;
  note?: string | null;
  status: ControlStatus;
  lastBriefingDate?: Date | null;
  instructor: string | null;
  briefingDate?: Date | null;
  dependent?: ProcedureDependentT;
}
export type ProcedureDependentT = {
  id: number;
  lastBriefingDate?: Date | null;
  companyId: number;
  companyName: string;
  departmentId: number;
  departmentTitle: string;
  positionId: number;
  positionTitle: string;
  employeeId: number;
  employeeName: string;
  employeeEmail: string;
  programId: number;
  programTitle: string;
  instructor: string;
  briefingDate?: Date | null;
  daysBeforeBriefing: number;
  cause: string;
  note: null;
  status: ControlStatus;
  isRequired: true;
  protocolNumber: string | null;
  trainingDocumentNumber: string | null;
}[];

export type ProcedureTrainingProgramsT = {
  title: string;
  categoryId: number;
  programs: ProcedureProgram[];
  instructions?: ProcedureProgram[];
};

export type ProcedureProgram = {
  categoryId: number;
  period: number | null;
  termPrimaryConducting: number | null;
  id: number;
  title: string;
};

export type FetchProcedureTrainingProgramsT = (params: {
  isIncludeChanges: boolean;
}) => void;

export type ProcedureInstructionsProgramT = {
  title: string;
  categoryId: number;
  programs: ProcedureInstruction[];
};

export type ProcedureInstruction = {
  categoryId: number;
  id: number;
  termPrimaryConducting: number | null;
  period: number | null;
  title: string;
};

export enum ControlStatus {
  RequiredToFill = "Необходимо заполнить",
  NoActionRequired = "Не требует действий",
  ProcessingRequired = "Требуется обработка",
  RequiredToFillAndProcessing = "Необходимо заполнить и обработать",
}

export type ProcedureTrainingQueryParams = {
  isArchived?: boolean;
  isConfirmed?: boolean;
  isShowOneTime?: boolean;
  isRequired?: boolean;
  searchString?: string;
  statuses?: ControlStatus[];
  controls?: number[];
  companies?: number[];
  categories?: number[];
  employees?: (number | string)[]; // string for general
  departments?: (number | string)[]; // string for general
  positions?: (number | string)[]; // string for general
  programs?: number[];
  causes?: string[];
  date?: string;
  deadline?: number[];
  lastTrainingDateOrder?: SortingType;
  companyOrder?: SortingType;
  departmentOrder?: SortingType;
  positionOrder?: SortingType;
  employeeOrder?: SortingType;
  programOrder?: SortingType;
  protocolNumberOrder?: SortingType;
  trainingDocumentNumberOrder?: SortingType;
  trainingDateOrder?: SortingType;
  daysBeforeTrainingOrder?: SortingType;
  causeOrder?: SortingType;
  noteOrder?: SortingType;
  offset?: number;
  limit?: number;
};

export const procedureTrainingSortKeys = [
  "lastTrainingDateOrder",
  "companyOrder",
  "departmentOrder",
  "positionOrder",
  "employeeOrder",
  "programOrder",
  "protocolNumberOrder",
  "trainingDocumentNumberOrder",
  "trainingDateOrder",
  "daysBeforeTrainingOrder",
  "causeOrder",
  "noteOrder",
];

export type ProcedureInstructionQueryParams = {
  isArchived?: boolean;
  isConfirmed?: boolean;
  isRequired?: boolean;
  isShowOneTime?: boolean;
  searchString?: string;
  statuses?: ControlStatus[];
  controls?: number[];
  companies?: number[];
  categories?: number[];
  employees?: (number | string)[];
  departments?: (number | string)[];
  positions?: (number | string)[];
  programs?: number[];
  causes?: string[];
  date?: string;
  deadline?: number[];
  lastBriefingDateOrder?: SortingType;
  companyOrder?: SortingType;
  departmentOrder?: SortingType;
  positionOrder?: SortingType;
  employeeOrder?: SortingType;
  programOrder?: SortingType;
  instructorOrder?: SortingType;
  briefingDateOrder?: SortingType;
  daysBeforeBriefingOrder?: SortingType;
  causeOrder?: SortingType;
  noteOrder?: SortingType;
  offset?: number;
  limit?: number;
};

export const procedureInstructionsSortKeys = [
  "lastBriefingDateOrder",
  "companyOrder",
  "departmentOrder",
  "positionOrder",
  "employeeOrder",
  "programOrder",
  "instructorOrder",
  "briefingDateOrder",
  "daysBeforeBriefingOrder",
  "causeOrder",
  "noteOrder",
];
