import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MedicalExamControlsTable } from "feature/procedure-control/medical-exam-controls/medical-exam-controls-table";
import {
  useMedicalExamControlsQuery,
  useMedicalExaminationQuery,
  useUpdateMedicalExamControlsMutation,
} from "@lib/medical-examination";
import { useSelectedCompanyQuery } from "@lib/company";
import { useSorting } from "@hooks/useSorting";
import { Pagination } from "@components/pagination";
import { Text } from "@mantine/core";
import {
  MedicalExamControlsBody,
  MedicalExamControlsT,
} from "@interfaces/medical-examination";
import { useQueryClient } from "react-query";
import { medicalExaminationQueryKeys } from "@lib/medical-examination/queryKeys";
import { Button } from "@ui/button";
import { BodyBold } from "@ui/fonts";
import styles from "@scss/pages/company/employees.module.scss";
import toast from "react-hot-toast";

const LIMIT = 20;
const getOffset = (page: number) => LIMIT * (page - 1);

const sortingKeys = [
  "lastDateOrder",
  "departmentTitleOrder",
  "positionTitleOrder",
  "employeeNameOrder",
  "factorTitleOrder",
  "nextDateOrder",
  "daysBeforeNextDateOrder",
];

export const EmployeesMedicalExams: FC = () => {
  const { id } = useParams<{ id: string }>();

  const queryClient = useQueryClient();
  const { company } = useSelectedCompanyQuery();

  const [page, setPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState<number>();

  const { data: categories } = useMedicalExaminationQuery();
  const categoriesList = categories?.filter(
    (category) => typeof category.id !== "undefined"
  );

  const sorting = useSorting(sortingKeys, "ASC");

  const params = {
    offset: getOffset(page),
    limit: LIMIT,
    categories: selectedCategory ? [selectedCategory] : [],
    companyId: company?.id,
    employees: id ? [+id] : undefined,
  };
  const { data, isLoading } = useMedicalExamControlsQuery(params);

  const updateControlsMutation = useUpdateMedicalExamControlsMutation();

  const hasNextPage = (data?.[0]?.body?.length ?? 0) >= LIMIT;

  useEffect(() => {
    if (!categoriesList || typeof selectedCategory !== "undefined") return;

    setSelectedCategory(categoriesList[0].id);
  }, [categoriesList, selectedCategory]);

  const handleUpdateLastDate = (
    row: MedicalExamControlsBody,
    lastDate: string | null
  ) => {
    if (!company) return;

    updateControlsMutation.mutate(
      {
        companyId: company.id,
        id: row.id,
        lastDate,
      },
      {
        onSuccess: (result) => {
          toast.success("Данные обновлены");
          queryClient.setQueryData<MedicalExamControlsT[] | null>(
            medicalExaminationQueryKeys.controls(params),
            (state) => {
              if (!state) return null;

              return state.map((table) => {
                table.body = table.body.map((_row) =>
                  _row.id === row.id ? { ..._row, ...result } : _row
                );
                return table;
              });
            }
          );
        },
      }
    );
  };

  if (!company) {
    return null;
  }

  return (
    <div className={styles.employeesMedicalExams}>
      <div className={styles.employeesMedicalExams__categories}>
        {categoriesList?.map((category) => (
          <Button
            key={category.id}
            color={selectedCategory !== category.id ? "white-green" : "green"}
            borderColor="green"
            onClick={() => setSelectedCategory(category.id)}
          >
            <BodyBold>{category.title}</BodyBold>
          </Button>
        ))}
      </div>
      <Text size={30} color="green" weight="bold" mt={48} mb={24}>
        {data?.[0].title}
      </Text>
      <MedicalExamControlsTable
        data={data?.[0].body ?? []}
        isLoading={isLoading}
        offset={params.offset}
        sorting={sorting}
        onUpdateLastDate={handleUpdateLastDate}
        className={styles.employeesMedicalExams__table}
      />
      <div className={styles.pagination}>
        <Pagination
          value={page}
          onChange={setPage}
          total={hasNextPage ? page + 1 : page}
        />
      </div>
    </div>
  );
};
