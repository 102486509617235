import { Link, useLocation } from "react-router-dom";

import { Button } from "@ui/button";
import React from "react";
import styles from "./study-materials.module.scss";

export type StudyCenterMaterialsProps = {
  children?: React.ReactNode;
  viewLink?: boolean;
};

export const StudyCenterMaterials: React.FC<StudyCenterMaterialsProps> = ({
  children,
  viewLink = true,
}) => {
  const location = useLocation();

  const isEducationalPromotionsPage = location.pathname.includes(
    "/educational-promotions"
  );
  const isEventsPage = location.pathname.includes("/educational-events");

  return (
    <div className={styles.Materials}>
      {viewLink && (
        <div className={styles.Materials__tabs}>
          <Link to="/study-center/materials/educational-promotions">
            <Button
              color={isEducationalPromotionsPage ? "green" : "white"}
              border
              borderColor="green"
            >
              Акции и предложения
            </Button>
          </Link>
          <Link to="/study-center/materials/educational-events">
            <Button
              color={isEventsPage ? "green" : "white"}
              border
              borderColor="green"
            >
              События и вебинары
            </Button>
          </Link>
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};
