import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  MenuItem,
  Select,
  TextField,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Procedure, ProcedureDependentT } from "@interfaces/company/procedure";
import React, { useEffect, useState } from "react";
import moment, { Moment } from "moment";

import { BodyNormal } from "@ui/fonts";
import { Button } from "@ui/button";
import { Modal } from "@ui/modal";
import MomentUtils from "@date-io/moment";
import { Text } from "@mantine/core";
import styles from "./generate-cell.module.scss";
import toast from "react-hot-toast";

interface GenerateCellProps {
  type?: "string" | string[] | "date" | "input_date" | "input_string";
  value?: Date | string | number;
  name: string;
  label: string;
  onChange?: (id: number, value: { [key: string]: string }) => void;
  onBlur?: () => void;
  defaultValue: Date | string | number | undefined | null | ProcedureDependentT;
  row: Procedure;
}

const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputBase: {
      minWidth: 150,
      paddingLeft: 5,

      "&:focus": {
        backgroundColor: "transparent",
      },
    },
  })
);

export const GenerateCell: React.FC<GenerateCellProps> = ({
  type,
  value,
  defaultValue,
  name,
  label,
  onChange,
  onBlur,
  row,
}) => {
  const [dateValue, setDateValue] = useState<GenerateCellProps["defaultValue"]>(
    (defaultValue ?? "") + ""
  );
  const [open, setOpen] = useState<boolean>(false);
  const classes = useFormStyles();
  const [errorText, setErrorText] = useState<string>();
  const [isDateError, setIsDateError] = useState(false);

  useEffect(() => {
    if (name === "lastTrainingDate" && !defaultValue) {
      setDateValue("");
    }
    if (name === "lastTrainingDate" && defaultValue) {
      setDateValue(defaultValue);
    }
  }, [name, defaultValue]);

  useEffect(() => {
    if (type === "input_date" && defaultValue) {
      setDateValue(defaultValue);
    }
  }, [type, defaultValue, name]);

  const onClose = () => {
    setOpen(false);
    setErrorText("");
  };

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = ({
    target: { value },
  }) => {
    if (!onChange || !row.id) return undefined;
    onChange(row.id, { [name]: value });
  };

  const handleDateChange = (date: Moment, value: string | null | undefined) => {
    if (!onChange || !row.id) return;

    setIsDateError(false);

    const valDate =
      date && date.isValid() ? date.format("YYYY-MM-DD") : value ?? "";

    // show input as error if entered date is in the future
    if (moment().isBefore(valDate, "days")) {
      toast.error("Дата не может быть позже текущей даты");

      setIsDateError(true);
      return;
    }

    setDateValue(valDate);
    onChange(row.id, { [name]: valDate });
  };

  const handleSelectChange: (
    event: React.ChangeEvent<{
      value: unknown;
    }>,
    child: React.ReactNode
  ) => void = ({ target: { value } }) => {
    if (
      value === "Повторное" &&
      defaultValue === "Первичное" &&
      !row?.lastTrainingDate
    ) {
      setErrorText("Укажите дату обучения");
      setOpen(true);
      return;
    }
    if (defaultValue === "Повторное" && value === "Первичное") {
      if (!onChange || !row.id) return undefined;

      onChange(row.id, { [name]: value as string });
      setErrorText("Дата прошлого выполнения процедуры будет удалена.");
      setOpen(true);
    }
    if (!onChange || !row.id) return undefined;
    onChange(row.id, { [name]: value as string });
  };

  return (
    <>
      {type === "input_string" && (
        <TextField
          value={value}
          defaultValue={defaultValue}
          onChange={handleChange}
          onBlur={onBlur}
        />
      )}
      {type === "input_date" && (
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale={"ru"}
        >
          <KeyboardDatePicker
            cancelLabel={"Отменить"}
            okLabel={"ОК"}
            InputProps={{ classes: { root: classes.inputBase } }}
            disableToolbar
            fullWidth
            variant="dialog"
            format="DD.MM.yyyy"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            maxDate={moment()}
            onChange={handleDateChange}
            value={dateValue || null}
            defaultValue={defaultValue}
            error={isDateError}
            helperText=""
          />
        </MuiPickersUtilsProvider>
      )}
      {Array.isArray(type) && (
        <Select
          value={defaultValue}
          onBlur={onBlur}
          onChange={handleSelectChange}
          inputProps={{ className: classes.inputBase }}
        >
          {type.map((item) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      )}
      {!Array.isArray(type) &&
        type !== "input_date" &&
        type !== "input_string" && (
          <Text color="gray">Что-то пошло не так </Text>
        )}
      {open && (
        <Modal open={open} onClose={onClose} className={styles.modal}>
          <div className={styles.modal_head}>
            <BodyNormal>{errorText}</BodyNormal>
          </div>
          <div className={styles.modal_buttons}>
            {/* <Button
              color={"white-green"}
              borderColor={"green"}
              onClick={onClose}
            >
              <BodyNormal>Отменить</BodyNormal>
            </Button> */}
            <Button onClick={onClose}>Принять</Button>
          </div>
        </Modal>
      )}
    </>
  );
};
