import { $UserAddPermissions, UserRoleEnum } from "@store/user-store";
import { FC, ReactNode, useState } from "react";
import {
  InternalStudyCenterCategory,
  InternalStudyCenterSection,
  InternalStudyCenterSectionContentType,
} from "@interfaces/internal-study-center";
import { Subheader, Subtitle } from "@ui/fonts";

import { AddPublicCategoryModal } from "./add-public-category-modal";
import { Button } from "@ui/button";
import { Link } from "react-router-dom";
import { PublicMaterialsBox } from "./public-materials-box";
import clsx from "clsx";
import styles from "./materials-public-tab.module.scss";
import { useInternalCategoryMaterialsQuery } from "@lib/internal-study-center/useInternalCategoryMaterialsQuery";
import { useStore } from "effector-react";

export type PublicMaterialSectionProps = {
  section: InternalStudyCenterSection;
  rightSide?: ReactNode;
  withCategories?: boolean;
  className?: string;
};

export const PublicMaterialSection: FC<PublicMaterialSectionProps> = ({
  section,
  rightSide,
  withCategories = true,
  className,
}) => {
  const permissions = useStore($UserAddPermissions);

  const [open, setOpen] = useState(false);

  return (
    <div className={clsx(styles.section)}>
      <AddPublicCategoryModal
        open={open}
        sectionId={section.id}
        onClose={() => setOpen(false)}
      />
      <div
        className={clsx(styles.section__content, className, {
          [styles.section__content_clipped]: withCategories,
        })}
      >
        <div className={styles.section__header}>
          <Subtitle weight="bold">{section.name}</Subtitle>
          {permissions.roleIsIn([
            UserRoleEnum.SuperAdmin,
            UserRoleEnum.SuperAdminMinus,
          ]) &&
            withCategories && (
              <Button border borderColor="green" onClick={() => setOpen(true)}>
                + Добавить категорию
              </Button>
            )}
        </div>
        <div
          className={clsx(styles.wrapper, {
            [styles.wrapper_withMargin]: withCategories,
          })}
        >
          <div className={styles.listCategory}>
            {section.categories.map((category) => (
              <SectionCategory
                key={category.id}
                category={category}
                contentType={section.contentType}
                sectionId={section.id}
                withName={withCategories}
              />
            ))}
            {/* <div className={styles.recomendations}><Subheader></Subheader></div> */}
          </div>
        </div>
      </div>
      {rightSide && <div className={styles.section__divider} />}
      {rightSide}
    </div>
  );
};

type SectionCategoryProps = {
  sectionId: number;
  contentType: InternalStudyCenterSectionContentType;
  category: InternalStudyCenterCategory;
  withName: boolean;
};

const SectionCategory: FC<SectionCategoryProps> = ({
  sectionId,
  contentType,
  category,
  withName,
}) => {
  const permissions = useStore($UserAddPermissions);

  const { materials } = useInternalCategoryMaterialsQuery({
    sectionId,
    categoryId: category.id,
  });

  const hasPermissionToCreate = permissions.roleIsIn([
    UserRoleEnum.SuperAdmin,
    UserRoleEnum.SuperAdminMinus,
  ]);

  const canCreateArticle =
    hasPermissionToCreate &&
    (contentType === InternalStudyCenterSectionContentType.Article ||
      contentType === InternalStudyCenterSectionContentType.LinkAndArticle);

  const canCreatePostlink =
    hasPermissionToCreate &&
    (contentType === InternalStudyCenterSectionContentType.Link ||
      contentType === InternalStudyCenterSectionContentType.LinkAndArticle);

  return (
    <section className={styles.listCategoryItem}>
      <div className={styles.headerCategory}>
        {withName && <Subheader weight="bold">{category.name}</Subheader>}
        <div className={styles.headerCategory__actions}>
          {canCreateArticle && (
            <Link
              to={`/study-center/public-materials/${sectionId}/${category.id}/article/create`}
            >
              <Button border borderColor="green">
                + Добавить статью
              </Button>
            </Link>
          )}
          {canCreatePostlink && (
            <Link
              to={`/study-center/public-materials/${sectionId}/${category.id}/post-link/create`}
            >
              <Button border borderColor="green">
                + Добавить пост-ссылку
              </Button>
            </Link>
          )}
        </div>
      </div>
      <div className={styles.publicMaterial}>
        {materials?.map((material) => (
          <PublicMaterialsBox
            key={material.id}
            material={material}
            categoryId={category.id}
            sectionId={sectionId}
          />
        ))}
      </div>
    </section>
  );
};
