import { ArticleType } from "@interfaces/legislation-news";
import { NewsParams } from "@interfaces/news";

export const newsQueryKeys = {
  all: () => ["news"],
  newsByType: (type: ArticleType) => [...newsQueryKeys.all(), type],
  paginatedNews: ({ type, ...params }: NewsParams) => [
    ...newsQueryKeys.newsByType(type),
    params,
  ],
  itemById: (type: ArticleType, id: string | undefined) => [
    ...newsQueryKeys.newsByType(type),
    "item",
    id,
  ],
  otherNews: (type: ArticleType, excludeId: string) => [
    "other-news",
    type,
    excludeId,
  ],
};
