import { FC, useEffect, useState } from "react";
import { Text } from "@mantine/core";
import { CommonSearch } from "@ui/common-search";
import { useSearch } from "@hooks/useSearch";
import {
  GetGeneralMedicalExamControlsParams,
  MedicalExamControlsBody,
  MedicalExamControlsT,
} from "@interfaces/medical-examination";
import styles from "./general-medical-exam-controls.module.scss";
import {
  useDownloadGeneralMedicalExamControlsMutation,
  useGeneralMedicalExamControlsQuery,
  useMedicalExaminationFactorsQuery,
  useMedicalExaminationQuery,
  useUpdateMedicalExamControlsMutation,
} from "@lib/medical-examination";
import { Button } from "@ui/button";
import { BodyBold } from "@ui/fonts";
import { MedicalExamControlsTable } from "../medical-exam-controls/medical-exam-controls-table";
import { useSorting } from "@hooks/useSorting";
import { Pagination } from "@components/pagination";
import {
  MedicalExamControlsFilters,
  SelectList,
} from "../medical-exam-controls/medical-exam-controls-filters";
import { useDepartmentsGetAllQuery } from "@lib/departments-get-all/departments-get-all";
import { convertToSelectList } from "@utils/convertToSelectList";
import { usePositionsGetAllQuery } from "@lib/positions-get-all/positions-get-all";
import { useEmployeesGetAllQuery } from "@lib/employees-get-all/employees-get-all";
import { useCompaniesQuery } from "@lib/company";
import { useQueryClient } from "react-query";
import { medicalExaminationQueryKeys } from "@lib/medical-examination/queryKeys";
import { FileLoadSVG } from "@components/svgs";
import { downloadBlob } from "@utils/downloadBlob";
import toast from "react-hot-toast";
import { getProcedureControlParamsArray } from "@utils/getProcedureControlParamsArray";

const LIMIT = 20;
const getOffset = (page: number) => LIMIT * (page - 1);

const sortingKeys = [
  "lastDateOrder",
  "companyNameOrder",
  "departmentTitleOrder",
  "positionTitleOrder",
  "employeeNameOrder",
  "factorTitleOrder",
  "nextDateOrder",
  "daysBeforeNextDateOrder",
];

export const GeneralMedicalExamControls: FC = () => {
  const queryClient = useQueryClient();
  const updateControlsMutation = useUpdateMedicalExamControlsMutation();
  const downloadControlsMutation =
    useDownloadGeneralMedicalExamControlsMutation();

  const [page, setPage] = useState(1);
  const search = useSearch();
  const [selectedCategory, setSelectedCategory] = useState<number>();
  const [selectedDepartments, setSelectedDepartments] = useState<SelectList[]>(
    []
  );
  const [selectedPositions, setSelectedPositions] = useState<SelectList[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<SelectList[]>([]);
  const [selectedFactors, setSelectedFactors] = useState<SelectList[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<SelectList[]>([]);
  const [startDate, setStartDate] = useState<number[]>();
  const [deadline, setDeadline] = useState<number[]>();

  const { data: categories } = useMedicalExaminationQuery();
  const categoriesList = categories?.filter(
    (category) => typeof category.id !== "undefined"
  );

  const { data: departments } = useDepartmentsGetAllQuery(
    { companies: selectedCompanies.map((c) => c.id) },
    true
  );
  const departmentsList = convertToSelectList(
    departments ?? [],
    (item) => item.id,
    (item) => item.title ?? String(item.id)
  );

  const { data: positions } = usePositionsGetAllQuery(
    {
      companies: selectedCompanies.map((c) => c.id),
      departments: selectedDepartments.map((d) => d.title),
    },
    true
  );
  const positionsList = convertToSelectList(
    positions ?? [],
    (item) => item.id,
    (item) => item.title ?? String(item.id),
    (position) => position.parentId === null
  );

  const { data: employees } = useEmployeesGetAllQuery(
    {
      companies: selectedCompanies.map((c) => c.id),
      departments: selectedDepartments.map((d) => d.title),
      positions: selectedPositions.map((p) => p.title),
    },
    true
  );
  const employeesList = convertToSelectList(
    employees ?? [],
    (item) => item.id,
    (item) => item.name ?? String(item.id)
  );

  const { data: factorsData } = useMedicalExaminationFactorsQuery();
  const factors = factorsData?.map((item) => item.factors).flat();
  const factorsList = convertToSelectList(
    factors ?? [],
    (item) => item.id,
    (item) => item.title
  );

  const { companies } = useCompaniesQuery();
  const companiesList = convertToSelectList(
    companies ?? [],
    (item) => item.id,
    (item) => item.name
  );

  const sorting = useSorting(sortingKeys, "ASC");

  const params: GetGeneralMedicalExamControlsParams = {
    offset: getOffset(page),
    limit: LIMIT,
    categories: selectedCategory ? [selectedCategory] : [],
    departments: getProcedureControlParamsArray(
      selectedDepartments,
      departments?.length ?? 0,
      true
    ),
    positions: getProcedureControlParamsArray(
      selectedPositions,
      positions?.length ?? 0,
      true
    ),
    employees: getProcedureControlParamsArray(
      selectedEmployees,
      employees?.length ?? 0,
      true
    ),
    factors: getProcedureControlParamsArray(
      selectedFactors,
      factors?.length ?? 0,
      false
    ),
    companies: getProcedureControlParamsArray(
      selectedCompanies,
      companies?.length ?? 0,
      false
    ),
    date: startDate,
    deadline,
    searchString: search.debounced,
    ...sorting.getActiveSorting(),
  };

  const { data, isLoading } = useGeneralMedicalExamControlsQuery(params);

  const hasNextPage = (data?.[0]?.body?.length ?? 0) >= LIMIT;

  const handleUpdateLastDate = (
    row: MedicalExamControlsBody,
    lastDate: string | null
  ) => {
    updateControlsMutation.mutate(
      {
        companyId: row.companyId,
        id: row.id,
        lastDate,
      },
      {
        onSuccess: (result) => {
          toast.success("Данные обновлены");
          queryClient.setQueryData<MedicalExamControlsT[] | null>(
            medicalExaminationQueryKeys.generalControls(params),
            (state) => {
              if (!state) return null;

              return state.map((table) => {
                table.body = table.body.map((_row) =>
                  _row.id === row.id ? { ..._row, ...result } : _row
                );
                return table;
              });
            }
          );
        },
      }
    );
  };

  const getExcelFileName = () => {
    if (typeof selectedCategory === "undefined") return;

    let filename = "";
    const category = categoriesList?.find(
      (category) => category.id === selectedCategory
    );
    if (category) {
      filename = `Контроль медицинских осмотров [${category.title}].xlsx`;
    } else {
      filename = `Контроль медицинских осмотров.xlsx`;
    }

    return filename;
  };

  const handleDownloadTable = () => {
    downloadControlsMutation.mutate(params, {
      onSuccess: (blob) => {
        const filename = getExcelFileName();
        if (!filename) return;
        downloadBlob(blob, filename);
      },
    });
  };

  useEffect(() => {
    if (!categoriesList || typeof selectedCategory !== "undefined") return;
    if (categoriesList.length === 0) return;

    setSelectedCategory(categoriesList[0].id);
  }, [categoriesList, selectedCategory]);

  return (
    <div className={styles.controls}>
      <CommonSearch
        className="ml-auto"
        onChange={() => {}}
        setSearch={search.setValue}
      />
      <div className={styles.controls__content}>
        <div className={styles.controls__downloadActions}>
          <button
            className={styles.controls__downloadBtn}
            onClick={handleDownloadTable}
          >
            <FileLoadSVG />
            Скачать текущую таблицу
          </button>
        </div>
        <MedicalExamControlsFilters
          departments={departmentsList}
          positions={positionsList}
          employees={employeesList}
          factors={factorsList}
          companies={companiesList}
          selectedDepartments={selectedDepartments}
          selectedPositions={selectedPositions}
          selectedEmployees={selectedEmployees}
          selectedFactors={selectedFactors}
          selectedCompanies={selectedCompanies}
          onDepartmentsChange={(values) => {
            setSelectedDepartments(values);
            setSelectedPositions([]);
            setSelectedEmployees([]);
          }}
          onPositionChange={(values) => {
            setSelectedPositions(values);
            setSelectedEmployees([]);
          }}
          onEmployeeChange={setSelectedEmployees}
          onFactorChange={setSelectedFactors}
          onStartDateChange={setStartDate}
          onDeadlineChange={setDeadline}
          onCompaniesChange={(values) => {
            setSelectedCompanies(values);
            setSelectedDepartments([]);
            setSelectedPositions([]);
            setSelectedEmployees([]);
          }}
        />
        <div className={styles.controls__categories}>
          {categoriesList?.map((category) => (
            <Button
              key={category.id}
              color={selectedCategory !== category.id ? "white-green" : "green"}
              borderColor="green"
              onClick={() => setSelectedCategory(category.id)}
            >
              <BodyBold>{category.title}</BodyBold>
            </Button>
          ))}
        </div>
        <div>
          <Text size={30} color="green" weight="bold" mt={48} mb={24}>
            {data?.[0].title}
          </Text>
          <MedicalExamControlsTable
            data={data?.[0].body ?? []}
            isLoading={isLoading}
            sorting={sorting}
            offset={params.offset}
            withCompany
            onUpdateLastDate={handleUpdateLastDate}
          />
          <div className={styles.controls__pagination}>
            <Pagination
              value={page}
              onChange={setPage}
              total={hasNextPage ? page + 1 : page}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
