import { GetAllProjectNewsQuery } from "@interfaces/project-news";
import ProjectNewsService from "@services/project-news-service";
import { getNextPage, getPreviousPage } from "@utils/pagination";
import { useInfiniteQuery } from "react-query";
import { projectNewsQueryKeys } from "./queryKeys";

export const useProjectNewsQuery = (limit: number) => {
  return useInfiniteQuery(
    projectNewsQueryKeys.allWithParams(limit),
    async ({ pageParam = 1 }) => {
      return new Promise<GetAllProjectNewsQuery>((resolve, reject) => {
        ProjectNewsService.getAll(
          {
            limit,
            offset: limit * (pageParam - 1),
          },
          (err, res) => {
            if (err || !res) {
              return reject(err);
            }

            resolve({
              result: res.data,
              page: pageParam,
            });
          }
        );
      });
    },
    {
      getNextPageParam: ({ page, result }) =>
        getNextPage(page, limit, result.count),
      getPreviousPageParam: ({ page }) => getPreviousPage(page),
    }
  );
};
