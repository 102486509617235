import { Checkbox, FormControl, InputLabel, Select } from "@material-ui/core";
import { CheckboxCheckedSVG, CheckboxSVG } from "@components/svgs";

import clsx from "clsx";
import style from "./drop-down-deadline-select.module.scss";
import { useState } from "react";

type deadlinesType = {
  id: string;
  title: string;
  color: string;
};

type PropsDropDownDeadlineSelect = {
  type: string;
  title: string;
  handlerChangeFilter: (key: string, value: string) => void;
};

export const DropDownDeadlineSelect: React.FC<PropsDropDownDeadlineSelect> = ({
  handlerChangeFilter,
  title,
  type,
}) => {
  const [ids, setIds] = useState<string[]>([]);

  const changeIncludingOfDirection = (id: string) => {
    //add
    if (!ids.find((item, _) => item === id)) {
      return setIds([...ids, id]);
    }
    //delete
    if (!!ids.find((item, _) => item === id)) {
      return setIds(ids.filter((item, _) => item !== id));
    }
    //if []
    if (ids.length === 0) {
      return setIds([id]);
    }
  };

  const handleSelectClose = () => {
    if (!ids.length) {
      return handlerChangeFilter(type, "");
    }
    handlerChangeFilter(type, ids.join(";"));
  };

  return (
    <div className={style.container}>
      <FormControl variant="filled">
        <InputLabel className={style.label}>{title}</InputLabel>
        <Select
          onClose={handleSelectClose}
          multiple
          value={[""]}
          renderValue={() => {
            const selectedTitles = deadlines
              .filter((item) => ids.includes(item.id))
              .map((item) => item.title);
            return selectedTitles.join(", ");
          }}
          MenuProps={{
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                maxWidth: 600,
                minWidth: 300,
                left: "50%",
                minHeight: 160,
                maxHeight: 160,
              },
            },
          }}
          className={style.box_input}
        >
          {deadlines.slice(1).map((item: deadlinesType) => (
            <div
              key={item.id}
              className={style.checked_list}
              onClick={(e) => changeIncludingOfDirection(item.id)}
            >
              <div className={style.checked_row}>
                <Checkbox
                  checked={!!ids.includes(item.id)}
                  name="select_all"
                  color="primary"
                  icon={<CheckboxSVG />}
                  checkedIcon={<CheckboxCheckedSVG />}
                />
                {item.title}
              </div>
              <div
                className={clsx(
                  style.block_color,
                  style[`block_color_${item.color}`]
                )}
              ></div>
            </div>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

const deadlines: deadlinesType[] = [
  {
    id: "0",
    title: "Выбрать...",
    color: "",
  },
  {
    id: "-2147483648,0",
    title: "Просроченные",
    color: "red-row",
  },
  {
    id: "0,14",
    title: "2 недели до окончания",
    color: "red-row",
  },
  {
    id: "15,30",
    title: "1 месяц до окончания",
    color: "orange-row",
  },

  {
    id: "31,60",
    title: "2 месяца до окончания",
    color: "yellow-row",
  },

  {
    id: "61,2147483647",
    title: "Свыше 2-х месяцев",
    color: "green-row",
  },
];
