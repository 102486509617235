import { useMutation, useQueryClient } from "react-query";
import { TrainingRequestSendEmailData } from "@interfaces/request-training";
import requestTrainingService from "@services/request-training-service";
import { trainingRequestsQueryKeys } from "./queryKeys";

export const useSendTrainingRequestEmailMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TrainingRequestSendEmailData) =>
      requestTrainingService.sendEmail(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(trainingRequestsQueryKeys.all);
      },
    }
  );
};
