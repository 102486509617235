import { Component, ErrorInfo } from "react";

import styles from "./ErrorBoundary.module.scss";
import { ErrorPage } from "@pages/error-page";
import { BodyNormal } from "@ui/fonts";
import { Warning } from "@phosphor-icons/react";

type ErrorBoundaryPropsT = {
  className?: string;
  isComponent?: boolean;
};

type ErrorBoundaryStateT = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<
  ErrorBoundaryPropsT,
  ErrorBoundaryStateT
> {
  constructor(props: ErrorBoundaryPropsT) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
    console.error(error, errorInfo);
  }

  componentWillUnmount(): void {
    this.setState({
      hasError: false,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children, isComponent } = this.props;

    if (hasError) {
      // Можно отрендерить запасной UI произвольного вида
      if (isComponent) {
        return (
          <div className={styles.error__container}>
            <Warning size={24} color="#770909" />
            <BodyNormal>Ошибка</BodyNormal>
          </div>
        );
      }

      return <ErrorPage />;
    }

    return children;
  }
}
