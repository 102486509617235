import "slick-carousel/slick/slick.css";

import { FC } from "react";

import clsx from "clsx";

import { Title } from "@components/common/common";
import { ErrorModal } from "@modals/modal-items/error/error-modal";
import styles from "@scss/pages/error.module.scss";

type ErrorPagePropsT = {
  className?: string;
};

export const ErrorPage: FC<ErrorPagePropsT> = ({ className }) => {
  return (
    <main className={clsx("content-container", styles.error)}>
      <div className="content-section">
        <div className="top-content">
          <Title text="Произошла ошибка" />
        </div>
        <div className="error-content">
          <ErrorModal open={true} onClose={() => null} />
        </div>
      </div>
    </main>
  );
};
