import { useMutation, useQueryClient } from "react-query";
import { TrainingRequestStatusActionData } from "@interfaces/request-training";
import requestTrainingService from "@services/request-training-service";
import { trainingRequestsQueryKeys } from "./queryKeys";

export const useTrainingRequestStatusActionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: TrainingRequestStatusActionData) =>
      requestTrainingService.statusAction(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(trainingRequestsQueryKeys.all);
      },
    }
  );
};
