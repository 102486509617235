import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { BodyNormal } from "@ui/fonts";
import { NewEmployeesBodyRow } from "../new-employees-body-row";
import styles from "./table-new-employees.module.scss";
import { v4 as uuidv4 } from "uuid";
import { isTrainingRequestEditable } from "@utils/isTrainingRequestEditable";
import {
  SingleTrainingRequest,
  SingleTrainingRequestProgram,
} from "@interfaces/request-training";
import { useUpdateTrainingRequestMutation } from "@lib/request-training";
import { useQueryClient } from "react-query";
import { trainingRequestsQueryKeys } from "@lib/request-training/queryKeys";

type TableTrainingProps = {
  training: SingleTrainingRequest | undefined;
};

export const TableNewEmployees: React.FC<TableTrainingProps> = ({
  training,
}) => {
  const queryClient = useQueryClient();

  const updateTrainingRequestMutation = useUpdateTrainingRequestMutation(false);

  const isTrainingEditable = isTrainingRequestEditable(training);
  const count =
    training?.programsFromApplication?.reduce(
      (total, program) => total + program.students.length,
      0
    ) ?? 0;

  const handleUpdateEducationForm = (
    program: SingleTrainingRequestProgram,
    form: string
  ) => {
    if (!training) return;

    updateTrainingRequestMutation.mutate({
      id: training.id,
      programsFromApplication: [{ id: program.id, form }],
    });
  };

  const handleUpdateTrainingPeriod = (
    program: SingleTrainingRequestProgram,
    timestamp: Date
  ) => {
    if (!training) return;

    updateTrainingRequestMutation.mutate(
      {
        id: training.id,
        programsFromApplication: [
          { id: program.id, deadline: timestamp.toISOString() },
        ],
      },
      {
        onSuccess: (result) => {
          queryClient.setQueryData<SingleTrainingRequest | undefined>(
            trainingRequestsQueryKeys.byId(training.id),
            (data) => {
              if (!data) return;
              return {
                ...data,
                ...result,
                programsFromApplication: training.programsFromApplication.map(
                  (_program) =>
                    _program.id === program.id
                      ? { ...program, deadline: timestamp.toISOString() }
                      : { ..._program }
                ),
              };
            }
          );
        },
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div>
          <BodyNormal weight={"bold"}>
            Просим Вас принять сотрудников нашей организации в количестве{" "}
          </BodyNormal>
          <BodyNormal weight={"bold"} color={"green"}>
            &nbsp;{count}&nbsp;
          </BodyNormal>
          <BodyNormal weight={"bold"}>
            {/* TODO: Добавить сколнение - человек человека */}
            человек на обучение по программам:
          </BodyNormal>
        </div>
        <div className={styles.table}>
          <Table className={styles.table_wrapper} aria-label="caption table">
            <TableHead>
              <TableRow>
                {head.map((item) => (
                  <TableCell
                    key={uuidv4()}
                    align="right"
                    className={styles.table_head}
                  >
                    <div className={styles.table_head_wrapper}>
                      <div className={styles.table_head_name}>{item.title}</div>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {training?.programsFromApplication?.map((program, index) => (
                <NewEmployeesBodyRow
                  key={uuidv4()}
                  index={index}
                  program={program}
                  isEditable={isTrainingEditable}
                  onEducationFormChange={(form) =>
                    handleUpdateEducationForm(program, form)
                  }
                  onTrainingPeriodChange={(timestamp) =>
                    handleUpdateTrainingPeriod(program, timestamp)
                  }
                />
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

type HeadT = {
  id: number;
  title: string;
}[];

const head: HeadT = [
  {
    id: 1,
    title: "№",
  },
  {
    id: 2,
    title: "Название",
  },
  {
    id: 3,
    title: "Форма обучения",
  },
  {
    id: 4,
    title: "количество человек",
  },
  {
    id: 5,
    title: "планируемый период обучения (обучить до)",
  },
];
