import { useQuery } from "react-query";
import { GetMedicalExaminationFactorsParams } from "@interfaces/medical-examination";
import { MedicalExaminationService } from "@services/medical-examination.service";
import { medicalExaminationQueryKeys } from "./queryKeys";

export const useMedicalExaminationFactorsQuery = (
  params?: GetMedicalExaminationFactorsParams
) => {
  return useQuery(medicalExaminationQueryKeys.factors(params), () =>
    MedicalExaminationService.getFactors(params)
  );
};
