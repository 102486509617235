import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { $Company } from "@store/company/company-store";
import qs from "qs";
import { setForbiddenWarning } from "@store/forbidden-warning-store";

const ErrorRefreshTokenExpired = "The token has expired";
const ErrorIllegalActivityDetected = "Обнаружена подозрительная активность";

class Fetcher {
  private _CompanyStore = $Company;
  private _addCompanyId = false;

  private readonly API_URL: string;
  private readonly $api: AxiosInstance;

  constructor(api_url: string, version: number) {
    this.API_URL = api_url + `/api/v${version}`;

    this.$api = axios.create({
      withCredentials: true,
      baseURL: this.API_URL,
      validateStatus: (status) => {
        return status >= 200 && status < 300;
      },
    });

    this.$api.interceptors.request.use((axiosReq) => {
      axiosReq.headers.authorization =
        "Bearer " + localStorage.getItem("token");
      return axiosReq;
    });

    this.$api.interceptors.response.use(
      (successRes: AxiosResponse) => successRes,
      async (error) => {
        const originalRequest = error.config;

        if (error.response.status === 403) {
          const message = error?.response?.data?.message;
          if (message) {
            setForbiddenWarning(message);
          }
        }

        if (
          error.response.status === 401 &&
          error.config &&
          originalRequest._isRetry !== true
        ) {
          originalRequest._isRetry = true;
          try {
            const response = await axios.post<string>(
              `${this.API_URL}/auth/refresh`,
              {},
              { withCredentials: true }
            );
            localStorage.setItem("token", response.data);

            return this.$api.request(originalRequest);
          } catch (e) {
            if (
              e.response.status === 401 &&
              e.response.data?.message === ErrorIllegalActivityDetected
            ) {
              localStorage.removeItem("token");
              localStorage.removeItem("selectedCompany");
              window.location.assign(
                "/login?error=" +
                  encodeURIComponent(
                    "Обнаружен вход в ваш аккаунт с другого устройства. Пожалуйста, пройдите авторизацию заново и немедленно измените пароль, если это были не вы."
                  )
              );
            }

            if (
              e.response.status === 500 &&
              e.response.data?.message.startsWith(ErrorRefreshTokenExpired)
            ) {
              localStorage.removeItem("token");
              localStorage.removeItem("selectedCompany");
              window.location.assign("/login");
            }

            throw e;
          }
        }

        throw error;
      }
    );
  }

  private _modifyUrl(url: string): string {
    if (this._addCompanyId) {
      this._addCompanyId = false;
      return "/companies/" + this._CompanyStore.getState() + url;
    }
    return url;
  }

  public get modified(): this {
    this._addCompanyId = true;
    return this;
  }

  public async get<ResponseT>(url: string, config: AxiosRequestConfig = {}) {
    try {
      url = this._modifyUrl(url);
      return await this.$api.get<ResponseT>(
        url,
        Object.assign(config, {
          paramsSerializer: (params: any) =>
            qs.stringify(params, { arrayFormat: "repeat" }),
        })
      );
    } catch (err) {
      throw err;
    }
  }

  public async post<ResponseT>(
    url: string,
    data: any = {},
    config: AxiosRequestConfig = {}
  ) {
    try {
      url = this._modifyUrl(url);
      return await this.$api.post<ResponseT>(url, data, config);
    } catch (err) {
      throw err;
    }
  }

  public async put<ResponseT>(
    url: string,
    data: any = {},
    config: AxiosRequestConfig = {}
  ) {
    try {
      url = this._modifyUrl(url);
      return await this.$api.put<ResponseT>(url, data, config);
    } catch (err) {
      throw err;
    }
  }

  public async patch<ResponseT>(
    url: string,
    data: any = {},
    config: AxiosRequestConfig = {}
  ) {
    try {
      url = this._modifyUrl(url);
      return await this.$api.patch<ResponseT>(url, data, config);
    } catch (err) {
      throw err;
    }
  }

  public async delete<ResponseT>(url: string, config: AxiosRequestConfig = {}) {
    try {
      url = this._modifyUrl(url);
      return await this.$api.delete<ResponseT>(url, config);
    } catch (err) {
      throw err;
    }
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Fetcher(process.env.API_URL as string, 1);

export const fetcherV2 = new Fetcher(process.env.API_URL as string, 2);
