import { DepartmentShortDataT } from "@interfaces/company/departments";
import DepartmentService from "@services/department-service";
import { useQuery } from "react-query";
import { departmentQueryKeys } from "./queryKeys";

export const useDepartmentListQuery = () => {
  return useQuery(departmentQueryKeys.all, async () => {
    return new Promise<DepartmentShortDataT[]>((resolve, reject) => {
      DepartmentService.getAll((err, res) => {
        if (err || !res) {
          reject(err);
        } else {
          resolve(res.data);
        }
      });
    });
  });
};
