import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { BreadCrumb, TableTopPanel } from "@components/common";
import { ColorfulButton, Title } from "@components/common/common";

import { $LegalInformationDocuments } from "@store/legal-information-store";
import LegalInformationTable from "@components/tables/legal-information-tables";
import clsx from "clsx";
import style from "@scss/pages/responsibility.module.scss";
import useModal from "@modals/modal-hook";
import { useState } from "react";
import { useStore } from "effector-react";

const LegalInformation = () => {
  const permissions = useStore($UserAddPermissions);
  const currentDocuments = useStore($LegalInformationDocuments);
  const [withHistory, setWithHistory] = useState<boolean>(false);
  const [sortOption, setSortOption] = useState<number>(0);

  const { open } = useModal();

  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={["Главная", "Юридическая информация"]} />
          <Title text="Юридическая информация" withHistory={withHistory} />
        </div>
        <div className="mt-50 bg-white rounded-md">
          <TableTopPanel
            text={`Папок: ${currentDocuments[1].length} Файлов: ${currentDocuments[0].length}`}
            hideSearchPanel
            onSelectOption={(option) => setSortOption(option.value)}
          />
        </div>
        <div className="bg-white mt-25">
          <div className={clsx(style.creation_buttons)}>
            {permissions.hasPermission(
              ACTIONS.legalInformation_files_allowedToCreate
            ) && (
              <ColorfulButton
                text={"Загрузить документы"}
                onClick={() => open("UploadLegalInformationModal")}
              />
            )}
            {permissions.hasPermission(
              ACTIONS.legalInformation_directories_allowedToCreate
            ) && (
              <ColorfulButton
                text={"Создать папку"}
                plusIcon={false}
                onClick={() =>
                  open("CreateLegalInfoFolderModal", {
                    modalData: { modalTitle: "Создать папку" },
                    btnText: "Создать",
                  })
                }
              />
            )}
          </div>
          <LegalInformationTable
            setWithHistory={setWithHistory}
            sort={sortOption}
          />
        </div>
      </div>
    </main>
  );
};

export default LegalInformation;
