import {
  GetJudicialPracticesPropsT,
  GetJudicialPracticesResT,
} from "@interfaces/requests/judicial-practices";
import { JudicialPracticeT } from "@interfaces/judicial-practice";

import Fetcher from "@http/fetcher";

import { ResCallback } from "@interfaces/common";

class JudicialPracticesService {
  static async getAll(
    params: GetJudicialPracticesPropsT,
    cb: ResCallback<GetJudicialPracticesResT>
  ) {
    try {
      const res = await Fetcher.get<GetJudicialPracticesResT>(
        "/judicial-practices",
        {
          params,
        }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err) {
      return cb(err);
    }
  }

  static async getById(id: string, cb: ResCallback<JudicialPracticeT>) {
    try {
      const res = await Fetcher.get<JudicialPracticeT>(
        `/judicial-practices/${id}`
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err) {
      return cb(err);
    }
  }

  static async create(data: FormData, cb: ResCallback<JudicialPracticeT>) {
    try {
      const res = await Fetcher.post<JudicialPracticeT>(
        `/judicial-practices`,
        data
      );
      if (res.status === 201) {
        return cb(null, res);
      }
    } catch (err) {
      return cb(err);
    }
  }

  static async update(id: string, data: FormData, cb: ResCallback<string>) {
    const formData = {
      title: data.get("title"),
      tags: data.getAll("tags"),
      text: data.get("text"),
      createdAt: data.get("createdAt"),
    };

    try {
      const res = await Fetcher.patch<string>(
        `/judicial-practices/${id}`,
        formData
      );
      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err) {
      return cb(err);
    }
  }

  static async delete(id: string, cb: ResCallback<string>) {
    try {
      const res = await Fetcher.delete<string>(`/judicial-practices/${id}`);

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err) {
      return cb(err);
    }
  }

  static async download(filename: string, cb: ResCallback<Blob>) {
    try {
      const res = await Fetcher.get<Blob>(
        `/judicial-practices/download/${filename}`,
        {
          responseType: "blob",
        }
      );
      cb(null, res);
    } catch (err) {
      return cb(err);
    }
  }
}

export default JudicialPracticesService;
