import { InstructionsComponents, LearningComponents } from "@feature";
import { memo, useState } from "react";

import { BreadCrumb } from "@components/common";
import React from "react";
import { TabSwicher } from "@ui/tab-swicher";
import { Title } from "@components/common/common";
import clsx from "clsx";
import style from "@scss/pages/company/procedure-control.module.scss";
import { GeneralMedicalExamControls } from "feature/procedure-control/general-medical-exam-controls/general-medical-exam-controls";

const tabs = [
  "Контроль обучения",
  "Контроль инструктажей",
  "Контроль мед. осмотров",
];
enum Tab {
  LEARNING = 0,
  INSTRUCTIONS = 1,
  MEDICAL_EXAMS = 2,
}

const ProcedureControlCompany: React.FC = memo(() => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={["Главная", "Контроль выполнения процедур"]} />
          <Title text="Контроль выполнения процедур" />
        </div>
        {/* <div className={clsx(style.creation_content_container)}></div> */}
        <div className={clsx(style.content)}>
          <div className={style.head}>
            <div>
              <TabSwicher
                onChange={setSelectedTab}
                value={selectedTab}
                labels={tabs}
              />
            </div>
          </div>
          <div className={style.modules_table}>
            {selectedTab === Tab.LEARNING && (
              <LearningComponents
                isGeneral={true}
                defaultFilters={{
                  isArchived: false,
                  isConfirmed: true,
                  isRequired: true,
                  isShowOneTime: false,
                }}
              />
            )}
            {selectedTab === Tab.INSTRUCTIONS && (
              <InstructionsComponents
                isGeneral={true}
                defaultFilters={{
                  isArchived: false,
                  isConfirmed: true,
                  isRequired: true,
                  isShowOneTime: false,
                }}
              />
            )}
            {selectedTab === Tab.MEDICAL_EXAMS && (
              <GeneralMedicalExamControls />
            )}
          </div>
        </div>
      </div>
    </main>
  );
});

export default ProcedureControlCompany;
