import { WorkspaceGetFilesParams } from "@interfaces/company/workspace";
import { useQuery } from "react-query";
import { workspaceQueryKeys } from "./queryKeys";
import { workspaceService } from "@services/workspace-service";

export const useWorkspaceFilesQuery = (
  companyId: number | undefined,
  groupId: number,
  directoryId: number,
  params?: WorkspaceGetFilesParams
) => {
  const { data: files, ...stuff } = useQuery(
    workspaceQueryKeys.directoriesFiles(
      companyId,
      groupId,
      directoryId,
      params
    ),
    () =>
      workspaceService.getDirectoriesAndFiles(
        companyId!,
        groupId,
        directoryId,
        params
      ),
    {
      enabled: typeof companyId !== "undefined",
    }
  );

  return {
    files,
    ...stuff,
  };
};

export const useArchivedWorkspaceFilesQuery = (
  companyId: number | undefined,
  groupId: number,
  params?: WorkspaceGetFilesParams
) => {
  const { data: files, ...stuff } = useQuery(
    ["arhived", groupId, companyId, params],
    () =>
      workspaceService.getisArchivedDirectoriesAndFiles(
        companyId!,
        groupId,
        params
      ),
    {
      enabled: typeof companyId !== "undefined",
    }
  );

  return {
    files,
    ...stuff,
  };
};
