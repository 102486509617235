import { ArticleType } from "@interfaces/legislation-news";

export const getNewsArticleBreadcrumbs = (type: ArticleType): string[] => {
  const titles: Record<ArticleType, string> = {
    "LEGISLATION-NEWS": "Новости законодательства",
    PRACTICE: "Судебная практика",
    HELP: "Полезные материалы",
    "DEVELOPMENT-NEWS": "Скоро в Личном кабинете",
    "PROJECT-NEWS": "Новости проекта",
  };
  const pageTitle = titles[type];

  return ["Главная", pageTitle];
};
