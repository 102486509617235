type CloseProps = {
  color?: string;
};

export const CloseSVG: React.FC<CloseProps> = ({ color = "#373737" }) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7805 1.03021C15.0734 1.3231 15.031 1.84036 14.6858 2.18554L2.18572 14.6856C1.84054 15.0308 1.32328 15.0732 1.03039 14.7803C0.737496 14.4874 0.779882 13.9702 1.12506 13.625L13.6252 1.12488C13.9703 0.7797 14.4876 0.737313 14.7805 1.03021Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.21935 1.03021C1.51224 0.737313 2.0295 0.7797 2.37468 1.12488L14.8748 13.625C15.22 13.9702 15.2623 14.4874 14.9694 14.7803C14.6766 15.0732 14.1593 15.0308 13.8141 14.6856L1.31402 2.18554C0.968841 1.84036 0.926454 1.3231 1.21935 1.03021Z"
        fill={color}
      />
    </svg>
  );
};
