import { FC } from "react";
import { CompanyT } from "@interfaces/company/company";
import { GetTotalSpace } from "@store/company/company-store";
import { formatFileSize } from "@utils/formatFileSize";

export type CompanyDiskUsagePropsT = {
  company: CompanyT;
};

export const CompanyDiskUsage: FC<CompanyDiskUsagePropsT> = ({ company }) => {
  if (company.tariff === 0) return null;

  const workspaceSize = parseFloat(company.workspaceSize);
  const additionalSize = parseFloat(company.additionalSize);

  const usedSpace = workspaceSize;
  const totalSpace = GetTotalSpace(company.tariff) + additionalSize;

  const leftSpacePercent = Math.floor(
    ((totalSpace - usedSpace) / totalSpace) * 100
  );

  const units = leftSpacePercent <= 5 ? ["Кб", "Мб"] : ["Кб", "Мб", "Гб"];

  return (
    <div className="remains-free-space">
      <progress value={usedSpace} max={totalSpace} />
      <p>
        Использовано {formatFileSize(usedSpace, 1, { units, showBytes: false })}{" "}
        из {formatFileSize(totalSpace, 1, { units, showBytes: false })}
      </p>
    </div>
  );
};
