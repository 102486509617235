import fetcher from "@http/fetcher";
import {
  CreateMedicalExaminationFactorData,
  GetGeneralMedicalExamControlsParams,
  GetMedicalExamControlsParams,
  GetMedicalExamEmployeeReferralParams,
  GetMedicalExaminationFactorsParams,
  GetMedicalExaminationFactorsResult,
  MedicalExamControlsT,
  MedicalExamEmployeeReferral,
  MedicalExaminationCategoryT,
  UpdateMedicalExamControlsData,
  UpdateMedicalExaminationCategoryData,
  UpdateMedicalExaminationFactorData,
} from "@interfaces/medical-examination";

export const MedicalExaminationService = {
  async getCategories() {
    const resp = await fetcher.get<MedicalExaminationCategoryT[]>(
      "/directories/medical-examination/categories",
      {
        params: {
          isIncludeChanges: true,
        },
      }
    );
    return resp.data;
  },

  async getFactors(params?: GetMedicalExaminationFactorsParams) {
    const resp = await fetcher.get<GetMedicalExaminationFactorsResult[]>(
      "/directories/medical-examination/factors",
      { params }
    );
    return resp.data;
  },

  async createFactor(data: CreateMedicalExaminationFactorData) {
    const resp = await fetcher.post(
      "/directories/medical-examination/factors",
      data
    );
    return resp.data;
  },

  async updateCategory(data: UpdateMedicalExaminationCategoryData) {
    const { id, ...body } = data;
    const resp = await fetcher.patch(
      `/directories/medical-examination/categories/${id}`,
      body
    );
    return resp.data;
  },

  async updateFactor(data: UpdateMedicalExaminationFactorData) {
    const { id, ...body } = data;
    const resp = await fetcher.patch(
      `/directories/medical-examination/factors/${id}`,
      body
    );
    return resp.data;
  },

  async deleteCategory(id: number) {
    const resp = await fetcher.delete(
      `/directories/medical-examination/categories/${id}`
    );
    return resp.data;
  },

  async deleteFactor(id: number) {
    const resp = await fetcher.delete(
      `/directories/medical-examination/factors/${id}`
    );
    return resp.data;
  },

  async getHasAnyChanges() {
    const resp = await fetcher.get<boolean>(
      "/directories/medical-examination/versions/has-any-changes"
    );
    return resp.data;
  },

  async getControls({ companyId, ...params }: GetMedicalExamControlsParams) {
    if (!companyId) {
      return null;
    }

    const resp = await fetcher.get<MedicalExamControlsT[]>(
      `/companies/${companyId}/procedures-control/medical-examinations/`,
      { params }
    );
    return resp.data;
  },

  async getGeneralControls(params: GetGeneralMedicalExamControlsParams) {
    const resp = await fetcher.get<MedicalExamControlsT[]>(
      "/companies/procedures-control/medical-examinations/",
      { params }
    );
    return resp.data;
  },

  async updateControls(data: UpdateMedicalExamControlsData) {
    const { companyId, id, ...body } = data;
    const resp = await fetcher.put<MedicalExamControlsT["body"][number]>(
      `/companies/${companyId}/procedures-control/medical-examinations/${id}`,
      body
    );
    return resp.data;
  },

  async downloadGeneralTable(params: GetGeneralMedicalExamControlsParams) {
    const resp = await fetcher.get<Blob>(
      "/companies/procedures-control/medical-examinations/download",
      { params, responseType: "blob" }
    );
    return resp.data;
  },

  async downloadCompanyTable({
    companyId,
    ...params
  }: GetMedicalExamControlsParams) {
    const resp = await fetcher.get<Blob>(
      `/companies/${companyId}/procedures-control/medical-examinations/download`,
      { params, responseType: "blob" }
    );
    return resp.data;
  },

  async downloadApplicantsList(companyId: number) {
    const resp = await fetcher.get<Blob>(
      `/companies/${companyId}/procedures-control/medical-examinations/download-list-of-persons-applying-for-work`,
      {
        responseType: "blob",
      }
    );
    return resp.data;
  },

  async downloadPeriodicExams(companyId: number) {
    const resp = await fetcher.get<Blob>(
      `/companies/${companyId}/procedures-control/medical-examinations/download-list-of-employees-subject-to-periodic-medical-examination`,
      {
        responseType: "blob",
      }
    );
    return resp.data;
  },

  async downloadEmployeesList(companyId: number) {
    const resp = await fetcher.get<Blob>(
      `/companies/${companyId}/procedures-control/medical-examinations/download-name-list-of-employees`,
      { responseType: "blob" }
    );
    return resp.data;
  },

  async getEmployeeReferral({
    companyId,
    employeeId,
    ...params
  }: GetMedicalExamEmployeeReferralParams) {
    const resp = await fetcher.get<MedicalExamEmployeeReferral>(
      `/companies/${companyId}/employees/${employeeId}/referral-to-medical-examination`,
      { params }
    );
    return resp.data;
  },

  async downloadGeneralApplicantsList(companies?: number[]) {
    const resp = await fetcher.get<string>(
      `/companies/procedures-control/medical-examinations/form-list-of-persons-applying-for-work`,
      { params: { companies } }
    );
    return resp.data;
  },

  async downloadGeneralPeriodicExams(companies?: number[]) {
    const resp = await fetcher.get<string>(
      `/companies/procedures-control/medical-examinations/form-list-of-employees-subject-to-periodic-medical-examination`,
      { params: { companies } }
    );
    return resp.data;
  },

  async downloadGeneralEmployeesList(companies?: number[]) {
    const resp = await fetcher.get<string>(
      `/companies/procedures-control/medical-examinations/form-name-list-of-employees`,
      { params: { companies } }
    );
    return resp.data;
  },
};
