import { useDisclosure } from "@mantine/hooks";
import styles from "@scss/components/date-period-input.module.scss";
import { FC, useState } from "react";
import CalendarMonth from "@mui/icons-material/CalendarMonth";
import { DatePeriod } from "@ui/date-period";

export type DatePeriodInputProps = {
  label: string;
  value: [Date | null, Date | null];
  onChange: (value: [Date | null, Date | null]) => void;
};

export const DatePeriodInput: FC<DatePeriodInputProps> = ({
  label,
  value,
  onChange,
}) => {
  const [textDate, setTextDate] = useState("");
  const [date, setDate] = useState<Date | null>(value[0]);
  const [isDateModalOpen, dateModal] = useDisclosure();

  return (
    <>
      <div onClick={dateModal.open} className={styles.datePeriodInput}>
        <div className={styles.datePeriodInput__label}>{label}</div>
        {textDate}
        <CalendarMonth
          color="action"
          className={styles.datePeriodInput__icon}
        />
      </div>
      <DatePeriod
        active={isDateModalOpen}
        setTextDate={setTextDate}
        closeModal={dateModal.close}
        selectedDate={date}
        setSelectedDate={setDate}
        type="date"
        handlerChangeFilter={(type, value) => {
          if (type !== "date" || value?.length === 1) return;
          if (!value) {
            return onChange([null, null]);
          }
          const start = value[0];
          const end = value[1];
          onChange([new Date(parseInt(start)), new Date(parseInt(end))]);
        }}
      />
    </>
  );
};
