import {
  $CommonLegalInformationDocuments,
  $LegalInformationDocuments,
  pushToLegalInformationCommonDocs,
  pushToLegalInformationDocs,
  setLegalInformationCommonDocs,
  setLegalInformationDocuments,
} from "@store/legal-information-store";
import {
  $CommonResponsibilityDocuments,
  $ResponsibilityDocuments,
  pushToResponsibilityCommonDocs,
  pushToResponsibilityDocs,
  setResponsibilityCommonDocs,
  setResponsibilityDocuments,
} from "@store/responsibility-store";
import {
  useCreateWorkspaceDirectoryMutation,
  useUpdateWorkspaceDirectoryMutation,
} from "@lib/workspace";

import LegalInformationService from "@services/legal-information-service";
import React from "react";
import ResponsibilityService from "@services/responsibility-service";
import { TextField } from "@material-ui/core";
import qs from "qs";
import { useHistory } from "react-router-dom";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import useStyles from "@ui/material-ui-styles";
import { useSelectedCompanyQuery } from "@lib/company";

const CreateFolderModal = () => {
  const { company } = useSelectedCompanyQuery();

  const responsibilityCommonDocs = useStore($CommonResponsibilityDocuments);
  const responsibilityCurrentDocs = useStore($ResponsibilityDocuments);
  const legalInformationCommonDocs = useStore($CommonLegalInformationDocuments);
  const legalInformationCurrentDocs = useStore($LegalInformationDocuments);

  const createWorkspaceDirectoryMutation =
    useCreateWorkspaceDirectoryMutation();
  const updateWorkspaceDirectoryMutation =
    useUpdateWorkspaceDirectoryMutation();

  const { modalComponent, modalData, close } = useModal();
  const classes = useStyles();
  const history = useHistory();

  const isLegalInformationPage = !!modalData.legalInformationMode;

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!company) return;

    const directoryTitle = document.querySelector(
      'input[name="directoryTitle"]'
    ) as HTMLInputElement;
    const modalBtn = document.querySelector(
      "button.modal_btn"
    ) as HTMLButtonElement;
    modalBtn.disabled = true;

    // CREATING FOLDER
    if (directoryTitle?.value && !modalData?.item?.id) {
      const queryString = qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
      });

      if (isLegalInformationPage) {
        return LegalInformationService.CreateDirectory(
          {
            name: directoryTitle.value,
            directoryId: queryString.folder_id ? +queryString.folder_id : null,
          },
          (err, res) => {
            if (err || !res) return;

            if (+(queryString.folder_id as string) === 0) {
              pushToLegalInformationCommonDocs([null, res.data]);
            } else {
              pushToLegalInformationDocs([null, res.data]);
            }
            close();
          }
        );
      }

      if (modalData.responsibilityMode) {
        return ResponsibilityService.CreateDirectory(
          {
            name: directoryTitle.value,
            directoryId: queryString.folder_id ? +queryString.folder_id : null,
          },
          (err, res) => {
            if (err || !res) {
              return console.error("При создании папки произошла ошибка");
            }
            if (+(queryString.folder_id as string) === 0) {
              pushToResponsibilityCommonDocs([null, res.data]);
            } else {
              pushToResponsibilityDocs([null, res.data]);
            }
            close();
          }
        );
      }

      return createWorkspaceDirectoryMutation.mutate(
        {
          companyId: company.id,
          groupId: modalData.groupId,
          directoryId: queryString.folder_id ? +queryString.folder_id : 0,
          name: directoryTitle.value,
        },
        {
          onSuccess: () => {
            close();
          },
        }
      );
    }

    // UPDATING FOLDER
    if (directoryTitle?.value && modalData?.item?.id) {
      if (isLegalInformationPage) {
        return LegalInformationService.UpdateDirectory(
          modalData.item.id,
          directoryTitle.value,
          (err, res) => {
            if (err || !res) return;

            setLegalInformationCommonDocs([
              legalInformationCommonDocs[0],
              legalInformationCommonDocs[1].map((dir) => {
                if (dir.id === modalData.item.id) {
                  dir.name = directoryTitle.value;
                }
                return dir;
              }),
            ]);
            setLegalInformationDocuments([
              legalInformationCurrentDocs[0],
              legalInformationCurrentDocs[1].map((dir) => {
                if (dir.id === modalData.item.id) {
                  dir.name = directoryTitle.value;
                }
                return dir;
              }),
            ]);
            close();
          }
        );
      }

      if (modalData.responsibilityMode) {
        ResponsibilityService.UpdateDirectory(
          modalData.item.id,
          directoryTitle.value,
          (err, res) => {
            if (err || !res) {
              return console.error("При обновлении папки произошла ошибка");
            }
            setResponsibilityCommonDocs([
              responsibilityCommonDocs[0],
              responsibilityCommonDocs[1].map((dir) => {
                if (dir.id === modalData.item.id) {
                  dir.name = directoryTitle.value;
                }
                return dir;
              }),
            ]);
            setResponsibilityDocuments([
              responsibilityCurrentDocs[0],
              responsibilityCurrentDocs[1].map((dir) => {
                if (dir.id === modalData.item.id) {
                  dir.name = directoryTitle.value;
                }
                return dir;
              }),
            ]);
            close();
          }
        );
        return;
      }

      return updateWorkspaceDirectoryMutation.mutate(
        {
          companyId: company.id,
          groupId: modalData.groupId || 0,
          directoryId: modalData.item.id,
          name: directoryTitle.value,
        },
        {
          onSuccess: () => {
            close();
          },
        }
      );
    }
  };

  return (
    <div key={modalComponent.key}>
      <p className="modal_title">{modalData.modalTitle}</p>
      <div className="underline" />
      <form className={`modal_content ${classes.root}`} onSubmit={handleSubmit}>
        <TextField
          label={"Название папки"}
          name={"directoryTitle"}
          variant="filled"
          required
          defaultValue={modalData.itemTitle}
        />
        <button type="submit" className="modal_btn">
          {modalComponent.btnText}
        </button>
      </form>
    </div>
  );
};

export default CreateFolderModal;
