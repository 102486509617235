import { internalStudyCenterService } from "@services/internal-study-center-service";
import { useQuery } from "react-query";
import { internalStudyCenterQueryKeys } from "./queryKeys";

export const useInternalMaterialQuery = (
  id: number | undefined,
  sectionId: number | undefined,
  categoryId: number | undefined
) => {
  const { data: material, ...stuff } = useQuery(
    internalStudyCenterQueryKeys.materialById(id, sectionId, categoryId),
    () =>
      internalStudyCenterService.getMaterialById(id!, sectionId!, categoryId!),
    {
      enabled:
        typeof id !== "undefined" &&
        typeof categoryId !== "undefined" &&
        typeof sectionId !== "undefined",
    }
  );

  return {
    material,
    ...stuff,
  };
};
