import {
  $DirectoryInstructions,
  FetchGetDirectoryInstructionsTable,
} from "@store/directory-programm-instuctions";
import {
  $ProcedureInstructions,
  FetchAllCategoriesForInstructionsPrograms,
} from "@store/company/procedure-instrunctions-store";
import { memo, useEffect, useState } from "react";

import { AddCatedory } from "../add-catedory/add-catedory";
import { BodyNormal } from "@ui/fonts";
import { Button } from "@ui/button";
import { DirectoryVersion } from "../directory-version";
import DirectoryVersionService from "@services/version-service";
import { DropDownMultiSelect } from "@ui/drop-down";
import { OnSaveAll } from "../on-save-all";
import { ProgramTableT } from "@interfaces/directory/version";
import { TableProgramm } from "../table-programm/table-programm";
import styles from "./programm-instuctions.module.scss";
import { useStore } from "effector-react";
import { v4 as uuidv4 } from "uuid";

type SelectListType = {
  id: number;
  title: string;
};

export const ProgrammInstuctions: React.FC = memo(() => {
  const TYPE = "briefing";
  const { instructions } = useStore($DirectoryInstructions);
  const directionInstructions = useStore($ProcedureInstructions);
  const [openVersionLog, setOpenVersionLog] = useState<boolean>(false);
  const [instructionList, setInstructionList] = useState<SelectListType[]>();
  const [stateSave, setStateSave] = useState<boolean>(false);
  const handlerLerningList = (values: SelectListType[]) => {
    setInstructionList(values);
  };

  const updateCategory = () => {
    FetchGetDirectoryInstructionsTable({});
    FetchAllCategoriesForInstructionsPrograms({ isIncludeChanges: true });
  };

  useEffect(() => {
    updateCategory();
  }, []);

  const closeDownSave = () => setStateSave(false);

  const handlerProgramm = (choiceId: number) => {
    if (!!instructionList) {
      if (choiceId !== 0) {
        let categories = [choiceId];
        FetchGetDirectoryInstructionsTable({
          categories,
        });
      }
      if (choiceId === 0) {
        let categories = instructionList
          .filter(function (item, pos) {
            return instructionList.indexOf(item) === pos;
          })
          .map((item) => item.id);

        FetchGetDirectoryInstructionsTable({
          categories,
        });
      }
    }
  };

  useEffect(() => {
    if (!instructionList) {
      FetchGetDirectoryInstructionsTable({});
    }
    if (instructionList && instructions?.length) {
      let categories = instructionList
        .filter((item, pos) => instructionList.indexOf(item) === pos)
        .map((item) => item.id);
      if (categories) {
        FetchGetDirectoryInstructionsTable({ categories });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instructionList]);

  const viewOnSaveButton = () => {
    DirectoryVersionService.GetHaveAnyChanges(TYPE, (err, res) => {
      if (err || !res) {
        return Error(
          `При попытке отобразить кнопку для сохранения изменений ${TYPE} произошла ошибка`
        );
      }
      if (res.data) {
        setStateSave(res.data);
      }
    });
  };

  useEffect(() => {
    viewOnSaveButton();
  }, [instructions]);

  useEffect(() => {
    if (!directionInstructions.categories) return;
    setInstructionList(
      directionInstructions.categories.filter((c) => c.id !== 99999)
    );
  }, [directionInstructions.categories]);

  return (
    <div className={styles.content}>
      {openVersionLog ? (
        <DirectoryVersion
          updateCategory={updateCategory}
          onClose={() => setOpenVersionLog(false)}
          type={TYPE}
        />
      ) : (
        <>
          <div className={styles.head}>
            <div className={styles.head_direction}>
              <AddCatedory
                list={instructionList}
                type={TYPE}
                handlerProgramm={handlerProgramm}
              />
              <div className={styles.head_select}>
                <DropDownMultiSelect
                  title={"Все категории"}
                  direction={directionInstructions.categories ?? []}
                  handlerList={handlerLerningList}
                />
              </div>
            </div>
            <div className={styles.head_right}>
              <Button
                onClick={() => setOpenVersionLog(true)}
                className={styles.button_next}
              >
                <BodyNormal>Журнал версий справочника</BodyNormal>
              </Button>
            </div>
          </div>
          <div className={styles.tables}>
            {!!instructions &&
              instructions.map((item: ProgramTableT) => {
                return (
                  <TableProgramm
                    key={uuidv4()}
                    type={TYPE}
                    head={headTable}
                    id={item?.id}
                    action={item?.action}
                    title={item?.title}
                    change={item?.change}
                    programs={item.programs}
                  />
                );
              })}
          </div>
          {stateSave && (
            <OnSaveAll
              type={TYPE}
              updateCategory={updateCategory}
              closeDownSave={closeDownSave}
            />
          )}
        </>
      )}
    </div>
  );
});

type HeadTableT = {
  id: string;
  title: string;
}[];

const headTable: HeadTableT = [
  {
    id: "0",
    title: "№",
  },
  {
    id: "1",
    title: "Вид инструктажа",
  },
  {
    id: "2",
    title: "Срок проведения",
  },
  {
    id: "3",
    title: "Периодичность",
  },
  {
    id: "4",
    title: "",
  },
  {
    id: "5",
    title: "",
  },
];
