import {
  StudyCenterEvent,
  StudyCenterEducationalPromotion,
} from "@interfaces/study-center";

import { ProcedureTrainingProgramsT } from "@interfaces/company/procedure";
import fetcher from "@http/fetcher";

export type StudyCenterProgramsParams = {
  categories?: string;
};

export type StudyCenterCreateRequestData = {
  companyId: number;
  programs: number[];
};

export type EducationalPromotionData = Pick<
  StudyCenterEducationalPromotion,
  "title" | "shortDescription" | "description" | "date"
> & {
  file?: File;
};
export type UpdateEducationalPromotionData = Partial<
  EducationalPromotionData & {
    id: number;
  }
>;

export type EventData = Pick<
  StudyCenterEvent,
  "title" | "shortDescription" | "description" | "date"
> & {
  file?: File;
};
export type UpdateEventData = Partial<
  EventData & {
    id: number;
  }
>;

export type ApplyData = {
  id: number;
  companyId: number;
};

export const studyCenterService = {
  async getPrograms(params?: StudyCenterProgramsParams) {
    const resp = await fetcher.get<ProcedureTrainingProgramsT[]>(
      "/directories/training/programs",
      { params }
    );

    return resp.data;
  },

  async createRequest(data: StudyCenterCreateRequestData) {
    const resp = await fetcher.post<{ id: number }>(
      "/applications-for-training/create-by-program",
      data
    );

    return resp.data;
  },

  async getPromotions(params: { limit: number; offset: number }) {
    const resp = await fetcher.get<StudyCenterEducationalPromotion[]>(
      "/educational-center/offers/promotions",
      { params }
    );

    return resp.data;
  },

  async getPromotionById(id: number) {
    const resp = await fetcher.get<StudyCenterEducationalPromotion>(
      `/educational-center/offers/promotions/${id}`
    );

    return resp.data;
  },

  async deletePromotion(id: number) {
    const resp = await fetcher.delete(
      `/educational-center/offers/promotions/${id}`
    );

    return resp.data;
  },

  async getEvents(params: { limit: number; offset: number }) {
    const resp = await fetcher.get<StudyCenterEvent[]>(
      "/educational-center/offers/events",
      { params }
    );

    return resp.data;
  },

  async getEventById(id: number) {
    const resp = await fetcher.get<StudyCenterEvent>(
      `/educational-center/offers/events/${id}`
    );

    return resp.data;
  },

  async deleteEvent(id: number) {
    const resp = await fetcher.delete(
      `/educational-center/offers/events/${id}`
    );

    return resp.data;
  },

  async createPromotion(data: EducationalPromotionData) {
    const formData = new FormData();
    formData.set("title", data.title);
    formData.set("description", data.description);
    formData.set("shortDescription", data.shortDescription);
    formData.set("date", data.date);
    if (data.file) {
      formData.set("image", data.file);
    }

    const resp = await fetcher.post(
      "/educational-center/offers/promotions",
      formData
    );

    return resp.data;
  },

  async updatePromotion(data: UpdateEducationalPromotionData) {
    const formData = new FormData();
    formData.set("title", data.title ?? "");
    formData.set("description", data.description ?? "");
    formData.set("shortDescription", data.shortDescription ?? "");
    formData.set("date", data.date ?? "");
    if (data.file) {
      formData.set("image", data.file);
    }

    const resp = await fetcher.patch(
      `/educational-center/offers/promotions/${data.id}`,
      formData
    );

    return resp.data;
  },

  async createEvent(data: EventData) {
    const formData = new FormData();
    formData.set("title", data.title);
    formData.set("description", data.description);
    formData.set("shortDescription", data.shortDescription);
    formData.set("date", data.date);
    if (data.file) {
      formData.set("image", data.file);
    }

    const resp = await fetcher.post(
      "/educational-center/offers/events",
      formData
    );

    return resp.data;
  },

  async updateEvent(data: UpdateEventData) {
    const formData = new FormData();
    formData.set("title", data.title ?? "");
    formData.set("description", data.description ?? "");
    formData.set("shortDescription", data.shortDescription ?? "");
    formData.set("date", data.date ?? "");
    if (data.file) {
      formData.set("image", data.file);
    }

    const resp = await fetcher.patch(
      `/educational-center/offers/events/${data.id}`,
      formData
    );

    return resp.data;
  },

  async applyForPromotion(data: ApplyData) {
    const resp = await fetcher.post(
      `/educational-center/offers/promotions/${data.id}/apply`,
      data
    );

    return resp.data;
  },

  async applyForEvent(data: ApplyData) {
    const resp = await fetcher.post(
      `/educational-center/offers/events/${data.id}/apply`,
      data
    );

    return resp.data;
  },
};
