import { Procedure } from "./company/procedure";
import { WorkspaceFileT } from "./company/workspace";

export type PlanTaskEmployee = {
  id: number;
  name: string;
  email: string;
  position: {
    id: number;
    title: string;
    department: {
      id: number;
      title: string;
    };
  };
};

export enum TaskType {
  Custom = "Custom",
  FillDataInTrainingControlTable = "FillDataInTrainingControlTable",
  FillDataInBriefingControlTable = "FillDataInBriefingControlTable",
  ConductBriefing = "ConductBriefing",
  ConductPrimaryBriefing = "ConductPrimaryBriefing",
  ConductIntroductoryBriefing = "ConductIntroductoryBriefing",
  ConductTrainingIn = "ConductTrainingIn",
  ConductTrainingOut = "ConductTrainingOut",
  FillPositionInformation = "FillPositionInformation",
}

export type PlanTaskDataT = {
  id: number;
  title: string;
  desc: string;
  isFinancial: boolean;
  withSigning: number;
  status: number;
  periodicity: number;
  savedToCalendar: number;
  groupId: number;
  parentId: number;
  startDate: number;
  deadline: number;
  selectedGroups: Array<string | number>;
  documents: WorkspaceFileT[];
  employees: Array<string | number>;
  signedEmployees: Array<string | number> | null;
  createdBy: number;
  applicationId?: number;
  groupTitle?: string;
  group: {
    company: {
      id: number;
      name: string;
    };
  };

  /*
    applicationId: null;
  briefingControls: null;
  completeDate: null;
  createdBy: 25;
  deadline: "2023-10-11T11:26:36.254Z";
  desc: "!!!!!!!!!!!!!!";
  documents: [705, 660];
  0: 705;
  1: 660;
  employees: [285];
  0: 285;
  groupId: 912;
  id: 515;
  isCreatedAutomatically: false;
  isFinancial: false;
  parentId: null;
  periodicity: 1;
  savedToCalendar: false;
  selectedGroups: [687, 688, 689, 690, 691];
  0: 687;
  1: 688;
  2: 689;
  3: 690;
  4: 691;
  signedEmployees: null;
  startDate: "2023-10-11T10:26:36.254Z";
  status: 1;
  title: "!!!!!!!!!!!!!!1";
  trainingControls: null;
  type: "Custom";
  departmentId: null;
  withSigning: true;
   */
  // TODO: здесь прилетают другие типы
  //
  // briefingControls?: null;
  // // createdBy: 55;
  // // deadline: 1690200534678;
  // // desc: "333";
  // // documents: null;
  // // employees: null;
  // // groupId: 6419;
  // // id: 125;
  // isCreatedAutomatically: false;
  // // isFinancial: false;
  // // periodicity: 1;
  // // savedToCalendar: false;
  // // selectedGroups: null;
  // // signedEmployees: null;
  // // startDate: 1690196934678;
  // // status: 1;
  // // title: "222";
  // trainingControls: null;
  // type: "Пользовательская";
  // // withSigning: true;
};
export enum PlanTaskStatusEnum {
  "s1" = "Создание",
  "s2" = "На подписании",
  "s3" = "Документы подписаны",
  "s4" = "Подписание проверено",
  "s5" = "В работе",
  "s6" = "Выполнено",
  "s7" = "В архиве",
  "s11" = "Просрочено",
}

export type WorkplanTask = Omit<PlanTaskDataT, "employees"> & {
  employees?: PlanTaskEmployee[] | null;
  trainingControls?: Procedure[] | null;
  briefingControls?: Procedure[] | null;
  departmentId?: number | null;
  type: TaskType;
};

export type GetAllGroupIdT = {
  companyId: number;
  id: number;
  title: string;
  userId: number | null;
};

export type TaskChangeHistoryT = {
  id: number;
  updatedAt: number;
  oldDeadLine: number;
  newDeadLine: number;
};

export type SimplePlanGroupDataT = {
  id: number;
  title: string;
};

export type PlanGroupDataT = SimplePlanGroupDataT & {
  tasks: PlanTaskDataT[];
};

export type PlanGroupsStoreT = {
  count: number;
  rows: PlanGroupDataT[];
};

export type SelectedTasksStateT = {
  [key: string]: {
    display: boolean;
    subtasks: {
      [key: string]: boolean;
    };
  };
};
