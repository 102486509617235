export const CircleNotDoneSVG = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9004 30.7021C24.2793 30.7021 31.1787 23.8027 31.1787 15.4238C31.1787 7.05957 24.2646 0.145508 15.8857 0.145508C7.52148 0.145508 0.62207 7.05957 0.62207 15.4238C0.62207 23.8027 7.52148 30.7021 15.9004 30.7021ZM15.9004 27.3477C9.29395 27.3477 3.99121 22.0303 3.99121 15.4238C3.99121 8.81738 9.2793 3.5 15.8857 3.5C22.5068 3.5 27.8096 8.81738 27.8242 15.4238C27.8389 22.0303 22.5068 27.3477 15.9004 27.3477ZM10.4805 16.9326H21.3203C22.2285 16.9326 22.8877 16.3613 22.8877 15.4531C22.8877 14.5449 22.2578 13.959 21.3203 13.959H10.4805C9.54297 13.959 8.89844 14.5449 8.89844 15.4531C8.89844 16.3613 9.55762 16.9326 10.4805 16.9326Z"
        fill="#00B856"
      />
    </svg>
  );
};
