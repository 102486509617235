import { WorkplanTask } from "@interfaces/specialist-plan";
import fetcher from "@http/fetcher";
import { useQuery } from "react-query";

export const useWorkplanTaskQuery = (
  companyId: number | undefined,
  taskId: number | undefined
) => {
  const { data: task, ...stuff } = useQuery(
    ["workplan", "task", { companyId, taskId }],
    async () => {
      const resp = await fetcher.get<WorkplanTask>(
        `/companies/${companyId}/work-plan/tasks/${taskId}`
      );
      return resp.data;
    },
    {
      enabled:
        typeof companyId !== "undefined" && typeof taskId !== "undefined",
    }
  );

  return {
    task,
    ...stuff,
  };
};
