import { CloseSVG } from "@components/svgs";
import { Modal as ModalMUI } from "@mui/material";
import clsx from "clsx";
import styles from "./modal.module.scss";
import { CSSProperties, useEffect } from "react";

type ModalProps = {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
  className?: string;
  viewClose?: boolean;
  style?: CSSProperties;
};

export const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  children,
  viewClose = true,
  className = "",
  ...props
}) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("keyup", ({ key }) => {
        if (key === "Escape") return onClose();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalMUI
      {...props}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className={clsx(styles.modal_box, className)}>
        <div className={styles.modal_close} onClick={onClose}>
          <CloseSVG />
        </div>
        {children}
      </div>
    </ModalMUI>
  );
};
