import "@scss/pages/question-answer.scss";

import { $UserAddPermissions, ACTIONS } from "@store/user-store";

import { BreadCrumb } from "@components/common";
import { FeedBackBlock } from "@feature";
import { Title } from "@components/common/common";
import { useStore } from "effector-react";

const Feedbacks: React.FC = () => {
  const permissions = useStore($UserAddPermissions);

  return (
    <>
      <main className="content-container">
        <div className="content-section">
          <div className="top-content">
            <BreadCrumb items={["Главная", "Техподдержка"]} />
            <Title text="Техподдержка" />
          </div>
          {permissions.hasPermission(
            ACTIONS.support_questions_allowedToAsk
          ) && (
            <section className="qa-tab-items full-width">
              <FeedBackBlock />
            </section>
          )}
        </div>
      </main>
    </>
  );
};

export default Feedbacks;
