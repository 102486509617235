import {
  $ProcedureControlStates,
  $ProcedureTraining,
  FetchAllTrainingPrograms,
} from "@store/company/procedure-training-store";
import { Box, Center, ScrollArea, Text } from "@mantine/core";
import React, { useEffect, useMemo, useState } from "react";

import { ArrowDropDownSVG } from "@components/svgs";
import { ChildrenProps } from "./create-position-modal";
import EditTrainingProgramDepartment from "./edit-training-program-department";
import { Loader } from "@ui/indicators";
import { ProcedureProgram } from "@interfaces/company/procedure";
import ViewTrainingProgramDepartment from "./view-training-program-department";
import clsx from "clsx";
import styles from "@scss/modals/program/edit-program-modal.module.scss";
import { useStore } from "effector-react";
import { useSelectedCompanyQuery } from "@lib/company";

export const TRAINING_PLACES = [
  { value: "У работодателя", label: "У работодателя" },
  {
    value: "В образовательной организации",
    label: "В образовательной организации",
  },
];

const EditLearningProgramModal: React.FC<ChildrenProps> = ({
  trainingProgramsValue,
  setTraining,
  type,
}) => {
  const { company } = useSelectedCompanyQuery();

  const { programs } = useStore($ProcedureTraining);
  const { isLoading } = useStore($ProcedureControlStates);

  const sortedPrograms = useMemo(() => {
    if (!programs?.length) return [];

    return programs.filter((item) => item.programs.length !== 0);
  }, [programs]);

  const addedSortedPrograms = useMemo(() => {
    if (type === "view") {
      const selectedIds = trainingProgramsValue.map((item) => item.id);
      return sortedPrograms
        .map((prog) => {
          const programs = prog.programs.filter((dataItem) =>
            selectedIds.includes(dataItem.id)
          );
          return { ...prog, programs };
        })
        .filter((block) => block.programs.length !== 0);
    }
    return sortedPrograms;
  }, [type, trainingProgramsValue, sortedPrograms]);

  useEffect(() => {
    if (company) {
      FetchAllTrainingPrograms({ isIncludeChanges: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.head_title}>
        Выбрать программы и место обучения
      </div>
      <ScrollArea style={{ height: "70vh", maxHeight: "100%", minWidth: 250 }}>
        {!isLoading ? (
          addedSortedPrograms.map(({ programs, categoryId, title }) => (
            <Categoryes
              key={categoryId}
              setTraining={setTraining}
              programs={programs}
              title={title}
              trainingProgramsValue={trainingProgramsValue}
              type={type}
            />
          ))
        ) : (
          <Center sx={{ height: "70vh", margin: "0 auto", width: 250 }}>
            <Loader />
          </Center>
        )}
      </ScrollArea>
    </>
  );
};

type CategoryesTypeProps = {
  programs: ProcedureProgram[];
  title: string;
  type: "view" | "edit";
  setTraining: React.Dispatch<
    React.SetStateAction<
      {
        id: number;
        location: string;
      }[]
    >
  >;
  trainingProgramsValue: {
    id: number;
    location: string;
  }[];
};

const Categoryes: React.FC<CategoryesTypeProps> = ({
  programs,
  title,
  type,
  setTraining,
  trainingProgramsValue,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <React.Fragment>
      <div onClick={() => setIsOpen(!isOpen)} className={styles.title}>
        <div
          className={clsx(
            {
              [styles.arrow_drop__opened]: isOpen,
            },
            styles.arrow
          )}
        >
          <ArrowDropDownSVG />
        </div>
        <Text py={12}>{title}</Text>
      </div>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.colors.gray[0],
        })}
      >
        {isOpen && (
          <>
            <div className={styles.table_head}>
              <Text size="md" sx={{ whiteSpace: "nowrap" }}>
                Наименование программы
              </Text>
              <Text
                size="md"
                sx={{
                  whiteSpace: "nowrap",
                  width: type === "edit" ? 200 : 140,
                }}
              >
                Место обучения
              </Text>
            </div>
            {programs.map((item) =>
              type === "edit" ? (
                <EditTrainingProgramDepartment
                  setTraining={setTraining}
                  trainingProgramsValue={trainingProgramsValue}
                  key={item.id}
                  item={item}
                />
              ) : (
                <ViewTrainingProgramDepartment
                  trainingProgramsValue={trainingProgramsValue}
                  key={item.id}
                  item={item}
                />
              )
            )}
          </>
        )}
      </Box>
    </React.Fragment>
  );
};

export default EditLearningProgramModal;
