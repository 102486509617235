import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import React, { useEffect, useRef, useState } from "react";
import {
  useApplyForEducationalPromotionMutation,
  useStudyCenterEducationalPromotionsQuery,
} from "@lib/study-center";

import { Button } from "@ui/button";
import { Link } from "react-router-dom";
import { Loader } from "@ui/indicators";
import { MaterialCard } from "./material-card";
import styles from "./study-materials.module.scss";
import { useModal } from "@modals/index";
import { useQueryClient } from "react-query";
import { useStore } from "effector-react";
import { studyCenterQueryKeys } from "@lib/study-center/queryKeys";

export const EducationalPromotionsTab: React.FC = () => {
  const permissions = useStore($UserAddPermissions);
  const hiddenDiv = useRef<HTMLDivElement>(null);
  const [isBottom, setIsBottom] = useState(false);
  const [page, setPage] = useState<number>(0);
  const queryClient = useQueryClient();

  const handleIsBottom = (e: boolean) => {
    setIsBottom(e);
  };

  useEffect(() => {
    queryClient.removeQueries("study-center");
    queryClient.removeQueries("educational-promotions");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: educationalPromotions,
    isLoading,
    fetchNextPage,
  } = useStudyCenterEducationalPromotionsQuery({
    offset: page,
    enabled: true,
  });

  useEffect(() => {
    if (!isBottom && isLoading) return;
    if (educationalPromotions?.pages?.length) {
      if (
        educationalPromotions?.pages[educationalPromotions?.pages.length - 1]
          .length === 0
      ) {
        return;
      }
    }
    setPage((prevPage) => prevPage + 24);
    !!fetchNextPage && fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBottom]);

  useEffect(() => {
    if (!isLoading) !!fetchNextPage && fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, isLoading]);

  const modal = useModal();
  const applyForEducationalPromotionMutation =
    useApplyForEducationalPromotionMutation();

  const handleApply = (id: number) => {
    modal.open("SelectCompanyModal", {
      modalData: {
        title: "Для получения скидки выберите компанию.",
      },
      onConfirm: (companyId: number | undefined) => {
        if (companyId === undefined || id === undefined) return;

        applyForEducationalPromotionMutation.mutate(
          {
            id,
            companyId,
          },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(
                studyCenterQueryKeys.educationalPromotions()
              );
              modal.open("ConfirmActionModal", {
                btnText: "Хорошо",
                modalData: {
                  title: "Заявка отправлена",
                  text: "Спасибо! Ваша заявка отправлена. В ближайшее время с вами свяжется Администратор для уточнения деталей",
                  removeIcon: true,
                  removeCancelBtn: true,
                  style: {
                    maxWidth: "500px",
                  },
                },
              });
            },
          }
        );
      },
    });
  };

  return (
    <div>
      {permissions.hasPermission(
        ACTIONS.educationalCenter_materials_allowedToCreate
      ) && (
        <Link to="/study-center/materials/educational-promotions/create">
          <Button className={styles.Materials__createBtn}>
            Создать акцию или предложение
          </Button>
        </Link>
      )}
      <div>
        {isLoading && <Loader />}
        {!isLoading &&
          educationalPromotions &&
          educationalPromotions?.pages?.[0] && (
            <>
              {educationalPromotions?.pages?.map((item, i) => {
                if (!item.length) {
                  return <></>;
                }
                return (
                  <div key={i} className={styles.Materials__list}>
                    {item?.map((educationalPromotion, index) => (
                      <div key={educationalPromotion.id}>
                        <MaterialCard
                          material={educationalPromotion}
                          type="educational-promotion"
                          onApply={() => handleApply(educationalPromotion.id)}
                          handleIsBottom={handleIsBottom}
                          hiddenDiv={
                            index === item.length - 1 ? hiddenDiv : null
                          }
                        />
                      </div>
                    ))}
                    <div ref={hiddenDiv} />
                  </div>
                );
              })}
            </>
          )}
      </div>
    </div>
  );
};
