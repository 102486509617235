import { InstructionsComponents } from "@feature";
import React from "react";
import { useParams } from "react-router-dom";

interface EmployeesInstructionsProps {}

const EmployeesInstructions: React.FC<EmployeesInstructionsProps> = () => {
  const { id } = useParams<{ id: string }>();

  if (typeof id === "undefined") {
    return null;
  }

  return (
    <InstructionsComponents
      withoutFilters
      withoutEmpty
      defaultFilters={{
        isRequired: true,
        employees: [+id],
      }}
      ignoredKeys={["companyName", "departmentTitle", "positionTitle"]}
    />
  );
};

export default EmployeesInstructions;
