import { GetAllTrainingRequestsParams } from "@interfaces/request-training";

export const trainingRequestsQueryKeys = {
  all: ["training-requests"],
  allWithParams: (params: GetAllTrainingRequestsParams) => [
    ...trainingRequestsQueryKeys.all,
    params,
  ],
  byId: (id: number) => [...trainingRequestsQueryKeys.all, id],
  availableEmployees: (id: number) => [
    ...trainingRequestsQueryKeys.all,
    id,
    "available-employees",
  ],
};
