import { $UserAddPermissions, UserPriorityEnum } from "@store/user-store";

import { $AllTariffs } from "@store/payment-store";
import { ChoosingTariffCards } from "@components/payment/payment-components";
import { useStore } from "effector-react";

const PaymentTariffs: React.FC = () => {
  const allTariffs = useStore($AllTariffs);
  const permissions = useStore($UserAddPermissions);

  return (
    <section className="tab-content-item">
      <div className="tab-content-item__top-panel">
        <h4 className="tab-content-title">Тарифы</h4>
        {!permissions.hasRequiredLevel(UserPriorityEnum.Worker) && (
          <p>Чтобы продлить тариф, выберите срок тарификации и способ оплаты</p>
        )}
      </div>
      <ChoosingTariffCards items={allTariffs} />
    </section>
  );
};

export default PaymentTariffs;
