import {
  PositionCompanyDataT,
  PositionShortDataT,
} from "@interfaces/company/departments";

import Fetcher from "@http/fetcher";
import { ResCallback } from "@interfaces/common";

class PositionsService {
  static async create(
    departmentId: number,
    title: string,
    briefingPrograms: number[],
    trainingPrograms: { id: number; location: string }[],
    medicalExaminationFactors: number[],
    cb: ResCallback<PositionShortDataT>
  ) {
    try {
      const res = await Fetcher.modified.post<PositionShortDataT>(
        `/departments/${departmentId}/positions`,
        {
          departmentId,
          title,
          briefingPrograms,
          trainingPrograms,
          medicalExaminationFactors,
        }
      );

      if (res.status === 201) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async getCompanyPositions(cb: ResCallback<PositionCompanyDataT[]>) {
    try {
      const res = await Fetcher.modified.get<PositionCompanyDataT[]>(
        `/departments/positions`
      );
      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async update(
    departmentId: number,
    positionId: number,
    title: string,
    briefingPrograms: number[],
    trainingPrograms: { id: number; location: string }[],
    medicalExaminationFactors: number[],
    cb: ResCallback<PositionShortDataT>
  ) {
    try {
      const res = await Fetcher.modified.put<PositionShortDataT>(
        `/departments/${departmentId}/positions/${positionId}`,
        { title, trainingPrograms, briefingPrograms, medicalExaminationFactors }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async addFiles(
    departmentId: number,
    positionId: number,
    selectedFiles: number[] | [],
    cb: ResCallback<string>
  ) {
    try {
      const res = await Fetcher.modified.put<string>(
        `/departments/${departmentId}/positions/${positionId}/files`,
        { files: selectedFiles }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async removeFiles(
    departmentId: number,
    positionId: number,
    files: number[],
    cb: ResCallback<unknown>
  ) {
    try {
      const res = await Fetcher.modified.delete(
        `/departments/${departmentId}/positions/${positionId}/files`,
        {
          data: {
            files,
          },
        }
      );

      return cb(null, res);
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async delete(
    departmentId: number,
    positionId: number,
    cb: ResCallback<string>
  ) {
    try {
      const res = await Fetcher.modified.delete<string>(
        `/departments/${departmentId}/positions/${positionId}`
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }
}

export default PositionsService;
