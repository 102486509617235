import { $Employees, fetchEmployees } from "@store/company/employees-store";
import {
  $SpecialistPlans,
  fetchSpecialistPlans,
} from "@store/specialist-plan-store";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { FC, HTMLAttributes, memo, useEffect, useState } from "react";
import { FileSVG, PeopleSVG, PlusSVG } from "@components/svgs";

import DateToStringMonth from "@utils/month-to-string";
import EmployeeService from "@services/employee-service";
import { PlanTaskDataT } from "@interfaces/specialist-plan";
import SpecialistPlanService from "@services/specialist-plan-service";
import clsx from "clsx";
import style from "@scss/pages/company/signing-control-kanban.module.scss";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import { useSelectedCompanyQuery } from "@lib/company";

const KANBAN_STRUCT = [
  {
    id: 1,
    title: "Создание",
    items: [],
  },
  {
    id: 2,
    title: "На подписании",
    items: [],
  },
  {
    id: 3,
    title: "Документы подписаны",
    items: [],
  },
  {
    id: 4,
    title: "Подписание проверено",
    items: [],
  },
];

type PropsCard = {
  task: CardT;
  eventId: number;
};
type ColumnT = {
  id: number;
  title: string;
  items: CardT[];
};
type CardT = PlanTaskDataT & {
  section: string;
  sectionId: number;
};

// CARD OF TASK IN KANBAN
const Card: FC<PropsCard & HTMLAttributes<HTMLDivElement>> = memo(
  ({ task, eventId, ...props }) => {
    const {
      //   id,
      title,
      //   desc,
      deadline,
      section,
      //   selectedGroups,
      employees,
      signedEmployees,
      documents,
      //   sectionId,
    } = task;
    const { open, close } = useModal();
    const permissions = useStore($UserAddPermissions);
    let deadline2 = new Date(deadline);
    const month = DateToStringMonth(deadline2);
    const formattedDate = `${deadline2.getDate()} ${month}, ${(
      "0" + deadline2.getHours()
    ).slice(-2)}:${("0" + deadline2.getMinutes()).slice(-2)}`;
    const isExpired = new Date().getTime() > deadline2.getTime();
    const handleTaskCreation = () => {
      close();
    };

    const onClickHandler = () => {
      open("CreateTaskModal", {
        btnText: "Сохранить",
        modalData: {
          modalTitle: "Редактировать задачу",
          editMode: true,
          event: {
            ...task,
            eventId: task.id,
          },
        },
        onConfirm: handleTaskCreation,
      });
    };

    return (
      <div
        className={clsx(style.kanban_column_card)}
        {...((task.withSigning
          ? permissions.hasPermission(
              ACTIONS.companyDocumentSigningControl_allowedToEdit
            )
          : permissions.hasPermission(
              ACTIONS.companySpecialistWorkPlan_tasks_allowedToEdit
            )) && {
          onClick: () => onClickHandler(),
        })}
        {...props}
      >
        <p className={style.kanban_column_title}>{title}</p>
        <p
          className={clsx(style.kanban_column_date, {
            [style.bg__red]: isExpired,
          })}
        >
          {formattedDate}
        </p>
        <p className={style.kanban_column_section}>
          Раздел: <span>{section}</span>
        </p>
        <div className={style.kanban_column_stats}>
          <div>
            <PeopleSVG />
            <span>
              {typeof employees === "object"
                ? employees
                  ? employees[0]
                    ? employees.length
                    : "0"
                  : "0"
                : "0"}
            </span>
            <FileSVG />
            <span>
              {typeof documents === "object"
                ? documents
                  ? documents[0]
                    ? documents.length
                    : "0"
                  : "0"
                : "0"}
            </span>
          </div>
          <div>
            Ознакомлены: {signedEmployees ? signedEmployees.length : "0"} из{" "}
            {typeof employees === "object"
              ? employees
                ? employees[0]
                  ? employees.length
                  : "0"
                : "0"
              : "0"}
          </div>
        </div>
      </div>
    );
  }
);

const SigningControlKanban = memo(() => {
  const { rows } = useStore($SpecialistPlans);
  const [, setRenderedRows] = useState(rows);
  const { open, close } = useModal();
  const [kanban, setKanban] = useState<ColumnT[]>([]);
  const [currentColumn, setCurrentColumn] = useState<ColumnT | null>(null);
  const [currentCard, setCurrentCard] = useState<CardT | null>(null);
  const employees = useStore($Employees);
  const permissions = useStore($UserAddPermissions);

  const { company } = useSelectedCompanyQuery();

  useEffect(() => {
    if (!company) return;

    fetchSpecialistPlans({
      companies: [company.id],
      groups: null,
      statuses: null,
      startDate: null,
      endDate: null,
      count: true,
      offset: 0,
      limit: 1000,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    fetchEmployees();
  }, []);

  useEffect(() => {
    setRenderedRows(rows);
  }, [rows]);

  useEffect(() => {
    let tasks_created: CardT[] = [];
    let tasks_signing: CardT[] = [];
    let tasks_signed: CardT[] = [];
    let tasks_checked: CardT[] = [];

    rows.forEach((section) => {
      section.tasks.forEach((task) => {
        if (task.withSigning) {
          switch (task.status) {
            case 1:
              tasks_created.push({
                ...task,
                section: section.title,
                sectionId: section.id,
              });
              break;
            case 2:
              tasks_signing.push({
                ...task,
                section: section.title,
                sectionId: section.id,
              });
              break;
            case 3:
              tasks_signed.push({
                ...task,
                section: section.title,
                sectionId: section.id,
              });
              break;
            case 4:
              tasks_checked.push({
                ...task,
                section: section.title,
                sectionId: section.id,
              });
              break;
          }
        }
      });
    });
    const newKanban: ColumnT[] = KANBAN_STRUCT;
    newKanban[0].items = tasks_created;
    newKanban[1].items = tasks_signing;
    newKanban[2].items = tasks_signed;
    newKanban[3].items = tasks_checked;

    setKanban(newKanban);
  }, [rows]);

  const handleTaskCreation = () => {
    close();
  };

  const openCreateTaskModal = (groupId: number) => {
    open("CreateTaskModal", {
      btnText: "Создать",
      modalData: { modalTitle: "Создать задачу", groupId },
      onConfirm: handleTaskCreation,
    });
  };

  const dragOverHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.className.indexOf("card") !== -1) {
      // e.target.style.boxShadow = '0 4px 3px gray'
    }
  };

  const dragLeaveHandler = (e: any) => {
    // e.target.style.boxShadow = 'none'
  };

  const dragStartHandler = (e: any, column: any, card: any) => {
    setCurrentColumn(column);
    setCurrentCard(card);
  };

  const dragEndHandler = (e: any) => {
    // e.target.style.boxShadow = 'none'
  };

  const dropHandler = (e: any, column: any, card: CardT) => {
    e.preventDefault();
    e.stopPropagation();
    if (!company) return;

    if (column.id === currentColumn!.id) return;

    SpecialistPlanService.updateTask(
      company.id,
      currentCard!.id,
      { status: column.id },
      (err, res) => {
        if (err || !res) {
          return console.error("При изменении задачи произошла ошибка");
        } else {
          // updatePlanTask({ ...card, status: column.id})
          if (currentColumn) {
            const currentIndex = currentColumn!.items.indexOf(currentCard!);
            currentColumn!.items.splice(currentIndex, 1);
          }
          const dropIndex = column.items.indexOf(card);
          column.items.splice(dropIndex + 1, 0, {
            ...currentCard,
            status: column.id,
          });
          setKanban(
            kanban.map((c) => {
              if (c.id === column.id) {
                return column;
              }
              if (c.id === currentColumn!.id) {
                return currentColumn;
              }
              return c;
            })
          );
        }
      }
    );
    if (column.id === 2) {
      const selectedEmployeeIds = currentCard!.employees.map((emp) => +emp);
      const selectedEmployeeIdsAndPos = employees.reduce(
        (result: any, employee) => {
          if (selectedEmployeeIds.includes(employee.id)) {
            return result.concat({
              employeeId: employee.id,
              positionId: employee.position?.id,
            });
          }
          return result;
        },
        []
      );
      const selectedDocIds = currentCard!.documents.map((doc) => +doc);
      EmployeeService.SendForSignatureToMany(
        selectedEmployeeIdsAndPos,
        selectedDocIds,
        currentCard!.id,
        () => {}
      )
        .then(() => {
          open("NotificationModal", {
            modalData: {
              modalTitle: "",
              text: "Документы отправлены",
            },
          });
        })
        .catch(() => {
          console.error(
            "При отправке документов на подпимсание произошла ошибка"
          );
        });
    }
  };

  const dropCardHandler = (e: any, column: any) => {
    e.preventDefault();
    if (!company) return;

    if (column.id === currentColumn!.id) return;
    SpecialistPlanService.updateTask(
      company.id,
      currentCard!.id,
      { status: column.id },
      (err, res) => {
        if (err || !res) {
          return console.error("При изменении задачи произошла ошибка");
        }
        column.items.push({ ...currentCard, status: column.id });
        const currentIndex = currentColumn!.items.indexOf(currentCard!);
        currentColumn!.items.splice(currentIndex, 1);
        setKanban(
          kanban.map((c) => {
            if (c.id === column.id) {
              return column;
            }
            if (c.id === currentColumn!.id) {
              return currentColumn;
            }
            return c;
          })
        );
      }
    );
    if (column.id === 2) {
      const selectedEmployeeIds = currentCard!.employees.map((emp) => +emp);
      const selectedEmployeeIdsAndPos = employees.reduce(
        (result: any, employee) => {
          if (selectedEmployeeIds.includes(employee.id)) {
            return result.concat({
              employeeId: employee.id,
              positionId: employee.position?.id,
            });
          }
          return result;
        },
        []
      );
      const selectedDocIds = currentCard!.documents.map((doc) => +doc);
      EmployeeService.SendForSignatureToMany(
        selectedEmployeeIdsAndPos,
        selectedDocIds,
        currentCard!.id,
        () => {}
      )
        .then(() => {
          open("NotificationModal", {
            modalData: {
              modalTitle: "",
              text: "Документы отправлены",
            },
          });
        })
        .catch((err) => {
          console.error(
            "При отправке документов на подпимсание произошла ошибка"
          );
        });
    }
  };

  return (
    <div className={clsx(style.kanban)}>
      {kanban.map((column, index) => (
        <div
          className={clsx(style.kanban_column)}
          onDragOver={(e) => dragOverHandler(e)}
          onDrop={(e) => dropCardHandler(e, column)}
          key={index}
        >
          <div
            className={clsx(
              style.kanban_column_header,
              style[`bg__s${column.id}`]
            )}
          >
            {`${column.title} (${column.items.length})`}
            {index === 0 &&
              permissions.hasPermission(
                ACTIONS.companyDocumentSigningControl_allowedToCreate
              ) && (
                <div
                  onClick={() => openCreateTaskModal(column.id)}
                  className={clsx(style.plus_icon)}
                >
                  <PlusSVG />
                </div>
              )}
          </div>
          {column.items.map((card: CardT, index) => {
            return (
              <Card
                key={card.id}
                task={card}
                eventId={card.id}
                draggable={true}
                onDragOver={(e) => {
                  dragOverHandler(e);
                }}
                onDragLeave={(e) => {
                  dragLeaveHandler(e);
                }}
                onDragStart={(e) => {
                  dragStartHandler(e, column, card);
                }}
                onDragEnd={(e) => {
                  dragEndHandler(e);
                }}
                onDrop={(e) => dropHandler(e, column, card)}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
});

export default SigningControlKanban;
