import { BreadCrumb, TabItems } from "@components/common";
import { Route, Switch } from "react-router-dom";

import { PermissionsRoles } from "./permissions-roles";
import { PermissionsUsers } from "./permissions-users";
import { Title } from "@components/common/common";

export enum ResponsibilityRequestTypeEnum {
  RESPONSIBILITY = "RESPONSIBILITY",
  LEGAL_INFORMATION = "LEGAL_INFORMATION",
}

const Permissions: React.FC = () => {
  const TAB_ITEMS: { label: string; path: string; exact?: boolean }[] = [
    { label: "Роли", path: "/permissions", exact: true },
    { label: "Пользователи", path: "/permissions/users", exact: true },
  ];

  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={["Права пользователей"]} />
          <Title text="Права пользователей" />
        </div>
        <div className="tab-section">
          <div className="tab-cont">
            <TabItems items={TAB_ITEMS} />
            <div className="tab-content-items">
              <Switch>
                <Route
                  path={"/permissions"}
                  exact
                  component={PermissionsRoles}
                />
                <Route path="/permissions/users" component={PermissionsUsers} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Permissions;
