import { useQuery } from "react-query";
import { studyCenterService } from "@services/study-center";
import { studyCenterQueryKeys } from "./queryKeys";

export const useStudyCenterEducationalEventQuery = (id: number | undefined) => {
  const { data: event, ...stuff } = useQuery(
    studyCenterQueryKeys.educationalEventById(id!),
    () => studyCenterService.getEventById(id!),
    {
      enabled: typeof id !== "undefined",
    }
  );

  return {
    event,
    ...stuff,
  };
};
