import {
  ArticleType,
  getServiceByType,
  LegislationNewsT,
} from "@interfaces/legislation-news";
import { NewsParams } from "@interfaces/news";
import { getNextPage, getPreviousPage } from "@utils/pagination";
import { QueryClient, useInfiniteQuery } from "react-query";
import { newsQueryKeys } from "./queryKeys";

export type InfiniteNewsQueryResult = {
  rows: LegislationNewsT[];
  page: number;
  totalCount: number;
};

export const useNewsQuery = (
  params: NewsParams,
  options?: { enabled?: boolean }
) => {
  const { enabled = true } = options || {};

  return useInfiniteQuery(
    newsQueryKeys.paginatedNews(params),
    async ({ pageParam = 1 }) => {
      return new Promise<InfiniteNewsQueryResult>((resolve, reject) => {
        const service = getServiceByType(params.type);
        service.getAll(
          {
            limit: params.limit,
            offset: params.limit * (pageParam - 1),
            count: true,
          },
          (err, res) => {
            if (err || !res) {
              reject(err);
            } else {
              resolve({
                rows: res.data.rows,
                page: pageParam,
                totalCount: res.data.count ?? 0,
              });
            }
          }
        );
      });
    },
    {
      getNextPageParam: ({ page, totalCount }) =>
        getNextPage(page, params.limit, totalCount),
      getPreviousPageParam: ({ page }) => getPreviousPage(page),
      enabled,
    }
  );
};

export const invalidateNewsQuery = (
  queryClient: QueryClient,
  type: ArticleType
) => {
  queryClient.invalidateQueries(newsQueryKeys.newsByType(type));
};
