import {
  $RolesPermissions,
  $UserAddPermissions,
  ACTIONS,
  UserRoleEnum,
} from "@store/user-store";
import {
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { pushToWorkersData, updateWorkerData } from "@store/workers-store";
import { selectColourStyles, selectTheme } from "@components/common/common";

import ReactSelect from "react-select";
import { RolesNPermissionsT } from "@interfaces/store/users-store-types";
import { SimpleOptionT } from "@interfaces/company/employees";
import Validator from "@utils/validator";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import WorkerService from "@services/worker-service";
import makeAnimated from "react-select/animated";
import passwordGenerator from "generate-password";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import useStyles from "@ui/material-ui-styles";
import { useCompaniesQuery, useSelectedCompanyQuery } from "@lib/company";
import { useQueryClient } from "react-query";
import { companyQueryKeys } from "@lib/company/queryKeys";

const animatedComponents = makeAnimated();

const CreateUserModal: React.FC = () => {
  const queryClient = useQueryClient();

  const { companies } = useCompaniesQuery();
  const { company: selectedCompany } = useSelectedCompanyQuery();

  const permissions = useStore($UserAddPermissions);
  const rolesNPermissions = useStore($RolesPermissions) as RolesNPermissionsT;
  const [companyOptions, setCompanyOptions] = useState<SimpleOptionT[]>([]);
  const [selectedCompanyOptions, setCompanySelectedOptions] = useState<
    SimpleOptionT[]
  >([]);
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);
  const [validation, setValidation] = useState({
    nameError: "",
    emailError: "",
    passwordError: "",
    roleError: "",
  });
  const [value, setValue] = useState({
    roleId: 4,
    password: "",
    showPassword: false,
  });

  const { close, modalComponent, modalData } = useModal();
  const classes = useStyles();

  useEffect(() => {
    setCompanyOptions(
      companies?.map((company) => ({
        value: company.id,
        label: company.name,
      })) ?? []
    );
    if (modalData.worker) {
      setValue({ ...value, roleId: modalData.worker.roleId });
      setSelectedCompanies(
        modalData.worker.companies.map((company: { id: number }) => company.id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  useEffect(() => {
    setCompanySelectedOptions(
      companyOptions.filter(
        (company) =>
          selectedCompanies?.find(
            (selectedCompanyId) => selectedCompanyId === company.value
          )
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanies]);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue({ ...value, password: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValue({ ...value, showPassword: !value.showPassword });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue({ ...value, roleId: event.target.value as number });
  };

  const generatePassword = () => {
    setValue({
      ...value,
      password: passwordGenerator.generate({ length: 10, numbers: true }),
    });
  };

  const handleSubmit = (e: any) => {
    if (!selectedCompany) return;

    e.preventDefault();
    const modalBtn = document.querySelector(
      "button.modal_btn"
    ) as HTMLButtonElement;
    modalBtn.disabled = true;

    const nameField = document.querySelector(
      'input[name="name"]'
    ) as HTMLInputElement;
    const emailField = document.querySelector(
      'input[name="email"]'
    ) as HTMLInputElement;

    const validating = {
      ...Validator(nameField?.value || "", "name")
        .isLength({ min: 3 }, "Это поле обязательно")
        .getErrors(),
      ...Validator(emailField?.value || "", "email")
        .isEmail("Некорректный адрес эл. почты")
        .getErrors(),
      // IF ACTIVE EDIT MODE, THEN DONT CHECK PASSWORD, BECAUSE THIS FIELD IS OPTIONAL FOR EDIT MODE
      ...Validator(
        modalData.worker && value.password.length === 0
          ? "111111"
          : value.password,
        "password"
      )
        .isLength(
          { min: 6, max: 40 },
          "Пароль должен содержать не менее 6 символов"
        )
        .getErrors(),
    };

    if (Validator.hasError(validating)) {
      modalBtn.disabled = false;
      return setValidation(validating as any);
    }

    if (modalData.worker) {
      const updatedData = {
        name: nameField.value,
        email: emailField.value,
        password: value.password.length > 0 ? value.password : undefined,
        roleId: value.roleId,
        companies: selectedCompanies,
      };

      return WorkerService.UpdateWorker(
        modalData.worker.id,
        updatedData,
        (err, res) => {
          if (err || !res) {
            if (err?.response?.status === 422) {
              modalBtn.disabled = false;
              return setValidation(err.response.data);
            }
            return console.error(
              "При обновлении данных пользователя произошла ошибка"
            );
          }

          queryClient.invalidateQueries(companyQueryKeys.all);
          updateWorkerData(res.data);
          close();
        }
      );
    }

    const newData = {
      name: nameField.value,
      email: emailField.value,
      password: value.password,
      roleId: value.roleId,
      companies: selectedCompanies,
    };

    // ADDING NEW WORKER
    WorkerService.AddNewWorker(newData, (err, res) => {
      if (err || !res) {
        if (err?.response?.status === 422) {
          modalBtn.disabled = false;
          return setValidation(err.response.data);
        }
        return console.error(
          "При добавлении нового пользователя произошла ошибка"
        );
      }
      queryClient.invalidateQueries(companyQueryKeys.all);
      pushToWorkersData(res.data);
      close();
    });
  };

  const onOptionSelect = (options: SimpleOptionT[]) => {
    setSelectedCompanies(options.map((option) => option.value));
  };

  const isDisabled =
    (value.roleId === UserRoleEnum.SuperAdminMinus &&
      !permissions.hasPermission(
        ACTIONS.roles_levelFirstRoles_allowedToAssign
      )) ||
    (value.roleId === UserRoleEnum.Admin &&
      !permissions.hasPermission(
        ACTIONS.roles_levelSecondRoles_allowedToAssign
      )) ||
    (value.roleId === UserRoleEnum.Client &&
      !permissions.hasPermission(
        ACTIONS.roles_levelThirdRoles_allowedToAssign
      )) ||
    (value.roleId === UserRoleEnum.Worker &&
      !permissions.hasPermission(
        ACTIONS.roles_levelFourthRoles_allowedToAssign
      )) ||
    value.roleId === UserRoleEnum.SuperAdmin;

  const checkForPermission = (roleId: number) => {
    switch (roleId) {
      case UserRoleEnum.SuperAdminMinus:
        return !permissions.hasPermission(
          ACTIONS.roles_levelFirstRoles_allowedToAssign
        );
      case UserRoleEnum.Admin:
        return !permissions.hasPermission(
          ACTIONS.roles_levelSecondRoles_allowedToAssign
        );
      case UserRoleEnum.Client:
        return !permissions.hasPermission(
          ACTIONS.roles_levelThirdRoles_allowedToAssign
        );
      case UserRoleEnum.Worker:
        return !permissions.hasPermission(
          ACTIONS.roles_levelFourthRoles_allowedToAssign
        );
    }
  };
  return (
    <div key={modalComponent.key} style={{ maxWidth: "650px" }}>
      <p className="modal_title">{modalData.modalTitle}</p>
      <div className="underline" />
      <form className={`modal_content ${classes.root}`} onSubmit={handleSubmit}>
        <TextField
          label="Имя"
          name={"name"}
          variant="filled"
          required
          defaultValue={modalData.worker?.name || ""}
        />
        <p className="error-text" style={{ maxWidth: "100%", width: "650px" }}>
          {validation.nameError}
        </p>
        <TextField
          label="Email"
          name={"email"}
          variant="filled"
          required
          defaultValue={modalData.worker?.email || ""}
        />
        <p className="error-text">{validation.emailError}</p>
        <FormControl variant="filled">
          <InputLabel htmlFor="filled-adornment-password">Пароль</InputLabel>
          <FilledInput
            type={value.showPassword ? "text" : "password"}
            value={value.password}
            onChange={handlePasswordChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {value.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <p className="error-text">{validation.passwordError}</p>
        <button
          type="button"
          className="white-btn px-20 mt-25"
          onClick={generatePassword}
        >
          Сгенерировать пароль
        </button>
        <ReactSelect
          closeMenuOnSelect={true}
          components={animatedComponents}
          isMulti
          value={selectedCompanyOptions}
          options={companyOptions}
          isSearchable
          menuPlacement="top"
          menuPortalTarget={document.body}
          placeholder={"Компании"}
          onChange={(values) => onOptionSelect(values as any)}
          styles={selectColourStyles({ zIndex: 2 })}
          theme={selectTheme}
          isDisabled={
            !permissions.hasPermission(ACTIONS.companies_allowedToAssignUsers)
          }
        />
        {rolesNPermissions && (
          <FormControl
            variant="filled"
            disabled={modalData.worker ? isDisabled : false}
          >
            <InputLabel id="demo-simple-select-filled-label">Роль</InputLabel>
            <Select
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={value.roleId}
              onChange={handleRoleChange}
            >
              {rolesNPermissions.roles.map((role) => {
                const isOptionDisabled = checkForPermission(role.id);
                return (
                  <MenuItem disabled={isOptionDisabled} value={role.id}>
                    {role.name}
                  </MenuItem>
                );
              })}

              {/* <MenuItem
                            disabled={!permissions.hasPermission(ACTIONS.roles_levelSecondRoles_allowedToCreate)}
                            value={UserRoleEnum.Admin}
                        >
                            Администратор</MenuItem>
                        <MenuItem
                            disabled={!permissions.hasPermission(ACTIONS.roles_levelThirdRoles_allowedToCreate)}
                            value={UserRoleEnum.Client}
                        >
                            Клиент</MenuItem>
                        <MenuItem
                            disabled={!permissions.hasPermission(ACTIONS.roles_levelFourthRoles_allowedToCreate)}
                            value={UserRoleEnum.Worker}
                        >
                            Специалист по ОТ</MenuItem> */}
            </Select>
          </FormControl>
        )}
        <p className="error-text">{validation.roleError}</p>
        <button type="submit" className="modal_btn">
          {modalComponent.btnText}
        </button>
      </form>
    </div>
  );
};

export default CreateUserModal;
