import { $UserAddPermissions, UserPriorityEnum } from "@store/user-store";
import { CheckboxCheckedSVG, CheckboxSVG } from "@components/svgs";
import React, { useState } from "react";

import { Checkbox } from "@material-ui/core";
import { ColorfulButton } from "@components/common/common";
import SpecialistPlanService from "@services/specialist-plan-service";
import { useModal } from "@modals/index";
import { useStore } from "effector-react";
import useStyles from "@ui/material-ui-styles";
import { useQueryClient } from "react-query";
import { workplanQueryKeys } from "@lib/workplan/queryKeys";

const CATEGORY_LIST = [
  { label: "Основные документы", value: 1 },
  { label: "Обучение по охране труда", value: 2 },
  { label: "Инструктаж по охране труда", value: 3 },
  { label: "Первая помощь", value: 4 },
  { label: "Средства индивидуальной защиты", value: 5 },
  { label: "Смывающие и обезвреживающие средства", value: 6 },
  { label: "Медицинские осмотры", value: 7 },
  { label: "Психиатрические освидетельствования", value: 8 },
  { label: "Расследование несчастных случаев на производстве", value: 9 },
  { label: "Электробезопасность", value: 10 },
  { label: "Пожарная безопасность", value: 11 },
  { label: "Работы на высоте", value: 12 },
  { label: "Транспорт", value: 13 },
  { label: "Погрузочно-разгрузочные работы", value: 14 },
  { label: "Инструмент", value: 15 },
  { label: "Стеллажи", value: 16 },
  { label: "Прочие задачи", value: 17 },
];

const AddPlanGroupModal = () => {
  const queryClient = useQueryClient();

  const { modalComponent, modalData, close, open } = useModal();
  const [chosedCategory] = useState<{
    label: string;
    value: number;
  }>({ label: "", value: 0 });
  const [chosedCategories, setChosedCategories] = useState<string[]>([]);
  const classes = useStyles();
  const permissions = useStore($UserAddPermissions);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!chosedCategories.length) return;

    if (modalData.editMode) {
      if (!modalData.itemId) {
        throw new Error("Отсутствует идентфикатор для редактирования данных");
      }
      SpecialistPlanService.updateGroup(
        modalData.companyId,
        modalData.itemId,
        chosedCategory.label,
        (err, res) => {
          if (err || !res) {
            return console.error(
              "При изменении данных группы произошла ошибка"
            );
          }
          queryClient.invalidateQueries(workplanQueryKeys.all);
          close();
        }
      );
      return;
    }
    // CREATING GROUP
    // if (chosedCategory.label === "Прочие задачи") {
    SpecialistPlanService.createGroup(
      modalData.companyId,
      chosedCategories.map((title) => ({ title })),
      (err, res) => {
        if (err || !res) {
          return console.error("При создании группы произошла ошибка");
        }
        queryClient.invalidateQueries(workplanQueryKeys.all);
      }
    );

    close();
  };

  const choseGroup = (category: any) => {
    const isChosed = chosedCategories.includes(category.label);
    if (isChosed) {
      setChosedCategories(
        chosedCategories.filter((title) => title !== category.label)
      );
    } else {
      setChosedCategories([...chosedCategories, category.label]);
    }
  };

  return (
    <div key={modalComponent.key}>
      <p className="modal_title">{modalData.modalTitle}</p>
      <div className="underline" />
      <form className={`modal_content ${classes.root}`} onSubmit={handleSubmit}>
        {CATEGORY_LIST.filter(
          (c) => !modalData.categories.includes(c.label)
        ).map((category, index: number) => {
          return (
            <div key={index}>
              <Checkbox
                checked={chosedCategories.includes(category.label)}
                onChange={(e) => choseGroup(category)}
                color="primary"
                icon={<CheckboxSVG />}
                checkedIcon={<CheckboxCheckedSVG />}
              />
              {category.label}
            </div>
          );
        })}
        {permissions.hasRequiredLevel(UserPriorityEnum.Admin) && (
          <div style={{ marginTop: "16px" }}>
            <ColorfulButton
              text="Создать новую категорию"
              onClick={() =>
                open("CreatePlanGroupModal", {
                  btnText: "Создать",
                  modalData: {
                    modalTitle: "Создать категорию",
                    companyId: modalData.companyId,
                  },
                })
              }
            />
          </div>
        )}

        <button
          type="submit"
          className="modal_btn"
          disabled={!chosedCategories.length}
        >
          {modalComponent.btnText}
        </button>
      </form>
    </div>
  );
};

export default AddPlanGroupModal;
