import {
  GetGeneralMedicalExamControlsParams,
  GetMedicalExamControlsParams,
  GetMedicalExamEmployeeReferralParams,
  GetMedicalExaminationFactorsParams,
} from "@interfaces/medical-examination";

export const medicalExaminationQueryKeys = {
  all: ["medical-examination"],
  categories: () => [...medicalExaminationQueryKeys.all, "categories"],
  factors: (params?: GetMedicalExaminationFactorsParams) => [
    ...medicalExaminationQueryKeys.all,
    "factors",
    params,
  ],
  hasChanges: () => [...medicalExaminationQueryKeys.all, "hasChanges"],
  controls: (params: GetMedicalExamControlsParams) => [
    ...medicalExaminationQueryKeys.all,
    "controls",
    params,
  ],
  generalControls: (params: GetGeneralMedicalExamControlsParams) => [
    ...medicalExaminationQueryKeys.all,
    "general-controls",
    params,
  ],
  employeeReferral: (params: GetMedicalExamEmployeeReferralParams) => [
    ...medicalExaminationQueryKeys.all,
    "employee-referral",
    params,
  ],
};
