import LegalInformationService from "@services/legal-information-service";
import { useMutation } from "react-query";

export const useDeleteLegalInformationFileMutation = () => {
  return useMutation((id: number) => {
    return new Promise((resolve, reject) => {
      LegalInformationService.DeleteFile(id, (err, res) => {
        if (err || !res) {
          reject(err);
        } else {
          resolve(res.data);
        }
      });
    });
  });
};
