import { useMutation, useQueryClient } from "react-query";
import {
  CreateInternalMaterialData,
  internalStudyCenterService,
} from "@services/internal-study-center-service";
import { internalStudyCenterQueryKeys } from "./queryKeys";

export const useCreateInternalMaterialMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: CreateInternalMaterialData) =>
      internalStudyCenterService.createMaterial(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(internalStudyCenterQueryKeys.all);
      },
    }
  );
};
