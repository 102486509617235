import { memo, useEffect, useState } from "react";

import { ArrowDropDownSVG } from "@components/svgs";
import clsx from "clsx";

import s from "./drop-down-list.module.scss";

type Props = {
  options: {
    label: string;
    value: string | number;
  }[];
  onChange?: Function;
  chosedValue?: string | number | undefined;
};

const DropDownList: React.FC<Props> = memo(
  ({ options, onChange = () => {}, chosedValue }) => {
    const onChangeHandler = (e: any) => {
      onChange(e.target.value);
    };
    const [isOpened, setIsOpened] = useState(false);
    const [value, setValue] = useState(chosedValue);

    useEffect(() => {
      setValue(chosedValue);
    }, [chosedValue]);

    return (
      <div className={clsx(s.dropDown_container)}>
        <select
          onFocus={() => setIsOpened(true)}
          onBlur={() => setIsOpened(false)}
          className={s.dropDown}
          onChange={onChangeHandler}
          value={value}
          name=""
          id=""
        >
          {options.map(({ label, value }, index) => {
            return (
              <option key={index} value={value} label={label}>
                {label}
              </option>
            );
          })}
        </select>
        <div
          className={clsx(s.arrow_icon, { [s.arrow_icon__opened]: isOpened })}
        >
          <ArrowDropDownSVG />
        </div>
      </div>
    );
  }
);

export default DropDownList;
