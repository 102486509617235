import { useQuery } from "react-query";
import { WorkplanService } from "@services/workplan-service";
import { workplanQueryKeys } from "./queryKeys";
import { GetWorkplanGroupsParams } from "@interfaces/workplan";

export const useWorkplanGroupsQuery = (params: GetWorkplanGroupsParams) => {
  return useQuery(
    workplanQueryKeys.groups(params),
    () => WorkplanService.getGroups(params),
    {
      enabled: params.companies.length > 0,
    }
  );
};
