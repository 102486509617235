import { GetCompaniesParams } from "@interfaces/company/company";
import { companyService } from "@services/company-service";
import { useQuery } from "react-query";
import { companyQueryKeys } from "./queryKeys";

export const useCompaniesQuery = (params?: GetCompaniesParams) => {
  const { data: companies, ...stuff } = useQuery(
    companyQueryKeys.allWithParams(params),
    () => companyService.getAll(params)
  );

  return {
    companies,
    ...stuff,
  };
};
