import { HTMLAttributes } from "react";

type SVGPropsT = {
  width?: string;
};

export const FileJustSVG: React.FC<
  HTMLAttributes<HTMLOrSVGElement> & SVGPropsT
> = ({ width = "14", ...props }) => {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5625 0H0.4375C0.196 0 0 0.196 0 0.4375V13.5625C0 13.804 0.196 14 0.4375 14H9.1875C9.30344 14 9.415 13.9541 9.49681 13.8718L13.8718 9.49681C13.9541 9.415 14 9.30344 14 9.1875V0.4375C14 0.196 13.804 0 13.5625 0ZM9.625 12.5064V9.625H12.5064L9.625 12.5064ZM13.125 8.75H9.1875C8.946 8.75 8.75 8.946 8.75 9.1875V13.125H0.875V0.875H13.125V8.75Z"
        fill="#8D96A5"
      />
    </svg>
  );
};
