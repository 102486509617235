import {
  $RolesPermissionsStates,
  $User,
  $UserAddPermissions,
  ACTIONS,
  UserPriorityEnum,
  fetchRolesPermissions,
} from "@store/user-store";
import { $WorkersDataStates, getWorkers } from "@store/workers-store";
import { ColorfulButton, TabItems, Title } from "@components/common/common";
import { Route, Switch, useLocation } from "react-router-dom";

import { BreadCrumb } from "@components/common";
import { PermissionsRoles } from "@pages/permissions/permissions-roles";
import { PermissionsUsers } from "@pages/permissions/permissions-users";
import { UserDataT } from "@interfaces/user";
import { useEffect } from "react";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import { useSelectedCompanyQuery } from "@lib/company";

const Users = () => {
  const user = useStore($User) as UserDataT;
  const permissions = useStore($UserAddPermissions);
  const { isFetched } = useStore($WorkersDataStates);

  const { company } = useSelectedCompanyQuery();

  const TAB_ITEMS: { label: string; path: string; exact?: boolean }[] = [
    { label: "Пользователи", path: "/settings/users", exact: true },
  ];
  if (
    permissions.hasRequiredLevel(UserPriorityEnum.Admin) ||
    permissions.hasPermission(ACTIONS.roles_allowedToShow)
  ) {
    TAB_ITEMS.push({ label: "Роли", path: "/settings/roles", exact: true });
  }

  const { open } = useModal();

  useEffect(() => {
    if (!isFetched) {
      getWorkers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    isFetched: isFetchedRoles,
    isLoading,
    isFetchedCompany,
  } = useStore($RolesPermissionsStates);
  const location = useLocation();
  const IS_COMPANY_MODULE = location.pathname.includes("company");
  useEffect(() => {
    if (!company) return;

    const doesNeedToFetch =
      (!isFetchedCompany && !isLoading && IS_COMPANY_MODULE) ||
      (!isFetchedRoles && !isLoading && !IS_COMPANY_MODULE);

    if (doesNeedToFetch) {
      fetchRolesPermissions(
        IS_COMPANY_MODULE
          ? {
              exactCompany: true,
              companyId: company.id,
              priority: user.role.priority,
            }
          : { priority: user.role.priority }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={["Главная", "Настройки ЛК"]} />
          <div className="content-title-section-with-btn">
            <Title text="Настройки ЛК" />
            {permissions.hasPermission(ACTIONS.users_allowedToCreate) ? (
              <ColorfulButton
                text="Создать пользователя"
                onClick={() =>
                  open("CreateUserModal", {
                    btnText: "Создать",
                    modalData: { modalTitle: "Создать пользователя" },
                  })
                }
              />
            ) : null}
          </div>
        </div>
        <div className="tab-section">
          <div className="tab-cont">
            <TabItems items={TAB_ITEMS} />
            <div className="tab-content-items">
              <Switch>
                {permissions.hasRequiredLevel(UserPriorityEnum.Admin) && (
                  <Route
                    path={"/settings/roles"}
                    exact
                    component={PermissionsRoles}
                  />
                )}
                <Route
                  path={"/settings/users"}
                  exact
                  component={PermissionsUsers}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Users;
