import { ArticleType, getServiceByType } from "@interfaces/legislation-news";
import { useMutation, useQueryClient } from "react-query";
import { invalidateNewsQuery } from "./useNewsQuery";

export type UpdateNewsData = {
  id: string;
  data: FormData;
};

export const useUpdateNewsMutation = (type: ArticleType) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data: UpdateNewsData) => {
      return new Promise((resolve, reject) => {
        const service = getServiceByType(type);
        service.update(data.id, data.data, (err, res) => {
          if (err || !res) {
            reject(err);
          } else {
            resolve(res.data);
          }
        });
      });
    },
    {
      onSuccess: () => {
        invalidateNewsQuery(queryClient, type);
      },
    }
  );
};
