import React from "react";
import { Button } from "@ui/button";

type GroupSwitchItem = {
  value: string;
  label?: string;
};

export type GroupSwitchProps = Omit<
  JSX.IntrinsicElements["div"],
  "style" | "onChange"
> & {
  data: GroupSwitchItem[];
  value: string;
  onChange: (value: string) => void;
};

export const GroupSwitch: React.FC<GroupSwitchProps> = ({
  data,
  value,
  onChange,
  ...props
}) => {
  // NOTE: я добавил стили тут, потому что получал ошибку если клал их в .module.scss файл рядом с этим файлом
  // а класть его в scss директорию было лень
  // здесь не так много стилей anyway, так что пойдёт
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        flexWrap: "wrap",
      }}
      {...props}
    >
      {data.map((item) => (
        <Button
          color={value === item.value ? "green" : "white"}
          border
          borderColor="green"
          onClick={() => onChange(item.value)}
        >
          {item.label ?? item.value}
        </Button>
      ))}
    </div>
  );
};
