import React, { useEffect } from "react";
import { useStore } from "effector-react";
import clsx from "clsx";

import { DepartmentsTable } from "@components/tables";
import useModal from "@modals/modal-hook";
import { ColorfulButton } from "@components/common/common";

import { $UserAddPermissions, ACTIONS, setModule } from "@store/user-store";

import { CompanyTabPropsT } from "@interfaces/company/company";
import { Modules } from "@interfaces/common";

import style from "@scss/pages/company/departments.module.scss";
import { fetchDepartments } from "@store/company/departments-store";

const Departments: React.FC<CompanyTabPropsT> = () => {
  const permissions = useStore($UserAddPermissions);
  const { open } = useModal();

  useEffect(() => {
    setModule(Modules.SUBDIVISION);
  }, []);

  useEffect(() => {
    fetchDepartments();
  }, []);

  return (
    <div className="tab-content-item">
      <div className={clsx(style.department_top_panel)}>
        {
          // permissions.roleIsIn([UserRoleEnum.Client], true) ? null :
          permissions.hasPermission(
            ACTIONS.companyStaffing_departments_allowedToCreate
          ) && (
            <ColorfulButton
              text={"Добавить подразделение"}
              onClick={() =>
                open("CreateDepartmentModal", {
                  btnText: "Добавить",
                  modalData: {
                    modalTitle: "Добавить подразделение",
                    fieldTitle: "Название",
                  },
                })
              }
            />
          )
        }
      </div>
      <DepartmentsTable />
    </div>
  );
};

export default Departments;
