import {
  $MonthEvents,
  fetchEvents,
  fetchMonthEvents,
  resetNotificationEvents,
  setMonthEventsData,
} from "@store/company/event-store";
import {
  $User,
  $UserAddPermissions,
  ACTIONS,
  setModule,
} from "@store/user-store";
import Calendar, { CalendarTileProperties, Detail } from "react-calendar";
import {
  CalendarEventItems,
  getMonth,
  setCalendarArrowsOpacity,
} from "@components/company";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { CheckboxCheckedSVG, CheckboxSVG } from "@components/svgs";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";

import { $Workers } from "@store/workers-store";
import { CompanyTabPropsT } from "@interfaces/company/company";
import DropDownList from "@ui/drop-down-list";
import EventService from "@services/event-service";
import { EventT } from "@interfaces/company/event";
import { Modules } from "@interfaces/common";
import { UserDataT } from "@interfaces/user";
import clsx from "clsx";
import { localStorageUse } from "@utils/localStorageUse";
import moment from "moment";
import style from "@scss/pages/company/company-calendar.module.scss";
import { switchCompany } from "@store/company/company-store";
import { useCompaniesQuery } from "@lib/company";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";

type CalendarProps = {
  isCompanyDefined: boolean;
  defaultCompanyId?: number;
};

const COMPANIES_FIRST_OPTION = {
  label: "Все компании",
  value: 0,
};
const WORKERS_FIRST_OPTION = {
  label: "Все сотрудники",
  value: 0,
};

const filterEventByDay = (
  event: EventT,
  day: Date,
  includeNotifications: boolean
) => {
  const eventStartDate = moment(event.dateStart);
  const eventFinishDate = moment(event.dateFinish);
  const date = moment(day);

  const isNotification = event.type === "SIGNATURE";
  const isDayIncludedInEvent = date.isBetween(
    eventStartDate.format("YYYY-MM-DD"),
    eventFinishDate.format("YYYY-MM-DD"),
    "days",
    "[]"
  );

  if (includeNotifications) {
    return isDayIncludedInEvent;
  }

  return !isNotification && isDayIncludedInEvent;
};

const EventCalendar: React.FC<CalendarProps & CompanyTabPropsT> = memo(
  ({ isCompanyDefined, defaultCompanyId = 0 }) => {
    const monthEvents = useStore($MonthEvents);
    const permissions = useStore($UserAddPermissions);
    const user = useStore($User) as UserDataT;
    const workers = useStore($Workers);
    const [filterbyWorkerId] = useState(0);
    const [filterByCompanyId, setFilterByCompanyId] =
      useState<number>(defaultCompanyId);
    const today = new Date();

    const { companies } = useCompaniesQuery();

    const [calendarView, setCalendarView] = useState<Detail>("month");
    const [currentDate, setCurrentDate] = useState<Date>(today);
    const currentMonthAndYear = {
      month: today.getMonth(),
      year: today.getFullYear(),
    };
    const [currentMonthEvents, setCurrentMonthEvents] = useState<EventT[]>([]);
    const [states, setStates] = useState({
      error: false,
      isPending: true,
      monthEventsError: false,
    });

    const [displayNotifications, setDisplayNotifications] = useState(false);

    useEffect(() => {
      if (
        localStorageUse.getItem("display_notifications") === "true" ||
        localStorageUse.getItem("display_notifications") === true
      ) {
        setDisplayNotifications(true);
      }
      if (
        localStorageUse.getItem("display_notifications") === "false" ||
        localStorageUse.getItem("display_notifications") === null
      ) {
        setDisplayNotifications(false);
      }
    }, []);

    useEffect(() => {
      localStorageUse.saveItem("display_notifications", displayNotifications);
    }, [displayNotifications]);

    useEffect(() => {
      setModule(Modules.CALENDAR);

      if (filterbyWorkerId === 0) {
        const filteredCompaniesOptions =
          companies?.map((company) => ({
            value: company.id,
            label: company.name,
          })) ?? [];
        filteredCompaniesOptions.unshift(COMPANIES_FIRST_OPTION);
        setCompaniesOptions(filteredCompaniesOptions);
      } else {
        let hasCurrentCompany = false;
        const selectedWorker = workers.find(
          (worker) => worker.id === filterbyWorkerId
        );
        const filteredCompaniesOptions = selectedWorker!.companies?.map(
          (company) => {
            if (company.id === filterByCompanyId) {
              hasCurrentCompany = true;
            }
            return {
              value: company.id,
              label: company.name,
            };
          }
        );
        if (!hasCurrentCompany) {
          setFilterByCompanyId(0);
        }
        filteredCompaniesOptions.unshift(COMPANIES_FIRST_OPTION);
        setCompaniesOptions(filteredCompaniesOptions);
      }

      fetchMonthEvents({
        dateStart: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ).getTime(),
        dateFinish: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          1
        ).getTime(),
        setStore: true,
        companyId: filterByCompanyId,
        cb: (err, res) => {
          if (err || !res) {
            return setStates((states) => ({
              ...states,
              monthEventsError: true,
            }));
          }
          setCurrentMonthEvents(
            res.data
              .filter(
                (event) =>
                  event.createdBy === filterbyWorkerId ||
                  !filterbyWorkerId ||
                  filterbyWorkerId === 0
              )
              .map((event) => ({
                ...event,
                companyId:
                  "companyId" in event ? event.companyId : filterByCompanyId,
              }))
          );
          fetchEvents({
            dateStart: new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              currentDate.getDate()
            ).getTime(),
            dateFinish: new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              currentDate.getDate() + 1
            ).getTime(),
            companyId: filterByCompanyId,
            cb: (err, res) => {
              if (err || !res) {
                return setStates((states) => ({
                  ...states,
                  error: true,
                  isPending: false,
                }));
              }
              setStates((states) => ({
                ...states,
                error: false,
                isPending: false,
              }));
            },
          });
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterbyWorkerId, filterByCompanyId, currentDate, workers, companies]);

    const defaultWorkersOptions = workers
      .filter((worker) => worker.roleId === 2)
      .map((worker) => ({
        label: worker.name,
        value: worker.id,
      }));
    defaultWorkersOptions.unshift(WORKERS_FIRST_OPTION);

    // const [workersOptions, setWorkersOptions] = useState(defaultWorkersOptions);
    const [companiesOptions, setCompaniesOptions] = useState(
      companies?.map((company) => ({
        value: company.id,
        label: company.name,
      })) ?? []
    );

    const { open } = useModal();

    const currentDayEvents = useMemo(() => {
      return currentMonthEvents.filter((event) =>
        filterEventByDay(event, currentDate, displayNotifications)
      );
    }, [currentMonthEvents, currentDate, displayNotifications]);

    const tileContentHandler = useCallback(
      ({ date, view }: CalendarTileProperties) => {
        if (view !== "month") {
          return null;
        }

        const today = moment();
        const dayEvents = currentMonthEvents.filter((event) =>
          filterEventByDay(event, date, false)
        );
        const hasExpiredEvents = dayEvents.some((event) =>
          moment(event.dateFinish).isBefore(today)
        );
        const hasNonExpiredEvents = dayEvents.some((event) =>
          moment(event.dateFinish).isAfter(today)
        );
        const isFinancial = !!dayEvents.find((event) => event.isFinancial);

        if (dayEvents.length === 0) {
          return null;
        }

        return (
          <>
            <div className={clsx(style.event_block)}>
              {hasExpiredEvents && (
                <p className={clsx(style.calendar_event, style.red)} />
              )}
              {hasNonExpiredEvents && (
                <p className={clsx(style.calendar_event, style.green)} />
              )}
            </div>

            {isFinancial && (
              <img
                height="24px"
                width="24px"
                src="/img/static/coins.png"
                alt=""
                className={style.event_block_financialIcon}
              />
            )}

            <div className="event-counter">
              <div>{dayEvents.length}</div>
            </div>
          </>
        );
      },
      [currentMonthEvents]
    );

    const tileClassNameHandler = useCallback(({ date, view }) => {
      return view === "month" &&
        date.getDate() === new Date().getDate() &&
        date.getMonth() === new Date().getMonth() &&
        date.getFullYear() === new Date().getFullYear()
        ? clsx([style.calendar_day_item, style.active])
        : clsx(style.calendar_day_item);
    }, []);

    const onUpdateEvent = (updatedEvent: EventT) => {
      setCurrentMonthEvents((events) =>
        events.map((event) => {
          if (event.id === updatedEvent.id) {
            return updatedEvent;
          }
          return event;
        })
      );
    };

    const onDelete = (id: number) => {
      EventService.delete(id, (err) => {
        if (err) {
          return console.error("При удалении события произошла ошибка");
        }
        const destroyedEventInMonthEvents = currentMonthEvents.find(
          (event) => event.id === id
        );
        if (destroyedEventInMonthEvents) {
          setCurrentMonthEvents(
            currentMonthEvents.filter((event) => event.id !== id)
          );
        }
      });
    };

    const handleEventCreation = (newEvent: EventT) => {
      const newEventDateStart = new Date(newEvent.dateStart);
      const newEventDateStartMonth = newEventDateStart.getMonth();
      const newEventDateStartYear = newEventDateStart.getFullYear();

      const newEventDateFinish = new Date(newEvent.dateFinish);
      const newEventDateFinishMonth = newEventDateFinish.getMonth();
      const newEventDateFinishYear = newEventDateFinish.getFullYear();

      // UPDATING CURRENT SELECTED DAY EVENTS STATE
      // UPDATING CURRENT SELECTED MONTH EVENTS STATE
      if (
        (newEventDateStartMonth === currentMonthAndYear.month &&
          newEventDateStartYear === currentMonthAndYear.year) ||
        (newEventDateFinishMonth === currentMonthAndYear.month &&
          newEventDateFinishYear === currentMonthAndYear.year)
      ) {
        setCurrentMonthEvents([...currentMonthEvents, newEvent]);
      }
      // UPDATING MONTH EVENTS STORE
      if (
        (newEventDateStartMonth === today.getMonth() &&
          newEventDateStartYear === today.getFullYear()) ||
        (newEventDateFinishMonth === today.getMonth() &&
          newEventDateFinishYear === today.getFullYear())
      ) {
        setMonthEventsData([...monthEvents, newEvent]);
      }
      // RESETTING NOTIFICATION EVENTS & STATES TO FETCH IT AGAIN ON GOING TO THE PAGE
      resetNotificationEvents();
    };

    // NOTE: Этот useEffect делал запрос на получение сотрудников для фильтра, поскольку фильтр мы скрыли, то и запрос я закомментил
    // useEffect(() => {
    //   getWorkers({
    //     cb: (workers) => {
    //       setWorkersOptions([
    //         WORKERS_FIRST_OPTION,
    //         ...workers
    //           .filter((worker) => worker.roleId === 2)
    //           .map((worker) => ({
    //             label: worker.name,
    //             value: worker.id,
    //           })),
    //       ]);
    //     },
    //   });
    // }, [company]);

    const switchCompanyHandler = async (value: number) => {
      if (!value || value === 0) {
        return setFilterByCompanyId(0);
      }

      await switchCompany({ id: value });
      setFilterByCompanyId(value);
    };

    return (
      <section className="tab-content-item">
        <div className={clsx(style.calendar_section)}>
          <div className={clsx(style.calendar_panel)}>
            <div className={clsx(style.calendar_control__panel)}>
              <div className={clsx(style.calendar_control__panel)}>
                <div className={style.filter_btns}>
                  {/*permissions.hasRequiredLevel(UserPriorityEnum.Admin) ? (
                    <div className={style.filter_btn}>
                      <DropDownList
                        options={workersOptions}
                        onChange={changeWorkerHandler}
                        chosedValue={filterbyWorkerId}
                      />
                    </div>
                  ) : null*/}
                  {!isCompanyDefined ? (
                    <div className={style.filter_btn}>
                      <DropDownList
                        options={companiesOptions}
                        chosedValue={filterByCompanyId ? filterByCompanyId : 0}
                        onChange={(value: string) =>
                          switchCompanyHandler(parseInt(value))
                        }
                      />
                    </div>
                  ) : null}
                </div>
                <div className={style.dateFilter_btns}>
                  <button
                    className={clsx(style.year_btn)}
                    onClick={() => {
                      setCalendarArrowsOpacity("0");
                      setCalendarView("year");
                    }}
                  >
                    {getMonth(currentDate.getMonth())}
                    <img
                      src="/img/static/black-arrow-drop.png"
                      alt=""
                      className={clsx(style.calendar_control__arrow_down)}
                    />
                  </button>
                  <button
                    className={clsx(style.month_btn)}
                    onClick={() => {
                      // CALENDAR ARROW - should be 1 but made 0 to remove these arrows
                      setCalendarArrowsOpacity("0");
                      setCalendarView("decade");
                    }}
                  >
                    {currentDate.getFullYear()}
                    <img
                      src="/img/static/black-arrow-drop.png"
                      alt=""
                      className={clsx(style.calendar_control__arrow_down)}
                    />
                  </button>
                </div>
              </div>
            </div>
            <Calendar
              tileContent={tileContentHandler}
              onClickYear={() => {
                setCalendarArrowsOpacity("0");
              }}
              view={calendarView}
              onViewChange={({ view, activeStartDate }) => {
                setCalendarView(view);
                setCurrentDate(activeStartDate);
              }}
              locale={"ru-RU"}
              value={currentDate}
              prev2Label={null}
              next2Label={null}
              prevLabel={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={clsx(
                    style.calendar_control__btn,
                    style.calendar_prev_btn
                  )}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 19l-7-7 7-7"
                  />
                </svg>
              }
              nextLabel={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={clsx(
                    style.calendar_control__btn,
                    style.calendar_next_btn
                  )}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              }
              tileClassName={tileClassNameHandler}
              onChange={(date) => {
                setCurrentDate(date);
              }}
            />
          </div>
          <div className={clsx(style.events_panel)}>
            <div className={clsx(style.events_panel__top_content)}>
              <p className={clsx(style.today_text)}>
                Сегодня, {today.getDate()} {getMonth(today.getMonth(), true)}
              </p>
              <div className={clsx(style.selected_calendar_date)}>
                <p className={clsx(style.selected_calendar_date__text)}>
                  {currentDate.getDate()}{" "}
                  {getMonth(currentDate.getMonth(), true)}
                </p>
              </div>
              {permissions.hasPermission(
                ACTIONS.companyEventsCalendar_allowedToCreate
              ) && (
                <button
                  className={clsx(style.calendar_create_event_btn)}
                  onClick={(e) => {
                    e.stopPropagation();
                    open("CreateEventModal", {
                      btnText: "Создать",
                      modalData: {
                        modalTitle: "Создание события",
                        companyId:
                          filterByCompanyId !== 0 ? filterByCompanyId : null,
                        userId: user.id,
                        isCompanyCalendar: isCompanyDefined,
                      },
                      onConfirm: handleEventCreation,
                    });
                  }}
                >
                  Создать событие <img src="/img/plus.png" alt="" />
                </button>
              )}
            </div>
            <div className={style.displayNotificationsCheckbox}>
              <FormControlLabel
                label="Показывать оповещения"
                control={
                  <Checkbox
                    checked={displayNotifications}
                    onChange={(e) => setDisplayNotifications(e.target.checked)}
                    color="primary"
                    icon={<CheckboxSVG />}
                    checkedIcon={<CheckboxCheckedSVG />}
                  />
                }
              />
            </div>
            <div className={clsx(style.events_panel__event_items)}>
              <CalendarEventItems
                events={currentDayEvents}
                error={states.error}
                isPending={states.isPending}
                isCompanyCalendar={isCompanyDefined}
                onDelete={onDelete}
                onUpdateEvent={onUpdateEvent}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default EventCalendar;
