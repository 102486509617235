import { FC } from "react";
import { ProjectNewsItem } from "@interfaces/project-news";
import { Modal } from "@ui/modal";
import styles from "@scss/modals/login-news-modal.module.scss";
import { concatApiUrl } from "@utils/api-tools";
import moment from "moment";
import { getTextWithAnchors } from "@utils/common-utils";
import { Button } from "@ui/button";

export type LoginNewsModalPropsT = {
  item: ProjectNewsItem;
  isOpen: boolean;
  onClose: () => void;
};

const LoginNewsModal: FC<LoginNewsModalPropsT> = ({
  item,
  isOpen,
  onClose,
}) => {
  return (
    <Modal open={isOpen} className={styles.loginNewsModal} onClose={onClose}>
      <div className={styles.loginNewsModal__content}>
        <div className={styles.loginNewsModal__image}>
          <img
            src={concatApiUrl(
              "/uploads/project-news/" + item.images[0].filename
            )}
            alt=""
          />
        </div>
        <div className={styles.loginNewsModal__info}>
          <div>
            {item.tags.map((tagText, idx) => (
              <p key={idx}>#{tagText.trim()}</p>
            ))}
          </div>
          <p className={styles.loginNewsModal__title}>{item.title}</p>
          <p className={styles.loginNewsModal__date}>
            {moment(item.createdAt).format("LLL")}
          </p>
          <p
            className={styles.loginNewsModal__description}
            dangerouslySetInnerHTML={{
              __html: getTextWithAnchors(item.text),
            }}
          />
        </div>
        <div className={styles.loginNewsModal__footer}>
          <Button onClick={() => onClose()}>Закрыть</Button>
        </div>
      </div>
    </Modal>
  );
};

export default LoginNewsModal;
