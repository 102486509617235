import { ChoiceStore, useExternalChoiceState } from "@hooks/useChoiceState";
import { useWorkspaceGroupsQuery } from "@lib/workspace";
import { useWorkspaceGroupFilesQuery } from "@lib/workspace/useWorkspaceGroupFilesQuery";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { CheckboxCheckedSVG, CheckboxSVG } from "@components/svgs";
import style from "@scss/modals/company/create-task.module.scss";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  CreateTaskModalForm,
  CreateTaskModalTabProps,
} from "./create-task-modal";
import { DropDownLabel } from "@ui/drop-down";
import { useModal } from "@modals/index";
import { useSelectedCompanyQuery } from "@lib/company";
import { Search } from "@ui/search";
import { useSearch } from "@hooks/useSearch";
import { useSearchByName } from "@hooks/useSearchByName";
import clsx from "clsx";

const AllGroups = {
  id: -1,
  title: "Выбрать все",
};

const GeneralGroup = {
  id: 0,
  title: "Общие документы организации",
};

export const CreateTaskModalFilesTab: FC<CreateTaskModalTabProps> = ({
  className,
  touchField,
}) => {
  const { company } = useSelectedCompanyQuery();

  const { modalData } = useModal();

  const isTaskEditable = modalData.event ? modalData.event.status === 1 : true;

  const [selectedGroup, setSelectedGroup] = useState(AllGroups.id);

  const getGroupsParams = () => {
    if (selectedGroup === AllGroups.id) {
      return [GeneralGroup.id, ...(groups?.map((group) => group.id) ?? [])];
    }
    return [selectedGroup];
  };

  const { groups } = useWorkspaceGroupsQuery(company?.id);
  const { files } = useWorkspaceGroupFilesQuery(company?.id, getGroupsParams());

  const groupOptions = [
    AllGroups,
    GeneralGroup,
    ...(groups?.map((group) => ({
      id: group.id,
      title: group.name,
    })) ?? []),
  ];

  const search = useSearch();
  const filteredFiles = useSearchByName(search.debounced, files);

  const { watch, setValue } = useFormContext<CreateTaskModalForm>();

  const selectedFiles = watch("documents");
  const store: ChoiceStore<number> = {
    items: selectedFiles,
    add: (files) => {
      setValue("documents", [...selectedFiles, ...files]);
    },
    set: (files) => {
      setValue("documents", files);
    },
    remove: (files) => {
      setValue(
        "documents",
        selectedFiles.filter((item) => !files.find((id) => id === item))
      );
    },
  };

  const choice = useExternalChoiceState(
    files?.map((file) => file.id) ?? [],
    (id) => id,
    store
  );

  return (
    <div className={className}>
      <div className={style.createTaskModal__filters}>
        <DropDownLabel
          label="Группа"
          list={groupOptions}
          type="groups"
          handlerChangeFilter={(_, value) => {
            if (value === "") {
              return setSelectedGroup(GeneralGroup.id);
            }
            if (!value) {
              return setSelectedGroup(AllGroups.id);
            }
            setSelectedGroup(parseInt(value));
          }}
        />
        <Search
          value={search.value}
          onChange={search.onChange}
          options={files ?? []}
          getOptionLabel={(file) => file.name}
          showDropDown={false}
          placeholder="Поиск на названию"
          className={clsx(
            style.createTaskModal__search,
            style.createTaskModal__filesSearch
          )}
        />
      </div>
      <div className={style.createTaskModal__list}>
        <Table>
          <TableHead>
            <TableCell width={40}>
              <Checkbox
                checked={choice.areAllItemsSelected}
                onChange={(e) => {
                  touchField("documents");
                  choice.selectSeveral(
                    filteredFiles?.map((file) => file.id) ?? [],
                    e.target.checked
                  );
                }}
                color="primary"
                icon={<CheckboxSVG />}
                checkedIcon={<CheckboxCheckedSVG />}
                disabled={!isTaskEditable}
                classes={{
                  disabled: style.createTaskModal__disabledCheckbox,
                }}
              />
            </TableCell>
            <TableCell>Наименование документа</TableCell>
          </TableHead>
          <TableBody>
            {filteredFiles?.map((file) => (
              <TableRow key={file.id}>
                <TableCell>
                  <Checkbox
                    checked={choice.getIsItemSelected(file.id)}
                    onChange={(e) => {
                      touchField("documents");
                      choice.selectById(file.id, e.target.checked);
                    }}
                    color="primary"
                    icon={<CheckboxSVG />}
                    checkedIcon={<CheckboxCheckedSVG />}
                    disabled={!isTaskEditable}
                    classes={{
                      disabled: style.createTaskModal__disabledCheckbox,
                    }}
                  />
                </TableCell>
                <TableCell className={style.createTaskModal__fileName}>
                  {file.name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
