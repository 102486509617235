import { useQuery } from "react-query";
import { GetGeneralMedicalExamControlsParams } from "@interfaces/medical-examination";
import { medicalExaminationQueryKeys } from "./queryKeys";
import { MedicalExaminationService } from "@services/medical-examination.service";

export const useGeneralMedicalExamControlsQuery = (
  params: GetGeneralMedicalExamControlsParams
) => {
  return useQuery(
    medicalExaminationQueryKeys.generalControls(params),
    () => MedicalExaminationService.getGeneralControls(params),
    {
      enabled: params.categories.length > 0,
    }
  );
};
