import { useEffect, useMemo, useState } from "react";

import { Button } from "@ui/button";
import { ListFeedBack, SendFeedBack } from "./components";
import { localStorageUse } from "@utils/localStorageUse";
import styles from "./FeedBackBlock.module.scss";
import { useGetAllFeedbackQuery } from "@lib/feedback";
import { useStore } from "effector-react";
import { $DeviceType } from "@store/device-type-store";
import clsx from "clsx";

const LIMIT = 15;

export const FeedBackBlock: React.FC = () => {
  const { isPhone } = useStore($DeviceType);
  const [newFeedBack, setNewFeedBack] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>("");
  const [checkedClose, setCheckedClose] = useState(false);
  const [onOffset, setOnOffset] = useState(1);

  useEffect(() => setPage(1), [search]);

  useEffect(() => setOnOffset(LIMIT * (page - 1)), [page]);

  useEffect(() => {
    if (
      localStorageUse.getItem("type_status") === "true" ||
      localStorageUse.getItem("type_status") === true
    ) {
      setCheckedClose(true);
    }
    if (
      localStorageUse.getItem("type_status") === "false" ||
      localStorageUse.getItem("type_status") === null
    ) {
      setCheckedClose(false);
    }
  }, []);

  useEffect(() => {
    localStorageUse.saveItem("type_status", checkedClose);
    setPage(1);
  }, [checkedClose]);

  const params = useMemo(() => {
    return {
      limit: LIMIT,
      offset: onOffset,
      searchString: search,
      status: checkedClose ? "open" : "closed",
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LIMIT, search, onOffset, checkedClose]);

  const { data: feedbackList } = useGetAllFeedbackQuery(params);

  useEffect(() => {
    setTotalPages(Math.ceil((feedbackList?.data?.count ?? 0) / LIMIT) ?? 0);
  }, [feedbackList]);
  return (
    <div
      className={clsx(
        styles.FeedBackBlock,
        isPhone && styles.FeedBackBlock_mobile
      )}
    >
      {!newFeedBack && (
        <Button onClick={() => setNewFeedBack(true)}>+ Новое обращение</Button>
      )}
      <SendFeedBack
        newFeedBack={newFeedBack}
        handlerNewFeedBack={() => setNewFeedBack(false)}
      />
      <ListFeedBack
        newFeedBack={newFeedBack}
        feedbackList={feedbackList}
        checkedClose={checkedClose}
        setCheckedClose={setCheckedClose}
        setSearch={setSearch}
        totalPages={totalPages}
        setPage={setPage}
        page={page}
      />
    </div>
  );
};
