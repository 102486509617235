import { WorkspaceGroupT } from "@interfaces/company/workspace";

export type CompanyT = AvailableCompanyDataT & {
  readonly ownerId: number;
  specialists: CompanyClientT[];
  groups: WorkspaceGroupT[];
  leadershipPosition: string;
  supervisor: string;
};

export type CompanyShortDataT = {
  contractNumber: string;
  readonly id: number;
  name: string;
  image: string;
  organizationalLegalForm: string;
  email: string | null;
  inn: string;
  accessToken: string | null;
  ownerId: number;
  tariff: number;
  workspaceSize: string;
  tariffExpires: number;
  tariffStarts: number;
  additionalSize: string;
  cloudFolderId: number;
};

export type AvailableCompanyDataT = {
  readonly id: number;
  name: string;
  contractNumber: string;
  actualAddress: string;
  legalAddress: string;
  shortDesc: string;
  image: string;
  organizationalLegalForm: string;
  email: string | null;
  accessToken: string | null;
  inn: string;
  tariff: number;
  workspaceSize: string;
  tariffExpires: number;
  tariffStarts: number;
  additionalSize: string;
  cloudFolderId: number;
};

export type CompanyTabPropsT = {};

type CompanyClientT = {
  readonly id: number;
  name: string;
  roleId?: number;
};

// export type CompanyT = {
//    readonly id: number
//    name: string
//    ownerId: number
//    actualAddress: string
//    legalAddress: string
//    shortDesc: string
//    image: string
//    organizationalLegalForm: string
//    inn: string
//    specialists: CompanyClientT[]
//    client: CompanyClientT
// }

export type FetchingCompanyDataT = {
  success: boolean;
  data: CompanyT;
};

export enum TariffEnum {
  None = 0,
  Basic = 1,
  Extended = 2,
  Maximum = 3,
  Demo = 5,
  Temp = 4,
}

export type GetCompaniesParams = {
  searchString?: string;
};
