import { useMutation, useQueryClient } from "react-query";

import { WorkspaceDeleteFileData } from "@interfaces/company/workspace";
import { workspaceQueryKeys } from "./queryKeys";
import { workspaceService } from "@services/workspace-service";

export const useDeleteWorkspaceFileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: WorkspaceDeleteFileData) => workspaceService.deleteFile(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(workspaceQueryKeys.allDirectoriesFiles);
        queryClient.invalidateQueries(["arhived"]);
      },
    }
  );
};
