import { useMutation, useQueryClient } from "react-query";
import { studyCenterService, UpdateEventData } from "@services/study-center";
import { studyCenterQueryKeys } from "./queryKeys";

export const useUpdateEducationalEventMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateEventData) => studyCenterService.updateEvent(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(studyCenterQueryKeys.all);
      },
    }
  );
};
