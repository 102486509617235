import {
  ArchiveDocumentTable,
  ArchiveEmployeeTable,
} from "@components/tables/archive-tables";
import { CompanyTabPropsT } from "@interfaces/company/company";
import React, { useEffect, useState } from "react";

import { Modules } from "@interfaces/common";
import clsx from "clsx";
import qs from "qs";
import { setModule } from "@store/user-store";
import style from "@scss/pages/company/archive.module.scss";
import { useHistory } from "react-router-dom";
import { ArchiveDocumentTypeEnum } from "@interfaces/company/archive";

const Archive: React.FC<CompanyTabPropsT> = () => {
  const history = useHistory();

  const [type, setType] = useState<ArchiveDocumentTypeEnum>(
    ArchiveDocumentTypeEnum.document
  );

  useEffect(() => {
    setModule(Modules.WORKSPACE);

    const Querystring = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    if (Querystring.groupId) {
      // return setActiveGroupId(+Querystring.groupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="tab-content-item">
      <div className={clsx(style.archive_top_panel)}>
        <button
          onClick={() => setType(ArchiveDocumentTypeEnum.document)}
          className={clsx(style.archive__type_btn, {
            [style.active]: type === ArchiveDocumentTypeEnum.document,
          })}
        >
          Рабочий блок
        </button>
        <button
          onClick={() => setType(ArchiveDocumentTypeEnum.employee)}
          className={clsx(style.archive__type_btn, {
            [style.active]: type === ArchiveDocumentTypeEnum.employee,
          })}
        >
          Сотрудники
        </button>
      </div>
      {type === ArchiveDocumentTypeEnum.document ? (
        <ArchiveDocumentTable />
      ) : type === ArchiveDocumentTypeEnum.employee ? (
        <ArchiveEmployeeTable />
      ) : null}
    </div>
  );
};

export default Archive;
