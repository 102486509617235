import {
  FetchProcedureControlPropsT,
  FetchProcedureTrainingProgramsT,
  ProcedureTrainingProgramsT,
  TablesDirectionDataT,
} from "@interfaces/company/procedure";
import { createEffect, createEvent, createStore } from "effector";

import { EventStateStoreT } from "@interfaces/common";
import ProcedureControlService from "@services/procedure-control-service";
import ProcedureService from "@services/programs-service";
import { resetAllStates } from "@store/user-store";
import { СategoriesT } from "@interfaces/directory/version";

export const setCategoriesTraining = createEvent<TablesDirectionDataT>();
export const pushProcedureTrainingCategories = createEvent<{
  categories?: СategoriesT;
}>();
export const removeProcedureTrainingCategories = createEvent<number>();
export const editProcedureTrainingCategories = createEvent<СategoriesT>();
export const setProcedureTraining = createEvent<{
  categories?: СategoriesT[];
  programs?: ProcedureTrainingProgramsT[];
}>();
export const $ProcedureTraining = createStore<{
  categories?: СategoriesT[];
  programs?: ProcedureTrainingProgramsT[];
}>({ categories: [], programs: [] })
  .on(setProcedureTraining, (oldState, newStateTraining) => {
    if (!newStateTraining) {
      return { categories: [] };
    }
    return {
      ...oldState,
      ...newStateTraining,
    };
  })
  .on(pushProcedureTrainingCategories, (oldState, newState) => {
    if (newState.categories && oldState.categories) {
      return {
        ...oldState,
        categories: [...oldState?.categories, newState?.categories],
      };
    }
    if (newState.categories && !oldState.categories) {
      return { ...oldState, categories: [newState?.categories] };
    }
  })
  .on(removeProcedureTrainingCategories, (oldState, categoryId) => {
    if (categoryId) {
      return {
        categories: oldState?.categories?.filter(
          (category) => category.id !== categoryId
        ),
      };
    }
  })
  .on(editProcedureTrainingCategories, (oldState, editCategory) => {
    if (editCategory.title) {
      const editTitle = oldState?.categories?.map((category) => {
        if (category.id === editCategory.id) {
          return { ...category, title: editCategory.title };
        }
        return category;
      });
      return { categories: editTitle };
    }
  })
  .reset(resetAllStates);

export const setProcedureTrainingStates = createEvent<EventStateStoreT>();
export const setProcedureTrainingLoading = createEvent<boolean>();
export const setProcedureTrainingError = createEvent<boolean>();
export const SELECTS_ID: number = 99999;

export const $ProcedureControlStates = createStore<EventStateStoreT>({
  isLoading: true,
  error: false,
  isFetched: false,
})
  .on(setProcedureTrainingLoading, (oldState, newState) => ({
    ...oldState,
    isLoading: newState,
  }))
  .on(setProcedureTrainingError, (oldState, newState) => ({
    ...oldState,
    isLoading: false,
    error: newState,
  }))
  .on(setProcedureTrainingStates, (_, newStateTraining) => newStateTraining)
  .reset(resetAllStates);

export const FetchEmployeeProcedureTrainingCategories = createEffect(
  (employeeId: number) => {
    setProcedureTrainingLoading(true);

    ProcedureControlService.GetTrainingControls(
      {
        employees: [employeeId],
      },
      (err, res) => {
        if (err || !res) {
          return setProcedureTrainingStates({
            error: true,
            isFetched: false,
            isLoading: false,
          });
        }

        const categories: СategoriesT[] = res.data
          .filter((table) => table.body.length > 0)
          .map((table) => ({
            id: table.id,
            title: table.title,
            taskGroup: "",
            action: undefined,
          }));

        setProcedureTraining({ categories });
        setProcedureTrainingStates({
          error: false,
          isFetched: true,
          isLoading: false,
        });
      }
    );
  }
);

export const FetchAllCategoriesForTrainingPrograms =
  createEffect<FetchProcedureControlPropsT>(({ isIncludeChanges = true }) => {
    setProcedureTrainingLoading(true);
    ProcedureControlService.GetAllCategories(
      "training",
      isIncludeChanges,
      (err, res) => {
        if (err || !res) {
          setProcedureTrainingStates({
            error: true,
            isFetched: false,
            isLoading: false,
          });
          return console.error(
            "При получении данных TrainingProgram произошла ошибка"
          );
        }
        if (!res.data.length) {
          setProcedureTraining({ categories: [] });
        }
        if (!!res.data.length) {
          res.data.unshift({
            id: SELECTS_ID,
            title: "Выбрать все",
            taskGroup: "",
          });
          const categories: СategoriesT[] = res.data.map((item) => {
            if (item?.change?.id && item?.change?.title) {
              return {
                id: item.change.id,
                title: item.change.title,
                action: item.change?.action || undefined,
                taskGroup: item.change?.taskGroup,
              };
            }
            if (item?.id && item?.title) {
              return {
                id: item.id,
                title: item.title,
                action: undefined,
                taskGroup: item.taskGroup,
              };
            }
            return {
              id: 100000,
              title: "",
              action: undefined,
              taskGroup: "",
            };
          });
          if (categories) setProcedureTraining({ categories });
        }
        setProcedureTrainingStates({
          error: false,
          isFetched: true,
          isLoading: false,
        });
      }
    );
  });

export const FetchAllTrainingPrograms =
  createEffect<FetchProcedureTrainingProgramsT>(({ isIncludeChanges }) => {
    setProcedureTrainingLoading(true);
    ProcedureService.Programs(
      "training",
      undefined,
      isIncludeChanges,
      (err, res) => {
        if (err || !res) {
          setProcedureTrainingStates({
            error: true,
            isFetched: false,
            isLoading: false,
          });
          return console.error(
            "При получении данных Programs произошла ошибка"
          );
        }
        if (res.data) {
          if (!!res.data.length) {
            setProcedureTraining({ programs: res.data });
          }
        }

        setProcedureTrainingStates({
          error: false,
          isFetched: true,
          isLoading: false,
        });
      }
    );
  });
