export const PlusSVG = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.41919 16.4297C12.8333 16.4297 16.4895 12.7812 16.4895 8.35938C16.4895 3.94531 12.8333 0.289062 8.41138 0.289062C3.99731 0.289062 0.348877 3.94531 0.348877 8.35938C0.348877 12.7812 4.00513 16.4297 8.41919 16.4297ZM8.41919 14.8438C4.82544 14.8438 1.94263 11.9531 1.94263 8.35938C1.94263 4.76562 4.81763 1.88281 8.41138 1.88281C12.0051 1.88281 14.8958 4.76562 14.9036 8.35938C14.9036 11.9531 12.0129 14.8438 8.41919 14.8438ZM4.802 8.35938C4.802 8.78125 5.10669 9.07812 5.54419 9.07812H7.68481V11.2266C7.68481 11.6562 7.98169 11.9609 8.40356 11.9609C8.84106 11.9609 9.14575 11.6562 9.14575 11.2266V9.07812H11.2942C11.7239 9.07812 12.0208 8.78125 12.0208 8.35938C12.0208 7.92188 11.7239 7.61719 11.2942 7.61719H9.14575V5.47656C9.14575 5.03906 8.84106 4.74219 8.40356 4.74219C7.98169 4.74219 7.68481 5.03906 7.68481 5.47656V7.61719H5.54419C5.10669 7.61719 4.802 7.92188 4.802 8.35938Z"
        fill="white"
      />
    </svg>
  );
};
