import Fetcher from "@http/fetcher";
import { ResCallback } from "@interfaces/common";
import { WarningT } from "@interfaces/warning";

class WarningService {
  static async GetWarning(companyId: number, cb: ResCallback<WarningT>) {
    try {
      const res = await Fetcher.get<WarningT>(
        `/companies/${companyId}/warnings`
      );
      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }
}

export default WarningService;
