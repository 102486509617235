import { ArrowBigGreen } from "@components/svgs";
import { BodyNormal } from "@ui/fonts";
import React from "react";
import clsx from "clsx";
import styles from "@scss/components/back-button.module.scss";
import { useHistory } from "react-router-dom";

export type BackButtonProps = {
  link?: string;
  className?: string;
  children?: string;
  off?: boolean;
};

export const BackButton: React.FC<BackButtonProps> = ({
  link,
  className,
  children,
  off,
}) => {
  const history = useHistory();

  const handleClick = () => {
    if (off) return;
    if (link) {
      history.push(link);
    } else {
      history.goBack();
    }
  };

  return (
    <button
      className={clsx(styles.backButton, className)}
      onClick={handleClick}
    >
      <ArrowBigGreen />
      <BodyNormal color="green"> {children ? children : "Назад"}</BodyNormal>
    </button>
  );
};
