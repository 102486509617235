import { CSSProperties, FC } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import { concatApiUrl } from "@utils/api-tools";
import { getTextWithAnchors } from "@utils/common-utils";
import style from "@scss/pages/news-and-practice/news-and-practice.module.scss";

function getPathToFile(
  type:
    | "legislation-news"
    | "practice"
    | "help"
    | "development-news"
    | "project-news"
    | string
): string {
  switch (type) {
    case "legislation-news":
      return "/uploads/legislation-news/";
    case "help":
      return "/uploads/useful-materials/";
    case "practice":
      return "/uploads/judicial-practices/";
    case "development-news":
      return "/uploads/development-news/";
    case "project-news":
      return "/uploads/project-news/";
    default:
      return "/uploads/";
  }
}

export type NewsItemPropsT = {
  id: string;
  title: string;
  tags: string[];
  type: string;
  text: string;
  image: string;
  createdAt: string;
  color?: string;
  className?: string;
};

export const NewsItem: FC<NewsItemPropsT> = ({
  id,
  title,
  tags,
  type,
  text,
  image,
  createdAt,
  color,
  className,
}) => {
  return (
    <div
      className={clsx(style.news_item, className)}
      style={
        {
          "--news-item-color": color,
        } as CSSProperties
      }
    >
      <Link to={`/${type.toLowerCase()}/${id}`}>
        <div className={`${clsx(style.img_section)}`}>
          <img src={concatApiUrl(getPathToFile(type) + image)} alt="" />
        </div>
        <div className={clsx(style.info_section)}>
          <div className={clsx(style.tags)}>
            {tags.map((tagText, idx) => (
              <p key={idx} className={clsx(style.tag_item)}>
                #{tagText.trim()}
              </p>
            ))}
          </div>
          <p className={clsx(style.news_title)}>{title}</p>
          <p className={clsx(style.news_date)}>
            {moment(createdAt).format("LLL")}
          </p>
          <p
            className={clsx(style.news_desc)}
            dangerouslySetInnerHTML={{
              __html: getTextWithAnchors(text),
            }}
          />
        </div>
      </Link>
    </div>
  );
};
