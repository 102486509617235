import { GetCompaniesParams } from "@interfaces/company/company";

export const companyQueryKeys = {
  all: ["companies"],
  allWithParams: (params?: GetCompaniesParams) => [
    ...companyQueryKeys.all,
    params,
  ],
  oneById: (id: number | null) => [...companyQueryKeys.all, id],
};
