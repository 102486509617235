import { FC } from "react";
import clsx from "clsx";
import { ResponsibilityDirectoryT } from "@interfaces/responsibility";
import style from "@scss/pages/responsibility.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import moment from "moment";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import { Tooltip } from "@material-ui/core";
import { NotePencil, Trash } from "@phosphor-icons/react";
import { useStore } from "effector-react";
import { useModal } from "@modals/index";
import qs from "qs";
import { useHistory } from "react-router-dom";

export type ResponsibilityDirectoryPropsT = {
  directory: ResponsibilityDirectoryT;
  isSelected: boolean;
  onSelect: (checked: boolean) => void;
  onDelete: () => void;
};

export const ResponsibilityDirectory: FC<ResponsibilityDirectoryPropsT> = ({
  directory,
  isSelected,
  onSelect,
  onDelete,
}) => {
  const permissions = useStore($UserAddPermissions);

  const history = useHistory();
  const modal = useModal();

  const id = `directory-${directory.id}`;

  const handleClick = (id: number) => {
    const queryString = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    let newQueryStringObj: any = {
      folder_id: id,
    };

    if (queryString.group_id) {
      newQueryStringObj.group_id = queryString.group_id;
    }

    history.push({
      pathname: history.location.pathname,
      search: qs.stringify(newQueryStringObj),
    });
  };

  return (
    <tr>
      <td>
        <label className={clsx(tableStyle.column_fixed_height)}>
          <input
            type="checkbox"
            className={clsx(tableStyle.checkbox_item)}
            hidden
            name=""
            id={id}
            checked={isSelected}
            onChange={(e) => onSelect(e.target.checked)}
          />
          <label htmlFor={id}>
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </label>
          <svg
            className={clsx(style.file_icon)}
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
          >
            <path
              d="M0 11.7761V30.3969C0 31.452 0.806906 32.259 1.86209 32.259H34.1379C35.1931 32.259 36 31.452 36 30.3969V11.7761C36 10.721 35.1931 9.91406 34.1379 9.91406H1.86209C0.806906 9.91406 0 10.7831 0 11.7761Z"
              fill="#F7B84E"
            />
            <path
              d="M32.8963 9.93103C32.8963 8.56549 31.779 7.44829 30.4135 7.44829H20.4825L17.379 3.72412H3.7239C2.66872 3.72412 1.86182 4.59311 1.86182 5.58621V9.93103H32.8963Z"
              fill="#E4A12F"
            />
          </svg>
          <label className={clsx(tableStyle.nameFile)}>
            <button onClick={() => handleClick(directory.id)}>
              {directory.name}
            </button>
          </label>
        </label>
      </td>
      <td>
        <span>{moment(directory.updatedAt).format("DD.MM.YYYY hh:mm")}</span>
      </td>
      <td>
        <div className={clsx(style.action_col)}>
          <div className={clsx(style.action_buttons)}>
            {permissions.hasPermission(
              ACTIONS.responsibility_directories_allowedToEdit
            ) && (
              <WrapperSVG color={"orange"} margin>
                <Tooltip title={"Изменить"} placement="top">
                  <NotePencil
                    size={24}
                    onClick={() =>
                      modal.open("CreateFolderModal", {
                        btnText: "Сохранить",
                        modalData: {
                          modalTitle: "Изменить папку",
                          responsibilityMode: true,
                          itemTitle: directory.name,
                          item: directory,
                        },
                      })
                    }
                  />
                </Tooltip>
              </WrapperSVG>
            )}
            {permissions.hasPermission(
              ACTIONS.responsibility_directories_allowedToDelete
            ) && (
              <Tooltip title={"Удалить"} placement="top">
                <WrapperSVG color="">
                  <Trash
                    size={24}
                    onClick={() =>
                      modal.open("ConfirmActionModal", {
                        btnText: "Удалить",
                        modalData: {
                          text: `Вы уверены, что хотите удалить папку "${directory.name}" и все содержимое?`,
                        },
                        onConfirm: () => onDelete(),
                      })
                    }
                  />
                </WrapperSVG>
              </Tooltip>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};
