import { useState } from "react";
import clsx from "clsx";
import { useModal } from "@modals/index";
import { Button } from "@ui/button";
import { BodyNormal, BodyBold, BodySmall } from "@ui/fonts";
import styles from "@scss/modals/select-company-modal.module.scss";
import { useCompaniesQuery } from "@lib/company";
import { getCompanyLogo } from "@utils/getCompanyLogo";

const SelectCompanyModal = () => {
  const { modalComponent, modalData, close } = useModal();

  const { companies } = useCompaniesQuery();

  const [companyId, setCompanyId] = useState<number | undefined>(
    companies?.length === 1 ? companies?.[0].id : undefined
  );

  const handleContinue = () => {
    modalComponent.onConfirm?.(companyId);
    setCompanyId(undefined);
    close();
  };

  return (
    <div className={styles.modal}>
      <div className={styles.modal__top}>
        <BodyBold weight="bold">{modalData.title}</BodyBold>
      </div>
      <div className={styles.modal__companies}>
        {companies?.map((company, i) => (
          <div
            key={i}
            className={clsx(styles.Company, {
              [styles.Company__selected]: companyId === company.id,
            })}
            tabIndex={0}
            onClick={() => setCompanyId(company.id)}
          >
            <img
              src={getCompanyLogo(company.image)}
              alt={company.name}
              className={styles.Company__logo}
            />
            <BodyNormal weight="bold" className={styles.Company__name}>
              {company.name}
            </BodyNormal>
            <BodySmall color="dark">ИНН {company.inn}</BodySmall>
          </div>
        ))}
      </div>
      <Button className={styles.modal__continueBtn} onClick={handleContinue}>
        Далее
      </Button>
    </div>
  );
};

export default SelectCompanyModal;
