import { InternalStudyCenterMaterial } from "@interfaces/internal-study-center";
import { INTERNAL_IMAGES_BASE_URL } from "@lib/constants";
import { Button } from "@ui/button";
import { BodyNormal } from "@ui/fonts";
import { useHistory } from "react-router-dom";
import styles from "./public-materials-box.module.scss";

type PublicMaterialsBoxT = {
  material: InternalStudyCenterMaterial;
  categoryId: number;
  sectionId: number;
};

export const PublicMaterialsBox: React.FC<PublicMaterialsBoxT> = ({
  material,
  categoryId,
  sectionId,
}) => {
  const history = useHistory();

  const materialLink = material.link
    ? material.link
    : `/study-center/public-materials/${sectionId}/${categoryId}/${material.id}`;

  // TODO: hardcoded for now because it's needed ASAP. get from API later
  const linkButtonText = "Посмотреть видеоинструктаж";

  const handleCardClick = () => {
    if (material.link) {
      window.open(material.link, "_target", "noopener noreferrer");
    } else {
      history.push(materialLink);
    }
  };

  return (
    <div className={styles.Card} onClick={handleCardClick}>
      <div className={styles.Card__imageWrapper}>
        <img
          src={`${INTERNAL_IMAGES_BASE_URL}/${material.preview}`}
          alt=""
          className={styles.Card__image}
        />
      </div>
      <div className={styles.Card__content}>
        <div className={styles.Card__text}>
          <BodyNormal weight="bold">{material.title}</BodyNormal>
        </div>
        {material.link && (
          <div className={styles.Card__postlinkBtn}>
            <a href={material.link} target="_blank" rel="noopener noreferrer">
              <Button>{linkButtonText}</Button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};
