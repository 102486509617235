import { useQuery } from "react-query";
import { GetMedicalExamControlsParams } from "@interfaces/medical-examination";
import { MedicalExaminationService } from "@services/medical-examination.service";
import { medicalExaminationQueryKeys } from "./queryKeys";

export const useMedicalExamControlsQuery = (
  params: GetMedicalExamControlsParams
) => {
  return useQuery(
    medicalExaminationQueryKeys.controls(params),
    () => MedicalExaminationService.getControls(params),
    {
      enabled:
        typeof params.companyId !== "undefined" && params.categories.length > 0,
    }
  );
};
