import { useCallback, useState } from "react";

export type ChoiceStore<TItem = number> = {
  items: TItem[];
  add: (items: TItem[]) => void;
  set: (items: TItem[]) => void;
  remove: (items: TItem[]) => void;
};

export type ChoiceState<TItem = number> = {
  selectedItems: TItem[];
  areAllItemsSelected: boolean;
  selectAll: (checked: boolean) => void;
  selectSeveral: (items: TItem[], checked: boolean) => void;
  selectById: (item: TItem, checked: boolean) => void;
  clearSelectedItems: () => void;
  getIsItemSelected: (item: TItem) => boolean;
  getItemId: (item: TItem) => number;
};

export const useChoiceState = <TItem = number>(
  items: TItem[],
  getId: (item: TItem) => number
): ChoiceState<TItem> => {
  const [selectedItems, setSelectedItems] = useState<TItem[]>([]);

  const add = useCallback((items: TItem[]) => {
    setSelectedItems((prev) => [...prev, ...items]);
  }, []);

  const set = useCallback((items: TItem[]) => {
    setSelectedItems(items);
  }, []);

  const remove = useCallback(
    (items: TItem[]) => {
      setSelectedItems((prev) =>
        prev.filter(
          (item) => !items.find((_item) => getId(item) === getId(_item))
        )
      );
    },
    [getId]
  );

  const store: ChoiceStore<TItem> = {
    items: selectedItems,
    add,
    set,
    remove,
  };

  return useExternalChoiceState(items, getId, store);
};

export const useExternalChoiceState = <TItem = number>(
  items: TItem[],
  getId: (item: TItem) => number,
  store: ChoiceStore<TItem>
): ChoiceState<TItem> => {
  const areAllItemsSelected =
    items.filter((item) =>
      store.items.find((_item) => getId(item) === getId(_item))
    ).length === items.length;

  const selectAll = useCallback(
    (checked: boolean) => {
      if (checked) {
        store.set(items);
      } else {
        store.set([]);
      }
    },
    [items, store]
  );

  const selectSeveral = useCallback(
    (items: TItem[], checked: boolean) => {
      if (checked) {
        store.add(items);
      } else {
        store.remove(items);
      }
    },
    [store]
  );

  const selectById = useCallback(
    (item: TItem, checked: boolean) => {
      if (checked) {
        store.add([item]);
      } else {
        store.remove([item]);
      }
    },
    [store]
  );

  const clearSelectedItems = useCallback(() => {
    store.set([]);
  }, [store]);

  const getIsItemSelected = useCallback(
    (item: TItem) => {
      const isSelected = !!store.items.find(
        (_item) => getId(_item) === getId(item)
      );

      return isSelected;
    },
    [store.items, getId]
  );

  return {
    selectedItems: store.items,
    areAllItemsSelected,
    selectAll,
    selectSeveral,
    selectById,
    clearSelectedItems,
    getIsItemSelected,
    getItemId: getId,
  };
};
