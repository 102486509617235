import { Box, Checkbox, Radio, Text } from "@mantine/core";
import { FormControl, RadioGroup } from "@mui/material";
import React, { useState } from "react";

import { ProcedureProgram } from "@interfaces/company/procedure";
import { TRAINING_PLACES } from "./edit-learning-program-modal";
import styles from "@scss/modals/company/edit-training-program-department.module.scss";

interface EditTrainingProgramDepartmentProps {
  item: ProcedureProgram;
  setTraining: React.Dispatch<
    React.SetStateAction<{ id: number; location: string }[]>
  >;
  trainingProgramsValue: { id: number; location: string }[];
}

const EditTrainingProgramDepartment: React.FC<
  EditTrainingProgramDepartmentProps
> = ({ item, trainingProgramsValue, setTraining }) => {
  const currentItemInSelectedList = trainingProgramsValue.find(
    (program) => program.id === item.id
  );
  const isChecked = !!currentItemInSelectedList;

  const [selectedValue, setSelectedValue] = useState(
    currentItemInSelectedList?.location ?? "У работодателя"
  );

  const handleChangeCheckbox = () => {
    if (isChecked) {
      return setTraining((prev) =>
        prev.filter((program) => program.id !== currentItemInSelectedList?.id)
      );
    }
    return setTraining((prev) => [
      ...prev,
      { id: item.id, location: selectedValue },
    ]);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value as string);
    if (isChecked) {
      setTraining((prev) =>
        prev.map((prog) =>
          prog.id !== item.id
            ? prog
            : { ...prog, location: event.target.value as string }
        )
      );
    }
  };

  return (
    <Box
      key={item.id}
      sx={{
        borderBottom: "1px solid white",
        padding: "5px 42px",
        minHeight: 62,
        display: "flex",
        alignItems: "flex-start",
      }}
    >
      <Checkbox
        checked={isChecked}
        onChange={handleChangeCheckbox}
        styles={{
          body: { borderColor: "#00B856", alignItems: "center", minWidth: 380 },
          input: {
            borderColor: "#00B856",
            "&:checked": {
              backgroundColor: "#00B856",
              borderColor: "#00B856",
            },
          },
        }}
        mr={11}
        label={<Text>{item.title}</Text>}
      />
      <Box sx={{ display: "flex", gap: 2 }}>
        <FormControl
          style={{
            minWidth: 200,
            maxWidth: 200,
            marginLeft: 21,
            marginTop: 0,
          }}
        >
          <RadioGroup
            defaultValue="medium"
            name="radio-buttons-group"
            className={styles.radioGroup}
          >
            {TRAINING_PLACES.map((option) => (
              <Radio
                key={option.value}
                color="green"
                value={option.value}
                checked={selectedValue === option.value}
                onChange={handleChange}
                label={option.label}
                size={"sm"}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default EditTrainingProgramDepartment;
