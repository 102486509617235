import { LearningComponents } from "@feature";
import { useParams } from "react-router-dom";

interface EmployeesTrainingProps {}

const EmployeesTraining: React.FC<EmployeesTrainingProps> = () => {
  const { id } = useParams<{ id: string }>();

  if (typeof id === "undefined") {
    return null;
  }

  return (
    <LearningComponents
      withoutFilters
      withoutEmpty
      defaultFilters={{
        isRequired: true,
        employees: [+id],
      }}
      ignoredKeys={["companyName", "departmentTitle", "positionTitle"]}
    />
  );
};

export default EmployeesTraining;
