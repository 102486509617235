import { BodyBold, BodyNormal } from "@ui/fonts";
import { useEffect, useState } from "react";

import { $UserAddPermissions } from "@store/user-store";
import { ACTIONS } from "@store/user-store";
import { Button } from "@ui/button";
import { Modal } from "@ui/modal";
import ProcedureControlService from "@services/procedure-control-service";
import { TextField } from "@material-ui/core";
import { pushDirectoryInstructionsCategories } from "@store/directory-programm-instuctions";
import { pushDirectoryTrainingCategories } from "@store/directory-programm-training";
import { pushProcedureInstructionsCategories } from "@store/company/procedure-instrunctions-store";
import { pushProcedureTrainingCategories } from "@store/company/procedure-training-store";
import styles from "./add-catedory.module.scss";
import { useStore } from "effector-react";
import { v4 as uuidv4 } from "uuid";

type AddCatedoryProps = {
  type: "training" | "briefing" | "medical-examination";
  handlerProgramm: (id: number) => void;
  updateCategories?: () => void;
  list?: {
    id: number | string;
    title: string;
    status?: boolean;
  }[];
  withTaskGroup?: boolean;
};

export const AddCatedory: React.FC<AddCatedoryProps> = ({
  list,
  type,
  withTaskGroup = true,
  handlerProgramm,
  updateCategories,
}) => {
  const [choiceID, setChoiceID] = useState<number>(0);
  const permissions = useStore($UserAddPermissions);
  const [valueInput, setValueInput] = useState<{
    title: string;
    taskGroup: string;
  }>({
    title: "",
    taskGroup: "",
  });
  const [open, setOpen] = useState<boolean>(false);

  const handlerSelect = (id: number) => {
    if (choiceID === id) {
      setChoiceID(0);
    } else {
      setChoiceID(id);
    }
  };

  useEffect(() => {
    handlerProgramm(choiceID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choiceID]);

  useEffect(() => {
    if (!!list?.length) {
      setChoiceID(0);
    }
  }, [list?.length]);

  const onSubmitNewProgram = () => {
    if (!valueInput.title.trim()) return;
    if (withTaskGroup && !valueInput.taskGroup.trim()) return;

    ProcedureControlService.AddCategoryProgramm(
      type,
      valueInput,
      (err, res) => {
        if (err || !res) {
          return Error("При получении списка программ произошла ошибка");
        }
        if (res.data) {
          if (type === "training") {
            pushProcedureTrainingCategories({ categories: res.data });
            pushDirectoryTrainingCategories({ training: res.data });
          }
          if (type === "briefing") {
            pushProcedureInstructionsCategories({ categories: res.data });
            pushDirectoryInstructionsCategories({ instructions: res.data });
          }
          updateCategories?.();
        }
      }
    );
    setOpen(false);
  };

  const addNewCategory: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    setValueInput((prevState) => ({
      ...prevState,
      [target.name]:
        target.type === "number"
          ? target.value === "0"
            ? null
            : target.value
          : target.value,
    }));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.choise_programm}>
        {!!list &&
          list.map((item) => (
            <Button
              key={uuidv4()}
              color={choiceID !== +item.id ? "white-green" : "green"}
              borderColor={"green"}
              onClick={() => handlerSelect(+item.id)}
            >
              <BodyBold>{item.title}</BodyBold>
            </Button>
          ))}
        {permissions.hasPermission(ACTIONS.directories_allowedToCreate) && (
          <Button
            color={"white-green"}
            onClick={() => setOpen(true)}
            borderColor={"green"}
          >
            <BodyBold>+ Добавить категорию</BodyBold>
          </Button>
        )}
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        className={styles.modal}
      >
        <div className={styles.modal_head}>
          <BodyBold weight={"bold"}>Добавить направление</BodyBold>
        </div>
        <div className={styles.modal_input}>
          <TextField
            type={"text"}
            required
            label={"Название направления"}
            name={"title"}
            variant="filled"
            onChange={addNewCategory}
          />
          {withTaskGroup && (
            <TextField
              type={"text"}
              required
              label={"Категория плана работ"}
              name={"taskGroup"}
              variant="filled"
              onChange={addNewCategory}
            />
          )}
        </div>
        <div className={styles.modal_buttons}>
          <Button
            color={"white-green"}
            borderColor={"green"}
            onClick={() => setOpen(false)}
          >
            <BodyNormal>Отменить</BodyNormal>
          </Button>
          <Button onClick={onSubmitNewProgram}>
            <BodyNormal>Добавить</BodyNormal>
          </Button>
        </div>
      </Modal>
    </div>
  );
};
