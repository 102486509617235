import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { useEffect, useState } from "react";

import AddingDocumentTable from "@components/tables/sending-for-signature-tables/adding-document-table";
import { DepartmentFileForAddingToPositionDataT } from "@interfaces/company/departments";
import DepartmentService from "@services/department-service";
import PositionsService from "@services/positions-service";
import { WorkspaceGroups } from "@components/company";
import clsx from "clsx";
import style from "@scss/pages/signing-documents.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import { useWorkspaceGroupsQuery } from "@lib/workspace";
import { useSelectedCompanyQuery } from "@lib/company";

const AddingDepartmentDocumentModal = () => {
  const { company } = useSelectedCompanyQuery();

  const permissions = useStore($UserAddPermissions);

  const { groups } = useWorkspaceGroupsQuery(company?.id);

  const { modalComponent, modalData, close } = useModal();

  const [selectedFiles, setSelectedFiles] = useState<Record<number, number[]>>(
    {}
  );
  const [activeGroupId, setActiveGroupId] = useState(0);
  const [files, setFiles] = useState<
    Record<number, DepartmentFileForAddingToPositionDataT[]>
  >({});

  useEffect(() => {
    if (modalData.positionMode) {
      DepartmentService.GetFilesForAddingToPosition(
        {
          departmentId: modalData.departmentId,
          groupId: activeGroupId,
          positionId: modalData.itemId,
        },
        (err, res) => {
          if (err || !res) {
            return console.error("При получении документов произошла ошибка");
          }
          setFiles((state) => ({
            ...state,
            [activeGroupId]: res.data,
          }));

          const hasSelectedDocs = activeGroupId in selectedFiles;
          if (!hasSelectedDocs) {
            setSelectedFiles((state) => ({
              ...state,
              [activeGroupId]: res.data
                .filter((file) => file.isAdded)
                .map((file) => file.id),
            }));
          }
        }
      );
    } else {
      DepartmentService.GetFilesForAddingToDepartment(
        {
          departmentId: modalData.itemId,
          groupId: activeGroupId,
        },
        (err, res) => {
          if (err || !res) {
            return console.error("При получении документов произошла ошибка");
          }
          setFiles((state) => ({
            ...state,
            [activeGroupId]: res.data,
          }));
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeGroupId,
    modalData.itemId,
    modalData.positionMode,
    modalData.departmentId,
  ]);

  const archiveHandler = (fileId: number) => {
    if (!files) return;

    const updatedFiles =
      files[activeGroupId]?.filter((file) => file.id !== fileId) ?? [];
    setFiles((state) => ({
      ...state,
      [activeGroupId]: updatedFiles,
    }));
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      const newSelectedList =
        files[activeGroupId]?.map((file) => file.id) ?? [];
      setSelectedFiles((state) => ({
        ...state,
        [activeGroupId]: newSelectedList,
      }));
    } else {
      setSelectedFiles((state) => ({
        ...state,
        [activeGroupId]: [],
      }));
    }
  };

  const updateButtonDisabledAttr = (value: boolean) => {
    const button = document.querySelector(".modal_btn") as HTMLButtonElement;
    button.disabled = value;
  };

  const handleSubmit = () => {
    updateButtonDisabledAttr(true);
    // ADDING DOCUMENT TO DEPARTMENT
    if (modalData.departmentMode) {
      return DepartmentService.addFiles(
        modalData.itemId,
        selectedFiles[activeGroupId] ?? [],
        (err) => {
          if (err) {
            return console.error("При добавлении документов произошла ошибка");
          }
          updateButtonDisabledAttr(false);
          close();
        }
      );
    }
    // ADDING DOCUMENT TO DEPARTMENT POSITION
    if (modalData.positionMode) {
      let addedFiles = Object.values(selectedFiles).flat();

      const allFiles = Object.values(files).flat();
      const deletedFiles: number[] = [];
      for (const file of allFiles) {
        if (file.isAdded && !addedFiles.includes(file.id)) {
          deletedFiles.push(file.id);
          addedFiles = addedFiles.filter((id) => id !== file.id); // remove from local array of files' ids
        }
      }

      if (deletedFiles.length > 0) {
        PositionsService.removeFiles(
          modalData.departmentId,
          modalData.itemId,
          deletedFiles,
          () => {
            if (addedFiles.length === 0) {
              close();
            }
          }
        );
      }

      if (addedFiles.length > 0) {
        PositionsService.addFiles(
          modalData.departmentId,
          modalData.itemId,
          addedFiles,
          (err) => {
            if (err) {
              return console.error(
                "При добавлении документов произошла ошибка"
              );
            }
            updateButtonDisabledAttr(false);
            close();
          }
        );
      }
    }
  };

  return (
    <div
      key={modalComponent.key}
      className={clsx(style.sending_for_signature_modal)}
    >
      <p className="modal_title">Добавить документы</p>
      <div className="underline" />
      <div className={`modal_content ${style.adding_doc_modal_content}`}>
        {!modalData.departmentMode && !modalData.positionMode ? null : (
          <div className="mb-15">
            {groups && (
              <WorkspaceGroups
                groups={groups}
                activeGroupId={activeGroupId}
                setActiveGroupId={setActiveGroupId}
                hideUnavailableGroups
              />
            )}
          </div>
        )}

        <label
          htmlFor={`counter_of_selected`}
          className={clsx(style.documents_counter)}
        >
          <label
            htmlFor={`counter_of_selected`}
            className={clsx(tableStyle.checkbox_label)}
          >
            Отмечено документов: {selectedFiles[activeGroupId]?.length}
          </label>
        </label>
        <AddingDocumentTable
          selectedDocs={selectedFiles[activeGroupId] ?? []}
          onSelect={(value) =>
            setSelectedFiles((state) => ({
              ...state,
              [activeGroupId]: value,
            }))
          }
          files={files[activeGroupId] ?? []}
          archiveHandler={archiveHandler}
          onSelectAll={handleSelectAll}
        />

        {permissions.hasPermission(
          ACTIONS.companyWorkspace_allowedToDistributionByDepartmentsAndPositions
        ) && (
          <button className="modal_btn" onClick={handleSubmit}>
            Сохранить документы
          </button>
        )}
      </div>
    </div>
  );
};

export default AddingDepartmentDocumentModal;
