import { HTMLAttributes } from "react";
import { useGetCSSVars } from "@lib/use-get-css-vars";

type SVGPropsT = {
  width?: string;
  color?: string;
};

export const PencilSVG: React.FC<
  HTMLAttributes<HTMLOrSVGElement> & SVGPropsT
> = ({ width = "22", color = "green", ...props }) => {
  const currentColor = useGetCSSVars("color", color);

  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.6348 5.48633L21.1494 3.98242C21.8584 3.27344 21.8906 2.41406 21.2031 1.71582L20.5371 1.03906C19.8496 0.34082 18.9688 0.405273 18.249 1.10352L16.7451 2.59668L19.6348 5.48633ZM4.56348 20.5791L18.2275 6.89355L15.3379 4.00391L1.67383 17.6895L0.513672 20.8047C0.298828 21.4062 0.911133 21.9434 1.4375 21.75L4.56348 20.5791Z"
        fill={currentColor}
      />
    </svg>
  );
};
