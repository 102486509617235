import { useMutation, useQueryClient } from "react-query";

import { WorkspaceArchiveFileData } from "@interfaces/company/workspace";
import { workspaceQueryKeys } from "./queryKeys";
import { workspaceService } from "@services/workspace-service";

export const useUnarchiveWorkspaceFileMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: WorkspaceArchiveFileData) => workspaceService.unarchiveFile(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(workspaceQueryKeys.allDirectoriesFiles);
        queryClient.invalidateQueries("arhived");
      },
    }
  );
};
