import {
  $UserAddPermissions,
  ACTIONS,
  UserPriorityEnum,
  setModule,
} from "@store/user-store";
import { CompanyTabPropsT, TariffEnum } from "@interfaces/company/company";
import React, { useEffect } from "react";

import { $AllTariffs } from "@store/payment-store";
import { BackButton } from "@components/back-button";
import Loader from "@ui/indicators/loader";
import { Modules } from "@interfaces/common";
import clsx from "clsx";
import moment from "moment";
import qs from "qs";
import { renderDayWithEnding } from "@utils/renderDayEndingDependsOnCount";
import style from "@scss/pages/company/company-info.module.scss";
import { useLocation } from "react-router-dom";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import { useSelectedCompanyQuery } from "@lib/company";
import { CompanyDiskUsage } from "@components/CompanyDiskUsage";

const CompanyInfo: React.FC<CompanyTabPropsT> = () => {
  const permissions = useStore($UserAddPermissions);
  const allTariffs = useStore($AllTariffs);

  const { company, isLoading, isError } = useSelectedCompanyQuery();

  const { open } = useModal();
  const { search, hash } = useLocation();

  useEffect(() => {
    setModule(Modules.COMPANY_INFO);
  }, []);

  useEffect(() => {
    if (search.includes("edit=1")) {
      onEditCompany();
      window.sessionStorage.setItem(
        "accessToken",
        `${qs.parse(hash, { ignoreQueryPrefix: true })["#access_token"]}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  if (!company || isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <>Произошла ошибка</>;
  }

  const onEditCompany = () => {
    open("CreateCompanyModal", {
      btnText: "Сохранить",
      modalData: company,
    });
  };

  return (
    <section className={`tab-content-item ${clsx(style.company_info)}`}>
      <BackButton link={"/company/companies"} />
      <div className={clsx(style.tab_top_content)}>
        <h5 className={clsx(style.company_name)}>{company.name}</h5>
        <div
          style={{
            display: "flex",
            gap: "2rem",
            alignItems: "center",
            marginLeft: "-50px",
            fontSize: "16px",
          }}
        >
          {permissions.hasRequiredLevel(UserPriorityEnum.Admin) &&
          [1, 2, 3, 4, 5].includes(company.tariff) ? (
            <>
              <div>
                {[TariffEnum.Temp, TariffEnum.Demo].includes(company.tariff) ? (
                  <p className={clsx(style.tarrif_name)}>
                    {company.tariff === 4 ? "Временный" : "Демо дни"}
                  </p>
                ) : (
                  <>
                    <p className={clsx(style.company_info__item)}>
                      Тариф:{" "}
                      <span>
                        {allTariffs && allTariffs[company.tariff - 1].title}
                      </span>
                    </p>
                    {/* <p ></p> */}
                    <p className={clsx(style.company_info__item)}>
                      Срок действия тарифа:{" "}
                      <span>
                        до {moment(company.tariffExpires).format("DD.MM.YYYY")}
                      </span>
                    </p>
                  </>
                )}
              </div>
              <div className="remains-free-space">
                {[TariffEnum.Temp, TariffEnum.Demo].includes(company.tariff) ? (
                  <>
                    <progress
                      value={company.tariffExpires - Date.now()}
                      max={company.tariffExpires - company.tariffStarts}
                    ></progress>
                    <p>
                      {Math.ceil(
                        (company.tariffExpires - Date.now()) / 86400000
                      ) === 1
                        ? "Остался"
                        : "Осталось"}{" "}
                      {Math.ceil(
                        (company.tariffExpires - Date.now()) / 86400000
                      )}{" "}
                      {renderDayWithEnding(
                        Math.ceil(
                          (company.tariffExpires - Date.now()) / 86400000
                        )
                      )}
                    </p>
                  </>
                ) : (
                  <CompanyDiskUsage company={company} />
                )}
              </div>
            </>
          ) : null}
        </div>
        {permissions.hasPermission(ACTIONS.companies_allowedToEdit) && (
          <button
            className={clsx(style.company_edit_btn)}
            onClick={onEditCompany}
          >
            Редактировать
          </button>
        )}
      </div>
      <div className={clsx(style.company_info_section)}>
        <p className={clsx(style.company_info__item)}>
          № договора:{" "}
          <span>
            {company.contractNumber ? company?.contractNumber : "Не указано"}
          </span>
        </p>
        <p className={clsx(style.company_info__item)}>
          Организационно-правовая форма:{" "}
          <span>
            {company?.organizationalLegalForm
              ? company?.organizationalLegalForm
              : "Не указано"}
          </span>
        </p>
        <p className={clsx(style.company_info__item)}>
          ИНН: <span>{company?.inn ? company?.inn : "Не указано"}</span>
        </p>
        <p className={clsx(style.company_info__item)}>
          Адрес фактический:{" "}
          <span>
            {company?.actualAddress ? company?.actualAddress : "Не указано"}
          </span>
        </p>
        <p className={clsx(style.company_info__item)}>
          Адрес юридический:{" "}
          <span>
            {company?.legalAddress ? company.legalAddress : "Не указано"}
          </span>
        </p>
        <p className={clsx(style.company_info__item)}>
          Описание:{" "}
          <span>{company?.shortDesc ? company?.shortDesc : "Не указано"}</span>
        </p>
        <div className={clsx(style.underline)} />
        <div className={clsx(style.company_info_group)}>
          <p className={clsx(style.company_info__item)}>
            {company.leadershipPosition}:{" "}
          </p>
          <div className={clsx(style.company_info_group__list)}>
            <p className={clsx(style.company_info__item)}>
              <span>{company.supervisor}</span>
            </p>
          </div>
        </div>
        {company.specialists.length === 0 ? null : (
          <div className={clsx(style.company_info_group)}>
            <p className={clsx(style.company_info__item)}>Специалисты по ОТ:</p>
            <div className={clsx(style.company_info_group__list)}>
              {company?.specialists?.map((specialist) => (
                <p
                  key={specialist.id}
                  className={clsx(style.company_info__item)}
                >
                  <span>{specialist.name}</span>
                </p>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default CompanyInfo;
