import { useMutation, useQueryClient } from "react-query";
import { WorkspaceGroupData } from "@interfaces/company/workspace";
import { workspaceService } from "@services/workspace-service";
import { workspaceQueryKeys } from "./queryKeys";

export const useCreateWorkspaceGroupMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: WorkspaceGroupData) => workspaceService.createGroup(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(workspaceQueryKeys.allGroups);
      },
    }
  );
};
