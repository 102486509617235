import { switchCompany } from "@store/company/company-store";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import CompanyService from "@services/company-service";
import Dropzone from "@ui/dropzone/dropzone";
import { TextField } from "@material-ui/core";
import Validator from "@utils/validator";
import clsx from "clsx";
import qs from "qs";
import { setPlansLoading } from "@store/specialist-plan-store";
import style from "@scss/modals/company/create-company.module.scss";
import useModal from "@modals/modal-hook";
import useStyles from "@ui/material-ui-styles";
import { useQueryClient } from "react-query";
import { companyQueryKeys } from "@lib/company/queryKeys";
import { useSelectedCompanyQuery } from "@lib/company";

const CreateCompanyModal = () => {
  const queryClient = useQueryClient();

  const { company } = useSelectedCompanyQuery();

  const [validation, setValidation] = useState<any>({});
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const { search, hash } = useLocation();
  const history = useHistory();
  const { close, modalComponent, modalData, open } = useModal();
  const classes = useStyles();

  const handleUnlink = async () => {
    CompanyService.update(
      modalData.id,
      { email: null, accessToken: null },
      (err, res) => {
        if (err || !res) {
          console.error(err);
          return;
        }

        window.sessionStorage.removeItem("accessToken");
        open("CreateCompanyModal", {
          btnText: "Сохранить",
          modalData: { ...modalData, accessToken: null, email: null },
        });
      }
    );
  };

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    id: number | null
  ) => {
    e.preventDefault();
    const modalBtn = document.querySelector(
      "button.modal_btn"
    ) as HTMLButtonElement;
    modalBtn.disabled = true;
    const contractNumber = document.querySelector(
      'input[name="contractNumber"]'
    ) as HTMLInputElement;
    const name = document.querySelector(
      'input[name="name"]'
    ) as HTMLInputElement;
    const organizationalLegalForm = document.querySelector(
      'input[name="organizationalLegalForm"]'
    ) as HTMLInputElement;
    const inn = document.querySelector('input[name="inn"]') as HTMLInputElement;
    const actualAddress = document.querySelector(
      'input[name="actualAddress"]'
    ) as HTMLInputElement;
    const legalAddress = document.querySelector(
      'input[name="legalAddress"]'
    ) as HTMLInputElement;
    const shortDesc = document.querySelector(
      'input[name="shortDesc"]'
    ) as HTMLInputElement;
    const leadershipPosition = document.querySelector<HTMLInputElement>(
      'input[name="leadershipPosition"]'
    );
    const supervisor = document.querySelector<HTMLInputElement>(
      'input[name="supervisor"]'
    );
    const accessToken =
      window.sessionStorage.getItem("accessToken") ??
      modalData.accessToken ??
      null;

    const validating = {
      ...Validator(name.value, "name")
        .isLength({ min: 1, max: 1000 })
        .withMessage("Это поле должно содержать не менее 5 символов")
        .getErrors(),
      ...Validator(inn.value, "inn")
        .isRequired()
        .withMessage("Это поле обязательно")
        .getErrors(),
      ...Validator(shortDesc.value, "shortDesc")
        .isLength({ max: 3000 })
        .withMessage("Длина описания не должна превышать 1022 символа")
        .getErrors(),
    };

    if (Validator.hasError(validating)) {
      modalBtn.disabled = false;
      return setValidation(validating);
    }

    const formData = new FormData();
    formData.append("contractNumber", contractNumber?.value);
    formData.append("name", name?.value);
    formData.append("inn", inn?.value);
    formData.append("legalAddress", legalAddress?.value);
    formData.append("actualAddress", actualAddress?.value);
    formData.append("organizationalLegalForm", organizationalLegalForm?.value);
    formData.append("shortDesc", shortDesc?.value);
    formData.append("leadershipPosition", leadershipPosition?.value ?? "");
    formData.append("supervisor", supervisor?.value ?? "");
    if (accessToken && accessToken !== "null") {
      formData.append("accessToken", accessToken);
    }

    if (uploadedFiles.length) {
      formData.append("companyLogo", uploadedFiles[0]);
    }

    if (id) {
      return CompanyService.update(id, formData, (err, res) => {
        if (err || !res) {
          if (err?.response?.status === 422) {
            modalBtn.disabled = false;
            setValidation(err.response.data);
          }
          return console.error(
            "При обновлении данных компании произошла ошибка"
          );
        }

        queryClient.invalidateQueries(companyQueryKeys.all);
        close();
      });
    }

    CompanyService.create(formData, (err, res) => {
      if (err || !res) {
        if (err?.response?.status === 422) {
          modalBtn.disabled = false;
          setValidation(err.response.data);
        }
        return console.error("При создании новой компании произошла ошибка");
      }
      window.sessionStorage.removeItem("accessToken");

      queryClient.invalidateQueries(companyQueryKeys.all);
      switchCompany({ id: res.data.id });
      setPlansLoading(true);
      history.push("/company");

      close();
    });
  };

  useEffect(() => {
    if (search.includes("edit=1") && modalData.id && company) {
      window.sessionStorage.setItem(
        "accessToken",
        `${qs.parse(hash, { ignoreQueryPrefix: true })["#access_token"]}`
      );
      const accessToken =
        window.sessionStorage.getItem("accessToken") ??
        modalData.accessToken ??
        null;

      const formData = new FormData();
      formData.append("contractNumber", modalData.contractNumber);
      formData.append("name", modalData.name);
      formData.append("inn", modalData.inn);
      formData.append("legalAddress", modalData.legalAddress);
      formData.append("actualAddress", modalData.actualAddress);
      formData.append(
        "organizationalLegalForm",
        modalData.organizationalLegalForm
      );
      formData.append("shortDesc", modalData.shortDesc);
      if (accessToken && accessToken !== "null") {
        formData.append("accessToken", accessToken);
      }
      CompanyService.update(modalData.id, formData, (err, res) => {
        if (err || !res) {
          if (err?.response?.status === 422) {
            const modalBtn = document.querySelector(
              "button.modal_btn"
            ) as HTMLButtonElement;
            modalBtn.disabled = false;
            setValidation(err.response.data);
          }
          return console.error(
            "При обновлении данных компании произошла ошибка"
          );
        }

        queryClient.invalidateQueries(companyQueryKeys.all);

        CompanyService.getById(company.id, (err, res) => {
          if (err || !res) {
            return console.error(
              "Произошла ошибка при получении данных компании"
            );
          }

          open("CreateCompanyModal", {
            btnText: "Сохранить",
            modalData: res.data,
          });
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <div key={modalComponent.key} className={clsx(style.create_company_modal)}>
      <p className="modal_title">
        {modalData.id ? "Редактировать компанию" : "Создать компанию"}
      </p>
      <div className="underline" />
      <form
        className={`modal_content ${classes.root}`}
        onSubmit={(e) => handleSubmit(e, modalData.id || null)}
      >
        <TextField
          name="contractNumber"
          label="№ Договора"
          variant="filled"
          required
          defaultValue={modalData.contractNumber || ""}
        />
        <TextField
          name="name"
          label="Наименование организации"
          variant="filled"
          required
          defaultValue={modalData.name || ""}
        />
        <p className="error-text">{validation.nameError}</p>
        <TextField
          name="organizationalLegalForm"
          label="Организационно-правовая форма"
          variant="filled"
          defaultValue={modalData.organizationalLegalForm || ""}
        />
        <p className="error-text">{validation.organizationalLegalFormError}</p>
        <TextField
          name="inn"
          label="ИНН"
          variant="filled"
          required
          defaultValue={modalData.inn || ""}
        />
        <p className="error-text">{validation.innError}</p>
        <TextField
          name="actualAddress"
          label="Адрес фактический"
          variant="filled"
          defaultValue={modalData.actualAddress || ""}
        />
        <p className="error-text">{validation.actualAddressError}</p>
        <TextField
          name="legalAddress"
          label="Адрес юридический"
          variant="filled"
          required
          defaultValue={modalData.legalAddress || ""}
        />
        <p className="error-text">{validation.legalAddressError}</p>
        <TextField
          name="shortDesc"
          label="Краткое описание"
          variant="filled"
          defaultValue={modalData.shortDesc || ""}
        />
        <p className="error-text">{validation.shortDescError}</p>
        <TextField
          name="leadershipPosition"
          label="Должность руководителя"
          variant="filled"
          required
          defaultValue={modalData.leadershipPosition || ""}
        />
        <p className="error-text">{validation.leadershipPositionError}</p>
        <TextField
          name="supervisor"
          label="Руководитель"
          variant="filled"
          required
          defaultValue={modalData.supervisor || ""}
        />
        <p className="error-text">{validation.supervisorError}</p>
        <div className={style.email}>
          {modalData.email ? (
            <span>Текущая техническая почта компании: </span>
          ) : (
            <span>Подключить техническую почту компании </span>
          )}
          {modalData.email ? (
            <span> {modalData.email}</span>
          ) : (
            <div className={clsx(style.yandex)}>
              {/* b4a4db36a7f5415f8738b27b7a160e46 */}
              <a
                href={`https://oauth.yandex.ru/authorize?response_type=token&client_id=${process.env.YANDEX_APP_CLIENT_ID}&scope=mail:smtp login:email login:info&force_confirm=true`}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 44 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="44" height="44" rx="22" fill="#FC3F1D" />
                  <path
                    d="M25.2438 12.3208H23.0173C19.2005 12.3208 17.292 14.2292 17.292 17.0919C17.292 20.2726 18.5643 21.863 21.427 23.7714L23.6535 25.3618L17.292 35.222H12.2029L18.2463 26.316C14.7475 23.7714 12.839 21.5449 12.839 17.41C12.839 12.3208 16.3378 8.82202 23.0173 8.82202H29.6969V35.222H25.2438V12.3208Z"
                    fill="white"
                  />
                </svg>
                <span>Войти через Яндекс Почту</span>
              </a>
            </div>
          )}
        </div>
        {modalData.email ? (
          <>
            <button
              className="modal_btn"
              style={{ margin: "0 0 10px 0" }}
              onClick={handleUnlink}
            >
              Отвязать?
            </button>
            <p className="error-text large">
              Уважаемый пользователь, для корректной работы отправки писем с
              привязанной почты обязательно необходимо включить imap протокол в
              настройках почтового ящика (раздел "Почтовые программы")!
              <a
                target="_blank"
                href="https://yandex.ru/support/mail/mail-clients/others.html#imap__imap-prep-config"
                rel="noreferrer"
              >
                {" "}
                Ссылка на инструкцию
              </a>
            </p>
          </>
        ) : (
          <p>С этой почты будет производиться рассылка вашим сотрудникам</p>
        )}
        <Dropzone
          maxFiles={1}
          onUpload={(files) => setUploadedFiles(files)}
          accept="image/jpeg, image/png"
          type={"company"}
        />
        <p className="error-text">{validation.imageError}</p>
        <button type="submit" className="modal_btn">
          {modalComponent.btnText}
        </button>
      </form>
    </div>
  );
};

export default CreateCompanyModal;
