import { FC } from "react";
import { useProjectNewsQuery } from "@lib/project-news";
import { BreadCrumb, ColorfulButton, Title } from "@components/common";
import { useStore } from "effector-react";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { ErrorIndicator, Loader } from "@ui/indicators";
import NewsItems from "@components/news-components";

export const ProjectNews: FC = () => {
  const permissions = useStore($UserAddPermissions);

  const { data, isLoading, error, hasNextPage, fetchNextPage } =
    useProjectNewsQuery(16);

  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={["Главная", "Новости проекта"]} />
          <div className="content-title-section-with-btn">
            <Title text="Новости проекта" />
            {permissions.hasPermission(ACTIONS.projectNews_allowedToCreate) && (
              <ColorfulButton
                link={"/project-news/create"}
                text={"Создать новость"}
              />
            )}
          </div>
        </div>
        {error ? (
          <ErrorIndicator />
        ) : isLoading ? (
          <Loader />
        ) : (
          data?.pages.map((page) => (
            <NewsItems items={page.result.rows} path="project-news" />
          ))
        )}
        {hasNextPage && (
          <button className="pagination-button" onClick={() => fetchNextPage()}>
            Показать еще
          </button>
        )}
      </div>
    </main>
  );
};
