import React, { useMemo, useState } from "react";
import clsx from "clsx";
import styles from "./image-preview.module.scss";
import { CloseSVG } from "@components/svgs";

export type DropzoneImagePreviewProps = JSX.IntrinsicElements["div"] & {
  file: File;
  onDelete: () => void;
};

export const DropzoneImagePreview: React.FC<DropzoneImagePreviewProps> = ({
  file,
  className,
  onDelete,
  ...props
}) => {
  const [isDeleteBtnVisible, setIsDeleteBtnVisible] = useState(false);

  const image = useMemo(() => URL.createObjectURL(file), [file]);

  const handleDelete = () => {
    URL.revokeObjectURL(file.name);
    onDelete();
  };

  return (
    <div
      className={clsx(styles.ImagePreview, className)}
      {...props}
      onPointerEnter={() => setIsDeleteBtnVisible(true)}
      onPointerLeave={() => setIsDeleteBtnVisible(false)}
    >
      {isDeleteBtnVisible && (
        <div className={styles.ImagePreview__deleteBtn} onClick={handleDelete}>
          <CloseSVG color="#ffffff" />
        </div>
      )}
      <img src={image} alt="" className={styles.ImagePreview__image} />
    </div>
  );
};
