import { FC, HTMLAttributes, memo } from "react";
import { MuiThemeProvider, createStyles } from "@material-ui/core/styles";

import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";

const theme = createStyles({
  overrides: {
    MuiTabs: {
      root: {
        minHeight: 32,
      },
      flexContainer: {
        border: "2px solid #CED1D6",
        borderRadius: "50px",
        width: "calc(100% - 6px)",
        marginRight: "2px",
      },
      indicator: {
        minHeight: 36,
        marginLeft: "-2px",
        backgroundColor: "#00B856",
        borderRadius: "35px",
        paddingLeft: 100,
      },
    },
    MuiTab: {
      root: {
        minHeight: 32,
        textTransform: "normal",
        fontFamily: "Source Sans Pro",
        fontSize: 18,
        lineHeight: 24,
        color: "#ffffff",
      },
      textColorInherit: {
        color: "#000000",
      },
      selected: {
        backgroundColor: "#00000000",
        zIndex: 1000,
        color: "#ffffff",
        maxHeight: 32,
        minHeight: 32,
      },
      wrapper: {
        maxHeight: 32,
      },
    },
    MuiButtonBase: {
      root: {
        maxHeight: 32,
        minHeight: 32,
      },
    },
  },
});

type Props = Omit<HTMLAttributes<HTMLInputElement>, "onChange"> & {
  onChange: (value: number) => void;
  value?: number;
  labels: string[];
};

export const TabSwicher: FC<Props> = memo(({ onChange, value = 0, labels }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <div>
        <Tabs value={value} onChange={(e, index) => onChange(index)}>
          {labels.map((label) => (
            <Tab key={label} label={label} />
          ))}
        </Tabs>
      </div>
    </MuiThemeProvider>
  );
});
