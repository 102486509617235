import { FC } from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { SigningControlJournalRow } from "@interfaces/signing-control";
import { SignatureStatusEnum } from "@interfaces/company/departments";
import moment from "moment";
import styles from "./signing-control-journal-table.module.scss";
import { UseSortingResult } from "@hooks/useSorting";
import clsx from "clsx";
import {
  ArrowDropDownGraySVG,
  CheckboxCheckedSVG,
  CheckboxSVG,
} from "@components/svgs";
import { Checkbox } from "@material-ui/core";
import { ChoiceState } from "@hooks/useChoiceState";

const head = [
  {
    label: "№ п/п",
  },
  {
    label: "Подразделение",
    sortKey: "departmentTitleOrder",
  },
  {
    label: "Должность",
    sortKey: "positionNameOrder",
  },
  {
    label: "Сотрудник",
    sortKey: "employeeNameOrder",
  },
  {
    label: "Наименование документа",
    sortKey: "filenameOrder",
  },
  {
    label: "Статус",
  },
  {
    label: "Дата подписания",
    sortKey: "signedAtOrder",
  },
];

export type SigningControlJournalTableProps = {
  data: SigningControlJournalRow[];
  offset: number;
  sorting: UseSortingResult;
  choice: ChoiceState<SigningControlJournalRow>;
};

export const SigningControlJournalTable: FC<
  SigningControlJournalTableProps
> = ({ data, offset, sorting, choice }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Checkbox
              color="primary"
              icon={<CheckboxSVG />}
              checkedIcon={<CheckboxCheckedSVG />}
              checked={choice.areAllItemsSelected}
              onChange={(e) => choice.selectSeveral(data, e.target.checked)}
            />
          </TableCell>
          {head.map((col, i) => (
            <TableCell
              key={i}
              tabIndex={0}
              onClick={() =>
                col.sortKey && sorting.onItemSortingChange(col.sortKey)
              }
            >
              <div
                className={clsx(styles.signingControlJournalTable__headCell, {
                  [styles.signingControlJournalTable__indexCell]: i === 0,
                })}
              >
                <span>{col.label}</span>
                {col.sortKey && (
                  <div className={styles.signingControlJournalTable__sort}>
                    <div
                      className={clsx(
                        styles.signingControlJournalTable__sortIcon,
                        {
                          [styles.signingControlJournalTable__sortIcon_rotated]:
                            sorting?.shouldIconRotate(col.sortKey),
                        }
                      )}
                    >
                      <ArrowDropDownGraySVG />
                    </div>
                  </div>
                )}
              </div>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, i) => (
          <TableRow key={i}>
            <TableCell width={70}>
              <Checkbox
                color="primary"
                icon={<CheckboxSVG />}
                checkedIcon={<CheckboxCheckedSVG />}
                checked={choice.getIsItemSelected(row)}
                onChange={(e) => choice.selectById(row, e.target.checked)}
              />
            </TableCell>
            <TableCell width={50}>{offset + i + 1}</TableCell>
            <TableCell width={200}>
              {row.signer.position.department.title}
            </TableCell>
            <TableCell width={200}>{row.signer.position.title}</TableCell>
            <TableCell width={200}>
              <span className={styles.signingControlJournalTable__employeeName}>
                {row.signer.name}
              </span>
            </TableCell>
            <TableCell width={300} title={row.file.name}>
              <span className={styles.signingControlJournalTable__filename}>
                {row.file.name}
              </span>
            </TableCell>
            <TableCell width={150}>{formatStatus(row.status)}</TableCell>
            <TableCell width={150}>
              {row.signedAt && (
                <span>{moment(row.signedAt).format("DD.MM.YYYY")}</span>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

const formatStatus = (status: SignatureStatusEnum): string => {
  const obj: { [key in SignatureStatusEnum]: string } = {
    [SignatureStatusEnum.signed]: "Документ подписан",
    [SignatureStatusEnum.expired]: "Истекло",
    [SignatureStatusEnum.waitingForSignature]: "Отправлено на подписание",
  };

  return obj[status];
};
