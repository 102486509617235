import { FC, FormEvent, useState } from "react";
import { TextField } from "@material-ui/core";
import { Modal } from "@ui/modal";
import styles from "./add-public-category-modal.module.scss";
import { useCreateInternalCategoryMutation } from "@lib/internal-study-center";

export type AddPublicCategoryModalProps = {
  open: boolean;
  sectionId: number;
  onClose: () => void;
};

export const AddPublicCategoryModal: FC<AddPublicCategoryModalProps> = ({
  open,
  sectionId,
  onClose,
}) => {
  const createCategoryMutation = useCreateInternalCategoryMutation();

  const [category, setCategory] = useState("");

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    createCategoryMutation.mutate(
      { name: category, sectionId },
      {
        onSuccess: () => {
          setCategory("");
          onClose();
        },
      }
    );
  };

  return (
    <Modal open={open} onClose={onClose} className={styles.modal}>
      <p className={styles.modal__title}>Добавить категорию</p>
      <div className="underline" />
      <form onSubmit={onSubmit} className={styles.modal__form}>
        <TextField
          variant="filled"
          label="Название категории"
          required
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
        <button type="submit" className="modal_btn">
          Добавить
        </button>
      </form>
    </Modal>
  );
};
