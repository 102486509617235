import {
  useCreateDocumentTemplatesDirectoryMutation,
  useUpdateDocumentTemplatesDirectoryMutation,
} from "@lib/document-templates";

import { TextField } from "@material-ui/core";
import qs from "qs";
import { useHistory } from "react-router-dom";
import useModal from "@modals/modal-hook";
import useStyles from "@ui/material-ui-styles";
import { useSelectedCompanyQuery } from "@lib/company";

const CreateFolderDocumentTemplatesModal = () => {
  const { company } = useSelectedCompanyQuery();

  const createDirectoryMutation = useCreateDocumentTemplatesDirectoryMutation();
  const updateDirectoryMutation = useUpdateDocumentTemplatesDirectoryMutation();

  const { modalComponent, modalData, close } = useModal();
  const classes = useStyles();
  const history = useHistory();

  const handleSubmit = (e: any) => {
    if (!company) return;

    e.preventDefault();
    const directoryTitle = document.querySelector(
      'input[name="directoryTitle"]'
    ) as HTMLInputElement;
    const modalBtn = document.querySelector(
      "button.modal_btn"
    ) as HTMLButtonElement;
    modalBtn.disabled = true;

    // CREATING FOLDER
    if (directoryTitle?.value && !modalData?.item?.id) {
      const queryString = qs.parse(history.location.search, {
        ignoreQueryPrefix: true,
      });

      return createDirectoryMutation.mutate(
        {
          companyId: company.id,
          groupId: modalData.groupId,
          directoryId: queryString.folder_id ? +queryString.folder_id : 0,
          name: directoryTitle.value,
        },
        {
          onSuccess: () => {
            close();
          },
        }
      );
    }

    // UPDATING FOLDER
    if (directoryTitle?.value && modalData?.item?.id) {
      return updateDirectoryMutation.mutate(
        {
          companyId: company.id,
          groupId: modalData.groupId || 0,
          directoryId: modalData.item.id,
          name: directoryTitle.value,
        },
        {
          onSuccess: () => {
            close();
          },
        }
      );
    }
  };

  return (
    <div key={modalComponent.key}>
      <p className="modal_title">{modalData.modalTitle}</p>
      <div className="underline" />
      <form className={`modal_content ${classes.root}`} onSubmit={handleSubmit}>
        <TextField
          label={"Название папки"}
          name={"directoryTitle"}
          variant="filled"
          required
          defaultValue={modalData.itemTitle}
        />
        <button type="submit" className="modal_btn">
          {modalComponent.btnText}
        </button>
      </form>
    </div>
  );
};

export default CreateFolderDocumentTemplatesModal;
