import { BodyNormal, Subheader, Subtitle } from "@ui/fonts";

import { CheckAround } from "@components/svgs";
import { ImageBlock } from "./components/ImageBlock";
import { ItemRow } from "./components/resources";
import { TextBlock } from "./components/TextBlock";
import styles from "./goskey.module.scss";

export const Goskey: React.FC = () => {
  return (
    <div className={styles.content}>
      <div className={styles.head}>
        <div className={styles.head_title}>
          <Subtitle weight="bold">Уважаемые Пользователи!</Subtitle>
          <Subtitle weight="bold">
            «ОТсервис» имеет возможность подписания документов
          </Subtitle>
        </div>
        <div className={styles.head_icon}>
          <div className={styles.gos_image}>
            <img src="/img/static/goskey.png" alt="" />
          </div>
          <div className={styles.gos_title}>
            <Subtitle weight={"medium"} color={"blue"}>
              Система Госключ
            </Subtitle>
          </div>
        </div>
      </div>
      <div className={styles.boxes}>
        {ItemRow.map((item, i) => (
          <div key={i} className={styles.boxes_item}>
            <div className={styles.item_title}>
              <Subheader weight="bold" color={"green"}>
                {item.title}
              </Subheader>
            </div>
            <div>
              {item.li.map((item, i) => (
                <div key={i} className={styles.boxes_li}>
                  <div className={styles.boxes_img}>
                    <CheckAround />
                  </div>
                  <div>
                    <BodyNormal>{item}</BodyNormal>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.info}>
        <TextBlock />
        <ImageBlock />
      </div>
    </div>
  );
};
