import { useSorting } from "@hooks/useSorting";
import { GetWorkplanTasksFilters } from "@interfaces/workplan";
import { useSelectedCompanyQuery } from "@lib/company";
import { useWorkplanGroupsQuery } from "@lib/workplan";
import { WorkplanTable } from "feature/specialist-plan/workplan-table/workplan-table";
import { Moment } from "moment";
import { useEffect, useState } from "react";

const sortingKeys = ["startDateOrder", "deadlineOrder", "statusOrder"];
const allStatuses = [1, 2, 3, 4, 5, 6];

type SelectListType = {
  id: number;
  title: string;
};

export const WorkplanPage = () => {
  const { company } = useSelectedCompanyQuery();

  const [resetCategory, setResetCategory] = useState(false);
  const [resetCompany, setResetCompany] = useState(false);
  const [resetStatus, setResetStatus] = useState(false);

  const [filters, setFilters] = useState<GetWorkplanTasksFilters>({
    companies: undefined,
    statuses: allStatuses,
    groups: [],
    startDate: null,
    deadline: null,
  });

  const sorting = useSorting(sortingKeys, "ASC");

  const { data } = useWorkplanGroupsQuery({
    companies: company ? [company.id] : [],
  });

  useEffect(() => {
    if (!data) return;

    setFilters((prev) => ({
      ...prev,
      groups: data.map((group) => group.title),
    }));
  }, [data]);

  useEffect(() => {
    if (!company) return;

    setFilters((prev) => ({
      ...prev,
      companies: [company.id],
    }));
  }, [company]);

  const handlerReset = () => {
    setResetCategory(false);
    setResetCompany(false);
    setResetStatus(false);
  };

  const onStartDateChange = (date: Moment) => {
    if (!date || !date.isValid()) {
      return setFilters((prev) => ({
        ...prev,
        startDate: null,
      }));
    }

    setFilters((prev) => ({
      ...prev,
      startDate: date.toDate(),
    }));
  };
  const onEndDateChange = (date: Moment) => {
    if (!date || !date.isValid()) {
      return setFilters((prev) => ({
        ...prev,
        deadline: null,
      }));
    }

    setFilters((prev) => ({
      ...prev,
      deadline: date.toDate(),
    }));
  };

  const handleIsArchivedChange = (checked: boolean) => {
    if (checked) {
      setFilters((prev) => ({
        ...prev,
        statuses: [...(prev.statuses ?? []), 7],
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        statuses: (prev.statuses ?? []).filter((status) => status !== 7),
      }));
    }
  };

  const handleCompaniesChange = (values: SelectListType[]) => {
    setFilters((prev) => ({
      ...prev,
      companies: values.map((value) => value.id),
    }));
  };

  const handleCategoriesChange = (values: SelectListType[]) => {
    setFilters((prev) => ({
      ...prev,
      groups: values.map((value) => value.title),
    }));
  };

  const handleStatusesChange = (values: SelectListType[]) => {
    setFilters((prev) => ({
      ...prev,
      statuses: values.map((value) => value.id),
    }));
  };

  const clearAllFilters = () => {
    if (!company) return;

    setFilters({
      companies: [],
      statuses: allStatuses,
      groups: [],
      startDate: null,
      deadline: null,
    });
    setResetCategory(true);
    setResetStatus(true);
  };

  return (
    <WorkplanTable
      groups={data ?? []}
      filters={filters}
      sorting={sorting}
      handlerArchive={handleIsArchivedChange}
      companyId={company?.id}
      isCompanyDefined={true}
      selectedCompanyId={company?.id}
      resetCategory={resetCategory}
      resetStatus={resetStatus}
      resetCompany={resetCompany}
      handlerChoiceCompany={handleCompaniesChange}
      handlerChoiceCategory={handleCategoriesChange}
      handlerChoiceStatus={handleStatusesChange}
      handlerReset={handlerReset}
      onStartDateChange={onStartDateChange}
      onEndDateChange={onEndDateChange}
      clearAllFilters={clearAllFilters}
    />
  );
};
