import { ProjectNewsItem } from "@interfaces/project-news";
import ProjectNewsService from "@services/project-news-service";
import { useQuery } from "react-query";
import { projectNewsQueryKeys } from "./queryKeys";

export const useProjectNewsItemQuery = (id: string | undefined) => {
  return useQuery(
    projectNewsQueryKeys.byId(id),
    async () => {
      return new Promise<ProjectNewsItem>((resolve, reject) => {
        ProjectNewsService.getById(id!, (err, res) => {
          if (err || !res) {
            reject(err);
          } else {
            resolve(res.data);
          }
        });
      });
    },
    {
      enabled: typeof id !== "undefined",
    }
  );
};
