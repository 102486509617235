import clsx from "clsx";
import qs from "qs";

import useModal from "@modals/modal-hook";
import useStyles from "@ui/material-ui-styles";

import { TextField } from "@material-ui/core";

import style from "@scss/modals/company/workspace-document.module.scss";
import { useHistory } from "react-router-dom";
import { useUpdateDocumentTemplatesFileMutation } from "@lib/document-templates";

const EditDocumentTemplatesFileModal = () => {
  const updateFileMutation = useUpdateDocumentTemplatesFileMutation();

  const { modalComponent, modalData, close } = useModal();
  const classes = useStyles();
  const history = useHistory();

  const handleSubmit = (e: any) => {
    e.preventDefault();

    const modalBtn = document.querySelector(
      "button.modal_btn"
    ) as HTMLButtonElement;
    modalBtn.disabled = true;

    const titleField = document.querySelector(
      'input[name="title"]'
    ) as HTMLInputElement;

    const queryString = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    if (titleField && modalData?.item?.id) {
      const data = { name: titleField.value };

      const groupId = queryString.group_id ? +queryString.group_id : 0;
      const directoryId = queryString.folder_id ? +queryString.folder_id : 0;

      updateFileMutation.mutate(
        {
          groupId,
          directoryId,
          fileId: modalData.item.id,
          ...data,
        },
        {
          onSuccess: () => {
            close();
          },
        }
      );
    }
  };

  return (
    <div className={clsx(style.workspace_document_modal)}>
      <p className="modal_title">Изменить файл</p>
      <div className="underline" />
      <form className={`modal_content ${classes.root}`} onSubmit={handleSubmit}>
        <TextField
          label="Название файла"
          variant="filled"
          name="title"
          required
          defaultValue={modalData?.item?.name}
        />
        <button type="submit" className="modal_btn">
          {modalComponent.btnText}
        </button>
      </form>
    </div>
  );
};

export default EditDocumentTemplatesFileModal;
