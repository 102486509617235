import Fetcher from "@http/fetcher";
import { GetAllRolesT } from "@interfaces/feedback";

import { ResCallback } from "@interfaces/common";

class RolesPermissionsService {
  static async CreateRole(data: any, cb: ResCallback<any>) {
    try {
      const res = await Fetcher.post<any>("/roles-permissions", data);

      if (res.status !== 200) {
        throw new Error();
      }
      return cb(null, res);
    } catch (err) {
      // @ts-ignore
      return cb(err);
    }
  }

  static async GetRolesNPermissions(
    exactCompany: boolean,
    companyId: number,
    priority: number,
    cb: ResCallback<any>
  ) {
    try {
      const res = await Fetcher.get<any>("/roles-permissions", {
        params: {
          exactCompany: exactCompany,
          companyId: companyId,
          priority,
        },
      });

      if (res.status !== 200) {
        throw new Error();
      }
      return cb(null, res);
    } catch (err) {
      // @ts-ignore
      return cb(err);
    }
  }

  static async ChangeRolePermissions(
    { roleId, permissionId }: any,
    cb: ResCallback<any>
  ) {
    try {
      const res = await Fetcher.put<any>("/roles-permissions", {
        roleId,
        permissionId,
      });

      if (res.status !== 200) {
        throw new Error();
      }
      return cb(null, res);
    } catch (err) {
      // @ts-ignore
      return cb(err);
    }
  }

  static async GetAllRoles(cb: ResCallback<GetAllRolesT[]>) {
    try {
      const res = await Fetcher.get<GetAllRolesT[]>(`/roles-permissions/roles`);

      if (res.status !== 200) {
        throw new Error();
      }
      return cb(null, res);
    } catch (err) {
      // @ts-ignore
      return cb(err);
    }
  }

  // static async CreateFeedback(formData: FormData, cb: ResCallback<FeedbackT>) {
  //     try {
  //         const res = await Fetcher.put<FeedbackT>('/technical-support', formData)

  //         if (res.status !== 201) {
  //             throw new Error()
  //         }
  //         return cb(null, res)
  //     } catch (err) {
  //         return cb(err)
  //     }
  // }

  // static async RespondToFeedback(id: number, answer: string, cb: ResCallback<string>) {
  //     try {
  //         const res = await Fetcher.post<string>(`/technical-support/${id}`, { answer })

  //         if (res.status !== 200) {
  //             throw new Error()
  //         }
  //         return cb(null, res)
  //     } catch (err) {
  //         return cb(err)
  //     }
  // }
}

export default RolesPermissionsService;
