import "@scss/components/reset-calendar.scss";

import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { $Warning, $WarningStates, FetchWarning } from "@store/warning-store";
import { BreadCrumb, TabItems } from "@components/common";
import { Route, Switch, useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";

import Archive from "@pages/company/archive";
import { BodyBold } from "@ui/fonts";
import Companies from "./companies";
import CompanyInfo from "@pages/company/company-info";
import CreatePrescription from "@pages/prescription/create-prescription";
import Departments from "@pages/company/departments";
import { EditRequestTraining } from "feature/request-training/edit-request-training";
import Employees from "@pages/company/employees";
import EmployeesCreate from "./employees/employees-create";
import EventCalendar from "@pages/company/calendar";
import Prescriptions from "@pages/prescription/prescriptions";
import ProcedureControlBriefings from "@pages/company/procedure-control-briefings";
import ProcedureControlTraning from "@pages/company/procedure-control-traning";
import RequestTrainingPage from "./request-training";
import SigningControl from "@pages/company/signing-control";
import SingleDepartment from "@pages/company/single-department";
import SingleDocumentsPage from "./single-documents";
import SinglePositionPage from "./single-position";
import SinglePrescription from "@pages/prescription/single-prescription";
import Workspace from "@pages/company/workspace";
import style from "@scss/pages/company/CompanyHeadSelect.module.scss";
import { useSelectedCompanyQuery } from "@lib/company";
import { useStore } from "effector-react";
import { WorkplanPage } from "feature/workplan";
import { getCompanyLogo } from "@utils/getCompanyLogo";
import ProcedureControlMedicalExams from "./procedure-control-medical-exams";

const pathnameToTitle: { [key: string]: string } = {
  "/company/companies": "Компании",
  "/company": "Информация об организации",
  "/company/workspace": "Рабочий блок",
  "/company/departments": "Подразделения",
  "/company/employees": "Сотрудники",
  "/company/calendar": "Календарь",
  "/company/workplan": "План работы",
  "/company/signing-control": "Контроль подписания документов",
  "/company/procedure-control/training": "Контроль выполнения процедур",
  "/company/procedure-control/briefings": "Контроль выполнения процедур",
  "/company/request-training": "Заявки на обучение",
  "/company/prescriptions": "Предписания",
  "/company/archive": "Архив",
};

const Company = () => {
  const [breadCrumbs, setBreadCrumbs] = useState<Array<string>>([
    "Главная",
    "Компания",
  ]);

  const { company } = useSelectedCompanyQuery();

  const [title, setTitle] = useState<string>("Компания");

  const permissions = useStore($UserAddPermissions);

  const { pathname } = useLocation();

  useEffect(() => {
    const newTitle = pathnameToTitle[pathname] ?? title;
    const newBreadCrumbs = ["Главная", "Компания", newTitle];

    setTitle(newTitle);
    setBreadCrumbs(newBreadCrumbs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const TAB_ITEMS = useMemo(() => {
    const tabs = [
      { label: "Компании", path: "/company/companies" },
      { label: "Информация об организации", path: "/company", exact: true },
      { label: "Рабочий блок", path: "/company/workspace" },
      { label: "Подразделения", path: "/company/departments" },
      { label: "Сотрудники", path: "/company/employees" },
      { label: "Календарь", path: "/company/calendar" },
    ];
    if (
      permissions.hasPermission(ACTIONS.companySpecialistWorkPlan_allowedToShow)
    ) {
      tabs.push({ label: "План работы", path: "/company/workplan" });
    }
    if (
      permissions.hasPermission(
        ACTIONS.companyDocumentSigningControl_allowedToShow
      )
    ) {
      tabs.push({
        label: "Контроль подписания документов",
        path: "/company/signing-control",
      });
    }
    if (
      permissions.hasPermission(ACTIONS.companyProcedureControls_allowedToShow)
    ) {
      tabs.push({
        label: "Контроль выполнения процедур",
        path: "/company/procedure-control/training",
      });
    }
    if (
      permissions.hasPermission(
        ACTIONS.companyApplicationsForTraining_allowedToShow
      )
    ) {
      tabs.push({
        label: "Заявки на обучение",
        path: "/company/request-training",
      });
    }
    tabs.push({
      label: "Предписания",
      path: "/company/prescriptions",
    });

    const hasArchiveTab = tabs.find((item) => item.label === "Архив");
    if (!hasArchiveTab) {
      tabs.push({ label: "Архив", path: "/company/archive" });
    }

    return tabs;
  }, [permissions]);
  const warning = useStore($Warning);
  const warningState = useStore($WarningStates);

  useEffect(() => {
    if (!company) return;
    FetchWarning(company.id);
  }, [company]);

  return (
    <main className="content-container company">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={breadCrumbs} />
          <img
            src={company ? getCompanyLogo(company?.image) : undefined}
            alt=""
            style={{
              maxWidth: "400px",
              maxHeight: "100px",
              objectFit: "contain",
              marginTop: "24px",
            }}
          />
        </div>
        <div className={style.companyName}>
          <BodyBold weight="bold">{company?.name}</BodyBold>
        </div>
        <div className="tab-section">
          <div className="tab-cont">
            <TabItems
              items={TAB_ITEMS}
              warning={warning}
              warningLoading={warningState.isLoading}
              currentPath={pathname}
            />
            <div className="tab-content-items">
              <Switch>
                <Route exact path={"/company"} component={CompanyInfo} />
                <Route
                  exact
                  path={"/company/companies"}
                  component={Companies}
                />
                <Route
                  exact
                  path={[
                    "/company/workspace",
                    "/company/workspace/:directoryId",
                  ]}
                >
                  <Workspace />
                </Route>
                <Route exact path={"/company/departments"}>
                  <Departments />
                </Route>
                <Route
                  exact
                  path={"/company/departments/:id"}
                  component={SingleDepartment}
                />
                <Route exact path={"/company/departments/:id/:id"}>
                  <SinglePositionPage />
                </Route>
                <Route exact path={"/company/departments-documents/:id/:id"}>
                  <SingleDocumentsPage />
                </Route>
                <Route exact path={"/company/employees"}>
                  <Employees />
                </Route>
                <Route
                  path={[
                    "/company/employees/create",
                    "/company/:departmentId/employees/create",
                  ]}
                >
                  <EmployeesCreate />
                </Route>
                <Route path={["/company/employees/:id"]}>
                  <EmployeesCreate type="edit" />
                </Route>
                <Route exact path={"/company/calendar"}>
                  {company && (
                    <EventCalendar
                      isCompanyDefined={true}
                      defaultCompanyId={company.id}
                    />
                  )}
                </Route>
                <Route exact path={"/company/archive"}>
                  <Archive />
                </Route>
                <Route exact path={"/company/workplan"}>
                  <WorkplanPage />
                </Route>
                <Route
                  exact
                  path={"/company/signing-control"}
                  component={SigningControl}
                >
                  <SigningControl />
                </Route>
                <Route
                  exact
                  path={["/company/procedure-control/training"]}
                  component={ProcedureControlTraning}
                >
                  <ProcedureControlTraning />
                </Route>
                <Route
                  exact
                  path={["/company/procedure-control/briefings"]}
                  component={ProcedureControlBriefings}
                >
                  <ProcedureControlBriefings />
                </Route>
                <Route exact path="/company/procedure-control/medical-exams">
                  <ProcedureControlMedicalExams />
                </Route>

                <Route
                  exact
                  path={"/company/request-training"}
                  component={RequestTrainingPage}
                >
                  <RequestTrainingPage />
                </Route>
                <Route
                  exact
                  path={"/company/request-training/:id"}
                  component={EditRequestTraining}
                >
                  <EditRequestTraining />
                </Route>
                <Route
                  exact
                  path={"/company/prescriptions"}
                  component={Prescriptions}
                />
                <Route
                  exact
                  path={"/company/prescriptions/create"}
                  component={CreatePrescription}
                />
                <Route
                  exact
                  path={"/company/prescriptions/:id"}
                  component={SinglePrescription}
                />
                <Route
                  exact
                  path={"/company/prescriptions/:id/edit"}
                  component={CreatePrescription}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Company;
