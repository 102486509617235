import { studyCenterService } from "@services/study-center";
import { useInfiniteQuery } from "react-query";
import { studyCenterQueryKeys } from "./queryKeys";

type AllEducationalPromotions = {
  offset?: number;
  limit?: number;
  enabled?: boolean;
};

export const useStudyCenterEducationalPromotionsQuery = (
  props: AllEducationalPromotions = {}
) => {
  const { offset = 0, limit = 24, enabled = true } = props;
  return useInfiniteQuery(
    studyCenterQueryKeys.educationalPromotions(),
    async ({ pageParam = offset }) => {
      return await studyCenterService.getPromotions({
        limit,
        offset: pageParam,
      });
    },
    {
      enabled,
      getNextPageParam: () => offset + limit,
    }
  );
};
