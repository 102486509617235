import "@scss/pages/question-answer.scss";
import { FC, useState } from "react";

import clsx from "clsx";

import styles from "./FeedbackInfo.module.scss";
import { BodyNormal } from "@ui/fonts";
import { FileT } from "@interfaces/common";
import { concatApiUrl } from "@utils/api-tools";
import { downloadFile } from "@utils/downloadFile";
import { FeedbackToggle } from "..";

type FeedbackInfoPropsT = {
  className?: string;
  title?: string;
  description?: string;
  files?: FileT[];
  isMobile?: boolean;
  isClosed?: boolean;
  setIsClosed?: (is: boolean) => void;
  feedBackId?: string;
};

export const FeedbackInfo: FC<FeedbackInfoPropsT> = ({
  className,
  title,
  description,
  files,
  isMobile,
  isClosed,
  setIsClosed,
  feedBackId,
}) => {
  const [isInfoExpanded, setIsInfoExpanded] = useState<boolean>(false);
  const urlLoadedFile = concatApiUrl("/uploads/technical-support/issues/");

  const handleToggleInfo = () => setIsInfoExpanded((prev) => !prev);

  const isTitleLarge = (title?.length || 0) > (isMobile ? 16 : 32);

  return (
    <div className={clsx(styles.info, isMobile && styles.info_mobile)}>
      {isMobile && (
        <div className={clsx(styles.closedStatus, styles.closedStatus_mobile)}>
          <FeedbackToggle
            isClosed={isClosed}
            setIsClosed={setIsClosed}
            feedBackId={feedBackId}
          />
        </div>
      )}
      <div
        className={clsx(
          "qa-tab__item",
          "shadow-sm",
          isInfoExpanded && "active",
          styles.tab,
          isMobile && styles.tab_mobile
        )}
      >
        <div
          className={clsx("qa-tab__trigger", styles.tab__trigger)}
          onClick={handleToggleInfo}
        >
          <img
            src="/img/static/green-arrow_drop_down.png"
            alt=""
            className="qa-tab__arrow"
          />
          <div
            className={clsx(
              styles.info__topicContainer,
              isInfoExpanded &&
                isTitleLarge &&
                styles.info__topicContainer_expanded
            )}
          >
            <BodyNormal weight="bold">Тема обращения:</BodyNormal>
            <BodyNormal
              className={clsx(
                styles.info__topic,
                isInfoExpanded && isTitleLarge && styles.info__topic_expanded
              )}
            >
              {title ?? ""}
            </BodyNormal>
          </div>
        </div>
        <div
          className={clsx(
            styles.info__description,
            isMobile &&
              isInfoExpanded &&
              styles.info__description_mobileExpanded
          )}
        >
          <BodyNormal weight="bold">Описание: </BodyNormal>
          &nbsp;
          <BodyNormal>{description ?? ""}</BodyNormal>
        </div>
      </div>
      {!!files?.length && (
        <div
          className={clsx(
            styles.materials,
            isMobile && styles.materials_mobile
          )}
        >
          <BodyNormal weight="bold">Дополнительные материалы: </BodyNormal>
          <ul className={styles.materials__list}>
            {files.map((file) => (
              <li
                key={file?.originalName}
                className={styles.materials__file}
                onClick={() => {
                  downloadFile(urlLoadedFile + file?.filename, file?.filename);
                }}
              >
                <BodyNormal className={styles.materials__fileName}>
                  {(file.originalName && file.originalName.length > 20
                    ? file.originalName?.slice(0, 20) + "..."
                    : file.originalName) ?? ""}
                </BodyNormal>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
