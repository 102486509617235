import { FC } from "react";
import { useProjectNewsModal } from "@hooks/useProjectNewsModal";
import LoginNewsModal from "@modals/modal-items/login-news-modal";

export const ProjectNewsWrapper: FC = () => {
  const { newsItem, isModalOpen, closeModal } = useProjectNewsModal();

  if (!newsItem) {
    return null;
  }

  return (
    <LoginNewsModal isOpen={isModalOpen} onClose={closeModal} item={newsItem} />
  );
};
