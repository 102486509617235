import { companyService } from "@services/company-service";
import { useCompanyId } from "@store/company/company-store";
import { useQuery } from "react-query";
import { companyQueryKeys } from "./queryKeys";

export const useCompanyQuery = (id: number | null) => {
  const { data: company, ...stuff } = useQuery(
    companyQueryKeys.oneById(id),
    () => companyService.getById(id!),
    {
      enabled: id !== null,
    }
  );

  return {
    company,
    ...stuff,
  };
};

export const useSelectedCompanyQuery = () => {
  const companyId = useCompanyId();

  return useCompanyQuery(companyId);
};
