import Fetcher from "@http/fetcher";

export type EmployeesGetAllT = {
  id: number;
  companyId: number;
  name: string | null;
  departmentId: number | null;
};

export type GetAllEmployeesParams = {
  companies?: number[];
  departments?: string[];
  positions?: string[];
};

const EmployeesGetAllService = {
  async getAll(params?: GetAllEmployeesParams) {
    const resp = await Fetcher.get<EmployeesGetAllT[]>(
      `/companies/employees/`,
      { params }
    );

    return resp.data;
  },
};

export default EmployeesGetAllService;
