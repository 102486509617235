import { studyCenterQueryKeys } from "./queryKeys";
import { studyCenterService } from "@services/study-center";
import { useInfiniteQuery } from "react-query";

type AllEvents = {
  offset?: number;
  limit?: number;
  enabled?: boolean;
};

export const useStudyCenterEducationalEventsQuery = (props: AllEvents = {}) => {
  const { offset = 0, limit = 24, enabled = true } = props;
  return useInfiniteQuery(
    studyCenterQueryKeys.educationalEvents(),
    async ({ pageParam = offset }) => {
      return await studyCenterService.getEvents({ limit, offset: pageParam });
    },
    {
      enabled,
      getNextPageParam: () => offset + limit,
    }
  );
};
