import { workspaceService } from "@services/workspace-service";
import { useQuery } from "react-query";
import { workspaceQueryKeys } from "./queryKeys";

export const useWorkspaceGroupFilesQuery = (
  companyId: number | undefined,
  groups: number[]
) => {
  const { data: files, ...stuff } = useQuery(
    workspaceQueryKeys.groupFiles(companyId, groups),
    () => workspaceService.getGroupFiles(companyId!, groups),
    {
      enabled: typeof companyId !== "undefined",
    }
  );

  return {
    files,
    ...stuff,
  };
};
