import { ErrorIndicator, Loader } from "@ui/indicators";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { BackButton } from "@components/back-button";
import { BodyNormal } from "@ui/fonts";
import { HeadBar } from "./head-bar";
import { TableBodyRowDocuments } from "./table-body-row";
import style from "./single-documents.module.scss";
import { usePositionFilesQuery } from "@lib/positions";

type SingleDocumentsPropsT = {
  departmentId: number;
  positionId: number;
  companyId: number;
  type: string;
};

export const SingleDocuments: React.FC<SingleDocumentsPropsT> = ({
  departmentId,
  positionId,
  companyId,
  type,
}) => {
  const { isSuccess, files, isError, isLoading } = usePositionFilesQuery({
    companyId,
    departmentId,
    positionId,
  });

  return (
    <div className={style.wrapper}>
      <BackButton className={style.backButton} />
      {!isLoading && (
        <HeadBar
          type={type}
          departmentId={departmentId}
          positionId={positionId}
          department={files?.department?.title}
          position={files?.position?.title}
        />
      )}
      <div>
        {isLoading ? (
          <Loader />
        ) : isError ? (
          <ErrorIndicator />
        ) : (
          <Table className={style.tableWrapper} aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell className={style.table_head}>
                  <div className={style.table_head_wrapper}>
                    <div className={style.table_head_name}>Документы</div>
                  </div>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {isSuccess && !!files?.files?.length && (
              <TableBody>
                {files?.files?.map((file) => (
                  <TableBodyRowDocuments
                    file={file}
                    key={file.id}
                    departmentId={departmentId}
                  />
                ))}
              </TableBody>
            )}
          </Table>
        )}
        {isSuccess && !files?.files?.length && (
          <div className={style.list_empty}>
            <BodyNormal color={"gray-dark2"}>Список пуст</BodyNormal>
          </div>
        )}
      </div>
    </div>
  );
};

// EmployeesTable
