import {
  GetWorkplanTasksParams,
  WorkplanTasksResult,
} from "@interfaces/workplan";
import { WorkplanService } from "@services/workplan-service";
import { useInfiniteQuery } from "react-query";
import { workplanQueryKeys } from "./queryKeys";

export const useWorkplanTasksQuery = (
  params: GetWorkplanTasksParams,
  { enabled = true }
) => {
  return useInfiniteQuery(
    workplanQueryKeys.tasks(params),
    async ({ pageParam = 0 }) => {
      return new Promise<WorkplanTasksResult>(async (resolve, reject) => {
        try {
          const tasks = await WorkplanService.getTasks({
            offset: pageParam,
            ...params,
          });
          resolve({
            offset: pageParam,
            limit: params.limit,
            tasks,
          });
        } catch (err) {
          reject(err);
        }
      });
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage.tasks.length < lastPage.limit
          ? null
          : lastPage.offset + lastPage.limit,
      getPreviousPageParam: (lastPage) =>
        lastPage.offset - lastPage.limit > 0
          ? lastPage.offset - lastPage.limit
          : null,
      enabled,
    }
  );
};
