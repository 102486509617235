import {
  GetWorkplanGroupsParams,
  GetWorkplanTasksParams,
} from "@interfaces/workplan";

export const workplanQueryKeys = {
  all: ["workplan"],
  groups: (params: GetWorkplanGroupsParams) => [
    ...workplanQueryKeys.all,
    "groups",
    params,
  ],
  tasks: (params: GetWorkplanTasksParams) => [
    ...workplanQueryKeys.all,
    "tasks",
    params,
  ],
};
