import { useQuery } from "react-query";
import { workspaceService } from "@services/workspace-service";
import { workspaceQueryKeys } from "./queryKeys";

export const useWorkspaceGroupsQuery = (companyId: number | undefined) => {
  const { data: groups, ...stuff } = useQuery(
    workspaceQueryKeys.groups(companyId),
    () => workspaceService.getGroups(companyId!),
    {
      enabled: typeof companyId !== "undefined",
    }
  );

  return {
    groups,
    ...stuff,
  };
};
