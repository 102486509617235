import React, { useEffect } from "react";

import { BodyNormal } from "@ui/fonts";
import { Button } from "@ui/button";
import { Link } from "react-router-dom";
import moment from "moment";
import styles from "./material-card.module.scss";

export interface Material {
  id: number;
  title: string;
  date: string;
  shortDescription: string;
  imagePath: string | null;
}

export type MaterialCardProps = {
  hiddenDiv?: React.RefObject<HTMLDivElement> | null;
  material: Material;
  type: "educational-promotion" | "educational-event";
  onApply: () => void;
  handleIsBottom?: (e: boolean) => void;
};

export const MaterialCard: React.FC<MaterialCardProps> = ({
  material,
  type,
  onApply,
  hiddenDiv,
  handleIsBottom,
}) => {
  const formattedDate = moment(material.date).format("DD MMM YYYYг., HH:mm");

  const urlPart =
    type === "educational-promotion"
      ? "educational-promotions"
      : "educational-events";
  const articleUrl = `/study-center/materials/${urlPart}/${material.id}`;

  const handleApply = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    onApply();
  };

  useEffect(() => {
    if (!hiddenDiv) return;
    const observer = new IntersectionObserver(
      (entries) => {
        const div = entries[0];
        handleIsBottom && handleIsBottom(div.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
      }
    );
    if (hiddenDiv.current) observer.observe(hiddenDiv.current);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (hiddenDiv.current) observer.unobserve(hiddenDiv.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenDiv]);

  return (
    <Link to={articleUrl}>
      <div className={styles.Card}>
        <div
          className={
            type === "educational-promotion"
              ? styles.Card__educationalPromotionImageWrapper
              : styles.Card__eventImageWrapper
          }
        >
          {material.imagePath && (
            <img
              src={`${process.env.API_URL}/uploads/educational-center/offers/${
                type === "educational-promotion" ? "promotions" : "events"
              }/${material.imagePath}`}
              alt=""
              className={styles.Card__image}
            />
          )}
        </div>
        <div className={styles.Card__content}>
          <div className={styles.Card__text}>
            <div className={styles.Card__title}>
              <BodyNormal weight="bold">
                {material.title.length > 60
                  ? material.title.slice(0, 60) + "..."
                  : material.title}
              </BodyNormal>
            </div>
            <BodyNormal color="gray-darker2">{formattedDate}</BodyNormal>
            <BodyNormal color="gray-darker2">
              {material.shortDescription.length > 30
                ? material.shortDescription.slice(0, 30) + "..."
                : material.shortDescription}
            </BodyNormal>
          </div>
          <Button
            color="white"
            border
            borderColor="green"
            onClick={handleApply}
          >
            {type === "educational-promotion"
              ? "Получить скидку"
              : "Участвовать"}
          </Button>
        </div>
      </div>
    </Link>
  );
};
