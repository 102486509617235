import { CreateEventPropsT, GetEventsPropsT } from "@interfaces/requests/event";
import { EventT, EventsWithCountT } from "@interfaces/company/event";

import Fetcher from "@http/fetcher";
import { ResCallback } from "@interfaces/common";

class EventService {
  static async GetMonthEventsForAllCompanies(
    dateStart: number,
    dateFinish: number,
    cb: ResCallback<EventT[]>
  ) {
    try {
      const res = await Fetcher.get<EventT[]>("/companies/events-calendar", {
        params: {
          dateStart,
          dateFinish,
        },
      });

      return cb(null, res);
    } catch (err) {
      cb(err);
    }
  }

  static async GetMonthEvents(
    dateStart: number,
    dateFinish: number,
    companyId: number,
    cb: ResCallback<EventT[]>
  ) {
    try {
      const res = await Fetcher.get<EventT[]>(
        `/companies/${companyId}/events-calendar`,
        {
          params: {
            dateStart,
            dateFinish,
          },
        }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async getAllForAllCompanies(
    data: GetEventsPropsT,
    cb: ResCallback<EventT[] | EventsWithCountT>
  ) {
    try {
      const res = await Fetcher.get<EventT[] | EventsWithCountT>(
        "/companies/events-calendar",
        {
          params: {
            dateStart: data.dateStart,
            dateFinish: data.dateFinish,
            offset: data.skip,
            limit: data.limit,
            count: data.count || undefined,
            type: data.type || undefined,
          },
        }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async getAll(
    data: GetEventsPropsT & { companyId: number },
    cb: ResCallback<EventT[] | EventsWithCountT>
  ) {
    try {
      const res = await Fetcher.get<EventT[] | EventsWithCountT>(
        `/companies/${data.companyId}/events-calendar`,
        {
          params: {
            dateStart: data.dateStart,
            dateFinish: data.dateFinish,
            offset: data.skip,
            limit: data.limit,
            count: data.count || undefined,
            type: data.type || undefined,
          },
        }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async create(
    { companyId, ...data }: CreateEventPropsT,
    cb: ResCallback<EventT>
  ) {
    try {
      const res = await Fetcher.post<EventT>(
        `/companies/${companyId}/events-calendar`,
        data
      );

      if (res.status === 201) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async update(
    eventId: number,
    data: CreateEventPropsT,
    cb: ResCallback<EventT>
  ) {
    try {
      const res = await Fetcher.modified.patch<EventT>(
        `/events-calendar/${eventId}`,
        data
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async delete(eventId: number, cb: ResCallback<EventT>) {
    try {
      const res = await Fetcher.modified.delete<EventT>(
        `/events-calendar/${eventId}`
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async sendByEmail(
    companyId: number,
    eventId: number,
    email: string,
    eventType: "EVENT" | "SIGNATURE",
    cb: ResCallback<string>
  ) {
    try {
      const res = await Fetcher.post<string>(
        `/companies/${companyId}/events-calendar/${eventId}/email`,
        { email },
        {
          params: { type: eventType },
        }
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }
}

export default EventService;
