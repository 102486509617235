import { useQuery } from "react-query";
import { internalStudyCenterService } from "@services/internal-study-center-service";
import { internalStudyCenterQueryKeys } from "./queryKeys";

export const useInternalSectionsCategoriesQuery = () => {
  const { data: sections, ...stuff } = useQuery(
    internalStudyCenterQueryKeys.sectionsCategories(),
    () => internalStudyCenterService.getSectionsCategories()
  );

  return {
    sections,
    ...stuff,
  };
};
