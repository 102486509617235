import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { File, Image } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import { BreadCrumb } from "@components/common";
import { Button } from "@ui/button";
import Dropzone from "@ui/dropzone";
import { ArticleType } from "@interfaces/legislation-news";
import { RichEditor } from "@ui/rich-editor";
import { TextField } from "@material-ui/core";
import { Title } from "@components/common/common";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import clsx from "clsx";
import style from "@scss/pages/news-and-practice/news-and-practice.module.scss";
import useStyles from "@ui/material-ui-styles";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { useForm, Controller } from "react-hook-form";
import { useNewsItemQuery } from "@lib/news/useNewsItemQuery";
import { useCreateNewsMutation, useUpdateNewsMutation } from "@lib/news";
import { getNewsArticleBreadcrumbs } from "@utils/news";

type FormFields = {
  title: string;
  text: string;
  tags: string;
  createdAt: string;
};

type CreateNewsPropsT = {
  type: ArticleType;
  mode: "create" | "edit";
};

const CreateNews: React.FC<CreateNewsPropsT> = ({ type, mode }) => {
  const { pathname } = useLocation();
  const query = useRouteMatch<{ id: string }>();
  const history = useHistory();
  const classes = useStyles();

  const createNewsMutation = useCreateNewsMutation(type);
  const updateNewsMutation = useUpdateNewsMutation(type);

  const { data: currentItem, isLoading } = useNewsItemQuery(
    query.params.id,
    type
  );

  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [uploadError, setUploadError] = useState<string>();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormFields>({
    values: {
      title: currentItem?.title ?? "",
      text: currentItem?.text ?? "",
      tags: currentItem?.tags?.join(",") ?? "",
      createdAt: currentItem?.createdAt ?? "",
    },
    defaultValues: {
      createdAt: currentItem?.createdAt ?? moment().toISOString(),
    },
  });

  const breadcrumbs = getNewsArticleBreadcrumbs(type);

  useEffect(() => {
    if (isLoading) return;

    if (mode === "edit" && !currentItem) {
      let redirectUrl: string;

      switch (type) {
        case "LEGISLATION-NEWS":
          redirectUrl = "/legislation-news/";
          break;
        case "PRACTICE":
          redirectUrl = "/practice/";
          break;
        case "HELP":
          redirectUrl = "/help/";
          break;
        case "DEVELOPMENT-NEWS":
          redirectUrl = "/development-news/";
          break;
        default:
          redirectUrl = "/legislation-news/";
      }

      return history.push(redirectUrl + query.params.id);
    }
  }, [currentItem, history, isLoading, type, mode, query.params.id]);

  const onSubmit = handleSubmit((data) => {
    const formData = new FormData();
    formData.append("title", data.title);
    if (mode === "create" || data.text.length !== 0) {
      formData.append("text", data.text);
    }
    if (data.tags) {
      data.tags.split(",").forEach((tag) => {
        formData.append("tags", tag.trim());
      });
    }

    if (data.createdAt) {
      formData.append("createdAt", data.createdAt);
    }

    if (mode === "create") {
      if (uploadedImages.length === 0) {
        return setUploadError("Обязательное поле");
      }

      uploadedImages.forEach((file) => formData.append("images", file));
      uploadedFiles.forEach((file) => formData.append("files", file));
    }

    if (mode === "edit") {
      if (!currentItem) return;

      updateNewsMutation.mutate(
        {
          id: currentItem._id,
          data: formData,
        },
        {
          onSuccess: () => {
            history.goBack();
          },
        }
      );
    } else {
      createNewsMutation.mutate(formData, {
        onSuccess: () => {
          history.goBack();
        },
      });
    }
  });

  const canRenderEditor = () => {
    if (mode === "edit") {
      return !!currentItem;
    }

    return mode === "create";
  };

  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={breadcrumbs} />
          <div className="content-title-section-with-btn">
            <Title
              text={
                pathname.includes("create")
                  ? "Создание новости"
                  : "Изменение новости"
              }
              withHistory
            />
          </div>
        </div>
        <div className={clsx(style.create_news_container)}>
          <form className={classes.root} onSubmit={onSubmit}>
            <Controller
              control={control}
              name="title"
              rules={{
                required: "Обязательное поле",
              }}
              render={({ field }) => (
                <TextField
                  label="Название новости"
                  variant="filled"
                  placeholder="Название новости"
                  error={!!errors.title}
                  helperText={errors.title?.message}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="tags"
              render={({ field }) => (
                <TextField
                  label="Теги (через запятую)"
                  variant="filled"
                  placeholder="Через запятую добавьте теги"
                  error={!!errors.tags}
                  helperText={errors.tags?.message}
                  {...field}
                />
              )}
            />
            <Controller
              control={control}
              name="createdAt"
              render={({ field }) => (
                <MuiPickersUtilsProvider
                  libInstance={moment}
                  utils={MomentUtils}
                  locale="ru"
                >
                  <KeyboardDateTimePicker
                    cancelLabel={"Отменить"}
                    okLabel={"ОК"}
                    ampm={false}
                    fullWidth
                    variant="dialog"
                    format="DD.MM.yyyy HH:mm"
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    value={moment(field.value)}
                    onChange={(date) => field.onChange(date.toISOString())}
                    error={!!errors.createdAt}
                    helperText={errors.createdAt?.message}
                  />
                </MuiPickersUtilsProvider>
              )}
            />
            <>
              {mode === "create" && (
                <>
                  <p className={clsx(style.create_news_field_title)}>
                    <WrapperSVG color="green" cursor={false}>
                      <Image size={24} />
                    </WrapperSVG>
                    Загрузить фото *
                  </p>

                  <p className={clsx(style.create_news_field_desc)}>
                    Допускается только формат изображения!
                  </p>
                  <Dropzone
                    widthFull={true}
                    accept="image/*"
                    maxFiles={64}
                    typeFile={"image"}
                    onUpload={(files) => {
                      setUploadError(undefined);
                      setUploadedImages(files);
                    }}
                  />
                  {uploadError && <p className="error-text">{uploadError}</p>}
                </>
              )}
              {canRenderEditor() && (
                <Controller
                  control={control}
                  name="text"
                  rules={{
                    required: "Обязательное поле",
                  }}
                  render={({ field }) => (
                    <RichEditor
                      initialContent={currentItem?.text ?? ""}
                      handleContent={field.onChange}
                      label="Текст новости *"
                      error={errors.text?.message}
                    />
                  )}
                />
              )}
              {mode === "create" && (
                <>
                  <p className={clsx(style.create_news_field_title)}>
                    <WrapperSVG color="green" cursor={false}>
                      <File size={24} />
                    </WrapperSVG>
                    Загрузить файлы для скачивания
                  </p>
                  <Dropzone
                    widthFull={true}
                    maxFiles={64}
                    onUpload={(files) => setUploadedFiles(files)}
                  />
                </>
              )}
            </>
            {/* TODO: отключилючено до востребования */}
            {/* <p className="error-text">{validation.textError}</p> */}
            {/* <p className="error-text">{validation.processError}</p> */}
            <Button type="submit" className={clsx(style.create_news_btn)}>
              {mode === "edit" ? "Сохранить" : "Создать"}
            </Button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default CreateNews;
