import "slick-carousel/slick/slick.css";

import { $HomeData, fetchHomeData } from "@store/home-store";
import { ErrorIndicator, Loader } from "@ui/indicators";

import EventCalendar from "./company/calendar";
import EventItems from "@components/events";
import { Link } from "react-router-dom";
import NewsItems from "@components/news-components";
import { Title } from "@components/common/common";
import clsx from "clsx";
import style from "@scss/pages/home.module.scss";
import { useEffect } from "react";
import { useStore } from "effector-react";
import { useSelectedCompanyQuery } from "@lib/company";
import { useNewsQuery } from "@lib/news";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";

const Home = () => {
  const { company } = useSelectedCompanyQuery();

  const permissions = useStore($UserAddPermissions);
  const { notifications, isFetched } = useStore($HomeData);

  const canSeeNews = permissions.hasPermission(
    ACTIONS.legislationNews_allowedToShow
  );

  const {
    data: news,
    isLoading: newsPending,
    error: newsError,
  } = useNewsQuery(
    {
      limit: 4,
      type: "LEGISLATION-NEWS",
    },
    {
      enabled: canSeeNews,
    }
  );

  useEffect(() => {
    if (!isFetched) {
      fetchHomeData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <Title text="Главная страница" />
        </div>
        <div className={clsx(style.home_content)}>
          <div className={clsx(style.top_blocks)}>
            <div className={clsx(style.calendar_block, "bg-white")}>
              <EventCalendar isCompanyDefined={false} />
            </div>
          </div>
          {/* {notifications === null ? (
            <Loader />
          ) : notifications === false ? (
            <ErrorIndicator />
          ) : notifications.length < 3 ? null : (
            <div className={clsx(style.notifications)}>
              <p className={clsx(style.block_title)}>
                Оповещения <Link to={"/notifications"}>Все оповещения</Link>
              </p>
              <EventItems events={notifications} limit={3} />
            </div>
          )} */}
          {canSeeNews && (
            <div className={clsx(style.news)}>
              <p className={clsx(style.block_title)}>
                Новости законодательства
                <Link to={"/legislation-news"}>Все новости</Link>
              </p>
              {newsPending ? (
                <Loader />
              ) : newsError ? (
                <ErrorIndicator />
              ) : (
                <NewsItems
                  items={news?.pages[0].rows ?? []}
                  path={"legislation-news"}
                  limit={4}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </main>
  );
};

export default Home;
