import {
  FetchPrescriptionsPropsT,
  PrescriptionExecutorT,
  PrescriptionExecutorsStateT,
  PrescriptionStoreT,
  PrescriptionT,
} from "@interfaces/prescriptions";

import { createEffect, createEvent, createStore } from "effector";

import { EventStateStoreT } from "@interfaces/common";

import PrescriptionService from "@services/prescription-service";

// PRESCRIPTIONS
export const setPrescriptions = createEvent<PrescriptionStoreT>();
export const pushToPrescriptions = createEvent<PrescriptionT[]>();
export const updatePrescription = createEvent<PrescriptionT>();
export const removePrescription = createEvent<string>();

export const $Prescriptions = createStore<PrescriptionStoreT>({
  count: 0,
  rows: [],
})
  .on(setPrescriptions, (_, newState) => newState)
  .on(pushToPrescriptions, (oldState, newState) => ({
    count: ++oldState.count,
    rows: [...newState, ...oldState.rows],
  }))
  .on(updatePrescription, (oldState, updatedPrescription) => ({
    count: oldState.count,
    rows: oldState.rows.map((prescription) => {
      if (prescription._id === updatedPrescription._id) {
        prescription = updatedPrescription;
      }
      return prescription;
    }),
  }))
  .on(removePrescription, (oldState, prescriptionId) => ({
    count: --oldState.count,
    rows: oldState.rows.filter(
      (prescription) => prescription._id !== prescriptionId
    ),
  }));

// PRESCRIPTIONS STATES
export const setPrescriptionsStates = createEvent<EventStateStoreT>();

export const $PrescriptionsStates = createStore<EventStateStoreT>({
  isFetched: false,
  isLoading: true,
  error: false,
}).on(setPrescriptionsStates, (_, newState) => newState);

// SELECTED PRESCRIPTION
export const setSelectedPrescription = createEvent<PrescriptionT>();
export const resetSelectedPrescription = createEvent();

export const $SelectedPrescription = createStore<PrescriptionT | null>(null)
  .on(setSelectedPrescription, (_, newState) => newState)
  .reset(resetSelectedPrescription);

// PRESCRIPTION EXECUTORS
export const setPrescriptionExecutorsState =
  createEvent<PrescriptionExecutorsStateT>();
export const pushToPrescriptionExecutors =
  createEvent<PrescriptionExecutorT[]>();

export const $PrescriptionExecutors = createStore<PrescriptionExecutorsStateT>({
  count: 0,
  rows: [],
})
  .on(setPrescriptionExecutorsState, (_, newState) => newState)
  .on(pushToPrescriptionExecutors, (oldState, newState) => ({
    count: oldState.count,
    rows: [...oldState.rows, ...newState],
  }));

// PRESCRIPTION EXECUTORS STATES
export const setExecutorsStates = createEvent<EventStateStoreT>();

export const $ExecutorsStates = createStore<EventStateStoreT>({
  isFetched: false,
  isLoading: true,
  error: false,
}).on(setExecutorsStates, (_, newState) => newState);

// REQUESTS
export const fetchPrescriptions = createEffect<FetchPrescriptionsPropsT>(
  ({ companyId, offset, limit, count, cb }) => {
    PrescriptionService.getAll(companyId, offset, limit, count, (err, res) => {
      if (err || !res) {
        setPrescriptionsStates({
          isFetched: false,
          error: true,
          isLoading: false,
        });
        if (cb) {
          cb(err);
        }
        return console.error("При получении предписаний произошла ошибка");
      }
      if (count) {
        setPrescriptions(res.data);
      } else {
        pushToPrescriptions(res.data.rows);
      }
      setPrescriptionsStates({
        isFetched: true,
        error: false,
        isLoading: false,
      });
      if (cb) {
        cb(null, res);
      }
    });
  }
);
