import {
  DeleteCategoriesProgrammT,
  EditRowTrainingTableT,
  ProgramRowTrainingTableStateT,
  ProgramTableT,
  СategoriesT,
} from "@interfaces/directory/version";
import { createEffect, createEvent, createStore } from "effector";
import produce, { Draft } from "immer";

import { EventStateStoreT } from "@interfaces/common";
import { FetchGetDirectoryInstructionsPropsT } from "@interfaces/company/procedure";
import ProcedureService from "@services/programs-service";
import { WritableDraft } from "immer/dist/internal";
import { resetAllStates } from "@store/user-store";

export const setDirectoryTraining = createEvent<{
  training?: ProgramTableT[];
}>();
export const pushDirectoryProgramRowTrainingTable = createEvent<{
  programs?: ProgramRowTrainingTableStateT;
}>();
export const editDirectoryProgramRowTrainingTable = createEvent<{
  programs?: EditRowTrainingTableT;
}>();
export const removeDirectoryProgramRowTrainingTable = createEvent<{
  programs?: EditRowTrainingTableT;
}>();
export const pushDirectoryTrainingCategories = createEvent<{
  training?: СategoriesT;
}>();
export const editDirectoryTrainingCategories = createEvent<СategoriesT>();
export const removeDirectoryTrainingCategories =
  createEvent<DeleteCategoriesProgrammT>();
export const $DirectoryTraining = createStore<{
  training?: ProgramTableT[];
}>({ training: [] })
  .on(setDirectoryTraining, (oldState, newState) => ({
    ...oldState,
    ...newState,
  }))
  .on(pushDirectoryTrainingCategories, (oldState, newState) => {
    if (newState.training && oldState.training) {
      return {
        ...oldState,
        training: [...oldState.training, newState?.training],
      };
    }
    if (newState.training && !oldState.training) {
      return { ...oldState, categories: [newState?.training] };
    }
  })
  .on(editDirectoryProgramRowTrainingTable, (oldState, editRowTable) => {
    const updatedState = produce(oldState, (draftState) => {
      if (draftState.training) {
        const editRowTableProgramId = editRowTable?.programs?.id;
        draftState.training = draftState.training.map((program) => {
          if (
            editRowTableProgramId &&
            editRowTableProgramId < 0 &&
            !!program.programs?.length
          ) {
            program.programs = program.programs.map((objectProgram) => {
              if (
                objectProgram.change?.id === editRowTableProgramId &&
                editRowTable?.programs
              ) {
                const updatedProgram = {
                  ...objectProgram,
                  change: editRowTable.programs,
                };
                return updatedProgram as typeof objectProgram;
              }
              if (
                objectProgram.id === editRowTableProgramId &&
                editRowTable?.programs
              ) {
                const updatedProgram = {
                  ...objectProgram,
                  change: editRowTable.programs,
                };
                return updatedProgram as typeof objectProgram;
              }
              return objectProgram;
            });
          }
          if (editRowTableProgramId && !!program.programs?.length) {
            program.programs = program.programs.map((objectProgram) => {
              if (
                objectProgram.id === editRowTableProgramId &&
                editRowTable?.programs
              ) {
                const updatedProgram = {
                  ...objectProgram,
                  change: editRowTable.programs,
                };
                return updatedProgram as typeof objectProgram;
              }
              return objectProgram;
            });
          }
          return program;
        });
      }
    });
    return updatedState;
  })
  .on(removeDirectoryProgramRowTrainingTable, (oldState, removeRowTable) => {
    const updatedState = produce(oldState, (draftState) => {
      if (draftState.training) {
        const removeRowTableProgramId = removeRowTable?.programs?.id;
        draftState.training = draftState.training.map((program) => {
          if (removeRowTableProgramId && removeRowTableProgramId < 0) {
            program.programs = program.programs?.filter(
              (objectProgram) =>
                objectProgram?.id !== removeRowTableProgramId &&
                objectProgram?.change?.id !== removeRowTableProgramId
            );
          }
          if (removeRowTableProgramId && !!program.programs?.length) {
            program.programs = program.programs.map((objectProgram) => {
              if (
                objectProgram.id === removeRowTableProgramId &&
                removeRowTable?.programs
              ) {
                const updatedProgram = {
                  ...objectProgram,
                  change: removeRowTable.programs,
                };
                return updatedProgram as typeof objectProgram;
              }
              return objectProgram;
            });
          }
          return program;
        });
      }
    });

    return updatedState;
  })
  .on(pushDirectoryProgramRowTrainingTable, (oldState, newRowTable) => {
    if (newRowTable?.programs) {
      return produce(oldState, (draftState) => {
        if (draftState?.training) {
          const programIndex = draftState.training.findIndex(
            (program) =>
              program?.id === newRowTable?.programs?.categoryId ||
              program?.change?.id === newRowTable?.programs?.categoryId
          );
          if (programIndex !== undefined && programIndex !== -1) {
            const program = draftState.training[programIndex];

            const updatedProgram = Object.assign({}, program, {
              programs: program.programs
                ? [...program.programs, { change: newRowTable.programs }]
                : [{ change: newRowTable.programs }],
            });

            draftState.training[programIndex] = updatedProgram;
          }
        }
      });
    }
    return oldState;
  })
  .on(editDirectoryTrainingCategories, (oldState, editCategory) => {
    const newState = produce(
      oldState,
      (draftState: Draft<{ training: ProgramTableT[] }>) => {
        if (editCategory.id) {
          if (editCategory.id > 0) {
            const editTitle = draftState?.training?.map((category) => {
              if (category.id === editCategory.id) {
                return {
                  ...editCategory,
                  programs: category.programs,
                };
              }
              if (category?.change?.id === editCategory.id) {
                const newData = {
                  ...category,
                  change: editCategory as СategoriesT,
                };
                return newData;
              }
              return category;
            });
            draftState.training = editTitle as WritableDraft<ProgramTableT>[];
          }
          if (editCategory.id < 0) {
            const editTitle = draftState?.training?.map((category) => {
              if (category?.change?.id === editCategory.id) {
                Object.assign(category.change, editCategory);
                return category;
              }
              if (category?.id === editCategory.id) {
                Object.assign(category, editCategory);
                return category;
              }
              return category;
            });
            draftState.training = editTitle as WritableDraft<ProgramTableT>[];
          }
        }
      }
    );
    return newState;
  })
  .on(removeDirectoryTrainingCategories, (oldState, removeCategory) => {
    if (removeCategory.id < 0) {
      const removeProgramm = oldState?.training?.filter(
        (programm) =>
          programm.id !== removeCategory.id &&
          programm?.change?.id !== removeCategory.id
      );
      return { training: removeProgramm };
    }
    if (removeCategory.id > 0) {
      const removeProgramm = oldState?.training?.map((programm) => {
        if (programm.id === removeCategory.id) {
          return {
            ...programm,
            title: removeCategory.title,
            action: removeCategory.action,
          };
        }
        return programm;
      });

      return { training: removeProgramm };
    }
  });

export const setDirectoryTrainingStates = createEvent<EventStateStoreT>();
export const setDirectoryTrainingLoading = createEvent<boolean>();
export const setDirectoryTrainingError = createEvent<boolean>();

export const $DirectoryControlStates = createStore<EventStateStoreT>({
  isLoading: true,
  error: false,
  isFetched: false,
})
  .on(setDirectoryTrainingLoading, (oldState, newState) => ({
    ...oldState,
    isLoading: newState,
  }))
  .on(setDirectoryTrainingError, (oldState, newState) => ({
    ...oldState,
    isLoading: false,
    error: newState,
  }))
  .on(setDirectoryTrainingStates, (_, newStateTraining) => newStateTraining)
  .reset(resetAllStates);

export const FetchGetDirectoryTrainingTable =
  createEffect<FetchGetDirectoryInstructionsPropsT>(
    ({
      isIncludeChanges = true,
      categories = undefined,
      titleOrder,
      termPrimaryConductingOrder,
      periodOrder,
    }) => {
      setDirectoryTrainingLoading(true);
      ProcedureService.ProcedureTable(
        "training",
        isIncludeChanges,
        categories,
        titleOrder,
        termPrimaryConductingOrder,
        periodOrder,
        (err, res) => {
          if (err || !res) {
            setDirectoryTrainingStates({
              error: true,
              isFetched: false,
              isLoading: false,
            });
            return console.error(
              "При получении данных таблиц произошла ошибка"
            );
          }
          if (res.data) {
            if (!!res.data.length) {
              setDirectoryTraining({ training: res.data });
            }
            if (!res.data.length) {
              setDirectoryTraining({ training: [] });
            }
          }
          setDirectoryTrainingStates({
            error: false,
            isFetched: true,
            isLoading: false,
          });
        }
      );
    }
  );
