import React, { FormEventHandler, HTMLAttributes, memo, useState } from "react";

import { SearchSVG } from "@components/svgs";
import clsx from "clsx";
import style from "./common-search.module.scss";

type Props = HTMLAttributes<HTMLInputElement> & {
  color?: "gray" | "white";
  setSearch?: (value: string) => void;
  onChange: FormEventHandler<HTMLInputElement> & ((value: string) => void);
};

export const CommonSearch: React.FC<Props> = memo(
  ({ color = "gray", className, onChange, setSearch, ...props }) => {
    const [inputValue, setInputValue] = useState("");

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
      setSearch && setSearch(event.target.value);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        onChange(inputValue);
        event.currentTarget.blur();
      }
    };

    const handleSearchClick = () => {
      onChange(inputValue);
    };

    const handleBlur = () => {
      onChange(inputValue);
    };

    return (
      <div className={clsx(style.container, className)}>
        <input
          type="text"
          placeholder="Поиск"
          value={inputValue}
          className={clsx(style[color])}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          {...props}
        />
        <div className={style.search_icon} onClick={handleSearchClick}>
          <SearchSVG />
        </div>
      </div>
    );
  }
);
