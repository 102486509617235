import clsx from "clsx";
import style from "./head-bar.module.scss";
import { useHistory } from "react-router-dom";

type HeadBarPropsT = {
  department?: string;
  position?: string;
  departmentId?: number;
  positionId?: number;
  type?: string;
};

export const HeadBar: React.FC<HeadBarPropsT> = ({
  department = "",
  position = "",
  departmentId,
  positionId,
  type,
}) => {
  const history = useHistory();
  return (
    <div className={clsx(style.department_top_panel)}>
      <p className={clsx(style.department_title)}>
        {department} / {position}
      </p>
      <button
        className={clsx(style.department_type_btn, {
          [style.active]: type === "departments",
        })}
        onClick={() =>
          history.replace(`/company/departments/${departmentId}/${positionId}`)
        }
      >
        Сотрудники
      </button>
      <button
        className={clsx(style.department_type_btn, {
          [style.active]: type === "documents",
        })}
        onClick={() =>
          history.replace(
            `/company/departments-documents/${departmentId}/${positionId}`
          )
        }
      >
        Документы
      </button>
    </div>
  );
};
