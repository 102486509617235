import { useMutation, useQueryClient } from "react-query";
import { MedicalExaminationService } from "@services/medical-examination.service";
import { medicalExaminationQueryKeys } from "./queryKeys";

export const useCreateMedicalExaminationFactorMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(MedicalExaminationService.createFactor, {
    onSuccess: () => {
      queryClient.invalidateQueries(medicalExaminationQueryKeys.all);
    },
  });
};
