import Fetcher from "@http/fetcher";

export type PositionsGetAllT = {
  id: number;
  parentId: number | null;
  title: string | null;
  departmentId: number | null;
};

export type GetAllPositionsParams = {
  companies?: number[];
  departments?: string[];
};

const PositionsGetAllService = {
  async getAll(params?: GetAllPositionsParams) {
    const resp = await Fetcher.get<PositionsGetAllT[]>(
      `/companies/positions/`,
      { params }
    );

    return resp.data;
  },
};

export default PositionsGetAllService;
