import clsx from "clsx";

import useModal from "@modals/modal-hook";

import style from "@scss/modals/common/confirm-action.module.scss";
import Select from "react-select";
import { selectColourStyles, selectTheme } from "@components/common/common";
import { useState } from "react";
import { SimpleOptionT } from "@interfaces/company/employees";

const GB = 1024 * 1024 * 1024;

const AddAdditionalSpace = () => {
  const { close, modalComponent, modalData } = useModal();

  const [selected, setSelected] = useState<SimpleOptionT | null>(null);

  return (
    <div key={modalComponent.key} className={clsx(style.confirm_modal)}>
      <p className="modal_title">Выберите объем</p>
      <div className="underline" />
      <div className="modal_content">
        <p className={clsx(style.confirm_modal_text)}>
          <Select
            closeMenuOnSelect={true}
            // components={ animatedComponents }
            // value={ selectedDocOptions }
            options={[
              { value: 1 * GB, label: "1 Гб" },
              { value: 10 * GB, label: "10 Гб" },
              { value: 20 * GB, label: "20 Гб" },
            ]}
            menuPortalTarget={document.body}
            isSearchable
            placeholder={"Объем"}
            onChange={setSelected}
            styles={selectColourStyles()}
            theme={selectTheme}
            menuPlacement="auto"
          />
        </p>
        <div className="modal_buttons_group">
          <button
            type="submit"
            className="modal_btn"
            onClick={(e) => {
              (e.target as HTMLButtonElement).disabled = true;
              close();
              return modalComponent.onConfirm
                ? modalComponent.onConfirm(
                    selected ? selected.value : 0,
                    modalData.companyId
                  )
                : null;
            }}
          >
            {modalComponent.btnText}
          </button>
          <button
            type="submit"
            className="modal_btn colorful-btn"
            onClick={close}
          >
            Отменить
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAdditionalSpace;
