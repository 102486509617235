import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { ArticleType, NewsFileT } from "@interfaces/legislation-news";
import { ColorfulButton, Title } from "@components/common/common";
import { ErrorIndicator, Loader } from "@ui/indicators";
import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { BreadCrumb } from "@components/common";
import { Modal } from "@ui/modal";
import NewsItems from "@components/news-components";
import { TextPageComponent } from "feature/text-page-component/text-page-component";
import clsx from "clsx";
import style from "@scss/pages/news-and-practice/news-and-practice.module.scss";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import {
  useNewsItemQuery,
  useDeleteNewsMutation,
  useOtherNewsQuery,
  useDownloadNewsMutation,
} from "@lib/news";
import { getNewsArticleBreadcrumbs } from "@utils/news";
import { downloadBlob } from "@utils/downloadBlob";

function getPathToFile(type: ArticleType): string {
  switch (type) {
    case "LEGISLATION-NEWS":
      return "uploads/legislation-news/";
    case "HELP":
      return "uploads/useful-materials/";
    case "PRACTICE":
      return "uploads/judicial-practices/";
    case "DEVELOPMENT-NEWS":
      return "uploads/development-news/";
    case "PROJECT-NEWS":
      return "uploads/project-news/";
    default:
      return "uploads/";
  }
}

function convertPath(type: ArticleType, files: NewsFileT[]): string[] {
  const basePath = getPathToFile(type);
  return files.map((file) => basePath + file.filename);
}

const ViewNews: React.FC<{ path: ArticleType }> = ({ path }) => {
  const permissions = useStore($UserAddPermissions);
  const [openModalDownload, setOpenModalDownload] = useState(false);
  const modal = useModal();
  const query = useRouteMatch<{ id: string }>();
  const history = useHistory();

  const {
    data: currentItem,
    isLoading,
    isError,
  } = useNewsItemQuery(query.params.id, path);

  const { data: otherNews } = useOtherNewsQuery(query.params.id, path);

  const deleteNewsMutation = useDeleteNewsMutation(path);
  const downloadFileMutation = useDownloadNewsMutation(path);

  const breadcrumbs = getNewsArticleBreadcrumbs(path);

  const handlerOpenDownFile = () => {
    setOpenModalDownload(true);
  };

  const handleDeleteNews = () => {
    if (!currentItem) return;

    modal.open("ConfirmActionModal", {
      modalData: {
        text: `Вы уверены, что хотите удалить запись "${currentItem.title}"?`,
      },
      btnText: "Удалить",
      onConfirm: () => {
        deleteNewsMutation.mutate(currentItem._id, {
          onSuccess: () => {
            history.goBack();
          },
        });
      },
    });
  };

  const handleDownloadFile = (file: NewsFileT) => {
    downloadFileMutation.mutate(file.filename, {
      onSuccess: (blob) => {
        downloadBlob(blob, file.originalname);
      },
    });
  };

  const canEditNews = () => {
    if (path === "LEGISLATION-NEWS") {
      return permissions.hasPermission(ACTIONS.legislationNews_allowedToEdit);
    }
    if (path === "HELP") {
      return permissions.hasPermission(ACTIONS.usefulMaterials_allowedToEdit);
    }
    if (path === "PRACTICE") {
      return permissions.hasPermission(ACTIONS.judicialPractice_allowedToEdit);
    }
    if (path === "DEVELOPMENT-NEWS") {
      return permissions.hasPermission(ACTIONS.developmentNews_allowedToEdit);
    }
    if (path === "PROJECT-NEWS") {
      return permissions.hasPermission(ACTIONS.projectNews_allowedToEdit);
    }
    return false;
  };

  const canDeleteNews = () => {
    if (path === "LEGISLATION-NEWS") {
      return permissions.hasPermission(ACTIONS.legislationNews_allowedToDelete);
    }
    if (path === "HELP") {
      return permissions.hasPermission(ACTIONS.usefulMaterials_allowedToDelete);
    }
    if (path === "PRACTICE") {
      return permissions.hasPermission(
        ACTIONS.judicialPractice_allowedToDelete
      );
    }
    if (path === "DEVELOPMENT-NEWS") {
      return permissions.hasPermission(ACTIONS.developmentNews_allowedToDelete);
    }
    if (path === "PROJECT-NEWS") {
      return permissions.hasPermission(ACTIONS.projectNews_allowedToDelete);
    }
    return false;
  };

  return (
    <main
      key={currentItem?._id} // this way page will re-render when currentItem changes and scroll to the top
      className={clsx("content-container", style.wrapperInfo)}
    >
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={breadcrumbs} />
          <div className="content-title-section-with-btn">
            <Title text={breadcrumbs[1]} withHistory />
            {isError ? (
              <ErrorIndicator />
            ) : isLoading ? (
              <Loader />
            ) : !currentItem ? null : (
              <div className={style.actions}>
                {canEditNews() && (
                  <ColorfulButton
                    link={`/${path.toLowerCase()}/${currentItem._id}/edit`}
                    text={"Изменить новость"}
                    plusIcon={false}
                  />
                )}
                {canDeleteNews() && (
                  <ColorfulButton
                    text={"Удалить"}
                    plusIcon={false}
                    onClick={handleDeleteNews}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        {isError ? (
          <ErrorIndicator />
        ) : isLoading ? (
          <Loader />
        ) : !currentItem ? null : (
          <TextPageComponent
            id={currentItem._id}
            files={currentItem.files}
            title={currentItem.title}
            images={convertPath(path, currentItem.images)}
            tags={currentItem.tags}
            createdAt={currentItem.createdAt}
            text={currentItem.text}
            handlerOpenDownFile={handlerOpenDownFile}
          />
        )}

        {otherNews && otherNews.length > 0 && (
          <div className={clsx(style.additional_news)}>
            <p className={clsx(style.add_section_title)}>Читайте еще</p>
            <NewsItems items={otherNews} path={path.toLowerCase()} />
          </div>
        )}
      </div>
      <Modal
        open={openModalDownload}
        onClose={() => setOpenModalDownload(false)}
      >
        <>
          {currentItem?.files &&
            currentItem.files.map((file, index) => {
              return (
                <ul key={index}>
                  <li className={style.onLinkDownload}>
                    <button onClick={() => handleDownloadFile(file)}>
                      {file.originalname}
                    </button>
                  </li>
                </ul>
              );
            })}
        </>
      </Modal>
    </main>
  );
};

export default ViewNews;
