import "@scss/pages/question-answer.scss";
import clsx from "clsx";

import { BodyBold, BodyNormal } from "@ui/fonts";
import { Chats } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import { useCreateFeedbackMessageQuery } from "@lib/feedback";

import styles from "./FeedbackChat.module.scss";
import {
  ChatActions,
  ChatHeader,
  ChatMessages,
  FeedbackInfo,
  InputFiles,
  SendMessageDataT,
} from "./components";
import { GetIdFeedbackT } from "@interfaces/technical-support";
import toast from "react-hot-toast";
import { useStore } from "effector-react";
import { $DeviceType } from "@store/device-type-store";
import { BreadCrumb } from "@components/common";
import { Modal } from "@ui/modal";

type FeedbackChatT = {
  feedbackItem?: GetIdFeedbackT;
};

export const FeedbackChat: React.FC<FeedbackChatT> = ({ feedbackItem }) => {
  const { isPhone } = useStore($DeviceType);
  const [isClosed, setIsClosed] = useState<boolean>(!!feedbackItem?.closedAt);
  const [isInfoOpen, setIsInfoOpen] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const messagesRootRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const { mutate: createMessage, isLoading: isCreateLoading } =
    useCreateFeedbackMessageQuery();

  useEffect(() => {
    setIsClosed(!!feedbackItem?.closedAt);
  }, [feedbackItem?.closedAt]);

  const handleSubmit = (data: SendMessageDataT) => {
    if ((!selectedFiles && !data.text) || !feedbackItem?._id) return;
    const formData = new FormData();

    if (data.text) formData.set("text", data.text);
    if (selectedFiles) {
      for (const file of selectedFiles) {
        if (file instanceof File) {
          formData.append("files", file);
        }
      }
    }

    createMessage(
      { id: feedbackItem._id, formData },
      {
        onSuccess: () => {
          setSelectedFiles([]);
          data.clear();
        },
        onError: () => {
          toast.error("Что-то пошло не так");
        },
      }
    );
  };

  useEffect(() => {
    if (feedbackItem) setIsLoaded(true);
  }, [feedbackItem]);

  useEffect(() => {
    const messagesRoot = messagesRootRef.current;
    if (messagesRoot && isLoaded) {
      messagesRoot.style.scrollBehavior = "smooth";
      messagesRoot.scrollTop = messagesRoot.scrollHeight;

      setTimeout(() => {
        messagesRoot.style.scrollBehavior = "auto";
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackItem?.messages]);

  useEffect(() => {
    const messagesRoot = messagesRootRef.current;
    if (messagesRoot) {
      messagesRoot.scrollTop = messagesRoot.scrollHeight;
    }
  }, [feedbackItem?.messages]);

  const handleToggleInfo = () => setIsInfoOpen((prev) => !prev);

  return (
    <div className={styles.feedbackContainer}>
      {isPhone && (
        <div className={styles.feedbackContainer__breadcumps}>
          <BreadCrumb
            items={[
              "Главная",
              "Техподдержка",
              feedbackItem?.title
                ? feedbackItem?.title?.length > 25
                  ? feedbackItem?.title?.slice(0, 25) + "..."
                  : feedbackItem?.title
                : "",
            ]}
          />
        </div>
      )}
      <ChatHeader
        feedBackId={feedbackItem?._id}
        createdAt={feedbackItem?.createdAt}
        closedAt={feedbackItem?.closedAt}
        isClosed={isClosed}
        setIsClosed={setIsClosed}
      />
      {isPhone ? (
        <Modal
          open={isInfoOpen}
          onClose={() => setIsInfoOpen(false)}
          className={clsx(
            styles.modal,
            (feedbackItem?.files?.length ||
              (feedbackItem?.description?.length || 0) > 80) &&
              styles.modal_large
          )}
        >
          <BodyBold className={styles.modal__title}>
            {feedbackItem?.title}
          </BodyBold>
          <FeedbackInfo
            title={feedbackItem?.title}
            description={feedbackItem?.description}
            files={feedbackItem?.files}
            isClosed={isClosed}
            setIsClosed={setIsClosed}
            feedBackId={feedbackItem?._id}
            isMobile
          />
        </Modal>
      ) : (
        <FeedbackInfo
          title={feedbackItem?.title}
          description={feedbackItem?.description}
          files={feedbackItem?.files}
        />
      )}
      <div className={styles.chat}>
        <div className={styles.chat__head}>
          <BodyNormal weight="bold">Обсуждение: </BodyNormal>
          {isPhone && (
            <BodyNormal
              onClick={handleToggleInfo}
              weight="bold"
              className={styles.chat__seeMore}
            >
              Смотреть больше
            </BodyNormal>
          )}
        </div>
        <div
          className={clsx(
            styles.chat__messagesContainer,
            isPhone && styles.chat__messagesContainer_mobile
          )}
        >
          <div className={styles.messages} ref={messagesRootRef}>
            {!!feedbackItem?.messages.length ? (
              <ChatMessages
                messages={feedbackItem.messages}
                feedbackCreatorId={feedbackItem.userId}
              />
            ) : (
              <div className={styles.messages__noMessages}>
                <Chats size={54} />
              </div>
            )}
          </div>
          {!!selectedFiles.length && (
            <InputFiles
              files={selectedFiles}
              setFiles={setSelectedFiles}
              isLoading={isCreateLoading}
            />
          )}
        </div>
        <ChatActions
          onSubmit={handleSubmit}
          files={selectedFiles}
          setFiles={setSelectedFiles}
          disabled={isClosed}
        />
      </div>
    </div>
  );
};
