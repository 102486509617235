import ResponsibilityService from "@services/responsibility-service";
import { useMutation } from "react-query";

export const useDeleteResponsibilityFileMutation = () => {
  return useMutation(async (id: number) => {
    return new Promise((resolve, reject) => {
      ResponsibilityService.DeleteFile(id, (err, res) => {
        if (err || !res) {
          reject(err);
        } else {
          resolve(res.data);
        }
      });
    });
  });
};
