import {
  $DepartmentState,
  $Departments,
  fetchDepartments,
} from "@store/company/departments-store";
import {
  $UserAddPermissions,
  ACTIONS,
  UserPriorityEnum,
} from "@store/user-store";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

import { BackButton } from "@components/back-button";
import { ColorfulButton } from "@components/common/common";
import DepartmentService from "@services/department-service";
import { DepartmentShortDataT } from "@interfaces/company/departments";
import DocumentTable from "@components/tables/department-tables/document-table";
import { EmployeeListDataT } from "@interfaces/company/employees";
import { EmployeesTable } from "@components/tables";
import { Loader } from "@ui/indicators";
import PositionTable from "@components/tables/department-tables/position-table";
import { UploadSVG } from "@components/svgs";
import clsx from "clsx";
import style from "@scss/pages/company/single-department.module.scss";
import { useImportEmployees } from "@lib/use-import-employees";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";

export enum DepartmentInfoTypeEnum {
  positions = 1,
  employees,
  documents,
}

const SingleDepartment: React.FC = () => {
  const departments = useStore($Departments);
  const { isFetched } = useStore($DepartmentState);
  const permissions = useStore($UserAddPermissions);
  const [currentDepartment, setCurrentDepartment] = useState<
    DepartmentShortDataT | undefined
  >(undefined);
  const [currentDepartmentEmployees, setCurrentDepartmentEmployees] = useState<
    EmployeeListDataT[] | null
  >(null);
  const [addDataStates, setAddDataStates] = useState({
    employeesFetched: false,
    employeesError: false,
  });
  const [type, setType] = useState<DepartmentInfoTypeEnum>(
    DepartmentInfoTypeEnum.positions
  );

  const handleImportEmployees = useImportEmployees();

  const { open } = useModal();
  const query = useRouteMatch<{ id: string }>();
  const history = useHistory();

  useEffect(() => {
    if (!isFetched) {
      fetchDepartments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFetched) {
      const currentDepartment = departments.find(
        (department) => department.id === +query.params.id
      );
      setCurrentDepartment(currentDepartment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched]);

  useEffect(() => {
    if (
      type === DepartmentInfoTypeEnum.employees &&
      !addDataStates.employeesFetched &&
      currentDepartment
    ) {
      DepartmentService.getEmployees(currentDepartment.id, (err, res) => {
        if (err || !res) {
          setAddDataStates({ ...addDataStates, employeesError: true });
          return console.error(
            "При получении данных сотрудников произошла ошибка"
          );
        }
        setAddDataStates({ ...addDataStates, employeesFetched: true });
        setCurrentDepartmentEmployees(res.data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, currentDepartment]);

  return (
    <div>
      <BackButton className={style.backButton} />
      <div className={clsx(style.department_top_panel)}>
        <p className={clsx(style.department_title)}>
          {currentDepartment?.title}
        </p>
        <button
          onClick={() => setType(1)}
          className={clsx(style.department_type_btn, {
            [style.active]: type === DepartmentInfoTypeEnum.positions,
          })}
        >
          Должности
        </button>
        {/* TODO: В работе */}
        {/* <button
          onClick={() => setType(2)}
          className={clsx(style.department_type_btn, {
            [style.active]: type === DepartmentInfoTypeEnum.employees,
          })}
        >
          Сотрудники
        </button> */}
        <button
          onClick={() => setType(3)}
          className={clsx(style.department_type_btn, {
            [style.active]: type === DepartmentInfoTypeEnum.documents,
          })}
        >
          Документы
        </button>
      </div>
      {!currentDepartment ? (
        <Loader />
      ) : type === DepartmentInfoTypeEnum.positions ? (
        <>
          <div className={clsx(style.creation_btn)}>
            {permissions.hasPermission(
              ACTIONS.companyStaffing_positions_allowedToCreate
            ) && (
              <ColorfulButton
                text={"Добавить должность"}
                onClick={() =>
                  open("CreatePositionModal", {
                    btnText: "Добавить",
                    modalData: {
                      modalTitle: "Добавить должность",
                      fieldTitle: "Название",
                      positionMode: true,
                      departmentId: currentDepartment.id,
                    },
                  })
                }
              />
            )}
            <ColorfulButton
              text="Загрузить список сотрудников"
              customIcon={<UploadSVG />}
              onClick={() => handleImportEmployees(currentDepartment?.id)}
            />
          </div>
          <PositionTable
            items={currentDepartment.positions}
            departmentId={currentDepartment.id}
          />
        </>
      ) : type === DepartmentInfoTypeEnum.employees ? (
        <>
          {permissions.hasPermission(
            ACTIONS.companyStaffing_employees_allowedToCreate
          ) && (
            <div className={clsx(style.creation_btn)}>
              <ColorfulButton
                text={"Добавить сотрудника"}
                onClick={() =>
                  history.push(
                    `/company/employees/create?startDepartment=${query.params.id}`
                  )
                }
              />
            </div>
          )}
          {!currentDepartmentEmployees ? (
            <Loader />
          ) : (
            <EmployeesTable
              items={currentDepartmentEmployees}
              setItems={setCurrentDepartmentEmployees}
              departmentId={currentDepartment.id}
            />
          )}
        </>
      ) : type === DepartmentInfoTypeEnum.documents ? (
        <>
          {permissions.hasRequiredLevel(UserPriorityEnum.Client) ? null : (
            <div className={clsx(style.creation_btn)}>
              <ColorfulButton
                text={"Добавить документы"}
                onClick={() => {
                  history.push({
                    search: "group_id=0",
                  });
                  open("AddingDepartmentDocumentModal", {
                    modalData: {
                      itemId: currentDepartment.id,
                      departmentMode: true,
                    },
                  });
                }}
              />
            </div>
          )}
          <DocumentTable
            departmentId={currentDepartment.id}
            positions={currentDepartment.positions}
          />
        </>
      ) : null}
    </div>
  );
};

export default SingleDepartment;
