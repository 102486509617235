import { companyService } from "@services/company-service";
import { useMutation, useQueryClient } from "react-query";
import { companyQueryKeys } from "./queryKeys";

export const useDeleteCompanyMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((id: number) => companyService.deleteById(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(companyQueryKeys.all);
    },
  });
};
