export const convertToSelectList = <T>(
  array: T[],
  getValue: (item: T) => number,
  getLabel: (item: T) => string,
  filterFn?: (item: T) => boolean
): { id: number; title: string }[] => {
  const filterFunc = filterFn ?? (() => true);

  return array.filter(filterFunc).map((item) => ({
    id: getValue(item),
    title: getLabel(item),
  }));
};
