import { FC } from "react";
import clsx from "clsx";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import { ResponsibilityFileT } from "@interfaces/responsibility";
import { DocumentViewButton } from "@components/common/common";
import style from "@scss/pages/responsibility.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import { useStore } from "effector-react";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { DownloadSimple, NotePencil, Trash } from "@phosphor-icons/react";
import { useModal } from "@modals/index";

export type LegalInformationFilePropsT = {
  file: ResponsibilityFileT;
  isSelected: boolean;
  onDelete: () => void;
  onSelect: (checked: boolean) => void;
};

export const LegalInformationFile: FC<LegalInformationFilePropsT> = ({
  file,
  isSelected,
  onDelete,
  onSelect,
}) => {
  const permissions = useStore($UserAddPermissions);

  const modal = useModal();

  const id = `file-${file.id}`;
  const downloadUrl = `${process.env.API_URL}/uploads/legal-information/${file.path}`;

  return (
    <tr>
      <td>
        <span
          className={clsx(
            tableStyle.column_fixed_height,
            tableStyle.checkboxLabel
          )}
        >
          <input
            type="checkbox"
            className={clsx(tableStyle.checkbox_item)}
            hidden
            id={id}
            checked={isSelected}
            onChange={(e) => onSelect(e.target.checked)}
          />
          <label htmlFor={id}>
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </label>
          <svg
            className={clsx(style.file_icon)}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z"
              fill="#DFE3F1"
            />
            <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#B0B7BD" />
            <path d="M30 14L24 8H30V14Z" fill="#CAD1D8" />
          </svg>
          <span className={clsx(tableStyle.nameFile)}>{file.name}</span>
        </span>
      </td>
      <td>{moment(file.updatedAt).format("DD.MM.YYYY")}</td>
      <td>
        <div className={clsx(style.action_col)}>
          <div className={clsx(style.action_buttons)}>
            <Tooltip title="Посмотреть" placement="top">
              <button>
                <DocumentViewButton
                  fileName={file.name}
                  filePath={`uploads/legal-information/${file.path}`}
                />
              </button>
            </Tooltip>
            {permissions.hasPermission(
              ACTIONS.legalInformation_files_allowedToEdit
            ) && (
              <>
                <WrapperSVG color={"orange"} margin>
                  <Tooltip title={"Изменить"} placement="top">
                    <NotePencil
                      size={24}
                      onClick={() =>
                        modal.open("UploadLegalInformationModal", {
                          btnText: "Сохранить",
                          modalData: {
                            modalTitle: "Изменить файл",
                            editMode: true,
                            file,
                          },
                        })
                      }
                    />
                  </Tooltip>
                </WrapperSVG>
                {/* Скрыл эту кнопку, пока не удаляй */}
                {/* <Tooltip title="Редактировать документ" placement="top"> */}
                {/*   <button> */}
                {/*     <a */}
                {/*       href={fullEditorUrl} */}
                {/*       target={"_blank"} */}
                {/*       rel={"noreferrer"} */}
                {/*     > */}
                {/*       <img */}
                {/*         src={EditDocumentIcon} */}
                {/*         alt="Редактировать" */}
                {/*         style={{ width: 24, height: 17 }} */}
                {/*       /> */}
                {/*     </a> */}
                {/*   </button> */}
                {/* </Tooltip> */}
              </>
            )}
            <Tooltip title="Скачать" placement="top">
              <a
                href={downloadUrl}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <WrapperSVG color={"green-light"} margin>
                  <DownloadSimple size={20} />
                </WrapperSVG>
              </a>
            </Tooltip>
            {permissions.hasPermission(
              ACTIONS.legalInformation_files_allowedToDelete
            ) && (
              <Tooltip title={"Удалить"} placement="top">
                <Trash
                  size={24}
                  onClick={() =>
                    modal.open("ConfirmActionModal", {
                      btnText: "Удалить",
                      modalData: {
                        text: `Вы уверены, что хотите удалить файл "${file.name}"?`,
                      },
                      onConfirm: () => onDelete(),
                    })
                  }
                />
              </Tooltip>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};
