import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import Users from "./users";
import SingleUser from "./single-user";

import { setModule } from "@store/user-store";

import { Modules } from "@interfaces/common";

const UsersRoutes = () => {
  useEffect(() => {
    setModule(Modules.USERS);
  }, []);

  return (
    <Switch>
      <Route
        exact
        path={["/settings/users", "/settings/roles"]}
        component={Users}
      />
      <Route exact path="/users/:id" component={SingleUser} />
    </Switch>
  );
};

export default UsersRoutes;
