import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { BodyBold, BodyNormal } from "@ui/fonts";
import {
  editDirectoryInstructionsCategories,
  removeDirectoryInstructionsCategories,
} from "@store/directory-programm-instuctions";
import {
  editDirectoryTrainingCategories,
  removeDirectoryTrainingCategories,
} from "@store/directory-programm-training";
import {
  editProcedureInstructionsCategories,
  removeProcedureInstructionsCategories,
} from "@store/company/procedure-instrunctions-store";
import {
  editProcedureTrainingCategories,
  removeProcedureTrainingCategories,
} from "@store/company/procedure-training-store";

import { Button } from "@ui/button";
import { DeleteCrossSVG } from "@components/svgs/delete-cross";
import { Modal } from "@ui/modal";
import ProcedureControlService from "@services/procedure-control-service";
import { TextFieldTitle } from "@ui/text-field-title";
import styles from "./edit-name-category.module.scss";
import { useState } from "react";
import { useStore } from "effector-react";

type EditNameCategoryProps = {
  title?: string;
  changeTitle?: string;
  id?: number;
  taskGroup?: string;
  onClose: () => void;
  type: "training" | "briefing" | "medical-examination";
  onDelete?: () => void;
  onUpdate?: (title: string) => void;
};

export const EditNameCategory: React.FC<EditNameCategoryProps> = ({
  title,
  id,
  changeTitle,
  taskGroup = "",
  onClose,
  type,
  onDelete,
  onUpdate,
}) => {
  const permissions = useStore($UserAddPermissions);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isTitle, seiIstitle] = useState<string>((changeTitle ?? title) || "");

  const handlerCancel = () => {
    onClose();
    setOpenModal(false);
  };

  const handlerOnDelete = () => {
    if (onDelete) return onDelete();

    if (id) {
      ProcedureControlService.DeleteCategoriesProgramm(id, type, (err, res) => {
        if (err || !res) {
          return Error("При удалении направления произошла ошибка");
        }
        if (res.data) {
          setOpenModal(false);
          onClose();
          if (type === "training") {
            removeProcedureTrainingCategories(res.data.id);
            removeDirectoryTrainingCategories(res.data);
          }
          if (type === "briefing") {
            removeProcedureInstructionsCategories(res.data.id);
            removeDirectoryInstructionsCategories(res.data);
          }
        }
      });
    }
  };

  const onChangeTitle: React.ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    seiIstitle(target.value);
  };

  const onSubmitEdit = () => {
    if (onUpdate) return onUpdate(isTitle);

    if (!!isTitle && id) {
      ProcedureControlService.EditCategoryProgramm(
        id,
        type,
        isTitle,
        taskGroup,
        (err, res) => {
          if (err || !res) {
            return Error("При изменени названия напрвления произошла ошибка");
          }
          if (res.data) {
            onClose();
            if (type === "training") {
              editProcedureTrainingCategories(res.data);
              editDirectoryTrainingCategories(res.data);
            }
            if (type === "briefing") {
              editProcedureInstructionsCategories(res.data);
              editDirectoryInstructionsCategories(res.data);
            }
          }
        }
      );
    }
    onClose();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.edit_title}>
        <TextFieldTitle
          fontSize={"large"}
          maxLength={70}
          autoFocus={true}
          onChange={onChangeTitle}
          name={"title"}
          value={isTitle}
        />
      </div>
      <div className={styles.edit_type}>
        {permissions.hasPermission(ACTIONS.directories_allowedToEdit) && (
          <div onClick={onSubmitEdit}>
            <BodyNormal color="green" weight="bold">
              Сохранить
            </BodyNormal>
          </div>
        )}
        <div onClick={handlerCancel}>
          <BodyNormal color="gray-darker2" weight="bold">
            Отменить
          </BodyNormal>
        </div>
        {permissions.hasPermission(ACTIONS.directories_allowedToDelete) && (
          <>
            <div className={styles.line_path}></div>
            <div
              onClick={() => setOpenModal(true)}
              className={styles.edit_remove}
            >
              <DeleteCrossSVG />
              <BodyNormal color="red" weight="bold">
                Удалить направление
              </BodyNormal>
            </div>
          </>
        )}
      </div>
      <Modal
        open={openModal}
        onClose={handlerCancel}
        className={styles.modal_box}
      >
        <>
          <div className={styles.modal_title}>
            <BodyBold>
              Вы уверены, что хотите удалить направление "{isTitle}" и все
              содержимое
            </BodyBold>
          </div>
          <div className={styles.modal_buttons}>
            <Button
              color={"white-green"}
              borderColor={"green"}
              onClick={handlerCancel}
            >
              <BodyNormal>Отмена</BodyNormal>
            </Button>
            <Button onClick={handlerOnDelete}>
              <BodyNormal>Удалить</BodyNormal>
            </Button>
          </div>
        </>
      </Modal>
    </div>
  );
};
