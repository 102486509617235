import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { CheckboxCheckedSVG, CheckboxSVG } from "@components/svgs";
import { ChoiceStore, useExternalChoiceState } from "@hooks/useChoiceState";
import { useModal } from "@modals/index";
import { $Employees } from "@store/company/employees-store";
import { useStore } from "effector-react";
import style from "@scss/modals/company/create-task.module.scss";
import { FC, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  CreateTaskModalForm,
  CreateTaskModalTabProps,
} from "./create-task-modal";
import { DropDownLabel } from "@ui/drop-down";
import styles from "@scss/modals/company/create-task.module.scss";
import { useCompanyPositions } from "@lib/company-positions";
import { useDepartmentListQuery } from "@lib/departments";
import { EmployeeListDataT } from "@interfaces/company/employees";
import { useSearch } from "@hooks/useSearch";
import { useSearchByName } from "@hooks/useSearchByName";
import { Search } from "@ui/search";
import clsx from "clsx";

const SELECT_ALL = {
  id: 0,
  title: "Выбрать все",
};

export const CreateTaskModalEmployeesTab: FC<CreateTaskModalTabProps> = ({
  className,
  touchField,
}) => {
  const employees = useStore($Employees);
  const { modalData } = useModal();

  const isTaskEditable = modalData.event ? modalData.event.status === 1 : true;

  const { data: departments } = useDepartmentListQuery();
  const { data: positions } = useCompanyPositions();

  const { watch, setValue } = useFormContext<CreateTaskModalForm>();

  const [selectedDepartment, setSelectedDepartment] = useState(SELECT_ALL.id);
  const [selectedPosition, setSelectedPosition] = useState(SELECT_ALL.id);

  const search = useSearch();
  const filteredEmployees = useSearchByName(search.debounced, employees);

  const selectedEmployees = watch("employees");
  const store: ChoiceStore<number> = {
    items: selectedEmployees,
    add: (employees) => {
      setValue("employees", [...selectedEmployees, ...employees]);
    },
    set: (employees) => {
      setValue("employees", employees);
    },
    remove: (employees) => {
      setValue(
        "employees",
        selectedEmployees.filter((item) => !employees.find((id) => id === item))
      );
    },
  };

  const choice = useExternalChoiceState(
    employees.map((employee) => employee.id),
    (id) => id,
    store
  );

  const departmentOptions = [
    SELECT_ALL,
    ...(departments?.map((department) => ({
      id: department.id,
      title: department.title,
    })) ?? []),
  ];
  const positionOptions = [
    SELECT_ALL,
    ...(positions
      ?.filter((position) =>
        selectedDepartment !== SELECT_ALL.id
          ? position.department.id === selectedDepartment
          : true
      )
      .map((position) => ({
        id: position.id,
        title: position.title,
      })) ?? []),
  ];

  const employeeOptions = useMemo(() => {
    const mapEmployees = (employee: EmployeeListDataT) => ({
      id: employee.id,
      name: employee.name,
      signedDocuments: modalData.event?.signedEmployees?.includes(employee.id),
    });

    const options = [];
    for (const employee of filteredEmployees ?? []) {
      if (
        selectedDepartment !== SELECT_ALL.id &&
        employee.position.department.id !== selectedDepartment
      ) {
        continue;
      }

      if (
        selectedPosition !== SELECT_ALL.id &&
        employee.position.id !== selectedPosition
      ) {
        continue;
      }

      options.push(mapEmployees(employee));
    }

    return options;
  }, [
    filteredEmployees,
    selectedPosition,
    selectedDepartment,
    modalData.event?.signedEmployees,
  ]);

  return (
    <div className={className}>
      <div className={styles.createTaskModal__filters}>
        <DropDownLabel
          label="Подразделения"
          list={departmentOptions}
          type="departments"
          handlerChangeFilter={(_, value) => {
            setSelectedPosition(SELECT_ALL.id);

            if (!value) {
              return setSelectedDepartment(SELECT_ALL.id);
            }
            setSelectedDepartment(parseInt(value));
          }}
        />
        <DropDownLabel
          label="Должности"
          list={positionOptions}
          type="positions"
          handlerChangeFilter={(_, value) => {
            if (!value) {
              return setSelectedPosition(SELECT_ALL.id);
            }
            setSelectedPosition(parseInt(value));
          }}
        />
        <Search
          value={search.value}
          onChange={search.onChange}
          options={employees}
          getOptionLabel={(employee) => employee.name}
          placeholder="Поиск по имени"
          showDropDown={false}
          className={clsx(
            style.createTaskModal__search,
            style.createTaskModal__employeesSearch
          )}
        />
      </div>
      <div className={style.createTaskModal__list}>
        <Table>
          <TableHead>
            <TableCell width={40}>
              <Checkbox
                checked={choice.areAllItemsSelected}
                onChange={(e) => {
                  touchField("employees");
                  choice.selectSeveral(
                    filteredEmployees?.map((employee) => employee.id) ?? [],
                    e.target.checked
                  );
                }}
                color="primary"
                icon={<CheckboxSVG />}
                checkedIcon={<CheckboxCheckedSVG />}
                disabled={!isTaskEditable}
                classes={{
                  disabled: style.createTaskModal__disabledCheckbox,
                }}
              />
            </TableCell>
            <TableCell>Сотрудник</TableCell>
            <TableCell>Подписали документы</TableCell>
          </TableHead>
          <TableBody>
            {employeeOptions.map((employee) => (
              <TableRow key={employee.id}>
                <TableCell>
                  <Checkbox
                    checked={choice.getIsItemSelected(employee.id)}
                    onChange={(e) => {
                      touchField("employees");
                      choice.selectById(employee.id, e.target.checked);
                    }}
                    color="primary"
                    icon={<CheckboxSVG />}
                    checkedIcon={<CheckboxCheckedSVG />}
                    disabled={!isTaskEditable}
                    classes={{
                      disabled: style.createTaskModal__disabledCheckbox,
                    }}
                  />
                </TableCell>
                <TableCell>{employee.name}</TableCell>
                <TableCell>{employee.signedDocuments ? "Да" : "Нет"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
