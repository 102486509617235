import { SystemFontWeightT } from "@interfaces/system";

export function correctWeight(weight: SystemFontWeightT): number {
  return weight === "bold"
    ? 600
    : weight === "medium"
      ? 400
      : weight === "normal"
        ? 400
        : 300;
}
