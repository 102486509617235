import requestTrainingService from "@services/request-training-service";
import { useMutation, useQueryClient } from "react-query";
import { trainingRequestsQueryKeys } from "./queryKeys";

export const useDeleteTrainingRequestMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((id: number) => requestTrainingService.deleteById(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(trainingRequestsQueryKeys.all);
    },
  });
};
