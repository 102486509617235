import { BodyBold, BodyNormal } from "@ui/fonts";
import {
  GetAllTrainingRequestsParams,
  requestTrainingStatusList,
  trainingRequestsSortings,
} from "@interfaces/request-training";

import { DropDownLabel } from "@ui/drop-down";
import { TableTraining } from "./table-training";
import styles from "./request-training.module.scss";
import { useSorting } from "@hooks/useSorting";
import { useEffect, useState } from "react";
import { useTrainingRequestsQuery } from "@lib/request-training";
import { useSelectedCompanyQuery } from "@lib/company";

type Choice = {
  id: string | number;
  title: string;
};

const firstChoice: Choice = {
  id: 0,
  title: "Выбрать...",
};

export const RequestTraining: React.FC = () => {
  const { company } = useSelectedCompanyQuery();

  const [filters, setFilters] = useState<GetAllTrainingRequestsParams>({
    customer: company?.id,
  });

  const sorting = useSorting(trainingRequestsSortings);

  const { trainingRequests } = useTrainingRequestsQuery({
    ...filters,
    ...sorting.getActiveSorting(),
  });

  const statusList = [firstChoice, ...requestTrainingStatusList];

  const handlerChangeFilter = (key: string, value: string) => {
    if (key === "status") {
      return setFilters((prev) => ({
        ...prev,
        status: value || undefined,
      }));
    }
  };

  useEffect(() => {
    if (!company) return;

    setFilters((prev) => ({
      ...prev,
      customer: company.id,
    }));
  }, [company]);

  return (
    <div className={styles.content}>
      <div className={styles.content__head}>
        <BodyBold weight="bold">Список заявок</BodyBold>
      </div>
      <div className={styles.content__filter}>
        <DropDownLabel
          label={"Статус заявки"}
          list={statusList}
          type="status"
          handlerChangeFilter={handlerChangeFilter}
          choice={false}
        />
      </div>
      {trainingRequests && (
        <div className={styles.content__table}>
          {trainingRequests.length > 0 ? (
            <TableTraining data={trainingRequests} sorting={sorting} />
          ) : (
            <div className={styles.content__table_empty}>
              <BodyNormal>Ничего не найдено</BodyNormal>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
