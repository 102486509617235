import { FC } from "react";

import clsx from "clsx";

import styles from "./error-modal.module.scss";
import { Modal } from "@ui/modal";
import { Subheader } from "@ui/fonts";
import { Button } from "@ui/button";
import Caution from "@assets/images/caution.png";
import { useHistory } from "react-router-dom";

type ErrorModalPropsT = {
  className?: string;
  open: boolean;
  onClose?: () => void;
};

export const ErrorModal: FC<ErrorModalPropsT> = ({
  className,
  open,
  onClose,
}) => {
  const history = useHistory();

  const handleReload = () => {
    onClose?.();
    history.go(0);
  };

  const handleGoHome = () => {
    onClose?.();
    history.push("/");
    history.go(0);
  };

  return (
    <Modal
      className={clsx(styles.modal, className)}
      open={open}
      onClose={handleReload}
    >
      <img
        className={styles.modal__icon}
        src={Caution}
        alt="Изображение не найдено"
      />
      <Subheader className={styles.modal__title}>Произошла ошибка</Subheader>
      <div className={styles.modal__actions}>
        <Button onClick={handleGoHome} color="green">
          На главную
        </Button>
        <Button
          onClick={handleReload}
          className={styles.modal__secondaryAction}
        >
          Обновить страницу
        </Button>
        {/* <Button onClick={handleSendFeedback}>Написать в поддержку</Button> */}
      </div>
    </Modal>
  );
};
