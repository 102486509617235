import { TabSwicher } from "@ui/tab-swicher";
import { ComponentType, FC, useState } from "react";
import styles from "@scss/pages/company/signing-control.module.scss";
import SigningControlKanban from "./signing-control-kanban";
import SigningControlJournal from "./signing-control-journal";

enum Tab {
  Kanban,
  Journal,
}

const components: { [key in Tab]: ComponentType } = {
  [Tab.Kanban]: SigningControlKanban,
  [Tab.Journal]: SigningControlJournal,
};

const SigningControl: FC = () => {
  const [tab, setTab] = useState<Tab>(Tab.Kanban);

  const Component = components[tab];

  return (
    <div className={styles.signingControl}>
      <div className={styles.signingControl__tabs}>
        <TabSwicher
          value={tab}
          onChange={(value) => setTab(value as unknown as Tab)}
          labels={["Канбан", "Журнал"]}
        />
      </div>
      <Component />
    </div>
  );
};

export default SigningControl;
