import { BodyBold, BodyNormal } from "@ui/fonts";

import styles from "../goskey.module.scss";

export const TextBlock: React.FC = () => {
  return (
    <BodyNormal>
      <div className={styles.block_text}>
        Поскольку документы в сфере охраны труда являются кадровыми, их ведение
        регламентируется поправками о КЭДО.
      </div>
      <div className={styles.block_text}>
        Кроме того, с 1 марта 2022 года начал действовать Федеральный закон от 2
        июля 2021 года № 311-ФЗ, утверждающий право работодателя на внедрение
        ЭДО в области охраны труда (ст. 214.2 ТК РФ).
      </div>
      <div className={styles.block_text}>
        При использовании информационной системы работодателя: <br />
        работодателем должна использоваться усиленная квалифицированная
        электронная подпись (далее - УКЭП) при подписании следующих электронных
        кадровых документов:
      </div>
      <div className={styles.block_text}>
        трудовых договоров, договоров о материальной ответственности,
        ученических договоров, договоров на получение образования без отрыва или
        с отрывом от работы, а также соглашений о внесении изменений в
        вышеперечисленные договоры; приказа (распоряжения) о применении
        дисциплинарного взыскания;уведомления об изменении определенных
        сторонами условий трудового договора.
      </div>
      <div className={styles.block_text}>
        Работодателем могут использоваться при подписании иных электронных
        кадровых документов: <br />
        УКЭП; <br />
        усиленная неквалифицированная электронная подпись (далее — УНЭП).
      </div>
      <div className={styles.block_text}>
        Работником используются УКЭП либо УНЭП при подписании следующих
        электронных кадровых документов:
      </div>
      <div className={styles.block_text}>
        трудового договора, договора о материальной ответственности,
        ученического договора, договора на получение образования без отрыва или
        с отрывом от работы, соглашения о внесении изменений в вышеперечисленные
        договоры; согласия на перевод; заявления об увольнении и отзыва
        заявления об увольнении; подтверждения ознакомления с уведомлением об
        изменении определенных сторонами условий трудового договора, приказом
        (распоряжением) о применении дисциплинарного взыскания.
      </div>
      <div className={styles.block_text}>
        При подписании иных электронных кадровых документов работник вправе
        использовать одну из следующих электронных подписей:
        <br />
        УКЭП;
        <br />
        УНЭП;
        <br />
        простую электронную подпись (далее - ПЭП) в случае, если соглашением
        сторон трудового договора установлены правила определения лица,
        подписывающего электронный документ, по его ПЭП и требования к
        соблюдению конфиденциальности ключа такой подписи.
      </div>
      <br />
      <div className={styles.block_text}>
        <BodyBold color="green">Как настроить подписание документов?</BodyBold>
      </div>
      <div className={styles.block_text}>
        Оформление электронной подписи Для юридически значимого документооборота
        подойдёт только усиленная квалифицированная ЭП.
      </div>
      <div className={styles.block_text}>
        Электронная подпись в охране труда используется лишь для подписания
        документа после ознакомления, поэтому для этих целей подойдёт усиленная
        неквалифицированная ЭП.
      </div>
      <div className={styles.block_text}>
        <ul>
          <li>
            <BodyNormal color="green">1.</BodyNormal> Подготовка документации о
            переходе на кадровый ЭДО.
          </li>
          <li>
            <BodyNormal color="green">2.</BodyNormal> Нужно разработать и
            подписать регламент об ЭДО.
          </li>
          <li>
            <BodyNormal color="green">3.</BodyNormal> Он будет содержать
            определения и термины, правила работы с электронными документами и
            доступа к системе электронного документооборота.
          </li>
          <li>
            <BodyNormal color="green">4.</BodyNormal> Подготовьте график
            документооборота в учётной политике — это поможет в разработке
            собственной схемы работы с документами.
          </li>
          <li>
            <BodyNormal color="green">5.</BodyNormal> Уведомление сотрудников.
          </li>
          <li>
            <BodyNormal color="green">6.</BodyNormal> Работники должны дать своё
            согласие на ведение своих кадровых документов в электронном виде.
          </li>
          <li>
            <BodyNormal color="green">7.</BodyNormal> Подписание документов,
            подтверждающих прохождение работником инструктажей по охране труда
          </li>
        </ul>
      </div>
    </BodyNormal>
  );
};
