import { useQuery } from "react-query";
import { workspaceService } from "@services/workspace-service";

export const useArchivedWorkspaceEmployeeQuery = (
  companyId: number | undefined
) => {
  const { data: employees, ...stuff } = useQuery(
    ["archiverd_employee"],
    () => workspaceService.getIsArchivedEmployee(companyId!),
    {
      enabled: typeof companyId !== "undefined",
    }
  );

  return {
    employees,
    ...stuff,
  };
};
