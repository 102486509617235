import { ProcedureTrainingQueryParams } from "@interfaces/company/procedure";
import { createContext, FC, ReactNode, useContext } from "react";

const context = createContext<ProcedureTrainingQueryParams>({});

export const useTrainingProcedureParamsContext = () => useContext(context);

export const TrainingProcedureParamsProvider: FC<{
  value: ProcedureTrainingQueryParams;
  children: ReactNode;
}> = ({ value, children }) => {
  return <context.Provider value={value}>{children}</context.Provider>;
};
