import {
  ProcedureTrainingQueryParams,
  TablesDirectionDataT,
} from "@interfaces/company/procedure";

import Fetcher from "@http/fetcher";
import { proceduresQueryKeys } from "./queryKeys";
import { useQuery } from "react-query";

export const useTrainingProceduresQuery = (
  params?: ProcedureTrainingQueryParams,
  isGeneral?: boolean
) => {
  return useQuery(
    proceduresQueryKeys.allTrainingProcedures(params),
    async () => {
      const resp = await Fetcher.modified.get<TablesDirectionDataT[]>(
        "/procedures-control/training",
        {
          params,
        }
      );
      return resp.data;
    },
    {
      enabled: !isGeneral,
      keepPreviousData: true,
    }
  );
};

export const useTrainingProcedureGeneralsQuery = (
  params?: ProcedureTrainingQueryParams,
  isGeneral?: boolean
) => {
  return useQuery(
    proceduresQueryKeys.allTrainingProcedures(params),
    async () => {
      const resp = await Fetcher.get<TablesDirectionDataT[]>(
        `companies/procedures-control/training`,
        {
          params,
        }
      );

      return resp.data;
    },
    {
      enabled: isGeneral,
      keepPreviousData: true,
    }
  );
};
