import { BreadCrumb } from "@components/common";
import { Profile } from "@feature";
import { Title } from "@components/common/common";

export const ProfilePage: React.FC = () => {
  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={["Главная", "Личный кабинет"]} />
          <Title text="Личный кабинет" />
        </div>
        <Profile />
      </div>
    </main>
  );
};
