export const ItemRow = [
  {
    title: "Виды подписей",
    li: [
      "ПЭП – простой электронной подписью ",
      "УНЭП – усиленной неквалифицированной электронной подписью",
      "УКЭП – усиленной квалифицированной электронной подписью",
    ],
  },
  {
    title: "Что такое “Госключ”",
    li: [
      "Госключ — это приложение для подписания юридически значимых документов в электронном виде.",
      "В приложении можно бесплатно получить сертификат и сформировать усиленную квалифицированную или неквалифицированную электронную подпись (УКЭП или УНЭП).",
      "Оно соответствует требованиям к средствам электронной подписи.",
    ],
  },
  {
    title: "Нормативные правовые акты",
    li: [
      "В настоящее время согласно статье 22.1 Трудового Кодекса Российской Федерации электронное подписание не применяется в отношении документов, подтверждающих прохождение работником инструктажей по охране труда. ",
      "Обращаем Ваше внимание, что согласно Постановления Правительства РФ от 24.12.2021 № 2464 «О порядке обучения по охране труда и проверки знания требований охраны труда» с 1 сентября 2022 года порядок регистрации проведенного инструктажа по охране труда и форма его документирования утверждаются работодателем.",
      "В Государственную Думу внесён Проект Федерального закона № 270457-8 «О внесении изменений в статьи 22.1 и 22.3 Трудового кодекса Российской Федерации», разрешающий электронное подписание документов, подтверждающих прохождение работником инструктажей по охране труда с 1 сентября 2023 года. ",
    ],
  },
];
