import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import React, { useEffect, useRef, useState } from "react";
import {
  useApplyForEducationalEventMutation,
  useStudyCenterEducationalEventsQuery,
} from "@lib/study-center";

import { Button } from "@ui/button";
import { Link } from "react-router-dom";
import { Loader } from "@ui/indicators";
import { MaterialCard } from "./material-card";
import { studyCenterQueryKeys } from "@lib/study-center/queryKeys";
import styles from "./study-materials.module.scss";
import { useModal } from "@modals/index";
import { useQueryClient } from "react-query";
import { useStore } from "effector-react";

export const EducationalEventsTab: React.FC = () => {
  const permissions = useStore($UserAddPermissions);
  const hiddenDiv = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState<number>(0);
  const [isBottom, setIsBottom] = useState(false);
  const queryClient = useQueryClient();

  const handleIsBottom = (e: boolean) => {
    setIsBottom(e);
  };

  useEffect(() => {
    queryClient.removeQueries(studyCenterQueryKeys.educationalEvents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    data: events,
    isLoading,
    fetchNextPage,
  } = useStudyCenterEducationalEventsQuery({
    offset: page,
    enabled: true,
  });

  useEffect(() => {
    if (!isBottom && isLoading) return;
    if (events?.pages?.length) {
      if (events?.pages[events?.pages.length - 1].length === 0) {
        return;
      }
    }
    setPage((prevPage) => prevPage + 24);
    !!fetchNextPage && fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBottom]);

  const applyForEventMutation = useApplyForEducationalEventMutation();
  const modal = useModal();

  const handleApply = (id: number) => {
    modal.open("SelectCompanyModal", {
      modalData: {
        title: "Для записи на событие выберите компанию.",
      },
      onConfirm: (companyId: number | undefined) => {
        if (companyId === undefined || id === undefined) return;

        applyForEventMutation.mutate(
          {
            id,
            companyId,
          },
          {
            onSuccess: () => {
              modal.open("ConfirmActionModal", {
                btnText: "Хорошо",
                modalData: {
                  title: "Заявка отправлена",
                  text: "Спасибо! Ваша заявка отправлена. В ближайшее время с вами свяжется Администратор для уточнения деталей",
                  removeIcon: true,
                  removeCancelBtn: true,
                  style: {
                    maxWidth: "500px",
                  },
                },
              });
            },
          }
        );
      },
    });
  };

  return (
    <div>
      {permissions.hasPermission(
        ACTIONS.educationalCenter_materials_allowedToCreate
      ) && (
        <Link to="/study-center/materials/educational-events/create">
          <Button className={styles.Materials__createBtn}>
            Создать событие или вебинар
          </Button>
        </Link>
      )}
      <div>
        {isLoading && <Loader />}
        {!isLoading && events && events?.pages?.[0] && (
          <>
            {events?.pages?.map((item, i) => {
              if (!item.length) {
                return <></>;
              }
              return (
                <div key={i} className={styles.Materials__list}>
                  {item?.map((event, index) => (
                    <div key={event.id}>
                      <MaterialCard
                        material={event}
                        type="educational-event"
                        onApply={() => handleApply(event.id)}
                        handleIsBottom={handleIsBottom}
                        hiddenDiv={index === item.length - 1 ? hiddenDiv : null}
                      />
                    </div>
                  ))}
                  <div ref={hiddenDiv} />
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};
