import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { BodyNormal, Subtitle } from "@ui/fonts";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  useApplyForEducationalPromotionMutation,
  useDeleteEducationalPromotionMutation,
  useStudyCenterEducationalPromotionQuery,
} from "@lib/study-center";

import { BackButton } from "@components/back-button";
import { Button } from "@ui/button";
import React from "react";
import { StudyCenterEducationalPromotion } from "@interfaces/study-center";
import { TypographyStylesProvider } from "@mantine/core";
import clsx from "clsx";
import moment from "moment";
import styles from "./educational-promotion-page.module.scss";
import { useModal } from "@modals/index";
import { useStore } from "effector-react";

type EducationalPromotionPageParams = {
  id?: string;
};

export const EducationalPromotionPage: React.FC = () => {
  const history = useHistory();
  const articleId = useParams<EducationalPromotionPageParams>()?.id;

  const modal = useModal();

  const id = typeof articleId === "undefined" ? undefined : +articleId;
  const { educationalPromotion } = useStudyCenterEducationalPromotionQuery(id);

  const applyForEducationalPromotionMutation =
    useApplyForEducationalPromotionMutation();
  const deleteEducationalPromotionMutation =
    useDeleteEducationalPromotionMutation();

  const formattedDate = moment(educationalPromotion?.date).format(
    "DD MMM YYYYг., HH:mm"
  );

  const handleDelete = () => {
    if (!educationalPromotion) return;

    modal.open("ConfirmActionModal", {
      btnText: "Удалить",
      modalData: {
        text: `Вы уверены, что хотите удалить акцию или предложение ${educationalPromotion.title}?`,
        style: {
          maxWidth: "500px",
        },
      },
      onConfirm: () => {
        deleteEducationalPromotionMutation.mutate(educationalPromotion.id, {
          onSuccess: () => {
            history.push("/study-center/materials/educational-promotions");
          },
        });
      },
    });
  };

  const handleApply = () => {
    modal.open("SelectCompanyModal", {
      modalData: {
        title: "Для получения скидки выберите компанию.",
      },
      onConfirm: (companyId: number | undefined) => {
        if (companyId === undefined || id === undefined) return;

        applyForEducationalPromotionMutation.mutate(
          {
            id,
            companyId,
          },
          {
            onSuccess: () => {
              modal.open("ConfirmActionModal", {
                btnText: "Хорошо",
                modalData: {
                  title: "Заявка отправлена",
                  text: "Спасибо! Ваша заявка отправлена. В ближайшее время с вами свяжется Администратор для уточнения деталей",
                  removeIcon: true,
                  removeCancelBtn: true,
                  style: {
                    maxWidth: "500px",
                  },
                },
              });
            },
          }
        );
      },
    });
  };

  if (!educationalPromotion) {
    return null;
  }

  return (
    <div className={styles.wrapper_article}>
      <div className={styles.header_block}>
        <BackButton />
        <Button
          color="green"
          onClick={handleApply}
          className={styles.Article__applyBtn}
        >
          Получить скидку
        </Button>
      </div>
      <div className={styles.Article}>
        <div className={styles.Article__leftSide}>
          <div className={styles.Article__backBtn} tabIndex={0}></div>
          <AdminControls
            className={styles.Article__mobileAdminControls}
            material={educationalPromotion}
            onDelete={handleDelete}
          />
        </div>

        <div className={styles.Article__content}>
          <div className={styles.Article__controls}>
            <Subtitle weight="bold">{educationalPromotion.title}</Subtitle>
          </div>
          <div className={styles.Article__body}>
            {educationalPromotion.imagePath && (
              <img
                src={`${process.env.API_URL}/uploads/educational-center/offers/promotions/${educationalPromotion.imagePath}`}
                alt={educationalPromotion.title}
                className={styles.Article__image}
              />
            )}
            <BodyNormal weight="bold">{formattedDate}</BodyNormal>
            {!!educationalPromotion.description && (
              <TypographyStylesProvider>
                <div
                  className={clsx(styles.Text)}
                  dangerouslySetInnerHTML={{
                    __html: educationalPromotion.description ?? "",
                  }}
                />
              </TypographyStylesProvider>
            )}
          </div>
        </div>
        <AdminControls
          className={styles.Article__adminControls}
          material={educationalPromotion}
          onDelete={handleDelete}
        />
      </div>
    </div>
  );
};

type AdminControlsProps = {
  material: StudyCenterEducationalPromotion;
  className: string;
  onDelete: () => void;
};

const AdminControls: React.FC<AdminControlsProps> = ({
  material,
  className,
  onDelete,
}) => {
  const permissions = useStore($UserAddPermissions);

  return (
    <div className={className}>
      {permissions.hasPermission(
        ACTIONS.educationalCenter_materials_allowedToEdit
      ) && (
        <Link
          to={`/study-center/materials/educational-promotions/edit/${material.id}`}
        >
          <button
            className={clsx(
              styles.Article__adminControlsBtn,
              "colorful_action_btn"
            )}
          >
            Изменить
          </button>
        </Link>
      )}
      {permissions.hasPermission(
        ACTIONS.educationalCenter_materials_allowedToDelete
      ) && (
        <button
          className={clsx(
            styles.Article__adminControlsBtn,
            "colorful_action_btn"
          )}
          onClick={onDelete}
        >
          Удалить
        </button>
      )}
    </div>
  );
};
