import { useMutation, useQueryClient } from "react-query";

import {
  WorkspaceArchiveEmployeesParams,
  WorkspaceEmployeeParams,
} from "@interfaces/company/workspace";
import { workspaceService } from "@services/workspace-service";

export const useUnarchiveWorkspaceEmployeeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: WorkspaceEmployeeParams) => workspaceService.unarchiveEmployee(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["archiverd_employee"]);
      },
    }
  );
};

export const useUnarchiveWorkspaceEmployeesMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: WorkspaceArchiveEmployeesParams) =>
      workspaceService.unarchiveEmployees(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["archiverd_employee"]);
      },
    }
  );
};
