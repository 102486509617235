import React, { memo } from "react";

import { BreadCrumb } from "@components/common";
import { Goskey } from "@feature";
import { Title } from "@components/common/common";

const GosKey: React.FC = memo(() => {
  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={["Главная", "Госключ"]} />
          <Title text="Госключ" />
        </div>
        <div>
          <Goskey />
          {/* <BodyBold>
            Раздел находится в разработке. Скоро здесь появится актуальная
            информация
          </BodyBold> */}
        </div>
      </div>
    </main>
  );
});

export default GosKey;
