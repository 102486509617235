import {
  AddNewSuperAdminPropsT,
  CheckAuthResT,
  ServiceLoginPropsT,
  ServiceSignupPropsT,
  SignupResT,
} from "@interfaces/requests/auth";
import { resetAllStates, resetUserData } from "@store/user-store";

import Fetcher from "@http/fetcher";

import { ResCallback } from "@interfaces/common";
import { resetCompany } from "@store/company/company-store";
import { setAuth } from "@store/auth-store";

class AuthService {
  static async CheckAuth(cb: ResCallback<CheckAuthResT>) {
    try {
      const accessToken = localStorage.getItem("token");
      if (!accessToken) {
        throw new Error("Не авторизован");
      }

      const res = await Fetcher.post<CheckAuthResT>("/auth/check");

      if (res?.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async login(data: ServiceLoginPropsT, cb: ResCallback<string>) {
    try {
      const res = await Fetcher.post<string>("/auth/login", data);

      if (res?.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async signup(data: ServiceSignupPropsT, cb: ResCallback<SignupResT>) {
    try {
      const res = await Fetcher.post<SignupResT>("/auth/signup", data);

      if (res?.status === 201 || res?.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async logout(cb: ResCallback<unknown>) {
    setAuth(false);
    resetAllStates();
    resetUserData();
    resetCompany();
    try {
      const res = await Fetcher.post("/auth/logout");

      if (res?.status === 200) {
        return cb(null);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async AddNewSuperAdmin(
    data: AddNewSuperAdminPropsT,
    cb: ResCallback<void>
  ) {
    try {
      const res = await Fetcher.post<void>("/auth/superadmin", data);

      if (res?.status === 201) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }
}

export default AuthService;
