import React, { memo } from "react";

import { BreadCrumb } from "@components/common";
import { ControlRequestTraining } from "@feature";
import { Title } from "@components/common/common";

const ControlRequestTrainingPage: React.FC = memo(() => {
  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={["Главная", "Контроль заявок на обучение"]} />
          <Title text="Контроль заявок на обучение" />
        </div>
        <ControlRequestTraining />
      </div>
    </main>
  );
});

export default ControlRequestTrainingPage;
