import {
  $RolesPermissions,
  $UserAddPermissions,
  ACTIONS,
  GetRolePriorityName,
  UserPriorityEnum,
  getPermissionName,
  pushToRolesPermissions,
} from "@store/user-store";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { CheckboxCheckedSVG, CheckboxSVG } from "@components/svgs";
import { selectColourStyles, selectTheme } from "@components/common/common";
import { useEffect, useMemo, useState } from "react";

import { Permission } from "@interfaces/store/users-store-types";
import RolesPermissionsService from "@services/roles-permissions-service";
import Select from "react-select";
import { SimpleOptionT } from "@interfaces/company/employees";
import s from "@scss/modals/users/create_user.module.scss";
import { useLocation } from "react-router-dom";
import useModal from "@modals/modal-hook";
import { useStore } from "effector-react";
import useStyles from "@ui/material-ui-styles";

const CreateRoleModal = () => {
  const { modalComponent, modalData, close } = useModal();
  const classes = useStyles();
  const rolesNPermissions = useStore($RolesPermissions);
  const permissions = useStore($UserAddPermissions);
  const location = useLocation();

  const [permissionsIds, setPermissionsIds] = useState<Set<number>>(
    new Set([])
  );
  const [roleName, setRoleName] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<SimpleOptionT>();
  const [options, setOptions] = useState<SimpleOptionT[]>([]);

  useEffect(() => {
    const avaliablePriorities: number[] = [];
    rolesNPermissions &&
      rolesNPermissions.roles.forEach((role) => {
        if (!avaliablePriorities.includes(role.priority))
          avaliablePriorities.push(role.priority);
      });
    setOptions(
      avaliablePriorities
        .filter(function (priority) {
          if (priority === 0) {
            return false;
          }
          if (
            priority === 1 &&
            !permissions.hasPermission(
              ACTIONS.roles_levelFirstRoles_allowedToCreate
            )
          ) {
            return false;
          }
          if (
            priority === 2 &&
            !permissions.hasPermission(
              ACTIONS.roles_levelSecondRoles_allowedToCreate
            )
          ) {
            return false;
          }
          if (
            priority === 3 &&
            !permissions.hasPermission(
              ACTIONS.roles_levelThirdRoles_allowedToCreate
            )
          ) {
            return false;
          }
          if (
            priority === 4 &&
            !permissions.hasPermission(
              ACTIONS.roles_levelFourthRoles_allowedToCreate
            )
          ) {
            return false;
          }
          return true;
        })
        .map((priority) => {
          return {
            value: priority,
            label: `${priority} уровень (${GetRolePriorityName(priority)})`,
          };
        })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesNPermissions]);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!selectedOption) return console.error("Приоритетность роли не выбрана");
    RolesPermissionsService.CreateRole(
      {
        name: roleName,
        permissions: Array.from(permissionsIds),
        companyId: location.pathname.includes("company")
          ? modalData.companyId
          : undefined,
        priority: selectedOption.value,
      },
      (err, res) => {
        if (err || !res) {
        } else {
          pushToRolesPermissions(res.data);
          close();
        }
      }
    );
  };

  const onChangeHandler = (e: any, permission: any) => {
    if (!e.target.checked) {
      permissionsIds.delete(permission.id);
      setPermissionsIds(new Set(Array.from(permissionsIds)));
    } else {
      setPermissionsIds(
        new Set([...Array.from(permissionsIds), permission.id])
      );
    }
  };
  const memoPermissions = useMemo(
    () =>
      rolesNPermissions?.permissions.map(
        (permission: Permission, index: number) => {
          if (
            !permissions.hasRequiredLevel(UserPriorityEnum.SuperAdmin) &&
            [
              ACTIONS.roles_levelFirstRoles_allowedToCreate,
              ACTIONS.roles_levelFirstRoles_allowedToEdit,
              ACTIONS.roles_levelFirstRoles_allowedToAssign,
            ].includes(permission.action_name)
          )
            return null;
          if (
            !permissions.hasRequiredLevel(UserPriorityEnum.Admin) &&
            [
              ACTIONS.roles_levelFirstRoles_allowedToCreate,
              ACTIONS.roles_levelFirstRoles_allowedToEdit,
              ACTIONS.roles_levelFirstRoles_allowedToAssign,
              ACTIONS.roles_levelSecondRoles_allowedToCreate,
              ACTIONS.roles_levelSecondRoles_allowedToEdit,
              ACTIONS.roles_levelSecondRoles_allowedToAssign,
            ].includes(permission.action_name)
          )
            return null;

          let title: string | null = null;
          switch (permission.id) {
            case 1:
              title = "Личный кабинет";
              break;
            case 101:
              title = "Пользователи";
              break;
            case 201:
              title = "Роли";
              break;
            case 301:
              title = "Компании";
              break;
            case 401:
              title = "Компания - рабочее пространство";
              break;
            case 501:
              title = "Компания - штатное расписание";
              break;
            case 601:
              title = "Компания - календарь событий";
              break;
            case 701:
              title = "Компания - план работы специалиста";
              break;
            case 801:
              title = "Компания - контроль подписания документов";
              break;
            case 901:
              title = "Компания - предписания";
              break;
            case 1001:
              title = "Компания - контроль выполнения процедур";
              break;
            case 1101:
              title = "Компания - заявки на обучение";
              break;
            case 1201:
              title = "Учебный центр";
              break;
            case 1301:
              title = "Новости законодательства";
              break;
            case 1401:
              title = "Полезные материалы";
              break;
            case 1501:
              title = "Ответственность";
              break;
            case 1601:
              title = "Юридическая информация";
              break;
            case 1701:
              title = "Судебная практика";
              break;
            case 1801:
              title = "Оплата";
              break;
            case 1901:
              title = "Помощь специалиста";
              break;
            case 2001:
              title = "Справочники";
              break;
            case 2101:
              title = "Техподдержка";
              break;
          }
          return (
            <div>
              {title && <div className="mt-30 mb-5 font-lg">{title}:</div>}
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    defaultChecked={false}
                    name="isFinancial"
                    color="primary"
                    icon={<CheckboxSVG />}
                    checkedIcon={<CheckboxCheckedSVG />}
                  />
                }
                label={getPermissionName(permission.action_name)}
                labelPlacement="start"
                onChange={(e) => onChangeHandler(e, permission)}
              />
            </div>
          );
        }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [permissionsIds.size, rolesNPermissions]
  );

  return (
    <div key={modalComponent.key} className={s.wrapper}>
      <p className="modal_title">{modalData.modalTitle}</p>
      <div className="underline" />
      <form className={`modal_content ${classes.root}`} onSubmit={handleSubmit}>
        <TextField
          label={"Введите название новой роли"}
          name={"directoryTitle"}
          variant="filled"
          required
          onChange={(e) => setRoleName(e.target.value)}
        />
        <Select
          value={selectedOption}
          options={options}
          placeholder={"Уровень роли"}
          onChange={(value) => setSelectedOption(value)}
          styles={selectColourStyles()}
          theme={selectTheme}
          menuPlacement="auto"
          menuPortalTarget={document.body}
        />
        <p className={s.title}>Выберите функционал для роли:</p>
        <div className={s.list}>{memoPermissions}</div>
        <button type="submit" className="modal_btn">
          {modalComponent.btnText}
        </button>
        <button
          type="submit"
          style={{ marginLeft: "24px" }}
          className="modal_btn colorful-btn"
          onClick={close}
        >
          Отменить
        </button>
      </form>
    </div>
  );
};

export default CreateRoleModal;
