import { ControlStatus, Procedure } from "@interfaces/company/procedure";

export const isAllowedToCreateTrainingRequest = (
  row: Procedure,
  selectedCompanyId: number | undefined
): boolean => {
  const isSameCompany =
    selectedCompanyId === undefined
      ? true
      : selectedCompanyId === row.companyId;
  const isStatusRight =
    row.status === ControlStatus.NoActionRequired ||
    row.status === ControlStatus.RequiredToFill;

  return isSameCompany && isStatusRight;
};
