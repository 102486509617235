import { FC, useState } from "react";
import {
  DropDownDeadlineSelect,
  DropDownMultiSelect,
  SimpleDropDownMultiSelect,
} from "@ui/drop-down";
import styles from "./medical-exam-controls-filters.module.scss";
import { DatePeriod } from "@ui/date-period";
import { useDisclosure } from "@mantine/hooks";
import CalendarMonth from "@mui/icons-material/CalendarMonth";

enum FilterType {
  Companies = "companies",
  Departments = "departments",
  Positions = "positions",
  Factors = "factors",
  Employees = "employees",
}

export type SelectList = {
  id: number;
  title: string;
};

export type MedicalExamControlsFiltersPropsT = {
  departments: SelectList[];
  positions: SelectList[];
  employees: SelectList[];
  factors: SelectList[];
  companies?: SelectList[];
  selectedDepartments: SelectList[];
  selectedPositions: SelectList[];
  selectedEmployees: SelectList[];
  selectedFactors: SelectList[];
  selectedCompanies?: SelectList[];
  onDepartmentsChange: (values: SelectList[]) => void;
  onPositionChange: (values: SelectList[]) => void;
  onEmployeeChange: (values: SelectList[]) => void;
  onFactorChange: (values: SelectList[]) => void;
  onStartDateChange: (value: number[] | undefined) => void;
  onDeadlineChange: (value: number[] | undefined) => void;
  onCompaniesChange?: (values: SelectList[]) => void;
};

export const MedicalExamControlsFilters: FC<
  MedicalExamControlsFiltersPropsT
> = ({
  departments,
  positions,
  employees,
  factors,
  companies,
  selectedDepartments,
  selectedPositions,
  selectedEmployees,
  selectedFactors,
  selectedCompanies,
  onDepartmentsChange,
  onPositionChange,
  onEmployeeChange,
  onFactorChange,
  onStartDateChange,
  onDeadlineChange,
  onCompaniesChange,
}) => {
  const handleChangeFilter = (key: string, values: SelectList[]) => {
    switch (key) {
      case FilterType.Departments:
        onDepartmentsChange(values);
        break;
      case FilterType.Positions:
        onPositionChange(values);
        break;
      case FilterType.Employees:
        onEmployeeChange(values);
        break;
      case FilterType.Factors:
        onFactorChange(values);
        break;
      case FilterType.Companies:
        onCompaniesChange?.(values);
        break;
    }
  };

  const handleChangeStartDate = (value: number[] | undefined) => {
    if (value && value.length < 2) return;
    onStartDateChange(value);
  };

  const handleChangeDeadline = (value: string) => {
    if (!value) {
      return onDeadlineChange(undefined);
    }

    const arr = value
      .split(";")
      .map((v) => v.split(",").map((v) => parseInt(v)))
      .flat();
    onDeadlineChange(arr);
  };

  return (
    <div className={styles.filters}>
      {companies && onCompaniesChange && selectedCompanies && (
        <SimpleDropDownMultiSelect
          label="Все компании"
          options={companies}
          emptyIsFull
          value={selectedCompanies}
          onChange={(values) => handleChangeFilter("companies", values)}
        />
      )}
      <SimpleDropDownMultiSelect
        label="Все подразделения"
        options={departments}
        emptyIsFull
        value={selectedDepartments}
        onChange={(values) => handleChangeFilter("departments", values)}
      />
      <SimpleDropDownMultiSelect
        label="Все должности"
        options={positions}
        emptyIsFull
        value={selectedPositions}
        onChange={(values) => handleChangeFilter("positions", values)}
      />
      <SimpleDropDownMultiSelect
        label="Все сотрудники"
        options={employees}
        emptyIsFull
        value={selectedEmployees}
        onChange={(values) => handleChangeFilter("employees", values)}
      />
      <SimpleDropDownMultiSelect
        label="Все факторы"
        options={factors}
        emptyIsFull
        value={selectedFactors}
        onChange={(values) => handleChangeFilter("factors", values)}
      />
      <DatePicker
        label="Дата следующего мед. осмотра"
        onChange={handleChangeStartDate}
      />
      <DropDownDeadlineSelect
        type="deadline"
        title="Дней до мед. осмотра"
        handlerChangeFilter={(_, value) => handleChangeDeadline(value)}
      />
    </div>
  );
};

type DatePickerPropsT = {
  label: string;
  onChange: (value: number[] | undefined) => void;
};

const DatePicker: FC<DatePickerPropsT> = ({ label, onChange }) => {
  const [isModalOpen, modal] = useDisclosure();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [textDate, setTextDate] = useState("");

  const handleChange = (_: string, value: string[] | undefined) => {
    if (!value) {
      return onChange(undefined);
    }
    onChange(value.map((v) => parseInt(v)));
  };

  return (
    <div>
      <div className={styles.date} onClick={modal.open}>
        <div className={styles.date__label}>{label}</div>
        {textDate}
        <CalendarMonth color="action" className={styles.date__icon} />
      </div>
      <DatePeriod
        active={isModalOpen}
        closeModal={modal.close}
        setTextDate={setTextDate}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        type="startDate"
        handlerChangeFilter={handleChange}
      />
    </div>
  );
};
