import { useMutation, useQueryClient } from "react-query";
import { WorkspaceDirectoryData } from "@interfaces/company/workspace";
import { workspaceService } from "@services/workspace-service";
import { workspaceQueryKeys } from "./queryKeys";

export const useUpdateWorkspaceDirectoryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: WorkspaceDirectoryData) => workspaceService.updateDirectory(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(workspaceQueryKeys.allDirectoriesFiles);
      },
    }
  );
};
