import { localStorageUse } from "@utils/localStorageUse";
import { useEffect, useState } from "react";

export const useTableColumnsWidth = (
  tableName: string,
  defaultValue: number[]
) => {
  const [columnsWidth, setColumnsWidth] = useState<number[]>(defaultValue);

  const update = (width: number[]) => {
    localStorageUse.saveItem(`${tableName}-columns-width`, width.join(";"));
    setColumnsWidth(width);
  };

  useEffect(() => {
    const columnsWidth = localStorageUse.getItem(`${tableName}-columns-width`);
    if (!columnsWidth) return;

    setColumnsWidth(
      columnsWidth.split(";").map((value: string) => Number(value))
    );
  }, [tableName]);

  return [columnsWidth, update] as const;
};
