import { HTMLAttributes } from "react";

type SVGPropsT = {
  width?: string;
};

export const DownloadSVG: React.FC<
  HTMLAttributes<HTMLOrSVGElement> & SVGPropsT
> = ({ width = "14", ...props }) => {
  return (
    <svg
      width={width}
      height={+width * 1.5}
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.0625 12.4609C7.22656 12.4609 7.35938 12.4141 7.51562 12.2578L10.1562 9.70312C10.2734 9.58594 10.3438 9.46094 10.3438 9.29688C10.3438 8.97656 10.0938 8.75 9.77344 8.75C9.61719 8.75 9.46094 8.8125 9.35156 8.9375L8.16406 10.1953L7.64062 10.75L7.6875 9.57812V1.34375C7.6875 1.01562 7.39844 0.734375 7.0625 0.734375C6.72656 0.734375 6.44531 1.01562 6.44531 1.34375V9.57812L6.49219 10.75L5.96094 10.1953L4.78125 8.9375C4.67188 8.8125 4.5 8.75 4.35156 8.75C4.02344 8.75 3.78906 8.97656 3.78906 9.29688C3.78906 9.46094 3.85156 9.58594 3.96875 9.70312L6.60938 12.2578C6.77344 12.4141 6.90625 12.4609 7.0625 12.4609ZM2.58594 17.6016H11.5469C13.1797 17.6016 14 16.7891 14 15.1797V7.38281C14 5.77344 13.1797 4.96094 11.5469 4.96094H9.36719V6.21875H11.5234C12.2969 6.21875 12.7422 6.64062 12.7422 7.45312V15.1094C12.7422 15.9219 12.2969 16.3438 11.5234 16.3438H2.60156C1.82031 16.3438 1.39062 15.9219 1.39062 15.1094V7.45312C1.39062 6.64062 1.82031 6.21875 2.60156 6.21875H4.76562V4.96094H2.58594C0.953125 4.96094 0.132812 5.77344 0.132812 7.38281V15.1797C0.132812 16.7891 0.953125 17.6016 2.58594 17.6016Z"
        fill="#00B856"
      />
    </svg>
  );
};
