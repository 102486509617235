import "@scss/pages/payment.scss";

import {
  $UserAddPermissions,
  ACTIONS,
  UserPriorityEnum,
} from "@store/user-store";

import { BreadCrumb, TabItems } from "@components/common";
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import {
  fetchAllTariffs,
  fetchPaymentHistory,
  fetchTariffPeriods,
} from "@store/payment-store";

import PaymentContent from "@pages/payment/payment-content";
import PaymentTariffEdit from "./paymant-edit";
import PaymentTariffManage from "./payment-tariffs-edit";
import PaymentTariffs from "@pages/payment/payment-tariffs";
import { Title } from "@components/common/common";
import { useStore } from "effector-react";

const Payment = () => {
  useEffect(() => {
    fetchTariffPeriods();
    // fetchCurrentTariff()
    fetchPaymentHistory({});
    fetchAllTariffs();
  }, []);

  const permissions = useStore($UserAddPermissions);

  const canManageTariffs =
    permissions.hasRequiredLevel(UserPriorityEnum.Owner) ||
    permissions.hasPermission(ACTIONS.payment_tariffs_allowedToChange);

  const TAB_ITEMS: { label: string; path: string; exact?: boolean }[] = [
    // { label: 'Продление тарифа', path: '/payment', exact: true },
    // { label: 'История оплат', path: '/payment/history' },
    { label: "Тариф", path: "/payment/tariffs" },
  ];
  if (canManageTariffs) {
    TAB_ITEMS.push({ label: "Изменить тарифы", path: "/payment/manage" });
  }

  return (
    <main className="content-container payment">
      <div className="content-section">
        <div className="top-content">
          <div>
            <BreadCrumb items={["Главная", "Оплата"]} />
            <Title text="Оплата" />
          </div>
        </div>
        {/* CONTENT */}
        <div className="tab-section">
          <div className="tab-cont">
            {/* TABS */}
            <TabItems items={TAB_ITEMS} />
            {/* <div>
                            <Search />
                        </div> */}
            {/* TAB CONTENTS */}
            <div className="tab-content-items">
              <Switch>
                {/* FIRST TAB CONTENT */}
                <Route exact path="/payment" component={PaymentContent} />
                {/* SECOND TAB CONTENT */}
                {/*<Route exact path='/payment/history' component={PaymentHistory} />*/}
                {/* THIRD TAB CONTENT */}
                <Route
                  exact
                  path="/payment/tariffs"
                  component={PaymentTariffs}
                />
                {canManageTariffs && (
                  <>
                    <Route
                      exact
                      path="/payment/tariffs/edit"
                      component={PaymentTariffEdit}
                    />
                    <Route
                      exact
                      path="/payment/manage"
                      component={PaymentTariffManage}
                    />
                  </>
                )}
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Payment;
