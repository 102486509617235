import { useSearch } from "@hooks/useSearch";
import { CompanyT } from "@interfaces/company/company";
import { useCompaniesQuery } from "@lib/company";
import { switchCompany } from "@store/company/company-store";
import { Search } from "@ui/search";
import { FC } from "react";
import { useHistory } from "react-router-dom";

export const CompanySearch: FC = () => {
  const history = useHistory();

  const search = useSearch();

  const { companies } = useCompaniesQuery({
    searchString: search.debounced,
  });

  const handleSelectCompany = (company: CompanyT) => {
    switchCompany({ id: company.id });
    history.push("/company");
  };

  return (
    <Search
      options={companies ?? []}
      getOptionLabel={(option) => option.name}
      onSelectOption={handleSelectCompany}
      value={search.value}
      onChange={search.onChange}
      placeholder="Поиск по ИНН и названию компании"
    />
  );
};
