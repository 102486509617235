import { FC } from "react";
import { Tooltip } from "@material-ui/core";
import { WorkspaceDirectoryT } from "@interfaces/company/workspace";
import {
  useArchiveWorkspaceDirectoryMutation,
  useDeleteWorkspaceDirectoryMutation,
} from "@lib/workspace";
import clsx from "clsx";
import style from "@scss/pages/company/company-workspace.module.scss";
import tableStyle from "@scss/components/tables/base-table.module.scss";
import { useStore } from "effector-react";
import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { WrapperSVG } from "@components/WrapperSVG/WrapperSVG";
import { Archive, NotePencil, X } from "@phosphor-icons/react";
import { useModal } from "@modals/index";
import { useSelectedCompanyQuery } from "@lib/company";
import { useAsyncConfirm } from "@hooks/useAsyncConfirm";
import { Link, useLocation } from "react-router-dom";
import QueryString from "qs";

export type WorkspaceDirectoryPropsT = {
  directory: Omit<WorkspaceDirectoryT, "companyId" | "groupId">;
  groupId: number;
  disabled?: boolean;
  isSelected: boolean;
  onSelect: (checked: boolean) => void;
};

export const WorkspaceDirectory: FC<WorkspaceDirectoryPropsT> = ({
  directory,
  groupId,
  disabled = false,
  isSelected,
  onSelect,
}) => {
  const permissions = useStore($UserAddPermissions);

  const { company } = useSelectedCompanyQuery();

  const location = useLocation();
  const modal = useModal();
  const confirm = useAsyncConfirm();

  const archiveMutation = useArchiveWorkspaceDirectoryMutation();
  const deleteMutation = useDeleteWorkspaceDirectoryMutation();

  const handleArchive = () => {
    if (!company) return;

    archiveMutation.mutate({
      companyId: company.id,
      groupId,
      directoryId: directory.id,
    });
  };

  const handleDelete = async () => {
    if (!company) return;

    const ok = await confirm({
      btnText: "Удалить",
      modalData: {
        text: `Вы уверены, что хотите удалить папку ${directory.name} и все содержимое?`,
      },
    });
    if (!ok) return;

    deleteMutation.mutate({
      companyId: company.id,
      groupId,
      directoryId: directory.id,
    });
  };

  const getLink = () => {
    const query = QueryString.stringify({
      group_id: groupId,
      folder_id: directory.id,
    });

    return `${location.pathname}?${query}`;
  };

  return (
    <tr>
      <td className={clsx(style.folder)}>
        <span
          className={clsx(
            tableStyle.column_fixed_height,
            tableStyle.checkboxLabel
          )}
        >
          <input
            type="checkbox"
            className={clsx(tableStyle.checkbox_item)}
            hidden
            checked={isSelected}
            onChange={(e) => onSelect(e.target.checked)}
            disabled={disabled}
            name=""
            id={directory.name}
          />
          <label htmlFor={directory.name}>
            <svg
              className="w-5 h-5"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </label>
          <div className={style.directoryIconWrapper}>
            <svg
              className={clsx(style.file_icon)}
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              style={{ minWidth: "32px" }}
            >
              <path
                d="M0 11.7761V30.3969C0 31.452 0.806906 32.259 1.86209 32.259H34.1379C35.1931 32.259 36 31.452 36 30.3969V11.7761C36 10.721 35.1931 9.91406 34.1379 9.91406H1.86209C0.806906 9.91406 0 10.7831 0 11.7761Z"
                fill="#F7B84E"
              />
              <path
                d="M32.8963 9.93103C32.8963 8.56549 31.779 7.44829 30.4135 7.44829H20.4825L17.379 3.72412H3.7239C2.66872 3.72412 1.86182 4.59311 1.86182 5.58621V9.93103H32.8963Z"
                fill="#E4A12F"
              />
            </svg>
            <Link
              to={getLink()}
              className={clsx(tableStyle.nameFile, style.workspaceFileName)}
            >
              {directory.name}
            </Link>
          </div>
        </span>
      </td>
      <td>
        <div className={clsx(style.table_buttons)}>
          {permissions.hasPermission(
            ACTIONS.companyWorkspace_directories_allowedToEdit
          ) && (
            <Tooltip title="Изменить" placement="top">
              <button
                disabled={disabled}
                onClick={() => {
                  modal.open("CreateFolderModal", {
                    btnText: "Сохранить",
                    modalData: {
                      modalTitle: "Изменить папку",
                      itemTitle: directory.name,
                      item: directory,
                      groupId,
                    },
                  });
                }}
              >
                <WrapperSVG color={"orange"}>
                  <NotePencil size={24} />
                </WrapperSVG>
              </button>
            </Tooltip>
          )}
          {permissions.hasPermission(
            ACTIONS.companyWorkspace_directories_allowedToArchive
          ) && (
            <Tooltip title="В архив" placement="top">
              <button disabled={disabled} onClick={handleArchive}>
                <WrapperSVG color="red">
                  <Archive size={24} />
                </WrapperSVG>
              </button>
            </Tooltip>
          )}
          {permissions.hasPermission(
            ACTIONS.companyWorkspace_directories_allowedToDelete
          ) && (
            <Tooltip title="Удалить" placement="top">
              <button disabled={disabled} onClick={handleDelete}>
                <X size={24} />
              </button>
            </Tooltip>
          )}
        </div>
      </td>
    </tr>
  );
};
