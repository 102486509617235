import { TariffEnum } from "@interfaces/company/company";
import { createEvent, createStore } from "effector";

import { SwitchCompanyResT } from "@interfaces/requests/company";
import { createEffect } from "effector/compat";
import { resetAllStates } from "@store/user-store";
import { useStore } from "effector-react";

export const setCompany = createEvent<number>();
export const resetCompany = createEvent();

export const $Company = createStore<number | null>(null)
  .on(setCompany, (state, newData) => newData)
  .reset(resetCompany);
export const switchCompany = createEffect<SwitchCompanyResT>(async ({ id }) => {
  resetAllStates();
  setCompany(id);
  localStorage.setItem("selectedCompany", String(id));
});

export const useCompanyId = () => useStore($Company);

export const GetTotalSpace = (tariff: number) => {
  switch (tariff) {
    case TariffEnum.Basic:
      return 5 * 1024 * 1024 * 1024;
    case TariffEnum.Extended:
      return 10 * 1024 * 1024 * 1024;
    case TariffEnum.Maximum:
      return 20 * 1024 * 1024 * 1024;
    case TariffEnum.Temp:
      return 20 * 1024 * 1024 * 1024;
    case TariffEnum.Demo:
      return 20 * 1024 * 1024 * 1024;
    default:
      return 0;
  }
};
