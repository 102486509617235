import Fetcher from "@http/fetcher";

import { ResCallback } from "@interfaces/common";
import { CompanyT, GetCompaniesParams } from "@interfaces/company/company";
import {
  CreateCompanyResT,
  UpdateCompanyResT,
} from "@interfaces/requests/company";

class CompanyService {
  static async getById(id: number, cb: ResCallback<CompanyT>) {
    try {
      const res = await Fetcher.get<CompanyT>(`/companies/${id}`);

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async create(data: FormData, cb: ResCallback<CreateCompanyResT>) {
    try {
      const res = await Fetcher.post<CreateCompanyResT>("/companies", data);

      if (res.status === 200 || res.status === 201) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async update(
    id: number,
    data: FormData | Partial<Omit<CompanyT, "id">>,
    cb: ResCallback<UpdateCompanyResT>
  ) {
    try {
      const res = await Fetcher.patch<UpdateCompanyResT>(
        `/companies/${id}`,
        data
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }
  static async changeTariff(id: number, data: any, cb: ResCallback<any>) {
    try {
      const res = await Fetcher.put<UpdateCompanyResT>(
        `/companies/tariff/${id}`,
        {
          companyId: id,
          ...data,
        }
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }
}

export const companyService = {
  async getAll(params?: GetCompaniesParams) {
    const resp = await Fetcher.get<CompanyT[]>("/companies", { params });
    return resp.data;
  },

  async getById(id: number) {
    const resp = await Fetcher.get<CompanyT>(`/companies/${id}`);
    return resp.data;
  },

  async deleteById(id: number) {
    const resp = await Fetcher.delete(`/companies/${id}`);
    return resp.data;
  },
};

export default CompanyService;
