import { useMemo } from "react";

export const useSearchByName = <TItem extends { name: string }>(
  search: string | undefined,
  array: TItem[] | undefined
) => {
  const filtered = useMemo(() => {
    if (search) {
      return array?.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    return array;
  }, [array, search]);

  return filtered;
};
