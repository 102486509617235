import { ResCallback } from "@interfaces/common";
import LegislationNewsService from "@services/legislation-news-service";
import JudicialPracticesService from "@services/judicial-practices-service";
import UsefulMaterialsService from "@services/useful-materials-service";
import DevelopmentNewsService from "@services/development-news.service";
import ProjectNewsService from "@services/project-news-service";

export type NewsActionsT =
  | "createNews"
  | "createPractice"
  | "createSpecHelp"
  | "editNews"
  | "editPractice"
  | "editSpecHelp";

export type ArticleType =
  | "LEGISLATION-NEWS"
  | "PRACTICE"
  | "HELP"
  | "DEVELOPMENT-NEWS"
  | "PROJECT-NEWS";

export function getServiceByType(
  type: ArticleType
):
  | typeof LegislationNewsService
  | typeof JudicialPracticesService
  | typeof UsefulMaterialsService
  | typeof DevelopmentNewsService
  | typeof ProjectNewsService {
  switch (type) {
    case "LEGISLATION-NEWS":
      return LegislationNewsService;
    case "PRACTICE":
      return JudicialPracticesService;
    case "HELP":
      return UsefulMaterialsService;
    case "DEVELOPMENT-NEWS":
      return DevelopmentNewsService;
    case "PROJECT-NEWS":
      return ProjectNewsService;
  }
}

export type NewsFileT = {
  filename: string;
  originalname: string;
  size: number;
};

export type LegislationNewsT = {
  _id: string;
  title: string;
  text: string;
  tags: string[];
  images: NewsFileT[];
  files: NewsFileT[];
  createdAt: string;
};

// REQUESTS
export type FetchingNewsPropsT = (params: {
  offset: number;
  limit: number;
  count?: boolean;
  cb?: ResCallback<{ count?: number; rows: LegislationNewsT[] }>;
}) => void;
