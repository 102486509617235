import { PositionParams } from "./usePositionFilesQuery";

export const positionQueryKeys = {
  all: ["positions"],
  files: (params: PositionParams) => [
    ...positionQueryKeys.all,
    "files",
    params,
  ],
};
