import React from "react";
import { BreadCrumb, Title } from "@components/common";

export function UserGuide() {
  return (
    <main className="content-container">
      <div className="content-section">
        <div className="top-content">
          <BreadCrumb items={["Главная", "Руководство пользователя"]} />
          <Title text="Руководство пользователя" />
        </div>
      </div>
    </main>
  );
}
