import { useMutation, useQueryClient } from "react-query";

import { WorkspaceDirectoryData } from "@interfaces/company/workspace";
import { documentTemplateService } from "@services/document-templates-service";
import { documentTemplatesQueryKeys } from "./queryKeys";

export const useCreateDocumentTemplatesDirectoryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: WorkspaceDirectoryData) =>
      documentTemplateService.createDirectory(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(documentTemplatesQueryKeys.all);
      },
    }
  );
};
