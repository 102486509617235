import { fetcherV2 } from "@http/fetcher";
import { PaginationParams } from "@interfaces/pagination";
import {
  GetWorkplanGroupsParams,
  GetWorkplanTasksParams,
  WorkplanGroupT,
  WorkplanTaskT,
} from "@interfaces/workplan";

export const WorkplanService = {
  async getGroups(params: GetWorkplanGroupsParams) {
    const resp = await fetcherV2.get<WorkplanGroupT[]>(
      "/companies/work-plan/groups/",
      {
        params,
      }
    );
    return resp.data;
  },

  async getTasks(params: GetWorkplanTasksParams & PaginationParams) {
    const resp = await fetcherV2.get<WorkplanTaskT[]>(
      "/companies/work-plan/tasks/",
      {
        params,
      }
    );
    return resp.data;
  },
};
