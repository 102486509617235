import { FC } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "@ui/modal";
import {
  CreateMedicalExaminationFactorData,
  MedicalExaminationCategoryT,
} from "@interfaces/medical-examination";
import { BodyBold } from "@ui/fonts";
import styles from "./create-medical-examination-factor.module.scss";
import { useCreateMedicalExaminationFactorMutation } from "@lib/medical-examination";
import { TextField } from "@material-ui/core";
import { Button } from "@ui/button";

export type CreateMedicalExaminationFactorModalProps = {
  isOpen: boolean;
  category: MedicalExaminationCategoryT;
  onClose: () => void;
};

export const CreateMedicalExaminationFactorModal: FC<
  CreateMedicalExaminationFactorModalProps
> = ({ isOpen, category, onClose }) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<CreateMedicalExaminationFactorData>({
    defaultValues: {
      categoryId: category.change?.id ?? category.id,
    },
  });

  const createFactorMutation = useCreateMedicalExaminationFactorMutation();

  const categoryTitle = category.change?.title ?? category.title;

  const handleCloseModal = () => {
    reset();
    onClose();
  };

  const onSubmit = handleSubmit((data) => {
    createFactorMutation.mutate(data, {
      onSuccess: () => {
        handleCloseModal();
      },
    });
  });

  return (
    <Modal open={isOpen} className={styles.modal} onClose={handleCloseModal}>
      <BodyBold weight="bold">{categoryTitle}</BodyBold>
      <form className={styles.modal__form} onSubmit={onSubmit}>
        <TextField
          type="text"
          placeholder="Название"
          multiline
          rows={4}
          error={!!errors.title}
          helperText={errors.title?.message}
          className={styles.modal__input}
          {...register("title", {
            required: "Обязательное поле",
          })}
        />
        <TextField
          type="number"
          inputMode="numeric"
          error={!!errors.inspectionFrequency}
          helperText={errors.inspectionFrequency?.message}
          className={styles.modal__input}
          placeholder="Периодичность"
          {...register("inspectionFrequency", {
            required: "Обязательное поле",
            pattern: /[0-9]*/,
          })}
        />
        <TextField
          type="text"
          placeholder="Участие врачей-специалистов"
          error={!!errors.medicalSpecialists}
          helperText={errors.medicalSpecialists?.message}
          className={styles.modal__input}
          {...register("medicalSpecialists", {
            required: "Обязательное поле",
          })}
        />
        <TextField
          type="text"
          placeholder="Лабораторные и функциональные исследования"
          error={!!errors.laboratoryFunctionalStudies}
          helperText={errors.laboratoryFunctionalStudies?.message}
          className={styles.modal__input}
          {...register("laboratoryFunctionalStudies", {
            required: "Обязательное поле",
          })}
        />
        <div className={styles.modal__actions}>
          <Button type="button" color="white-green" borderColor="green">
            Отменить
          </Button>
          <Button type="submit">Добавить</Button>
        </div>
      </form>
    </Modal>
  );
};
