import { $UserAddPermissions, ACTIONS } from "@store/user-store";
import { useEffect, useState } from "react";

import { BodyNormal } from "@ui/fonts";
import { Button } from "@ui/button";
import { CircleInfoSVG } from "@components/svgs";
import { SendTrainingRequestModal } from "../send-training-request-modal";
import { SingleTrainingRequest } from "@interfaces/request-training";
import { handleApiErrorLocally } from "@utils/handleApiError";
import styles from "./footer-edit-request.module.scss";
import { useHistory } from "react-router-dom";
import { useStore } from "effector-react";
import { useTrainingRequestStatusActionMutation } from "@lib/request-training";

type FooterEditRequestPropsT = {
  training: SingleTrainingRequest | undefined;
};

export const FooterEditRequest: React.FC<FooterEditRequestPropsT> = ({
  training,
}) => {
  const permissions = useStore($UserAddPermissions);
  const trainingRequestStatusActionMutation =
    useTrainingRequestStatusActionMutation();
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState<string>();

  const isTrainingApproved = training?.status === "Утверждена";
  const isTrainingCanceled = training?.status === "Отменена";

  useEffect(() => {
    if (!error) return;

    window.setTimeout(() => setError(undefined), 2000);
  }, [error]);

  const handleApprove = () => {
    if (!training) return;

    trainingRequestStatusActionMutation.mutate(
      {
        id: training.id,
        action: "approve",
      },
      {
        onError: (error) => {
          handleApiErrorLocally(error, setError);
        },
      }
    );
  };

  const handleCancel = () => {
    if (!training) return;

    trainingRequestStatusActionMutation.mutate(
      {
        id: training.id,
        action: "cancel",
      },
      {
        onError: (error) => {
          handleApiErrorLocally(error, setError);
        },
      }
    );
  };

  return (
    <>
      <div className={styles.contain}>
        {training && (
          <SendTrainingRequestModal
            open={isModalOpen}
            training={training}
            onClose={() => setIsModalOpen(false)}
          />
        )}

        <div>
          <CircleInfoSVG />
        </div>
        <div className={styles.text__info}>
          <div className={styles.text__path}>
            <BodyNormal weight={"light"} color={"gray-darker2"}>
              Заявка составляется на фирменном бланке организации, подписывается
              Генеральным директором (или иным уполномоченным лицом, на
              основании доверенности), заверяется печатью организации.
            </BodyNormal>
          </div>
          <div className={styles.text__path}>
            <BodyNormal weight={"light"} color={"gray-darker2"}>
              К заявке необходимо приложить реквизиты организации в формате
              word.
            </BodyNormal>
          </div>
        </div>
      </div>
      <div className={styles.buttons}>
        {permissions.hasPermission(
          ACTIONS.companyApplicationsForTraining_allowedToEdit
        ) && (
          <Button
            color="white"
            border={true}
            borderColor={"green"}
            onClick={history.goBack}
          >
            Сохранить
          </Button>
        )}
        {permissions.hasPermission(
          ACTIONS.companyApplicationsForTraining_allowedToEdit
        ) &&
          !isTrainingCanceled && (
            <Button disabled={isTrainingApproved} onClick={handleApprove}>
              Утвердить
            </Button>
          )}
        {permissions.hasPermission(
          ACTIONS.companyApplicationsForTraining_allowedToEdit
        ) && (
          <Button
            disabled={!isTrainingApproved}
            onClick={() => setIsModalOpen(true)}
          >
            Отправить
          </Button>
        )}
        {permissions.hasPermission(
          ACTIONS.companyApplicationsForTraining_allowedToEdit
        ) && (
          <Button
            color="white"
            border={false}
            borderColor={"white"}
            onClick={handleCancel}
          >
            <BodyNormal color={"green"} weight={"bold"}>
              Отменить
            </BodyNormal>
          </Button>
        )}
      </div>
      {error && (
        <BodyNormal color="red" sx={{ marginTop: "12px" }}>
          {error}
        </BodyNormal>
      )}
    </>
  );
};
