import { FeedbackT } from "@interfaces/feedback";
import Fetcher from "@http/fetcher";
import {
  GetAllFeedbackT,
  GetIdFeedbackT,
  ParamsGetAllFeedbackT,
} from "@interfaces/technical-support";

class TechnicalSupportService {
  static async getAll(params: ParamsGetAllFeedbackT) {
    return await Fetcher.get<GetAllFeedbackT>("/technical-support", { params });
  }

  static async getById(id: string) {
    const res = await Fetcher.get<GetIdFeedbackT>(`/technical-support/${id}`);
    return res?.data;
  }

  static async create(formData: FormData) {
    return await Fetcher.post<FeedbackT>("/technical-support", formData);
  }

  static async createMessage(id: string, formData: FormData) {
    const res = await Fetcher.post<any>(
      `/technical-support/${id}/messages`,
      formData
    );
    return res?.data;
  }

  static async closeMessage(id: string) {
    const res = await Fetcher.patch<any>(`/technical-support/${id}/close`);
    return res?.data;
  }

  static async reopenMessage(id: string) {
    const res = await Fetcher.patch<any>(`/technical-support/${id}/reopen`);
    return res?.data;
  }
}

export default TechnicalSupportService;
