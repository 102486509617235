import {
  ProcedureInstructionQueryParams,
  ProcedureTrainingQueryParams,
} from "@interfaces/company/procedure";

export const proceduresQueryKeys = {
  all: ["procedures"],
  allTraining: () => [...proceduresQueryKeys.all, "training"],
  allTrainingProcedures: (params?: ProcedureTrainingQueryParams) => [
    ...proceduresQueryKeys.allTraining(),
    params,
  ],
  allBriefing: () => [...proceduresQueryKeys.all, "briefing"],
  allBriefingProcedures: (params?: ProcedureInstructionQueryParams) => [
    ...proceduresQueryKeys.allBriefing(),
    params,
  ],
};
