import { FC } from "react";
import cx from "clsx";

import Caution from "@assets/images/caution.png";

import style from "./error.module.scss";

type ErrorIndicatorPropsT = {
  message?: string;
};

const ErrorIndicator: FC<ErrorIndicatorPropsT> = ({
  message = "Произошла неожиданная ошибка",
}) => {
  return (
    <div className={cx(style.error_container)}>
      <div className={style.error_inner}>
        <img src={Caution} alt="" className={cx(style.error_icon)} />
        <p className={cx(style.error_text)}>{message}</p>
      </div>
    </div>
  );
};

export default ErrorIndicator;
