import { $UserAddPermissions, UserRoleEnum } from "@store/user-store";

import { Button } from "@ui/button";
import SliderWithNav from "../../ui/sliders/slider-with-nav";
import { TypographyStylesProvider } from "@mantine/core";
import clsx from "clsx";
import moment from "moment";
import styles from "./text-page-component.module.scss";
import { useStore } from "effector-react";
import { NewsFileT } from "@interfaces/legislation-news";

type TextPageComponentPropsT = {
  handlerOpenDownFile?: () => void;
  id?: string;
  title?: string;
  text?: string;
  tags?: string[];
  images?: string[];
  files?: NewsFileT[];
  createdAt?: string;
};

export const TextPageComponent: React.FC<TextPageComponentPropsT> = ({
  id,
  title,
  text,
  tags,
  images,
  files,
  createdAt,
  handlerOpenDownFile,
}) => {
  const permissions = useStore($UserAddPermissions);

  return (
    <div className={clsx(styles.TextPage)}>
      {!!images ? (
        <div className={clsx(styles.WrapperImage)}>
          <SliderWithNav key={id} images={images} />
        </div>
      ) : null}
      <div className={clsx(styles.WrapperText)}>
        <p className={clsx(styles.Title)}>{title}</p>
        <p className={clsx(styles.Time)}>{moment(createdAt).format("LLL")}</p>
        {permissions.roleIsIn([UserRoleEnum.Client]) ? null : (
          <div className={clsx(styles.Tag)}>
            {tags?.map((tagText, idx) => (
              <p key={idx} className={clsx(styles.TagItem)}>
                #{tagText.trim()}{" "}
              </p>
            ))}
          </div>
        )}
        {!!text && (
          <TypographyStylesProvider>
            <div
              className={clsx(styles.Text)}
              dangerouslySetInnerHTML={{
                __html: text ?? "",
              }}
            />
          </TypographyStylesProvider>
        )}
        {!!files && files.length ? (
          <Button onClick={handlerOpenDownFile} className={styles.onloadFile}>
            Скачать прикреплённые материалы
          </Button>
        ) : null}
      </div>
    </div>
  );
};
