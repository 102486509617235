export const CheckboxMinusSVG = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="20" height="20" rx="2" fill="#EFF1F8" />
      <rect x="3" y="9" width="14" height="2" rx="1" fill="#373737" />
    </svg>
  );
};
