import {
  $ProcedureTraining,
  FetchAllCategoriesForTrainingPrograms,
} from "@store/company/procedure-training-store";
import React, { useEffect, useState } from "react";

import { BodyNormal } from "@ui/fonts";
import { CategoryTable } from "./category-table";
import { ExternalLinkSVG } from "@components/svgs/external-link";
import { GroupSwitch } from "@components/group-switch";
import styles from "./study-center.module.scss";
import { useStore } from "effector-react";
import { useStudyCenterProgramsQuery } from "@lib/study-center";

const ALL_CATEGORIES_ID = "99999";

export const StudyCenter: React.FC = () => {
  const { categories } = useStore($ProcedureTraining);
  const categoriesList = (categories ?? []).map((category) => ({
    value: category.id + "",
    label: category.title,
  }));

  const [category, setCategory] = useState(ALL_CATEGORIES_ID);

  const { programs } = useStudyCenterProgramsQuery({
    categories:
      typeof category !== "undefined" && category !== ALL_CATEGORIES_ID
        ? `${category}`
        : undefined,
  });

  useEffect(() => {
    FetchAllCategoriesForTrainingPrograms({ isIncludeChanges: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.StudyCenter}>
      <div className={styles.StudyCenter__filters}>
        <GroupSwitch
          data={categoriesList}
          value={category ?? ""}
          onChange={setCategory}
        />
        <a
          href="https://ucrbp.ru/?ysclid=lc07l6j1b802514322"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.StudyCenter__rbpLink}
        >
          <BodyNormal weight="bold">
            Учебный Центр ДПО переподготовки и повышения квалификации
          </BodyNormal>
          <ExternalLinkSVG />
        </a>
      </div>
      <div className={styles.StudyCenter__categories}>
        {programs?.map((program) => (
          <CategoryTable key={program.categoryId} category={program} />
        ))}
      </div>
    </div>
  );
};
