import { ApiData, SortOptionT } from "@interfaces/common";

// PAYMENT HISTORY
export type PaymentHistoryT = {
  id: number;
  name: string;
  term: number;
  date_start: string;
  income_amount: string;
};

export interface PaymentHistoryDataT extends ApiData {
  data: PaymentHistoryT[];
}

export type PaymentHistoryPropsType = {
  list: PaymentHistoryT[] | null | false;
  onOptionSelect: (option: SortOptionT) => void;
  onSearch: (value: string) => void;
};

// PURCHASING TARIFF
export type CurrentTariffT = {
  is_serve: boolean;
  tariff: {
    id: number;
    title: string;
    date_start: string;
    date_end: string;
    month_limit: number;
  };
};

export type FetchingCurrentTariffDataT = {
  success: boolean;
  data: CurrentTariffT;
};

export type TariffPeriodT = {
  period: number;
  price: number;
};

export type TariffPeriodPropsT = {
  items: Array<{
    period: number;
    price: number;
  }>;
  current: number | null;
  onClick: (idx: number) => void;
  setPrice: (price: number) => void;
};

export type TariffT = {
  id: number;
  space: number; // Kb
  title: string;
  price: number;
  modules: {
    id: number;
    name: string;
    includes: boolean;
  }[];
  fillColor: string;
  strokeColor: string;
  studyCenter: boolean;
  notification: boolean;
  instruction: boolean;
  organizer: boolean;
  news: boolean;
  responsibility: boolean;
  arbitragePractice: boolean;
};

export type ChoosingTariffTableT = {
  items: TariffT[] | null;
};

export const TariffVariants = [1001, 1002, 1003, 1004];

export type FetchingPaymentHistoryPropsT = {
  search?: string;
  option?: string;
};

export type FetchingPaymentHistoryUrlParamsT = {
  q?: string;
  sort?: string;
  sort_field?: string;
};

export const DEFAULT_TARIFFS = [
  {
    id: 1,
    title: "Базовый",
    space: 5000000,
    price: 10000,
    fillColor: "#f5feff",
    strokeColor: "#59D1F8",
    modules: [
      {
        id: 1,
        name: "Пакет документов по ОТ, ПБ, ЭБ",
        includes: true,
      },
      {
        id: 2,
        name: "Календарь",
        includes: true,
      },
      {
        id: 3,
        name: "Новости законодательства",
        includes: true,
      },
      {
        id: 4,
        name: "Юридическая информация",
        includes: true,
      },
      {
        id: 5,
        name: "Учебный центр",
        includes: true,
      },
      {
        id: 6,
        name: "Предписания",
        includes: true,
      },
      {
        id: 7,
        name: "Оповещения",
        includes: true,
      },
      {
        id: 8,
        name: "Ответственность",
        includes: true,
      },
      {
        id: 9,
        name: "Судебная практика ",
        includes: true,
      },
      {
        id: 10,
        name: "Выбор и продление тарифа онлайн",
        includes: true,
      },
      {
        id: 11,
        name: "Техническая поддержка",
        includes: true,
      },
      {
        id: 12,
        name: "Архив (объём - 500 Мб)",
        includes: true,
      },
      {
        id: 13,
        name: "Корзина (срок хранения - 20 дней)",
        includes: true,
      },
      {
        id: 14,
        name: "Полезные материалы",
        includes: false,
      },
      {
        id: 15,
        name: "План специалиста",
        includes: false,
      },
      {
        id: 16,
        name: "Вопрос специалисту",
        includes: false,
      },
    ],
  },
  {
    id: 2,
    title: "Расширенный",
    space: 10000000,
    price: 20000,
    fillColor: "#eefff8",
    strokeColor: "#00B856",
    modules: [
      {
        id: 1,
        name: "Пакет документов по ОТ, ПБ, ЭБ",
        includes: true,
      },
      {
        id: 2,
        name: "Календарь",
        includes: true,
      },
      {
        id: 3,
        name: "Новости законодательства",
        includes: true,
      },
      {
        id: 4,
        name: "Юридическая информация",
        includes: true,
      },
      {
        id: 5,
        name: "Учебный центр",
        includes: true,
      },
      {
        id: 6,
        name: "Предписания",
        includes: true,
      },
      {
        id: 7,
        name: "Оповещения",
        includes: true,
      },
      {
        id: 8,
        name: "Ответственность",
        includes: true,
      },
      {
        id: 9,
        name: "Судебная практика ",
        includes: true,
      },
      {
        id: 10,
        name: "Выбор и продление тарифа онлайн",
        includes: true,
      },
      {
        id: 11,
        name: "Техническая поддержка",
        includes: true,
      },
      {
        id: 12,
        name: "Архив (объём - 500 Мб)",
        includes: true,
      },
      {
        id: 13,
        name: "Корзина (срок хранения - 20 дней)",
        includes: true,
      },
      {
        id: 14,
        name: "Полезные материалы",
        includes: true,
      },
      {
        id: 15,
        name: "План специалиста",
        includes: true,
      },
      {
        id: 16,
        name: "Вопрос специалисту",
        includes: false,
      },
    ],
  },
  {
    id: 3,
    title: "Максимальный",
    space: 20000000,
    price: 30000,
    fillColor: "#faf8fc",
    strokeColor: "#8250BE",
    modules: [
      {
        id: 1,
        name: "Пакет документов по ОТ, ПБ, ЭБ",
        includes: true,
      },
      {
        id: 2,
        name: "Календарь",
        includes: true,
      },
      {
        id: 3,
        name: "Новости законодательства",
        includes: true,
      },
      {
        id: 4,
        name: "Юридическая информация",
        includes: true,
      },
      {
        id: 5,
        name: "Учебный центр",
        includes: true,
      },
      {
        id: 6,
        name: "Предписания",
        includes: true,
      },
      {
        id: 7,
        name: "Оповещения",
        includes: true,
      },
      {
        id: 8,
        name: "Ответственность",
        includes: true,
      },
      {
        id: 9,
        name: "Судебная практика ",
        includes: true,
      },
      {
        id: 10,
        name: "Выбор и продление тарифа онлайн",
        includes: true,
      },
      {
        id: 11,
        name: "Техническая поддержка",
        includes: true,
      },
      {
        id: 12,
        name: "Архив (объём - 500 Мб)",
        includes: true,
      },
      {
        id: 13,
        name: "Корзина (срок хранения - 20 дней)",
        includes: true,
      },
      {
        id: 14,
        name: "Полезные материалы",
        includes: true,
      },
      {
        id: 15,
        name: "План специалиста",
        includes: true,
      },
      {
        id: 16,
        name: "Вопрос специалисту",
        includes: true,
      },
    ],
  },
];
