import DepartmentsGetAllService, {
  DepartmentsGetAllT,
  GetAllDepartmentsParams,
} from "@services/departments-get-all.service";

import { useQuery } from "react-query";

const uniqueDepartments = (data: DepartmentsGetAllT) => {
  const filteredData = data.reduce((accumulator, current) => {
    const existingObject = accumulator.find(
      (item) => item.title === current.title
    );
    if (!existingObject) {
      accumulator.push(current);
    }

    return accumulator;
  }, [] as DepartmentsGetAllT);

  return filteredData;
};

export const useDepartmentsGetAllQuery = (
  params?: GetAllDepartmentsParams,
  onQuery?: boolean
) => {
  const { data } = useQuery(
    ["departments-get-all", params, onQuery],
    async () => {
      return await DepartmentsGetAllService.getAll(params);
    },
    { enabled: !!onQuery }
  );

  const filteredData = data ? uniqueDepartments(data) : [];
  return { data: filteredData };
};
