import { CompanyTabPropsT } from "@interfaces/company/company";

import { SinglePosition } from "@feature";
import { useRouteMatch } from "react-router-dom";
import { useSelectedCompanyQuery } from "@lib/company";

const SinglePositionPage: React.FC<CompanyTabPropsT> = () => {
  const { company } = useSelectedCompanyQuery();

  const query = useRouteMatch<{ id: string }>();
  const url = query.url.split("/");

  if (!company) {
    return null;
  }

  return (
    <SinglePosition
      type={url[2]}
      departmentId={+url[3]}
      positionId={+url[4]}
      companyId={company?.id}
    />
  );
};

export default SinglePositionPage;
