import { ProcedureInstruction } from "@interfaces/company/procedure";
import { Box, Checkbox, Text } from "@mantine/core";
import React from "react";

interface EditInstructionsProgramDepartmentProps {
  item: ProcedureInstruction;
  setInstructions: React.Dispatch<React.SetStateAction<number[]>>;
  instructionsValue: number[];
}

const EditInstructionsProgramDepartment: React.FC<
  EditInstructionsProgramDepartmentProps
> = ({ item, instructionsValue, setInstructions }) => {
  const isChecked = instructionsValue.includes(item.id);

  const handleChangeCheckbox = () => {
    if (isChecked) {
      return setInstructions((prev) =>
        prev.filter((instructions) => instructions !== item.id)
      );
    }
    return setInstructions((prev) => [...prev, item.id]);
  };

  return (
    <Box
      sx={{
        borderBottom: "1px solid white",
        padding: "5px 42px",
        minHeight: 62,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Checkbox
        checked={instructionsValue.includes(item.id)}
        onChange={handleChangeCheckbox}
        styles={{
          body: { borderColor: "#00B856", alignItems: "center" },
          input: {
            borderColor: "#00B856",
            "&:checked": {
              backgroundColor: "#00B856",
              borderColor: "#00B856",
            },
          },
        }}
        mr={11}
        label={<Text>{item.title}</Text>}
      />
    </Box>
  );
};

export default EditInstructionsProgramDepartment;
