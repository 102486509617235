import { ArticleType, getServiceByType } from "@interfaces/legislation-news";
import { useMutation, useQueryClient } from "react-query";
import { invalidateNewsQuery } from "./useNewsQuery";

export const useDownloadNewsMutation = (type: ArticleType) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (filename: string) => {
      return new Promise<Blob>((resolve, reject) => {
        const service = getServiceByType(type);
        service.download(filename, (err, res) => {
          if (err || !res) {
            reject(err);
          } else {
            resolve(res.data);
          }
        });
      });
    },
    {
      onSuccess: () => {
        invalidateNewsQuery(queryClient, type);
      },
    }
  );
};
