import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { useModal } from "@modals/index";
import { Button } from "@ui/button";
import { BodyNormal, BodyBold, BodySmall } from "@ui/fonts";
import { Modal } from "@ui/modal";
import styles from "./send-request-modal.module.scss";
import { useCreateStudyCenterRequestMutation } from "@lib/study-center";
import { ProcedureProgram } from "@interfaces/company/procedure";
import { useCompaniesQuery } from "@lib/company";
import { getCompanyLogo } from "@utils/getCompanyLogo";

export type SendRequestModalProps = {
  open: boolean;
  programs: ProcedureProgram[];
  onClose: () => void;
};

export const SendRequestModal: React.FC<SendRequestModalProps> = ({
  open,
  programs,
  onClose,
}) => {
  const { companies } = useCompaniesQuery();

  const history = useHistory();
  const modal = useModal();

  const createRequestMutation = useCreateStudyCenterRequestMutation();

  const [companyId, setCompanyId] = useState<number | undefined>(
    companies?.length === 1 ? companies?.[0].id : undefined
  );

  const handleCloseModal = () => {
    setCompanyId(undefined);
    onClose();
  };

  const handleContinue = () => {
    if (typeof companyId === "undefined") {
      // здесь можно показывать ошибку пользователю или ещё что-нибудь
      return;
    }

    createRequestMutation.mutate(
      { companyId, programs: programs.map((program) => program.id) },
      {
        onSuccess: async (data) => {
          handleCloseModal();
          modal.open("NotificationModal", {
            btnText: "Перейти в заявку",
            modalData: {
              modalTitle: "Заявка на обучение сформирована!",
              text: "Для добавления сотрудников по выбранным программам обучения перейдите в заявку на обучение.",
              removeIcon: true,
              btnText: "Перейти в заявку",
              style: {
                maxWidth: "500px",
              },
            },
            onConfirm: () => {
              modal.close();

              history.push(`/control-request-training/${data.id}`);
            },
          });
        },
      }
    );
  };

  return (
    <Modal open={open} onClose={handleCloseModal}>
      <div className={styles.modal__top}>
        <BodyBold weight="bold">
          Выберите компанию для формирования заявки на обучение
        </BodyBold>
      </div>
      <div className={styles.modal__companies}>
        {companies?.map((company, i) => (
          <div
            key={i}
            className={clsx(styles.Company, {
              [styles.Company__selected]: companyId === company.id,
            })}
            tabIndex={0}
            onClick={() => setCompanyId(company.id)}
          >
            <img
              src={getCompanyLogo(company.image)}
              alt={company.name}
              className={styles.Company__logo}
            />
            <BodyNormal weight="bold" className={styles.Company__name}>
              {company.name}
            </BodyNormal>
            <BodySmall color="dark">ИНН {company.inn}</BodySmall>
          </div>
        ))}
      </div>
      <Button className={styles.modal__continueBtn} onClick={handleContinue}>
        Далее
      </Button>
    </Modal>
  );
};
