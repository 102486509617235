import { EmployeeListDataT } from "@interfaces/company/employees";
import EmployeeService from "@services/employee-service";
import { useQuery } from "react-query";
import { employeesQueryKeys } from "./queryKeys";

export const useEmployeeListQuery = () => {
  return useQuery(employeesQueryKeys.all, async () => {
    return new Promise<EmployeeListDataT[]>((resolve, reject) => {
      EmployeeService.getAll(false, (err, res) => {
        if (err || !res) {
          reject(err);
        } else {
          resolve(res.data);
        }
      });
    });
  });
};
