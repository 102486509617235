import fetcher from "@http/fetcher";
import {
  UpdatePasswordPropsT,
  UpdateUserDataPropsT,
} from "@interfaces/requests/auth";
import { UserDataT } from "@interfaces/user";

export const userService = {
  async getById(id: number) {
    const resp = await fetcher.get<UserDataT>(`/users/${id}`);
    return resp.data;
  },

  async update(data: UpdateUserDataPropsT) {
    const resp = await fetcher.patch<string>("/users/data/update", data);
    return resp.data;
  },

  async changePassword(data: UpdatePasswordPropsT) {
    const resp = await fetcher.patch<string>("/users/password/update", data);
    return resp.data;
  },

  async changeAvatar(data: FormData) {
    const resp = await fetcher.patch<string>("/users/avatar", data);
    return resp.data;
  },
};
