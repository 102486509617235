export const ArrowBigGreen = () => {
  return (
    <svg
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.171875 8.94922C0.171875 9.34961 0.318359 9.68164 0.640625 9.99414L8.16016 17.3477C8.4043 17.6016 8.7168 17.7285 9.07812 17.7285C9.81055 17.7285 10.3965 17.1426 10.3965 16.4102C10.3965 16.0391 10.25 15.7168 9.98633 15.4531L3.30664 8.93945L9.98633 2.44531C10.25 2.18164 10.3965 1.84961 10.3965 1.48828C10.3965 0.755859 9.81055 0.179688 9.07812 0.179688C8.70703 0.179688 8.4043 0.296875 8.16016 0.550781L0.640625 7.9043C0.318359 8.2168 0.181641 8.54883 0.171875 8.94922Z"
        fill="#00B856"
      />
    </svg>
  );
};
