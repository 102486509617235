import { documentTemplateService } from "@services/document-templates-service";
import { useQuery } from "react-query";
import { documentTemplatesQueryKeys } from "./queryKeys";

export const useDocumentTemplatesFilesQuery = (
  groupId: number,
  directoryId: number
) => {
  const { data: files, ...stuff } = useQuery(
    documentTemplatesQueryKeys.directoriesFiles(groupId, directoryId),
    () => documentTemplateService.getDirectoriesAndFiles(groupId, directoryId)
  );

  return {
    files,
    ...stuff,
  };
};
