import { PositionCompanyDataT } from "@interfaces/company/departments";
import PositionsService from "@services/positions-service";
import { departmentQueryKeys } from "./queryKeys";
import { useQuery } from "react-query";

export const useCompanyPositions = () => {
  return useQuery(departmentQueryKeys.all, async () => {
    return new Promise<PositionCompanyDataT[]>((resolve, reject) => {
      PositionsService.getCompanyPositions((err, res) => {
        if (err || !res) {
          reject(err);
        } else {
          resolve(res.data);
        }
      });
    });
  });
};
