import { createEffect, createEvent, createStore } from "effector";
// STORE
import {
  serUserStates,
  setUserData,
  setUserError,
  setUserLoading,
  setUserAddPermissions,
} from "@store/user-store";
import { setCompany } from "@store/company/company-store";

import AuthService from "@services/auth-service";

import {
  AuthStoreLoginPropsT,
  AuthStoreSignupPropsT,
} from "@interfaces/store/auth-store-types";
import { companyService } from "@services/company-service";
import { parseJWT } from "@utils/parseJWT";
import { userService } from "@services/user-service";
import { useStore } from "effector-react";

// AUTH STORE
export const setAuth = createEvent<boolean>();

export const $Auth = createStore<boolean | null>(
  null
) /* MUST BE NULL FOR INITIAL STATE */
  .on(setAuth, (_, newState) => newState);

export const useAuth = () => {
  return useStore($Auth);
};

// REQUESTS
export const checkAuth = createEffect(async () => {
  const accessToken = localStorage.getItem("token");
  if (!accessToken) {
    setAuth(false);
    return;
  }

  setUserLoading(true);

  try {
    const companies = await companyService.getAll();
    let selectedCompany = companies[0];
    const selectedCompanyId = localStorage.getItem("selectedCompany");
    if (selectedCompanyId) {
      try {
        const company = companies.find(
          (company) => company.id === parseInt(selectedCompanyId)
        );
        if (company) {
          selectedCompany = company;
        }
      } catch (err) {
        localStorage.removeItem("selectedCompany");
      }
    }

    const data = parseJWT(accessToken);
    const user = await userService.getById(data.userId);

    let doesTariffExpire = false;

    setCompany(selectedCompany.id);
    setUserData({
      ...user,
      doesTariffExpire,
    });
    setUserAddPermissions({
      id: user.id,
      roleId: user.roleId,
      modules: user.additionalPermissions,
      priority: user.role.priority,
      permissions: user.role.permissions.map(
        (permission) => permission.action_name
      ),
    });

    serUserStates({ error: false, isLoading: false, isFetched: true });
    setAuth(true);
  } catch (err) {
    localStorage.removeItem("token");
    setAuth(false);
    setUserLoading(false);
  }
});

export const login = createEffect<AuthStoreLoginPropsT, void>(async (data) => {
  const { cb } = data;
  setUserLoading(true);

  await AuthService.login(
    { email: data.email, password: data.password },
    async (err, res) => {
      if (err || res?.status !== 200) {
        cb(err);
        setUserError(true);
        return setAuth(false);
      }
      localStorage.setItem("token", res.data);

      const companies = await companyService.getAll();

      let selectedCompany = companies[0];
      const selectedCompanyId = localStorage.getItem("selectedCompany");
      if (selectedCompanyId) {
        try {
          const company = companies.find(
            (company) => company.id === parseInt(selectedCompanyId)
          );
          if (company) {
            selectedCompany = company;
          } else {
            selectedCompany = companies[0];
          }
        } catch (err) {
          localStorage.removeItem("selectedCompany");
        }
      }

      setCompany(selectedCompany.id);

      const id = parseJWT(res.data).userId;
      const user = await userService.getById(id);

      let doesTariffExpire = false;
      setUserData({ ...user, doesTariffExpire });
      setUserAddPermissions({
        id: user.id,
        roleId: user.roleId ? user.roleId : 0,
        priority: user.role ? user.role.priority : 4,
        modules: user.additionalPermissions,
        permissions: user.role.permissions.map(
          (permission) => permission.action_name
        ),
      });

      serUserStates({ error: false, isLoading: false, isFetched: true });
      setAuth(true);
      cb(null);
    }
  );
});

export const signup = createEffect<AuthStoreSignupPropsT, void>(
  async (data) => {
    const { cb } = data;

    await AuthService.signup(
      {
        email: data.email,
        name: data.name,
        password: data.password,
        passwordConfirm: data.passwordConfirm,
      },
      (err, res) => {
        if (err || res?.status !== 200) {
          cb(err);
          return setAuth(false);
        }
        cb(null);
      }
    );
  }
);
