import {
  SingleTrainingRequest,
  TrainingRequest,
} from "@interfaces/request-training";

export const isTrainingRequestEditable = (
  training: TrainingRequest | SingleTrainingRequest | undefined
): boolean => {
  return training?.status === "Создана";
};
