import { switchCompany } from "@store/company/company-store";
import { $UserAddPermissions, UserRoleEnum } from "@store/user-store";
import {
  fetchSpecialistPlans,
  setPlansError,
  setPlansLoading,
} from "@store/specialist-plan-store";
import { FC, useEffect, useState } from "react";

import { CompanyShortDataT } from "@interfaces/company/company";
import { FetchWarning } from "@store/warning-store";
import { Search } from "@ui/search";
import clsx from "clsx";
import { getTextExcerpt } from "@utils/common-utils";
import style from "@scss/pages/specialist-plan.module.scss";
import { useHistory } from "react-router-dom";
import { useStore } from "effector-react";
import { useCompaniesQuery, useSelectedCompanyQuery } from "@lib/company";
import { useSearch } from "@hooks/useSearch";
import { getCompanyLogo } from "@utils/getCompanyLogo";

const itemsPerPage = 10;

const Companies = () => {
  const history = useHistory();

  const search = useSearch();

  const { company } = useSelectedCompanyQuery();
  const { companies } = useCompaniesQuery({
    searchString: search.debounced,
  });

  const [selectedCompanyId, setSelectedCompanyId] = useState(company?.id);

  useEffect(() => {
    if (!company) return;
    FetchWarning(company.id);
  }, [company]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [offset, setOffset] = useState<number>(0);

  const handleCompanyChanging = (companyId: number) => {
    if (companyId !== selectedCompanyId) {
      switchCompany({ id: companyId });
      setSelectedCompanyId(companyId);
      setOffset(0);
      setPlansLoading(true);

      fetchSpecialistPlans({
        companies: [companyId],
        groups: null,
        statuses: null,
        count: true,
        startDate: null,
        endDate: null,
        offset: 0,
        limit: itemsPerPage,
        cb: (err) => {
          if (err) {
            return setPlansError(true);
          }
          setPlansLoading(false);
        },
      });
    }
    history.push("/company");
  };
  const permissions = useStore($UserAddPermissions);

  const filterCompanies = (companies: CompanyShortDataT[]) => {
    if (
      !permissions.roleIsIn([
        UserRoleEnum.SuperAdmin,
        UserRoleEnum.SuperAdminMinus,
        UserRoleEnum.Admin,
      ])
    ) {
      return companies.filter((company) => company.tariff > 0);
    }

    return companies;
  };

  return (
    <div style={{ padding: "16px" }}>
      <Search
        options={companies ?? []}
        getOptionLabel={(option) => option.name}
        showDropDown={false}
        placeholder="Поиск по ИНН и названию компании"
        value={search.value}
        onChange={search.onChange}
      />
      <div className={clsx(style.companies_list)}>
        {filterCompanies(companies ?? []).map((company) => (
          <CompanyCard
            key={company.id}
            company={company}
            isSelected={company.id === selectedCompanyId}
            onSelect={() => handleCompanyChanging(company.id)}
          />
        ))}
      </div>
    </div>
  );
};

type CompanyCardProps = {
  company: CompanyShortDataT;
  isSelected: boolean;
  onSelect: () => void;
};

const CompanyCard: FC<CompanyCardProps> = ({
  company,
  isSelected,
  onSelect,
}) => {
  return (
    <div
      className={clsx(style.company_item, "hover-animation-card", {
        [style.active]: isSelected,
      })}
      onClick={() => onSelect()}
    >
      <img
        src={getCompanyLogo(company.image)}
        alt=""
        className={clsx(style.company_img)}
      />
      <p className={clsx(style.company_info_text)}>
        {getTextExcerpt(
          `${company.name}`,
          document.documentElement.clientWidth > 1124
            ? 25
            : document.documentElement.clientWidth < 480
              ? 13
              : 20
        )}
      </p>
    </div>
  );
};

export default Companies;
