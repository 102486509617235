import { FC } from "react";

import { useFeedbackCloseQuery, useFeedbackReopenQuery } from "@lib/feedback";
import { SwitchUI } from "@components/switch";

type FeedbackTogglePropsT = {
  isClosed?: boolean;
  feedBackId?: string;
  setIsClosed?: (is: boolean) => void;
};

export const FeedbackToggle: FC<FeedbackTogglePropsT> = ({
  isClosed,
  feedBackId,
  setIsClosed,
}) => {
  const { mutate: reopenQuery } = useFeedbackReopenQuery();
  const { mutate: closeQuery } = useFeedbackCloseQuery();

  const onChangeChecked = (state: boolean) => {
    if (!feedBackId) return;

    if (!state) {
      reopenQuery(feedBackId, {
        onSuccess: () => {
          setIsClosed?.(state);
        },
      });
    }

    if (state) {
      closeQuery(feedBackId, {
        onSuccess: () => {
          setIsClosed?.(state);
        },
      });
    }
  };

  return (
    <SwitchUI
      checked={!!isClosed}
      onChange={onChangeChecked}
      label={isClosed ? "Обращение закрыто" : "Обращение открыто"}
    />
  );
};
