import { ErrorIndicator, Loader } from "@ui/indicators";
import { useEffect, useState } from "react";

import { DocumentSignatureHistoryT } from "@interfaces/company/departments";
import SignatureLogTable from "@components/tables/department-tables/signature-log-table";
import DepartmentService from "@services/department-service";
import clsx from "clsx";
import style from "@scss/pages/signing-documents.module.scss";
import { useModal } from "@modals/index";

const SignatureLogModal: React.FC = () => {
  const [signatures, setSignatures] = useState<
    Array<DocumentSignatureHistoryT>
  >([]);
  const [states, setStates] = useState({
    isPending: true,
    error: false,
  });
  const { modalData, modalComponent } = useModal();

  useEffect(() => {
    const currentModal = document.querySelector(
      "." + clsx(style.sending_for_signature_modal)
    ) as HTMLDivElement;
    const reactSliderDiv = currentModal.closest(
      ".react-responsive-modal-modal"
    );

    if (reactSliderDiv) {
      reactSliderDiv.classList.add(clsx(style.large_modal));
    }
    DepartmentService.GetSignatureHistories(
      modalData.departmentId,
      modalData.file.id,
      (err, res) => {
        if (err || !res) {
          return setStates({ isPending: false, error: true });
        }
        setSignatures(res.data);
        setStates({ isPending: false, error: false });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      key={modalComponent.key}
      className={clsx(style.sending_for_signature_modal)}
    >
      <p className="modal_title">Журнал подписаний "{modalData.file.name}"</p>
      <div className="underline" />
      <div className={`modal_content ${style.adding_doc_modal_content}`}>
        {states.error ? (
          <ErrorIndicator />
        ) : states.isPending ? (
          <Loader />
        ) : (
          <SignatureLogTable signatures={signatures} />
        )}
      </div>
    </div>
  );
};

export default SignatureLogModal;
