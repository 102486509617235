import { FC, Fragment, useMemo } from "react";

import clsx from "clsx";

import styles from "./ChatMessages.module.scss";
import { MessageT } from "@interfaces/technical-support";
import { useStore } from "effector-react";
import { UserDataT } from "@interfaces/user";
import { $User } from "@store/user-store";
import { BodyLight } from "@ui/fonts";
import moment from "moment";
import { MessageFiles } from "../MessageFiles/MessageFiles";

type ChatMessagesPropsT = {
  className?: string;
  messages: MessageT[];
  feedbackCreatorId: number;
};

export const ChatMessages: FC<ChatMessagesPropsT> = ({
  className,
  messages,
  feedbackCreatorId,
}) => {
  const today = useMemo(() => new Date(), []);
  const user = useStore($User) as UserDataT;

  const handleDate = (time: string) => {
    const date = new Date(time);
    return `${
      date
        .toLocaleString("ru-RU", {
          dateStyle: "long",
        })
        .split(` ${today.getFullYear()} г`)[0]
    }`;
  };

  const handleIsMyMessage = (senderId: number) => {
    return (
      senderId === user.id || ![user.id, senderId].includes(feedbackCreatorId)
    );
  };

  const getMessageAuthor = (message: MessageT): string => {
    if (message.senderId === feedbackCreatorId) {
      return "Клиент";
    }

    return "Поддержка";
  };

  return (
    <div className={className}>
      {messages.map((message, index, array) => {
        const isSplit =
          handleDate(array[index - 1]?.createdAt) !==
          handleDate(message.createdAt);

        const isMine = handleIsMyMessage(message?.senderId);
        const isFirst =
          (isSplit || array[index - 1]?.senderId !== message.senderId) &&
          !isMine;

        const hasFiles = !!message.files.length;

        return (
          <Fragment key={index + message._id}>
            {isSplit && (
              <div className={styles.splitDate}>
                {handleDate(message.createdAt)}
              </div>
            )}
            <div
              className={clsx(styles.message__container, {
                [styles.message__container_mine]: isMine,
              })}
            >
              <div
                className={clsx(styles.message, {
                  [styles.message_mine]: isMine,
                  [styles.message_withFiles]: hasFiles,
                })}
              >
                {isFirst && (
                  <div className={styles.message__author}>
                    <BodyLight color="gray-dark2">
                      {getMessageAuthor(message)}
                    </BodyLight>
                  </div>
                )}
                <div className={styles.message__text}>{message.text}</div>
                {hasFiles && <MessageFiles files={message.files} />}
                <div className={styles.message__time}>
                  <BodyLight color="gray-dark2">
                    {moment(message?.createdAt).format("HH:mm")}
                  </BodyLight>
                </div>
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};
