import {
  EmployeeDataForSigningT,
  EmployeeDocumentT,
  EmployeeListDataT,
  EmployeeSendMedicalExamReferralData,
  ImportEmployeesResult,
  PostUserData,
  RelatedEmployeeDataT,
} from "@interfaces/company/employees";

import Fetcher, { fetcherV2 } from "@http/fetcher";
import { ResCallback } from "@interfaces/common";
import { SelectedDocsDataT } from "@modals/modal-items/company/sending-for-signature-modal";
import { SendingForSignatureDocumentT } from "@interfaces/company/departments";
import { SignDocumentsPropsT } from "@interfaces/requests/employee";

export type ConfirmEmployeeData = {
  id: number;
  isTrainingControlFilled?: boolean;
  isBriefingControlFilled?: boolean;
  isMedicalExaminationControlFilled: boolean;
};

type ArchiveType = {
  employeeId: number;
};

export const employeesService = {
  async moveToSign(id: number | number[]) {
    const resp = await Fetcher.patch<Record<number, boolean>>(
      `/move-to-signing`,
      {
        employeeId: id,
      }
    );
    return resp.data;
  },

  async archive(data: ArchiveType) {
    const resp = await Fetcher.modified.put<string>(
      `/employees/${data.employeeId}/archive`,
      { isArchived: true }
    );

    return resp;
  },

  async getById(id: number) {
    const resp = await Fetcher.modified.get<RelatedEmployeeDataT>(
      `/employees/${id}`,
      {
        params: {
          extended: true,
        },
      }
    );

    return resp.data;
  },

  async create(data: PostUserData) {
    // TODO: вынести это отсюда куда-нибудь в более подходящее место и переписать желательно
    const _data = {
      ...data,
      position: undefined,
      isCustomPosition: undefined,
      trainingPrograms: data.isCustomPosition
        ? data.trainingPrograms
        : undefined,
      briefingPrograms: data.isCustomPosition
        ? data.briefingPrograms
        : undefined,
      medicalExaminationFactors: data.isCustomPosition
        ? data.medicalExaminationFactors
        : undefined,
    };

    const resp = await Fetcher.modified.post<EmployeeListDataT>(
      "/employees",
      _data
    );

    return resp;
  },

  async update(employeeId: number, data: PostUserData) {
    // TODO: вынести это отсюда куда-нибудь в более подходящее место и переписать желательно
    const _data = {
      ...data,
      position: undefined,
      isCustomPosition: undefined,
      trainingPrograms: data.isCustomPosition
        ? data.trainingPrograms
        : undefined,
      briefingPrograms: data.isCustomPosition
        ? data.briefingPrograms
        : undefined,
      medicalExaminationFactors: data.isCustomPosition
        ? data.medicalExaminationFactors
        : undefined,
    };

    const resp = await Fetcher.modified.patch<EmployeeListDataT>(
      `/employees/${employeeId}`,
      _data
    );

    return resp;
  },

  async confirmTrainingControl(employeeId: number) {
    const resp = await Fetcher.modified.put(
      `/employees/${employeeId}/confirm-training-control`
    );

    return resp.data;
  },

  async confirmBriefingControl(employeeId: number) {
    const resp = await Fetcher.modified.put(
      `/employees/${employeeId}/confirm-briefing-control`
    );

    return resp.data;
  },

  async importFromFile(companyId: number, file: File, departmentId?: number) {
    const formData = new FormData();
    formData.set("file", file);
    formData.set("companyId", String(companyId));

    if (typeof departmentId !== "undefined") {
      formData.set("departmentId", String(departmentId));
    }

    const resp = await Fetcher.modified.post<ImportEmployeesResult>(
      "/employees/file",
      formData
    );

    return resp.data;
  },

  async sendMedicalExamReferral({
    companyId,
    id,
    ...params
  }: EmployeeSendMedicalExamReferralData) {
    const resp = await Fetcher.post(
      `/companies/${companyId}/employees/${id}/send-referral-to-medical-examination`,
      {},
      { params }
    );
    return resp.data;
  },

  async downloadEmployeeReferral({
    companyId,
    id,
    isNew,
  }: {
    companyId: number;
    id: number;
    isNew: boolean;
  }) {
    const resp = await Fetcher.get<Blob>(
      `/companies/${companyId}/employees/${id}/download-referral-to-medical-examination`,
      {
        params: { isNew },
        responseType: "blob",
      }
    );
    return resp.data;
  },
};

// static async getAll(
//   isArchived: boolean,
//   cb: ResCallback<EmployeeListDataT[]>
// ) {
//   try {
//     const res = await Fetcher.modified.get<EmployeeListDataT[]>(
//       `/employees?isArchived=${isArchived}`
//     );

//     if (res.status === 200) {
//       cb(null, res);
//     }
//   } catch (err: any) {
//     cb(err);
//   }
// }

class EmployeeService {
  static async getAll(
    isArchived: boolean,
    cb: ResCallback<EmployeeListDataT[]>
  ) {
    try {
      const res = await Fetcher.modified.get<EmployeeListDataT[]>(
        `/employees?isArchived=${isArchived}`
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async getById(
    employeeId: number,
    extended: boolean,
    cb: ResCallback<RelatedEmployeeDataT | EmployeeDocumentT[]>
  ) {
    try {
      const res = await Fetcher.modified.get<
        RelatedEmployeeDataT | EmployeeDocumentT[]
      >(`/employees/${employeeId}`, {
        params: { extended },
      });

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async GetDocumentsForSendingSignature(
    employeeId: number,
    positionId: number,
    cb: ResCallback<SendingForSignatureDocumentT[]>
  ) {
    try {
      const res = await Fetcher.modified.get<SendingForSignatureDocumentT[]>(
        `/employees/${employeeId}/signing/files`,
        {
          params: { positionId },
        }
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async GetDocumentsForSigning(
    companyId: string,
    employeeId: string,
    verifyingId: string,
    cb: ResCallback<EmployeeDataForSigningT>
  ) {
    try {
      const res = await fetcherV2.get<EmployeeDataForSigningT>(
        `/companies/${companyId}/employees/${employeeId}/signing`,
        {
          params: { verifyingId },
        }
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async DownloadFileForSignature(
    employeeId: number,
    hash: string,
    eventId: number,
    documentId: number,
    cb: ResCallback<Blob>
  ) {
    try {
      const res = await Fetcher.get<Blob>("/download-file-for-signature", {
        params: {
          employeeId,
          hash,
          eventId,
          documentId,
        },
        responseType: "blob",
      });

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async create(data: PostUserData, cb: ResCallback<EmployeeListDataT>) {
    try {
      const res = await Fetcher.modified.put<EmployeeListDataT>(
        "/employees",
        data
      );

      if (res.status === 201) {
        cb(null, res);
        return res;
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async ConfirmNewEmployee(
    companyId: number,
    data: ConfirmEmployeeData,
    cb?: ResCallback<{}>
  ) {
    try {
      const res = await Fetcher.put<{}>(
        `/companies/${companyId}/employees/confirm`,
        data
      );

      if (res.status === 200) {
        cb?.(null, res);
        return res;
      }
    } catch (err: any) {
      cb?.(err);
    }
  }

  static async update(
    employeeId: number,
    data: PostUserData,
    cb: ResCallback<EmployeeListDataT>
  ) {
    try {
      const res = await Fetcher.modified.post<EmployeeListDataT>(
        `/employees/${employeeId}`,
        data
      );

      if (res.status === 200) {
        cb(null, res);
        return res;
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async AddToArchive(employeeId: number, cb: ResCallback<string>) {
    try {
      const res = await Fetcher.modified.put<string>(
        `/employees/${employeeId}/archive`,
        { isArchived: true }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async ArchiveEmployees(
    employees: number[],
    isArchived: boolean,
    cb: ResCallback<number>
  ) {
    try {
      const res = await Fetcher.modified.post<number>(`/employees/archive`, {
        employees,
        isArchived,
      });

      cb(null, res);
    } catch (err: any) {
      cb(err);
    }
  }

  static async SendForSignature(
    employeeId: number[],
    documents: SelectedDocsDataT[],
    cb: ResCallback<string>
  ) {
    try {
      const res = await Fetcher.modified.post<string>(
        `/employees/${employeeId}/signature`,
        { documents }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }
  // REFACTOR - have to send one request and many email but not many requests and one email on each request
  static async SendForSignatureToMany(
    employeeIdsAndPos: any,
    documentIds: number[],
    eventId: number,
    cb: ResCallback<string>
  ) {
    employeeIdsAndPos.forEach(async (employee: any) => {
      const documents = documentIds.map((docId) => ({
        documentId: docId,
        positionId: employee.positionId,
      }));
      try {
        const res = await Fetcher.modified.post<string>(
          `/employees/${employee.employeeId}/signature`,
          { documents, eventId }
        );

        if (res.status === 200) {
          return cb(null, res);
        }
      } catch (err: any) {
        cb(err);
      }
    });
  }

  static async ReSendForSignature(
    employeeId: number,
    signatureId: number,
    cb: ResCallback<string>
  ) {
    try {
      const res = await Fetcher.modified.post<string>(
        `/employees/${employeeId}/signature/${signatureId}/re-signature`
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async MarkDocumentAsViewed(
    companyId: number,
    signatureId: number,
    verifyingId: string,
    cb: ResCallback<string>
  ) {
    try {
      const res = await Fetcher.post<string>(
        `/signature/${signatureId}/signing/mark?verifyingId=${verifyingId}`
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async SendSigningVerificationCode(
    companyId: number,
    employeeId: number,
    verifyingId: string,
    cb: ResCallback<string>
  ) {
    try {
      const res = await Fetcher.post<string>(
        `/employee/${employeeId}/signing_code?verifyingId=${verifyingId}&companyId=${companyId}`
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async SignDocuments(
    props: SignDocumentsPropsT,
    cb: ResCallback<string>
  ) {
    const { companyId, employeeId, verifyingId, code, eventId } = props;
    try {
      const res = await fetcherV2.post<string>(
        `/companies/${companyId}/employees/${employeeId}/sign`,
        {
          verifyingId,
          code,
          eventId,
        }
      );

      if (res.status === 200) {
        return cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }
}

export default EmployeeService;
