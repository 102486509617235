import { AxiosError } from "axios";

export const handleApiError = (
  error: unknown,
  setErrors: (errors: { [key: string]: string }) => void
) => {
  const err = error as AxiosError;

  const errorsObj = err.response?.data;
  if (!errorsObj) return;

  const errs: { [key: string]: string } = {};
  for (const key of Object.keys(errorsObj)) {
    const value = errorsObj[key];
    if (!!value) {
      const newKey = key.replace("Error", "");
      errs[newKey] = value;
    }
  }
  setErrors(errs);
};

export const handleApiErrorLocally = (
  error: unknown,
  setError: (error: string | undefined) => void
) => {
  const err = error as AxiosError;
  const message = err.response?.data?.message ?? err.message;
  setError(message);
};
