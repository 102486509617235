import Fetcher from "@http/fetcher";

import { ResCallback } from "@interfaces/common";
import {
  CreateResponsibilityDirectoryPropsT,
  GetResponsibilityDocumentsResT,
} from "@interfaces/requests/responsibility";
import {
  ResponsibilityDirectoryT,
  ResponsibilityFileT,
} from "@interfaces/responsibility";
import { ResponsibilityRequestTypeEnum } from "@pages/responsibility";

class LegalInformationService {
  static async getItems(
    directoryId: number | null,
    cb: ResCallback<GetResponsibilityDocumentsResT>
  ) {
    try {
      const res = await Fetcher.get<GetResponsibilityDocumentsResT>(
        `/legal-information/directories/${directoryId || 0}`,
        {
          params: {
            directoryId: directoryId || 0,
            type: ResponsibilityRequestTypeEnum.LEGAL_INFORMATION,
          },
        }
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async CreateDirectory(
    data: CreateResponsibilityDirectoryPropsT,
    cb: ResCallback<ResponsibilityDirectoryT>
  ) {
    try {
      const res = await Fetcher.post<ResponsibilityDirectoryT>(
        "/legal-information/directories",
        data,
        {
          params: {
            type: ResponsibilityRequestTypeEnum.LEGAL_INFORMATION,
          },
        }
      );

      if (res.status === 201) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async UploadFiles(
    formData: FormData,
    directoryId: number,
    cb: ResCallback<ResponsibilityFileT[]>
  ) {
    formData.set("directoryId", String(directoryId));

    try {
      const res = await Fetcher.post<ResponsibilityFileT[]>(
        `/legal-information/files`,
        formData,
        {
          params: {
            directoryId,
            type: ResponsibilityRequestTypeEnum.LEGAL_INFORMATION,
          },
        }
      );

      if (res.status === 201) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async UpdateFile(id: number, name: string, cb: ResCallback<string>) {
    try {
      const res = await Fetcher.put<string>(`/legal-information/files/${id}`, {
        name,
      });

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async UpdateDirectory(
    id: number,
    name: string,
    cb: ResCallback<string>
  ) {
    try {
      const res = await Fetcher.put<string>(
        `/legal-information/directories/${id}`,
        { name }
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async DeleteFile(fileId: number, cb: ResCallback<string>) {
    try {
      const res = await Fetcher.delete<string>(
        `/legal-information/files/${fileId}`
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }

  static async DeleteDirectory(directoryId: number, cb: ResCallback<string>) {
    try {
      const res = await Fetcher.delete<string>(
        `/legal-information/directories/${directoryId}`
      );

      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err: any) {
      cb(err);
    }
  }
}

export default LegalInformationService;
