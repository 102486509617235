export const getNextPage = (
  page: number,
  limit: number,
  totalCount: number
): number | null => {
  const nextPage = page + 1;
  if (nextPage > Math.ceil(totalCount / limit)) {
    return null;
  }

  return nextPage;
};

export const getPreviousPage = (page: number): number | null => {
  const previousPage = page - 1;
  if (previousPage <= 0) {
    return null;
  }

  return previousPage;
};
