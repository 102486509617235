export const FileLoadSVG = () => {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.61719 17.8828H11.9609C13.6719 17.8828 14.5859 16.9609 14.5859 15.2344V8.14844C14.5859 7.00781 14.4297 6.46094 13.7109 5.73438L9.75781 1.72656C9.0625 1.01562 8.47656 0.851562 7.42188 0.851562H3.61719C1.90625 0.851562 0.992188 1.76562 0.992188 3.49219V15.2344C0.992188 16.9609 1.89844 17.8828 3.61719 17.8828ZM3.78906 16.1406C3.07812 16.1406 2.73438 15.7734 2.73438 15.0859V3.64062C2.73438 2.96094 3.07812 2.58594 3.79688 2.58594H7.00781V6.67188C7.00781 7.89062 7.60156 8.47656 8.82031 8.47656H12.8438V15.0859C12.8438 15.7734 12.5 16.1406 11.7812 16.1406H3.78906ZM8.96875 7C8.64062 7 8.49219 6.85156 8.49219 6.52344V2.78125L12.6562 7H8.96875ZM8.53125 10.0234C8.53125 9.60938 8.19531 9.3125 7.78906 9.3125C7.38281 9.3125 7.03906 9.60938 7.03906 10.0234V12.0938L7.10938 13.2656L6.5625 12.6797L6.03906 12.1484C5.92188 12.0078 5.72656 11.9141 5.54688 11.9141C5.16406 11.9141 4.88281 12.1875 4.88281 12.5547C4.88281 12.7656 4.96094 12.9219 5.11719 13.0625L7.21094 14.9688C7.39844 15.1406 7.57031 15.2266 7.78906 15.2266C8.00781 15.2266 8.17969 15.1406 8.36719 14.9688L10.4531 13.0625C10.6172 12.9219 10.6953 12.7656 10.6953 12.5547C10.6953 12.1875 10.4062 11.9141 10.0312 11.9141C9.84375 11.9141 9.65625 12.0078 9.53906 12.1484L9.02344 12.6797L8.46875 13.2656L8.53125"
        fill="#00B856"
      />
    </svg>
  );
};
