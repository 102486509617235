import { useProjectNewsQuery } from "@lib/project-news";
import { useEffect, useState } from "react";

export const useProjectNewsModal = () => {
  const { data } = useProjectNewsQuery(1);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const newsItem = data?.pages?.[0].result?.rows?.[0];

  useEffect(() => {
    if (typeof window === "undefined" || isModalOpen) return;

    const newsItem = data?.pages?.[0].result?.rows?.[0];
    if (!newsItem) return;

    const lastViewedItemId = localStorage.getItem("last-viewed-news-id");
    if (newsItem._id !== lastViewedItemId) {
      localStorage.setItem("last-viewed-news-id", newsItem._id);
      setIsModalOpen(true);
    }
  }, [data, isModalOpen]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return {
    isModalOpen,
    closeModal,
    newsItem,
  } as const;
};
