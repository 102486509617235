import { BodyNormal, Subheader } from "@ui/fonts";
import { Dispatch, SetStateAction } from "react";
import clsx from "clsx";

import { AxiosResponse } from "axios";
import { CommonSearch } from "@ui/common-search";
import { GetAllFeedbackT } from "@interfaces/technical-support";
import { Link } from "react-router-dom";
import { Pagination } from "@components/pagination";
import { SwitchUI } from "@components/switch";
import moment from "moment";
import styles from "./ListFeedBack.module.scss";
import { useStore } from "effector-react";
import { $DeviceType } from "@store/device-type-store";

type ListFeedBackT = {
  newFeedBack: boolean;
  feedbackList?: AxiosResponse<GetAllFeedbackT>;
  checkedClose: boolean;
  setCheckedClose: Dispatch<SetStateAction<boolean>>;
  setSearch: Dispatch<SetStateAction<string>>;
  totalPages: number;
  setPage: Dispatch<SetStateAction<number>>;
  page: number;
};

export const ListFeedBack: React.FC<ListFeedBackT> = ({
  newFeedBack,
  feedbackList,
  checkedClose,
  setCheckedClose,
  setSearch,
  totalPages,
  setPage,
  page,
}) => {
  const { isPhone, isMobile } = useStore($DeviceType);

  return (
    <>
      {!newFeedBack && (
        <>
          <div className={styles.ListFeedBack}>
            <div
              className={clsx(
                styles.header,
                isMobile && !isPhone && styles.header_tablet,
                isPhone && styles.header_mobile
              )}
            >
              <div className={styles.header__top}>
                <div className={styles.header__title}>
                  {feedbackList?.data?.rows && (
                    <Subheader>Тема обращения</Subheader>
                  )}
                </div>
                <div className={styles.header__switch}>
                  <SwitchUI
                    checked={checkedClose}
                    onChange={(state: boolean) => setCheckedClose(state)}
                    label={
                      checkedClose ? "Закрытые обращения" : "Открытые обращения"
                    }
                  />
                </div>
              </div>
              <CommonSearch
                className={styles.header__search}
                onChange={() => {}}
                setSearch={setSearch}
              />
            </div>
            {feedbackList?.data?.rows.map((item, i) => {
              return (
                <Link
                  to={"/feedback/" + item._id}
                  key={item.createdAt + i}
                  className={styles.ListFeedBackItem}
                >
                  <BodyNormal>{item.title}</BodyNormal>
                  {!isPhone && !!item?.closedAt && (
                    <div className={styles.closedMessage}>
                      <BodyNormal weight="bold">Обращение закрыто</BodyNormal>
                    </div>
                  )}
                  <div className={styles.supportCreatedAt}>
                    <BodyNormal>
                      {moment(item.createdAt).format("LLL")}
                    </BodyNormal>
                  </div>
                </Link>
              );
            })}
          </div>
          <div className={styles.pagination}>
            {totalPages > 1 && (
              <Pagination value={page} onChange={setPage} total={totalPages} />
            )}
          </div>
        </>
      )}
    </>
  );
};
