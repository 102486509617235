import { useMutation, useQueryClient } from "react-query";
import { MedicalExaminationService } from "@services/medical-examination.service";
import { medicalExaminationQueryKeys } from "./queryKeys";

export const useUpdateMedicalExaminationFactorMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(MedicalExaminationService.updateFactor, {
    onSuccess: () => {
      queryClient.invalidateQueries(medicalExaminationQueryKeys.all);
    },
  });
};
