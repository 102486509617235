import { StudyCenterProgramsParams } from "@services/study-center";

export const studyCenterQueryKeys = {
  all: ["study-center"],
  programs: (params?: StudyCenterProgramsParams) => [
    ...studyCenterQueryKeys.all,
    "programs",
    params,
  ],
  educationalPromotions: () => [
    ...studyCenterQueryKeys.all,
    "educationalPromotions",
  ],
  educationalPromotionById: (id: number) => [
    ...studyCenterQueryKeys.educationalPromotions(),
    id,
  ],
  educationalEvents: () => [...studyCenterQueryKeys.all, "educationalEvents"],
  educationalEventById: (id: number) => [
    ...studyCenterQueryKeys.educationalEvents(),
    id,
  ],
};
