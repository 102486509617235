import { VersionsAllT, VersionsIdT } from "@interfaces/directory/version";

import Fetcher from "@http/fetcher";
import { ResCallback } from "@interfaces/common";
import { SubmitObjectT } from "feature/directory-systems/on-save-all/on-save-all";

class DirectoryVersionService {
  static async GetHaveAnyChanges(
    type: "training" | "briefing" | "medical-examination",
    cb: ResCallback<boolean>
  ) {
    try {
      const res = await Fetcher.get<boolean>(
        `/directories/${type}/versions/has-any-changes`
      );
      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async CancelAllChanges(
    type: "training" | "briefing" | "medical-examination",
    cb: ResCallback<any>
  ) {
    try {
      const res = await Fetcher.delete<any>(
        `/directories/${type}/versions/cancel-all-changes`
      );
      if (res.status !== 200) {
        throw new Error();
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }

  static async GetAllChanges(
    type: "training" | "briefing" | "medical-examination",
    cb: ResCallback<VersionsAllT[]>
  ) {
    try {
      const res = await Fetcher.get<VersionsAllT[]>(
        `/directories/${type}/versions`
      );
      if (res.status === 200) {
        cb(null, res);
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }
  static async RecoveryChangeId(
    id: number,
    type: "training" | "briefing" | "medical-examination",
    cb: ResCallback<VersionsIdT>
  ) {
    try {
      const res = await Fetcher.delete<VersionsIdT>(
        `/directories/${type}/versions/${id}`
      );
      if (res.status === 200) {
        cb(null, res);
        return res;
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }
  static async SaveAllChanges(
    type: "training" | "briefing" | "medical-examination",
    submitObject: SubmitObjectT,
    ids: number[] | [] | undefined,
    cb: ResCallback<VersionsIdT>
  ) {
    try {
      const params = {
        versionData: {
          name: submitObject.name,
          description: submitObject.description,
        },
        notificationData: {
          text: submitObject.textEmail,
          roles: ids,
          // undefined - отправить всем
          // [] - никому не отправлять
          // roles: [1, 2, 4, 5, 777, 787, 788, 790, 796, 804, 805, 806, 811],
        },
      };
      const res = await Fetcher.post<VersionsIdT>(
        `/directories/${type}/versions`,
        params
      );
      if (res.status === 201) {
        cb(null, res);
        return res;
      }
    } catch (err) {
      // @ts-ignore
      cb(err);
    }
  }
}

export default DirectoryVersionService;
