import { SortingType } from "./sorting";

export const requestTrainingStatuses = {
  Created: "Создана",
  Submitted: "Отправлена",
  Approved: "Утверждена",
  InProgress: "В работе",
  Cancelled: "Отменена",
  Processing: "Обрабатывается",
  Completed: "Выполнена",
  Archived: "В архиве",
};
export type RequestTrainingStatus = keyof typeof requestTrainingStatuses;

export type TrainingRequestStatusAction =
  | "approve"
  | "confirm"
  | "take-to-work"
  | "close"
  | "cancel"
  | "archive"
  | "return";

export type TrainingRequestStatusActionData = {
  id: number;
  action: TrainingRequestStatusAction;
};

export type TrainingRequestSendEmailData = {
  id: number;
  file: File;
};

export const requestTrainingStatusList: {
  id: string;
  title: string;
}[] = [
  {
    id: "Создана",
    title: "Создана",
  },
  {
    id: "Отправлена",
    title: "Отправлена",
  },
  {
    id: "Утверждена",
    title: "Утверждена",
  },
  {
    id: "В работе",
    title: "В работе",
  },
  {
    id: "Отменена",
    title: "Отменена",
  },
  {
    id: "Обрабатывается",
    title: "Обрабатывается",
  },
  {
    id: "Выполнена",
    title: "Выполнена",
  },
  {
    id: "В архиве",
    title: "В архиве",
  },
];

export const trainingRequestEducationForms: { id: string; title: string }[] = [
  {
    id: "Очная",
    title: "Очная",
  },
  {
    id: "Заочная",
    title: "Заочная",
  },
  {
    id: "Дистанционная",
    title: "Дистанционная",
  },
  {
    id: "Вебинар",
    title: "Вебинар",
  },
];

export type GetAllTrainingRequestsParams = {
  searchString?: string;
  status?: string;
  customer?: number;
  titleOrder?: SortingType;
  commentOrder?: SortingType;
  customerOrder?: SortingType;
  dateCreationOrder?: SortingType;
  statusOrder?: SortingType;
};

export const trainingRequestsSortings = [
  "titleOrder",
  "commentOrder",
  "customerOrder",
  "dateCreationOrder",
];

export type TrainingRequestStudent = {
  id: number;
  name: string;
};

export type TrainingRequest = {
  id: number;
  title: string;
  comment: string | null;
  status: string;
  customer: {
    id: number;
    name: string;
  };
  createdAt: string;
  updatedAt: string;
};

export type SingleTrainingRequestCompanyClient = {
  name: string;
};

export type SingleTrainingRequestCompany = {
  id: number;
  name: string;
  leadershipPosition: string;
  supervisor: string;
};

export enum TrainingControlCause {
  Primary = "Первичное",
  Repeated = "Повторное",
}

export type SingleTrainingRequestStudent = TrainingRequestStudent & {
  id: number;
  controlId: number;
  departmentId: number;
  departmentTitle: string;
  positionId: number;
  positionTitle: string;
  employeeId: number;
  employeeName: string;
  employeeEmail: string;
  employeeSnils: string;
  companyName: string;
  companyInn: string;
  cause: TrainingControlCause;
  programFromApplicationId: number;
};

export type SingleTrainingRequestProgram = {
  id: number;
  programId: number;
  programTitle: string;
  form: string;
  deadline: string | null;
  students: SingleTrainingRequestStudent[];
};

export type SingleTrainingRequest = Pick<
  TrainingRequest,
  "id" | "title" | "comment" | "status" | "createdAt" | "updatedAt"
> & {
  customer: SingleTrainingRequestCompany & {
    inn: string;
  };
  executor: SingleTrainingRequestCompany;
  programsFromApplication: SingleTrainingRequestProgram[];
  wayToGetDocuments: string | null;
  typeOfWayToGetDocuments: TrainingRequestDocumentShipping | null;
  contactPerson: string | null;
  contactPhoneNumber: string | null;
  deliveryAddress: string | null;
};

export type CreateTrainingRequestData = Partial<
  Omit<TrainingRequest, "status">
> & {
  title: string;
  students: { programId: number; employeeId: number }[];
  customerId: number;
  executorId: number;
  isCreateTask: boolean;
};

export type UpdateTrainingRequestData = {
  id: number;
  customerId?: number;
  executorId?: number;
  title?: string;
  comment?: string | null;
  wayToGetDocuments?: string | null;
  typeOfWayToGetDocuments?: TrainingRequestDocumentShipping | null;
  contactPerson?: string | null;
  contactPhoneNumber?: string | null;
  deliveryAddress?: string | null;
  status?: string;
  programsFromApplication?: Partial<
    Pick<SingleTrainingRequestProgram, "id" | "form" | "deadline">
  >[];
  addedStudents?: { programId: number; employeeId: number }[];
  deletedStudents?: number[];
};

export type UpdateTrainingRequestEducationFormData = {
  id: number;
  form: string;
};

export type TrainingRequestDocumentShipping =
  | "Почта"
  | "Служба доставки"
  | "Самовывоз";
export const trainingRequestDocumentShippings: {
  id: TrainingRequestDocumentShipping;
  title: string;
}[] = [
  {
    id: "Почта",
    title:
      "Почта России (за счет Заказчика, учитывается при выставлении счета)",
  },
  {
    id: "Служба доставки",
    title: "Курьерские службы (за счет Заказчика, по дополнительному счету)",
  },
  {
    id: "Самовывоз",
    title: "Забираем самостоятельно",
  },
];

export type EmployeeAvailableToAdd = {
  program: {
    id: number;
    title: string;
  };
  employees: {
    id: number;
    name: string;
  }[];
  employeesFromControls: {
    id: number;
    name: string;
  }[];
};
