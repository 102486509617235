import { useMutation, useQueryClient } from "react-query";
import {
  CreateInternalStudyCenterCategoryData,
  internalStudyCenterService,
} from "@services/internal-study-center-service";
import { internalStudyCenterQueryKeys } from "./queryKeys";

export const useCreateInternalCategoryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: CreateInternalStudyCenterCategoryData) =>
      internalStudyCenterService.createCategory(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(internalStudyCenterQueryKeys.all);
      },
    }
  );
};
