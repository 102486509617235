import { useMutation, useQuery, useQueryClient } from "react-query";

import TechnicalSupportService from "@services/technical-support-service";
import { ParamsGetAllFeedbackT } from "@interfaces/technical-support";

export const useFeedbackQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (formData: FormData) => TechnicalSupportService.create(formData),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["feedback-list"]);
      },
    }
  );
};

export const useFeedbackReopenQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => TechnicalSupportService.reopenMessage(id),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["feedback-list"]);
        queryClient.refetchQueries(["feedback-id"]);
      },
    }
  );
};

export const useFeedbackCloseQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (id: string) => TechnicalSupportService.closeMessage(id),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["feedback-list"]);
        queryClient.refetchQueries(["feedback-id"]);
      },
      onError: () => {
        queryClient.refetchQueries(["feedback-list"]);
        queryClient.refetchQueries(["feedback-id"]);
      },
    }
  );
};

export const useGetAllFeedbackQuery = (params: ParamsGetAllFeedbackT) => {
  return useQuery(
    ["feedback-list", params],
    async () => TechnicalSupportService.getAll(params),
    { keepPreviousData: true }
  );
};

export const useGetIdFeedbackQuery = (id: string) => {
  return useQuery(
    ["feedback-id", id],
    async () => TechnicalSupportService.getById(id),
    { keepPreviousData: true }
  );
};

export const useCreateFeedbackMessageQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ id, formData }: { id: string; formData: FormData }) =>
      TechnicalSupportService.createMessage(id, formData),
    {
      onSuccess: () => {
        queryClient.refetchQueries(["feedback-id"]);
      },
    }
  );
};
