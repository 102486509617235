import { useMutation, useQueryClient } from "react-query";
import { studyCenterService } from "@services/study-center";
import { studyCenterQueryKeys } from "./queryKeys";

export const useDeleteEducationalPromotionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation((id: number) => studyCenterService.deletePromotion(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(studyCenterQueryKeys.all);
    },
  });
};
