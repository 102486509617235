export type InternalStudyCenterMaterialFile = {
  id: number;
  name: string;
  originalName: string;
};

export type InternalStudyCenterMaterial = {
  id: number;
  title: string;
  link: string | null;
  text: string | null;
  preview: string;
  tags?: string[];
  files?: InternalStudyCenterMaterialFile[];
};

export enum InternalStudyCenterSectionContentType {
  Link = "Ссылки",
  Article = "Статьи",
  LinkAndArticle = "Ссылки и статьи",
}

export type InternalStudyCenterSection = {
  id: number;
  name: string;
  contentType: InternalStudyCenterSectionContentType;
  categories: InternalStudyCenterCategory[];
};

export type InternalStudyCenterCategory = {
  id: number;
  name: string;
  materials: InternalStudyCenterMaterial[];
};
