import requestTrainingService from "@services/request-training-service";
import { trainingRequestsQueryKeys } from "./queryKeys";
import { useQuery } from "react-query";

export const useTrainingRequestQuery = (id?: string) => {
  const { data: training, ...stuff } = useQuery(
    trainingRequestsQueryKeys.byId(id ? +id : 0),
    () => requestTrainingService.getById(id ? +id : 0)
  );

  return {
    training,
    ...stuff,
  };
};
